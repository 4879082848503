import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { useSelector, useDispatch } from 'react-redux';
import { Col, Row, Select, Table, Tooltip } from 'antd';
import startCase from 'lodash/startCase';
import moment from 'moment';
import fileDownload from 'js-file-download';
import withStyles from 'react-jss';

import { FilePdfOutlined } from '@ant-design/icons';
import Structure from '../Structure/Structure';
import { sorter, onFilter, filterDropdown, dateSorter, dateFilterDropdown, onDateFilter } from '../utils';
import { getStockTransferList, clearStockTransferList } from '../Actions';
import Heading from '../Components/Heading';
import { errorNotification, successNotification } from '../Snackbar';
import Api from '../Api';
import styles from '../CommonStyles';

const StockTransferList = props => {
  const { classes } = props;

  const dispatch = useDispatch();

  const stockTransferList = useSelector(state => state.stockTransferList);

  const [financialYear, setFinancialYear] = useState('2024');

  useEffect(() => {
    dispatch(getStockTransferList({ financialYear }));
    return () => {
      dispatch(clearStockTransferList());
    };
  }, [financialYear]);

  const onFileDownload = async (id, material, batch) => {
    try {
      const { data } = await Api.getFile(`/material-inventory/transfers/${id}/pdf`);

      fileDownload(data, `Transfer_${material}_${batch}.pdf`);
      successNotification('File downloaded.');
    } catch (err) {
      errorNotification(err);
    }
  };

  const columns = [
    {
      title: 'From Consignor',
      dataIndex: 'fromConsignor',
      sorter: sorter('fromConsignor'),
      onFilter: onFilter('fromConsignor', true),
      filterDropdown,
    },
    {
      title: 'To Consignor',
      dataIndex: 'toConsignor',
      sorter: sorter('toConsignor'),
      onFilter: onFilter('toConsignor', true),
      filterDropdown,
    },
    {
      title: 'Material',
      dataIndex: 'material',
      filterDropdown,
      sorter: sorter('material'),
      onFilter: onFilter('material', true),
    },
    {
      title: 'Batch',
      dataIndex: 'batch',
      filterDropdown,
      sorter: sorter('batch'),
      onFilter: onFilter('batch', true),
    },
    {
      title: 'Type',
      dataIndex: 'materialType',
      filterDropdown,
      sorter: sorter('materialType'),
      onFilter: onFilter('materialType', true),
      render: type => startCase(type),
    },
    {
      title: 'Unit',
      dataIndex: 'unit',
      filterDropdown,
      sorter: sorter('unit'),
      onFilter: onFilter('unit', true),
    },
    {
      title: 'Quantity',
      dataIndex: 'quantity',
      sorter: sorter('quantity'),
      filterDropdown,
      onFilter: onFilter('quantity'),
    },
    {
      title: 'Remark',
      dataIndex: 'remark',
      sorter: sorter('remark'),
      filterDropdown,
      onFilter: onFilter('remark'),
    },
    {
      title: 'Date',
      dataIndex: 'createdAt',
      sorter: dateSorter('createdAt'),
      filterDropdown: dateFilterDropdown,
      onFilter: onDateFilter('createdAt'),
      render: createdAt => (createdAt ? moment(createdAt).format('DD MMM YYYY') : null),
    },
    {
      title: 'Adjust By',
      dataIndex: 'byUser',
      sorter: sorter('byUser'),
      filterDropdown,
      onFilter: onFilter('byUser'),
    },
    {
      title: 'Download',
      dataIndex: 'download',
      width: 90,
      render: (_text, record) => (
        <Tooltip placement="bottom" title="Download">
          <FilePdfOutlined
            className={classes.tableIcon}
            onClick={() => onFileDownload(record.id, record.material, record.batch)}
          />
        </Tooltip>
      ),
    },
  ];

  return (
    <Structure>
      <>
        <Heading text="Stock Transfer List" />
        <Row gutter={[32, 8]}>
          <Col>
            <Select placeholder="Select year" onChange={value => setFinancialYear(value)} defaultValue={financialYear}>
              <Select.Option value="2019">19-20</Select.Option>
              <Select.Option value="2020">20-21</Select.Option>
              <Select.Option value="2021">21-22</Select.Option>
              <Select.Option value="2022">22-23</Select.Option>
              <Select.Option value="2023">23-24</Select.Option>
              <Select.Option value="2024">24-25</Select.Option>
              <Select.Option value="2025">25-26</Select.Option>
              <Select.Option value="2026">26-27</Select.Option>
              <Select.Option value="2027">27-28</Select.Option>
              <Select.Option value="2028">28-29</Select.Option>
              <Select.Option value="2029">29-30</Select.Option>
              <Select.Option value="2030">30-31</Select.Option>
            </Select>
          </Col>
        </Row>
        <Table
          columns={columns}
          bordered
          rowKey={record => record.id}
          pagination={{ defaultPageSize: 20, position: ['bottomLeft'] }}
          dataSource={stockTransferList}
          size="small"
          scroll={{ x: 1024, y: window.innerHeight - 300 }}
        />
      </>
    </Structure>
  );
};

StockTransferList.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(StockTransferList);

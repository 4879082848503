import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';
import { Button, Row, Table } from 'antd';
import startCase from 'lodash/startCase';
import moment from 'moment';
import withStyles from 'react-jss';
import { Link } from 'react-router-dom';
import {
  clearCustomerInventoryView,
  clearCustomerOrders,
  getCustomerInventoryView,
  getCustomerOrders,
} from '../Actions';
import Heading from '../Components/Heading';
import { sorter, onFilter, filterDropdown } from '../utils';
import styles from '../CommonStyles';

const CustomerDetailsView = props => {
  const { classes, match } = props;
  const dispatch = useDispatch();

  const customerInventoryList = useSelector(state => state.customerInventoryList);
  const customerOrders = useSelector(state => state.customerOrders);

  const [orderAmt, setorderAmt] = useState(0);
  const [inventoryAmt, setinventoryAmt] = useState(0);

  useEffect(() => {
    dispatch(getCustomerInventoryView(match.params.id));
    dispatch(getCustomerOrders(match.params.id));
    return () => {
      dispatch(clearCustomerInventoryView());
      dispatch(clearCustomerOrders());
    };
  }, []);

  useEffect(() => {
    if (customerOrders) {
      const totalbalance = customerOrders.reduce((acc, obj) => {
        return (acc += +obj.quantity * +obj.rate);
      }, 0);

      setorderAmt(totalbalance);
    }
  }, [customerOrders]);

  useEffect(() => {
    if (customerInventoryList) {
      const totalbalance = customerInventoryList.reduce((acc, obj) => {
        return (acc += +obj.purchaseAmount);
      }, 0);

      setinventoryAmt(totalbalance);
    }
  }, [customerInventoryList]);

  const inventoryTableColumns = [
    {
      title: 'Material',
      dataIndex: 'name',
      filterDropdown,
      sorter: sorter('name'),
      onFilter: onFilter('name'),
    },
    {
      title: 'Type',
      dataIndex: 'type',
      filterDropdown,
      sorter: sorter('type'),
      onFilter: onFilter('type', true),
      render: type => startCase(type),
    },
    {
      title: 'Unit',
      dataIndex: 'unit',
      filterDropdown,
      sorter: sorter('unit'),
      onFilter: onFilter('unit'),
    },
    {
      title: 'Max Stock',
      dataIndex: 'maximumStock',
      filterDropdown,
      sorter: sorter('maximumStock'),
      onFilter: onFilter('maximumStock'),
      render: text => Number(text).toFixed(2),
    },
    {
      title: 'Stock',
      dataIndex: 'stock',
      filterDropdown,
      sorter: sorter('stock'),
      onFilter: onFilter('stock'),
      render: text => Number(text).toFixed(2),
    },
    {
      title: 'Rate',
      dataIndex: 'purchaseRate',
      filterDropdown,
      sorter: sorter('purchaseRate'),
      onFilter: onFilter('purchaseRate'),
      render: text => Number(text).toFixed(2),
    },
    {
      title: 'Amount',
      dataIndex: 'purchaseAmount',
      filterDropdown,
      sorter: sorter('purchaseAmount'),
      onFilter: onFilter('purchaseAmount'),
      render: text => Number(text).toFixed(2),
    },
  ];

  const ordersTableColumns = [
    {
      title: 'Orders No',
      dataIndex: 'id',
      sorter: sorter('id'),
      onFilter: onFilter('id'),
      filterDropdown,
    },
    {
      title: 'Name',
      dataIndex: 'formulation',
      sorter: sorter('formulation'),
      onFilter: onFilter('formulation'),
      filterDropdown,
    },
    {
      title: 'Quantity',
      dataIndex: 'quantity',
      sorter: sorter('quantity'),
      onFilter: onFilter('quantity'),
      filterDropdown,
    },
    {
      title: 'Rate',
      dataIndex: 'rate',
      sorter: sorter('rate'),
      onFilter: onFilter('rate'),
      filterDropdown,
    },
    {
      title: 'MRP',
      dataIndex: 'mrp',
      sorter: sorter('mrp'),
      onFilter: onFilter('mrp'),
      filterDropdown,
    },
    {
      title: 'Expected By',
      dataIndex: 'expectedBy',
      sorter: sorter('expectedBy'),
      onFilter: onFilter('expectedBy'),
      filterDropdown,
      render: expectedBy => (expectedBy ? moment(expectedBy).format('DD MMM YYYY') : null),
    },
    {
      title: 'Status',
      dataIndex: 'status',
      sorter: sorter('status'),
      onFilter: onFilter('status'),
      filterDropdown,
    },
    {
      title: 'Remarks',
      dataIndex: 'remarks',
      sorter: sorter('remarks'),
      onFilter: onFilter('remarks'),
      filterDropdown,
    },
  ];

  return (
    <>
      <br />
      <Row>
        <Button type="primary" className={classes.button}>
          <Link to={`/customer/complaint/${match.params.id}`}>Raise Complaint</Link>
        </Button>
      </Row>
      <Heading text={`Orders (Total Amount: Rs. ${Number(orderAmt.toFixed(2)).toLocaleString('en-IN')})`} />
      <Table
        columns={ordersTableColumns}
        bordered
        rowKey={record => record.id}
        dataSource={customerOrders}
        size="small"
        scroll={{ x: 1050, y: 600 }}
        pagination={false}
      />
      <br />
      <Heading text={`Inventory (Total Amount: Rs. ${Number(inventoryAmt.toFixed(2)).toLocaleString('en-IN')})`} />
      <Table
        columns={inventoryTableColumns}
        bordered
        rowKey={record => record.name}
        dataSource={customerInventoryList}
        size="small"
        scroll={{ x: 1050, y: 600 }}
        pagination={false}
      />
    </>
  );
};

CustomerDetailsView.propTypes = {
  classes: PropTypes.object.isRequired,
  match: PropTypes.object.isRequired,
};

export default withStyles(styles)(CustomerDetailsView);

import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import withStyles from 'react-jss';
import { Field, getFormValues, reduxForm } from 'redux-form';
import { connect, useDispatch, useSelector } from 'react-redux';
import { Button, Col, DatePicker, Input, Popconfirm, Row, Select } from 'antd';
import { useHistory } from 'react-router';
import Modal from 'antd/lib/modal/Modal';
import moment from 'moment';
import GetField from '../Components/GetField';
import { get } from '../utils';
import Api from '../Api';
import { errorNotification, successNotification } from '../Snackbar';
import styles from '../CommonStyles';
import Structure from '../Structure/Structure';
import Heading from '../Components/Heading';
import LeftGrid from '../Components/LeftGrid';
import RightGrid from '../Components/RightGrid';
import { clearTicket, clearUserList, getTicket, getUserList } from '../Actions';

const AntInput = GetField(Input);
const AntSelect = GetField(Select);
const AntDatePicker = GetField(DatePicker);

const EditTicket = props => {
  const { handleSubmit, classes, match } = props;
  const history = useHistory();
  const dispatch = useDispatch();

  const [formValueModal, setFormValuesModal] = useState(false);
  const [confirmLoading, setConfirmLoading] = useState(false);
  const [preview, setPreview] = useState({});
  const FormValues = useSelector(state => getFormValues('EditTicket')(state));
  const userList = useSelector(state => state.userList);
  const auth = useSelector(state => state.auth);

  useEffect(() => {
    dispatch(getTicket(match.params.id));
    dispatch(getUserList());
    return () => {
      dispatch(clearTicket());
      dispatch(clearUserList());
    };
  }, []);

  const showFormValuesModal = () => {
    const dueDate = get(FormValues, ['dueDate']);

    const _preview = {
      title: get(FormValues, ['title'], '--'),
      description: get(FormValues, ['description'], '--'),
      forUser: '--',
      dueDate: dueDate ? moment(dueDate).format('DD MMM YYYY h:mm A') : '--',
      remark: get(FormValues, ['remark'], '--'),
    };

    const userId = get(FormValues, ['forUser'], null);
    if (userId) {
      const userObj = userList.find(user => user.id === +userId);
      if (userObj) {
        _preview.forUser = userObj.name;
      }
    }

    setPreview(_preview);
    setFormValuesModal(true);
  };

  const hideFormValuesModal = () => {
    setFormValuesModal(false);
    setPreview({});
  };

  const onSubmit = async () => {
    try {
      setConfirmLoading(true);
      await Api.put(`/tickets/${match.params.id}`, FormValues);
      successNotification('Ticket raised');
      setConfirmLoading(false);
      hideFormValuesModal();
      history.push('/');
    } catch (err) {
      setConfirmLoading(false);
      errorNotification(err);
    }
  };

  return (
    <Structure>
      <>
        <Heading text="Edit Ticket" />
        <form onSubmit={handleSubmit(onSubmit)}>
          <Row>
            <LeftGrid>
              <Field allowClear required name="title" label="Title" placeholder="Title" component={AntInput} />
            </LeftGrid>
            <RightGrid>
              <Field
                allowClear
                required
                name="description"
                label="Description"
                placeholder="Description"
                component={AntInput}
              />
            </RightGrid>
          </Row>
          <Row>
            <LeftGrid>
              <Field label="Assign To" disabled required name="forUser" component={AntSelect}>
                <Select.Option key={null} value="" disabled>
                  Select user
                </Select.Option>
                {userList
                  .filter(user => user.id !== auth.user.id)
                  .map(user => (
                    <Select.Option key={user.id} value={user.id}>
                      {user.name}
                    </Select.Option>
                  ))}
              </Field>
            </LeftGrid>
            <RightGrid>
              <Field
                disabled
                required
                showTime={{ format: 'HH:mm' }}
                use12Hours
                name="dueDate"
                label="Due Date"
                placeholder="Due Date"
                component={AntDatePicker}
              />
            </RightGrid>
          </Row>
          <Row>
            <LeftGrid>
              <Field allowClear required name="remark" label="Remark" placeholder="Remark" component={AntInput} />
            </LeftGrid>
          </Row>
          <Row className={classes.buttonContainer}>
            <Button className={classes.button} type="primary" onClick={showFormValuesModal}>
              Preview
            </Button>
          </Row>
        </form>
        <Modal
          onCancel={hideFormValuesModal}
          open={formValueModal}
          confirmLoading={confirmLoading}
          centered
          width="800px"
          maskClosable={false}
          closable={false}
          cancelText="Back"
          okButtonProps={{ className: classes.button }}
          okText={
            <Popconfirm
              title="Sure want to submit form?"
              onConfirm={() => onSubmit()}
              okText="Confirm"
              cancelText="Cancel"
            >
              Submit
            </Popconfirm>
          }
        >
          <Row>
            <Col xs={24} lg={12}>
              <p>
                Title:
                <span className={classes.detailColor}>{preview.title}</span>
              </p>
            </Col>
            <Col xs={24} lg={12}>
              <p>
                Description:
                <span className={classes.detailColor}>{preview.description}</span>
              </p>
            </Col>
          </Row>
          <Row>
            <Col xs={24} lg={12}>
              <p>
                Assigne To:
                <span className={classes.detailColor}>{preview.forUser}</span>
              </p>
            </Col>
            <Col xs={24} lg={12}>
              <p>
                Due Date:
                <span className={classes.detailColor}>{preview.dueDate}</span>
              </p>
            </Col>
          </Row>
          <Row>
            <Col xs={24} lg={12}>
              <p>
                Remark:
                <span className={classes.detailColor}>{preview.remark}</span>
              </p>
            </Col>
          </Row>
        </Modal>
      </>
    </Structure>
  );
};

EditTicket.propTypes = {
  handleSubmit: PropTypes.func.isRequired,
  classes: PropTypes.object.isRequired,
  match: PropTypes.object.isRequired,
};

const StyledEditTicket = withStyles(styles)(EditTicket);

const FormedEditTicket = reduxForm({
  form: 'EditTicket',
  enableReinitialize: true,
})(StyledEditTicket);

export default connect(({ ticket }) => ({ initialValues: ticket }), {})(FormedEditTicket);

import Api from '../Api';

export const getOrderReport = () => async dispatch => {
  try {
    const { data } = await Api.get('/orders/reports/overview');
    dispatch({
      type: 'FETCH_ORDER_REPORT',
      payload: data,
    });
  } catch (err) {
    throw err;
  }
};

export const clearOrderReport = () => {
  return {
    type: 'CLEAR_ORDER_REPORT',
  };
};

export const getOrderThroughputBrands = () => async dispatch => {
  try {
    const { data } = await Api.get('/orders/reports/throughput');
    dispatch({
      type: 'FETCH_ORDER_THROUGHPUT_BRANDS',
      payload: data,
    });
  } catch (err) {
    throw err;
  }
};

export const clearOrderThroughputBrands = () => {
  return {
    type: 'CLEAR_ORDER_THROUGHPUT_BRANDS',
  };
};

export const getOrderUsersReport = () => async dispatch => {
  try {
    const { data } = await Api.get('/orders/reports/users');
    dispatch({
      type: 'FETCH_ORDER_USERS_REPORT',
      payload: data,
    });
  } catch (err) {
    throw err;
  }
};

export const clearOrderUsersReport = () => {
  return {
    type: 'CLEAR_ORDER_USERS_REPORT',
  };
};

export const getPurchaseOrderReport = () => async dispatch => {
  try {
    const { data } = await Api.get('/purchase-orders/reports/overview');
    dispatch({
      type: 'FETCH_PURCHASE_ORDER_REPORT',
      payload: data,
    });
  } catch (err) {
    throw err;
  }
};

export const clearPurchaseOrderReport = () => {
  return {
    type: 'CLEAR_PURCHASE_ORDER_REPORT',
  };
};

export const getPurchaseReport = () => async dispatch => {
  try {
    const { data } = await Api.get('/purchases/reports/overview');
    dispatch({
      type: 'FETCH_PURCHASE_REPORT',
      payload: data,
    });
  } catch (err) {
    throw err;
  }
};

export const clearPurchaseReport = () => {
  return {
    type: 'CLEAR_PURCHASE_REPORT',
  };
};

export const getSaleReport = () => async dispatch => {
  try {
    const { data } = await Api.get('/order-sales/reports/overview');
    dispatch({
      type: 'FETCH_SALE_REPORT',
      payload: data,
    });
  } catch (err) {
    throw err;
  }
};

export const clearSaleReport = () => {
  return {
    type: 'CLEAR_SALE_REPORT',
  };
};

export const getFormulationReport = () => async dispatch => {
  try {
    const { data } = await Api.get('/formulations/reports/overview');
    dispatch({
      type: 'FETCH_FORMULATION_REPORT',
      payload: data,
    });
  } catch (err) {
    throw err;
  }
};

export const clearFormulationReport = () => {
  return {
    type: 'CLEAR_FORMULATION_REPORT',
  };
};

export const getMaterialReport = () => async dispatch => {
  try {
    const { data } = await Api.get('/material-inventory/reports/overview');
    dispatch({
      type: 'FETCH_MATERIAL_REPORT',
      payload: data,
    });
  } catch (err) {
    throw err;
  }
};

export const clearMaterialReport = () => {
  return {
    type: 'CLEAR_MATERIAL_REPORT',
  };
};

export const getCustomersInventoryTotalReport = () => async dispatch => {
  try {
    const { data } = await Api.get('/material-inventory/reports/customers');
    dispatch({
      type: 'FETCH_CUSTOMER_INVENTORY_TOTAL_REPORT',
      payload: data,
    });
  } catch (err) {
    throw err;
  }
};

export const clearCustomersInventoryTotalReport = () => {
  return {
    type: 'CLEAR_CUSTOMER_INVENTORY_TOTAL_REPORT',
  };
};

export const getMaterialInOutReport = () => async dispatch => {
  try {
    const { data } = await Api.get('/materials/reports/inout');
    dispatch({
      type: 'FETCH_MATERIAL_IN_OUT_REPORT',
      payload: data,
    });
  } catch (err) {
    throw err;
  }
};

export const clearMaterialInOutReport = () => {
  return {
    type: 'CLEAR_MATERIAL_IN_OUT_REPORT',
  };
};

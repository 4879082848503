import Api from '../Api';

export const getRoleList = () => async dispatch => {
  try {
    const { data } = await Api.get('/roles');
    dispatch({
      type: 'FETCH_ROLE_LIST',
      payload: data,
    });
  } catch (err) {
    throw err;
  }
};

export const clearRoleList = () => {
  return {
    type: 'CLEAR_ROLE_LIST',
  };
};

export const getRole = id => async dispatch => {
  try {
    const { data } = await Api.get(`/roles/${id}`);
    dispatch({
      type: 'FETCH_ROLE',
      payload: data,
    });
  } catch (err) {
    throw err;
  }
};

export const clearRole = () => {
  return {
    type: 'CLEAR_ROLE',
  };
};

export const getRoleIdList = () => async dispatch => {
  try {
    const { data } = await Api.get('/roles/ids');
    dispatch({
      type: 'FETCH_ROLE_ID_LIST',
      payload: data,
    });
  } catch (err) {
    throw err;
  }
};

export const clearRoleIdList = () => {
  return {
    type: 'CLEAR_ROLE_ID_LIST',
  };
};

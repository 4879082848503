import React, { useCallback, useEffect, useMemo, useState } from 'react';
import PropTypes from 'prop-types';
import { Button, Modal, Popconfirm, Table, Tooltip, Upload } from 'antd';
import { useDispatch, useSelector } from 'react-redux';

import { CloseOutlined, CloudUploadOutlined, DeleteOutlined } from '@ant-design/icons';
import moment from 'moment';
import { successNotification, errorNotification, infoNotification } from '../../Snackbar';
import Api from '../../Api';
import { getQueryFilesList, clearQueryFilesList } from '../../Actions';

const QueryFileModal = props => {
  const { query, hideModal } = props;

  const dispatch = useDispatch();

  const [fileList, setFileList] = useState([]);
  const [uploading, setUploading] = useState(false);

  const queryFiles = useSelector(state => state.queryFiles);

  useEffect(() => {
    dispatch(getQueryFilesList(query.id));

    return () => {
      dispatch(clearQueryFilesList());
    };
  }, []);

  const onUpload = async () => {
    try {
      setUploading(true);
      const formData = new FormData();
      fileList.forEach((file, index) => {
        formData.append(`files[${index}]`, file);
      });
      const { data } = await Api.post(`/leads/queries/${query.id}/files`, formData, {
        headers: { 'content-type': 'multipart/form-data' },
      });
      infoNotification(data);
      setUploading(false);
      setFileList([]);
      dispatch(clearQueryFilesList());
      dispatch(getQueryFilesList(query.id));
    } catch (err) {
      errorNotification(err);
    }
  };

  const onFileDelete = async id => {
    try {
      await Api.delete(`/leads/queries/${query.id}/files/${id}`);
      successNotification('File delete');
      dispatch(clearQueryFilesList(query.id));
    } catch (err) {
      errorNotification(err);
    }
  };

  const onRemove = file => {
    const index = fileList.indexOf(file);
    const newFileList = fileList.slice();
    newFileList.splice(index, 1);
    setFileList(newFileList);
  };

  const beforeUpload = (file, files) => {
    setFileList([...fileList, ...files]);
    return false;
  };

  const columns = useMemo(
    () => [
      {
        title: 'File Name',
        render: record => (
          <a href={record.url} rel="noreferrer noopener" target="_blank">
            <Tooltip placement="bottom" title="Click to open/download file.">
              {record.fileName}
            </Tooltip>
          </a>
        ),
      },
      {
        dataIndex: 'uploadedBy',
        title: 'Uploaded By',
      },
      {
        dataIndex: 'uploadedAt',
        title: 'Uploaded At',
        render: date => moment(date).format('DD MMM YYYY'),
      },
      {
        title: 'Delete',
        render: record => (
          <Tooltip placement="bottom" title="Delete">
            <Popconfirm
              title="Are you sure you want to delete?"
              onConfirm={() => onFileDelete(record.id)}
              okText="Yes"
              cancelText="No"
            >
              <DeleteOutlined />
            </Popconfirm>
          </Tooltip>
        ),
      },
    ],
    []
  );

  return (
    <Modal
      centered
      open
      maskClosable={false}
      okText="Close"
      onOk={hideModal}
      cancelButtonProps={{ style: { display: 'none' } }}
      width={1000}
      title={`${query?.query} | Files`}
      closeIcon={<CloseOutlined onClick={hideModal} />}
    >
      <Upload multiple onRemove={onRemove} beforeUpload={beforeUpload} fileList={fileList}>
        <Button icon={<CloudUploadOutlined />}>Select Files</Button>
      </Upload>
      <Button
        type="primary"
        onClick={onUpload}
        disabled={fileList.length === 0}
        loading={uploading}
        style={{ marginTop: 16 }}
      >
        {uploading ? 'Uploading' : 'Upload'}
      </Button>
      <br />
      <br />
      <Table
        bordered
        size="small"
        columns={columns}
        dataSource={queryFiles}
        rowKey={useCallback(record => record.id, [])}
        pagination={false}
      />
    </Modal>
  );
};

QueryFileModal.propTypes = {
  query: PropTypes.object,
  hideModal: PropTypes.func,
};

export default QueryFileModal;

/* eslint-disable no-dupe-keys */
import { secondary } from './theme';

export default {
  buttonContainer: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  button: {
    background: '#096dd9 !important',
    color: secondary,
    border: 'none',
  },
  tableIcon: {
    fontSize: '17px !important',
    color: 'rgba(0, 0, 0, 0.65)',
  },
  commentIcon: {
    fontSize: '17px !important',
    color: 'rgba(0, 0, 0, 0.85)',
    paddingLeft: '10px',
  },
  inputTable: {
    '& div.ant-form-item': {
      margin: '0 !important',
    },
  },
  selectOptions: {
    'white-space': '-moz-pre-wrap !important',
    'white-space': '-pre-wrap',
    'white-space': '-o-pre-wrap',
    'white-space': 'pre-wrap',
    'word-wrap': 'break-word',
    'word-wrap': 'break-word',
    'overflow-wrap': 'break-word',
    'white-space': '-webkit-pre-wrap',
    'word-break': 'break-all',
    'white-space': 'normal',
  },
  hide: {
    display: 'none',
  },
  centerAlign: {
    display: 'block',
    textAlign: 'center',
  },
  detailColor: {
    color: '#096dd9',
    paddingLeft: 5,
  },
  detailColorImp: {
    color: '#ff0003',
    paddingLeft: 5,
    fontSize: '16px',
  },
  headingColor: {
    color: '#096dd9',
  },
  paddingInCol: {
    padding: 5,
  },
  plusFormulatioHeadinng: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    fontSize: '16px',
  },
  fontWeight: {
    fontWeight: 600,
    fontSize: '16px',
  },
  heading: {
    marginTop: 5,
    marginBottom: 5,
    fontWeight: 'bold',
  },
  balanceSheetFont: {
    fontSize: '16px',
  },
  dashboardNumberFont: {
    fontSize: 20,
    fontWeight: '500',
    color: '#096dd9',
  },
  selectListWidth: {
    width: '300px',
  },
  textAlignRight: {
    textAlign: 'right',
  },
  textAlignCenter: {
    textAlign: 'center',
  },
  uploadButton: {
    marginTop: '40px',
  },
  inputTable: {
    width: '100%',
    tableLayout: 'fixed',
    textAlign: 'left',
    borderCollapse: 'separate;',
    borderRadius: '2px 2px 0 0',
    borderSpacing: 0,
  },
  inputThead: {
    overflowWrap: 'break-word',
    textAlign: 'center',
    backgroundColor: '#fafafa',
    fontWeight: 600,
  },
  inputTh: {
    border: '1px solid #f0f0f0',
    fontWeight: 600,
    backgroundColor: '#ebebeb',
    color: 'rgb(0, 0, 0)',
    padding: '2px',
    borderCollapse: 'collapse',
    textAlign: 'center',
  },
  inputTd: {
    border: '1px solid #f0f0f0',
    padding: '5px',
  },
  newInputTable: {
    width: '100%',
    // width: 'max-content',
    '& div.ant-form-item': {
      margin: '0 !important',
    },
    '& div.ant-select-selector': {
      padding: '0 0 0 2px !important',
    },
    '& span.ant-select-selection-item': {
      paddingRight: '0 !important',
    },
    '& span.ant-select-arrow': {
      // display: 'none !important',
    },
    '& span.ant-picker-suffix': {
      // display: 'none !important',
    },
    '& span.ant-picker-clear': {
      // display: 'none !important',
    },
    '& input.ant-input': {
      padding: '4px 2px !important',
    },
    '& div.ant-input-number-handler-wrap': {
      // display: 'none !important',
    },
    '& div.ant-picker': {
      padding: '4px 2px !important',
    },
    '& input.ant-input-number-input': {
      padding: '4px 2px !important',
    },
    tableLayout: 'fixed',
    textAlign: 'left',
    borderCollapse: 'separate;',
    borderRadius: '2px 2px 0 0',
    borderSpacing: 0,
  },
  newInputThead: {
    overflowWrap: 'break-word',
    textAlign: 'center',
    backgroundColor: '#fafafa',
  },
  newInputTh: {
    border: '1px solid #f0f0f0',
    fontWeight: 400,
    backgroundColor: '#ebebeb',
    color: 'rgb(0, 0, 0)',
    padding: '5px',
    borderCollapse: 'collapse',
  },
  newInputTd: {
    border: '1px solid #f0f0f0',
  },
  colorBox: {
    height: '30px',
    width: '30px',
    borderRadius: '15px',
  },
  inputTable: {
    width: '100%',
    // width: 'max-content',
    '& div.ant-form-item': {
      margin: '0 !important',
    },
    '& div.ant-select-selector': {
      padding: '0 0 0 2px !important',
    },
    '& span.ant-select-selection-item': {
      paddingRight: '0 !important',
    },
    '& span.ant-select-arrow': {
      // display: 'none !important',
    },
    '& span.ant-picker-suffix': {
      // display: 'none !important',
    },
    '& span.ant-picker-clear': {
      // display: 'none !important',
    },
    '& input.ant-input': {
      padding: '4px 2px !important',
    },
    '& div.ant-input-number-handler-wrap': {
      // display: 'none !important',
    },
    '& div.ant-picker': {
      padding: '4px 2px !important',
    },
    '& input.ant-input-number-input': {
      padding: '4px 2px !important',
    },
    tableLayout: 'fixed',
    textAlign: 'left',
    borderCollapse: 'separate;',
    borderRadius: '2px 2px 0 0',
    borderSpacing: 0,
  },
  inputThead: {
    overflowWrap: 'break-word',
    textAlign: 'center',
    backgroundColor: '#fafafa',
  },
  inputTh: {
    border: '1px solid #f0f0f0',
    fontWeight: 400,
    backgroundColor: '#ebebeb',
    color: 'rgb(0, 0, 0)',
    padding: '5px',
    borderCollapse: 'collapse',
  },
  inputTd: {
    border: '1px solid #f0f0f0',
  },
};

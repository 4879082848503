import React, { useEffect, useState } from 'react';
import { useSelector, useDispatch, connect } from 'react-redux';
import withStyles from 'react-jss';
import PropTypes from 'prop-types';
import { Popconfirm, Button, Input, Modal, Select, Col, Row, DatePicker } from 'antd';
import { reduxForm, Field, getFormValues } from 'redux-form';
import { useHistory } from 'react-router';
import startCase from 'lodash/startCase';
import moment from 'moment';
import Structure from '../Structure/Structure';
import Api from '../Api';
import {
  getNarrationList,
  clearNarrationList,
  getBankList,
  clearBankList,
  getSupplierIdList,
  clearSupplierIdList,
  getCustomerIdList,
  clearCustomerIdList,
  getTransaction,
  clearTransaction,
  getEmployeeIdList,
  getLedgerContactIdList,
  getAssetIdList,
  clearEmployeeIdList,
  clearLedgerContactIdList,
  clearAssetIdList,
} from '../Actions';
import { errorNotification, successNotification } from '../Snackbar';
import styles from '../CommonStyles';
import Heading from '../Components/Heading';
import GetField from '../Components/GetField';
import constants from '../constants';
import LeftGrid from '../Components/LeftGrid';
import RightGrid from '../Components/RightGrid';
import { get } from '../utils';

const AntSelect = GetField(Select);
const AntInput = GetField(Input);
const AntDatePicker = GetField(DatePicker);

const EditTransaction = props => {
  const { handleSubmit, classes, match, change } = props;
  const history = useHistory();
  const dispatch = useDispatch();

  const [formValueModal, setFormValuesModal] = useState(false);
  const [confirmLoading, setConfirmLoading] = useState(false);
  const [preview, setPreview] = useState({});
  const FormValues = useSelector(state => getFormValues('EditTransaction')(state));

  const bankList = useSelector(state => state.bankList);
  const narrationList = useSelector(state => state.narrationList);
  const supplierIdList = useSelector(state => state.supplierIdList);
  const customerIdList = useSelector(state => state.customerIdList);
  const employeeIdList = useSelector(state => state.employeeIdList);
  const ledgerContactIdList = useSelector(state => state.ledgerContactIdList);
  const assetIdList = useSelector(state => state.assetIdList);

  useEffect(() => {
    dispatch(getNarrationList());
    dispatch(getBankList());
    dispatch(getSupplierIdList());
    dispatch(getCustomerIdList());
    dispatch(getEmployeeIdList());
    dispatch(getLedgerContactIdList());
    dispatch(getAssetIdList());
    dispatch(getTransaction(match.params.id));
    return () => {
      dispatch(clearNarrationList());
      dispatch(clearBankList());
      dispatch(clearSupplierIdList());
      dispatch(clearCustomerIdList());
      dispatch(clearEmployeeIdList());
      dispatch(clearLedgerContactIdList());
      dispatch(clearAssetIdList());
      dispatch(clearTransaction());
    };
  }, []);

  const showFormValuesModal = () => {
    const type = get(FormValues, ['type']);
    const transDate = get(FormValues, ['transDate']);
    const whom = get(FormValues, ['whom']);
    const _preview = {
      type: type ? startCase(type) : '--',
      consignor: get(FormValues, ['consignor'], '--'),
      bank: '--',
      narration: '--',
      head: '--',
      transDate: transDate ? moment(transDate).format('DD MMM YYYY') : '--',
      amount: get(FormValues, ['amount'], '--'),
      whom: whom ? startCase(whom) : '--',
      name: '--',
      refNo: get(FormValues, ['refNo'], '--'),
      remarks: get(FormValues, ['remarks'], '--'),
      asset: '--',
    };

    const bankId = get(FormValues, ['bankId'], null);
    if (bankId) {
      const bankObj = bankList.find(bank => bank.id === +bankId);
      if (bankObj) {
        _preview.bank = bankObj.bank;
      }
    }

    const narrationId = get(FormValues, ['narrationId'], null);
    if (narrationId) {
      const narrationObj = narrationList.find(narration => narration.id === +narrationId);
      if (narrationObj) {
        _preview.narration = narrationObj.narration;
        _preview.head = narrationObj.head;
      }
    }

    if (whom === 'customer') {
      const customerId = get(FormValues, ['customerId'], null);
      if (customerId) {
        const customerObj = customerIdList.find(customer => customer.id === +customerId);
        if (customerObj) {
          _preview.name = customerObj.name;
        }
      }
    }

    if (whom === 'supplier') {
      const supplierId = get(FormValues, ['supplierId'], null);
      if (supplierId) {
        const supplierObj = supplierIdList.find(supplier => supplier.id === +supplierId);
        if (supplierObj) {
          _preview.name = supplierObj.name;
        }
      }
    }

    if (whom === 'employee') {
      const employeeId = get(FormValues, ['employeeId'], null);
      if (employeeId) {
        const employeeObj = employeeIdList.find(employee => employee.id === +employeeId);
        if (employeeObj) {
          _preview.name = employeeObj.name;
        }
      }
    }

    if (whom === 'contact') {
      const contactId = get(FormValues, ['contactId'], null);
      if (contactId) {
        const contactObj = ledgerContactIdList.find(contact => contact.id === +contactId);
        if (contactObj) {
          _preview.name = contactObj.name;
        }
      }
    }
    const assetId = get(FormValues, ['assetId'], null);
    if (assetId) {
      const assetObj = assetIdList.find(asset => asset.id === +assetId);
      if (assetObj) {
        _preview.asset = assetObj.name;
      }
    }

    setPreview(_preview);
    setFormValuesModal(true);
  };

  const hideFormValuesModal = () => {
    setFormValuesModal(false);
    setPreview({});
  };

  const onSubmit = async () => {
    try {
      setConfirmLoading(true);
      await Api.put(`/bank-statements/${match.params.id}`, FormValues);
      successNotification('Transaction updated');
      setConfirmLoading(false);
      hideFormValuesModal();
      history.push('/transaction/list');
    } catch (err) {
      setConfirmLoading(false);
      errorNotification(err);
    }
  };

  return (
    <Structure>
      <>
        <Heading text="Edit Transaction" />
        <form onSubmit={handleSubmit(onSubmit)}>
          <Row>
            <LeftGrid>
              <Field label="Transaction Type" required allowClear name="type" component={AntSelect}>
                <Select.Option key={null} value="" disabled>
                  Select transaction type
                </Select.Option>
                {Object.values(constants.bank.types).map(type => (
                  <Select.Option key={type} value={type}>
                    {startCase(type)}
                  </Select.Option>
                ))}
              </Field>
            </LeftGrid>
            <RightGrid>
              <Field label="Consignor" allowClear required name="consignor" component={AntSelect}>
                <Select.Option key={null} value="" disabled>
                  Select consignor
                </Select.Option>
                {Object.values(constants.consignors.list).map(consignor => (
                  <Select.Option key={consignor} value={consignor}>
                    {consignor}
                  </Select.Option>
                ))}
              </Field>
            </RightGrid>
          </Row>
          <Row>
            <LeftGrid>
              <Field label="Bank" required disabled allowClear name="bankId" component={AntSelect}>
                <Select.Option key={null} value="" disabled>
                  Select bank
                </Select.Option>
                {bankList.map(bank => (
                  <Select.Option key={bank.id} value={bank.id}>
                    {bank.consignor} ({bank.bank})
                  </Select.Option>
                ))}
              </Field>
            </LeftGrid>
            <RightGrid>
              <Field label="Narration" required allowClear name="narrationId" component={AntSelect}>
                <Select.Option key={null} value="" disabled>
                  Select narration
                </Select.Option>
                {narrationList.map(narration => (
                  <Select.Option key={narration.id} value={narration.id}>
                    {narration.narration}
                  </Select.Option>
                ))}
              </Field>
            </RightGrid>
          </Row>
          <Row>
            <LeftGrid>
              <Field label="Summary Head" disabled required allowClear name="narrationId" component={AntSelect}>
                <Select.Option key={null} value="" disabled>
                  Select narration
                </Select.Option>
                {narrationList.map(narration => (
                  <Select.Option key={narration.id} value={narration.id}>
                    {narration.head}
                  </Select.Option>
                ))}
              </Field>
            </LeftGrid>
            <RightGrid>
              <Field
                allowClear
                required
                label="Transaction Date"
                name="transDate"
                placeholder="Transaction Date"
                component={AntDatePicker}
              />
            </RightGrid>
          </Row>
          <Row>
            <LeftGrid>
              <Field
                label="Amount"
                allowClear
                required
                type="number"
                min={0}
                name="amount"
                placeholder="Amount"
                component={AntInput}
              />
            </LeftGrid>
            <RightGrid>
              <Field label="Reference" allowClear name="refNo" placeholder="Reference" component={AntInput} />
            </RightGrid>
          </Row>
          <Row>
            <LeftGrid>
              <Field
                label="Whom"
                allowClear
                required
                name="whom"
                component={AntSelect}
                onChange={() => {
                  change('customerId', null);
                  change('supplierId', null);
                  change('employeeId', null);
                  change('contactId', null);
                }}
              >
                <Select.Option key={null} value="" disabled>
                  Select whom
                </Select.Option>
                <Select.Option key="customer" value="customer">
                  Customer
                </Select.Option>
                <Select.Option key="supplier" value="supplier">
                  Supplier
                </Select.Option>
                <Select.Option key="employee" value="employee">
                  Employee
                </Select.Option>
                <Select.Option key="contact" value="contact">
                  Ledger Contact
                </Select.Option>
              </Field>
            </LeftGrid>
            <RightGrid>
              {FormValues && FormValues.whom === 'customer' ? (
                <Field label="Customer" allowClear required name="customerId" component={AntSelect}>
                  <Select.Option key={null} value="" disabled>
                    Select customer
                  </Select.Option>
                  {customerIdList.map(customer => (
                    <Select.Option key={customer.id} value={customer.id}>
                      {customer.name}
                    </Select.Option>
                  ))}
                </Field>
              ) : null}
              {FormValues && FormValues.whom === 'supplier' ? (
                <Field label="Supplier" allowClear required name="supplierId" component={AntSelect}>
                  <Select.Option key={null} value="" disabled>
                    Select supplier
                  </Select.Option>
                  {supplierIdList.map(supplier => (
                    <Select.Option key={supplier.id} value={supplier.id}>
                      {supplier.name}
                    </Select.Option>
                  ))}
                </Field>
              ) : null}
              {FormValues && FormValues.whom === 'employee' ? (
                <Field label="Employee" allowClear required name="employeeId" component={AntSelect}>
                  <Select.Option key={null} value="" disabled>
                    Select employee
                  </Select.Option>
                  {employeeIdList.map(employee => (
                    <Select.Option key={employee.id} value={employee.id}>
                      {employee.name}
                    </Select.Option>
                  ))}
                </Field>
              ) : null}
              {FormValues && FormValues.whom === 'contact' ? (
                <Field label="Ledger Contact" allowClear required name="contactId" component={AntSelect}>
                  <Select.Option key={null} value="" disabled>
                    Select contact
                  </Select.Option>
                  {ledgerContactIdList.map(contact => (
                    <Select.Option key={contact.id} value={contact.id}>
                      {contact.name}
                    </Select.Option>
                  ))}
                </Field>
              ) : null}
            </RightGrid>
          </Row>
          <Row>
            <LeftGrid>
              <Field label="Asset" allowClear required name="assetId" component={AntSelect}>
                <Select.Option key={null} value="" disabled>
                  Select asset
                </Select.Option>
                {assetIdList.map(asset => (
                  <Select.Option key={asset.id} value={asset.id}>
                    {asset.name}
                  </Select.Option>
                ))}
              </Field>
            </LeftGrid>
            <RightGrid>
              <Field label="Remarks" allowClear name="remarks" placeholder="Remarks" component={AntInput} />
            </RightGrid>
          </Row>
          <Row className={classes.buttonContainer}>
            <Button className={classes.button} type="primary" onClick={showFormValuesModal}>
              Preview
            </Button>
          </Row>
        </form>
        <Modal
          onCancel={hideFormValuesModal}
          open={formValueModal}
          confirmLoading={confirmLoading}
          centered
          width="800px"
          maskClosable={false}
          closable={false}
          cancelText="Back"
          okButtonProps={{ className: classes.button }}
          okText={
            <Popconfirm
              title="Sure want to submit form?"
              onConfirm={() => onSubmit()}
              okText="Confirm"
              cancelText="Cancel"
            >
              Submit
            </Popconfirm>
          }
        >
          <Row>
            <Col xs={24} lg={12}>
              <p>
                Transaction Type:
                <span className={classes.detailColor}>{preview.type}</span>
              </p>
            </Col>
            <Col xs={24} lg={12}>
              <p>
                Consignor:
                <span className={classes.detailColor}>{preview.consignor}</span>
              </p>
            </Col>
            <Col xs={24} lg={12}>
              <p>
                Bank:
                <span className={classes.detailColor}>{preview.bank}</span>
              </p>
            </Col>
          </Row>
          <Row>
            <Col xs={24} lg={12}>
              <p>
                Narration:
                <span className={classes.detailColor}>{preview.narration}</span>
              </p>
            </Col>
            <Col xs={24} lg={12}>
              <p>
                Summary Head:
                <span className={classes.detailColor}>{preview.head}</span>
              </p>
            </Col>
          </Row>
          <Row>
            <Col xs={24} lg={12}>
              <p>
                Transaction Date:
                <span className={classes.detailColor}>{preview.transDate}</span>
              </p>
            </Col>
            <Col xs={24} lg={12}>
              <p>
                Amount:
                <span className={classes.detailColor}>{preview.amount}</span>
              </p>
            </Col>
          </Row>
          <Row>
            <Col xs={24} lg={12}>
              <p>
                Reference:
                <span className={classes.detailColor}>{preview.refNo}</span>
              </p>
            </Col>
            <Col xs={24} lg={12}>
              <p>
                Whom:
                <span className={classes.detailColor}>{preview.whom}</span>
              </p>
            </Col>
          </Row>
          <Row>
            <Col xs={24} lg={12}>
              <p>
                Name:
                <span className={classes.detailColor}>{preview.name}</span>
              </p>
            </Col>
            <Col xs={24} lg={12}>
              <p>
                Remarks:
                <span className={classes.detailColor}>{preview.remarks}</span>
              </p>
            </Col>
          </Row>
          <Row>
            <Col xs={24} lg={12}>
              <p>
                Asset:
                <span className={classes.detailColor}>{preview.asset}</span>
              </p>
            </Col>
          </Row>
        </Modal>
      </>
    </Structure>
  );
};

EditTransaction.propTypes = {
  handleSubmit: PropTypes.func.isRequired,
  classes: PropTypes.object.isRequired,
  match: PropTypes.object.isRequired,
  change: PropTypes.func.isRequired,
};

const StyledEditTransaction = withStyles(styles)(EditTransaction);

const FormedEditTransaction = reduxForm({
  form: 'EditTransaction',
  enableReinitialize: true,
})(StyledEditTransaction);

export default connect(({ transaction }) => ({ initialValues: transaction }), {})(FormedEditTransaction);

import React, { useEffect, useMemo, useState } from 'react';
import { Button, Col, DatePicker, Modal, Row, Table } from 'antd';
import { useDispatch, useSelector } from 'react-redux';
import moment from 'moment';

import { FileExcelOutlined, InfoCircleOutlined } from '@ant-design/icons';
import fileDownload from 'js-file-download';
import Heading from '../Components/Heading';
import Structure from '../Structure/Structure';
import { getLastSellingGP, clearLastSellingGP } from '../Actions';
import { onFilter, sorter, filterDropdown } from '../utils';
import { errorNotification, infoNotification, successNotification } from '../Snackbar';
import Api from '../Api';

const CurrencyFormatter = new Intl.NumberFormat('en-IN', {
  currency: 'INR',
  style: 'currency',
});

const NumberFormatter = new Intl.NumberFormat('en-IN', {
  maximumFractionDigits: '2',
});

const LastSellingProductGP = () => {
  const [From, SetFrom] = useState(moment().startOf('month'));
  const [To, SetTo] = useState(moment().endOf('month'));

  const [Product, SetProduct] = useState(null);
  const [ModalType, SetModalType] = useState(null);

  const dispatch = useDispatch();

  const lastSellingGP = useSelector(state => state.lastSellingGP);

  const { totalGpAmount, totalSales } = useMemo(
    () =>
      lastSellingGP.reduce(
        (acc, el) => {
          acc.totalGpAmount += +el.gpAmount || 0;
          acc.totalSales += +el.value || 0;
          return acc;
        },
        { totalGpAmount: 0, totalSales: 0 }
      ),
    [lastSellingGP]
  );

  useEffect(() => {
    dispatch(
      getLastSellingGP({ from: From && From.startOf('day').toISOString(), to: To && To.endOf('day').toISOString() })
    );
    return () => {
      dispatch(clearLastSellingGP());
    };
  }, [From, To]);

  const onFileDownload = async () => {
    try {
      infoNotification('File downloading ...');

      const { data } = await Api.getFile(`/formulations/reports/gp/lastselling/export`, {
        from: From && From.startOf('day').toISOString(),
        to: To && To.endOf('day').toISOString(),
      });

      fileDownload(data, `Product-GP-Values-${moment().format('DD MM YY hh:mm:ss A')}.xlsx`);
      successNotification('File downloaded');
    } catch (err) {
      errorNotification(err);
    }
  };

  const columns = useMemo(() => {
    const _columns = [
      {
        title: 'Formulation',
        dataIndex: 'formulation',
        sorter: sorter('formulation'),
        onFilter: onFilter('formulation'),
        filterDropdown,
      },
      {
        title: 'Batch',
        dataIndex: 'batch',
        sorter: sorter('batch'),
        onFilter: onFilter('batch'),
        filterDropdown,
      },
      {
        title: 'Date',
        dataIndex: 'voucherDate',
        render: date => (date ? moment(date).format('DD MMM YYYY') : null),
      },
      {
        title: 'Voucher #',
        dataIndex: 'voucherNo',
      },
      {
        title: 'Quantity',
        dataIndex: 'quantity',
        render: el => NumberFormatter.format(+el),
      },
      {
        title: 'Material Cost',
        dataIndex: 'materialCost',
        render: el => (+el ? CurrencyFormatter.format(+el) : 'Not Known'),
      },
      {
        title: 'Selling Rate',
        dataIndex: 'sellingRate',
        render: el => CurrencyFormatter.format(+el),
      },
      {
        title: 'GP Value',
        dataIndex: 'gpValue',
        render: (__, record) => (+record.materialCost ? CurrencyFormatter.format(record.gpValue) : 'Not Known'),
        sorter: sorter('gpValue', true),
      },
      {
        title: 'GP Percentage',
        dataIndex: 'gpPercentage',
        render: (__, record) =>
          +record.materialCost ? `${NumberFormatter.format(+record.gpPercentage)}%` : 'Not Known',
        sorter: sorter('gpPercentage', true),
      },
      {
        title: 'Total GP Amount',
        dataIndex: 'gpAmount',
        render: gpAmount => (+gpAmount ? CurrencyFormatter.format(gpAmount) : 'Not Known'),
        sorter: sorter('gpAmount', true),
      },
      {
        title: 'Material Details',
        render: product => (
          <InfoCircleOutlined
            onClick={() => {
              SetProduct(product);
              SetModalType('Material Info');
            }}
          />
        ),
      },
    ];

    return _columns;
  }, []);

  const materialColumns = useMemo(() => {
    const _columns = [
      {
        title: 'Material',
        dataIndex: 'material',
      },
      {
        title: 'Batch',
        dataIndex: 'batch',
      },
      {
        title: 'Quantity',
        dataIndex: 'quantity',
      },
      {
        title: 'Unit',
        dataIndex: 'unit',
      },
      {
        title: 'Rate',
        dataIndex: 'rate',
      },
      {
        title: 'Value',
        render: el => +(el.quantity * el.rate).toFixed(4),
      },
      {
        title: 'Effective Value for one',
        render: el => +((el.quantity * el.rate) / el.yield).toFixed(4),
      },
    ];

    return _columns;
  }, []);

  return (
    <Structure>
      <>
        <Heading text="Product GP Values" />
        <br />
        <Row gutter={[8, 8]}>
          &nbsp;
          <Col>
            <DatePicker onChange={SetFrom} placeholder="From" value={From} />
          </Col>
          <Col>
            <DatePicker onChange={SetTo} placeholder="To" value={To} />
          </Col>
          <Col>
            <Button icon={<FileExcelOutlined />} onClick={onFileDownload}>
              Download
            </Button>
          </Col>
        </Row>
        <h3>
          Total GP Amount: {CurrencyFormatter.format(totalGpAmount)} (
          {NumberFormatter.format((totalGpAmount * 100) / totalSales)}%)
        </h3>
        <Table
          bordered
          size="small"
          columns={columns}
          dataSource={lastSellingGP}
          rowKey={useMemo(() => record => record.id, [])}
          pagination={false}
          scroll={{ y: 1000 }}
        />
        {ModalType === 'Material Info' && Product && (
          <Modal
            open
            title="Material Info"
            maskClosable={false}
            width={900}
            cancelButtonProps={{ style: { display: 'none' } }}
            onOk={() => {
              SetProduct(null);
              SetModalType(null);
            }}
          >
            <>
              <Row>Product: {Product.formulation}</Row>
              <Row>Batch: {Product.batch}</Row>
              <Row>Yield: {(Product.materialsInfo || [])[0]?.[0]?.yield}</Row>
              <br />
              <Row>
                <Table
                  bordered
                  size="small"
                  columns={materialColumns}
                  dataSource={Product.materialsInfo?.[0] || []}
                  rowKey={record => `m${record.material}-b${record.batch}`}
                  pagination={false}
                />
              </Row>
            </>
          </Modal>
        )}
      </>
    </Structure>
  );
};

export default LastSellingProductGP;

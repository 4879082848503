import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { reduxForm, Field, getFormValues } from 'redux-form';
import withStyles from 'react-jss';
import { useSelector, useDispatch, connect } from 'react-redux';
import { useHistory } from 'react-router';
import { Row, Button, Input, Select, Modal, Col, Popconfirm } from 'antd';
import Structure from '../Structure/Structure';
import styles from '../CommonStyles';
import { successNotification, errorNotification } from '../Snackbar';
import Api from '../Api';
import CenterGrid from '../Components/CenterGrid';
import GetField from '../Components/GetField';
import { getUser, clearUser, getRoleIdList, clearRoleIdList } from '../Actions';
import Heading from '../Components/Heading';
import { get } from '../utils';

const AntInput = GetField(Input);
const AntSelect = GetField(Select);

const EditUser = props => {
  const { handleSubmit, classes, match } = props;
  const history = useHistory();
  const dispatch = useDispatch();

  const [formValueModal, setFormValuesModal] = useState(false);
  const [confirmLoading, setConfirmLoading] = useState(false);
  const [preview, setPreview] = useState({});
  const FormValues = useSelector(state => getFormValues('EditUser')(state));

  const roleIdList = useSelector(state => state.roleIdList);

  useEffect(() => {
    dispatch(getRoleIdList());
    dispatch(getUser(match.params.id));
    return () => {
      dispatch(clearRoleIdList());
      dispatch(clearUser());
    };
  }, []);

  const showFormValuesModal = () => {
    const _preview = {
      firstName: get(FormValues, ['firstName'], '--'),
      lastName: get(FormValues, ['lastName'], '--'),
      email: get(FormValues, ['email'], '--'),
      phone: get(FormValues, ['phone'], '--'),
      role: '--',
    };

    const roleId = get(FormValues, ['roleId'], null);
    if (roleId) {
      const roleObj = roleIdList.find(unit => unit.id === +roleId);
      if (roleObj) {
        _preview.role = roleObj.role;
      }
    }

    setPreview(_preview);
    setFormValuesModal(true);
  };

  const hideFormValuesModal = () => {
    setFormValuesModal(false);
    setPreview({});
  };

  const onSubmit = async () => {
    try {
      setConfirmLoading(true);
      await Api.put(`/users/${match.params.id}`, FormValues);
      successNotification('User updated');
      setConfirmLoading(false);
      hideFormValuesModal();
      history.push('/user/list');
    } catch (err) {
      setConfirmLoading(false);
      errorNotification(err);
    }
  };

  return (
    <Structure>
      <>
        <Heading text="Edit User" />
        <form onSubmit={handleSubmit(onSubmit)}>
          <Row>
            <CenterGrid>
              <Field
                label="First Name"
                required
                allowClear
                name="firstName"
                placeholder="First Name"
                component={AntInput}
              />
            </CenterGrid>
            <CenterGrid>
              <Field
                label="Last Name"
                required
                allowClear
                name="lastName"
                placeholder="Last Name"
                component={AntInput}
              />
            </CenterGrid>
          </Row>
          <Row>
            <CenterGrid>
              <Field label="Email" disabled required allowClear name="email" placeholder="Email" component={AntInput} />
            </CenterGrid>
            <CenterGrid>
              <Field allowClear label="Phone Number" name="phone" placeholder="Phone Number" component={AntInput} />
            </CenterGrid>
          </Row>
          <Row>
            <CenterGrid>
              <Field label="Role" required allowClear name="roleId" component={AntSelect}>
                <Select.Option key={null} value="" disabled>
                  Select role
                </Select.Option>
                {roleIdList.map(role => (
                  <Select.Option key={role.id} value={role.id}>
                    {role.role}
                  </Select.Option>
                ))}
              </Field>
            </CenterGrid>
          </Row>
          <Row className={classes.buttonContainer}>
            <Button className={classes.button} type="primary" onClick={showFormValuesModal}>
              Preview
            </Button>
          </Row>
        </form>
        <Modal
          onCancel={hideFormValuesModal}
          open={formValueModal}
          confirmLoading={confirmLoading}
          centered
          width="800px"
          maskClosable={false}
          closable={false}
          cancelText="Back"
          okButtonProps={{ className: classes.button }}
          okText={
            <Popconfirm
              title="Sure want to submit form?"
              onConfirm={() => onSubmit()}
              okText="Confirm"
              cancelText="Cancel"
            >
              Submit
            </Popconfirm>
          }
        >
          <Row>
            <Col xs={24} lg={12}>
              <p>
                First Name:
                <span className={classes.detailColor}>{preview.firstName}</span>
              </p>
            </Col>
            <Col xs={24} lg={12}>
              <p>
                Last Name:
                <span className={classes.detailColor}>{preview.lastName}</span>
              </p>
            </Col>
          </Row>
          <Row>
            <Col xs={24} lg={12}>
              <p>
                Email:
                <span className={classes.detailColor}>{preview.email}</span>
              </p>
            </Col>
            <Col xs={24} lg={12}>
              <p>
                Phone Number:
                <span className={classes.detailColor}>{preview.phone}</span>
              </p>
            </Col>
          </Row>
          <Row>
            <Col xs={24} lg={12}>
              <p>
                Role:
                <span className={classes.detailColor}>{preview.role}</span>
              </p>
            </Col>
          </Row>
        </Modal>
      </>
    </Structure>
  );
};

EditUser.propTypes = {
  handleSubmit: PropTypes.func.isRequired,
  classes: PropTypes.object.isRequired,
  match: PropTypes.object.isRequired,
};

const StyledEditUser = withStyles(styles)(EditUser);

const FormedEditUser = reduxForm({
  form: 'EditUser',
  enableReinitialize: true,
})(StyledEditUser);

export default connect(({ user }) => ({ initialValues: user }), {})(FormedEditUser);

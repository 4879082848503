import { Button, Row, Select } from 'antd';
import PropTypes from 'prop-types';
import React, { Fragment } from 'react';
import withStyles from 'react-jss';

import { Field, reduxForm } from 'redux-form';
import { useHistory } from 'react-router';
import styles from '../CommonStyles';
import GetField from '../Components/GetField';
import Heading from '../Components/Heading';
import Structure from '../Structure/Structure';
import CenterGrid from '../Components/CenterGrid';
import { errorNotification } from '../Snackbar';
import Api from '../Api';

const AntSelect = GetField(Select);

const Messaging = props => {
  const { classes, handleSubmit } = props;
  const history = useHistory();

  const onSubmit = async formValues => {
    try {
      await Api.post('/marketing/sms', formValues);
      history.push('/');
    } catch (err) {
      errorNotification(err);
    }
  };

  return (
    <Structure>
      <>
        <Heading text="WhatsApp" />
        <form onSubmit={handleSubmit(onSubmit)}>
          <Row>
            <CenterGrid>
              <Field label="Whom to send" allowClear required name="to" component={AntSelect}>
                <Select.Option key={null} value="" disabled>
                  Select whom to send
                </Select.Option>
                <Select.Option key="all" value="all">
                  All
                </Select.Option>
                <Select.Option key="customer" value="customer">
                  Customer
                </Select.Option>
                <Select.Option key="supplier" value="supplier">
                  Supplier
                </Select.Option>
                {/* <Select.Option key="team" value="team">
                  Team
                </Select.Option> */}
              </Field>
            </CenterGrid>
            {/* <CenterGrid>
              <Field label="Text" allowClear name="text" placeholder="Text" component={AntInput} />
            </CenterGrid> */}
          </Row>
          <Row className={classes.buttonContainer}>
            <Button className={classes.button} type="primary" htmlType="submit">
              Submit
            </Button>
          </Row>
        </form>
      </>
    </Structure>
  );
};

Messaging.propTypes = {
  classes: PropTypes.object.isRequired,
  handleSubmit: PropTypes.func.isRequired,
};

const StyledMessaging = withStyles(styles)(Messaging);

const FormedMessaging = reduxForm({
  form: 'Messaging',
})(StyledMessaging);

export default FormedMessaging;

import React, { useState, useCallback, useEffect } from 'react';
import PropTypes from 'prop-types';
import { Controller } from 'react-hook-form';
import { DeleteOutlined } from '@ant-design/icons';
import { Input, Select } from 'antd';
import withStyles from 'react-jss';
import { useSelector } from 'react-redux';

import styles from '../../CommonStyles';
import GetField from '../../Components/GetRHFField';

const AntInput = GetField(Input);
const AntSelect = GetField(Select);
const { Option } = Select;

const ChargeTable = props => {
  const { classes, getValues, setValue, control, chargeItem, chargeIndex, chargeRemove } = props;

  const chargeIds = useSelector(state => state.chargeIds);

  const [chargeIndexesObject, setChargeIndexesObject] = useState({});

  useEffect(() => {
    const _charegIndexes = {};

    chargeIds.map(charge => {
      _charegIndexes[+charge.id] = charge;
    });

    setChargeIndexesObject(_charegIndexes);
  }, [chargeIds]);

  const calcChargeTotal = useCallback(() => {
    const chargeId = getValues(`charges[${chargeIndex}].chargeId`);

    if (chargeId) {
      const value = getValues(`charges[${chargeIndex}].value`) || 0;

      const tax = getValues(`charges[${chargeIndex}].tax`) || 0;

      const taxValue = +((+value * +tax) / 100).toFixed(2) || 0;

      const total = +(+value + +taxValue).toFixed(2) || 0;

      setValue(`charges[${chargeIndex}].taxValue`, taxValue);

      setValue(`charges[${chargeIndex}].total`, total);
    }
  }, []);

  const calcGrandTotal = useCallback(() => {
    const productsArray = getValues('products');
    const chargesArray = getValues('charges');

    let productTotal = 0;
    let chargeTotal = 0;

    if (productsArray.length) {
      productTotal = (productsArray || []).reduce((acc, { total }) => acc + +total, 0);

      setValue('productsTotal', +productTotal.toFixed(2));
    }

    if (chargesArray.length) {
      chargeTotal = (chargesArray || []).reduce((acc, { total }) => acc + +total, 0);

      setValue('chargesTotal', +chargeTotal.toFixed(2));
    }

    const grandTotal = +(+productTotal + +chargeTotal) || 0;

    setValue('grandTotal', Math.round(grandTotal));
  }, []);

  return (
    <tr>
      <td className={classes.inputTd}>
        <Controller
          name={`charges[${chargeIndex}].chargeId`}
          defaultValue={chargeItem.chargeId}
          control={control}
          render={({ field }) => (
            <AntSelect
              placeholder="Select charge"
              allowClear
              {...field}
              onChange={value => {
                field.onChange(value);

                if (value) {
                  const chargeObj = chargeIndexesObject[value];

                  setValue(`charges[${chargeIndex}].tax`, chargeObj.gst);
                } else {
                  setValue(`charges[${chargeIndex}].value`, 0);
                  setValue(`charges[${chargeIndex}].tax`, 0);
                  setValue(`charges[${chargeIndex}].taxValue`, 0);
                  setValue(`charges[${chargeIndex}].total`, 0);
                }
                calcChargeTotal();

                calcGrandTotal();
              }}
            >
              {chargeIds.map(charge => (
                <Option key={charge.id} value={charge.id}>
                  {charge.name}
                </Option>
              ))}
            </AntSelect>
          )}
        />
      </td>
      <td className={classes.inputTd}>
        <Controller
          name={`charges[${chargeIndex}].value`}
          defaultValue={chargeItem.value}
          control={control}
          render={({ field }) => (
            <AntInput
              type="number"
              min={0}
              {...field}
              onChange={value => {
                field.onChange(value);
                calcChargeTotal();
                calcGrandTotal();
              }}
            />
          )}
        />
      </td>
      <td className={classes.inputTd}>
        <Controller
          name={`charges[${chargeIndex}].tax`}
          defaultValue={chargeItem.tax}
          control={control}
          render={({ field }) => <AntInput type="number" min={0} disabled {...field} />}
        />
      </td>
      <td className={classes.inputTd}>
        <Controller
          name={`charges[${chargeIndex}].taxValue`}
          defaultValue={chargeItem.taxValue}
          control={control}
          render={({ field }) => <AntInput type="number" min={0} disabled {...field} />}
        />
      </td>
      <td className={classes.inputTd}>
        <Controller
          name={`charges[${chargeIndex}].total`}
          defaultValue={chargeItem.total}
          control={control}
          render={({ field }) => <AntInput type="number" min={0} disabled {...field} />}
        />
      </td>
      <td
        className={classes.inputTd}
        style={{
          textAlign: 'center',
        }}
      >
        <DeleteOutlined
          onClick={() => {
            chargeRemove(chargeIndex);

            calcGrandTotal();
          }}
        />
      </td>
    </tr>
  );
};

ChargeTable.propTypes = {
  classes: PropTypes.object.isRequired,
  getValues: PropTypes.func.isRequired,
  setValue: PropTypes.func.isRequired,
  control: PropTypes.any.isRequired,
  chargeItem: PropTypes.object.isRequired,
  chargeIndex: PropTypes.number.isRequired,
  chargeRemove: PropTypes.func.isRequired,
};

export default withStyles(styles)(ChargeTable);

/* eslint-disable no-nested-ternary */
import React, { useCallback, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import withStyles from 'react-jss';
import { useDispatch, useSelector } from 'react-redux';
import { Col, DatePicker, Input, Modal, Row, Select, Popconfirm } from 'antd';
import { useForm, Controller } from 'react-hook-form';
import moment from 'moment';

import styles from '../../CommonStyles';
import Api from '../../Api';
import { errorNotification, successNotification } from '../../Snackbar';
import GetRHFField from '../../Components/GetRHFField';
import { clearSubTask, clearUserList, getSubTask, getUserList } from '../../Actions';

const AntInput = GetRHFField(Input);
const AntDatePicker = GetRHFField(DatePicker);

const AntSelect = GetRHFField(Select);
const { Option } = Select;

const SubTaskModal = props => {
  const { recordId, taskId, setModaltype, setRefetch, refetch, setEvent } = props;

  const dispatch = useDispatch();

  const { control, reset, getValues } = useForm({
    shouldUnregister: false,
  });

  const [confirmLoading, setConfirmLoading] = useState(false);

  const userList = useSelector(state => state.userList);
  const subTask = useSelector(state => state.subTask);

  useEffect(() => {
    dispatch(getUserList());
    if (recordId) {
      dispatch(getSubTask(taskId, recordId));
    }

    return () => {
      dispatch(clearUserList());
      dispatch(clearSubTask());
    };
  }, []);

  const disabledDate = useCallback(current => {
    return current && current < moment().subtract(1, 'day').endOf('day');
  }, []);

  useEffect(() => {
    reset(subTask);
  }, [subTask]);

  const onSubmit = useCallback(async () => {
    try {
      const formValues = getValues();

      setConfirmLoading(true);
      await Api.post(`/tasks/${taskId}/subtasks`, {
        ...formValues,
        endAt:
          formValues.durationType === 'days'
            ? moment(formValues.startAt).add(formValues.duration, 'days')
            : formValues.durationType === 'hours'
            ? moment(formValues.startAt).add(formValues.duration, 'hours')
            : moment(formValues.startAt).add(formValues.duration, 'minutes'),
      });

      reset();
      setConfirmLoading(false);
      successNotification('Subtask added successfully');
      setModaltype(null);
      setEvent(null);
      dispatch(clearSubTask());
      setRefetch(!refetch);
    } catch (err) {
      setConfirmLoading(false);
      errorNotification(err);
    }
  }, []);

  const onEdit = useCallback(async () => {
    try {
      const formValues = getValues();

      setConfirmLoading(true);
      await Api.put(`/tasks/${taskId}/subtasks/${recordId}`, {
        ...formValues,
        endAt:
          formValues.durationType === 'days'
            ? moment(formValues.startAt).add(formValues.duration, 'days')
            : formValues.durationType === 'hours'
            ? moment(formValues.startAt).add(formValues.duration, 'hours')
            : moment(formValues.startAt).add(formValues.duration, 'minutes'),
      });
      reset();
      setConfirmLoading(false);
      successNotification('Subtask updated successfully');
      setModaltype(null);
      setEvent(null);
      dispatch(clearSubTask());
      setRefetch(!refetch);
    } catch (err) {
      setConfirmLoading(false);
      errorNotification(err);
    }
  }, [recordId]);

  return (
    <Modal
      title="Subtask Form"
      confirmLoading={confirmLoading}
      open
      onCancel={() => setModaltype(null)}
      centered
      maskClosable={false}
      cancelText="Back"
      okText={
        <Popconfirm
          title="Sure want to submit form?"
          onConfirm={() => (recordId ? onEdit() : onSubmit())}
          okText="Confirm"
          cancelText="Cancel"
        >
          Submit
        </Popconfirm>
      }
    >
      <form>
        <Row>
          <Col span={24}>
            <Controller
              name="name"
              control={control}
              render={({ field }) => <AntInput allowClear required label="Name" placeholder="Name" {...field} />}
            />
          </Col>
        </Row>
        <Row>
          <Col span={24}>
            <Controller
              name="description"
              control={control}
              render={({ field }) => (
                <AntInput allowClear required label="Description" placeholder="Description" {...field} />
              )}
            />
          </Col>
        </Row>
        <Row>
          <Col span={24}>
            <Controller
              name="assigneeId"
              control={control}
              render={({ field }) => (
                <AntSelect allowClear label="Assigne" placeholder="Select user" {...field}>
                  {userList.map(user => (
                    <Option key={user.id} value={user.id}>
                      {user.name}
                    </Option>
                  ))}
                </AntSelect>
              )}
            />
          </Col>
        </Row>
        <Row>
          <Col span={24}>
            <Controller
              name="startAt"
              control={control}
              render={({ field }) => (
                <AntDatePicker
                  allowClear
                  showTime={{ format: 'hh:mm A' }}
                  format="YYYY-MM-DD hh:mm A"
                  disabledDate={disabledDate}
                  label="Start Date"
                  placeholder="Start date"
                  {...field}
                />
              )}
            />
          </Col>
        </Row>
        <Row>
          <Col span={12}>
            <Controller
              name="durationType"
              control={control}
              render={({ field }) => (
                <AntSelect allowClear required label="Duration Type" placeholder="Select duration type" {...field}>
                  <Option key="minutes" value="minutes">
                    Minutes
                  </Option>
                  <Option key="hours" value="hours">
                    Hours
                  </Option>
                  <Option key="days" value="days">
                    Days
                  </Option>
                </AntSelect>
              )}
            />
          </Col>
          <Col span={12}>
            <Controller
              name="duration"
              control={control}
              render={({ field }) => (
                <AntInput
                  allowClear
                  required
                  type="number"
                  min={1}
                  label="Task duration"
                  placeholder="Task duration"
                  {...field}
                />
              )}
            />
          </Col>
        </Row>
      </form>
    </Modal>
  );
};

SubTaskModal.propTypes = {
  classes: PropTypes.object.isRequired,
  recordId: PropTypes.number,
  taskId: PropTypes.number,
  setModaltype: PropTypes.func.isRequired,
  refetch: PropTypes.bool,
  setRefetch: PropTypes.func.isRequired,
  setEvent: PropTypes.func.isRequired,
};

export default withStyles(styles)(SubTaskModal);

import React, { useState, useEffect, useCallback } from 'react';
import PropTypes from 'prop-types';
import { Button, Col, Descriptions, Modal, Row, Select, Tooltip, Badge } from 'antd';
import { Calendar as RBC, momentLocalizer } from 'react-big-calendar';
import moment from 'moment';
import { useDispatch, useSelector } from 'react-redux';
import startCase from 'lodash/startCase';

import { CloseOutlined, DeleteOutlined, EditOutlined, PlusOutlined } from '@ant-design/icons';
import withStyles from 'react-jss';
import Structure from '../Structure/Structure';
import { clearSubTask, clearTask, clearTaskList, getTaskList } from '../Actions';
import Api from '../Api';
import { errorNotification, successNotification } from '../Snackbar';
import TaskModal from './Tasks/TaskModal';
import SubTaskModal from './Tasks/SubTaskModal';
import RequestExtension from './Tasks/RequestExtension';
import TaskComeplete from './Tasks/TaskComeplete';
import styles from '../CommonStyles';
import { get } from '../utils';

const { Option } = Select;

const Calendar = props => {
  const { classes } = props;

  const dispatch = useDispatch();

  const tasks = useSelector(state =>
    state.tasksList.map(el => ({
      ...el,
      start: el.start ? new Date(el.start) : null,
      end: el.end ? new Date(el.end) : null,
    }))
  );

  const auth = useSelector(state => state.auth);
  const permissions = get(auth, ['permissions'], []);

  const [event, setEvent] = useState(null);
  const [fetchTasks, setFetchTasks] = useState(null);
  const [refetch, setRefetch] = useState(false);

  useEffect(() => {
    return () => {
      dispatch(clearTaskList());
      dispatch(clearTask());
      dispatch(clearSubTask());
    };
  }, []);

  const [modalType, setModaltype] = useState(null);

  useEffect(() => {
    if (!modalType) {
      dispatch(clearTask());
    }
  }, [modalType]);

  const [start, setStart] = useState(moment(new Date().toISOString()).startOf('month').startOf('week').toISOString());
  const [end, setEnd] = useState(moment(new Date().toISOString()).endOf('month').endOf('week').toISOString());

  useEffect(() => {
    dispatch(getTaskList({ start, end, filter: fetchTasks }));
  }, [start, end, fetchTasks, refetch]);

  const eventStyleGetter = useCallback(_event => {
    let bgColor = 'rgb(179, 157, 219)'; // Wisteria

    if (_event.type === 'task') {
      bgColor = 'rgb(251, 188, 5)'; // Yellow
    }
    if (_event.type === 'subTask') {
      bgColor = 'rgb(240, 147, 0)'; // Mango
    }
    if (_event.type === 'kra') {
      bgColor = 'rgb(234, 67, 53)'; // Red
    }
    if (_event.type === 'reminder') {
      bgColor = 'rgb(66, 133, 244)'; // Blue
    }
    if (_event.type === 'event') {
      bgColor = 'rgb(52, 168, 83)'; // Green
    }

    return {
      style: {
        backgroundColor: bgColor,
        color: 'rgb(255, 255, 255)',
        fontSize: '16px',
        border: '0px',
      },
    };
  }, []);

  const onDelete = async id => {
    try {
      await Api.delete(`tasks/${id}`);
      successNotification('Task deleted');
      setEvent(null);
      setRefetch(!refetch);
    } catch (err) {
      errorNotification(err);
    }
  };

  const onMarkAsNotDone = async id => {
    try {
      await Api.put(`tasks/undocomplete/${id}`);
      successNotification('Task marked as not done');
      setEvent(null);
      setRefetch(!refetch);
    } catch (error) {
      errorNotification(error);
    }
  };

  return (
    <Structure>
      <>
        <Row gutter={[8, 8]}>
          <Col sx={{ span: 6 }} sm={{ span: 6 }} md={{ span: 6 }} lg={{ span: 4 }} xl={{ span: 4 }} xxl={{ span: 4 }}>
            <Badge color="rgb(234, 67, 53)" text="KRA" />
          </Col>
          <Col sx={{ span: 6 }} sm={{ span: 6 }} md={{ span: 6 }} lg={{ span: 4 }} xl={{ span: 4 }} xxl={{ span: 4 }}>
            <Badge color="rgb(251, 188, 5)" text="Task" />
          </Col>
          {/* <Col sx={{ span: 6 }} sm={{ span: 6 }} md={{ span: 6 }} lg={{ span: 4 }} xl={{ span: 4 }} xxl={{ span: 4 }}>
            <Badge color="rgb(240, 147, 0)" text="Subtask" />
          </Col> */}
          <Col sx={{ span: 6 }} sm={{ span: 6 }} md={{ span: 6 }} lg={{ span: 4 }} xl={{ span: 4 }} xxl={{ span: 4 }}>
            <Badge color="rgb(66, 133, 244)" text="Reminder" />
          </Col>
          <Col sx={{ span: 6 }} sm={{ span: 6 }} md={{ span: 6 }} lg={{ span: 4 }} xl={{ span: 4 }} xxl={{ span: 4 }}>
            <Badge color="rgb(52, 168, 83)" text="Event" />
          </Col>
        </Row>
        <br />
        <Row>
          {permissions.includes('todo') ? (
            <Col>
              <Button className={classes.button} onClick={() => setModaltype('task')}>
                <PlusOutlined /> Task
              </Button>
            </Col>
          ) : null}
          &nbsp; &nbsp; &nbsp;
          <Col>
            <Select defaultValue="My Tasks" style={{ width: 150 }} onChange={value => setFetchTasks(value)}>
              <Option key="My Tasks" value="My Tasks">
                My Tasks
              </Option>
              <Option key="All Tasks" value="All Tasks">
                All Tasks
              </Option>
              <Option key="All Tasks" value="Completed">
                Completed (Assigned by you)
              </Option>
            </Select>
          </Col>
        </Row>
        <br />
        <RBC
          localizer={momentLocalizer(moment)}
          style={{ minHeight: 700, width: '95%' }}
          selectable
          events={tasks}
          views={['month', 'week', 'day', 'agenda']}
          defaultView="week"
          onSelectEvent={setEvent}
          selected={event}
          eventPropGetter={eventStyleGetter}
          titleAccessor={e => `${e.title} - ${e.createdBy}`}
          onRangeChange={x => {
            if (x.start && x.end) {
              setStart(moment(x.start).startOf('day').toISOString());
              setEnd(moment(x.end).endOf('day').toISOString());
            } else if (x.length) {
              setStart(moment(x[0]).startOf('day').toISOString());
              setEnd(
                moment(x[x.length - 1])
                  .endOf('day')
                  .toISOString()
              );
            }
          }}
        />
        {modalType === 'task' ? (
          <TaskModal
            recordId={event && event.id}
            setModaltype={setModaltype}
            onSubmit={() => {
              setEvent(null);
              setRefetch(!refetch);
            }}
          />
        ) : null}
        {modalType === 'subTask' ? (
          <SubTaskModal
            recordId={event && event.type === 'subTask' ? event.id : null}
            taskId={event && event.type === 'subTask' ? event && event.taskId : event && event.id}
            setModaltype={setModaltype}
            setRefetch={setRefetch}
            refetch={refetch}
            event={event}
            setEvent={setEvent}
          />
        ) : null}
        {modalType === 'requestExtension' ? (
          <RequestExtension
            setModaltype={setModaltype}
            setRefetch={setRefetch}
            refetch={refetch}
            event={event}
            setEvent={setEvent}
          />
        ) : null}
        {modalType === 'markAsDone' ? (
          <TaskComeplete
            setModaltype={setModaltype}
            setRefetch={setRefetch}
            refetch={refetch}
            event={event}
            setEvent={setEvent}
          />
        ) : null}
        {event ? (
          <Modal
            open
            closable={false}
            maskClosable={false}
            centered
            footer={[
              event.isEditable && event.type !== 'kra' ? (
                <Tooltip placement="bottom" title="Edit" key="edit">
                  <Button
                    shape="circle"
                    icon={<EditOutlined />}
                    onClick={() => (event?.taskType === 'task' ? setModaltype('subTask') : setModaltype('task'))}
                  />
                </Tooltip>
              ) : null,
              event.isEditable && event.type !== 'kra' ? (
                <Tooltip placement="bottom" title="Delete" key="delete">
                  <Button shape="circle" icon={<DeleteOutlined />} onClick={() => onDelete(event.id)} />
                </Tooltip>
              ) : null,
              <Tooltip placement="bottom" title="Close" key="close">
                <Button shape="circle" icon={<CloseOutlined />} onClick={() => setEvent(null)} />
              </Tooltip>,
              !event.completedAt ? (
                <Button type="primary" shape="round" key="markAsDone" onClick={() => setModaltype('markAsDone')}>
                  Mark as done
                </Button>
              ) : (
                <Button type="primary" shape="round" key="markAsNotDone" onClick={() => onMarkAsNotDone(event.id)}>
                  Mark as not done
                </Button>
              ),
            ]}
          >
            <Descriptions column={1} size="small" borderless title={`${startCase(event.type)} Details`}>
              <Descriptions.Item label="Name">{event.name}</Descriptions.Item>
              {/* <Descriptions.Item label="Description">{event.description}</Descriptions.Item> */}
              <Descriptions.Item label="Remark">{event.remark}</Descriptions.Item>
              <Descriptions.Item label="Starts At">
                {moment(event.startAt).format('DD MMM YYYY hh:mm A')}
              </Descriptions.Item>
              {event.type === 'kra' || event.type === 'task' || event.type === 'subTask' ? (
                <>
                  <Descriptions.Item label="Duration">{`${event.duration} ${startCase(
                    event.durationType
                  )} `}</Descriptions.Item>
                  {/* <Descriptions.Item label="Ends At">
                    {moment(event.endAt).format('DD MMM YYYY hh:mm A')}
                  </Descriptions.Item> */}
                </>
              ) : null}
              {event.type === 'subTask' ? (
                <>
                  <Descriptions.Item label="Task Name">{event.taskName}</Descriptions.Item>
                  {/* <Descriptions.Item label="Task Description">{event.taskDescription}</Descriptions.Item> */}
                  <Descriptions.Item label="Task Start Date">
                    {moment(event.taskStartAt).format('DD MMM YYYY hh:mm A')}
                  </Descriptions.Item>
                  <Descriptions.Item label="Task End Date">
                    {moment(event.taskEndAt).format('DD MMM YYYY hh:mm A')}
                  </Descriptions.Item>
                </>
              ) : null}
              {event.type === 'task' || event.type === 'subTask' || event.type === 'event' ? (
                <>
                  <Descriptions.Item label="Assigner">{event.createdBy}</Descriptions.Item>
                  <Descriptions.Item label="Assignees">{event.assignee}</Descriptions.Item>
                </>
              ) : null}
            </Descriptions>
            {event.type === 'task' ? (
              <>
                <br />
                <br />
                <Row>
                  {/* <Col
                    sx={{ span: 24 }}
                    sm={{ span: 24 }}
                    md={{ span: 12 }}
                    lg={{ span: 12 }}
                    xl={{ span: 12 }}
                    xxl={{ span: 12 }}
                  >
                    <Button onClick={() => setModaltype('subTask')}>Add Sub Task</Button>
                  </Col> */}
                  {event.type === 'task' || event.type === 'subTask' ? (
                    <Col
                      sx={{ span: 24 }}
                      sm={{ span: 24 }}
                      md={{ span: 12 }}
                      lg={{ span: 12 }}
                      xl={{ span: 12 }}
                      xxl={{ span: 12 }}
                    >
                      <Button onClick={() => setModaltype('requestExtension')}>Request Extension</Button>
                    </Col>
                  ) : null}
                </Row>
              </>
            ) : null}
          </Modal>
        ) : null}
      </>
    </Structure>
  );
};

Calendar.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(Calendar);

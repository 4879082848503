import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { reduxForm, Field, getFormValues } from 'redux-form';
import { Row, Button, Input, Select, Modal, Popconfirm, Descriptions } from 'antd';
import { useHistory } from 'react-router';
import withStyles from 'react-jss';
import startCase from 'lodash/startCase';
import { useDispatch, connect, useSelector } from 'react-redux';
import Structure from '../Structure/Structure';
import styles from '../CommonStyles';
import { successNotification, errorNotification } from '../Snackbar';
import Api from '../Api';
import CenterGrid from '../Components/CenterGrid';
import GetField from '../Components/GetField';
import { modules } from '../constants';
import { getRole, clearRole } from '../Actions';
import Heading from '../Components/Heading';
import { get } from '../utils';

const AntInput = GetField(Input);
const AntSelect = GetField(Select);

const EditRole = props => {
  const { handleSubmit, classes, match } = props;
  const history = useHistory();
  const dispatch = useDispatch();

  const [formValueModal, setFormValuesModal] = useState(false);
  const [confirmLoading, setConfirmLoading] = useState(false);
  const [preview, setPreview] = useState({});
  const FormValues = useSelector(state => getFormValues('EditRole')(state));

  useEffect(() => {
    dispatch(getRole(match.params.id));
    return () => {
      dispatch(clearRole());
    };
  }, []);

  const showFormValuesModal = () => {
    const _preview = {
      role: get(FormValues, ['role'], '--'),
      permissions: '--',
    };

    const permissionsArr = get(FormValues, ['permissions'], null);
    if (permissionsArr) {
      _preview.permissions = permissionsArr.map(permission => startCase(permission)).join(', ');
    }

    setPreview(_preview);
    setFormValuesModal(true);
  };

  const hideFormValuesModal = () => {
    setFormValuesModal(false);
    setPreview({});
  };

  const onSubmit = async () => {
    try {
      setConfirmLoading(true);
      await Api.put(`/roles/${match.params.id}`, FormValues);
      successNotification('Role updated');
      setConfirmLoading(false);
      hideFormValuesModal();
      history.push('/role/list');
    } catch (err) {
      setConfirmLoading(false);
      errorNotification(err);
    }
  };

  return (
    <Structure>
      <>
        <Heading text="Edit Role" />
        <form onSubmit={handleSubmit(onSubmit)}>
          <Row>
            <CenterGrid>
              <Field label="Role" required allowClear name="role" placeholder="Role" component={AntInput} />
            </CenterGrid>
          </Row>
          <Row>
            <CenterGrid>
              <Field
                label="Permissions"
                required
                name="permissions"
                mode="multiple"
                allowClear
                placeholder="Select permissions"
                component={AntSelect}
              >
                {modules.map(module => (
                  <Select.Option key={module} value={module}>
                    {startCase(module)}
                  </Select.Option>
                ))}
              </Field>
            </CenterGrid>
          </Row>
          <Row className={classes.buttonContainer}>
            <Button className={classes.button} type="primary" onClick={showFormValuesModal}>
              Preview
            </Button>
          </Row>
        </form>
        <Modal
          onCancel={hideFormValuesModal}
          open={formValueModal}
          confirmLoading={confirmLoading}
          centered
          width="800px"
          maskClosable={false}
          closable={false}
          cancelText="Back"
          okButtonProps={{ className: classes.button }}
          okText={
            <Popconfirm
              title="Sure want to submit form ?"
              onConfirm={() => onSubmit()}
              okText="Confirm"
              cancelText="Cancel"
            >
              Submit
            </Popconfirm>
          }
        >
          <Descriptions column={1} size="small" bordered>
            <Descriptions.Item label="Role">{preview.role}</Descriptions.Item>
            <Descriptions.Item label="Permissions">{preview.permissions}</Descriptions.Item>
          </Descriptions>
        </Modal>
      </>
    </Structure>
  );
};

EditRole.propTypes = {
  handleSubmit: PropTypes.func.isRequired,
  classes: PropTypes.object.isRequired,
  match: PropTypes.object.isRequired,
};

const StyledEditRole = withStyles(styles)(EditRole);

const FormedEditRole = reduxForm({
  form: 'EditRole',
  enableReinitialize: true,
})(StyledEditRole);

export default connect(({ role }) => ({ initialValues: role }), {})(FormedEditRole);

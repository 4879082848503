import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { useSelector, useDispatch } from 'react-redux';
import withStyles from 'react-jss';
import { Table, Popconfirm, Tooltip } from 'antd';
import { DeleteOutlined } from '@ant-design/icons';
import moment from 'moment';
import Structure from '../Structure/Structure';
import { dateFilterDropdown, dateSorter, filterDropdown, onDateFilter, onFilter, sorter } from '../utils';
import Api from '../Api';
import { errorNotification, successNotification } from '../Snackbar';
import styles from '../CommonStyles';
import Heading from '../Components/Heading';
import { getHolidays, clearHolidays } from '../Actions';

const HolidayList = props => {
  const { classes } = props;
  const dispatch = useDispatch();

  const holidays = useSelector(state => state.holidays);

  useEffect(() => {
    dispatch(getHolidays());
    return () => {
      dispatch(clearHolidays());
    };
  }, []);

  const onDelete = async id => {
    try {
      await Api.delete(`/holidays/${id}`);
      successNotification('Holiday deleted');
      dispatch(getHolidays());
    } catch (err) {
      errorNotification(err);
    }
  };

  const renderDelete = record => (
    <Tooltip placement="bottom" title="Delete">
      <Popconfirm
        title="Are you sure you want to delete?"
        onConfirm={() => onDelete(record.id)}
        okText="Yes"
        cancelText="No"
      >
        <DeleteOutlined className={classes.tableIcon} />
      </Popconfirm>
    </Tooltip>
  );

  const columns = [
    {
      title: 'Description',
      dataIndex: 'description',
      sorter: sorter('description'),
      filterDropdown,
      onFilter: onFilter('description'),
    },
    {
      title: 'Date',
      dataIndex: 'holiday',
      sorter: dateSorter('holiday'),
      filterDropdown: dateFilterDropdown,
      onFilter: onDateFilter('holiday'),
      render: holiday => (holiday ? moment(holiday).format('ddd DD MMM YYYY') : null),
    },
    {
      title: 'Actions',
      width: 70,
      render: renderDelete,
    },
  ];

  return (
    <Structure>
      <>
        <Heading text="Holidays List" />
        <Table
          columns={columns}
          bordered
          rowKey={record => record.id}
          pagination={{ defaultPageSize: 20, position: ['bottomLeft'], showTotal: total => `Total ${total} Items` }}
          dataSource={holidays}
          size="small"
          scroll={{ x: 1050, y: window.innerHeight - 300 }}
        />
      </>
    </Structure>
  );
};

HolidayList.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(HolidayList);

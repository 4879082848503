import React from 'react';
import PropTypes from 'prop-types';
import { Row } from 'antd';
import withStyles from 'react-jss';
import moment from 'moment';
import styles from '../CommonStyles';
import { get } from '../utils';

const Ledger = props => {
  const { classes, statement, statementData, ledgerData, Reference } = props;

  const dueData = get(ledgerData, ['dues'], []);

  const openingBalance = get(ledgerData, ['openingBalance'], 0);
  let debitTotal = 0;
  let creditTotal = 0;
  let closingBalance = 0;
  debitTotal = get(ledgerData, ['expenseTotal'], 0);
  creditTotal = get(ledgerData, ['incomeTotal'], 0);
  closingBalance = debitTotal + openingBalance - creditTotal;

  return (
    <div ref={Reference}>
      <Row className={`${classes.plusFormulatioHeadinng} ${classes.fontWeight}`}>
        <span>{get(statementData, ['name'], '--')}</span>
      </Row>
      <Row className={classes.plusFormulatioHeadinng}>
        {statement === 'Customer' ? (
          <span>{get(statementData, ['billingAddress'], '--')}</span>
        ) : (
          <span>{get(statementData, ['address'], '--')}</span>
        )}
      </Row>
      <Row className={classes.plusFormulatioHeadinng}>
        <span>{get(statementData, ['state'], '--')}</span>
      </Row>
      <Row className={classes.plusFormulatioHeadinng}>
        <span>GSTIN: {get(statementData, ['gstNo'], '--')}</span>
      </Row>
      <Row className={classes.plusFormulatioHeadinng}>
        <span>
          {get(ledgerData, ['allTransactions', 0, 'ts'])
            ? moment(get(ledgerData, ['allTransactions', 0, 'ts'])).format('DD MMM YYYY')
            : '--'}{' '}
          to {moment().format('DD MMM YYYY')}
        </span>
      </Row>
      <Row className={classes.plusFormulatioHeadinng}>Ledger</Row>
      <br />
      <Row>
        <table className={classes.inputTable}>
          <thead className={classes.inputThead}>
            <tr key="header">
              <th className={classes.inputTh}>Date</th>
              <th className={classes.inputTh}>Consignor</th>
              <th className={classes.inputTh}>Description</th>
              <th className={classes.inputTh}>Debit</th>
              <th className={classes.inputTh}>Credit</th>
            </tr>
          </thead>
          <tbody>
            {get(ledgerData, ['allTransactions'], []).map((item, index) => (
              <tr key={index}>
                <td className={classes.inputTd} style={{ textAlign: 'center' }}>
                  {item.ts ? moment(item.ts).format('DD MMM YYYY') : null}
                </td>
                <td className={classes.inputTd}>{item.consignor ? item.consignor : 'To be paid'}</td>
                <td className={classes.inputTd} style={{ textAlign: 'center' }}>
                  {item.description || ''}
                </td>
                <td className={classes.inputTd} style={{ textAlign: 'center' }}>
                  {item.type === 'expense' ? Number(item.amount).toLocaleString('en-IN') : ''}
                </td>
                <td className={classes.inputTd} style={{ textAlign: 'center' }}>
                  {item.type === 'income' ? Number(item.amount).toLocaleString('en-IN') : ''}
                </td>
              </tr>
            ))}
            <tr>
              <td className={classes.inputTd} colSpan={3}>
                Opening Balance
              </td>
              <td className={classes.inputTd} colSpan={2} style={{ textAlign: 'center' }}>
                {Number(openingBalance.toFixed(2)).toLocaleString('en-IN')}
              </td>
            </tr>
            <tr>
              <td className={classes.inputTd} colSpan={3}>
                Total
              </td>
              <td className={classes.inputTd} style={{ textAlign: 'center' }}>
                {Number(debitTotal.toFixed(2)).toLocaleString('en-IN')}
              </td>
              <td className={classes.inputTd} style={{ textAlign: 'center' }}>
                {Number(creditTotal.toFixed(2)).toLocaleString('en-IN')}
              </td>
            </tr>
            <tr style={{ color: 'red' }}>
              <td className={classes.inputTd} colSpan={3}>
                Closing Balance
              </td>
              <td className={classes.inputTd} style={{ textAlign: 'center' }} colSpan={2}>
                {Number(closingBalance.toFixed(2)).toLocaleString('en-IN')}
              </td>
            </tr>
            <tr>
              <td className={classes.inputTd} colSpan={3}>
                Overdue
              </td>
              <td className={classes.inputTd} colSpan={2} style={{ textAlign: 'center' }}>
                {Number(get(ledgerData, ['overdue'], 0))
                  .toFixed(2)
                  .toLocaleString('en-IN')}
              </td>
            </tr>
          </tbody>
        </table>
      </Row>
      {dueData.length ? (
        <>
          <br />
          <Row className={classes.plusFormulatioHeadinng}>
            <span>Dues</span>
          </Row>
          <Row>
            <table className={classes.inputTable}>
              <thead className={classes.inputThead}>
                <tr key="header">
                  <th className={classes.inputTh}>Date</th>
                  <th className={classes.inputTh}>Amount</th>
                </tr>
              </thead>
              <tbody>
                {dueData.map((due, index) => (
                  <tr key={index} style={{ color: 'rgb(179,134,0)', fontWeight: 600, textAlign: 'center' }}>
                    <td className={classes.inputTd}>{due.date ? moment(due.date).format('DD MMM YYYY') : ''}</td>
                    <td className={classes.inputTd}>{Number(due.amount).toLocaleString('en-IN')}</td>
                  </tr>
                ))}
              </tbody>
            </table>
          </Row>
        </>
      ) : null}
    </div>
  );
};

Ledger.propTypes = {
  classes: PropTypes.object.isRequired,
  statement: PropTypes.string,
  statementData: PropTypes.object,
  ledgerData: PropTypes.object,
  Reference: PropTypes.any,
};

export default withStyles(styles)(Ledger);

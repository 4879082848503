export const sentWhatsAppMessages = (state = [], action) => {
  if (action.type === 'FETCH_SENT_WHATSAPP_MESSAGES') {
    return action.payload;
  }
  if (action.type === 'CLEAR_WHATSAPP_MESSAGES') {
    return [];
  }
  return state;
};

export const receivedWhatsAppMessages = (state = [], action) => {
  if (action.type === 'FETCH_RECEIVED_WHATSAPP_MESSAGES') {
    return action.payload;
  }
  if (action.type === 'CLEAR_WHATSAPP_MESSAGES') {
    return [];
  }
  return state;
};

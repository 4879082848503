export const bankList = (state = [], action) => {
  if (action.type === 'FETCH_BANK_LIST') {
    return action.payload;
  }
  if (action.type === 'CLEAR_BANK_LIST') {
    return [];
  }
  return state;
};

export const transactionList = (state = [], action) => {
  if (action.type === 'FETCH_TRANSACTION_LIST') {
    return action.payload;
  }
  if (action.type === 'CLEAR_TRANSACTION_LIST') {
    return [];
  }
  return state;
};

export const transaction = (state = {}, action) => {
  if (action.type === 'FETCH_TRANSACTION') {
    return action.payload;
  }
  if (action.type === 'CLEAR_TRANSACTION') {
    return {};
  }
  return state;
};

export const financialSummary = (state = {}, action) => {
  if (action.type === 'FETCH_FINANCIAL_SUMMARY') {
    return action.payload;
  }
  if (action.type === 'CLEAR_FINANCIAL_SUMMARY') {
    return {};
  }
  return state;
};

export const balanceSheet = (state = {}, action) => {
  if (action.type === 'FETCH_BALANCE_SHEET') {
    return action.payload;
  }
  if (action.type === 'CLEAR_BALANCE_SHEET') {
    return {};
  }
  return state;
};

export const mis = (state = {}, action) => {
  if (action.type === 'FETCH_FINANCE_MIS') {
    return action.payload;
  }
  if (action.type === 'CLEAR_FINANCE_MIS') {
    return {};
  }
  return state;
};

export const transactionFileList = (state = [], action) => {
  if (action.type === 'FETCH_TRANSACTION_FILE_DATE_WISE_LIST') {
    return action.payload;
  }
  if (action.type === 'CLEAR_TRANSACTION_FILE_DATE_WISE_LIST') {
    return [];
  }
  return state;
};

export const transactionFiles = (state = [], action) => {
  if (action.type === 'FETCH_TRANSACTION_FILES_LIST') {
    return action.payload;
  }
  if (action.type === 'CLEAR_TRANSACTION_FILES_LIST') {
    return [];
  }
  return state;
};

export const weeklyCollections = (state = [], action) => {
  if (action.type === 'FETCH_WEEKLY_COLLECTIONS') {
    return action.payload;
  }
  if (action.type === 'CLEAR_WEEKLY_COLLECTIONS') {
    return [];
  }
  return state;
};

import React, { useCallback, useState } from 'react';
import PropTypes from 'prop-types';
import { Col, Input, Modal, Row, Popconfirm } from 'antd';
import { useForm, Controller } from 'react-hook-form';

import Api from '../../Api';
import { errorNotification, successNotification } from '../../Snackbar';
import GetRHFField from '../../Components/GetRHFField';

const AntInput = GetRHFField(Input);

const RejectExtension = props => {
  const { recordData, modalType, hideModal } = props;

  const { control, reset, getValues } = useForm({
    shouldUnregister: false,
  });

  const [confirmLoading, setConfirmLoading] = useState(false);

  const onSubmit = useCallback(async () => {
    try {
      const formValues = getValues();

      setConfirmLoading(true);

      if (modalType === 'rejectTask') {
        await Api.put(`/tasks/extensions/approve/${recordData.id}`, { ...formValues, newEndAt: null });
      } else if (modalType === 'rejectSubTask') {
        await Api.put(`/tasks/extensions/approve/subtasks/${recordData.id}`, { ...formValues, newEndAt: null });
      }
      reset();
      setConfirmLoading(false);
      successNotification('Request rejected');
      hideModal();
    } catch (err) {
      setConfirmLoading(false);
      errorNotification(err);
    }
  }, []);

  return (
    <Modal
      title="Extension Request Rejection"
      confirmLoading={confirmLoading}
      open
      onCancel={hideModal}
      centered
      maskClosable={false}
      cancelText="Back"
      okText={
        <Popconfirm title="Sure want to submit form?" onConfirm={() => onSubmit()} okText="Confirm" cancelText="Cancel">
          Submit
        </Popconfirm>
      }
    >
      <form>
        <Row>
          <Col span={24}>
            <Controller
              name="respondedRemark"
              control={control}
              render={({ field }) => (
                <AntInput allowClear required autoSize={{ minRows: 1, maxRows: 6 }} placeholder="Remark" {...field} />
              )}
            />
          </Col>
        </Row>
      </form>
    </Modal>
  );
};

RejectExtension.propTypes = {
  recordData: PropTypes.object,
  modalType: PropTypes.string,
  hideModal: PropTypes.func.isRequired,
};

export default RejectExtension;

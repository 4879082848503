import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import withStyles from 'react-jss';
import PropTypes from 'prop-types';
import { Button, Col, Modal, Popconfirm, Row, Select, Table, Tabs, Tooltip } from 'antd';
import { Link } from 'react-router-dom';
import { DeleteOutlined, EditOutlined, FileExcelOutlined, FileTextOutlined } from '@ant-design/icons';
import { useForm, Controller } from 'react-hook-form';
import fileDownload from 'js-file-download';

import Structure from '../../Structure/Structure';
import { get, onFilter, sorter, filterDropdown } from '../../utils';
import { getLeads, clearLeads } from '../../Actions';
import styles from '../../CommonStyles';
import constants from '../../constants';
import Heading from '../../Components/Heading';
import Api from '../../Api';
import { errorNotification, successNotification } from '../../Snackbar';
import GetField from '../../Components/GetRHFField';

const AntSelect = GetField(Select);

const { TabPane } = Tabs;

const LeadList = props => {
  const { classes } = props;
  const dispatch = useDispatch();

  const auth = useSelector(state => state.auth);
  const permissions = get(auth, ['permissions'], []);
  const leads = useSelector(state => state.leads);

  const { control, getValues } = useForm();

  const [confirmLoading, setConfirmLoading] = useState(false);
  const [modalView, setModalView] = useState(false);
  const [recordId, setRecordId] = useState(false);

  useEffect(() => {
    dispatch(getLeads({ isDeleted: false }));
    return () => {
      dispatch(clearLeads());
    };
  }, []);

  const onDelete = useCallback(async id => {
    try {
      await Api.delete(`/leads/${id}`);
      successNotification('Lead deleted');
      dispatch(getLeads({ isDeleted: false }));
    } catch (err) {
      errorNotification(err);
    }
  }, []);

  // const showModal = useCallback(id => {
  //   setRecordId(id);
  //   setModalView(true);
  // }, []);

  const hideModal = useCallback(() => {
    setRecordId(null);
    setModalView(false);
    dispatch(getLeads({ isDeleted: false }));
  }, []);

  const onSubmit = async () => {
    try {
      const { status } = getValues();
      setConfirmLoading(true);
      await Api.put(`/leads/status/${recordId}`, { status });
      setConfirmLoading(false);
      hideModal();
      successNotification('Status updated');
    } catch (err) {
      errorNotification(err);
    }
  };

  const fetchTabData = useCallback(key => {
    dispatch(clearLeads());
    dispatch(getLeads({ isDeleted: key }));
  }, []);

  // const updateStatusColumn = useMemo(
  //   () => (_text, record) =>
  //     permissions.includes('editLead') ? (
  //       <Tooltip placement="bottom" title="Update Status">
  //         <FileDoneOutlined onClick={() => showModal(record.id)} />
  //       </Tooltip>
  //     ) : null,
  //   []
  // );

  const editColumn = useMemo(
    () => (_text, record) =>
      permissions.includes('editLead') ? (
        <Tooltip placement="bottom" title="Edit">
          <Link to={`/leads/edit/${record.id}`}>
            <EditOutlined />
          </Link>
        </Tooltip>
      ) : null,
    []
  );

  const deleteColumn = useMemo(
    () => (_text, record) =>
      permissions.includes('deleteLead') ? (
        <Tooltip placement="bottom" title="Delete">
          <Popconfirm
            title="Are you sure you want to delete?"
            onConfirm={() => onDelete(record.id)}
            okText="Yes"
            cancelText="No"
          >
            <DeleteOutlined />
          </Popconfirm>
        </Tooltip>
      ) : null,
    []
  );

  const detailColumn = useMemo(
    () => (_text, record) =>
      (
        <Tooltip placement="bottom" title="Detail">
          <Link to={`/leads/detail/${record.id}`}>
            <FileTextOutlined />
          </Link>
        </Tooltip>
      ),
    []
  );

  const tooltipShow = text => (
    <Tooltip placement="topLeft" title={text}>
      {text}
    </Tooltip>
  );

  const onFileDownload = useCallback(async () => {
    try {
      const { data } = await Api.getFile('/leads/export');

      fileDownload(data, 'leads.csv');
      successNotification('File downloaded');
    } catch (err) {
      errorNotification(err);
    }
  }, []);

  const tooltipShowWithColors = (_text, record) => {
    let color;
    if (record.status) {
      if (record.noOfQueries) {
        color = 'black';
      } else {
        color = 'red';
      }
    }

    return (
      <Tooltip placement="topLeft" title={_text}>
        <span style={{ color }}>{_text}</span>
      </Tooltip>
    );
  };

  const columns = [
    {
      title: 'Company',
      dataIndex: 'company',
      key: 'company',
      sorter: sorter('company'),
      onFilter: onFilter('company'),
      filterDropdown,
      render: tooltipShowWithColors,
    },
    {
      title: 'Name',
      dataIndex: 'name',
      key: 'name',
      sorter: sorter('name'),
      onFilter: onFilter('name'),
      filterDropdown,
      render: tooltipShowWithColors,
    },
    {
      title: 'Mobile',
      dataIndex: 'contact',
      key: 'contact',
      sorter: sorter('contact'),
      onFilter: onFilter('contact'),
      filterDropdown,
      render: tooltipShowWithColors,
    },
    {
      title: 'State',
      dataIndex: 'state',
      key: 'state',
      sorter: sorter('state'),
      onFilter: onFilter('state'),
      filterDropdown,
      render: tooltipShowWithColors,
    },
    {
      title: 'Remark',
      dataIndex: 'remark',
      key: 'remark',
      sorter: sorter('remark'),
      onFilter: onFilter('remark'),
      filterDropdown,
      ellipsis: { showTitle: false },
      render: tooltipShow,
    },
    {
      title: 'Created By',
      dataIndex: 'createdBy',
      key: 'createdBy',
      sorter: sorter('createdBy'),
      onFilter: onFilter('createdBy'),
      filterDropdown,
    },
    {
      title: 'Query #',
      dataIndex: 'noOfQueries',
      key: 'noOfQueries',
      width: 85,
      sorter: true,
      filters: [{ text: '0', value: 0 }],
      render: tooltipShowWithColors,
    },
    {
      title: 'Detail',
      key: 'detail',
      width: '6%',
      render: detailColumn,
    },
  ];

  if (permissions.includes('editLead')) {
    columns.push(
      // {
      //   title: 'Update Status',
      //   width: '6%',
      //   render: updateStatusColumn,
      // },
      {
        title: 'Edit',
        key: 'edit',
        width: '6%',
        render: editColumn,
      }
    );
  }

  if (permissions.includes('deleteLead')) {
    columns.push({
      title: 'Delete',
      key: 'delete',
      width: '6%',
      render: deleteColumn,
    });
  }

  const closedColumns = [
    {
      title: 'Company',
      dataIndex: 'company',
      key: 'company',
      sorter: sorter('company'),
      onFilter: onFilter('company'),
      filterDropdown,
    },
    {
      title: 'Name',
      dataIndex: 'name',
      key: 'name',
      sorter: sorter('name'),
      onFilter: onFilter('name'),
      filterDropdown,
    },
    {
      title: 'Email',
      dataIndex: 'email',
      key: 'email',
      sorter: sorter('email'),
      onFilter: onFilter('email'),
      filterDropdown,
    },
    {
      title: 'Mobile',
      dataIndex: 'contact',
      key: 'contact',
      sorter: sorter('contact'),
      onFilter: onFilter('contact'),
      filterDropdown,
    },
    {
      title: 'Address',
      dataIndex: 'address',
      key: 'address',
      sorter: sorter('address'),
      onFilter: onFilter('address'),
      filterDropdown,
      ellipsis: { showTitle: false },
      render: tooltipShow,
    },
    {
      title: 'City',
      dataIndex: 'city',
      key: 'city',
      sorter: sorter('city'),
      onFilter: onFilter('city'),
      filterDropdown,
    },
    {
      title: 'State',
      dataIndex: 'state',
      key: 'state',
      sorter: sorter('state'),
      onFilter: onFilter('state'),
      filterDropdown,
    },
    {
      title: 'Remark',
      dataIndex: 'remark',
      key: 'remark',
      sorter: sorter('remark'),
      onFilter: onFilter('remark'),
      filterDropdown,
      ellipsis: { showTitle: false },
      render: tooltipShow,
    },
    {
      title: 'Status',
      dataIndex: 'status',
      key: 'status',
      sorter: sorter('status'),
      onFilter: onFilter('status'),
      filterDropdown,
    },
    {
      title: 'Detail',
      key: 'detail',
      width: '6%',
      render: detailColumn,
    },
  ];

  return (
    <Structure>
      <>
        <Heading text="Lead List" />
        <Row>
          <Col>
            <Button icon={<FileExcelOutlined />} onClick={onFileDownload}>
              Export
            </Button>
          </Col>
        </Row>
        <Tabs defaultActiveKey="false" onChange={fetchTabData}>
          <TabPane tab="Active" key="false">
            <Table
              bordered
              size="small"
              columns={columns}
              dataSource={leads}
              rowKey={record => record.id}
              pagination={{
                defaultPageSize: 20,
                position: ['bottomLeft'],
                showTotal: total => `Total ${total} Items`,
              }}
              scroll={{ x: 1024, y: window.innerHeight - 300 }}
            />
          </TabPane>
          <TabPane tab="Deleted" key="true">
            <Table
              bordered
              size="small"
              columns={closedColumns}
              dataSource={leads}
              rowKey={record => record.id}
              pagination={{
                defaultPageSize: 20,
                position: ['bottomLeft'],
                showTotal: total => `Total ${total} Items`,
              }}
              scroll={{ x: 1024, y: window.innerHeight - 300 }}
            />
          </TabPane>
        </Tabs>
        {modalView && recordId ? (
          <Modal
            open
            confirmLoading={confirmLoading}
            maskClosable={false}
            onCancel={hideModal}
            okButtonProps={{ className: classes.button }}
            okText={
              <Popconfirm
                title="Sure want to submit form?"
                onConfirm={() => onSubmit()}
                okText="Confirm"
                cancelText="Cancel"
              >
                Submit
              </Popconfirm>
            }
          >
            <Controller
              name="status"
              control={control}
              render={({ field }) => (
                <AntSelect allowClear required label="Status" placeholder="Select status" {...field}>
                  <Select.Option key={null} value="" disabled>
                    Select status
                  </Select.Option>
                  <Select.Option key={constants.leads.statuses.converted} value={constants.leads.statuses.converted}>
                    {constants.leads.statuses.converted}
                  </Select.Option>
                  <Select.Option key={constants.leads.statuses.closed} value={constants.leads.statuses.closed}>
                    {constants.leads.statuses.closed}
                  </Select.Option>
                </AntSelect>
              )}
            />
          </Modal>
        ) : null}
      </>
    </Structure>
  );
};

LeadList.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(LeadList);

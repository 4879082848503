import Api from '../Api';

export const getUnitList = () => async dispatch => {
  try {
    const { data } = await Api.get('/dropdowns/units');
    dispatch({
      type: 'FETCH_UNIT_LIST',
      payload: data,
    });
  } catch (err) {
    throw err;
  }
};

export const clearUnitList = () => {
  return {
    type: 'CLEAR_UNIT_LIST',
  };
};

export const getUnit = id => async dispatch => {
  try {
    const { data } = await Api.get(`/dropdowns/units/${id}`);
    dispatch({
      type: 'FETCH_UNIT',
      payload: data,
    });
  } catch (err) {
    throw err;
  }
};

export const clearUnit = () => {
  return {
    type: 'CLEAR_UNIT',
  };
};

export const getUnitIdList = () => async dispatch => {
  try {
    const { data } = await Api.get('/dropdowns/units/ids');
    dispatch({
      type: 'FETCH_UNIT_ID_LIST',
      payload: data,
    });
  } catch (err) {
    throw err;
  }
};

export const clearUnitIdList = () => {
  return {
    type: 'CLEAR_UNIT_ID_LIST',
  };
};

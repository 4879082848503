export const materialList = (state = [], action) => {
  if (action.type === 'FETCH_MATERIAL_LIST') {
    return action.payload;
  }
  if (action.type === 'CLEAR_MATERIAL_LIST') {
    return [];
  }
  return state;
};

export const material = (state = {}, action) => {
  if (action.type === 'FETCH_MATERIAL') {
    return action.payload;
  }
  if (action.type === 'CLEAR_MATERIAL') {
    return {};
  }
  return state;
};

export const materialIdList = (state = [], action) => {
  if (action.type === 'FETCH_MATERIAL_ID_LIST') {
    return action.payload;
  }
  if (action.type === 'CLEAR_MATERIAL_ID_LIST') {
    return [];
  }
  return state;
};

export const materialTransactions = (state = [], action) => {
  if (action.type === 'FETCH_MATERIAL_TRANSACTIONS') {
    return action.payload;
  }
  if (action.type === 'CLEAR_MATERIAL_TRANSACTIONS') {
    return [];
  }
  return state;
};

/* eslint-disable no-nested-ternary */
import React, { useEffect, useMemo } from 'react';
import { Table } from 'antd';
import { useDispatch, useSelector } from 'react-redux';
import moment from 'moment';
import { clearOrdersFMS, getOrdersFMS } from '../Actions';
import { onFilter, sorter, filterDropdown, dateSorter, dateFilterDropdown, onDateFilter } from '../utils';
import Structure from '../Structure/Structure';
import Heading from '../Components/Heading';

const OrdersFMS = () => {
  const dispatch = useDispatch();

  const ordersFMS = useSelector(state => state.ordersFMS);

  useEffect(() => {
    dispatch(getOrdersFMS());
    return () => {
      dispatch(clearOrdersFMS());
    };
  }, []);

  const columns = useMemo(() => {
    const _columns = [
      {
        title: 'Order #',
        dataIndex: 'id',
        sorter: sorter('id'),
        onFilter: onFilter('id'),
        filterDropdown,
        fixed: 'left',
        width: '5%',
      },
      {
        title: 'Finished Good Name',
        dataIndex: 'formulation',
        sorter: sorter('formulation'),
        onFilter: onFilter('formulation'),
        filterDropdown,
        fixed: 'left',
      },
      {
        title: 'Customer',
        dataIndex: 'customer',
        sorter: sorter('customer'),
        onFilter: onFilter('customer'),
        filterDropdown,
      },
      {
        title: 'Unit',
        dataIndex: 'unit',
        sorter: sorter('unit'),
        onFilter: onFilter('unit'),
        filterDropdown,
        width: '4%',
      },
      {
        title: 'Quantity',
        dataIndex: 'quantity',
        sorter: sorter('quantity'),
        onFilter: onFilter('quantity'),
        filterDropdown,
        width: '6%',
      },
      {
        title: 'Authorized Date',
        dataIndex: 'authorizedAt',
        sorter: dateSorter('authorizedAt'),
        onFilter: onDateFilter('authorizedAt'),
        filterDropdown: dateFilterDropdown,
        render: date => (date ? moment(date).format('DD MMM YYYY') : null),
      },
      {
        title: 'Design (3 day)',
        children: [
          {
            title: 'Planned',
            width: '5%',
            dataIndex: 'plannedDesign',
            sorter: dateSorter('plannedDesign'),
            onFilter: onDateFilter('plannedDesign'),
            filterDropdown: dateFilterDropdown,
            render: date => (date ? moment(date).format('DD MMM YYYY') : 'NA'),
          },
          {
            title: 'Actual',
            width: '5%',
            dataIndex: 'actualDesign',
            sorter: dateSorter('actualDesign'),
            onFilter: onDateFilter('actualDesign'),
            filterDropdown: dateFilterDropdown,
            render: date => (date ? moment(date).format('DD MMM YYYY') : 'NA'),
          },
          {
            title: 'Status',
            width: '5%',
            render: (_text, record) =>
              record.actualDesign ? (
                moment(record.actualDesign).diff(record.plannedDesign) > 0 ? (
                  <span style={{ color: 'red' }}>Delay</span>
                ) : (
                  'On-time'
                )
              ) : (
                'NA'
              ),
          },
        ],
      },
      {
        title: 'Printed Packing (5 day)',
        children: [
          {
            title: 'Planned',
            width: '5%',
            dataIndex: 'plannedPrintedPo',
            sorter: dateSorter('plannedPrintedPo'),
            onFilter: onDateFilter('plannedPrintedPo'),
            filterDropdown: dateFilterDropdown,
            render: date => (date ? moment(date).format('DD MMM YYYY') : null),
          },
          {
            title: 'Actual',
            width: '5%',
            dataIndex: 'actualPrintedPo',
            sorter: dateSorter('actualPrintedPo'),
            onFilter: onDateFilter('actualPrintedPo'),
            filterDropdown: dateFilterDropdown,
            render: date => (date ? moment(date).format('DD MMM YYYY') : null),
          },
          {
            title: 'Status',
            width: '5%',
            render: (_text, record) =>
              record.actualPrintedPo ? (
                moment(record.actualPrintedPo).diff(record.plannedPrintedPo) > 0 ? (
                  <span style={{ color: 'red' }}>Delay</span>
                ) : (
                  'On-time'
                )
              ) : null,
          },
        ],
      },
      {
        title: 'Sale (27 day)',
        children: [
          {
            title: 'Planned',
            dataIndex: 'plannedSale',
            width: '5%',
            sorter: dateSorter('plannedSale'),
            onFilter: onDateFilter('plannedSale'),
            filterDropdown: dateFilterDropdown,
            render: date => (date ? moment(date).format('DD MMM YYYY') : null),
          },
          {
            title: 'Actual',
            dataIndex: 'actualSale',
            width: '5%',
            sorter: dateSorter('actualSale'),
            onFilter: onDateFilter('actualSale'),
            filterDropdown: dateFilterDropdown,
            render: date => (date ? moment(date).format('DD MMM YYYY') : null),
          },
          {
            title: 'Status',
            width: '5%',
            render: (_text, record) =>
              record.actualSale ? (
                moment(record.actualSale).diff(record.plannedSale) > 0 ? (
                  <span style={{ color: 'red' }}>Delay</span>
                ) : (
                  'On-time'
                )
              ) : null,
          },
        ],
      },
      {
        title: 'GR Upload (29 day)',
        children: [
          {
            title: 'Planned',
            dataIndex: 'plannedGr',
            width: '5%',
            sorter: dateSorter('plannedGr'),
            onFilter: onDateFilter('plannedGr'),
            filterDropdown: dateFilterDropdown,
            render: date => (date ? moment(date).format('DD MMM YYYY') : null),
          },
          {
            title: 'Actual',
            dataIndex: 'actualGr',
            width: '5%',
            sorter: dateSorter('actualGr'),
            onFilter: onDateFilter('actualGr'),
            filterDropdown: dateFilterDropdown,
            render: date => (date ? moment(date).format('DD MMM YYYY') : null),
          },
          {
            title: 'Status',
            width: '5%',
            render: (_text, record) =>
              record.actualGr ? (
                moment(record.actualGr).diff(record.plannedGr) > 0 ? (
                  <span style={{ color: 'red' }}>Delay</span>
                ) : (
                  'On-time'
                )
              ) : null,
          },
        ],
      },
    ];

    return _columns;
  }, []);

  return (
    <Structure>
      <>
        <Heading text="Order FMS" />
        <Table
          columns={columns}
          bordered
          rowKey={record => record.id}
          dataSource={ordersFMS}
          size="small"
          pagination={{
            defaultPageSize: 20,
            position: ['bottomLeft'],
            showTotal: total => `Total ${total} Items`,
          }}
          scroll={{ x: 2000, y: window.innerHeight - 300 }}
        />
      </>
    </Structure>
  );
};

export default OrdersFMS;

import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { Button, Popconfirm, Row, Table, Tooltip, Upload } from 'antd';
import Modal from 'antd/lib/modal/Modal';
import { CloudUploadOutlined, DeleteOutlined } from '@ant-design/icons';
import { useDispatch, useSelector } from 'react-redux';
import withStyles from 'react-jss';
import Structure from '../Structure/Structure';
import Api from '../Api';
import { errorNotification, infoNotification, successNotification } from '../Snackbar';
import styles from '../CommonStyles';
import { clearFilesList, getFilesList } from '../Actions';

const GeneralFiles = props => {
  const { classes } = props;
  const dispatch = useDispatch();

  const [filesModal, setFilesModal] = useState(false);
  const [fileList, setFileList] = useState([]);
  const [uploading, setUploading] = useState(false);

  const filesList = useSelector(state => state.filesList);

  useEffect(() => {
    dispatch(getFilesList());
    return () => {
      dispatch(clearFilesList());
    };
  }, []);

  const showFileModal = () => {
    setFilesModal(true);
  };

  const hideFileModal = () => {
    setFilesModal(false);
  };

  const onUpload = async () => {
    try {
      setUploading(true);
      const formData = new FormData();
      fileList.forEach((file, index) => {
        formData.append(`files[${index}]`, file);
      });
      const { data } = await Api.post(`/files`, formData, {
        headers: { 'content-type': 'multipart/form-data' },
      });
      infoNotification(data);
      setUploading(false);
      hideFileModal();
      setFileList([]);
      dispatch(getFilesList());
    } catch (err) {
      errorNotification(err);
    }
  };

  const onFileDelete = async id => {
    try {
      await Api.delete(`/files/${id}`);
      successNotification('File delete');
      dispatch(getFilesList());
    } catch (err) {
      errorNotification(err);
    }
  };

  const onRemove = file => {
    const index = fileList.indexOf(file);
    const newFileList = fileList.slice();
    newFileList.splice(index, 1);
    setFileList(newFileList);
  };

  const beforeUpload = (file, files) => {
    setFileList([...fileList, ...files]);
    return false;
  };

  const filesTableColumns = [];

  const renderFileTableNameColumn = record => (
    <a href={record.url} rel="noreferrer noopener" target="_blank">
      <Tooltip placement="bottom" title={`Click to open/download "${record.fileName}" file.`}>
        {record.fileName}
      </Tooltip>
    </a>
  );

  const renderFileTableActionColumn = record => {
    return (
      <Tooltip placement="bottom" title="Delete File">
        <Popconfirm
          title="Are you sure you want to delete?"
          onConfirm={() => onFileDelete(record.id)}
          okText="Yes"
          cancelText="No"
        >
          <DeleteOutlined className={classes.tableIcon} />
        </Popconfirm>
      </Tooltip>
    );
  };

  filesTableColumns.push({
    title: 'File',
    render: renderFileTableNameColumn,
  });

  filesTableColumns.push({
    title: 'Delete',
    render: renderFileTableActionColumn,
    width: 70,
  });

  return (
    <Structure>
      <>
        <Row>
          <Button onClick={() => showFileModal()}>Upload Files</Button>
        </Row>
        <br />
        <Table
          columns={filesTableColumns}
          bordered
          rowKey={record => record.id}
          dataSource={filesList}
          size="small"
          pagination={{ defaultPageSize: 20, position: ['bottomLeft'] }}
          scroll={{ x: 1050, y: window.innerHeight - 300 }}
        />
        <Modal
          onOk={hideFileModal}
          open={filesModal}
          centered
          maskClosable={false}
          title="Upload Files."
          width="500px"
          okText="Close"
          cancelButtonProps={{ className: classes.hide }}
          okButtonProps={{ className: classes.button }}
          closable={false}
        >
          <Upload multiple onRemove={onRemove} beforeUpload={beforeUpload} fileList={fileList}>
            <Button icon={<CloudUploadOutlined />}>Select Files</Button>
          </Upload>
          <Button
            type="primary"
            onClick={onUpload}
            disabled={fileList.length === 0}
            loading={uploading}
            style={{ marginTop: 16 }}
          >
            {uploading ? 'Uploading' : 'Upload'}
          </Button>
        </Modal>
      </>
    </Structure>
  );
};

GeneralFiles.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(GeneralFiles);

import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { reduxForm, Field, getFormValues } from 'redux-form';
import withStyles from 'react-jss';
import { Input, Button, Row, Modal, Select, Col, Popconfirm, DatePicker } from 'antd';
import { useHistory } from 'react-router';
import { useDispatch, useSelector } from 'react-redux';
import moment from 'moment';
import Heading from '../Components/Heading';
import Api from '../Api';
import Structure from '../Structure/Structure';
import styles from '../CommonStyles';
import { successNotification, errorNotification } from '../Snackbar';
import LeftGrid from '../Components/LeftGrid';
import RightGrid from '../Components/RightGrid';
import GetField from '../Components/GetField';
import { get } from '../utils';
import { clearEmployeeRoleIdList, getEmployeeRoleIdList } from '../Actions';

const AntInput = GetField(Input);
const AntSelect = GetField(Select);

const AntDatePicker = GetField(DatePicker);

const NewEmployee = props => {
  const { handleSubmit, classes } = props;
  const history = useHistory();
  const dispatch = useDispatch();

  const employeeRoleIdList = useSelector(state => state.employeeRoleIdList);

  const [formValueModal, setFormValuesModal] = useState(false);
  const [confirmLoading, setConfirmLoading] = useState(false);
  const [preview, setPreview] = useState({});
  const FormValues = useSelector(state => getFormValues('NewEmployee')(state));

  useEffect(() => {
    dispatch(getEmployeeRoleIdList());
    return () => {
      dispatch(clearEmployeeRoleIdList());
    };
  }, []);

  const showFormValuesModal = () => {
    const joiningDate = get(FormValues, ['joiningDate']);

    const _preview = {
      name: get(FormValues, ['name'], '--'),
      aadhar: get(FormValues, ['aadhar'], '--'),
      address: get(FormValues, ['address'], '--'),
      corrAddress: get(FormValues, ['corrAddress'], '--'),
      mobile: get(FormValues, ['mobile'], '--'),
      alternateMobile: get(FormValues, ['alternateMobile'], '--'),
      emergencyName: get(FormValues, ['emergencyName'], '--'),
      emergencyMobile: get(FormValues, ['emergencyMobile'], '--'),
      joiningDate: joiningDate ? moment(joiningDate).format('DD MMM YYYY') : '--',
      referredBy: get(FormValues, ['referredBy'], '--'),
      salary: get(FormValues, ['salary'], '--'),
      role: get(FormValues, ['role'], '--'),
      status: get(FormValues, ['status'], '--'),
    };

    const roleId = get(FormValues, ['roleId'], null);
    if (roleId) {
      const roleObj = employeeRoleIdList.find(unit => unit.id === +roleId);
      if (roleObj) {
        _preview.role = roleObj.role;
      }
    }

    setPreview(_preview);
    setFormValuesModal(true);
  };

  const hideFormValuesModal = () => {
    setFormValuesModal(false);
    setPreview({});
  };

  const onSubmit = async () => {
    try {
      setConfirmLoading(true);
      await Api.post('/employees', FormValues);
      successNotification('Employee added');
      setConfirmLoading(false);
      hideFormValuesModal();
      history.push('/employee/list');
    } catch (err) {
      setConfirmLoading(false);
      errorNotification(err);
    }
  };

  return (
    <Structure>
      <>
        <Heading text="New Employee" />
        <form onSubmit={handleSubmit(onSubmit)}>
          <Row>
            <LeftGrid>
              <Field label="Name" required allowClear name="name" placeholder="Name" component={AntInput} />
            </LeftGrid>
            <RightGrid>
              <Field
                label="Aadhar Number"
                required
                allowClear
                name="aadhar"
                placeholder="Aadhar Number"
                component={AntInput}
              />
            </RightGrid>
          </Row>
          <Row>
            <LeftGrid>
              <Field label="Address" allowClear name="address" placeholder="Address" component={AntInput} />
            </LeftGrid>
            <RightGrid>
              <Field
                label="Correspondence Address"
                allowClear
                name="corrAddress"
                placeholder="Correspondence Address"
                component={AntInput}
              />
            </RightGrid>
          </Row>
          <Row>
            <LeftGrid>
              <Field label="Mobile" required allowClear name="mobile" placeholder="Mobile" component={AntInput} />
            </LeftGrid>
            <RightGrid>
              <Field
                label="Alternate Mobile"
                allowClear
                name="alternateMobile"
                placeholder="Alternate Mobile"
                component={AntInput}
              />
            </RightGrid>
          </Row>
          <Row>
            <LeftGrid>
              <Field
                label="Emergency Name"
                allowClear
                name="emergencyName"
                placeholder="Emergency Name"
                component={AntInput}
              />
            </LeftGrid>
            <RightGrid>
              <Field
                label="Emergency Mobile"
                allowClear
                name="emergencyMobile"
                placeholder="Emergency Mobile"
                component={AntInput}
              />
            </RightGrid>
          </Row>
          <Row>
            <LeftGrid>
              <Field
                allowClear
                required
                label="Joining Date"
                name="joiningDate"
                placeholder="Joining Date"
                component={AntDatePicker}
              />
            </LeftGrid>
            <RightGrid>
              <Field label="Referred By" allowClear name="referredBy" placeholder="Referred By" component={AntInput} />
            </RightGrid>
          </Row>
          <Row>
            <LeftGrid>
              <Field
                label="Salary per month"
                required
                type="number"
                min={0}
                allowClear
                name="salary"
                placeholder="Salary per month"
                component={AntInput}
              />
            </LeftGrid>
            <RightGrid>
              <Field label="Role" required allowClear name="roleId" component={AntSelect}>
                <Select.Option key={null} value="" disabled>
                  Select role
                </Select.Option>
                {employeeRoleIdList.map(role => (
                  <Select.Option key={role.id} value={role.id}>
                    {role.role}
                  </Select.Option>
                ))}
              </Field>
            </RightGrid>
          </Row>
          <Row>
            <LeftGrid>
              <Field label="Status" allowClear required name="status" component={AntSelect}>
                <Select.Option key={null} value="" disabled>
                  Select status
                </Select.Option>
                <Select.Option key="active" value="Active">
                  Active
                </Select.Option>
                <Select.Option key="inactive" value="Inactive">
                  Inactive
                </Select.Option>
              </Field>
            </LeftGrid>
          </Row>
          <Row className={classes.buttonContainer}>
            <Button className={classes.button} type="primary" onClick={showFormValuesModal}>
              Preview
            </Button>
          </Row>
        </form>
        <Modal
          onCancel={hideFormValuesModal}
          open={formValueModal}
          confirmLoading={confirmLoading}
          centered
          width="800px"
          maskClosable={false}
          closable={false}
          cancelText="Back"
          okButtonProps={{ className: classes.button }}
          okText={
            <Popconfirm
              title="Sure want to submit form ?"
              onConfirm={() => onSubmit()}
              okText="Confirm"
              cancelText="Cancel"
            >
              Submit
            </Popconfirm>
          }
        >
          <Row>
            <Col xs={24} lg={12}>
              <p>
                Name:
                <span className={classes.detailColor}>{preview.name}</span>
              </p>
            </Col>
            <Col xs={24} lg={12}>
              <p>
                Aadhar Number:
                <span className={classes.detailColor}>{preview.aadhar}</span>
              </p>
            </Col>
          </Row>
          <Row>
            <Col xs={24} lg={12}>
              <p>
                Address:
                <span className={classes.detailColor}>{preview.address}</span>
              </p>
            </Col>
            <Col xs={24} lg={12}>
              <p>
                Correspondence Address:
                <span className={classes.detailColor}>{preview.corrAddress}</span>
              </p>
            </Col>
          </Row>
          <Row>
            <Col xs={24} lg={12}>
              <p>
                Primary Contact:
                <span className={classes.detailColor}>{preview.mobile}</span>
              </p>
            </Col>
            <Col xs={24} lg={12}>
              <p>
                Alternate Contact:
                <span className={classes.detailColor}>{preview.alternateMobile}</span>
              </p>
            </Col>
          </Row>
          <Row>
            <Col xs={24} lg={12}>
              <p>
                Emergency Name:
                <span className={classes.detailColor}>{preview.emergencyName}</span>
              </p>
            </Col>
            <Col xs={24} lg={12}>
              <p>
                Emergency Mobile:
                <span className={classes.detailColor}>{preview.emergencyMobile}</span>
              </p>
            </Col>
          </Row>
          <Row>
            <Col xs={24} lg={12}>
              <p>
                Joining Date:
                <span className={classes.detailColor}>{preview.joiningDate}</span>
              </p>
            </Col>
            <Col xs={24} lg={12}>
              <p>
                Referred By:
                <span className={classes.detailColor}>{preview.referredBy}</span>
              </p>
            </Col>
          </Row>
          <Row>
            <Col xs={24} lg={12}>
              <p>
                Salary/Month:
                <span className={classes.detailColor}>{preview.salary}</span>
              </p>
            </Col>
            <Col xs={24} lg={12}>
              <p>
                Role:
                <span className={classes.detailColor}>{preview.role}</span>
              </p>
            </Col>
          </Row>
          <Row>
            <Col xs={24} lg={12}>
              <p>
                Status:
                <span className={classes.detailColor}>{preview.status}</span>
              </p>
            </Col>
          </Row>
        </Modal>
      </>
    </Structure>
  );
};

NewEmployee.propTypes = {
  handleSubmit: PropTypes.func.isRequired,
  classes: PropTypes.object.isRequired,
};

const StyledNewEmployee = withStyles(styles)(NewEmployee);

const FormedNewEmployee = reduxForm({
  form: 'NewEmployee',
})(StyledNewEmployee);

export default FormedNewEmployee;

import React, { useEffect, useState } from 'react';
import moment from 'moment';
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';
import withStyles from 'react-jss';
import { Popconfirm, Table, Tooltip, Modal, Upload, Button, Row, Col, Select } from 'antd';
import { Link } from 'react-router-dom';
import {
  CloudDownloadOutlined,
  CloudUploadOutlined,
  DeleteOutlined,
  EditOutlined,
  FileDoneOutlined,
} from '@ant-design/icons';
import startCase from 'lodash/startCase';
import fileDownload from 'js-file-download';
import Structure from '../Structure/Structure';
import { filterDropdown, get, onFilter, sorter, dateSorter } from '../utils';
import Api from '../Api';
import { errorNotification, errorNotificationOnBlob, infoNotification, successNotification } from '../Snackbar';
import styles from '../CommonStyles';
import Heading from '../Components/Heading';
import {
  getPurchaseOrderList,
  clearPurchaseOrderList,
  getPurchaseOrderFilesList,
  clearPurchaseOrderFilesList,
} from '../Actions';
import constants, { materialTypes } from '../constants';

const PurchaseOrderList = props => {
  const { classes } = props;
  const dispatch = useDispatch();

  const auth = useSelector(state => state.auth);
  const purchaseOrderFilesList = useSelector(state => state.purchaseOrderFilesList);
  const purchaseOrderList = useSelector(state => state.purchaseOrderList);

  const permissions = get(auth, ['permissions'], []);

  const [rowCounts, setRowCounts] = useState(0);
  const [purchaseOrderId, setPurchaseOrderId] = useState(null);
  const [modalType, setModalType] = useState(null);
  const [fileList, setFileList] = useState([]);
  const [uploading, setUploading] = useState(false);

  const [financialYear, setFinancialYear] = useState('2024');
  const [Consignor, SetConsignor] = useState(constants.consignors.list.plusFormulationsHeadOffice);

  useEffect(() => {
    // dispatch(getPurchaseOrderList());
    return () => {
      dispatch(clearPurchaseOrderList());
    };
  }, []);

  useEffect(() => {
    dispatch(getPurchaseOrderList({ consignor: Consignor, financialYear }));
  }, [financialYear, Consignor]);

  useEffect(() => {
    if (purchaseOrderId && modalType === 'ViewFiles') {
      dispatch(getPurchaseOrderFilesList(purchaseOrderId));
    }
  }, [purchaseOrderId, modalType]);

  useEffect(() => {
    if (purchaseOrderList) {
      setRowCounts(purchaseOrderList.length);
    }
  }, [purchaseOrderList]);

  const exportPOList = async () => {
    try {
      const { data } = await Api.getFile(`/purchase-orders/export`, { consignor: Consignor, financialYear });
      fileDownload(data, `purchase-orders.xlsx`);
    } catch (error) {
      errorNotificationOnBlob(error);
    }
  };

  const onDelete = async id => {
    try {
      await Api.delete(`/purchase-orders/${id}`);
      successNotification('Purchase Order deleted');
      dispatch(getPurchaseOrderList({ consignor: Consignor, financialYear }));
    } catch (err) {
      errorNotification(err);
    }
  };

  const onForceCompletePO = async id => {
    try {
      await Api.put(`/purchase-orders/complete/${id}`);
      successNotification('Purchase Order completed');
      dispatch(getPurchaseOrderList({ consignor: Consignor, financialYear }));
    } catch (err) {
      errorNotification(err);
    }
  };

  const tooltipShowWithColors = (arg, record) => {
    let color;
    if (record.status) {
      if (record.status === 'Received') {
        color = 'Green';
      } else if (record.status === 'Pending') {
        color = 'red';
      } else {
        color = '#096dd9';
      }
    }

    return (
      <Tooltip placement="topLeft" title={materialTypes.includes(`${arg}`) ? startCase(arg) : arg}>
        <span style={{ color }}>{materialTypes.includes(`${arg}`) ? startCase(arg) : arg}</span>
      </Tooltip>
    );
  };

  const tooltipShowWithColorsInINRFormat = (arg, record) => {
    let color;
    if (record.status) {
      if (record.status === 'Received') {
        color = 'Green';
      } else if (record.status === 'Pending') {
        color = 'red';
      } else {
        color = '#096dd9';
      }
    }

    return (
      <Tooltip placement="topLeft" title={arg}>
        <span style={{ color }}>{Number(arg).toLocaleString('en-IN')}</span>
      </Tooltip>
    );
  };

  const dateTooltipShowWithColors = (arg, record) => {
    let color;
    if (record.status) {
      if (record.status === 'Received') {
        color = 'Green';
      } else if (record.status === 'Pending') {
        color = 'red';
      } else {
        color = '#096dd9';
      }
    }

    return (
      <Tooltip placement="topLeft" title={moment(arg).format('DD MMM YYYY')}>
        <span style={{ color }}>{moment(arg).format('DD MMM YYYY')}</span>
      </Tooltip>
    );
  };

  // const tooltipShowInsideObjectWithColors = (keys, arg, record) => {
  //   let color;
  //   if (record.status) {
  //     if (record.status === 'Received') {
  //       color = 'Green';
  //     } else if (record.status === 'Pending') {
  //       color = 'red';
  //     } else {
  //       color = '#096dd9';
  //     }
  //   }
  //   const value = get(arg, keys, null);

  //   return (
  //     <Tooltip placement="topLeft" title={materialTypes.includes(`${value}`) ? startCase(value) : value}>
  //       <span style={{ color }}>{materialTypes.includes(`${value}`) ? startCase(value) : value}</span>
  //     </Tooltip>
  //   );
  // };

  const showModal = (id, type) => {
    setPurchaseOrderId(id);
    setModalType(type);
  };

  const hideModal = () => {
    setPurchaseOrderId(null);
    setModalType(null);
    setFileList([]);
    dispatch(clearPurchaseOrderFilesList());
  };

  const renderPurchaseUploadColumn = record => (
    <Tooltip placement="bottom" title="Upload Files">
      <CloudUploadOutlined className={classes.tableIcon} onClick={() => showModal(record.id, 'UploadFiles')} />
    </Tooltip>
  );

  const renderPurchaseViewColumn = record => {
    let color;
    if (record.files) {
      if (record.files > 0) {
        color = 'Green';
      } else {
        color = 'Red';
      }
    }
    return (
      <Tooltip placement="bottom" title="View Files">
        <CloudDownloadOutlined
          style={{ color }}
          className={classes.tableIcon}
          onClick={() => showModal(record.id, 'ViewFiles')}
        />
      </Tooltip>
    );
  };

  const onUpload = async () => {
    try {
      setUploading(true);
      const formData = new FormData();
      fileList.forEach((file, index) => {
        formData.append(`files[${index}]`, file);
      });
      const { data } = await Api.post(`/purchase-orders/${purchaseOrderId}/files`, formData, {
        headers: { 'content-type': 'multipart/form-data' },
      });
      infoNotification(data);
      setUploading(false);
      hideModal();
      setFileList([]);
    } catch (err) {
      errorNotification(err);
    }
  };

  const onFileDelete = async id => {
    try {
      await Api.delete(`/purchase-orders/${purchaseOrderId}/files/${id}`);
      successNotification('File delete');
      dispatch(getPurchaseOrderFilesList(purchaseOrderId));
    } catch (err) {
      errorNotification(err);
    }
  };

  const onRemove = file => {
    const index = fileList.indexOf(file);
    const newFileList = fileList.slice();
    newFileList.splice(index, 1);
    setFileList(newFileList);
  };

  const beforeUpload = (file, files) => {
    setFileList([...fileList, ...files]);
    return false;
  };

  const columns = [
    {
      title: 'PO No.',
      dataIndex: 'id',
      sorter: sorter('id'),
      filterDropdown,
      onFilter: onFilter('id'),
      width: 70,
      fixed: 'left',
      render: tooltipShowWithColors,
    },
    {
      title: 'Revision No.',
      dataIndex: 'revisionNo',
      sorter: sorter('revisionNo'),
      filterDropdown,
      onFilter: onFilter('revisionNo'),
      width: 70,
      fixed: 'left',
      render: tooltipShowWithColors,
    },
    {
      title: 'Material',
      dataIndex: 'material',
      sorter: sorter('material'),
      filterDropdown,
      onFilter: onFilter('material'),
      render: tooltipShowWithColors,
      width: 250,
      fixed: 'left',
    },
    {
      title: 'Created On',
      dataIndex: 'createdAt',
      sorter: dateSorter('createdAt'),
      render: dateTooltipShowWithColors,
      width: 110,
    },
    {
      title: 'Type',
      dataIndex: 'materialType',
      sorter: sorter('materialType'),
      filterDropdown,
      onFilter: onFilter('materialType'),
      render: tooltipShowWithColors,
      width: 120,
    },
    {
      title: 'Unit',
      dataIndex: 'materialUnit',
      sorter: sorter('materialUnit'),
      filterDropdown,
      onFilter: onFilter('materialUnit'),
      render: tooltipShowWithColors,
      width: 80,
    },
    {
      title: 'Quantity',
      dataIndex: 'quantity',
      sorter: sorter('quantity'),
      filterDropdown,
      onFilter: onFilter('quantity'),
      render: tooltipShowWithColors,
      width: 130,
      align: 'right',
    },
    {
      title: 'Rate',
      dataIndex: 'rate',
      sorter: sorter('rate'),
      filterDropdown,
      onFilter: onFilter('rate'),
      render: tooltipShowWithColorsInINRFormat,
      width: 130,
      align: 'right',
    },
    {
      title: 'Supplier',
      dataIndex: 'supplier',
      sorter: sorter('supplier'),
      filterDropdown,
      onFilter: onFilter('supplier'),
      ellipsis: { showTitle: false },
      render: tooltipShowWithColors,
      width: 120,
    },
    {
      title: 'Lead Time',
      dataIndex: 'leadTime',
      sorter: sorter('leadTime'),
      filterDropdown,
      onFilter: onFilter('leadTime'),
      render: tooltipShowWithColors,
      width: 80,
      align: 'right',
    },
    {
      title: 'Expected On',
      dataIndex: 'expectedBy',
      sorter: dateSorter('expectedBy'),
      render: dateTooltipShowWithColors,
      width: 120,
    },
    {
      title: 'Order No.',
      dataIndex: 'orderId',
      sorter: sorter('orderId'),
      filterDropdown,
      onFilter: onFilter('orderId'),
      render: tooltipShowWithColors,
      width: 90,
    },
    {
      title: 'Finished Good Name',
      dataIndex: 'formulation',
      sorter: sorter('formulation'),
      filterDropdown,
      onFilter: onFilter('formulation'),
      ellipsis: { showTitle: false },
      render: tooltipShowWithColors,
      width: 190,
    },
    {
      title: 'Remarks',
      dataIndex: 'remarks',
      sorter: sorter('remarks'),
      filterDropdown,
      onFilter: onFilter('remarks'),
      ellipsis: { showTitle: false },
      render: tooltipShowWithColors,
      width: 200,
    },
    {
      title: 'Status',
      dataIndex: 'status',
      sorter: sorter('status'),
      filterDropdown,
      onFilter: onFilter('status'),
      render: tooltipShowWithColors,
      width: 200,
    },
    {
      title: 'Consignor',
      dataIndex: 'consignor',
      sorter: sorter('consignor'),
      filterDropdown,
      onFilter: onFilter('consignor'),
      render: tooltipShowWithColors,
      width: 120,
    },
    {
      title: 'Done By',
      dataIndex: 'createdByUser',
      sorter: sorter('createdByUser'),
      filterDropdown,
      onFilter: onFilter('createdByUser'),
      render: tooltipShowWithColors,
      width: 120,
    },
    {
      title: 'Upload Files',
      width: 70,
      render: renderPurchaseUploadColumn,
    },
    {
      title: 'View Files',
      width: 70,
      render: renderPurchaseViewColumn,
    },
  ];

  const renderActionIcons = record => (
    <div>
      {permissions.includes('edit_purchase_order') ? (
        <>
          <Tooltip placement="bottom" title="Edit">
            <Link to={`/purchaseorder/edit/${record.id}`}>
              <EditOutlined className={classes.tableIcon} />
            </Link>
          </Tooltip>
          &nbsp;&nbsp;&nbsp;&nbsp;
        </>
      ) : null}
      {permissions.includes('delete_purchase_order') ? (
        <>
          <Tooltip placement="bottom" title="Delete">
            <Popconfirm
              title="Are you sure you want to delete?"
              onConfirm={() => onDelete(record.id)}
              okText="Yes"
              cancelText="No"
            >
              <DeleteOutlined className={classes.tableIcon} />
            </Popconfirm>
          </Tooltip>
        </>
      ) : null}
    </div>
  );

  if (permissions.includes('edit_purchase_order') || permissions.includes('delete_purchase_order')) {
    columns.push({
      title: 'Actions',
      width: 70,
      render: renderActionIcons,
      // fixed: 'right',
    });
  }

  const renderForceCompletePOActionIcons = record =>
    permissions.includes('force_complete_purchase_order') && record.status.includes('Partial') ? (
      <Tooltip placement="bottom" title="Complete">
        <Popconfirm
          title="Are you sure you want to complete purchase order?"
          onConfirm={() => onForceCompletePO(record.id)}
          okText="Yes"
          cancelText="No"
        >
          <FileDoneOutlined className={classes.tableIcon} />
        </Popconfirm>
      </Tooltip>
    ) : null;

  if (permissions.includes('force_complete_purchase_order')) {
    columns.push({
      title: 'Complete PO',
      width: 90,
      render: renderForceCompletePOActionIcons,
      // fixed: 'right',
    });
  }

  const renderFileTableNameColumn = record => (
    <a href={record.url} rel="noreferrer noopener" target="_blank">
      <Tooltip placement="bottom" title={`Click to open/download "${record.fileName}" file.`}>
        {record.fileName}
      </Tooltip>
    </a>
  );

  const renderFileTableActionColumn = record => (
    <Tooltip placement="bottom" title="Delete File">
      <Popconfirm
        title="Are you sure you want to delete?"
        onConfirm={() => onFileDelete(record.id)}
        okText="Yes"
        cancelText="No"
      >
        <DeleteOutlined className={classes.tableIcon} />
      </Popconfirm>
    </Tooltip>
  );

  const filesTableColumns = [
    {
      title: 'File',
      render: renderFileTableNameColumn,
    },
    {
      title: 'Delete',
      render: renderFileTableActionColumn,
      width: 70,
    },
  ];

  return (
    <Structure>
      <>
        <Heading text={`Purchase Order List (${rowCounts})`} />
        <Row gutter={[8, 8]}>
          <Col>
            <Select placeholder="Select year" onChange={value => setFinancialYear(value)} defaultValue={financialYear}>
              <Select.Option value="2019">19-20</Select.Option>
              <Select.Option value="2020">20-21</Select.Option>
              <Select.Option value="2021">21-22</Select.Option>
              <Select.Option value="2022">22-23</Select.Option>
              <Select.Option value="2023">23-24</Select.Option>
              <Select.Option value="2024">24-25</Select.Option>
              <Select.Option value="2025">25-26</Select.Option>
              <Select.Option value="2026">26-27</Select.Option>
              <Select.Option value="2027">27-28</Select.Option>
              <Select.Option value="2028">28-29</Select.Option>
              <Select.Option value="2029">29-30</Select.Option>
              <Select.Option value="2030">30-31</Select.Option>
            </Select>
          </Col>
          <Col>
            <Select onChange={SetConsignor} placeholder="Consignor" value={Consignor} style={{ width: 280 }}>
              {Object.values(constants.consignors.list).map(consignor => (
                <Select.Option key={consignor} value={consignor}>
                  {consignor}
                </Select.Option>
              ))}
            </Select>
          </Col>
          <Col>
            <Button type="default" onClick={exportPOList}>
              Export
            </Button>
          </Col>
        </Row>
        <Table
          columns={columns}
          bordered
          rowKey={record => record.id}
          pagination={{ defaultPageSize: 20, position: ['bottomLeft'] }}
          dataSource={purchaseOrderList}
          size="small"
          scroll={{ x: 1200, y: window.innerHeight - 300 }}
          onChange={(_page, _filters, _sorter, { currentDataSource }) => {
            setRowCounts((currentDataSource || []).length);
          }}
        />
        <Modal
          onOk={hideModal}
          open={modalType === 'UploadFiles'}
          centered
          maskClosable={false}
          title="Upload Purchase Order Files."
          width="500px"
          okText="Close"
          cancelButtonProps={{ className: classes.hide }}
          okButtonProps={{ className: classes.button }}
          closable={false}
        >
          <Upload multiple onRemove={onRemove} beforeUpload={beforeUpload} fileList={fileList}>
            <Button icon={<CloudUploadOutlined />}>Select Files</Button>
          </Upload>
          <Button
            type="primary"
            onClick={onUpload}
            disabled={fileList.length === 0}
            loading={uploading}
            style={{ marginTop: 16 }}
          >
            {uploading ? 'Uploading' : 'Upload'}
          </Button>
        </Modal>
        <Modal
          onOk={hideModal}
          open={modalType === 'ViewFiles'}
          centered
          maskClosable={false}
          title="View Purchase Order Files."
          width="800px"
          okText="Close"
          cancelButtonProps={{ className: classes.hide }}
          closable={false}
          okButtonProps={{ className: classes.button }}
        >
          <Table
            columns={filesTableColumns}
            bordered
            rowKey={record => record.id}
            dataSource={purchaseOrderFilesList}
            size="small"
            pagination={{ position: ['bottomLeft'] }}
          />
        </Modal>
      </>
    </Structure>
  );
};

PurchaseOrderList.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(PurchaseOrderList);

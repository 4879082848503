export const locationList = (state = [], action) => {
  if (action.type === 'FETCH_LOCATION_LIST') {
    return action.payload;
  }
  if (action.type === 'CLEAR_LOCATION_LIST') {
    return [];
  }
  return state;
};

export const location = (state = {}, action) => {
  if (action.type === 'FETCH_LOCATION') {
    return action.payload;
  }
  if (action.type === 'CLEAR_LOCATION') {
    return {};
  }
  return state;
};

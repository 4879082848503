import React, { useCallback, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { Input, Modal, Popconfirm, Select } from 'antd';
import withStyles from 'react-jss';
import { useDispatch, useSelector } from 'react-redux';
import { Field, getFormValues, reduxForm, reset } from 'redux-form';

import styles from '../../CommonStyles';
import { successNotification, errorNotification } from '../../Snackbar';
import Api from '../../Api';
import GetField from '../../Components/GetField';
import { clearUserList, getUserList } from '../../Actions';

const AntInput = GetField(Input);
const AntSelect = GetField(Select);

const QueryCommentModal = props => {
  const { handleSubmit, classes, query, hideModal } = props;

  const dispatch = useDispatch();

  const [confirmLoading, setConfirmLoading] = useState(false);

  const FormValues = useSelector(state => getFormValues('QueryCommentForm')(state));

  const userList = useSelector(state => state.userList);

  useEffect(() => {
    dispatch(getUserList());

    return () => {
      dispatch(clearUserList());
    };
  }, []);

  const onSubmit = useCallback(async () => {
    try {
      setConfirmLoading(true);
      await Api.post(`/leads/queries/${query.id}/remarks`, { ...FormValues });
      setConfirmLoading(false);
      dispatch(reset('QueryCommentForm'));
      hideModal();
      successNotification('Remark added');
    } catch (err) {
      setConfirmLoading(false);
      errorNotification(err);
    }
  }, [FormValues]);

  return (
    <Modal
      onCancel={() => {
        reset();
        hideModal();
      }}
      open
      confirmLoading={confirmLoading}
      maskClosable={false}
      title={`${query?.query} | Add Remark`}
      style={{ top: 10 }}
      okButtonProps={{ className: classes.button }}
      okText={
        <Popconfirm title="Sure want to submit?" onConfirm={() => onSubmit()} okText="Confirm" cancelText="Cancel">
          Submit
        </Popconfirm>
      }
    >
      <form onSubmit={handleSubmit(onSubmit)}>
        <Field allowClear required name="remark" placeholder="Remark" component={AntInput} />
        <Field label="Assign" allowClear required name="assignedTo" component={AntSelect}>
          <Select.Option key={null} value="" disabled>
            Select user
          </Select.Option>
          {userList.map(user => (
            <Select.Option key={user.id} value={user.id}>
              {user.name}
            </Select.Option>
          ))}
        </Field>
      </form>
    </Modal>
  );
};

QueryCommentModal.propTypes = {
  handleSubmit: PropTypes.func.isRequired,
  classes: PropTypes.object.isRequired,
  query: PropTypes.object,
  hideModal: PropTypes.func,
};

const StyledQueryCommentModal = withStyles(styles)(QueryCommentModal);

const FormedQueryCommentModal = reduxForm({
  form: 'QueryCommentForm',
})(StyledQueryCommentModal);

export default FormedQueryCommentModal;

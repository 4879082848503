import Api from '../Api';

export const getDebitNoteList = () => async dispatch => {
  try {
    const { data } = await Api.get('/debit-note');
    dispatch({
      type: 'FETCH_DEBIT_NOTE_LIST',
      payload: data,
    });
  } catch (err) {
    throw err;
  }
};

export const clearDebitNoteList = () => {
  return {
    type: 'CLEAR_DEBIT_NOTE_LIST',
  };
};

export const getDebitNoteFilesList = id => async dispatch => {
  try {
    const { data } = await Api.get(`/debit-note/${id}/files`);
    dispatch({
      type: 'FETCH_DEBIT_NOTE_FILES_LIST',
      payload: data,
    });
  } catch (err) {
    throw err;
  }
};

export const clearDebitNoteFilesList = () => {
  return {
    type: 'CLEAR_DEBIT_NOTE_FILES_LIST',
  };
};

export const getDebitNote = id => async dispatch => {
  try {
    const { data } = await Api.get(`/debit-note/${id}`);
    dispatch({
      type: 'FETCH_DEBIT_NOTE',
      payload: data,
    });
  } catch (err) {
    throw err;
  }
};

export const clearDebitNote = () => {
  return {
    type: 'CLEAR_DEBIT_NOTE',
  };
};

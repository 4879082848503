import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Table, Tooltip, Button, Row, Form, Col, Select } from 'antd';
import startCase from 'lodash/startCase';
import fileDownload from 'js-file-download';
import moment from 'moment';
import { getMaterialInventoryList, clearMaterialInventoryList } from '../Actions';
import { dateFilterDropdown, dateSorter, filterDropdown, onDateFilter, onFilter, sorter } from '../utils';
import Structure from '../Structure/Structure';
import Heading from '../Components/Heading';
import { errorNotification } from '../Snackbar';
import Api from '../Api';
import constants from '../constants';

const MaterialInventory = () => {
  const dispatch = useDispatch();

  const materialInventoryList = useSelector(state => state.materialInventoryList);

  const [Consignor, SetConsignor] = useState(constants.consignors.list.plusFormulationsHeadOffice);

  useEffect(() => {
    dispatch(getMaterialInventoryList({ consignor: Consignor }));

    return () => {
      dispatch(clearMaterialInventoryList());
    };
  }, [Consignor]);

  const exportInventoryList = async () => {
    try {
      const { data } = await Api.getFile(`/material-inventory/export`, { consignor: Consignor });
      fileDownload(data, `inventory-${moment().format('DD MMM YYYY h:mm:ss a')}.csv`);
    } catch (error) {
      errorNotification(error);
    }
  };

  const tooltipShow = arg => (
    <Tooltip placement="topLeft" title={arg}>
      {arg}
    </Tooltip>
  );

  const columns = [
    {
      title: 'Name',
      dataIndex: 'name',
      sorter: sorter('name'),
      filterDropdown,
      onFilter: onFilter('name'),
      render: tooltipShow,
      fixed: 'left',
      width: 180,
    },
    {
      title: 'Type',
      dataIndex: 'type',
      sorter: sorter('type'),
      filterDropdown,
      onFilter: onFilter('type', true),
      render: type => startCase(type),
      width: 120,
    },
    {
      title: 'Unit',
      dataIndex: 'unit',
      sorter: sorter('unit'),
      width: 70,
    },
    {
      title: 'Batch',
      dataIndex: 'batch',
      sorter: sorter('batch'),
      filterDropdown,
      onFilter: onFilter('batch'),
      width: 100,
    },
    {
      title: 'Mfg Date',
      dataIndex: 'mfgDate',
      sorter: dateSorter('mfgDate'),
      onFilter: onDateFilter('mfgDate'),
      filterDropdown: dateFilterDropdown,
      render: date => (date ? moment(date).format('MMM YY') : null),
      width: 80,
    },
    {
      title: 'Exp Date',
      dataIndex: 'expDate',
      sorter: dateSorter('expDate'),
      onFilter: onDateFilter('expDate'),
      filterDropdown: dateFilterDropdown,
      render: date => (date ? moment(date).format('MMM YY') : null),
      width: 80,
    },
    {
      title: 'Manual Rate',
      dataIndex: 'manualRate',
      sorter: sorter('manualRate'),
      // filterDropdown,
      // onFilter: onFilter('manualRate'),
      render: manualRate => Number(manualRate).toLocaleString('en-IN'),
      width: 90,
      align: 'right',
    },
    {
      title: 'Last Purchase Rate',
      dataIndex: 'purchaseRate',
      sorter: sorter('purchaseRate'),
      // filterDropdown,
      // onFilter: onFilter('purchaseRate'),
      render: purchaseRate => Number(purchaseRate).toLocaleString('en-IN'),
      width: 90,
      align: 'right',
    },
    {
      title: 'Max Purchase Rate',
      dataIndex: 'maxRate',
      sorter: sorter('maxRate'),
      // filterDropdown,
      // onFilter: onFilter('maxRate'),
      width: 90,
      align: 'right',
    },
    {
      title: 'Max. Stock Value',
      dataIndex: 'maximumStock',
      sorter: sorter('maximumStock'),
      // filterDropdown,
      // onFilter: onFilter('maximumStock'),
      width: 90,
      align: 'right',
    },
    // {
    //   title: 'Minimum Stock Value',
    //   dataIndex: 'minimumStock',
    //   sorter: sorter('minimumStock'),
    //   filterDropdown,
    //   onFilter: onFilter('minimumStock'),
    //   width: 120,
    //   align: 'right',
    // },
    {
      title: 'Stock',
      dataIndex: 'stock',
      sorter: sorter('stock'),
      // filterDropdown,
      // onFilter: onFilter('stock'),
      width: 90,
      align: 'right',
    },
    {
      title: 'Customer',
      dataIndex: 'customer',
      sorter: sorter('customer'),
      filterDropdown,
      onFilter: onFilter('customer'),
      render: tooltipShow,
      ellipsis: { showTitle: false },
      width: 150,
    },
    {
      title: 'Formulations',
      dataIndex: 'usedByFormulations',
      sorter: sorter('usedByFormulations'),
      filterDropdown,
      onFilter: onFilter('usedByFormulations'),
      render: usedByFormulations => (usedByFormulations || []).join(', '),
    },
    // {
    //   title: 'Last Purchase Amount',
    //   dataIndex: 'purchaseAmount',
    //   sorter: sorter('purchaseAmount'),
    //   filterDropdown,
    //   onFilter: onFilter('purchaseAmount'),
    //   render: purchaseAmount => Number(purchaseAmount).toLocaleString('en-IN'),
    //   width: 120,
    //   align: 'right',
    // },
    // {
    //   title: 'Max Purchase Amount',
    //   filterDropdown,
    //   dataIndex: 'maxObject',
    //   sorter: sorterInsideObject('maxObject', 'maxAmount'),
    //   onFilter: onFilterInsideObject('maxObject', 'maxAmount'),
    //   render: tooltipShowInsideObject.bind(null, ['maxAmount']),
    //   width: 120,
    //   align: 'right',
    // },
  ];

  return (
    <Structure>
      <>
        <Heading text="Material Inventory" />
        <Row gutter={[32, 0]}>
          <Col xs={24} md={6} lg={3} xl={3}>
            <Button type="default" onClick={exportInventoryList}>
              Export
            </Button>
          </Col>
          <Col xs={24} md={6} lg={6} xl={3}>
            <Form.Item>
              <Select onChange={SetConsignor} placeholder="Consignor" value={Consignor} style={{ width: 280 }}>
                {Object.values(constants.consignors.list).map(consignor => (
                  <Select.Option key={consignor} value={consignor}>
                    {consignor}
                  </Select.Option>
                ))}
              </Select>
            </Form.Item>
          </Col>
        </Row>
        <Table
          columns={columns}
          bordered
          rowKey={record => record.id}
          pagination={{ defaultPageSize: 20, position: ['bottomLeft'] }}
          dataSource={materialInventoryList}
          size="small"
          scroll={{ x: 1500, y: window.innerHeight - 300 }}
        />
      </>
    </Structure>
  );
};

export default MaterialInventory;

import Api from '../../Api';

export const getTimeMotionList = () => async dispatch => {
  try {
    const { data } = await Api.get('/dropdowns/time-motions');
    dispatch({
      type: 'FETCH_TIME_MOTION_LIST',
      payload: data,
    });
  } catch (err) {
    throw err;
  }
};

export const clearTimeMotionList = () => {
  return {
    type: 'CLEAR_TIME_MOTION_LIST',
  };
};

export const getTimeMotion = id => async dispatch => {
  try {
    const { data } = await Api.get(`/dropdowns/time-motions/${id}`);
    dispatch({
      type: 'FETCH_TIME_MOTION',
      payload: data,
    });
  } catch (err) {
    throw err;
  }
};

export const clearTimeMotion = () => {
  return {
    type: 'CLEAR_TIME_MOTION',
  };
};

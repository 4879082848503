import React, { useEffect, Fragment } from 'react';
import { Route, useHistory } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import Login from './Login/Login';
import { login } from './Actions';
import Routes from './Routes';
import './App.css';
import CustomerDetailsView from './Customer/CustomerDetailsView';
import DetailsView from './Supplier/DetailsView';
import ComplaintForm from './Customer/ComplaintForm';

const App = () => {
  const auth = useSelector(state => state.auth);
  const dispatch = useDispatch();
  const history = useHistory();

  useEffect(() => {
    dispatch(login()).catch(() => {
      history.push('/login');
    });
  }, []);

  return (
    <>
      <Route path="/supplier/details/:id" exact component={DetailsView} />
      <Route path="/customer/details/:id" exact component={CustomerDetailsView} />
      <Route path="/customer/complaint/:id" exact component={ComplaintForm} />
      <Route path="/login" exact component={Login} />
      {auth.type && auth.user ? <Routes /> : null}
    </>
  );
};

export default App;

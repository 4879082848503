import Api from '../Api';

export const getItemsToOrderList = query => async dispatch => {
  try {
    console.log(query);

    const { data } = await Api.get('/purchase-planning/all', query);

    dispatch({
      type: 'FETCH_ITEMS_TO_ORDER_LIST',
      payload: data,
    });
  } catch (err) {
    throw err;
  }
};

export const clearItemsToOrderList = () => {
  return {
    type: 'CLEAR_ITEMS_TO_ORDER_LIST',
  };
};

/* eslint-disable no-nested-ternary */
import React, { useEffect, useRef } from 'react';
import PropTypes from 'prop-types';
import withStyles from 'react-jss';
import startCase from 'lodash/startCase';
import moment from 'moment';
import { Descriptions, Modal, Table } from 'antd';
import { useDispatch, useSelector } from 'react-redux';
import { CloseOutlined } from '@ant-design/icons';
import { useReactToPrint } from 'react-to-print';
import { get } from '../utils';
import styles from './styles';
import { clearJobCard, getJobCard } from '../Actions';

const JobCard = props => {
  const { classes, hideModal, order } = props;

  const dispatch = useDispatch();

  const auth = useSelector(state => state.auth);
  const permissions = get(auth, ['permissions'], []);

  const jobCard = useSelector(state => state.jobCard);

  const printRef = useRef();
  const handlePrint = useReactToPrint({
    content: () => printRef.current,
    pageStyle: '',
  });

  useEffect(() => {
    if (order.id) {
      dispatch(getJobCard(order.id));
    }
    return () => {
      dispatch(clearJobCard());
    };
  }, []);

  const dispenseTableColumn = [
    {
      title: 'Material',
      dataIndex: 'name',
    },
    {
      title: 'Batch',
      dataIndex: 'batch',
    },
    {
      title: 'Unit',
      dataIndex: 'unit',
    },
    // {
    //   title: 'Mfg.',
    //   dataIndex: 'mfgDate',
    //   render: date => (date ? moment(date).format('MMM YY') : null),
    // },
    // {
    //   title: 'Exp.',
    //   dataIndex: 'expDate',
    //   render: date => (date ? moment(date).format('MMM YY') : null),
    // },
    {
      title: 'Quantity',
      dataIndex: 'dispensedQuantity',
      align: 'right',
    },
    {
      title: 'Dispensed By',
      dataIndex: 'createdBy',
    },
    {
      title: 'Dispensed On',
      dataIndex: 'createdAt',
      render: createdAt => (createdAt ? moment(createdAt).format('DD MMM YY') : null),
    },
  ];

  const batchTableColumn = [
    {
      title: 'Material',
      dataIndex: 'name',
    },
    {
      title: 'Unit',
      dataIndex: 'unit',
    },
    {
      title: 'Qty As Per Order',
      dataIndex: 'orderQuantity',
      align: 'right',
    },
    {
      title: 'Actual Qty',
      dataIndex: 'quantity',
      align: 'right',
    },
    {
      title: 'Printed By',
      dataIndex: 'remarks',
    },
    {
      title: 'Done By',
      dataIndex: 'createdByUser',
    },
  ];

  return (
    <Modal
      open
      maskClosable={false}
      okText="Print"
      onCancel={hideModal}
      onOk={handlePrint}
      width={950}
      style={{ top: 10 }}
      title={`Job Card | ${order.id}`}
      closeIcon={<CloseOutlined onClick={hideModal} />}
    >
      <div ref={printRef}>
        <Descriptions column={2} size="small" title="Basic Detail">
          <Descriptions.Item label="Order Number">
            <span className={classes.detailColor}>{get(jobCard, ['id'], '--')}</span>
          </Descriptions.Item>
          <Descriptions.Item label="Finished Good Name">
            <span className={classes.detailColor}>{get(jobCard, ['formulation'], '--')}</span>
          </Descriptions.Item>
          <Descriptions.Item label="Customer">
            <span className={classes.detailColor}>{get(jobCard, ['customer'], '--')}</span>
          </Descriptions.Item>
          <Descriptions.Item label="Consignor">
            <span className={classes.detailColor}>{get(jobCard, ['consignor'], '--')}</span>
          </Descriptions.Item>
          <Descriptions.Item label="Category">
            <span className={classes.detailColor}>{get(jobCard, ['category'], '--')}</span>
          </Descriptions.Item>
          <Descriptions.Item label="Subcategory">
            <span className={classes.detailColor}>{get(jobCard, ['subcategory'], '--')}</span>
          </Descriptions.Item>
          <Descriptions.Item label="Dosage Form">
            <span className={classes.detailColor}>{get(jobCard, ['dosageForm'], '--')}</span>
          </Descriptions.Item>
          <Descriptions.Item label="Section">
            <span className={classes.detailColor}>{get(jobCard, ['section'], '--')}</span>
          </Descriptions.Item>
          <Descriptions.Item label="Batch Size">
            <span className={classes.detailColor}>{get(jobCard, ['batchSize'], '--')}</span>
          </Descriptions.Item>
          <Descriptions.Item label="Formulation Remarks">
            <span className={classes.detailColor}>{get(jobCard, ['formulationRemarks'], '--')}</span>
          </Descriptions.Item>
          <Descriptions.Item label="HSN Code">
            <span className={classes.detailColor}>{get(jobCard, ['hsn'], '--')}</span>
          </Descriptions.Item>
          <Descriptions.Item label="Quantity">
            <span className={classes.detailColor}>{get(jobCard, ['quantity'], '--')}</span>
          </Descriptions.Item>
          <Descriptions.Item label="Rate">
            <span className={classes.detailColor}>{Number(get(jobCard, ['rate'], 0)).toLocaleString('en-IN')}</span>
          </Descriptions.Item>
          <Descriptions.Item label="MRP">
            <span className={classes.detailColor}>{Number(get(jobCard, ['mrp'], 0)).toLocaleString('en-IN')}</span>
          </Descriptions.Item>
          <Descriptions.Item label="Nature">
            <span className={classes.detailColor}>{startCase(get(jobCard, ['nature'], '--'))}</span>
          </Descriptions.Item>
          <Descriptions.Item label="Payment Terms">
            <span className={classes.detailColor}>{startCase(get(jobCard, ['paymentTerms'], '--'))}</span>
          </Descriptions.Item>
          <Descriptions.Item label="Advance Deposit Date">
            <span className={classes.detailColor}>
              {get(jobCard, ['dateOfAdvanceDeposit'])
                ? moment(get(jobCard, ['dateOfAdvanceDeposit'])).format('DD MMM YY')
                : '--'}
            </span>
          </Descriptions.Item>
          <Descriptions.Item label="Deposited Amount">
            <span className={classes.detailColor}>
              {Number(get(jobCard, ['depositedAmount'], 0)).toLocaleString('en-IN')}
            </span>
          </Descriptions.Item>
          <Descriptions.Item label="Transport">
            <span className={classes.detailColor}>{startCase(get(jobCard, ['transport'], '--'))}</span>
          </Descriptions.Item>
          <Descriptions.Item label="Artwork change required">
            <span className={classes.detailColor}>{startCase(get(jobCard, ['isChangeRequired'], '--'))}</span>
          </Descriptions.Item>
          <Descriptions.Item label="Customer Remark">
            <span className={classes.detailColor}>{get(jobCard, ['remarks'], '--')}</span>
          </Descriptions.Item>
          <Descriptions.Item label="Internal Remark">
            <span className={classes.detailColor}>{get(jobCard, ['internalRemarks'], '--')}</span>
          </Descriptions.Item>
          <Descriptions.Item label="Estimated Dispatch Date">
            <span className={classes.detailColor}>
              {get(jobCard, ['estDispatchDate']) ? moment(get(jobCard, ['estDispatchDate'])).format('DD MMM YY') : '--'}
            </span>
          </Descriptions.Item>
          <Descriptions.Item label="Authorized">
            <span className={classes.detailColor}>{startCase(get(jobCard, ['isAuthorized'], '--'))}</span>
          </Descriptions.Item>
          <Descriptions.Item label="Authorized On">
            <span className={classes.detailColor}>
              {get(jobCard, ['authorizedAt']) ? moment(get(jobCard, ['authorizedAt'])).format('DD MMM YY') : '--'}
            </span>
          </Descriptions.Item>
          <Descriptions.Item label="Customer Verification On">
            <span className={classes.detailColor}>
              {get(jobCard, ['changeVerifiedByCustomerAt'])
                ? moment(get(jobCard, ['changeVerifiedByCustomerAt'])).format('DD MMM YY')
                : '--'}
            </span>
          </Descriptions.Item>
          <Descriptions.Item label="Internal Verification On">
            <span className={classes.detailColor}>
              {get(jobCard, ['changeVerifiedByUserAt'])
                ? moment(get(jobCard, ['changeVerifiedByUserAt'])).format('DD MMM YY')
                : '--'}
            </span>
          </Descriptions.Item>
          <Descriptions.Item label="Done By">
            <span className={classes.detailColor}>{get(jobCard, ['createdByUser'], '--')}</span>
          </Descriptions.Item>
        </Descriptions>
        <br />
        <Descriptions column={2} size="small" title="Batch Detail">
          <Descriptions.Item label="Batch Number">
            <span className={classes.detailColor}>
              {order.id === 5935 ? 'NIP-05' : get(jobCard, ['batchDetails', 'batchCode'], '--')}
            </span>
          </Descriptions.Item>
          <Descriptions.Item label="Batch MRP">
            <span className={classes.detailColor}>
              {Number(get(jobCard, ['batchDetails', 'mrp'], 0)).toLocaleString('en-IN')}
            </span>
          </Descriptions.Item>
          <Descriptions.Item label="Manufactured Date">
            <span className={classes.detailColor}>
              {get(jobCard, ['batchDetails', 'mfgDate'])
                ? moment(get(jobCard, ['batchDetails', 'mfgDate'])).format('MMM YY')
                : '--'}
            </span>
          </Descriptions.Item>
          <Descriptions.Item label="Expiry Date">
            <span className={classes.detailColor}>
              {get(jobCard, ['batchDetails', 'expDate'])
                ? moment(get(jobCard, ['batchDetails', 'expDate'])).format('MMM YY')
                : '--'}
            </span>
          </Descriptions.Item>
          <Descriptions.Item label="Done By">
            <span className={classes.detailColor}>{get(jobCard, ['batchDetails', 'createdByUser'], '--')}</span>
          </Descriptions.Item>
        </Descriptions>
        <br />
        <Table
          columns={batchTableColumn}
          bordered
          rowKey={record => record.id}
          pagination={false}
          dataSource={get(jobCard, ['batchMaterials'], [])}
          size="small"
          scroll={{ x: 425 }}
        />
        <br />
        <Descriptions column={2} size="small" title="Semi Finished Quantity Used">
          <Descriptions.Item label="Quantity">{order.semiFinishedQuantity}</Descriptions.Item>
        </Descriptions>
        <Descriptions column={2} size="small" title="Manufacturing Details">
          <Descriptions.Item label="Start Time">
            <span className={classes.detailColor}>
              {get(jobCard, ['manufacturingdetails', 'startTime'])
                ? moment(get(jobCard, ['manufacturingdetails', 'startTime'])).format('DD MMM YY hh:mm A')
                : '--'}
            </span>
          </Descriptions.Item>
          <Descriptions.Item label="End Time">
            <span className={classes.detailColor}>
              {get(jobCard, ['manufacturingdetails', 'endTime'])
                ? moment(get(jobCard, ['manufacturingdetails', 'endTime'])).format('DD MMM YY hh:mm A')
                : '--'}
            </span>
          </Descriptions.Item>
          <Descriptions.Item
            label={order?.section === 'External' || order?.section === 'Liquid' ? 'Volume' : 'Gross Weight'}
          >
            <span className={classes.detailColor}>{get(jobCard, ['manufacturingdetails', 'weightVolume'], '--')}</span>
          </Descriptions.Item>
          {order?.section !== 'Veterinary Tablet' ||
          order?.section !== 'Veterinary Powder' ||
          order?.section !== 'Capsule' ? (
            <Descriptions.Item label={order?.section === 'External' ? 'Fragrance' : 'Flavour'}>
              <span className={classes.detailColor}>
                {get(jobCard, ['manufacturingdetails', 'flavourFragrance'], '--')}
              </span>
            </Descriptions.Item>
          ) : null}
          {order?.section !== 'Capsule' ? (
            <Descriptions.Item label="Color">
              <span className={classes.detailColor}>{get(jobCard, ['manufacturingdetails', 'color'], '--')}</span>
            </Descriptions.Item>
          ) : null}
          {order?.section === 'Sachet' || order?.section === 'Capsule' || order?.section === 'Veterinary Sachet' ? (
            <Descriptions.Item label="Flow">
              <span className={classes.detailColor}>{get(jobCard, ['manufacturingdetails', 'flow'], '--')}</span>
            </Descriptions.Item>
          ) : null}
          {order?.section === 'External' || order?.section === 'Liquid' ? (
            <Descriptions.Item label="Gaze">
              <span className={classes.detailColor}>{get(jobCard, ['manufacturingdetails', 'gaze'], '--')}</span>
            </Descriptions.Item>
          ) : null}
          {order?.section === 'External' || order?.section === 'Liquid' ? (
            <Descriptions.Item label="pH">
              <span className={classes.detailColor}>{get(jobCard, ['manufacturingdetails', 'pH'], '--')}</span>
            </Descriptions.Item>
          ) : null}
          {order?.section === 'External' || order?.section === 'Liquid' ? (
            <Descriptions.Item label="Viscosity">
              <span className={classes.detailColor}>{get(jobCard, ['manufacturingdetails', 'viscosity'], '--')}</span>
            </Descriptions.Item>
          ) : null}
          <Descriptions.Item label="Any Other">
            <span className={classes.detailColor}>{get(jobCard, ['manufacturingdetails', 'anyOther'], '--')}</span>
          </Descriptions.Item>
          <Descriptions.Item label="Users in Manufacturing">
            <span className={classes.detailColor}>
              {get(jobCard, ['manufacturingdetails', 'usersInManufacturing'], [])
                .map(user => user.name)
                .join(', ')}
            </span>
          </Descriptions.Item>
          <Descriptions.Item label="Remarks">
            <span className={classes.detailColor}>{get(jobCard, ['manufacturingdetails', 'remarks'], '--')}</span>
          </Descriptions.Item>
          <Descriptions.Item label="Done By">
            <span className={classes.detailColor}>{get(jobCard, ['manufacturingdetails', 'createdByUser'], '--')}</span>
          </Descriptions.Item>
        </Descriptions>
        <br />
        <Descriptions column={2} size="small" title="Filling/Pouching Details">
          <Descriptions.Item label="Start Time">
            <span className={classes.detailColor}>
              {get(jobCard, ['fillingdetails', 'startTime'])
                ? moment(get(jobCard, ['fillingdetails', 'startTime'])).format('DD MMM YY hh:mm A')
                : '--'}
            </span>
          </Descriptions.Item>
          {/* <Descriptions.Item label="Cap/Pouch Sealing Checked">
            <span className={classes.detailColor}>
              {startCase(get(jobCard, ['fillingdetails', 'sealingChecked'], '--'))}
            </span>
          </Descriptions.Item>
          <Descriptions.Item label="Cap/Pouch Sealing Checked By">
            <span className={classes.detailColor}>{get(jobCard, ['fillingdetails', 'sealingCheckedBy'], '--')}</span>
          </Descriptions.Item>
          <Descriptions.Item label="Fill Volume/Pouch Weight Checked">
            <span className={classes.detailColor}>
              {startCase(get(jobCard, ['fillingdetails', 'fillVolumeChecked'], '--'))}
            </span>
          </Descriptions.Item> */}
          <Descriptions.Item
            label={
              order?.section === 'External' || order?.section === 'Liquid'
                ? 'Total Volume Received'
                : 'Total Gross Weight Received'
            }
          >
            <span className={classes.detailColor}>{get(jobCard, ['fillingdetails', 'total'], '--')}</span>
          </Descriptions.Item>
          <Descriptions.Item
            label={
              order?.section === 'External' || order?.section === 'Liquid'
                ? 'Volume Per Unit Packaging'
                : 'Weight Per Unit Packaging'
            }
          >
            <span className={classes.detailColor}>{get(jobCard, ['fillingdetails', 'perUnit'], '--')}</span>
          </Descriptions.Item>
          <Descriptions.Item
            label={order?.section === 'Veterinary Tablet' ? 'Hardness Checked By' : 'Filling Checked By'}
          >
            <span className={classes.detailColor}>{get(jobCard, ['fillingdetails', 'fillCheckedByName'], '--')}</span>
          </Descriptions.Item>
          <Descriptions.Item label="Sealing Checked By">
            <span className={classes.detailColor}>
              {get(jobCard, ['fillingdetails', 'sealingCheckedByName'], '--')}
            </span>
          </Descriptions.Item>
          <Descriptions.Item label="Users in Filling">
            <span className={classes.detailColor}>
              {get(jobCard, ['fillingdetails', 'usersInFilling'], [])
                .map(user => user.name)
                .join(', ')}
            </span>
          </Descriptions.Item>
          <Descriptions.Item label="Remarks">
            <span className={classes.detailColor}>{get(jobCard, ['fillingdetails', 'remarks'], '--')}</span>
          </Descriptions.Item>
          <Descriptions.Item label="Done By">
            <span className={classes.detailColor}>{get(jobCard, ['fillingdetails', 'createdByUser'], '--')}</span>
          </Descriptions.Item>
        </Descriptions>
        <br />
        <Descriptions column={2} size="small" title="Packing Details">
          <Descriptions.Item label="Start Time">
            <span className={classes.detailColor}>
              {get(jobCard, ['packingdetails', 'startTime'])
                ? moment(get(jobCard, ['packingdetails', 'startTime'])).format('DD MMM YY hh:mm A')
                : get(jobCard, ['fillingdetails', 'startTime'])
                ? moment(get(jobCard, ['fillingdetails', 'startTime'])).format('DD MMM YY hh:mm A')
                : '--'}
            </span>
          </Descriptions.Item>
          <Descriptions.Item label="End Time">
            <span className={classes.detailColor}>
              {get(jobCard, ['packingdetails', 'endTime'])
                ? moment(get(jobCard, ['packingdetails', 'endTime'])).format('DD MMM YY hh:mm A')
                : '--'}
            </span>
          </Descriptions.Item>
          <Descriptions.Item label="Batch Coding Checked for all items">
            <span className={classes.detailColor}>
              {startCase(get(jobCard, ['packingdetails', 'batchCoding'], '--'))}
            </span>
          </Descriptions.Item>
          <Descriptions.Item label="Batch Coding Checked By">
            <span className={classes.detailColor}>{get(jobCard, ['packingdetails', 'batchCodingBy'], '--')}</span>
          </Descriptions.Item>
          <Descriptions.Item label="Finished Details">
            <span className={classes.detailColor}>{get(jobCard, ['packingdetails', 'finishedDetails'], '--')}</span>
          </Descriptions.Item>
          <Descriptions.Item label="Actual Yield">
            <span className={classes.detailColor}>{get(jobCard, ['packingdetails', 'actualYield'], '--')}</span>
          </Descriptions.Item>
          <Descriptions.Item label="Percentage Yield (in %)">
            <span className={classes.detailColor}>{get(jobCard, ['packingdetails', 'percentageYield'], '--')}</span>
          </Descriptions.Item>
          <Descriptions.Item label="Shipper Packed By">
            <span className={classes.detailColor}>
              {get(jobCard, ['packingdetails', 'shipperPackedBy'], [])
                .map(user => user.name)
                .join(', ')}
            </span>
          </Descriptions.Item>
          <Descriptions.Item label="Users in Packing">
            <span className={classes.detailColor}>
              {get(jobCard, ['packingdetails', 'usersInPacking'], [])
                .map(user => user.name)
                .join(', ')}
            </span>
          </Descriptions.Item>
          <Descriptions.Item label="Remarks">
            <span className={classes.detailColor}>{get(jobCard, ['packingdetails', 'remarks'], '--')}</span>
          </Descriptions.Item>
          <Descriptions.Item label="Done By">
            <span className={classes.detailColor}>{get(jobCard, ['packingdetails', 'createdByUser'], '--')}</span>
          </Descriptions.Item>
        </Descriptions>
      </div>
      {permissions.includes('edit_formulation') ||
      permissions.includes('view_formulation') ||
      permissions.includes('new_formulation') ? (
        <>
          <h3 className={classes.heading}>Raw Material Dispense Details:</h3>
          <br />
          <Table
            columns={dispenseTableColumn}
            bordered
            rowKey={record => record.materialId}
            pagination={false}
            dataSource={get(jobCard, ['rawDispenseDetails'], [])}
            size="small"
            scroll={{ x: 425 }}
          />
        </>
      ) : null}
      <br />
      <>
        <h3 className={classes.heading}>Other Material Dispense Details:</h3>
        <br />
        <Table
          columns={dispenseTableColumn}
          bordered
          rowKey={record => record.materialId}
          pagination={false}
          dataSource={get(jobCard, ['otherDispenseDetails'], [])}
          size="small"
          scroll={{ x: 425 }}
        />
      </>
    </Modal>
  );
};

JobCard.propTypes = {
  classes: PropTypes.object.isRequired,
  order: PropTypes.object.isRequired,
  hideModal: PropTypes.func.isRequired,
};

export default withStyles(styles)(JobCard);

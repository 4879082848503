import React, { useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Table } from 'antd';
import moment from 'moment';
import Structure from '../Structure/Structure';
import { sorter, onFilter, filterDropdown, dateSorter, dateFilterDropdown, onDateFilter } from '../utils';
import { getAssetAdjustmentList, clearAssetAdjustmentList } from '../Actions';
import Heading from '../Components/Heading';

const AdjustmentList = () => {
  const dispatch = useDispatch();

  const assetAdjustmentList = useSelector(state => state.assetAdjustmentList);

  useEffect(() => {
    dispatch(getAssetAdjustmentList());
    return () => {
      dispatch(clearAssetAdjustmentList());
    };
  }, []);

  const columns = [
    {
      title: 'Type',
      dataIndex: 'type',
      sorter: sorter('type'),
      filterDropdown,
      onFilter: onFilter('type'),
    },
    {
      title: 'Asset',
      dataIndex: 'asset',
      sorter: sorter('asset'),
      filterDropdown,
      onFilter: onFilter('asset'),
    },
    {
      title: 'Value',
      dataIndex: 'value',
      sorter: sorter('value'),
      filterDropdown,
      onFilter: onFilter('value'),
    },
    {
      title: 'Adjusted At',
      dataIndex: 'adjustedAt',
      sorter: dateSorter('adjustedAt'),
      filterDropdown: dateFilterDropdown,
      onFilter: onDateFilter('adjustedAt'),
      render: adjustedAt => (adjustedAt ? moment(adjustedAt).format('DD MMM YYYY') : null),
    },
    {
      title: 'Adjusted By',
      dataIndex: 'adjustedBy',
      sorter: sorter('adjustedBy'),
      filterDropdown,
      onFilter: onFilter('adjustedBy'),
    },
  ];

  return (
    <Structure>
      <>
        <Heading text="Asset Adjustments" />
        <Table
          columns={columns}
          bordered
          rowKey={record => record.id}
          pagination={{
            defaultPageSize: 20,
            position: ['bottomLeft'],
            showTotal: total => `Total ${total} Records`,
          }}
          dataSource={assetAdjustmentList}
          size="small"
          scroll={{ x: 1050, y: window.innerHeight - 300 }}
        />
      </>
    </Structure>
  );
};

export default AdjustmentList;

import React, { useCallback, useEffect } from 'react';
import { Button, Row, Table } from 'antd';
import { useDispatch, useSelector } from 'react-redux';

import fileDownload from 'js-file-download';
import Heading from '../Components/Heading';
import Structure from '../Structure/Structure';
import { getGPReport, clearGPReport } from '../Actions';
import { sorter, onFilter, filterDropdown } from '../utils';
import Api from '../Api';
import { errorNotificationOnBlob } from '../Snackbar';

const GPReport = () => {
  const dispatch = useDispatch();

  const gpReport = useSelector(state => state.gpReport);

  useEffect(() => {
    dispatch(getGPReport());
    return () => {
      dispatch(clearGPReport());
    };
  }, []);

  const exportGPReport = useCallback(async () => {
    try {
      const { data: file } = await Api.getFile(`/formulations/reports/gp/export`);

      fileDownload(file, `gpreport.csv`);
    } catch (error) {
      errorNotificationOnBlob(error);
    }
  }, []);

  const columns = [
    {
      title: 'Finished Good Name',
      dataIndex: 'name',
      sorter: sorter('name'),
      filterDropdown,
      onFilter: onFilter('name'),
    },
    {
      title: 'Category',
      dataIndex: 'category',
      sorter: sorter('category'),
      filterDropdown,
      onFilter: onFilter('category'),
      width: 120,
    },
    {
      title: 'Subcategory',
      dataIndex: 'subcategory',
      sorter: sorter('subcategory'),
      filterDropdown,
      onFilter: onFilter('subcategory'),
      width: 140,
    },
    {
      title: 'Dosage Form',
      dataIndex: 'dosageForm',
      sorter: sorter('dosageForm'),
      filterDropdown,
      onFilter: onFilter('dosageForm'),
      width: 110,
    },
    {
      title: 'Section',
      dataIndex: 'section',
      sorter: sorter('section'),
      filterDropdown,
      onFilter: onFilter('section'),
      width: 110,
    },
    {
      title: 'Batch Size',
      dataIndex: 'batchSize',
      sorter: sorter('batchSize'),
      filterDropdown,
      onFilter: onFilter('batchSize'),
    },
    {
      title: 'Pack Size',
      dataIndex: 'packSize',
      sorter: sorter('packSize'),
      filterDropdown,
      onFilter: onFilter('packSize'),
    },
    {
      title: 'Theorotical Yeild',
      dataIndex: 'theoriticalYield',
      sorter: sorter('theoriticalYield'),
      filterDropdown,
      onFilter: onFilter('theoriticalYield'),
    },
    {
      title: 'RM Rates',
      dataIndex: 'rmRate',
      sorter: sorter('rmRate'),
      filterDropdown,
      onFilter: onFilter('rmRate'),
    },
    {
      title: 'Last Selling Rate',
      dataIndex: 'lastRate',
      sorter: sorter('lastRate'),
      filterDropdown,
      onFilter: onFilter('lastRate'),
    },
    {
      title: 'GP(Throughput)',
      dataIndex: 'gp',
      sorter: sorter('gp'),
      filterDropdown,
      onFilter: onFilter('gp'),
    },
    {
      title: 'GP Percentage',
      dataIndex: 'gpPercantage',
      sorter: sorter('gpPercantage'),
      filterDropdown,
      onFilter: onFilter('gpPercantage'),
    },
    {
      title: 'Ideal Throughput/Shift',
      dataIndex: 'gpAmount',
      sorter: sorter('gpAmount'),
      filterDropdown,
      onFilter: onFilter('gpAmount'),
    },
    {
      title: 'Time Motion',
      dataIndex: 'timeMotion',
      sorter: sorter('timeMotion'),
      filterDropdown,
      onFilter: onFilter('timeMotion'),
    },
    {
      title: 'Constraint Unit',
      dataIndex: 'constraintUnit',
      sorter: sorter('constraintUnit'),
      filterDropdown,
      onFilter: onFilter('constraintUnit'),
    },
    {
      title: 'Throughput Per Constraint Unit',
      dataIndex: 'ThroughputPerContaintsUnit',
      sorter: sorter('ThroughputPerContaintsUnit'),
      filterDropdown,
      onFilter: onFilter('ThroughputPerContaintsUnit'),
    },
  ];

  return (
    <Structure>
      <>
        <Heading text="GP Report" />
        <Row>
          <Button onClick={exportGPReport}>Export</Button>
        </Row>
        <br />
        <Table
          columns={columns}
          bordered
          rowKey={record => record.id}
          pagination={{ defaultPageSize: 20, position: ['bottomLeft'], showTotal: total => `Total ${total} Records` }}
          dataSource={gpReport}
          size="small"
          scroll={{ x: 2200, y: window.innerHeight - 300 }}
        />
      </>
    </Structure>
  );
};

export default GPReport;

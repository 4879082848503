import React, { useEffect, Fragment } from 'react';
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';
import { Popconfirm, Table, Tooltip } from 'antd';
import { DeleteOutlined } from '@ant-design/icons';
import withStyles from 'react-jss';
import startCase from 'lodash/startCase';
import { getFormulationStockAdjustments, clearFormulationStockAdjustments } from '../Actions';
import { filterDropdown, onFilter, sorter } from '../utils';
import Structure from '../Structure/Structure';
import Heading from '../Components/Heading';
import Api from '../Api';
import { errorNotification, successNotification } from '../Snackbar';
import styles from '../CommonStyles';

const AdjustmentList = props => {
  const { classes } = props;

  const dispatch = useDispatch();

  const formulationStockAdjustments = useSelector(state => state.formulationStockAdjustments);

  useEffect(() => {
    dispatch(getFormulationStockAdjustments());
    return () => {
      dispatch(clearFormulationStockAdjustments());
    };
  }, []);

  const onDelete = async id => {
    try {
      await Api.delete(`/formulation-inventory/adjustment/${id}`);
      successNotification('Adjustment deleted');
      dispatch(getFormulationStockAdjustments());
    } catch (err) {
      errorNotification(err);
    }
  };

  const renderActionIcons = record => (
    <>
      <Tooltip placement="bottom" title="Delete">
        <Popconfirm
          title="Are you sure you want to delete?"
          onConfirm={() => onDelete(record.id)}
          okText="Yes"
          cancelText="No"
        >
          <DeleteOutlined className={classes.tableIcon} />
        </Popconfirm>
      </Tooltip>
    </>
  );

  const columns = [
    {
      title: 'Type',
      dataIndex: 'type',
      sorter: sorter('type'),
      filterDropdown,
      onFilter: onFilter('type'),
      render: type => startCase(type),
    },
    {
      title: 'Formulation',
      dataIndex: 'formulation',
      sorter: sorter('formulation'),
      filterDropdown,
      onFilter: onFilter('formulation'),
    },
    {
      title: 'Stock',
      dataIndex: 'quantity',
      sorter: sorter('quantity'),
      filterDropdown,
      onFilter: onFilter('quantity'),
    },
    {
      title: 'Remark',
      dataIndex: 'remarks',
      sorter: sorter('remarks'),
      filterDropdown,
      onFilter: onFilter('remarks'),
    },
    {
      title: 'Actions',
      width: 100,
      render: renderActionIcons,
    },
  ];

  return (
    <Structure>
      <>
        <Heading text="Formulation Adjustments" />
        <Table
          columns={columns}
          bordered
          rowKey={record => record.id}
          pagination={{ defaultPageSize: 20, position: ['bottomLeft'] }}
          dataSource={formulationStockAdjustments}
          size="small"
          scroll={{ x: 1024, y: window.innerHeight - 300 }}
        />
      </>
    </Structure>
  );
};

AdjustmentList.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(AdjustmentList);

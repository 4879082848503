import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { DatePicker, Row, Table } from 'antd';
import { Field, getFormValues, reduxForm } from 'redux-form';
import Modal from 'antd/lib/modal/Modal';
import PropTypes from 'prop-types';
import withStyles from 'react-jss';
import moment from 'moment';
import startCase from 'lodash/startCase';
import Structure from '../Structure/Structure';
import GetField from '../Components/GetField';
import { getSupplierMIS, clearSupplierMIS, getSupplierDelayedPOs, clearSupplierDelayedPOs } from '../Actions';
import { sorter, onFilter, filterDropdown } from '../utils';
import styles from '../CommonStyles';

const AntDatePicker = GetField(DatePicker);

const SupplierMIS = props => {
  const { classes, change } = props;
  const dispatch = useDispatch();

  const [isModalVisible, setIsModalVisible] = useState(false);
  const [supplierId, setSupplierId] = useState(null);

  const supplierMIS = useSelector(state => state.supplierMIS);
  const supplierDelayedPOs = useSelector(state => state.supplierDelayedPOs);

  const FormValues = useSelector(state => getFormValues('SupplierMIS')(state));

  useEffect(() => {
    change('from', moment().startOf('month'));
    change('to', moment().endOf('month'));
    return () => {
      dispatch(clearSupplierMIS());
      dispatch(clearSupplierDelayedPOs());
    };
  }, []);

  useEffect(() => {
    if (FormValues && FormValues.from && FormValues.to) {
      dispatch(getSupplierMIS(FormValues));
    }
  }, [FormValues && FormValues.from, FormValues && FormValues.to]);

  useEffect(() => {
    if (FormValues && FormValues.from && FormValues.to && supplierId) {
      dispatch(getSupplierDelayedPOs({ from: FormValues.from, to: FormValues.to, supplierId }));
    }
  }, [FormValues && FormValues.from, FormValues && FormValues.to, isModalVisible, supplierId]);

  const showModal = id => {
    setSupplierId(id);
    setIsModalVisible(true);
  };

  const hideModal = () => {
    setSupplierId(null);
    setIsModalVisible(false);
    dispatch(clearSupplierDelayedPOs());
  };

  const scoringColumn = record => {
    let score = +record.totalPurchaseOrders - +record.receivedOnTimePurchaseOrders;
    if (score === 0) {
      return <span style={{ color: 'green' }}>{score}</span>;
    }
    return <span style={{ color: 'red' }}>{(score *= -1)}</span>;
  };

  const columns = [
    {
      title: 'Supplier',
      dataIndex: 'name',
      sorter: sorter('name'),
      onFilter: onFilter('name'),
      filterDropdown,
    },
    {
      title: 'Total Purchase Order',
      dataIndex: 'totalPurchaseOrders',
      sorter: sorter('totalPurchaseOrders'),
      onFilter: onFilter('totalPurchaseOrders'),
      filterDropdown,
      align: 'right',
    },
    {
      title: 'Total PO Received On Time',
      dataIndex: 'receivedOnTimePurchaseOrders',
      sorter: sorter('receivedOnTimePurchaseOrders'),
      onFilter: onFilter('receivedOnTimePurchaseOrders'),
      filterDropdown,
      align: 'right',
    },
    {
      title: 'Score',
      render: scoringColumn,
      align: 'right',
    },
  ];

  const renderDelayedPOList = record => {
    const score = +record.totalPurchaseOrders - +record.receivedOnTimePurchaseOrders;
    if (score !== 0) {
      return (
        <a onClick={() => showModal(record.id)} onKeyPress={() => showModal(record.id)} role="link" tabIndex={0}>
          View
        </a>
      );
    }
  };

  columns.push({
    title: "Delayed PO's",
    render: renderDelayedPOList,
    align: 'right',
  });

  const delayedPOsColumns = [
    {
      title: 'Material',
      dataIndex: 'name',
      sorter: sorter('name'),
      onFilter: onFilter('name'),
      filterDropdown,
    },
    {
      title: 'Type',
      dataIndex: 'type',
      sorter: sorter('type'),
      onFilter: onFilter('type'),
      filterDropdown,
      render: type => startCase(type),
    },
  ];

  return (
    <Structure>
      <>
        <Row>
          <Field allowClear required name="from" placeholder="From" component={AntDatePicker} />
          &nbsp;
          <Field allowClear required name="to" placeholder="To" component={AntDatePicker} />
        </Row>
        <Table
          columns={columns}
          bordered
          rowKey={record => record.id}
          dataSource={supplierMIS}
          size="small"
          pagination={{
            defaultPageSize: 20,
            position: ['bottomLeft'],
            showTotal: total => `Total ${total} Items`,
          }}
          scroll={{ x: 1050, y: window.innerHeight - 300 }}
        />
        <Modal
          open={isModalVisible}
          onOk={hideModal}
          centered
          maskClosable={false}
          closable={false}
          title="Delayed PO's List."
          okText="Close"
          cancelButtonProps={{ className: classes.hide }}
          width="800px"
          okButtonProps={{ className: classes.button }}
        >
          <Table
            columns={delayedPOsColumns}
            bordered
            rowKey={record => record.name}
            dataSource={supplierDelayedPOs}
            size="small"
            pagination={{
              defaultPageSize: 20,
              position: ['bottomLeft'],
              showTotal: total => `Total ${total} Items`,
            }}
            scroll={{ x: 425 }}
          />
        </Modal>
      </>
    </Structure>
  );
};

SupplierMIS.propTypes = {
  classes: PropTypes.object.isRequired,
  change: PropTypes.func.isRequired,
};

const StyledSupplierMIS = withStyles(styles)(SupplierMIS);

const FormedSupplierMIS = reduxForm({
  form: 'SupplierMIS',
  enableReinitialize: true,
})(StyledSupplierMIS);

export default FormedSupplierMIS;

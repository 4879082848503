import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import withStyles from 'react-jss';
import { reduxForm, Field, getFormValues } from 'redux-form';
import { useHistory } from 'react-router';
import { useDispatch, useSelector } from 'react-redux';
import { Button, Col, Input, Modal, Popconfirm, Row, Select } from 'antd';
import startCase from 'lodash/startCase';
import Structure from '../Structure/Structure';
import { errorNotification, successNotification } from '../Snackbar';
import Api from '../Api';
import styles from '../CommonStyles';
import LeftGrid from '../Components/LeftGrid';
import RightGrid from '../Components/RightGrid';
import GetField from '../Components/GetField';
import { getFormulationIdList, clearFormulationIdList } from '../Actions';
import Heading from '../Components/Heading';
import { get } from '../utils';

const AntInput = GetField(Input);
const AntSelect = GetField(Select);

const AdjustmentForm = props => {
  const { handleSubmit, classes } = props;
  const history = useHistory();
  const dispatch = useDispatch();

  const formulationIdList = useSelector(state => state.formulationIdList);

  const [formValueModal, setFormValuesModal] = useState(false);
  const [confirmLoading, setConfirmLoading] = useState(false);
  const [preview, setPreview] = useState({});
  const FormValues = useSelector(state => getFormValues('FormulationStockAdjustmentForm')(state));

  useEffect(() => {
    dispatch(getFormulationIdList());
    return () => {
      dispatch(clearFormulationIdList());
    };
  }, []);

  const showFormValuesModal = () => {
    const _preview = {
      type: get(FormValues, ['type']) ? startCase(get(FormValues, ['type'])) : '--',
      formulation: '--',
      section: '--',
      quantity: get(FormValues, ['quantity'], '--'),
      remarks: get(FormValues, ['remarks'], '--'),
    };

    const formulationId = get(FormValues, ['formulationId'], null);
    if (formulationId) {
      const formulationObj = formulationIdList.find(formulation => formulation.id === +formulationId);
      if (formulationObj) {
        _preview.formulation = formulationObj.name;
        _preview.section = formulationObj.section;
      }
    }

    setPreview(_preview);
    setFormValuesModal(true);
  };

  const hideFormValuesModal = () => {
    setFormValuesModal(false);
    setPreview({});
  };

  const onSubmit = async () => {
    try {
      setConfirmLoading(true);
      await Api.post('/formulation-inventory/adjustment', FormValues);
      successNotification('Stock adjusted');
      setConfirmLoading(false);
      hideFormValuesModal();
      history.push('/formulations/inventory/adjustment/list');
    } catch (err) {
      setConfirmLoading(false);
      errorNotification(err);
    }
  };

  return (
    <Structure>
      <>
        <Heading text="Formulation Stock Adjustment" />
        <form onSubmit={handleSubmit(onSubmit)}>
          <Row>
            <LeftGrid>
              <Field label="Type" allowClear required name="type" component={AntSelect}>
                <Select.Option key={null} value="" disabled>
                  Select type
                </Select.Option>
                <Select.Option key="stockIn" value="stockIn">
                  Stock In
                </Select.Option>
                <Select.Option key="stockOut" value="stockOut">
                  Stock Out
                </Select.Option>
              </Field>
            </LeftGrid>
            <RightGrid>
              <Field label="Finished Good Name" allowClear required name="formulationId" component={AntSelect}>
                <Select.Option key={null} value="" disabled>
                  Select finished good name
                </Select.Option>
                {formulationIdList.map(formulation => (
                  <Select.Option key={formulation.id} value={formulation.id}>
                    {formulation.name}
                  </Select.Option>
                ))}
              </Field>
            </RightGrid>
          </Row>
          <Row>
            <LeftGrid>
              <Field label="Section" allowClear disabled required name="formulationId" component={AntSelect}>
                <Select.Option key={null} value="" disabled>
                  Select finished good name
                </Select.Option>
                {formulationIdList.map(formulation => (
                  <Select.Option key={formulation.id} value={formulation.id}>
                    {formulation.section}
                  </Select.Option>
                ))}
              </Field>
            </LeftGrid>
            <RightGrid>
              <Field
                label="Quantity"
                required
                allowClear
                type="number"
                min={0}
                name="quantity"
                placeholder="Quantity"
                component={AntInput}
              />
            </RightGrid>
          </Row>
          <Row>
            <LeftGrid>
              <Field label="Remark" required allowClear name="remarks" placeholder="Remark" component={AntInput} />
            </LeftGrid>
          </Row>
          <Row className={classes.buttonContainer}>
            <Button className={classes.button} type="primary" onClick={showFormValuesModal}>
              Preview
            </Button>
          </Row>
        </form>
        <Modal
          onCancel={hideFormValuesModal}
          open={formValueModal}
          confirmLoading={confirmLoading}
          centered
          width="800px"
          maskClosable={false}
          closable={false}
          cancelText="Back"
          okButtonProps={{ className: classes.button }}
          okText={
            <Popconfirm
              title="Sure want to submit form?"
              onConfirm={() => onSubmit()}
              okText="Confirm"
              cancelText="Cancel"
            >
              Submit
            </Popconfirm>
          }
        >
          <Row>
            <Col xs={24} lg={12}>
              <p>
                Type:
                <span className={classes.detailColor}>{preview.type}</span>
              </p>
            </Col>
            <Col xs={24} lg={12}>
              <p>
                Formulation:
                <span className={classes.detailColor}>{preview.formulation}</span>
              </p>
            </Col>
          </Row>
          <Row>
            <Col xs={24} lg={12}>
              <p>
                Quantity:
                <span className={classes.detailColor}>{preview.quantity}</span>
              </p>
            </Col>
            <Col xs={24} lg={12}>
              <p>
                Reason:
                <span className={classes.detailColor}>{preview.remarks}</span>
              </p>
            </Col>
          </Row>
        </Modal>
      </>
    </Structure>
  );
};

AdjustmentForm.propTypes = {
  handleSubmit: PropTypes.func.isRequired,
  classes: PropTypes.object.isRequired,
};

const StyledFormulationAdjustmentForm = withStyles(styles)(AdjustmentForm);

const FormedFormulationAdjustmentForm = reduxForm({
  form: 'FormulationStockAdjustmentForm',
})(StyledFormulationAdjustmentForm);

export default FormedFormulationAdjustmentForm;

import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Button, DatePicker, Modal } from 'antd';
import { CloseOutlined } from '@ant-design/icons';
import { Controller, useForm } from 'react-hook-form';
import Api from '../Api';
import { errorNotification, successNotification } from '../Snackbar';
import GetField from '../Components/GetRHFField';

const AntDatePicker = GetField(DatePicker);

const DispatchDateModal = props => {
  const { recordId, hideModal, order } = props;

  const { handleSubmit, control } = useForm({
    defaultValues: {
      estDispatchDate: new Date(order.estDispatchDate).toISOString(),
    },
  });

  const [ConfirmLoading, SetConfirmLoading] = useState(false);

  const onSubmit = async formValues => {
    try {
      SetConfirmLoading(true);

      await Api.put(`/orders/dispatchdate/${recordId}`, formValues);

      successNotification('Est. dispatch date updated');
      hideModal();
    } catch (error) {
      errorNotification(error);
      SetConfirmLoading(false);
    }
  };

  return (
    <Modal
      open
      maskClosable={false}
      okText="Close"
      onOk={hideModal}
      cancelButtonProps={{ style: { display: 'none' } }}
      width={600}
      title="Update Dispatch Dates"
      closeIcon={<CloseOutlined onClick={hideModal} />}
    >
      <form onSubmit={handleSubmit(onSubmit)}>
        <Controller
          name="estDispatchDate"
          control={control}
          render={({ field }) => (
            <AntDatePicker
              {...field}
              showTime={{ format: 'hh:mm A' }}
              format="YYYY-MM-DD HH:mm A"
              placeholder="Esp. Dispatch Date"
              label="Esp. Dispatch Date"
            />
          )}
        />
        <Button htmlType="submit" type="primary" loading={ConfirmLoading}>
          Submit
        </Button>
      </form>
    </Modal>
  );
};

DispatchDateModal.propTypes = {
  recordId: PropTypes.number.isRequired,
  order: PropTypes.object.isRequired,
  hideModal: PropTypes.func.isRequired,
};

export default DispatchDateModal;

import React from 'react';
import PropTypes from 'prop-types';
import { Col } from 'antd';

const LeftGrid = props => {
  const { children, style, className } = props;

  return (
    <Col
      xs={{ span: 20, offset: 2 }}
      sm={{ span: 20, offset: 2 }}
      md={{ span: 20, offset: 2 }}
      lg={{ span: 10, offset: 1 }}
      xl={{ span: 9, offset: 2 }}
      style={style}
      className={className}
    >
      {children}
    </Col>
  );
};

LeftGrid.propTypes = {
  children: PropTypes.oneOfType([PropTypes.element, PropTypes.string, PropTypes.number, PropTypes.array]),
  style: PropTypes.object,
  className: PropTypes.string,
};

export default LeftGrid;

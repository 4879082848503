import React, { useEffect, useRef, useState } from 'react';
import withStyles from 'react-jss';
import { useDispatch, useSelector } from 'react-redux';
import { Input, Select, Button, Row, Form, Space, Descriptions, Table, Modal } from 'antd';
import startCase from 'lodash/startCase';
import { MinusCircleOutlined } from '@ant-design/icons';
import { useReactToPrint } from 'react-to-print';
import PropTypes from 'prop-types';
import Structure from '../Structure/Structure';
import styles from '../CommonStyles';
import LeftGrid from '../Components/LeftGrid';
import RightGrid from '../Components/RightGrid';
import {
  getMaterialIdList,
  clearMaterialIdList,
  getFormulationIdList,
  clearFormulationIdList,
  clearFormulationForCosting,
  getFormulationForCosting,
} from '../Actions';
import { get } from '../utils';

const { Option } = Select;

const FormulationCosting = props => {
  const { classes } = props;

  const [form] = Form.useForm();
  const dispatch = useDispatch();

  const materialIdList = useSelector(state => state.materialIdList);
  const formulationIdList = useSelector(state => state.formulationIdList);
  const formulationForCosting = useSelector(state => state.formulationForCosting);

  const [viewModal, setViewModal] = useState(false);
  const [MaterialIndexes, setMaterialIndexes] = useState({});
  const [formulationData, setFormulationData] = useState({});

  const printRef = useRef();
  const handlePrint = useReactToPrint({
    content: () => printRef.current,
    pageStyle: '',
  });

  useEffect(() => {
    dispatch(getMaterialIdList());
    dispatch(getFormulationIdList());
    return () => {
      dispatch(clearMaterialIdList());
      dispatch(clearFormulationIdList());
      dispatch(clearFormulationForCosting());
    };
  }, []);

  useEffect(() => {
    const newMaterialIndexes = {};
    materialIdList.map((el, index) => {
      newMaterialIndexes[+el.id] = index;
    });
    setMaterialIndexes(newMaterialIndexes);
  }, [materialIdList.length]);

  useEffect(() => {
    if (formulationForCosting) {
      const fields = form.getFieldsValue();
      let { recipe, category, subcategory, dosageForm, section, unit, hsn, remarks, batchSize, packSize } = fields;
      recipe = get(formulationForCosting, ['recipe'], []);
      category = get(formulationForCosting, ['category'], []);
      subcategory = get(formulationForCosting, ['subcategory'], []);
      dosageForm = get(formulationForCosting, ['dosageForm'], []);
      section = get(formulationForCosting, ['section'], []);
      unit = get(formulationForCosting, ['unit'], []);
      hsn = get(formulationForCosting, ['hsn'], []);
      remarks = get(formulationForCosting, ['remarks'], []);
      batchSize = get(formulationForCosting, ['batchSize'], []);
      packSize = get(formulationForCosting, ['packSize'], []);
      form.setFieldsValue({
        recipe,
        category,
        subcategory,
        dosageForm,
        section,
        unit,
        hsn,
        remarks,
        batchSize,
        packSize,
      });
    }
  }, [formulationForCosting]);

  const handleSelectChange = value => {
    dispatch(clearFormulationForCosting());
    dispatch(getFormulationForCosting(value));
  };

  const hideModal = () => {
    setViewModal(false);
    setFormulationData({});
  };

  const setRates = () => {
    const fields = form.getFieldsValue();
    const { recipe } = fields;

    recipe.map(el => {
      if (!el.rate) {
        const index = MaterialIndexes[+el.materialId];
        const material = materialIdList[index];
        el.rate = material.lastPurchase ? material.lastPurchase.rate : null;
      }
    });
    form.setFieldsValue({
      recipe,
    });
  };

  const onFinish = values => {
    const formulationName = get(formulationForCosting, ['name'], 0);
    const materialArr = [];
    let totalCost = 0;
    let theoroticalYeild = 0;
    let totalCostPlusBuffer = 0;
    let costPerUnit = 0;
    let gp = 0;
    let gpPercentage = 0;

    const recipe = get(values, ['recipe'], []);

    recipe.map(el => {
      const index = MaterialIndexes[+el.materialId];

      const { name, type, unit } = materialIdList[index];
      const cost = +el.quantity * +el.rate;
      totalCost += cost;

      materialArr.push({
        id: el.materialId,
        name,
        type,
        unit,
        quantity: +el.quantity,
        rate: +el.rate,
        cost: (+el.quantity * +el.rate).toFixed(2),
      });
    });

    totalCost = +totalCost.toFixed(2);
    theoroticalYeild = (+get(values, ['batchSize'], 0) / +get(values, ['packSize'], 0)).toFixed(2);
    totalCostPlusBuffer = (totalCost + (totalCost * +get(values, ['buffer'], 0)) / 100).toFixed(2);
    costPerUnit = (totalCostPlusBuffer / theoroticalYeild).toFixed(2);
    gp = (+get(values, ['sellingPrice'], 0) - costPerUnit).toFixed(2);
    gpPercentage = (gp / +get(values, ['sellingPrice'], 0)).toFixed(2);

    setFormulationData({
      ...values,
      materialArr,
      formulationName,
      totalCost,
      theoroticalYeild,
      totalCostPlusBuffer,
      costPerUnit,
      gp,
      gpPercentage,
    });

    setViewModal(true);
  };

  const materialTableColumn = [
    {
      title: 'Material',
      dataIndex: 'name',
    },
    {
      title: 'Type',
      dataIndex: 'type',
      render: type => startCase(type),
    },
    {
      title: 'Unit',
      dataIndex: 'unit',
    },
    {
      title: 'Quantity',
      dataIndex: 'quantity',
      align: 'right',
    },
    {
      title: 'Rate',
      dataIndex: 'rate',
      align: 'right',
    },
    {
      title: 'Cost',
      dataIndex: 'cost',
      align: 'right',
    },
  ];

  return (
    <Structure>
      <>
        <Form onFinish={onFinish} form={form}>
          <Row>
            <LeftGrid>
              <Form.Item name="formulationId" label="Formulation">
                <Select
                  allowClear
                  placeholder="Select formulation"
                  showSearch
                  optionFilterProp="children"
                  filterOption={(input, option) => option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}
                  onChange={handleSelectChange}
                >
                  {formulationIdList.map(formulation => (
                    <Option key={formulation.id} value={formulation.id}>
                      {formulation.name}
                    </Option>
                  ))}
                </Select>
              </Form.Item>
            </LeftGrid>
            <RightGrid>
              <Form.Item name="category" label="Category">
                <Input disabled />
              </Form.Item>
            </RightGrid>
          </Row>
          <Row>
            <LeftGrid>
              <Form.Item name="subcategory" label="Subcategory">
                <Input disabled />
              </Form.Item>
            </LeftGrid>
            <RightGrid>
              <Form.Item name="dosageForm" label="Dosage Form">
                <Input disabled />
              </Form.Item>
            </RightGrid>
          </Row>
          <Row>
            <LeftGrid>
              <Form.Item name="section" label="Section">
                <Input disabled />
              </Form.Item>
            </LeftGrid>
            <RightGrid>
              <Form.Item name="unit" label="Unit">
                <Input disabled />
              </Form.Item>
            </RightGrid>
          </Row>
          <Row>
            <LeftGrid>
              <Form.Item name="hsn" label="HSN">
                <Input disabled />
              </Form.Item>
            </LeftGrid>
            <RightGrid>
              <Form.Item name="remarks" label="Remarks">
                <Input disabled />
              </Form.Item>
            </RightGrid>
          </Row>
          <Row>
            <LeftGrid>
              <Form.Item name="batchSize" label="Batch Size">
                <Input type="number" min={0} step="any" />
              </Form.Item>
            </LeftGrid>
            <RightGrid>
              <Form.Item name="packSize" label="Average/Fill Weight or Fill Volume">
                <Input type="number" min={0} step="any" />
              </Form.Item>
            </RightGrid>
          </Row>
          <Row>
            <LeftGrid>
              <Form.Item name="buffer" label="Buffer (in %)">
                <Input type="number" min={0} step="any" />
              </Form.Item>
            </LeftGrid>
            <RightGrid>
              <Form.Item name="sellingPrice" label="Selling Price">
                <Input type="number" min={0} step="any" />
              </Form.Item>
            </RightGrid>
          </Row>
          <Form.List name="recipe">
            {(fields, { add, remove }) => (
              <>
                {fields.map(field => (
                  <Row>
                    <Space key={field.key} align="baseline">
                      <Form.Item
                        noStyle
                        shouldUpdate={(prevValues, curValues) => prevValues.materialId !== curValues.materialId}
                      >
                        {() => (
                          <>
                            <Form.Item
                              {...field}
                              label="Material"
                              name={[field.name, 'materialId']}
                              fieldKey={[field.fieldKey, 'materialId']}
                            >
                              <Select
                                style={{ width: 240 }}
                                placeholder="Select material"
                                showSearch
                                optionFilterProp="children"
                                filterOption={(input, option) =>
                                  option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                                }
                              >
                                {materialIdList.map(material => (
                                  <Option key={material.id} value={material.id}>
                                    {material.name}
                                  </Option>
                                ))}
                              </Select>
                            </Form.Item>
                          </>
                        )}
                      </Form.Item>
                      <Form.Item {...field} name={[field.name, 'materialId']} fieldKey={[field.fieldKey, 'materialId']}>
                        <Select disabled style={{ width: 155 }} placeholder="Select material" showArrow={false}>
                          {materialIdList.map(material => (
                            <Option key={material.id} value={material.id}>
                              {startCase(material.type)}
                            </Option>
                          ))}
                        </Select>
                      </Form.Item>
                      <Form.Item {...field} name={[field.name, 'materialId']} fieldKey={[field.fieldKey, 'materialId']}>
                        <Select disabled style={{ width: 45 }} placeholder="Select material" showArrow={false}>
                          {materialIdList.map(material => (
                            <Option key={material.id} value={material.id}>
                              {material.unit}
                            </Option>
                          ))}
                        </Select>
                      </Form.Item>
                      <Form.Item
                        {...field}
                        label="Qty"
                        name={[field.name, 'quantity']}
                        fieldKey={[field.fieldKey, 'quantity']}
                      >
                        <Input type="number" min={0} step="any" style={{ width: 110 }} />
                      </Form.Item>
                      <Form.Item
                        label="Last Pur. Rate"
                        {...field}
                        name={[field.name, 'materialId']}
                        fieldKey={[field.fieldKey, 'materialId']}
                      >
                        <Select disabled style={{ width: 110 }} placeholder="Select material" showArrow={false}>
                          {materialIdList.map(material => (
                            <Option key={material.id} value={material.id}>
                              {material.lastPurchase ? material.lastPurchase.rate : null}
                            </Option>
                          ))}
                        </Select>
                      </Form.Item>
                      <Form.Item
                        {...field}
                        label="Rate"
                        name={[field.name, 'rate']}
                        fieldKey={[field.fieldKey, 'rate']}
                      >
                        <Input type="number" min={0} step="any" style={{ width: 110 }} />
                      </Form.Item>
                      <MinusCircleOutlined onClick={() => remove(field.name)} />
                    </Space>
                  </Row>
                ))}
                <Row>
                  <Form.Item>
                    <Button type="primary" onClick={() => add()}>
                      Add Row
                    </Button>
                  </Form.Item>
                </Row>
              </>
            )}
          </Form.List>
          <Row>
            <Form.Item>
              <Button type="primary" onClick={() => setRates()}>
                Set Rates
              </Button>
              &nbsp;&nbsp;
              <Button type="primary" htmlType="submit">
                Get Formulation
              </Button>
            </Form.Item>
          </Row>
        </Form>
        <Modal
          open={viewModal}
          onOk={hideModal}
          onCancel={handlePrint}
          maskClosable={false}
          closable={false}
          centered
          width="1000px"
          okText="Close"
          cancelText="Print"
          cancelButtonProps={{ className: classes.button }}
          okButtonProps={{ className: classes.button }}
        >
          <div style={{ padding: '10px' }} ref={printRef}>
            <Descriptions column={2} size="small" title="Formulation Details" bordered>
              <Descriptions.Item label="Finished Good Name">
                {get(formulationData, ['formulationName'], '--')}
              </Descriptions.Item>
              <Descriptions.Item label="Category">{get(formulationData, ['category'], '--')}</Descriptions.Item>
              <Descriptions.Item label="Subcategory">{get(formulationData, ['subcategory'], '--')}</Descriptions.Item>
              <Descriptions.Item label="Dosage Form">{get(formulationData, ['dosageForm'], '--')}</Descriptions.Item>
              <Descriptions.Item label="Section">{get(formulationData, ['section'], '--')}</Descriptions.Item>
              <Descriptions.Item label="Unit">{get(formulationData, ['unit'], '--')}</Descriptions.Item>
              <Descriptions.Item label="HSN Code">{get(formulationData, ['hsn'], '--')}</Descriptions.Item>
              <Descriptions.Item label="Remarks">{get(formulationData, ['remarks'], '--')}</Descriptions.Item>
              <Descriptions.Item label="Batch Size">{get(formulationData, ['batchSize'], '--')}</Descriptions.Item>
              <Descriptions.Item label="Average/Fill Weight or Fill Volume">
                {get(formulationData, ['packSize'], '--')}
              </Descriptions.Item>
              <Descriptions.Item label="Theorotical Yeild">
                {get(formulationData, ['theoroticalYeild'], '--')}
              </Descriptions.Item>
              <Descriptions.Item label="Total Cost">{get(formulationData, ['totalCost'], '--')}</Descriptions.Item>
              <Descriptions.Item label="Buffer (in %)">{get(formulationData, ['buffer'], '--')}</Descriptions.Item>
              <Descriptions.Item label="Total Cost + Buffer Cost">
                {get(formulationData, ['totalCostPlusBuffer'], '--')}
              </Descriptions.Item>
              <Descriptions.Item label="Cost Per Unit">{get(formulationData, ['costPerUnit'], '--')}</Descriptions.Item>
              <Descriptions.Item label="Selling Price">
                {get(formulationData, ['sellingPrice'], '--')}
              </Descriptions.Item>
              <Descriptions.Item label="GP">{get(formulationData, ['gp'], '--')}</Descriptions.Item>
              <Descriptions.Item label="GP (in %)">{get(formulationData, ['gpPercentage'], '--')}</Descriptions.Item>
            </Descriptions>
            <br />
            <Table
              columns={materialTableColumn}
              bordered
              rowKey={record => record.id}
              pagination={false}
              dataSource={get(formulationData, ['materialArr'], [])}
              size="small"
              scroll={{ x: 425 }}
            />
          </div>
        </Modal>
      </>
    </Structure>
  );
};

FormulationCosting.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(FormulationCosting);

import Api from '../Api';

export const getCalendarEvents = object => async dispatch => {
  try {
    const { data } = await Api.get('/calendar/events', object);

    dispatch({
      type: 'FETCH_CALENDAR_EVENTS',
      payload: data,
    });
  } catch (err) {
    throw err;
  }
};

export const clearCalendarEvents = () => {
  return {
    type: 'CLEAR_CALENDAR_EVENTS',
  };
};

export const getCustomerCalendarEvents = () => async dispatch => {
  try {
    const { data } = await Api.get('/calendar/customer/events');

    dispatch({
      type: 'FETCH_CUSTOMER_CALENDAR_EVENTS',
      payload: data,
    });
  } catch (err) {
    throw err;
  }
};

export const clearCustomerCalendarEvents = () => {
  return {
    type: 'CLEAR_CUSTOMER_CALENDAR_EVENTS',
  };
};

export const getSupplierCalendarEvents = () => async dispatch => {
  try {
    const { data } = await Api.get('/calendar/supplier/events');

    dispatch({
      type: 'FETCH_SUPPLIER_CALENDAR_EVENTS',
      payload: data,
    });
  } catch (err) {
    throw err;
  }
};

export const clearSupplierCalendarEvents = () => {
  return {
    type: 'CLEAR_SUPPLIER_CALENDAR_EVENTS',
  };
};

export const purchaseList = (state = [], action) => {
  if (action.type === 'FETCH_PURCHASE_LIST') {
    return action.payload;
  }
  if (action.type === 'CLEAR_PURCHASE_LIST') {
    return [];
  }
  return state;
};

export const purchaseFilesList = (state = [], action) => {
  if (action.type === 'FETCH_PURCHASE_FILES_LIST') {
    return action.payload;
  }
  if (action.type === 'CLEAR_PURCHASE_FILES_LIST') {
    return [];
  }
  return state;
};

export const purchase = (state = {}, action) => {
  if (action.type === 'FETCH_PURCHASE') {
    return action.payload;
  }
  if (action.type === 'CLEAR_PURCHASE') {
    return {};
  }
  return state;
};

import Api from '../Api';

export const getPatientList = () => async dispatch => {
  try {
    const { data } = await Api.get('/patients');
    dispatch({
      type: 'FETCH_PATIENT_LIST',
      payload: data,
    });
  } catch (err) {
    throw err;
  }
};

export const clearPatientList = () => {
  return {
    type: 'CLEAR_PATIENT_LIST',
  };
};

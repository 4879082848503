import React, { useState, Fragment } from 'react';
import PropTypes from 'prop-types';
import withStyles from 'react-jss';
import { useHistory } from 'react-router';
import { useForm, Controller } from 'react-hook-form';
import { Button, Input, Select, Row } from 'antd';

import LeftGrid from '../../Components/LeftGrid';
import RightGrid from '../../Components/RightGrid';
import GetField from '../../Components/GetRHFField';
import styles from '../../CommonStyles';
import Structure from '../../Structure/Structure';
import Api from '../../Api';
import { successNotification, errorNotification } from '../../Snackbar';
import Heading from '../../Components/Heading';
import constants from '../../constants';

const AntSelect = GetField(Select);
const AntInput = GetField(Input);

const AddLead = props => {
  const { classes } = props;
  const history = useHistory();

  const { handleSubmit, control, watch, setValue } = useForm();

  const [confirmLoading, setConfirmLoading] = useState(false);

  const onSubmit = async formValues => {
    try {
      setConfirmLoading(true);
      await Api.post('/leads', formValues);
      successNotification('Lead added');
      setConfirmLoading(false);
      history.push('/leads/list');
    } catch (err) {
      setConfirmLoading(false);
      errorNotification(err);
    }
  };

  return (
    <Structure>
      <>
        <Heading text="Add Lead" />
        <form onSubmit={handleSubmit(onSubmit)}>
          <Row>
            <LeftGrid>
              <Controller
                name="company"
                control={control}
                render={({ field }) => <AntInput allowClear label="Company" placeholder="Company" {...field} />}
              />
            </LeftGrid>
            <RightGrid>
              <Controller
                name="category"
                control={control}
                render={({ field }) => (
                  <AntSelect
                    allowClear
                    label="Category"
                    placeholder="Select category"
                    {...field}
                    onChange={value => {
                      field.onChange(value);
                      setValue('source', null);
                    }}
                  >
                    {Object.values(constants.leads.category).map(el => (
                      <Select.Option key={el} value={el}>
                        {el}
                      </Select.Option>
                    ))}
                  </AntSelect>
                )}
              />
            </RightGrid>
          </Row>
          <Row>
            <LeftGrid>
              <Controller
                name="email"
                control={control}
                render={({ field }) => <AntInput allowClear label="Email" placeholder="Email" {...field} />}
              />
            </LeftGrid>
            <RightGrid>
              <Controller
                name="name"
                control={control}
                render={({ field }) => <AntInput allowClear label="Name" placeholder="Name" {...field} />}
              />
            </RightGrid>
          </Row>
          <Row>
            <LeftGrid>
              <Controller
                name="designation"
                control={control}
                render={({ field }) => <AntInput allowClear label="Designation" placeholder="Designation" {...field} />}
              />
            </LeftGrid>
            <RightGrid>
              <Controller
                name="website"
                control={control}
                render={({ field }) => <AntInput allowClear label="Website" placeholder="Website" {...field} />}
              />
            </RightGrid>
          </Row>
          <Row>
            <LeftGrid>
              <Controller
                name="contact"
                control={control}
                render={({ field }) => <AntInput allowClear label="Mobile" placeholder="Mobile" {...field} />}
              />
            </LeftGrid>
            <RightGrid>
              <Controller
                name="address"
                control={control}
                render={({ field }) => <AntInput allowClear label="Address" placeholder="Address" {...field} />}
              />
            </RightGrid>
          </Row>
          <Row>
            <LeftGrid>
              <Controller
                name="city"
                control={control}
                render={({ field }) => <AntInput allowClear label="City" placeholder="City" {...field} />}
              />
            </LeftGrid>
            <RightGrid>
              <Controller
                name="state"
                control={control}
                render={({ field }) => <AntInput allowClear label="State" placeholder="State" {...field} />}
              />
            </RightGrid>
          </Row>
          <Row>
            <LeftGrid>
              <Controller
                name="country"
                control={control}
                render={({ field }) => <AntInput allowClear label="Country" placeholder="Country" {...field} />}
              />
            </LeftGrid>
            <RightGrid>
              <Controller
                name="postalCode"
                control={control}
                render={({ field }) => <AntInput allowClear label="Postal Code" placeholder="Postal Code" {...field} />}
              />
            </RightGrid>
          </Row>
          <Row>
            <LeftGrid>
              <Controller
                name="source"
                control={control}
                render={({ field }) => (
                  <AntSelect allowClear label="Source" placeholder="Select source" {...field}>
                    {Object.values(constants.leads.sources[watch('category')] || {}).map(el => (
                      <Select.Option key={el} value={el}>
                        {el}
                      </Select.Option>
                    ))}
                  </AntSelect>
                )}
              />
            </LeftGrid>
            <RightGrid>
              <Controller
                name="gstNo"
                control={control}
                render={({ field }) => <AntInput allowClear label="GSTIN" placeholder="GSTIN" {...field} />}
              />
            </RightGrid>
          </Row>
          <Row>
            <LeftGrid>
              <Controller
                name="remark"
                control={control}
                render={({ field }) => <AntInput allowClear label="Remark" placeholder="Remark" {...field} />}
              />
            </LeftGrid>
          </Row>
          <Row className={classes.buttonContainer}>
            <Button type="primary" loading={confirmLoading} htmlType="submit">
              Submit
            </Button>
          </Row>
        </form>
      </>
    </Structure>
  );
};

AddLead.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(AddLead);

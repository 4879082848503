import Api from '../Api';

export const getLeads = query => async dispatch => {
  const { data } = await Api.get('/leads', query);
  dispatch({
    type: 'FETCH_LEADS',
    payload: data,
  });
};

export const clearLeads = () => {
  return {
    type: 'CLEAR_LEADS',
  };
};

export const getLead = id => async dispatch => {
  const { data } = await Api.get(`/leads/${id}`);
  dispatch({
    type: 'FETCH_LEAD',
    payload: data,
  });
};

export const clearLead = () => {
  return {
    type: 'CLEAR_LEAD',
  };
};

export const getLeadIds = query => async dispatch => {
  const { data } = await Api.get('/leads/ids', query);
  dispatch({
    type: 'FETCH_LEAD_IDS',
    payload: data,
  });
};

export const clearLeadIds = () => {
  return {
    type: 'CLEAR_LEAD_IDS',
  };
};

export const getInteractions = query => async dispatch => {
  const { data } = await Api.get(`/leads/interactions`, query);
  dispatch({
    type: 'FETCH_INTERACTIONS',
    payload: data,
  });
};

export const clearInteractions = () => {
  return {
    type: 'CLEAR_INTERACTIONS',
  };
};

export const getLeadQueries = query => async dispatch => {
  const { data } = await Api.get(`/leads/queries/ids`, query);
  dispatch({
    type: 'FETCH_LEAD_QUERIES',
    payload: data,
  });
};

export const clearLeadQueries = () => {
  return {
    type: 'CLEAR_LEAD_QUERIES',
  };
};

export const getLeadQuery = id => async dispatch => {
  const { data } = await Api.get(`/leads/queries/${id}`);
  dispatch({
    type: 'FETCH_LEAD_QUERY',
    payload: data,
  });
};

export const clearLeadQuery = () => {
  return {
    type: 'CLEAR_LEAD_QUERY',
  };
};

export const getQueryFilesList = id => async dispatch => {
  try {
    const { data } = await Api.get(`/leads/queries/${id}/files`);
    dispatch({
      type: 'FETCH_QUERY_FILES_LIST',
      payload: data,
    });
  } catch (err) {
    throw err;
  }
};

export const clearQueryFilesList = () => {
  return {
    type: 'CLEAR_QUERY_FILES_LIST',
  };
};

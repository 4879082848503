import Api from '../../Api';

export const getGenericList = () => async dispatch => {
  try {
    const { data } = await Api.get('/dropdowns/generic-formulations');
    dispatch({
      type: 'FETCH_GENERIC_LIST',
      payload: data,
    });
  } catch (err) {
    throw err;
  }
};

export const clearGenericList = () => {
  return {
    type: 'CLEAR_GENERIC_LIST',
  };
};

export const getGeneric = id => async dispatch => {
  try {
    const { data } = await Api.get(`/dropdowns/generic-formulations/${id}`);
    dispatch({
      type: 'FETCH_GENERIC',
      payload: data,
    });
  } catch (err) {
    throw err;
  }
};

export const clearGeneric = () => {
  return {
    type: 'CLEAR_GENERIC',
  };
};

import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { reduxForm, Field, getFormValues } from 'redux-form';
import withStyles from 'react-jss';
import { Button, Row, Modal, Col, Popconfirm, DatePicker, Input } from 'antd';
import { useHistory } from 'react-router';
import { useSelector } from 'react-redux';
import moment from 'moment';
import Heading from '../Components/Heading';
import Api from '../Api';
import Structure from '../Structure/Structure';
import styles from '../CommonStyles';
import { successNotification, errorNotification } from '../Snackbar';
import LeftGrid from '../Components/LeftGrid';
import RightGrid from '../Components/RightGrid';
import GetField from '../Components/GetField';
import { get } from '../utils';

const AntInput = GetField(Input);
const AntDatePicker = GetField(DatePicker);

const NewHoliday = props => {
  const { handleSubmit, classes } = props;
  const history = useHistory();

  const [formValueModal, setFormValuesModal] = useState(false);
  const [confirmLoading, setConfirmLoading] = useState(false);
  const [preview, setPreview] = useState({});
  const FormValues = useSelector(state => getFormValues('NewHoliday')(state));

  const showFormValuesModal = () => {
    const holiday = get(FormValues, ['holiday']);

    const _preview = {
      description: get(FormValues, ['description'], '--'),
      holiday: holiday ? moment(holiday).format('DD MMM YYYY') : '--',
    };

    setPreview(_preview);
    setFormValuesModal(true);
  };

  const hideFormValuesModal = () => {
    setFormValuesModal(false);
    setPreview({});
  };

  const onSubmit = async () => {
    try {
      setConfirmLoading(true);
      await Api.post('/holidays', FormValues);
      successNotification('Holiday added');
      setConfirmLoading(false);
      hideFormValuesModal();
      history.push('/holiday/list');
    } catch (err) {
      setConfirmLoading(false);
      errorNotification(err);
    }
  };

  return (
    <Structure>
      <>
        <Heading text="New Holiday" />
        <form onSubmit={handleSubmit(onSubmit)}>
          <Row>
            <LeftGrid>
              <Field
                label="Description"
                required
                allowClear
                name="description"
                placeholder="Description"
                component={AntInput}
              />
            </LeftGrid>
            <RightGrid>
              <Field
                allowClear
                required
                use12Hours
                name="holiday"
                label="Date"
                placeholder="Date"
                component={AntDatePicker}
              />
            </RightGrid>
          </Row>
          <Row className={classes.buttonContainer}>
            <Button className={classes.button} type="primary" onClick={showFormValuesModal}>
              Preview
            </Button>
          </Row>
        </form>
        <Modal
          onCancel={hideFormValuesModal}
          open={formValueModal}
          confirmLoading={confirmLoading}
          centered
          width="800px"
          maskClosable={false}
          closable={false}
          cancelText="Back"
          okButtonProps={{ className: classes.button }}
          okText={
            <Popconfirm
              title="Sure want to submit form ?"
              onConfirm={() => onSubmit()}
              okText="Confirm"
              cancelText="Cancel"
            >
              Submit
            </Popconfirm>
          }
        >
          <Row>
            <Col xs={24} lg={12}>
              <p>
                Description:
                <span className={classes.detailColor}>{preview.description}</span>
              </p>
            </Col>
            <Col xs={24} lg={12}>
              <p>
                Date:
                <span className={classes.detailColor}>{preview.holiday}</span>
              </p>
            </Col>
          </Row>
        </Modal>
      </>
    </Structure>
  );
};

NewHoliday.propTypes = {
  handleSubmit: PropTypes.func.isRequired,
  classes: PropTypes.object.isRequired,
};

const StyledNewHoliday = withStyles(styles)(NewHoliday);

const FormedNewHoliday = reduxForm({
  form: 'NewHoliday',
})(StyledNewHoliday);

export default FormedNewHoliday;

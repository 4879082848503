import React, { useCallback, useEffect, useMemo } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Table, Tabs, Tooltip } from 'antd';
import { Link } from 'react-router-dom';
import { FormOutlined } from '@ant-design/icons';

import moment from 'moment';
import { sorter, onFilter, filterDropdown, dateSorter, onDateFilter, dateFilterDropdown } from '../../utils';
import { getInteractions, clearInteractions } from '../../Actions';
import Structure from '../../Structure/Structure';
import Heading from '../../Components/Heading';

const { TabPane } = Tabs;

const FollowUpList = () => {
  const dispatch = useDispatch();

  const interactions = useSelector(state => state.interactions);

  useEffect(() => {
    dispatch(getInteractions({ type: 'follow-ups' }));
    return () => {
      dispatch(clearInteractions());
    };
  }, []);

  const fetchTabsData = useCallback(key => {
    dispatch(clearInteractions());
    dispatch(getInteractions({ type: key }));
  }, []);

  const detailColumn = useCallback(
    (_text, record) => (
      <Tooltip placement="bottom" title="Detail">
        <Link to={`/leads/detail/${record.leadId}`}>
          <FormOutlined />
        </Link>
      </Tooltip>
    ),
    []
  );

  const tooltipShow = useCallback(
    text => (
      <Tooltip placement="topLeft" title={text}>
        {text}
      </Tooltip>
    ),
    []
  );

  const columns = useMemo(() => {
    const _columns = [
      {
        title: 'Lead',
        dataIndex: 'lead',
        key: 'lead',
        sorter: sorter('lead'),
        onFilter: onFilter('lead'),
        filterDropdown,
      },
      {
        title: 'Company',
        dataIndex: 'company',
        key: 'company',
        sorter: sorter('company'),
        onFilter: onFilter('company'),
        filterDropdown,
      },
      {
        title: 'Assignee',
        dataIndex: 'assignee',
        key: 'assignee',
        sorter: sorter('assignee'),
        onFilter: onFilter('assignee'),
        filterDropdown,
        render: text => text || 'Unassigned',
      },
      {
        title: 'Type',
        dataIndex: 'type',
        key: 'type',
        sorter: sorter('type'),
        onFilter: onFilter('type'),
        filterDropdown,
      },
      {
        title: 'Scheduled At',
        dataIndex: 'scheduledAt',
        sorter: dateSorter('scheduledAt'),
        onFilter: onDateFilter('scheduledAt'),
        filterDropdown: dateFilterDropdown,
        render: text => (text ? moment(text).format('DD MMM YYYY hh:mm A') : null),
      },
      {
        title: 'Remark',
        dataIndex: 'remark',
        key: 'remark',
        sorter: sorter('remark'),
        onFilter: onFilter('remark'),
        filterDropdown,
        ellipsis: { showTitle: false },
        render: tooltipShow,
      },
      {
        title: 'Action',
        key: 'action',
        width: '6%',
        render: detailColumn,
      },
      {
        title: 'Created',
        children: [
          {
            title: 'By',
            dataIndex: 'createdBy',
            key: 'createdBy',
            sorter: sorter('createdBy'),
            onFilter: onFilter('createdBy'),
            filterDropdown,
          },
          {
            title: 'At',
            dataIndex: 'createdAt',
            sorter: dateSorter('createdAt'),
            onFilter: onDateFilter('createdAt'),
            filterDropdown: dateFilterDropdown,
            render: text => (text ? moment(text).format('DD MMM YYYY') : null),
          },
        ],
      },
    ];

    return _columns;
  }, []);

  const interactionsColumns = useMemo(() => {
    const _columns = [
      {
        title: 'Lead',
        dataIndex: 'lead',
        key: 'lead',
        sorter: sorter('lead'),
        onFilter: onFilter('lead'),
        filterDropdown,
      },
      {
        title: 'Company',
        dataIndex: 'company',
        key: 'company',
        sorter: sorter('company'),
        onFilter: onFilter('company'),
        filterDropdown,
      },
      {
        title: 'Assignee',
        dataIndex: 'assignee',
        key: 'assignee',
        sorter: sorter('assignee'),
        onFilter: onFilter('assignee'),
        filterDropdown,
        render: text => text || 'Unassigned',
      },
      {
        title: 'Type',
        dataIndex: 'type',
        key: 'type',
        sorter: sorter('type'),
        onFilter: onFilter('type'),
        filterDropdown,
      },
      {
        title: 'Remark',
        dataIndex: 'remark',
        key: 'remark',
        sorter: sorter('remark'),
        onFilter: onFilter('remark'),
        filterDropdown,
        ellipsis: { showTitle: false },
        render: tooltipShow,
      },
      {
        title: 'Scheduled At',
        dataIndex: 'scheduledAt',
        sorter: dateSorter('scheduledAt'),
        onFilter: onDateFilter('scheduledAt'),
        filterDropdown: dateFilterDropdown,
        render: text => (text ? moment(text).format('DD MMM YYYY hh:mm A') : null),
      },
      {
        title: 'Interacted At',
        dataIndex: 'interactionAt',
        sorter: dateSorter('interactionAt'),
        onFilter: onDateFilter('interactionAt'),
        filterDropdown: dateFilterDropdown,
        render: text => (text ? moment(text).format('DD MMM YYYY hh:mm A') : null),
      },
      {
        title: 'Duration',
        dataIndex: 'durationMin',
        key: 'durationMin',
        sorter: sorter('durationMin'),
        onFilter: onFilter('durationMin'),
        filterDropdown,
      },
      {
        title: 'Outcome',
        dataIndex: 'outcome',
        key: 'outcome',
        sorter: sorter('outcome'),
        onFilter: onFilter('outcome'),
        filterDropdown,
      },
      {
        title: 'Created',
        children: [
          {
            title: 'By',
            dataIndex: 'createdBy',
            key: 'createdBy',
            sorter: sorter('createdBy'),
            onFilter: onFilter('createdBy'),
            filterDropdown,
          },
          {
            title: 'At',
            dataIndex: 'createdAt',
            sorter: dateSorter('createdAt'),
            onFilter: onDateFilter('createdAt'),
            filterDropdown: dateFilterDropdown,
            render: text => (text ? moment(text).format('DD MMM YYYY') : null),
          },
        ],
      },
    ];

    return _columns;
  }, []);

  const rowKey = useCallback(record => record.id, []);

  const pagination = useCallback(
    {
      defaultPageSize: 20,
      position: ['bottomLeft'],
      showTotal: total => `Total ${total} Records`,
    },
    []
  );

  const scroll = useCallback({ x: 1800, y: window.innerHeight - 300 }, []);

  return (
    <Structure>
      <>
        <Heading text="Follow-ups" />
        <Tabs defaultActiveKey="follow-ups" onChange={fetchTabsData}>
          <TabPane tab="Follow-ups" key="follow-ups">
            <Table
              bordered
              size="small"
              columns={columns}
              dataSource={interactions}
              rowKey={rowKey}
              pagination={pagination}
              scroll={scroll}
            />
          </TabPane>
          <TabPane tab="Interactions" key="interactions">
            <Table
              bordered
              size="small"
              columns={interactionsColumns}
              dataSource={interactions}
              rowKey={rowKey}
              pagination={pagination}
              scroll={scroll}
            />
          </TabPane>
        </Tabs>
      </>
    </Structure>
  );
};

export default FollowUpList;

export const customerList = (state = [], action) => {
  if (action.type === 'FETCH_CUSTOMER_LIST') {
    return action.payload;
  }
  if (action.type === 'CLEAR_CUSTOMER_LIST') {
    return [];
  }
  return state;
};

export const customer = (state = {}, action) => {
  if (action.type === 'FETCH_CUSTOMER') {
    return action.payload;
  }
  if (action.type === 'CLEAR_CUSTOMER') {
    return {};
  }
  return state;
};

export const customerIdList = (state = [], action) => {
  if (action.type === 'FETCH_CUSTOMER_ID_LIST') {
    return action.payload;
  }
  if (action.type === 'CLEAR_CUSTOMER_ID_LIST') {
    return [];
  }
  return state;
};

export const customerLedger = (state = {}, action) => {
  if (action.type === 'FETCH_CUSTOMER_LEDGER') {
    return action.payload;
  }
  if (action.type === 'CLEAR_CUSTOMER_LEDGER') {
    return {};
  }
  return state;
};

export const customerInventoryList = (state = [], action) => {
  if (action.type === 'FETCH_CUSTOMER_INVENTORY_VIEW') {
    return action.payload;
  }
  if (action.type === 'CLEAR_CUSTOMER_INVENTORY_VIEW') {
    return [];
  }
  return state;
};

export const customerOrders = (state = [], action) => {
  if (action.type === 'FETCH_CUSTOMER_ORDERS') {
    return action.payload;
  }
  if (action.type === 'CLEAR_CUSTOMER_ORDERS') {
    return [];
  }
  return state;
};

export const complaints = (state = [], action) => {
  if (action.type === 'FETCH_CUSTOMER_COMPLAINTS') {
    return action.payload;
  }
  if (action.type === 'CLEAR_CUSTOMER_COMPLAINTS') {
    return [];
  }
  return state;
};

export const customerOpeningBalancesList = (state = [], action) => {
  if (action.type === 'FETCH_CUSTOMER_OPENING_BALANCES_LIST') {
    return action.payload;
  }
  if (action.type === 'CLEAR_CUSTOMER_OPENING_BALANCES_LIST') {
    return [];
  }
  return state;
};

export const customerOpeningBalance = (state = {}, action) => {
  if (action.type === 'FETCH_CUSTOMER_OPENING_BALANCE') {
    return action.payload;
  }
  if (action.type === 'CLEAR_CUSTOMER_OPENING_BALANCE') {
    return {};
  }
  return state;
};

export const customerNotes = (state = [], action) => {
  if (action.type === 'FETCH_CUSTOMER_NOTES') {
    return action.payload;
  }
  if (action.type === 'CLEAR_CUSTOMER_NOTES') {
    return [];
  }
  return state;
};

export const customerWisePayables = (state = [], action) => {
  if (action.type === 'FETCH_CUSTOMER_WISE_PAYABLES') {
    return action.payload;
  }
  if (action.type === 'CLEAR_CUSTOMER_WISE_PAYABLES') {
    return [];
  }
  return state;
};

import React, { useEffect, Fragment } from 'react';
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';
import withStyles from 'react-jss';
import { DatePicker, Row } from 'antd';
import { Field, getFormValues, reduxForm } from 'redux-form';
import styles from '../CommonStyles';
import { getFinanceMIS, clearFinanceMIS } from '../Actions';
import Structure from '../Structure/Structure';
// import { get } from '../utils';
import Heading from '../Components/Heading';
import GetField from '../Components/GetField';

const AntDatePicker = GetField(DatePicker);

const MIS = props => {
  const { classes } = props;
  const dispatch = useDispatch();

  // const mis = useSelector(state => state.mis);
  const FormValues = useSelector(state => getFormValues('MIS')(state));

  useEffect(() => {
    dispatch(getFinanceMIS());
    return () => {
      dispatch(clearFinanceMIS());
    };
  }, []);

  useEffect(() => {
    if (FormValues && FormValues.from && FormValues.to) {
      dispatch(getFinanceMIS(FormValues));
    }
  }, [FormValues && FormValues.from, FormValues && FormValues.to]);

  return (
    <Structure>
      <>
        <Heading text="Finance MIS" />
        <Row>
          <Field allowClear required name="from" placeholder="From" component={AntDatePicker} />
          &nbsp;
          <Field allowClear required name="to" placeholder="To" component={AntDatePicker} />
        </Row>
        <h3 className={classes.heading}>Finance MIS</h3>
      </>
    </Structure>
  );
};

MIS.propTypes = {
  classes: PropTypes.object.isRequired,
};

const StyledMIS = withStyles(styles)(MIS);

const FormedMIS = reduxForm({
  form: 'MIS',
  enableReinitialize: true,
})(StyledMIS);

export default FormedMIS;

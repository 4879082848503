import React, { useState } from 'react';
import PropTypes from 'prop-types';
import withStyles from 'react-jss';
import { reduxForm, Field, getFormValues, reset } from 'redux-form';
import { Button, Input, Select, Row } from 'antd';
import { useDispatch, useSelector } from 'react-redux';
import Modal from 'antd/lib/modal/Modal';
import Structure from '../Structure/Structure';
import styles from '../CommonStyles';
import { errorNotification } from '../Snackbar';
import Api from '../Api';
import CenterGrid from '../Components/CenterGrid';
import GetField from '../Components/GetField';

const AntInput = GetField(Input);
const AntSelect = GetField(Select);

const SocialCauseForm = props => {
  const { handleSubmit, classes } = props;
  const dispatch = useDispatch();

  const [confirmLoading, setConfirmLoading] = useState(false);
  const [previewModal, setPreviewModal] = useState(false);
  const [patientData, setPatientData] = useState({});
  const FormValues = useSelector(state => getFormValues('SocialCauseForm')(state));

  const hideModal = () => {
    setConfirmLoading(false);
    setPreviewModal(false);
    dispatch(reset('SocialCauseForm'));
    setPatientData({});
  };

  const onSubmit = async () => {
    try {
      setConfirmLoading(true);
      const { data } = await Api.post('/patients', FormValues);
      setPatientData(data);
      setPreviewModal(true);
    } catch (err) {
      setConfirmLoading(false);
      errorNotification(err);
    }
  };

  return (
    <Structure>
      <>
        <form onSubmit={handleSubmit(onSubmit)}>
          <Row>
            <CenterGrid>
              <Field label="Gender" required allowClear name="gender" component={AntSelect}>
                <Select.Option key={null} value="" disabled>
                  Select gender
                </Select.Option>
                <Select.Option key="male" value="male">
                  Male
                </Select.Option>
                <Select.Option key="female" value="female">
                  Female
                </Select.Option>
                <Select.Option key="other" value="other">
                  Other
                </Select.Option>
              </Field>
            </CenterGrid>
          </Row>
          <Row>
            <CenterGrid>
              <Field label="Name" required allowClear name="name" placeholder="Name" component={AntInput} />
            </CenterGrid>
          </Row>
          <Row>
            <CenterGrid>
              <Field
                allowClear
                required
                label="Phone Number"
                name="phone"
                placeholder="Phone Number"
                component={AntInput}
              />
            </CenterGrid>
          </Row>
          <Row>
            <CenterGrid>
              <Field
                allowClear
                required
                label="Age"
                name="age"
                placeholder="Age"
                type="number"
                min={0}
                component={AntInput}
              />
            </CenterGrid>
          </Row>
          <Row>
            <CenterGrid>
              <Field label="Category" required allowClear name="category" component={AntSelect}>
                <Select.Option key={null} value="" disabled>
                  Select category
                </Select.Option>
                <Select.Option key="General" value="General">
                  General
                </Select.Option>
                <Select.Option key="Heart" value="Heart">
                  Heart
                </Select.Option>
                <Select.Option key="Chest" value="Chest">
                  Chest
                </Select.Option>
                <Select.Option key="Neurology" value="Neurology">
                  Neurology
                </Select.Option>
                <Select.Option key="Gynaecology" value="Gynaecology">
                  Gynaecology
                </Select.Option>
                <Select.Option key="Skin" value="Skin">
                  Skin
                </Select.Option>
                <Select.Option key="Orthopedics" value="Orthopedics">
                  Orthopedics
                </Select.Option>
              </Field>
            </CenterGrid>
          </Row>
          <Row>
            <CenterGrid>
              <Field allowClear label="Location" name="location" placeholder="Location" component={AntInput} />
            </CenterGrid>
          </Row>
          <Row className={classes.buttonContainer}>
            <Button className={classes.button} type="primary" loading={confirmLoading} htmlType="submit">
              Submit
            </Button>
          </Row>
        </form>
        <Modal
          onCancel={hideModal}
          open={previewModal}
          width="90%"
          maskClosable={false}
          closable={false}
          cancelText="Close"
          okButtonProps={{ className: classes.hide }}
        >
          <Row>
            <p>
              Name:
              <span className={classes.detailColor}>{patientData.name}</span>
            </p>
          </Row>
          <Row>
            <p>
              Patient #:
              <span className={classes.detailColor}>{patientData.id}</span>
            </p>
          </Row>
          <Row>
            <p>
              Queue #:
              <span className={classes.detailColor}>{patientData.patientNo}</span>
            </p>
          </Row>
          <Row>
            <p>
              Category:
              <span className={classes.detailColor}>{patientData.category}</span>
            </p>
          </Row>
          <Row>
            <p>
              Age #:
              <span className={classes.detailColor}>{patientData.age}</span>
            </p>
          </Row>
        </Modal>
      </>
    </Structure>
  );
};

SocialCauseForm.propTypes = {
  handleSubmit: PropTypes.func.isRequired,
  classes: PropTypes.object.isRequired,
};

const StyledSocialCauseForm = withStyles(styles)(SocialCauseForm);

const FormedSocialCauseForm = reduxForm({
  form: 'SocialCauseForm',
})(StyledSocialCauseForm);

export default FormedSocialCauseForm;

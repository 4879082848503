import React, { useState, useEffect, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Table, Tooltip } from 'antd';
import moment from 'moment';
import { CalendarOutlined, FileTextOutlined } from '@ant-design/icons';
import { useHistory } from 'react-router-dom';
import Structure from '../Structure/Structure';
import Heading from '../Components/Heading';
import {
  getProductionOrderList,
  clearProductionOrderList,
  setSale,
  // getEmployeeList,
  // clearEmployeeList,
} from '../Actions';
import { sorter, onFilter, filterDropdown, get, onDateFilter, dateFilterDropdown, dateSorter } from '../utils';
import DispatchDateModal from './DispatchDateModal';
import RemainingStock from './ProductionModals/RemainingStock';
import ManufacturingModal from './ProductionModals/ManufacturingModal';
import FillingModal from './ProductionModals/FillingModal';
import PackingModal from './ProductionModals/PackingModal';
import EditJobCardModal from './ProductionModals/EditJobCardModal';
import JobCard from './JobCard';

const Production = () => {
  const dispatch = useDispatch();
  const history = useHistory();

  const auth = useSelector(state => state.auth);
  const role = get(auth, ['user', 'role'], []);

  const [orderId, setOrderId] = useState(null);
  const [ModalType, SetModalType] = useState(null);

  const productionOrders = useSelector(state => state.productionOrders);

  const orderData = useMemo(() => productionOrders.find(el => el.id === orderId), [orderId, productionOrders]);

  useEffect(() => {
    dispatch(getProductionOrderList());
    // dispatch(getEmployeeList());

    return () => {
      dispatch(clearProductionOrderList());
      // dispatch(clearEmployeeList());
    };
  }, []);

  const ShowModal = (id, type) => {
    setOrderId(id);
    SetModalType(type);
  };

  const HideModal = () => {
    setOrderId(null);
    SetModalType(null);
    dispatch(getProductionOrderList());
  };

  const setSaleState = id => {
    dispatch(setSale({ orderId: id }));
    history.push('/sale/new');
  };

  const tooltipShow = arg => (
    <Tooltip placement="topLeft" title={arg}>
      {arg}
    </Tooltip>
  );

  const columns = [
    {
      title: 'Section Seq. #',
      dataIndex: 'seqno',
      width: 150,
      render: (text, record) => `${moment(record.estDispatchDate).format('DD MMM')}/${record.section}/${record.seqno}`,
    },
    {
      title: 'Finished Good Name',
      dataIndex: 'formulation',
      filterDropdown,
      sorter: sorter('formulation'),
      onFilter: onFilter('formulation'),
      width: 180,
    },
    {
      title: 'Order #',
      dataIndex: 'id',
      filterDropdown,
      sorter: sorter('id'),
      onFilter: onFilter('id'),
      width: 90,
    },
    {
      title: 'Customer',
      dataIndex: 'customer',
      filterDropdown,
      sorter: sorter('customer'),
      onFilter: onFilter('customer'),
    },
    {
      title: 'Section',
      dataIndex: 'section',
      filterDropdown,
      sorter: sorter('section'),
      onFilter: onFilter('section'),
    },
    {
      title: 'Quantity',
      dataIndex: 'quantity',
      sorter: sorter('quantity'),
    },
    {
      title: 'Remarks',
      dataIndex: 'remarks',
      ellipsis: { showTitle: false },
      render: tooltipShow,
    },
    {
      title: 'Status',
      dataIndex: 'status',
      sorter: sorter('status'),
      filterDropdown,
      onFilter: onFilter('status'),
      width: 120,
    },

    {
      title: 'Est. Dispatch',
      dataIndex: 'estDispatchDate',
      width: 120,
      sorter: dateSorter('estDispatchDate'),
      filterDropdown: dateFilterDropdown,
      onFilter: onDateFilter('estDispatchDate'),
      render: (_text, record) => (
        <>
          <span>{moment(record.estDispatchDate).format('DD MMM YY hh:mm A')} | </span>
          {record.estDispatchDate && role === 'Owner' ? (
            <CalendarOutlined onClick={() => ShowModal(record.id, 'espDistachDate')} />
          ) : null}
        </>
      ),
    },

    ...(role === 'Owner'
      ? [
          {
            title: 'Edit Job Card',
            key: 'Edit Job Card',
            width: 60,
            render: (_text, record) =>
              record.PackingEndTime ? <FileTextOutlined onClick={() => ShowModal(record.id, 'EditJobCard')} /> : null,
          },
        ]
      : []),
    ...(role === 'Owner'
      ? [
          {
            title: 'Semi Finished Stock',
            key: 'Semi Finished Stock',
            width: 80,
            render: (_text, record) =>
              record.remainingStock && !record.PackingEndTime ? (
                <FileTextOutlined onClick={() => ShowModal(record.id, 'RemainingStock')} />
              ) : null,
          },
        ]
      : []),
    {
      title: 'Job Card',
      key: 'Job Card',
      width: 60,
      render: (_text, record) => <FileTextOutlined onClick={() => ShowModal(record.id, 'JobCard')} />,
    },
    {
      title: 'Manufacturing',
      key: 'Manufacturing',
      width: 120,
      render: (_text, record) =>
        !record.ManufacturingEndTime ? (
          <FileTextOutlined onClick={() => ShowModal(record.id, 'Manufacturing')} />
        ) : null,
    },
    {
      title: 'Filling',
      key: 'Filling',
      width: 80,
      render: (_text, record) =>
        record.ManufacturingEndTime && !record.FillingEndTime ? (
          <FileTextOutlined onClick={() => ShowModal(record.id, 'Filling')} />
        ) : null,
    },
    {
      title: 'Packing',
      key: 'Packing',
      width: 80,
      render: (_text, record) =>
        // record?.batchCodingChecked && !record.PackingEndTime ? (
        record.ManufacturingEndTime && record?.batchCodingChecked && !record.PackingEndTime ? (
          <FileTextOutlined onClick={() => ShowModal(record.id, 'Packing')} />
        ) : null,
      // ) : (
      //   'Batch Coding Pending'
      // ),
    },
    {
      title: 'Ready for Sale',
      key: 'Ready for Sale',
      width: 75,
      render: (_text, record) =>
        record.toMakeSale ? <FileTextOutlined onClick={() => setSaleState(record.id)} /> : null,
    },
  ];

  return (
    <Structure>
      <>
        <Heading text="Current Production" />
        <Table
          columns={columns}
          bordered
          rowKey={record => record.id}
          pagination={{ defaultPageSize: 50, position: ['bottomLeft'], showTotal: total => `Total ${total} Records` }}
          dataSource={productionOrders}
          size="small"
          scroll={{ x: 1700, y: window.innerHeight - 150 }}
        />
        {orderData && orderId && ModalType === 'espDistachDate' && (
          <DispatchDateModal recordId={orderId} order={orderData} hideModal={HideModal} showTimeFormat />
        )}
        {orderData && orderId && ModalType === 'RemainingStock' && (
          <RemainingStock recordId={orderId} order={orderData} hideModal={HideModal} />
        )}
        {orderData && orderId && ModalType === 'Manufacturing' && (
          <ManufacturingModal recordId={orderId} order={orderData} hideModal={HideModal} />
        )}
        {orderData && orderId && ModalType === 'Filling' && (
          <FillingModal recordId={orderId} order={orderData} hideModal={HideModal} />
        )}
        {orderData && orderId && ModalType === 'Packing' && (
          <PackingModal recordId={orderId} order={orderData} hideModal={HideModal} />
        )}
        {orderData && orderId && ModalType === 'JobCard' && <JobCard order={orderData} hideModal={HideModal} />}
        {orderData && orderId && ModalType === 'EditJobCard' && (
          <EditJobCardModal recordId={orderId} order={orderData} hideModal={HideModal} />
        )}
      </>
    </Structure>
  );
};

export default Production;

export const categoryList = (state = [], action) => {
  if (action.type === 'FETCH_CATEGORY_LIST') {
    return action.payload;
  }
  if (action.type === 'CLEAR_CATEGORY_LIST') {
    return [];
  }
  return state;
};

export const category = (state = {}, action) => {
  if (action.type === 'FETCH_CATEGORY') {
    return action.payload;
  }
  if (action.type === 'CLEAR_CATEGORY') {
    return {};
  }
  return state;
};

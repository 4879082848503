import React from 'react';
import {
  UserOutlined,
  MenuOutlined,
  ExperimentOutlined,
  DeploymentUnitOutlined,
  BuildOutlined,
  SettingOutlined,
  CarOutlined,
  BankOutlined,
  CalendarOutlined,
  FundProjectionScreenOutlined,
  BarChartOutlined,
  UnlockOutlined,
  WhatsAppOutlined,
  FileTextOutlined,
  TeamOutlined,
  ProfileOutlined,
  UnorderedListOutlined,
  FormOutlined,
  PieChartOutlined,
} from '@ant-design/icons';

export const Modules = [
  {
    type: 'list',
    title: 'Checklist',
    key: 'Checklist',
    icon: <CalendarOutlined />,
    subModules: [
      {
        type: 'item',
        title: 'Key Result Areas',
        icon: <CalendarOutlined />,
        module: 'kra',
        link: '/tasks/kra/list',
      },
      {
        type: 'item',
        title: 'Todo',
        icon: <FileTextOutlined />,
        module: 'notes',
        link: '/tasks/schedule/list',
      },
      {
        type: 'item',
        title: 'Extensions',
        icon: <ProfileOutlined />,
        module: 'todo',
        link: '/extensions',
      },
    ],
  },
  {
    type: 'list',
    title: 'CRM',
    key: 'CRM',
    icon: <PieChartOutlined />,
    subModules: [
      {
        type: 'list',
        title: 'Lead',
        key: 'Lead',
        icon: <FileTextOutlined />,
        subModules: [
          {
            type: 'item',
            module: 'addLead',
            title: 'Add Lead',
            key: 'Add Lead',
            link: '/leads/add',
            icon: <FormOutlined />,
          },
          {
            type: 'item',
            module: 'leadList',
            title: 'Lead List',
            key: 'Lead List',
            link: '/leads/list',
            icon: <UnorderedListOutlined />,
          },
          // {
          //   type: 'item',
          //   title: 'Follow-ups',
          //   key: 'Follow-ups',
          //   icon: <UnorderedListOutlined />,
          //   module: 'leadList',
          //   link: '/leads/followup/list',
          // },
        ],
      },
      {
        type: 'list',
        title: 'Proforma Invoice',
        key: 'ProformaInvoice',
        icon: <FileTextOutlined />,
        subModules: [
          {
            type: 'item',
            module: 'addProformaInvoice',
            title: 'Add PI',
            key: 'Add Proforma Invoice',
            link: '/proformainvoices/add',
            icon: <FormOutlined />,
          },
          {
            type: 'item',
            module: 'proformaInvoiceList',
            title: 'PI List',
            key: 'Proforma Invoice List',
            link: '/proformainvoices/list',
            icon: <UnorderedListOutlined />,
          },
        ],
      },
      {
        type: 'item',
        module: 'lead_FMS',
        title: 'Lead FMS',
        link: '/mis/lead/fms',
      },
      {
        type: 'item',
        module: 'lead_MIS',
        title: 'Lead MIS',
        link: '/mis/lead',
      },
    ],
  },
  {
    type: 'list',
    title: 'Human Resource',
    key: 'Human Resource',
    icon: <TeamOutlined />,
    subModules: [
      {
        type: 'item',
        module: 'new_leave',
        title: 'Request Leave',
        key: 'Request Leave',
        link: '/leave/new',
      },
      {
        type: 'item',
        module: 'new_employee',
        title: 'New Employee',
        key: 'New Employee',
        link: '/employee/new',
      },
      {
        type: 'item',
        module: 'leave_list',
        title: 'Leave List',
        key: 'Leave List',
        link: '/leave/list',
      },
      {
        type: 'item',
        module: 'employee_list',
        title: 'Employee List',
        key: 'Employee List',
        link: '/employee/list',
      },
      {
        type: 'item',
        module: 'attendance_list',
        title: 'Attendance List',
        key: 'Attendance List',
        link: '/attendance/list',
      },
      {
        type: 'item',
        module: 'new_holiday',
        title: 'New Holiday',
        key: 'New Holiday',
        link: '/holiday/new',
      },
      {
        type: 'item',
        module: 'holiday_list',
        title: 'Holiday List',
        key: 'Holiday List',
        link: '/holiday/list',
      },
      {
        type: 'item',
        module: 'old_attendance',
        title: 'Old Attendance',
        key: 'Old Attendance',
        link: '/attendance/old',
      },
      {
        type: 'item',
        module: 'new_attendance',
        title: 'Attendance',
        key: 'Attendance',
        link: '/attendance/new',
      },
    ],
  },
  // {
  //   type: 'item',
  //   title: 'Ticket',
  //   icon: <TagOutlined />,
  //   module: 'ticket',
  //   link: '/ticket/new',
  // },
  // {
  //   type: 'item',
  //   title: 'Ticket List',
  //   icon: <MenuOutlined />,
  //   module: 'ticket',
  //   link: '/ticket/list',
  // },
  {
    type: 'list',
    title: 'Reports',
    key: 'Reports',
    icon: <FundProjectionScreenOutlined />,
    subModules: [
      {
        type: 'item',
        title: 'Dashboard',
        key: 'Dashboard',
        icon: <FundProjectionScreenOutlined />,
        module: 'dashboard',
        link: '/dashboard',
      },
      {
        type: 'item',
        title: 'Order Report',
        key: 'Order Report',
        icon: <FundProjectionScreenOutlined />,
        module: 'user_order_report',
        link: '/report/team',
      },
      {
        type: 'item',
        title: 'Profit Center',
        key: 'Profit Center',
        icon: <FundProjectionScreenOutlined />,
        module: 'profit_center_report',
        link: '/report/profitcenter',
      },
      {
        type: 'item',
        title: 'ASP Reports',
        key: 'ASP Reports',
        icon: <FundProjectionScreenOutlined />,
        module: 'asp_report',
        link: '/report/aspreport',
      },
      {
        type: 'item',
        title: 'Calendar',
        key: 'Calendar',
        icon: <CalendarOutlined />,
        module: 'calendar',
        link: '/calendar',
      },
    ],
  },
  {
    type: 'list',
    title: 'Financial',
    key: 'Financial',
    icon: <BankOutlined />,
    subModules: [
      {
        type: 'item',
        module: 'new_transaction',
        title: 'New Transaction',
        key: 'New Transaction',
        link: '/transaction/new',
      },
      {
        type: 'item',
        module: 'transaction_list',
        title: 'Transaction List',
        key: 'Transaction List',
        link: '/transaction/list',
      },
      {
        type: 'item',
        module: 'new_transaction',
        title: 'File Upload',
        key: 'File Upload',
        link: '/transaction/fileupload',
      },
      {
        type: 'item',
        module: 'new_transaction',
        title: 'Files List',
        key: 'Files List',
        link: '/transaction/filelist',
      },
      {
        type: 'item',
        module: 'bank_list',
        title: 'Bank List',
        key: 'Bank List',
        link: '/bank/list',
      },
      {
        type: 'item',
        module: 'financial_summary',
        title: 'Financial Summary',
        key: 'Financial Summary',
        link: '/finance/report/financialsummary',
      },
      {
        type: 'item',
        module: 'balance_sheet',
        title: 'Balance Sheet',
        key: 'Balance Sheet',
        link: '/finance/report/balancesheet',
      },
      {
        type: 'item',
        module: 'financial_summary',
        title: 'Cash Flow',
        key: 'Cash Flow',
        link: '/finance/report/cashflow',
      },
      {
        type: 'item',
        module: 'financial_summary',
        title: 'Weekly Collection',
        key: 'Weekly Collection',
        link: '/weekly-collection',
      },
      {
        type: 'item',
        module: 'gst_reversal',
        title: 'GST Reversal',
        key: 'GST Reversal',
        link: '/gstreversal',
      },
      {
        type: 'list',
        title: 'Contacts',
        key: 'Contacts',
        icon: <MenuOutlined />,
        subModules: [
          {
            type: 'item',
            module: 'new_ledger_contact',
            title: 'New Contact',
            key: 'New Contact',
            link: '/ledgercontact/new',
          },
          {
            type: 'item',
            module: 'ledger_contact_list',
            title: 'Contact List',
            key: 'Contact List',
            link: '/ledgercontact/list',
          },
        ],
      },
      {
        type: 'list',
        title: 'Assets',
        key: 'Assets',
        icon: <MenuOutlined />,
        subModules: [
          {
            type: 'item',
            module: 'new_asset',
            title: 'New Asset',
            key: 'New Asset',
            link: '/assets/new',
          },
          {
            type: 'item',
            module: 'asset_list',
            title: 'Asset List',
            key: 'Asset List',
            link: '/assets/list',
          },
          {
            type: 'item',
            module: 'asset_adjustment',
            title: 'Adjustment Form',
            key: 'Adjustment Form',
            link: '/assets/adjustment',
          },
          {
            type: 'item',
            module: 'asset_adjustment',
            title: 'Asset Adjustments',
            key: 'Asset Adjustments',
            link: '/assets/adjustment/list',
          },
        ],
      },
      // {
      //   type: 'item',
      //   module: 'finance_MIS',
      //   title: 'MIS',
      //   link: '/finance/report/mis',
      // },
      // {
      //   type: 'item',
      //   module: 'finance_MIS_form',
      //   title: 'MIS Report Form',
      //   link: '/finance/report/mis/form',
      // },
    ],
  },
  {
    type: 'list',
    title: 'MIS',
    key: 'MIS',
    icon: <BarChartOutlined />,
    subModules: [
      {
        type: 'item',
        module: 'task_MIS',
        title: 'Task MIS',
        key: 'Task MIS',
        link: '/mis/task',
      },
      {
        type: 'item',
        module: 'order_MIS',
        title: 'Order MIS',
        key: 'Order MIS',
        link: '/mis/order',
      },
      {
        type: 'item',
        module: 'order_FMS',
        title: 'Order FMS',
        key: 'Order FMS',
        link: '/mis/order/fms',
      },
      {
        type: 'item',
        module: 'supplier_MIS',
        title: 'Supplier MIS',
        key: 'Supplier MIS',
        link: '/mis/supplier',
      },
      {
        type: 'item',
        module: 'team_MIS',
        title: 'Team',
        key: 'Team',
        link: '/mis/team',
      },
    ],
  },
  {
    type: 'list',
    title: 'Material',
    icon: <DeploymentUnitOutlined />,
    subModules: [
      {
        type: 'item',
        module: 'new_material',
        title: 'New Material',
        link: '/material/new',
      },
      {
        type: 'item',
        module: 'material_list',
        title: 'Material List',
        link: '/material/list',
      },
      {
        type: 'item',
        module: 'material_inventory',
        title: 'Material Inventory',
        link: '/material/inventory',
      },
      {
        type: 'item',
        module: 'material_transaction',
        title: 'Material Transaction',
        link: '/material/transaction',
      },
      {
        type: 'item',
        module: 'shortage_items_to_order',
        title: 'Item To Order',
        link: '/material/itemtoorder',
      },
      {
        type: 'item',
        module: 'stock_adjustment',
        title: 'Adjust Stock',
        link: '/material/inventory/adjustment',
      },
      {
        type: 'item',
        module: 'adjusted_stock_list',
        title: 'Stock Adjustments',
        link: '/material/inventory/adjustment/list',
      },
      {
        type: 'item',
        module: 'stock_adjustment',
        title: 'Transfer Stock',
        link: '/material/inventory/transfers',
      },
      {
        type: 'item',
        module: 'adjusted_stock_list',
        title: 'Stock Transfers',
        link: '/material/inventory/transfers/list',
      },
    ],
  },
  {
    type: 'list',
    title: 'Formulation',
    icon: <ExperimentOutlined />,
    subModules: [
      {
        type: 'item',
        module: 'new_formulation',
        title: 'New Formulation',
        link: '/formulations/new',
      },
      {
        type: 'item',
        module: 'formulation_list',
        title: 'Formulation List',
        link: '/formulations/list',
      },
      {
        type: 'item',
        module: 'formulation_costing',
        title: 'Costing',
        link: '/formulations/costing',
      },
      {
        type: 'item',
        module: 'formulation_inventory',
        title: 'Remaining Inventory',
        link: '/formulations/inventory',
      },
      {
        type: 'item',
        module: 'formulation_stock_adjustment',
        title: 'Stock Adjustment',
        link: '/formulations/inventory/adjustment',
      },
      {
        type: 'item',
        module: 'formulation_adjusted_stock_list',
        title: 'Adjusted Stock List',
        link: '/formulations/inventory/adjustment/list',
      },
      {
        type: 'item',
        module: 'generic_list',
        title: 'Generic List',
        link: '/formulations/generic',
      },
      {
        type: 'item',
        module: 'gp_report',
        title: 'GP Report',
        link: '/formulations/gpreport',
      },
      {
        type: 'item',
        module: 'last_selling_gp_report',
        title: 'Product GP Values',
        link: '/formulations/gpreport/lastselling',
      },
    ],
  },
  // {
  //   type: 'list',
  //   title: 'Enquiry',
  //   icon: <MenuOutlined />,
  //   subModules: [
  //     {
  //       type: 'item',
  //       module: 'new_enquiry',
  //       title: 'New Enquiry',
  //       link: '/enquiry/new',
  //     },
  //     {
  //       type: 'item',
  //       module: 'enquiry_list',
  //       title: 'Enquiry List',
  //       link: '/enquiry/list',
  //     },
  //   ],
  // },
  {
    type: 'list',
    title: 'Purchase Order',
    icon: <MenuOutlined />,
    subModules: [
      {
        type: 'item',
        module: 'new_purchase_order',
        title: 'New Purchase Order',
        link: '/purchaseorder/new',
      },
      {
        type: 'item',
        module: 'purchase_order_list',
        title: 'Purchase Order List',
        link: '/purchaseorder/list',
      },
    ],
  },
  {
    type: 'list',
    title: 'Purchase',
    icon: <MenuOutlined />,
    subModules: [
      {
        type: 'item',
        module: 'new_purchase',
        title: 'New Purchase',
        link: '/purchase/new',
      },
      {
        type: 'item',
        module: 'purchase_list',
        title: 'Purchase List',
        link: '/purchase/list',
      },
    ],
  },
  {
    type: 'list',
    title: 'Job Work',
    icon: <MenuOutlined />,
    subModules: [
      {
        type: 'item',
        module: 'new_purchase',
        title: 'New Job Work',
        link: '/jobworks/new',
      },
      {
        type: 'item',
        module: 'purchase_list',
        title: 'Job Work List',
        link: '/jobworks/list',
      },
    ],
  },
  {
    type: 'list',
    title: 'Order',
    icon: <MenuOutlined />,
    subModules: [
      {
        type: 'item',
        module: 'new_order',
        title: 'New Order',
        link: '/order/new',
      },
      {
        type: 'item',
        module: 'order_list',
        title: 'Order List',
        link: '/order/list',
      },
      {
        type: 'item',
        module: 'order_list',
        title: 'Authorized Orders',
        link: '/order/list/authorized',
      },
      {
        type: 'item',
        module: 'order_list',
        title: 'Monthly Planning',
        link: '/order/planning',
      },
    ],
  },

  {
    type: 'list',
    title: 'Production',
    icon: <BuildOutlined />,
    subModules: [
      {
        type: 'item',
        module: 'production_planning_list',
        title: 'Final Planning',
        link: '/production/planninglist',
      },
      {
        type: 'item',
        module: 'production_orders_list',
        title: 'Current Production',
        link: '/production/orders',
      },
      // {
      //   type: 'item',
      //   module: 'production_orders_list',
      //   title: 'Production Copy',
      //   link: '/production/orders/copy',
      // },
      {
        type: 'item',
        module: 'batch_list',
        title: 'Batch List',
        link: '/production/batch/list',
      },
      {
        type: 'item',
        module: 'gpPercentage',
        title: 'GP Percentage',
        link: '/production/gpPercentage',
      },
    ],
  },
  {
    type: 'list',
    title: 'Sale',
    icon: <MenuOutlined />,
    subModules: [
      {
        type: 'item',
        module: 'new_sale',
        title: 'New Sale',
        link: '/sale/new',
      },
      {
        type: 'item',
        module: 'sale_list',
        title: 'Sale List',
        link: '/sale/list',
      },
      {
        type: 'item',
        module: 'new_material_sale',
        title: 'New Material Sale',
        link: '/material/sale/new',
      },
      {
        type: 'item',
        module: 'sale_material_list',
        title: 'Material Sale List',
        link: '/material/sale/list',
      },
    ],
  },
  // {
  //   type: 'list',
  //   title: 'Sale Return',
  //   icon: <MenuOutlined />,
  //   subModules: [
  //     // {
  //     //   type: 'item',
  //     //   module: 'new_sale_return',
  //     //   title: 'New Return Sale',
  //     //   link: '/salereturn/new',
  //     // },
  //     {
  //       type: 'item',
  //       module: 'sale_return_list',
  //       title: 'Sale Return List',
  //       link: '/salereturn/list',
  //     },
  //   ],
  // },
  {
    type: 'list',
    title: 'Courier',
    icon: <MenuOutlined />,
    subModules: [
      {
        type: 'item',
        module: 'new_courier',
        title: 'New Courier',
        link: '/courier/new',
      },
      {
        type: 'item',
        module: 'courier_list',
        title: 'Courier List',
        link: '/courier/list',
      },
    ],
  },
  {
    type: 'list',
    title: 'Credit/Debit Note',
    icon: <MenuOutlined />,
    subModules: [
      {
        type: 'item',
        module: 'new_credit_debit_note',
        title: 'New Credit/Debit',
        link: '/creditdebitnote/new',
      },
      {
        type: 'item',
        module: 'credit_debit_note_list',
        title: 'Credit/Debit List',
        link: '/creditdebitnote/list',
      },
    ],
  },
  // {
  //   type: 'list',
  //   title: 'Credit Note',
  //   icon: <MenuOutlined />,
  //   subModules: [
  //     {
  //       type: 'item',
  //       module: 'new_credit_note',
  //       title: 'New Credit Note',
  //       link: '/creditnote/new',
  //     },
  //     {
  //       type: 'item',
  //       module: 'credit_note_list',
  //       title: 'Credit Note List',
  //       link: '/creditnote/list',
  //     },
  //   ],
  // },
  // {
  //   type: 'list',
  //   title: 'Debit Note',
  //   icon: <MenuOutlined />,
  //   subModules: [
  //     {
  //       type: 'item',
  //       module: 'new_debit_note',
  //       title: 'New Debit Note',
  //       link: '/debitnote/new',
  //     },
  //     {
  //       type: 'item',
  //       module: 'debit_note_list',
  //       title: 'Debit Note List',
  //       link: '/debitnote/list',
  //     },
  //   ],
  // },
  {
    type: 'list',
    title: 'Parties',
    icon: <UnorderedListOutlined />,
    subModules: [
      {
        type: 'list',
        title: 'Customer',
        icon: <UserOutlined />,
        subModules: [
          {
            type: 'item',
            module: 'new_customer',
            title: 'New Customer',
            link: '/customer/new',
          },
          {
            type: 'item',
            module: 'customer_list',
            title: 'Customer List',
            link: '/customer/list',
          },
          {
            type: 'item',
            module: 'customer_complaint_list',
            title: 'Complaint List',
            link: '/customer/complaints',
          },
          {
            type: 'item',
            module: 'customer_opening_balances_list',
            title: 'Opening Balances Customer',
            link: '/customer/openingbalances/list',
          },
        ],
      },
      {
        type: 'list',
        title: 'Supplier',
        icon: <UserOutlined />,
        subModules: [
          {
            type: 'item',
            module: 'new_supplier',
            title: 'New Supplier',
            link: '/supplier/new',
          },
          {
            type: 'item',
            module: 'supplier_list',
            title: 'Supplier List',
            link: '/supplier/list',
          },
          {
            type: 'item',
            module: 'supplier_opening_balances_list',
            title: 'Opening Balances Supplier',
            link: '/supplier/openingbalances/list',
          },
        ],
      },
      {
        type: 'list',
        title: 'Transporter',
        icon: <CarOutlined />,
        subModules: [
          {
            type: 'item',
            module: 'new_transporter',
            title: 'New Transporter',
            link: '/transporter/new',
          },
          {
            type: 'item',
            module: 'transporter_list',
            title: 'Transporter List',
            link: '/transporter/list',
          },
        ],
      },
    ],
  },
  {
    type: 'list',
    title: 'Dropdowns',
    icon: <SettingOutlined />,
    subModules: [
      {
        type: 'item',
        module: 'unit_of_measurement_list',
        title: 'Unit Of Measurement List',
        link: '/uom',
      },
      {
        type: 'item',
        module: 'hsn_code_list',
        title: 'HSN Code List',
        link: '/hsncode',
      },
      {
        type: 'item',
        module: 'category_list',
        title: 'Category List',
        link: '/formulations/category',
      },
      {
        type: 'item',
        module: 'subcategory_list',
        title: 'Subcategory List',
        link: '/formulations/subcategory',
      },
      {
        type: 'item',
        module: 'dosage_form_list',
        title: 'Dosage Form List',
        link: '/formulations/dosageform',
      },
      {
        type: 'item',
        module: 'section_list',
        title: 'Section List',
        link: '/formulations/section',
      },
      // {
      //   type: 'item',
      //   module: 'section_list',
      //   title: 'Generic List',
      //   link: '/formulations/generic',
      // },
      {
        type: 'item',
        module: 'time_motion_list',
        title: 'Time Motion List',
        link: '/formulations/time-motions',
      },
      {
        type: 'item',
        module: 'narration_list',
        title: 'Narration List',
        link: '/narration',
      },
      {
        type: 'item',
        module: 'payment_category',
        title: 'Payment Category',
        link: '/paymentcategory',
      },
      {
        type: 'item',
        module: 'location_list',
        title: 'Location',
        link: '/location',
      },
      {
        type: 'item',
        module: 'employee_role_list',
        title: 'Employee Role List',
        link: '/employeerole',
      },
      {
        type: 'item',
        module: 'chargeList',
        title: 'Charges',
        link: '/charges',
      },
      {
        type: 'item',
        module: 'batch_series',
        title: 'Batch Series',
        link: '/batch-series',
      },
    ],
  },
  {
    type: 'list',
    title: 'User',
    icon: <UserOutlined />,
    subModules: [
      {
        type: 'item',
        module: 'new_user',
        title: 'New User',
        link: '/user/new',
      },
      {
        type: 'item',
        module: 'user_list',
        title: 'User List',
        link: '/user/list',
      },
      {
        type: 'list',
        title: 'Access',
        icon: <UnlockOutlined />,
        subModules: [
          {
            type: 'item',
            module: 'new_role',
            title: 'New Role',
            link: '/role/new',
          },
          {
            type: 'item',
            module: 'role_list',
            title: 'Role List',
            link: '/role/list',
          },
        ],
      },
    ],
  },
  {
    type: 'list',
    title: 'WhatsApp',
    icon: <WhatsAppOutlined />,
    subModules: [
      {
        type: 'item',
        module: 'whatsapp',
        title: 'Received',
        link: '/whatsapp/received',
      },
      {
        type: 'item',
        module: 'whatsapp',
        title: 'Sent',
        link: '/whatsapp/sent',
      },
    ],
  },
  {
    type: 'list',
    title: 'Marketing',
    icon: <MenuOutlined />,
    subModules: [
      {
        type: 'item',
        module: 'send_bulk_emails',
        title: 'Email',
        link: '/marketing/emailing',
      },
      // {
      //   type: 'item',
      //   module: 'send_bulk_messages',
      //   title: 'WhatsApp',
      //   link: '/marketing/messaging',
      // },
      {
        type: 'item',
        module: 'general_files',
        title: 'Files',
        link: '/marketing/files',
      },
    ],
  },
  // {
  //   type: 'list',
  //   title: 'Social Cause',
  //   icon: <MenuOutlined />,
  //   subModules: [
  //     {
  //       type: 'item',
  //       module: 'social_cause',
  //       title: 'Form',
  //       link: '/patients/form',
  //     },
  //     {
  //       type: 'item',
  //       module: 'social_cause',
  //       title: 'List',
  //       link: '/patients/list',
  //     },
  //   ],
  // },
];

export const getSubModules = modules => {
  const list = [];

  modules.map(({ type, module, subModules }) => {
    if (type === 'item') {
      list.push(module);
    } else if (type === 'list') {
      list.push(...getSubModules(subModules));
    }
  });

  return list;
};

const moduleLinkings = {};

Modules.map(el => {
  if (el.subModules && el.subModules.length) {
    el.subModules.map(({ key, link }) => {
      moduleLinkings[link] = key;
    });
  }
});

export const getDefaultSelectedKeys = (link = '') => moduleLinkings[link];

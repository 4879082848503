export const ledgerContactList = (state = [], action) => {
  if (action.type === 'FETCH_LEDGER_CONTACT_LIST') {
    return action.payload;
  }
  if (action.type === 'CLEAR_LEDGER_CONTACT_LIST') {
    return [];
  }
  return state;
};

export const ledgerContact = (state = {}, action) => {
  if (action.type === 'FETCH_LEDGER_CONTACT') {
    return action.payload;
  }
  if (action.type === 'CLEAR_LEDGER_CONTACT') {
    return {};
  }
  return state;
};

export const ledgerContactIdList = (state = [], action) => {
  if (action.type === 'FETCH_LEDGER_CONTACT_ID_LIST') {
    return action.payload;
  }
  if (action.type === 'CLEAR_LEDGER_CONTACT_ID_LIST') {
    return [];
  }
  return state;
};

export const ledgerContactLedger = (state = {}, action) => {
  if (action.type === 'FETCH_LEDGER_CONTACT_LEDGER') {
    return action.payload;
  }
  if (action.type === 'CLEAR_LEDGER_CONTACT_LEDGER') {
    return {};
  }
  return state;
};

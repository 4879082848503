import React, { useState } from 'react';
import PropTypes from 'prop-types';
import SunEditor from 'suneditor-react';
import './suneditor.css';
import { Button, Col, Input, Row, Select, Upload } from 'antd';
import { Field, reduxForm } from 'redux-form';
import withStyles from 'react-jss';
import Modal from 'antd/lib/modal/Modal';
import { CloudUploadOutlined } from '@ant-design/icons';
import { useHistory } from 'react-router';
import Structure from '../Structure/Structure';
import Heading from '../Components/Heading';
import GetField from '../Components/GetField';
import styles from '../CommonStyles';
import { errorNotification } from '../Snackbar';
import Api from '../Api';
import LeftGrid from '../Components/LeftGrid';
import RightGrid from '../Components/RightGrid';

const AntSelect = GetField(Select);
const AntInput = GetField(Input);

const Emailing = props => {
  const { classes, handleSubmit } = props;
  const history = useHistory();

  const [editorContent, setEditorContent] = useState(null);
  const [filesModal, setFilesModal] = useState(false);
  const [fileList, setFileList] = useState([]);

  const handleChange = content => {
    setEditorContent(content);
  };

  const showFileModal = () => {
    setFilesModal(true);
  };

  const hideFileModal = () => {
    setFilesModal(false);
  };

  const onRemove = file => {
    const index = fileList.indexOf(file);
    const newFileList = fileList.slice();
    newFileList.splice(index, 1);
    setFileList(newFileList);
  };

  const beforeUpload = (file, files) => {
    setFileList([...fileList, ...files]);
    return false;
  };

  const onSubmit = async formValues => {
    try {
      const formData = new FormData();
      formData.append('to', formValues.to);
      formData.append('subject', formValues.subject);
      formData.append('html', editorContent);
      fileList.forEach((file, index) => {
        formData.append(`files[${index}]`, file);
      });
      await Api.post(`/marketing/email`, formData, {
        headers: { 'content-type': 'multipart/form-data' },
      });
      setFileList([]);
      history.push('/');
    } catch (err) {
      errorNotification(err);
    }
  };

  return (
    <Structure>
      <>
        <Heading text="Emailing" />
        <form onSubmit={handleSubmit(onSubmit)}>
          <Row>
            <LeftGrid>
              <Field label="Whom to send" allowClear required name="to" component={AntSelect}>
                <Select.Option key={null} value="" disabled>
                  Select whom to send
                </Select.Option>
                <Select.Option key="all" value="all">
                  All
                </Select.Option>
                <Select.Option key="customer" value="customer">
                  Customer
                </Select.Option>
                <Select.Option key="supplier" value="supplier">
                  Supplier
                </Select.Option>
                <Select.Option key="team" value="team">
                  Team
                </Select.Option>
              </Field>
            </LeftGrid>
            <RightGrid>
              <Field label="Subject" required allowClear name="subject" placeholder="Subject" component={AntInput} />
            </RightGrid>
          </Row>
          <Row>
            <LeftGrid>
              <Button onClick={() => showFileModal()}>Upload Files</Button>
            </LeftGrid>
          </Row>
          <br />
          <Row>
            <Col
              xs={{ span: 22, offset: 1 }}
              sm={{ span: 22, offset: 1 }}
              md={{ span: 22, offset: 1 }}
              lg={{ span: 22, offset: 1 }}
              xl={{ span: 22, offset: 1 }}
            >
              <SunEditor
                name="emailText"
                width="100%"
                height="100"
                placeholder="Please type here..."
                onChange={handleChange}
              />
            </Col>
          </Row>
          <br />
          <Row className={classes.buttonContainer}>
            <Button className={classes.button} type="primary" htmlType="submit">
              Submit
            </Button>
          </Row>
          <Modal
            onOk={hideFileModal}
            open={filesModal}
            centered
            maskClosable={false}
            title="Upload Files."
            width="500px"
            okText="Close"
            cancelButtonProps={{ className: classes.hide }}
            okButtonProps={{ className: classes.button }}
            closable={false}
          >
            <Upload multiple onRemove={onRemove} beforeUpload={beforeUpload} fileList={fileList}>
              <Button icon={<CloudUploadOutlined />}>Select Files</Button>
            </Upload>
          </Modal>
        </form>
      </>
    </Structure>
  );
};

Emailing.propTypes = {
  classes: PropTypes.object.isRequired,
  handleSubmit: PropTypes.func.isRequired,
};

const StyledEmailing = withStyles(styles)(Emailing);

const FormedEmailing = reduxForm({
  form: 'Emailing',
})(StyledEmailing);

export default FormedEmailing;

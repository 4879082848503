import React, { useEffect, useState, Fragment } from 'react';
import PropTypes from 'prop-types';
import { Button, Col, DatePicker, Input, Popconfirm, Row, Select } from 'antd';
import { useHistory } from 'react-router';
import { useDispatch, useSelector } from 'react-redux';
import withStyles from 'react-jss';
import { Field, getFormValues, reduxForm } from 'redux-form';
import Modal from 'antd/lib/modal/Modal';
import moment from 'moment';
import startCase from 'lodash/startCase';
import GetField from '../Components/GetField';
import styles from '../CommonStyles';
import LeftGrid from '../Components/LeftGrid';
import RightGrid from '../Components/RightGrid';
import { get } from '../utils';
import { errorNotification, successNotification } from '../Snackbar';
import Api from '../Api';
import { consignorList } from '../constants';
import {
  clearCustomerIdList,
  clearFormulationIdList,
  clearOrderList,
  getCustomerIdList,
  getFormulationIdList,
  getOrderList,
} from '../Actions';
import Structure from '../Structure/Structure';
import Heading from '../Components/Heading';

const AntInput = GetField(Input);
const AntSelect = GetField(Select);
const AntDatePicker = GetField(DatePicker);

const NewCreditNote = props => {
  const { handleSubmit, classes, change } = props;
  const history = useHistory();
  const dispatch = useDispatch();

  const orderList = useSelector(state => state.orderList);
  const customerIdList = useSelector(state => state.customerIdList);
  const formulationIdList = useSelector(state => state.formulationIdList);

  const [formValueModal, setFormValuesModal] = useState(false);
  const [confirmLoading, setConfirmLoading] = useState(false);
  const [preview, setPreview] = useState({});
  const FormValues = useSelector(state => getFormValues('NewCreditNote')(state));

  useEffect(() => {
    dispatch(getOrderList({ isAuthorized: true, isDispatched: true }));
    dispatch(getCustomerIdList({ status: 'Active' }));
    dispatch(getFormulationIdList());
    return () => {
      dispatch(clearOrderList());
      dispatch(clearCustomerIdList());
      dispatch(clearFormulationIdList());
    };
  }, []);

  useEffect(() => {
    if (FormValues && FormValues.formulationId) {
      dispatch(getOrderList({ isDispatched: true, formulationId: FormValues.formulationId }));
    }
  }, [FormValues && FormValues.formulationId]);

  useEffect(() => {
    if (FormValues && FormValues.orderId) {
      const orderObj = orderList.find(order => order.id === FormValues.orderId);
      if (orderObj) {
        change('quantity', orderObj.quantity);
        change('rate', orderObj.rate);
        change('formulationId', orderObj.formulationId);
        change('customerId', orderObj.customerId);
      }
    }
  }, [FormValues && FormValues.orderId, orderList]);

  useEffect(() => {
    if (FormValues && FormValues.formulationId) {
      const formulationObj = formulationIdList.find(formulation => formulation.id === FormValues.formulationId);
      if (formulationObj) {
        change('gst', formulationObj.gst);
      }
    }
  }, [FormValues && FormValues.formulationId, formulationIdList]);

  useEffect(() => {
    if (FormValues && FormValues.quantity && FormValues.rate) {
      const value = (+FormValues.quantity * +FormValues.rate).toFixed(2);
      change('value', value);
    } else {
      change('value', '');
    }
  }, [FormValues && FormValues.quantity, FormValues && FormValues.rate]);

  useEffect(() => {
    if (FormValues && FormValues.value && (FormValues.gst || FormValues.gst === 0)) {
      const gstValue = ((+FormValues.value * +FormValues.gst) / 100).toFixed(2);
      change('gstValue', gstValue);
    } else {
      change('gstValue', '');
    }
  }, [FormValues && FormValues.value, FormValues && (FormValues.gst || FormValues.gst === 0)]);

  useEffect(() => {
    if (FormValues && FormValues.forwardingCharges) {
      const forwardingChargesTax = ((+FormValues.forwardingCharges * 18) / 100).toFixed(2);
      change('forwardingChargesTax', forwardingChargesTax);
    } else {
      change('forwardingChargesTax', '');
    }
  }, [FormValues && FormValues.forwardingCharges]);

  useEffect(() => {
    if (FormValues && FormValues.value && FormValues && FormValues.gstValue) {
      const total = (
        +FormValues.value +
        +FormValues.gstValue +
        (+FormValues.forwardingCharges || 0) +
        (+FormValues.forwardingChargesTax || 0) +
        (+FormValues.otherChargesDiscount || 0) +
        (+FormValues.roundOf || 0)
      ).toFixed(2);
      change('total', total);
    }
  }, [
    FormValues && FormValues.value,
    FormValues && FormValues.gstValue,
    FormValues && FormValues.forwardingCharges,
    FormValues && FormValues.forwardingChargesTax,
    FormValues && FormValues.otherChargesDiscount,
    FormValues && FormValues.roundOf,
  ]);

  const showFormValuesModal = () => {
    const creditDate = get(FormValues, ['creditDate']);
    const type = get(FormValues, ['type']);

    const _preview = {
      orderNo: '--',
      customer: '--',
      consignor: get(FormValues, ['consignor'], '--'),
      type: type ? startCase(type) : '--',
      creditDate: creditDate ? moment(creditDate).format('DD MMM YYYY') : '--',
      formulation: '--',
      hsn: '--',
      quantity: get(FormValues, ['quantity'], '--'),
      rate: get(FormValues, ['rate'], '--'),
      value: get(FormValues, ['value'], '--'),
      gst: get(FormValues, ['gst'], '--'),
      gstValue: get(FormValues, ['gstValue'], '--'),
      forwardingCharges: get(FormValues, ['forwardingCharges'], '--'),
      forwardingChargesTax: get(FormValues, ['forwardingChargesTax'], '--'),
      otherChargesDiscount: get(FormValues, ['otherChargesDiscount'], '--'),
      roundOf: get(FormValues, ['roundOf'], '--'),
      total: get(FormValues, ['total'], '--'),
      remarks: get(FormValues, ['remarks'], '--'),
    };

    const formulationId = get(FormValues, ['formulationId'], null);
    if (formulationId) {
      const formulationObj = formulationIdList.find(material => material.id === +formulationId);
      if (formulationObj) {
        _preview.formulation = formulationObj.name;
        _preview.hsn = formulationObj.hsnCode;
      }
    }

    const orderId = get(FormValues, ['orderId'], null);
    if (orderId) {
      const orderObj = orderList.find(order => order.id === +orderId);
      if (orderObj) {
        _preview.orderNo = orderObj.id;
        _preview.orderRate = orderObj.rate;
      }
    }

    const customerId = get(FormValues, ['customerId'], null);
    if (customerId) {
      const customerObj = customerIdList.find(customer => customer.id === +customerId);
      if (customerObj) {
        _preview.customer = customerObj.name;
      }
    }

    setPreview(_preview);
    setFormValuesModal(true);
  };

  const hideFormValuesModal = () => {
    setFormValuesModal(false);
    setPreview({});
  };

  const onSubmit = async () => {
    try {
      setConfirmLoading(true);
      await Api.post('/credit-note', FormValues);
      successNotification('Credit note added');
      setConfirmLoading(false);
      hideFormValuesModal();
      history.push('/creditnote/list');
    } catch (err) {
      setConfirmLoading(false);
      errorNotification(err);
    }
  };

  return (
    <Structure>
      <>
        <Heading text="New Credit Note" />
        <form onSubmit={handleSubmit(onSubmit)}>
          <Row>
            <LeftGrid>
              <Field label="Finished Good Name" allowClear required name="formulationId" component={AntSelect}>
                <Select.Option key={null} value="" disabled>
                  Select finished good name
                </Select.Option>
                {formulationIdList.map(formulation => (
                  <Select.Option key={formulation.id} value={formulation.id}>
                    {formulation.name}
                  </Select.Option>
                ))}
              </Field>
            </LeftGrid>
            <RightGrid>
              <Field label="Order Number" allowClear required name="orderId" component={AntSelect}>
                <Select.Option key={null} value="">
                  Select order number
                </Select.Option>
                {orderList.map(order => (
                  <Select.Option key={order.id} value={order.id}>
                    {order.id}
                  </Select.Option>
                ))}
              </Field>
            </RightGrid>
          </Row>
          <Row>
            <LeftGrid>
              <Field label="Customer" disabled allowClear required name="customerId" component={AntSelect}>
                <Select.Option key={null} value="" disabled>
                  Select customer
                </Select.Option>
                {customerIdList.map(customer => (
                  <Select.Option key={customer.id} value={customer.id}>
                    {customer.name}
                  </Select.Option>
                ))}
              </Field>
            </LeftGrid>
            <RightGrid>
              <Field label="Consignor" allowClear required name="consignor" component={AntSelect}>
                <Select.Option key={null} value="" disabled>
                  Select consignor
                </Select.Option>
                {consignorList.map(consignor => (
                  <Select.Option key={consignor} value={consignor}>
                    {consignor}
                  </Select.Option>
                ))}
              </Field>
            </RightGrid>
          </Row>
          <Row>
            <LeftGrid>
              <Field label="Sale Type" allowClear required name="type" component={AntSelect}>
                <Select.Option key={null} value="" disabled>
                  Select sale type
                </Select.Option>
                <Select.Option key="invoice" value="invoice">
                  Invoice
                </Select.Option>
                <Select.Option key="challan" value="challan">
                  Challan
                </Select.Option>
              </Field>
            </LeftGrid>
            <RightGrid>
              <Field
                allowClear
                required
                label="Credit Note Date"
                name="creditDate"
                placeholder="Credit Note Date"
                component={AntDatePicker}
              />
            </RightGrid>
          </Row>
          <Row>
            <LeftGrid>
              <Field label="HSN Code" allowClear disabled required name="formulationId" component={AntSelect}>
                <Select.Option key={null} value="" disabled>
                  Select finished good name
                </Select.Option>
                {formulationIdList.map(formulation => (
                  <Select.Option key={formulation.id} value={formulation.id}>
                    {formulation.hsnCode}
                  </Select.Option>
                ))}
              </Field>
            </LeftGrid>
            <RightGrid>
              <Field
                label="Quantity"
                allowClear
                type="number"
                min={0}
                name="quantity"
                placeholder="Quantity"
                component={AntInput}
              />
            </RightGrid>
          </Row>
          <Row>
            <LeftGrid>
              <Field
                label="Rate"
                allowClear
                type="number"
                min={0}
                name="rate"
                placeholder="Rate"
                component={AntInput}
              />
            </LeftGrid>
            <RightGrid>
              <Field
                label="Sale Value"
                disabled
                allowClear
                type="number"
                min={0}
                name="value"
                placeholder="Sale Value"
                component={AntInput}
              />
            </RightGrid>
          </Row>
          <Row>
            <LeftGrid>
              <Field
                label="GST (in %)"
                allowClear
                disabled
                type="number"
                min={0}
                name="gst"
                placeholder="GST (in %)"
                component={AntInput}
              />
            </LeftGrid>
            <RightGrid>
              <Field
                label="GST Value"
                disabled
                allowClear
                type="number"
                min={0}
                name="gstValue"
                placeholder="GST Value"
                component={AntInput}
              />
            </RightGrid>
          </Row>
          <Row>
            <LeftGrid>
              <Field
                label="Forwarding Charges"
                allowClear
                type="number"
                min={0}
                name="forwardingCharges"
                placeholder="Forwarding Charges"
                component={AntInput}
              />
            </LeftGrid>
            <RightGrid>
              <Field
                label="Forwarding Charges Tax Value (Tax 18%)"
                allowClear
                disabled
                type="number"
                min={0}
                name="forwardingChargesTax"
                placeholder="Forwarding Charges Tax Value (Tax 18%)"
                component={AntInput}
              />
            </RightGrid>
          </Row>
          <Row>
            <LeftGrid>
              <Field
                label="Other Charges/Discount (+/-)"
                allowClear
                type="number"
                name="otherChargesDiscount"
                placeholder="Other Charges/Discount (+/-)"
                component={AntInput}
              />
            </LeftGrid>
            <RightGrid>
              <Field
                label="Round of (+/-)"
                allowClear
                type="number"
                name="roundOf"
                placeholder="Round of (+/-)"
                component={AntInput}
              />
            </RightGrid>
          </Row>
          <Row>
            <LeftGrid>
              <Field
                label="Total"
                disabled
                allowClear
                type="number"
                min={0}
                name="total"
                placeholder="Total"
                component={AntInput}
              />
            </LeftGrid>
            <RightGrid>
              <Field label="Remarks" allowClear name="remarks" placeholder="Remarks" component={AntInput} />
            </RightGrid>
          </Row>
          <Row className={classes.buttonContainer}>
            <Button className={classes.button} type="primary" onClick={showFormValuesModal}>
              Preview
            </Button>
          </Row>
        </form>
        <Modal
          onCancel={hideFormValuesModal}
          open={formValueModal}
          confirmLoading={confirmLoading}
          centered
          width="800px"
          maskClosable={false}
          closable={false}
          cancelText="Back"
          okButtonProps={{ className: classes.button }}
          okText={
            <Popconfirm
              title="Sure want to submit form?"
              onConfirm={() => onSubmit()}
              okText="Confirm"
              cancelText="Cancel"
            >
              Submit
            </Popconfirm>
          }
        >
          <Row>
            <Col xs={24} lg={12}>
              <p>
                Order Number:
                <span className={classes.detailColor}>{preview.orderNo}</span>
              </p>
            </Col>
            <Col xs={24} lg={12}>
              <p>
                Customer:
                <span className={classes.detailColor}>{preview.customer}</span>
              </p>
            </Col>
          </Row>
          <Row>
            <Col xs={24} lg={12}>
              <p>
                Consignor:
                <span className={classes.detailColor}>{preview.consignor}</span>
              </p>
            </Col>
            <Col xs={24} lg={12}>
              <p>
                Sale Type:
                <span className={classes.detailColor}>{preview.type}</span>
              </p>
            </Col>
          </Row>
          <Row>
            <Col xs={24} lg={12}>
              <p>
                Credit Note Date:
                <span className={classes.detailColor}>{preview.creditDate}</span>
              </p>
            </Col>
            <Col xs={24} lg={12}>
              <p>
                Finished Good Name:
                <span className={classes.detailColor}>{preview.formulation}</span>
              </p>
            </Col>
          </Row>
          <Row>
            <Col xs={24} lg={12}>
              <p>
                HSN Code:
                <span className={classes.detailColor}>{preview.hsn}</span>
              </p>
            </Col>
            <Col xs={24} lg={12}>
              <p>
                Quantity:
                <span className={classes.detailColor}>{preview.quantity}</span>
              </p>
            </Col>
          </Row>
          <Row>
            <Col xs={24} lg={12}>
              <p>
                Rate:
                <span className={classes.detailColor}>{preview.rate}</span>
              </p>
            </Col>
            <Col xs={24} lg={12}>
              <p>
                Sale Value:
                <span className={classes.detailColor}>{preview.value}</span>
              </p>
            </Col>
          </Row>
          <Row>
            <Col xs={24} lg={12}>
              <p>
                GST (in %):
                <span className={classes.detailColor}>{preview.gst}</span>
              </p>
            </Col>
            <Col xs={24} lg={12}>
              <p>
                GST Value:
                <span className={classes.detailColor}>{preview.gstValue}</span>
              </p>
            </Col>
          </Row>
          <Row>
            <Col xs={24} lg={12}>
              <p>
                Forwarding Charges:
                <span className={classes.detailColor}>{preview.forwardingCharges}</span>
              </p>
            </Col>
            <Col xs={24} lg={12}>
              <p>
                Forwarding Charges Tax Value (Tax 18%):
                <span className={classes.detailColor}>{preview.forwardingChargesTax}</span>
              </p>
            </Col>
          </Row>
          <Row>
            <Col xs={24} lg={12}>
              <p>
                Other Charges/Discount (+/-):
                <span className={classes.detailColor}>{preview.otherChargesDiscount}</span>
              </p>
            </Col>
            <Col xs={24} lg={12}>
              <p>
                Round of (+/-):
                <span className={classes.detailColor}>{preview.roundOf}</span>
              </p>
            </Col>
          </Row>
          <Row>
            <Col xs={24} lg={12}>
              <p>
                Total:
                <span className={classes.detailColor}>{preview.total}</span>
              </p>
            </Col>
            <Col xs={24} lg={12}>
              <p>
                Remarks:
                <span className={classes.detailColor}>{preview.remarks}</span>
              </p>
            </Col>
          </Row>
        </Modal>
      </>
    </Structure>
  );
};

NewCreditNote.propTypes = {
  handleSubmit: PropTypes.func.isRequired,
  classes: PropTypes.object.isRequired,
  change: PropTypes.func.isRequired,
};

const StyledNewCreditNote = withStyles(styles)(NewCreditNote);

const FormedNewCreditNote = reduxForm({
  form: 'NewCreditNote',
})(StyledNewCreditNote);

export default FormedNewCreditNote;

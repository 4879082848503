import React, { useEffect, useState, useRef, useCallback, useMemo } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import withStyles from 'react-jss';
import PropTypes from 'prop-types';
import { Table, Popconfirm, Tooltip, Modal, Row, Col, Button, Input, DatePicker } from 'antd';
import { Link } from 'react-router-dom';
import { EditOutlined, MailOutlined, EyeOutlined, FileTextOutlined, FileExcelOutlined } from '@ant-design/icons';
import { useReactToPrint } from 'react-to-print';
import fileDownload from 'js-file-download';
import { Field, getFormValues, reduxForm } from 'redux-form';
import Structure from '../Structure/Structure';
import { sorter, onFilter, filterDropdown, get } from '../utils';
import Api from '../Api';
import { getCustomerList, clearCustomerList, clearCustomerLedger, getCustomerLedger } from '../Actions';
import { errorNotification, successNotification, errorNotificationOnBlob, infoNotification } from '../Snackbar';
import styles from '../CommonStyles';
import Ledger from '../Ledger/Ledger';
import GetField from '../Components/GetField';

const AntInput = GetField(Input);
const AntDatePicker = GetField(DatePicker);

const CustomerList = props => {
  const { classes } = props;
  const dispatch = useDispatch();

  const auth = useSelector(state => state.auth);
  const permissions = get(auth, ['permissions'], []);
  const customerList = useSelector(state => state.customerList);
  const customerLedger = useSelector(state => state.customerLedger);

  const [isModalVisible, setIsModalVisible] = useState(false);
  const [customerId, setCustomerId] = useState(null);
  const [customer, setCustomer] = useState(null);

  const [isNotesModalVisible, setIsNotesModalVisible] = useState(false);
  const [confirmLoading, setConfirmLoading] = useState(false);

  const [from, setFrom] = useState(null);
  const [to, setTo] = useState(null);
  const [rowKeys, setRowKeys] = useState([]);

  const onSelectChange = useCallback(selectedRowKeys => setRowKeys(selectedRowKeys), [setRowKeys]);

  const FormValues = useSelector(state => getFormValues('CustomerNoteForm')(state));

  const printRef = useRef();
  const handlePrint = useReactToPrint({
    content: () => printRef.current,
    pageStyle: `
    @page {
      size: 210mm 297mm;
      margin: 5mm;
    }

    @media all {
      .pagebreak {
        margin-top: 1rem;
        display: block;
        page-break-before: auto;
    }

    @media print {
      .pagebreak {
        margin-top: 1rem;
        display: block;
        page-break-before: auto;
      }
    }
  `,
  });

  useEffect(() => {
    dispatch(getCustomerList());
    return () => {
      dispatch(clearCustomerList());
      dispatch(clearCustomerLedger());
    };
  }, []);

  useEffect(() => {
    if (customerId && isModalVisible) {
      dispatch(getCustomerLedger(customerId));
    }
  }, [customerId, isModalVisible]);

  const showModal = record => {
    setIsModalVisible(true);
    setCustomer(record);
    setCustomerId(record.id);
  };

  const handleOk = () => {
    setIsModalVisible(false);
    setCustomer(null);
    setCustomerId(null);
    dispatch(clearCustomerLedger());
  };

  const showNoteModal = record => {
    setIsNotesModalVisible(true);
    setCustomer(record);
    setCustomerId(record.id);
  };

  const hideNoteModal = () => {
    setIsNotesModalVisible(false);
    setCustomer(null);
    setCustomerId(null);
  };

  // const onDelete = async id => {
  //   try {
  //     await Api.delete(`/customers/${id}`);
  //     successNotification('Customer deleted');
  //     dispatch(getCustomerList());
  //   } catch (err) {
  //     errorNotification(err);
  //   }
  // };

  const onEmail = async id => {
    try {
      await Api.get(`/customers/${id}/inventory`);
      successNotification('Email sent');
    } catch (err) {
      errorNotification(err);
    }
  };

  const onNoteSubmit = async () => {
    try {
      setConfirmLoading(true);
      await Api.post(`/customers/${customerId}/notes`, FormValues);
      successNotification('Note submitted');
      setConfirmLoading(false);
      hideNoteModal();
    } catch (err) {
      errorNotification(err);
    }
  };

  const onFileDownload = async id => {
    try {
      const { data } = await Api.getFile(`/customers/${id}/export`);
      fileDownload(data, `customer-data.xlsx`);
      successNotification('File downloaded');
    } catch (err) {
      errorNotificationOnBlob(err);
    }
  };

  const onCustomersDownload = async () => {
    try {
      const { data } = await Api.getFile(`/customers/export`);
      fileDownload(data, `customers.xlsx`);
      successNotification('File downloaded');
    } catch (err) {
      errorNotificationOnBlob(err);
    }
  };

  const onLedgerFileDownload = useCallback(async () => {
    try {
      infoNotification('Downloading...');
      const { data } = await Api.getFile('/customers/ledgers/export', { from, to, customerIds: rowKeys.join(',') });
      fileDownload(data, `customer-ledger.xlsx`);
      successNotification('File downloaded');
    } catch (err) {
      errorNotificationOnBlob(err);
    }
  }, [from, to, rowKeys]);

  const tooltipShow = arg => (
    <Tooltip placement="topLeft" title={arg}>
      {arg}
    </Tooltip>
  );

  const columns = [
    {
      title: 'Name',
      dataIndex: 'name',
      sorter: sorter('name'),
      filterDropdown,
      onFilter: onFilter('name'),
      ellipsis: { showTitle: false },
      render: tooltipShow,
    },
    {
      title: 'Subcategory',
      dataIndex: 'subcategories',
      sorter: sorter('subcategories'),
      onFilter: onFilter('subcategories'),
      filterDropdown,
    },
    {
      title: 'GST Number',
      dataIndex: 'gstNo',
      sorter: sorter('gstNo'),
      filterDropdown,
      onFilter: onFilter('gstNo'),
      ellipsis: { showTitle: false },
      render: tooltipShow,
    },
    {
      title: 'Billing Address',
      dataIndex: 'billingAddress',
      sorter: sorter('billingAddress'),
      filterDropdown,
      onFilter: onFilter('billingAddress'),
      ellipsis: { showTitle: false },
      render: tooltipShow,
    },
    // {
    //   title: 'Corr Address',
    //   dataIndex: 'corrAddress',
    //   sorter: sorter('corrAddress'),
    //   filterDropdown,
    //   onFilter: onFilter('corrAddress'),
    //   ellipsis: { showTitle: false },
    //   render: tooltipShow,
    // },
    {
      title: 'State',
      dataIndex: 'state',
      sorter: sorter('state'),
      filterDropdown,
      onFilter: onFilter('state'),
    },
    {
      title: 'Contact Person',
      dataIndex: 'contactPerson',
      sorter: sorter('contactPerson'),
      filterDropdown,
      onFilter: onFilter('contactPerson'),
    },
    {
      title: 'Primary Contact',
      dataIndex: 'primaryContact',
      sorter: sorter('primaryContact'),
      filterDropdown,
      onFilter: onFilter('primaryContact'),
    },
    // {
    //   title: 'Contacts',
    //   dataIndex: 'contacts',
    //   sorter: sorter('contacts'),
    //   filterDropdown,
    //   onFilter: onFilter('contacts'),
    //   ellipsis: { showTitle: false },
    //   render: tooltipShow,
    // },
    {
      title: 'Primary Email',
      dataIndex: 'primaryEmail',
      sorter: sorter('primaryEmail'),
      filterDropdown,
      onFilter: onFilter('primaryEmail'),
      ellipsis: { showTitle: false },
      render: tooltipShow,
    },
    // {
    //   title: 'Emails',
    //   dataIndex: 'emails',
    //   sorter: sorter('emails'),
    //   filterDropdown,
    //   onFilter: onFilter('emails'),
    //   ellipsis: { showTitle: false },
    //   render: tooltipShow,
    // },
    {
      title: 'Payment Term',
      dataIndex: 'paymentTerms',
      sorter: sorter('paymentTerms'),
      filterDropdown,
      onFilter: onFilter('paymentTerms'),
      align: 'right',
    },
    {
      title: 'Handler',
      dataIndex: 'handler',
      sorter: sorter('handler'),
      filterDropdown,
      onFilter: onFilter('handler'),
      render: tooltipShow,
    },
    {
      title: 'Payment Category',
      dataIndex: 'paymentCategory',
      sorter: sorter('paymentCategory'),
      onFilter: onFilter('paymentCategory'),
      filterDropdown,
      render: tooltipShow,
    },
    {
      title: 'Remarks',
      dataIndex: 'remarks',
      sorter: sorter('remarks'),
      filterDropdown,
      onFilter: onFilter('remarks'),
      ellipsis: { showTitle: false },
      render: tooltipShow,
    },
    {
      title: 'Query #',
      dataIndex: 'noOfQueries',
      key: 'noOfQueries',
      width: 85,
      sorter: true,
    },
  ];

  const renderActionIcons = record =>
    permissions.includes('edit_customer') ? (
      <Tooltip placement="bottom" title="Edit">
        <Link to={`/customer/edit/${record.id}`}>
          <EditOutlined className={classes.tableIcon} />
        </Link>
      </Tooltip>
    ) : null;

  if (permissions.includes('edit_customer')) {
    columns.push({
      title: 'Actions',
      render: renderActionIcons,
      width: '3%',
    });
  }

  const renderInventoryIcons = record => (
    <>
      {permissions.includes('customer_details_view') ? (
        <>
          <Tooltip placement="bottom" title="View Details">
            <Link to={`/customer/details/${record.id}`} target="_blank" rel="noreferrer noopener" without="true">
              <EyeOutlined className={classes.tableIcon} />
            </Link>
          </Tooltip>
          &nbsp;&nbsp;&nbsp;&nbsp;
        </>
      ) : null}
      {permissions.includes('email_customer_details_link') ? (
        <Tooltip placement="bottom" title="Email Link">
          <Popconfirm title="Are you sure?" onConfirm={() => onEmail(record.id)} okText="Yes" cancelText="No">
            <MailOutlined className={classes.tableIcon} />
          </Popconfirm>
        </Tooltip>
      ) : null}
    </>
  );

  if (permissions.includes('customer_details_view') || permissions.includes('email_customer_details_link')) {
    columns.push({
      title: 'Details',
      render: renderInventoryIcons,
      width: '3%',
    });
  }

  const renderViewIcons = record => (
    <Tooltip placement="bottom" title="View">
      <Link to={`/customer/view/${record.id}`}>
        <FileTextOutlined className={classes.tableIcon} />
      </Link>
    </Tooltip>
  );

  const renderNoteIcons = record =>
    permissions.includes('customer_details_view') ? (
      <Tooltip placement="top" title="Add Note">
        <FileTextOutlined className={classes.tableIcon} onClick={() => showNoteModal(record)} />
      </Tooltip>
    ) : null;

  columns.push(
    {
      title: 'View',
      render: renderViewIcons,
      fixed: 'right',
      width: '2%',
    },
    {
      title: 'Note',
      render: renderNoteIcons,
      fixed: 'right',
      width: '2%',
    }
  );

  const renderLedgerIcons = record =>
    permissions.includes('customer_ledger_view') ? (
      <Tooltip placement="top" title="View Ledger">
        <FileTextOutlined className={classes.tableIcon} onClick={() => showModal(record)} />
      </Tooltip>
    ) : null;

  if (permissions.includes('customer_ledger_view')) {
    columns.push({
      title: 'Ledger',
      fixed: 'right',
      render: renderLedgerIcons,
      width: '3%',
    });
  }

  const downloadColumn = record => (
    <Tooltip placement="bottom" title="Download">
      <FileExcelOutlined className={classes.tableIcon} onClick={() => onFileDownload(record.id)} />
    </Tooltip>
  );

  columns.push({
    title: 'Download',
    fixed: 'right',
    render: downloadColumn,
    width: '3%',
  });

  const rowKey = useMemo(() => record => record.id, []);

  const pagination = useMemo(
    () => ({
      defaultPageSize: 20,
      position: ['bottomLeft'],
      showTotal: total => `Total ${total} Records`,
    }),
    []
  );

  const scroll = useMemo(() => ({ x: 3000, y: window.innerHeight - 300 }), []);

  return (
    <Structure>
      <>
        {/* <Heading text={`Customer List (${rowCounts})`}> */}
        <Row gutter={[16, 16]}>
          <Col xs={24} sm={24} md={24} lg={6} xl={4} xxl={4}>
            <DatePicker placeholder="From Date" onChange={value => setFrom(value ? value.toISOString() : null)} />
          </Col>
          <Col xs={24} sm={24} md={24} lg={6} xl={4} xxl={4}>
            <DatePicker placeholder="To Date" onChange={value => setTo(value ? value.toISOString() : null)} />
          </Col>
          <Col xs={24} sm={24} md={24} lg={6} xl={4} xxl={4}>
            <Button icon={<FileExcelOutlined />} onClick={onLedgerFileDownload}>
              Export Ledger
            </Button>
          </Col>
          <Col xs={24} sm={24} md={24} lg={6} xl={4} xxl={4}>
            <Button icon={<FileExcelOutlined />} onClick={onCustomersDownload}>
              Export Customers
            </Button>
          </Col>
        </Row>
        {/* </Heading> */}
        <br />
        <Table
          bordered
          size="small"
          rowSelection={{
            rowKeys,
            onChange: onSelectChange,
          }}
          columns={columns}
          dataSource={customerList}
          rowKey={rowKey}
          pagination={pagination}
          scroll={scroll}
        />
        <Modal
          open={isModalVisible}
          onOk={handleOk}
          onCancel={handlePrint}
          maskClosable={false}
          closable={false}
          width="1000px"
          okText="Close"
          cancelText="Print"
          cancelButtonProps={{ className: classes.button }}
          okButtonProps={{ className: classes.button }}
        >
          <Ledger statement="Customer" statementData={customer} ledgerData={customerLedger} Reference={printRef} />
        </Modal>
        {isNotesModalVisible ? (
          <Modal
            open={isNotesModalVisible}
            onCancel={hideNoteModal}
            confirmLoading={confirmLoading}
            title="Followup Note."
            centered
            width="60%"
            maskClosable={false}
            closable={false}
            cancelText="Back"
            okButtonProps={{ className: classes.button }}
            okText={
              <Popconfirm
                title="Sure want to submit form?"
                onConfirm={() => onNoteSubmit()}
                okText="Confirm"
                cancelText="Cancel"
              >
                Submit
              </Popconfirm>
            }
          >
            <Row>
              <Col xs={{ span: 24 }}>
                <Field label="Note" allowClear name="note" placeholder="Note" component={AntInput} />
              </Col>
            </Row>
            <Row>
              <Col xs={{ span: 24 }}>
                <Field
                  required
                  allowClear
                  name="followUpOn"
                  showTime={{ format: 'hh:mm A' }}
                  label="Next Follow-up Date"
                  placeholder="Next Follow-up Date"
                  component={AntDatePicker}
                />
              </Col>
            </Row>
          </Modal>
        ) : null}
      </>
    </Structure>
  );
};

CustomerList.propTypes = {
  classes: PropTypes.object.isRequired,
};

const StyledCustomerList = withStyles(styles)(CustomerList);

const FormedCustomerList = reduxForm({
  form: 'CustomerNoteForm',
})(StyledCustomerList);

export default FormedCustomerList;

import React, { useCallback, useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import withStyles from 'react-jss';
import PropTypes from 'prop-types';
import { Table, Popconfirm, Tooltip, Button, Input, Modal, Row, Col, Select } from 'antd';
import { EditOutlined, DeleteOutlined, FileExcelOutlined } from '@ant-design/icons';
import { Controller, useForm } from 'react-hook-form';
import fileDownload from 'js-file-download';
import Structure from '../../Structure/Structure';
import { sorter, onFilter, filterDropdown, get } from '../../utils';
import Api from '../../Api';
import {
  getGenericList,
  clearGenericList,
  getGeneric,
  clearGeneric,
  getCategoryList,
  getSubcategoryList,
  getSectionList,
  getDosageFormList,
  clearCategoryList,
  clearSubcategoryList,
  clearSectionList,
  clearDosageFormList,
} from '../../Actions';
import { errorNotification, errorNotificationOnBlob, infoNotification, successNotification } from '../../Snackbar';
import styles from '../../CommonStyles';
import Heading from '../../Components/Heading';
import GetRHFField from '../../Components/GetRHFField';
import constants from '../../constants';

const AntInput = GetRHFField(Input);
const AntSelect = GetRHFField(Select);
const { Option } = Select;

const Generic = props => {
  const { classes } = props;
  const dispatch = useDispatch();

  const { control, reset, getValues } = useForm();

  const auth = useSelector(state => state.auth);
  const permissions = get(auth, ['permissions'], []);
  const genericList = useSelector(state => state.genericList);
  const categoryList = useSelector(state => state.categoryList);
  const subcategoryList = useSelector(state => state.subcategoryList);
  const sectionList = useSelector(state => state.sectionList);
  const dosageFormList = useSelector(state => state.dosageFormList);
  const generic = useSelector(state => state.generic);

  const [dropdownModal, setDropdownModal] = useState(false);
  const [confirmLoading, setConfirmLoading] = useState(false);
  const [editDropdownId, setEditDropdownId] = useState(null);

  useEffect(() => {
    dispatch(getGenericList());
    return () => {
      dispatch(clearGenericList());
      dispatch(clearCategoryList());
      dispatch(clearSubcategoryList());
      dispatch(clearSectionList());
      dispatch(clearDosageFormList());
    };
  }, []);

  useEffect(() => {
    if (dropdownModal) {
      dispatch(getCategoryList());
      dispatch(getSubcategoryList());
      dispatch(getSectionList());
      dispatch(getDosageFormList());
    }
  }, [dropdownModal]);

  useEffect(() => {
    reset(generic);
  }, [generic]);

  const onDelete = async id => {
    try {
      await Api.delete(`/dropdowns/generic-formulations/${id}`);
      successNotification('Generic deleted');
      dispatch(getGenericList());
    } catch (err) {
      errorNotification(err);
    }
  };

  const showDropdownModal = () => {
    setEditDropdownId(null);
    setDropdownModal(true);
  };

  const showEditDropdownModal = id => {
    setEditDropdownId(id);
    dispatch(getGeneric(id));
    setDropdownModal(true);
  };

  const hideDropdownModal = () => {
    reset({});
    setConfirmLoading(false);
    setEditDropdownId(null);
    dispatch(clearGeneric());
    setDropdownModal(false);
  };

  const onNew = async () => {
    try {
      const formValues = getValues();
      setConfirmLoading(true);
      await Api.post('/dropdowns/generic-formulations', formValues);
      setConfirmLoading(false);
      reset();
      setDropdownModal(false);
      successNotification('Generic added');
      dispatch(clearGeneric());
      dispatch(getGenericList());
    } catch (err) {
      setConfirmLoading(false);
      errorNotification(err);
    }
  };

  const onEdit = async () => {
    try {
      const formValues = getValues();
      setConfirmLoading(true);
      await Api.put(`/dropdowns/generic-formulations/${editDropdownId}`, formValues);
      setConfirmLoading(false);
      reset();
      setDropdownModal(false);
      successNotification('Generic updated');
      dispatch(clearGeneric());
      dispatch(getGenericList());
    } catch (err) {
      setConfirmLoading(false);
      errorNotification(err);
    }
  };

  const onFileDownload = useCallback(async () => {
    try {
      infoNotification('Downloading...');
      const { data } = await Api.getFile('/dropdowns/generic-formulations/export');
      fileDownload(data, `generics.xlsx`);
      successNotification('File downloaded');
    } catch (err) {
      errorNotificationOnBlob(err);
    }
  }, []);

  const columns = [
    {
      title: 'Code',
      dataIndex: 'code',
      sorter: sorter('code'),
      filterDropdown,
      onFilter: onFilter('code'),
      width: 80,
    },
    {
      title: 'Generic',
      dataIndex: 'name',
      sorter: sorter('name'),
      filterDropdown,
      onFilter: onFilter('name'),
      width: 200,
    },
    {
      title: 'Consignor',
      dataIndex: 'consignor',
      sorter: sorter('consignor'),
      onFilter: onFilter('consignor'),
      filterDropdown,
      width: 120,
    },
    {
      title: 'Category',
      dataIndex: 'category',
      sorter: sorter('category'),
      filterDropdown,
      onFilter: onFilter('category'),
      width: 120,
    },
    {
      title: 'Subcategory',
      dataIndex: 'subcategory',
      sorter: sorter('subcategory'),
      filterDropdown,
      onFilter: onFilter('subcategory'),
      width: 140,
    },
    {
      title: 'Section',
      dataIndex: 'section',
      sorter: sorter('section'),
      filterDropdown,
      onFilter: onFilter('section'),
      width: 120,
    },
    {
      title: 'Dosage Form',
      dataIndex: 'dosageForm',
      sorter: sorter('dosageForm'),
      filterDropdown,
      onFilter: onFilter('dosageForm'),
      width: 120,
    },
    {
      title: 'Formulations',
      dataIndex: 'usedByFormulations',
      render: usedByFormulations => (usedByFormulations || []).join(', '),
    },
  ];

  const renderActionIcons = record => (
    <div>
      {permissions.includes('edit_generic') ? (
        <>
          <Tooltip placement="bottom" title="Edit">
            <EditOutlined className={classes.tableIcon} onClick={() => showEditDropdownModal(record.id)} />
          </Tooltip>
          &nbsp;&nbsp;&nbsp;&nbsp;
        </>
      ) : null}
      {permissions.includes('delete_generic') ? (
        <>
          <Tooltip placement="bottom" title="Delete">
            <Popconfirm
              title="Are you sure you want to delete?"
              onConfirm={() => onDelete(record.id)}
              okText="Yes"
              cancelText="No"
            >
              <DeleteOutlined className={classes.tableIcon} />
            </Popconfirm>
          </Tooltip>
        </>
      ) : null}
    </div>
  );

  if (permissions.includes('edit_generic') || permissions.includes('delete_generic')) {
    columns.push({
      title: 'Actions',
      width: 70,
      render: renderActionIcons,
    });
  }

  return (
    <Structure>
      <>
        <Heading text="Generic List">
          {permissions.includes('new_generic') ? (
            <Button className={classes.button} onClick={showDropdownModal}>
              New Generic
            </Button>
          ) : null}
        </Heading>
        <Row gutter={[24, 0]}>
          <Col>
            <Button icon={<FileExcelOutlined />} onClick={onFileDownload}>
              Export
            </Button>
          </Col>
        </Row>
        <br />
        <Table
          columns={columns}
          bordered
          rowKey={record => record.id}
          pagination={{
            defaultPageSize: 20,
            position: ['bottomLeft'],
            showTotal: total => `Total ${total} Items`,
          }}
          dataSource={genericList}
          size="small"
          scroll={{ x: 1200, y: window.innerHeight - 150 }}
        />
        <Modal
          confirmLoading={confirmLoading}
          open={dropdownModal}
          onCancel={hideDropdownModal}
          maskClosable={false}
          title={editDropdownId ? 'Edit Generic.' : 'New Generic.'}
          closable={false}
          width="60%"
          cancelText="Back"
          okText={
            editDropdownId ? (
              <Popconfirm
                title="Sure want to submit form?"
                onConfirm={() => onEdit()}
                okText="Confirm"
                cancelText="Cancel"
              >
                Submit
              </Popconfirm>
            ) : (
              <Popconfirm
                title="Sure want to submit form?"
                onConfirm={() => onNew()}
                okText="Confirm"
                cancelText="Cancel"
              >
                Submit
              </Popconfirm>
            )
          }
          okButtonProps={{ className: classes.button }}
        >
          <Row gutter={[24, 0]}>
            <Col span={11}>
              <Controller
                name="name"
                control={control}
                render={({ field }) => (
                  <AntInput
                    label="Generic Name"
                    allowClear
                    required
                    placeholder="Generic Name"
                    {...field}
                    autoSize={{ minRows: 1, maxRows: 6 }}
                  />
                )}
              />
            </Col>
            <Col span={11} offset={2}>
              <Controller
                name="categoryId"
                control={control}
                render={({ field }) => (
                  <AntSelect label="Category" allowClear required placeholder="Select category" {...field}>
                    {categoryList.map(category => (
                      <Option key={category.id} value={category.id}>
                        {category.category}
                      </Option>
                    ))}
                  </AntSelect>
                )}
              />
            </Col>
            <Col span={11}>
              <Controller
                name="subcategoryId"
                control={control}
                render={({ field }) => (
                  <AntSelect label="Subcategory" allowClear required placeholder="Select subcategory" {...field}>
                    {subcategoryList.map(subcategory => (
                      <Option key={subcategory.id} value={subcategory.id}>
                        {subcategory.subcategory}
                      </Option>
                    ))}
                  </AntSelect>
                )}
              />
            </Col>
            <Col span={11} offset={2}>
              <Controller
                name="sectionId"
                control={control}
                render={({ field }) => (
                  <AntSelect label="Section" allowClear required placeholder="Select section" {...field}>
                    {sectionList.map(section => (
                      <Option key={section.id} value={section.id}>
                        {section.section}
                      </Option>
                    ))}
                  </AntSelect>
                )}
              />
            </Col>
            <Col span={11}>
              <Controller
                name="dosageFormId"
                control={control}
                render={({ field }) => (
                  <AntSelect label="Dosage Form" allowClear required placeholder="Select dosage form" {...field}>
                    {dosageFormList.map(dosage => (
                      <Option key={dosage.id} value={dosage.id}>
                        {dosage.dosageForm}
                      </Option>
                    ))}
                  </AntSelect>
                )}
              />
            </Col>
            <Col span={11} offset={2}>
              <Controller
                name="consignor"
                control={control}
                render={({ field }) => (
                  <AntSelect label="Consignor" allowClear required placeholder="Select consignor" {...field}>
                    {Object.values(constants.consignors.list).map(consignor => (
                      <Option key={consignor} value={consignor}>
                        {consignor}
                      </Option>
                    ))}
                  </AntSelect>
                )}
              />
            </Col>
          </Row>
        </Modal>
      </>
    </Structure>
  );
};

Generic.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(Generic);

import Api from '../../Api';

export const getCategoryList = () => async dispatch => {
  try {
    const { data } = await Api.get('/dropdowns/formulation-categories');
    dispatch({
      type: 'FETCH_CATEGORY_LIST',
      payload: data,
    });
  } catch (err) {
    throw err;
  }
};

export const clearCategoryList = () => {
  return {
    type: 'CLEAR_CATEGORY_LIST',
  };
};

export const getCategory = id => async dispatch => {
  try {
    const { data } = await Api.get(`/dropdowns/formulation-categories/${id}`);
    dispatch({
      type: 'FETCH_CATEGORY',
      payload: data,
    });
  } catch (err) {
    throw err;
  }
};

export const clearCategory = () => {
  return {
    type: 'CLEAR_CATEGORY',
  };
};

export const purchaseOrderList = (state = [], action) => {
  if (action.type === 'FETCH_PURCHASE_ORDER_LIST') {
    return action.payload;
  }
  if (action.type === 'CLEAR_PURCHASE_ORDER_LIST') {
    return [];
  }
  return state;
};

export const purchaseOrder = (state = {}, action) => {
  if (action.type === 'FETCH_PURCHASE_ORDER') {
    return action.payload;
  }
  if (action.type === 'CLEAR_PURCHASE_ORDER') {
    return {};
  }
  return state;
};

export const purchaseOrderIdList = (state = [], action) => {
  if (action.type === 'FETCH_PURCHASE_ORDER_ID_LIST') {
    return action.payload;
  }
  if (action.type === 'CLEAR_PURCHASE_ORDER_ID_LIST') {
    return [];
  }
  return state;
};

export const purchaseOrderFilesList = (state = [], action) => {
  if (action.type === 'FETCH_PURCHASE_ORDER_FILES_LIST') {
    return action.payload;
  }
  if (action.type === 'CLEAR_PURCHASE_ORDER_FILES_LIST') {
    return [];
  }
  return state;
};

export const supplierWisePOList = (state = [], action) => {
  if (action.type === 'FETCH_SUPPLIER_WISE_PO_LIST') {
    return action.payload;
  }
  if (action.type === 'CLEAR_PSUPPLIER_WISE_PO_LIST') {
    return [];
  }
  return state;
};

export const modules = [
  'new_user',
  'edit_user',
  'delete_user',
  'recover_user',
  'user_list',
  'new_role',
  'edit_role',
  'delete_role',
  'role_list',
  'new_supplier',
  'edit_supplier',
  'delete_supplier',
  'supplier_list',
  'supplier_ledger_view',
  'supplier_opening_balances_list',
  'edit_supplier_opening_balance',
  'new_customer',
  'edit_customer',
  'delete_customer',
  'customer_list',
  'customer_complaint_list',
  'customer_details_view',
  'email_customer_details_link',
  'customer_ledger_view',
  'customer_opening_balances_list',
  'edit_customer_opening_balance',
  'new_unit_of_measurement',
  'edit_unit_of_measurement',
  'delete_unit_of_measurement',
  'unit_of_measurement_list',
  'new_transporter',
  'edit_transporter',
  'delete_transporter',
  'transporter_list',
  'new_category',
  'edit_category',
  'delete_category',
  'category_list',
  'new_subcategory',
  'edit_subcategory',
  'delete_subcategory',
  'subcategory_list',
  'new_dosage_form',
  'edit_dosage_form',
  'delete_dosage_form',
  'dosage_form_list',
  'new_section',
  'edit_section',
  'delete_section',
  'section_list',
  'new_time_motion',
  'edit_time_motion',
  'delete_time_motion',
  'time_motion_list',
  'new_hsn_code',
  'edit_hsn_code',
  'delete_hsn_code',
  'hsn_code_list',
  'new_material',
  'edit_material',
  'delete_material',
  'material_list',
  'material_inventory',
  'material_transaction',
  'stock_adjustment',
  'adjusted_stock_list',
  'new_formulation',
  'edit_formulation',
  'view_formulation',
  'delete_formulation',
  'formulation_list',
  'formulation_costing',
  'new_order',
  'edit_order',
  'delete_order',
  'order_list',
  'authorized_order_list',
  'authorize_order',
  'order_customer_approval',
  'order_artwork_upload',
  'order_artwork_view',
  'order_files_upload',
  'order_files_view',
  'order_internal_approval',
  'view_purchase_orders_of_order',
  'view_job_card',
  'production_planning_approval',
  'shortage_item_list',
  'new_purchase_order',
  'edit_purchase_order',
  'delete_purchase_order',
  'purchase_order_list',
  'shortage_items_to_order',
  'force_complete_purchase_order',
  'new_purchase',
  'edit_purchase',
  'delete_purchase',
  'purchase_list',
  'purchase_file_upload',
  'purchase_file_view',
  'production_planning_list',
  'dispense_details',
  'start_manufacturing',
  'production_orders_list',
  'batch_list',
  'batch_details',
  'new_sale',
  'edit_sale',
  'sale_list',
  'delete_sale',
  'sale_file_upload',
  'sale_file_view',
  'new_material_sale',
  'edit_material_sale',
  'sale_material_list',
  'delete_material_sale',
  'material_sale_file_upload',
  'material_sale_file_view',
  'new_sale_return',
  'edit_sale_return',
  'sale_return_list',
  'delete_sale_return',
  'new_courier',
  'edit_courier',
  'courier_list',
  'delete_courier',
  'courier_file_upload',
  'courier_file_view',
  'update_courier_details',
  'confirm_courier_receipt',
  'new_narration',
  'edit_narration',
  'narration_list',
  'new_transaction',
  'edit_transaction',
  'delete_transaction',
  'transaction_list',
  'bank_list',
  'financial_summary',
  'balance_sheet',
  'finance_MIS',
  'finance_MIS_form',
  'dashboard',
  'user_order_report',
  'calendar',
  'send_bulk_emails',
  'send_bulk_messages',
  'general_files',
  'supplier_MIS',
  'order_MIS',
  'order_FMS',
  'team_MIS',
  'task_MIS',
  'asp_report',
  'lead_MIS',
  'lead_FMS',
  'new_enquiry',
  'edit_enquiry',
  'delete_enquiry',
  'enquiry_list',
  'create_proforma_invoice',
  'ticket',
  'new_credit_debit_note',
  'edit_credit_debit_note',
  'delete_credit_debit_note',
  'credit_debit_note_list',
  // 'new_credit_note',
  // 'edit_credit_note',
  // 'delete_credit_note',
  // 'credit_note_list',
  // 'new_debit_note',
  // 'edit_debit_note',
  // 'delete_debit_note',
  // 'debit_note_list',
  'new_employee_role',
  'edit_employee_role',
  'delete_employee_role',
  'employee_role_list',
  'new_employee',
  'edit_employee',
  'delete_employee',
  'employee_list',
  'employee_ledger',
  'old_attendance',
  'new_attendance',
  'edit_attendance',
  'delete_attendance',
  'attendance_list',
  'new_leave',
  'edit_leave',
  'delete_leave',
  'leave_list',
  'leave_management',
  'new_holiday',
  'holiday_list',
  'whatsapp',
  'social_cause',
  'gpPercentage',
  'formulation_inventory',
  'formulation_stock_adjustment',
  'formulation_adjusted_stock_list',
  'addCharge',
  'updateCharge',
  'deleteCharge',
  'chargeList',
  'addLead',
  'editLead',
  'deleteLead',
  'leadList',
  'addProformaInvoice',
  'editProformaInvoice',
  'deleteProformaInvoice',
  'proformaInvoiceList',
  'notes',
  'kra',
  'todo',
  'payment_category',
  'new_location',
  'edit_location',
  'delete_location',
  'location_list',
  'new_ledger_contact',
  'edit_ledger_contact',
  'delete_ledger_contact',
  'ledger_contact_list',
  'new_asset',
  'edit_asset',
  'delete_asset',
  'asset_list',
  'asset_adjustment',
  'profit_center_report',
  'gp_report',
  'generic_list',
  'new_generic',
  'edit_generic',
  'delete_generic',
  'gst_reversal',
  'batch_series',
  'raw_materials_dispense',
  'other_materials_dispense',
];

export const materialTypes = ['raw_material', 'packing_material', 'printed_packaging_material', 'consumable'];

export const customerCategory = ['Human', 'Ruminants', 'Pet', 'Poulltry'];

export const consignorList = ['Plus Formulations', '123'];

export const transporterTypeList = ['general_transport', 'pick_and_drop_logistics', 'full_load_cargo_service'];

export const transporterSpecialityAreaList = ['pan_india', 'north', 'south', 'east', 'west'];

export const batchSeriesArr = ['O', 'PF', 'L', 'P', 'R', 'Z', 'U', 'N', 'M'];

export default {
  consignors: {
    list: {
      plusFormulationsHeadOffice: 'Plus Formulations (Head Office)',
      plusFormulationsSarthak: 'Plus Formulations (Sarthak)',
      plusFormulationsParrish: 'Plus Formulations (Parrish)',
      123: '123',
    },
    details: {
      'Plus Formulations (Head Office)': {
        address: '# 391, Sector 3A, (Extension) Phase-III, HSIIDC, Karnal - 132001',
        state: 'Haryana',
        stateCode: '06',
        gstin: '06AANFP3495K1ZM',
        contact: '+919215611177',
        dlNo: '',
        licNo: '10018064001550',
        mfgLicNo: '637 ISM (HR)',
      },
      'Plus Formulations (Sarthak)': {
        address: '# 391, Sector 3A, (Extension) Phase-III, HSIIDC, Karnal - 132001',
        state: '',
        stateCode: '',
        gstin: '',
        contact: '+919215611177',
        dlNo: '',
        licNo: '',
        mfgLicNo: '',
      },
      'Plus Formulations (Parrish)': {
        address: '# 391, Sector 3A, (Extension) Phase-III, HSIIDC, Karnal - 132001',
        state: '',
        stateCode: '',
        gstin: '',
        contact: '+919215611177',
        dlNo: '',
        licNo: '',
        mfgLicNo: '',
      },
      123: {
        address: '# 391, Sector 3A, (Extension) Phase-III, HSIIDC, Karnal - 132001',
        state: '',
        stateCode: '',
        gstin: '',
        contact: '+919215611177',
        dlNo: '',
        licNo: '',
        mfgLicNo: '',
      },
    },
  },
  assets: {
    adjustments: {
      addValue: 'Add Value',
      subtractValue: 'Subtract Value',
    },
  },
  inquiry: {
    status: {
      pendingAtPartyEnd: 'Pending At Party End',
      pendingAtOurEnd: 'Pending At Our End',
      closed: 'Closed',
    },
  },
  tasks: {
    kras: {
      repeatCycles: {
        daily: 'daily',
        weekly: 'weekly',
        biWeekly: 'bi-weekly',
        monthly: 'monthly',
        customMonths: 'custom-months',
      },
    },
    types: {
      task: 'task',
      event: 'event',
      reminder: 'reminder',
    },
  },
  leads: {
    category: {
      nBDcRR: 'NBD CRR',
      nBDiNCOMING: 'NBD Incoming',
      nBDoUTCOMING: 'NBD Outgoing',
    },
    sources: {
      'NBD CRR': {
        self: 'Self',
        promptedForWalletShareOrCrossSell: 'Prompted for wallet share or cross sell',
      },
      'NBD Incoming': {
        website: 'Website',
        referenceFromOthers: 'Reference from Others',
        promptedForWalletShareOrCrossSell: 'Prompted for wallet share or cross sell',
      },
      'NBD Outgoing': {
        digitalPromotion: 'Digital Promotion',
        seo: 'SEO',
        telecalling: 'Tele Calling',
      },
    },
    statuses: {
      open: 'Open',
      meeting: 'Meeting Done',
      quotation: 'Quotation Sent',
      converted: 'Converted',
      closed: 'Closed',
    },
    interactions: {
      types: {
        call: 'Call',
        virtualMeeting: 'Virtual Meeting',
        inPersonMeeting: 'In-Person Meeting',
      },
      outcomes: {
        Call: {
          noAnswer: 'No Answer',
          busy: 'Busy',
          wrongNumber: 'Wrong Number',
          leftVoicemail: 'Left Voicemail',
          connected: 'Connected',
          callMeAgain: 'Call Me Again',
          missed: 'Missed',
        },
        'Virtual Meeting': {
          completed: 'Completed',
          rescheduled: 'Rescheduled',
          cancelled: 'Cancelled',
        },
        'In-Person Meeting': {
          attended: 'Attended',
          notAttended: 'Not Attended',
        },
      },
    },
  },
  bank: {
    summaryHead: {
      commissionBank: 'Commission (Bank)',
      commissionCash: 'Commission (Cash)',
      fixedCostBank: 'Fixed Cost (Bank)',
      fixedCostCash: 'Fixed Cost (Cash)',
      supplierAdvanceBank: 'Supplier Advance (Bank)',
      supplierAdvanceCash: 'Supplier Advance (Cash)',
      customerAdvanceBank: 'Customer Advance (Bank)',
      customerAdvanceCash: 'Customer Advance (Cash)',
      customerReceiptBank: 'Customer Receipt (Bank)',
      customerReceiptCash: 'Customer Receipt (Cash)',
      capitalInvestmentBank: 'Capital Investment (Bank)',
      capitalInvestmentCash: 'Capital Investment (Cash)',
      otherInflowsBank: 'Other Inflows (Bank)',
      otherInflowsCash: 'Other Inflows (Cash)',
      supplierPaymentBank: 'Supplier Payment (Bank)',
      supplierPaymentCash: 'Supplier Payment (Cash)',
      capitalExpenditureTDBannk: 'Capital Expenditure/TD (Bank)',
      capitalExpenditureTDCash: 'Capital Expenditure/TD (Cash)',
      otherOutflowsBank: 'Other Outflows (Bank)',
      otherOutflowsCash: 'Other Outflows (Cash)',
      fixedAssetsBank: 'Fixed Assets (Bank)',
      fixedAssetsCash: 'Fixed Assets (Cash)',
      termLoan: 'Term Loan',
      termLoanReturns: 'Term Loan Returns',
      partOfPurchaseBank: 'Part of Purchase (Bank)',
      partOfPurchaseCash: 'Part of Purchase (Cash)',
    },
    parentHead: {
      directExpenses: 'Direct Expenses',
      indirectExpenses: 'Indirect Expenses',
      otherIncome: 'Other Income',
      // manufacturingExpenses: 'Manufacturing Expenses',
      capitalAccount: 'Capital Account',
      loans: 'Loans',
      otherLoans: 'Other Loans',
      currentLiabilities: 'Current Liabilities',
      fixedAssets: 'Fixed Assets',
      currentAssets: 'Current Assets',
    },
    types: {
      expense: 'expense',
      income: 'income',
    },
    financeMIS: {
      supplierNotDue: 'Supplier (Not Due)',
      supplierOverDue: 'Supplier (Overdue)',
      supplierAdvance: 'Supplier Advance',
      supplierTail: 'Supplier Tail',
      customerNotDue: 'Customer (Not Due)',
      customerOverdue: 'Customer (Overdue)',
      customerAdvance: 'Customer Advance',
      customerTail: 'Customer Tail',
      customerBadDebt: 'Customer Bad Debt',
      stockTurnoverRatio: 'Stock Turnover Ratio',
      workingCapital: 'Working Capital',
      cashOpeningBalance: 'Cash Opening Balance',
      cashReceived: 'Cash Received',
      cashExpenses: 'Cash Expenses',
      capitalExpenditureTDCash: 'Capital Expenditure/TD (Cash)',
      cashClosingBalance: 'Cash Closing Balance',
      cashImprest1OpeningBalance: 'Cash - Imprest 1 Opening Balance',
      cashImprest1Received: 'Cash - Imprest 1 Received',
      cashImprest1Expenses: 'Cash - Imprest 1 Expenses',
      cashImprest1ClosingBalance: 'Cash - Imprest 1 Closing Balance',
      cashImprest2OpeningBalance: 'Cash - Imprest 2 Opening Balance',
      cashImprest2Received: 'Cash - Imprest 2 Received',
      cashImprest2Expenses: 'Cash - Imprest 2 Expenses',
      cashImprest2ClosingBalance: 'Cash - Imprest 2 Closing Balance',
      CCCanaraPlusFormulationsBalance: 'CC Canara Plus Formulations Balance',
      CCCanaraAllianceFormulationsBalance: 'CC Canara Alliance Formulations Balance',
      termLoadPendingBalance: 'Term Load Pending Balance',
      totalSale: 'Total Sale',
      totalPurchase: 'Total Purchase',
      grossProfit: 'Gross Profit',
      grossProfitPercentage: 'Gross Profit Percentage',
      totalFixedCost: 'Total Fixed Cost',
      netProfitMargin: 'Net Profit Margin',
      netProfitMarginPercentage: 'Net Profit Margin Percentage',
      NumberOfPurchaseBillsWhoseRatesReceivedHigherThanPurchaseOrderRates:
        'Number of Purchase bills whose rates received higher than Purchase Order Rates',
      NumberOfSaleBillsWhoseRatesBilledLesserThanSaleOrder:
        'Number of Sale bills whose rates billed lesser than Sale Order',
      capitalInvestmentCashOpeningBalance: 'Capital Investment Cash Opening Balance',
      capitalInvestmentCashTotalPaid: 'Capital Investment Cash Total Paid',
      capitalInvestmentCashTotalReceived: 'Capital Investment Cash Total Received',
      capitalInvestmentCashClosingBalance: 'Capital Investment Cash Closing Balance',
      capitalInvestmentBankOpeningBalance: 'Capital Investment Bank Opening Balance',
      capitalInvestmentBankTotalPaid: 'Capital Investment Bank Total Paid',
      capitalInvestmentBankTotalReceived: 'Capital Investment Bank Total Received',
      capitalInvestmentBankClosingBalance: 'Capital Investment Bank Closing Balance',
    },
  },
};

export const states = [
  'Andhra Pradesh',
  'Arunachal Pradesh',
  'Assam',
  'Bihar',
  'Chandigarh',
  'Chhattisgarh',
  'Delhi',
  'Goa',
  'Gujarat',
  'Haryana',
  'Himachal Pradesh',
  'Jammu and Kashmir',
  'Jharkhand',
  'Karnataka',
  'Kerala',
  'Ladakh',
  'Lakshadweep',
  'Madhya Pradesh',
  'Maharashtra',
  'Manipur',
  'Meghalaya',
  'Mizoram',
  'Nagaland',
  'Odisha',
  'Puducherry',
  'Punjab',
  'Rajasthan',
  'Sikkim',
  'Tamil Nadu',
  'Telangana',
  'Tripura',
  'Uttar Pradesh',
  'Uttarakhand',
  'West Bengal',
  'Andaman and Nicobar Islands',
  'Dadra and Nagar Haveli and Daman and Diu',
];

export const dailyWorkingMinutes = 510;

import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { Button, Col, Input, InputNumber, Modal, Row, Select } from 'antd';
import { CloseOutlined } from '@ant-design/icons';
import { Controller, useForm } from 'react-hook-form';
import { useDispatch, useSelector } from 'react-redux';
import Api from '../../Api';
import { errorNotification, successNotification } from '../../Snackbar';
import GetField from '../../Components/GetRHFField';
import { clearEmployeeList, getEmployeeList } from '../../Actions';

const AntInputNumber = GetField(InputNumber);
const AntInput = GetField(Input);

const AntSelect = GetField(Select);

const PackingModal = props => {
  const { recordId, hideModal, order } = props;
  const dispatch = useDispatch();

  const { handleSubmit, control, watch, setValue } = useForm({
    defaultValues: {
      quantity: order.quantity,
    },
  });

  const users = useSelector(state => state.employeeList);
  const [ConfirmLoading, SetConfirmLoading] = useState(false);

  useEffect(() => {
    dispatch(getEmployeeList());

    return () => {
      dispatch(clearEmployeeList());
    };
  }, []);

  useEffect(() => {
    const actualYield = watch('actualYield');
    if (actualYield) {
      const percentageYield = ((+actualYield / +order.quantity) * 100).toFixed(2);
      setValue('percentageYield', percentageYield);
    }
  }, [watch('actualYield')]);

  const onSubmit = async formValues => {
    try {
      SetConfirmLoading(true);

      await Api.put(`/orders/${recordId}/endpacking`, formValues);

      successNotification('Packing details submitted');
      hideModal();
    } catch (error) {
      errorNotification(error);
      SetConfirmLoading(false);
    }
  };

  return (
    <Modal
      open
      maskClosable={false}
      okText="Close"
      onOk={hideModal}
      cancelButtonProps={{ style: { display: 'none' } }}
      width={800}
      style={{ top: 10 }}
      title={`Packing Details | ${order.id}`}
      closeIcon={<CloseOutlined onClick={hideModal} />}
    >
      <form onSubmit={handleSubmit(onSubmit)}>
        <Row gutter={[16, 0]}>
          <Col span={12}>
            <Controller
              name="finishedDetails"
              control={control}
              render={({ field }) => <AntInput {...field} placeholder="Finished Details" label="Finished Details" />}
            />
          </Col>
          <Col span={12}>
            <Controller
              name="quantity"
              control={control}
              render={({ field }) => (
                <AntInputNumber
                  {...field}
                  min={0}
                  step={1}
                  disabled
                  placeholder="Quantity As Per Order"
                  label="Quantity As Per Order"
                />
              )}
            />
          </Col>
          <Col span={12}>
            <Controller
              name="actualYield"
              control={control}
              render={({ field }) => (
                <AntInputNumber {...field} min={0} placeholder="Actual Yield" label="Actual Yield" />
              )}
            />
          </Col>
          <Col span={12}>
            <Controller
              name="percentageYield"
              control={control}
              render={({ field }) => (
                <AntInputNumber
                  {...field}
                  min={0}
                  disabled
                  placeholder="Percentage Yield (in %)"
                  label="Percentage Yield (in %)"
                />
              )}
            />
          </Col>
          <Col span={12}>
            <Controller
              name="semiFinishedQuantity"
              control={control}
              render={({ field }) => (
                <AntInputNumber
                  {...field}
                  min={0}
                  placeholder="Remaining Semi Finished Quantity"
                  label="Remaining Semi Finished Quantity"
                />
              )}
            />
          </Col>
          <Col span={12}>
            <Controller
              name="usersInPacking"
              control={control}
              render={({ field }) => (
                <AntSelect
                  allowClear
                  required
                  mode="multiple"
                  label="Users in packing"
                  placeholder="Select user"
                  {...field}
                >
                  {users.map(el => (
                    <Select.Option key={el.id} value={el.id}>
                      {el.name}
                    </Select.Option>
                  ))}
                </AntSelect>
              )}
            />
          </Col>
          <Col span={12}>
            <Controller
              name="shipperPackedBy"
              control={control}
              render={({ field }) => (
                <AntSelect
                  allowClear
                  required
                  mode="multiple"
                  placeholder="Select user"
                  label="Shipper Packed By"
                  {...field}
                >
                  {users.map(el => (
                    <Select.Option key={el.id} value={el.id}>
                      {el.name}
                    </Select.Option>
                  ))}
                </AntSelect>
              )}
            />
          </Col>
          <Col span={12}>
            <Controller
              name="remarks"
              control={control}
              render={({ field }) => (
                <AntInput
                  allowClear
                  required
                  label="Remark"
                  placeholder="Remark"
                  {...field}
                  autoSize={{ minRows: 1, maxRows: 6 }}
                />
              )}
            />
          </Col>
        </Row>
        <Button htmlType="submit" type="primary" loading={ConfirmLoading}>
          Submit
        </Button>
      </form>
    </Modal>
  );
};

PackingModal.propTypes = {
  recordId: PropTypes.number.isRequired,
  order: PropTypes.object.isRequired,
  hideModal: PropTypes.func.isRequired,
};

export default PackingModal;

import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { reduxForm, Field, getFormValues } from 'redux-form';
import withStyles from 'react-jss';
import { Button, Row, Modal, Select, Col, Popconfirm, DatePicker } from 'antd';
import { useHistory } from 'react-router';
import { connect, useDispatch, useSelector } from 'react-redux';
import startCase from 'lodash/startCase';
import moment from 'moment';
import Heading from '../Components/Heading';
import Api from '../Api';
import Structure from '../Structure/Structure';
import styles from '../CommonStyles';
import { successNotification, errorNotification } from '../Snackbar';
import LeftGrid from '../Components/LeftGrid';
import RightGrid from '../Components/RightGrid';
import GetField from '../Components/GetField';
import { get } from '../utils';
import { getEmployeeIdList, clearEmployeeIdList, getAttendance, clearAttendance } from '../Actions';

const AntSelect = GetField(Select);
const AntDatePicker = GetField(DatePicker);

const EditAttendance = props => {
  const { handleSubmit, classes, match } = props;
  const history = useHistory();
  const dispatch = useDispatch();

  const employeeIdList = useSelector(state => state.employeeIdList);

  const [formValueModal, setFormValuesModal] = useState(false);
  const [confirmLoading, setConfirmLoading] = useState(false);
  const [preview, setPreview] = useState({});
  const FormValues = useSelector(state => getFormValues('EditAttendance')(state));

  useEffect(() => {
    dispatch(getEmployeeIdList());
    dispatch(getAttendance(match.params.id));
    return () => {
      dispatch(clearEmployeeIdList());
      dispatch(clearAttendance());
    };
  }, []);

  const showFormValuesModal = () => {
    const type = get(FormValues, ['type'], '--');
    const ts = get(FormValues, ['ts']);

    const _preview = {
      type: type ? startCase(type) : '--',
      employee: '--',
      ts: ts ? moment(ts).format('DD MMM YYYY hh:mm:ss A') : '--',
    };

    const employeeId = get(FormValues, ['employeeId'], null);
    if (employeeId) {
      const employeeObj = employeeIdList.find(employee => employee.id === +employeeId);
      if (employeeObj) {
        _preview.employee = employeeObj.name;
      }
    }

    setPreview(_preview);
    setFormValuesModal(true);
  };

  const hideFormValuesModal = () => {
    setFormValuesModal(false);
    setPreview({});
  };

  const onSubmit = async () => {
    try {
      setConfirmLoading(true);
      const { ts } = FormValues;
      await Api.put(`/attendances/${match.params.id}`, { ...FormValues, ts: new Date(ts).toISOString() });
      successNotification('Attendance updated');
      setConfirmLoading(false);
      hideFormValuesModal();
      history.push('/attendance/list');
    } catch (err) {
      setConfirmLoading(false);
      errorNotification(err);
    }
  };

  return (
    <Structure>
      <>
        <Heading text="Edit Attendance" />
        <form onSubmit={handleSubmit(onSubmit)}>
          <Row>
            <LeftGrid>
              <Field label="Type" allowClear required name="type" component={AntSelect}>
                <Select.Option key={null} value="" disabled>
                  Select type
                </Select.Option>
                <Select.Option key="in" value="in">
                  In
                </Select.Option>
                <Select.Option key="out" value="out">
                  Out
                </Select.Option>
              </Field>
            </LeftGrid>
            <RightGrid>
              <Field label="Employee" allowClear required name="employeeId" component={AntSelect}>
                <Select.Option key={null} value="" disabled>
                  Select employee
                </Select.Option>
                {employeeIdList.map(employee => (
                  <Select.Option key={employee.id} value={employee.id}>
                    {employee.name}
                  </Select.Option>
                ))}
              </Field>
            </RightGrid>
          </Row>
          <Row>
            <LeftGrid>
              <Field
                allowClear
                required
                showTime={{ format: 'hh:mm:ss A' }}
                use12Hours
                name="ts"
                label="Timestamp"
                placeholder="Timestamp"
                component={AntDatePicker}
              />
            </LeftGrid>
          </Row>
          <Row className={classes.buttonContainer}>
            <Button className={classes.button} type="primary" onClick={showFormValuesModal}>
              Preview
            </Button>
          </Row>
        </form>
        <Modal
          onCancel={hideFormValuesModal}
          open={formValueModal}
          confirmLoading={confirmLoading}
          centered
          width="800px"
          maskClosable={false}
          closable={false}
          cancelText="Back"
          okButtonProps={{ className: classes.button }}
          okText={
            <Popconfirm
              title="Sure want to submit form ?"
              onConfirm={() => onSubmit()}
              okText="Confirm"
              cancelText="Cancel"
            >
              Submit
            </Popconfirm>
          }
        >
          <Row>
            <Col xs={24} lg={12}>
              <p>
                Type:
                <span className={classes.detailColor}>{preview.type}</span>
              </p>
            </Col>
            <Col xs={24} lg={12}>
              <p>
                Employee:
                <span className={classes.detailColor}>{preview.employee}</span>
              </p>
            </Col>
          </Row>
          <Row>
            <Col xs={24} lg={12}>
              <p>
                Timestamp:
                <span className={classes.detailColor}>{preview.ts}</span>
              </p>
            </Col>
          </Row>
        </Modal>
      </>
    </Structure>
  );
};

EditAttendance.propTypes = {
  handleSubmit: PropTypes.func.isRequired,
  classes: PropTypes.object.isRequired,
  match: PropTypes.object.isRequired,
};

const StyledEditAttendance = withStyles(styles)(EditAttendance);

const FormedEditAttendance = reduxForm({
  form: 'EditAttendance',
  enableReinitialize: true,
})(StyledEditAttendance);

export default connect(({ attendance }) => ({ initialValues: attendance }), {})(FormedEditAttendance);

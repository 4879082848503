import React, { useCallback, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import withStyles from 'react-jss';
import { useHistory } from 'react-router';
import { Button, Row, Input, Select, Col } from 'antd';
import { useDispatch, useSelector } from 'react-redux';
import { Controller, useForm, useFieldArray } from 'react-hook-form';
import Structure from '../Structure/Structure';
import styles from '../CommonStyles';
import { successNotification, errorNotification } from '../Snackbar';
import Api from '../Api';
import LeftGrid from '../Components/LeftGrid';
import RightGrid from '../Components/RightGrid';
import CenterGrid from '../Components/CenterGrid';
import {
  getMaterialIdList,
  clearMaterialIdList,
  getFormulation,
  clearFormulation,
  getHSNCodeIdList,
  clearHSNCodeIdList,
  getUnitIdList,
  clearUnitIdList,
  getGenericList,
  clearGenericList,
  getTimeMotionList,
  clearTimeMotionList,
  getFormulationIdForImport,
  clearFormulationIdForImport,
} from '../Actions';
import Heading from '../Components/Heading';
import GetRHFField from '../Components/GetRHFField';
import MaterialTable from './MaterialTable';

const AntInput = GetRHFField(Input);
const AntInputTextArea = GetRHFField(Input.TextArea);
const AntSelect = GetRHFField(Select);
const { Option } = Select;

const EditFromulation = props => {
  const { classes, match } = props;

  const history = useHistory();
  const dispatch = useDispatch();

  const { handleSubmit, control, setValue, reset, watch, getValues } = useForm({
    shouldUnregister: false,
    defaultValues: {
      recipe: [{ materialId: null }],
    },
  });

  const { fields, append, remove } = useFieldArray({
    control,
    name: 'recipe',
  });

  const hsnCodeIdList = useSelector(state => state.hsnCodeIdList);
  const unitIdList = useSelector(state => state.unitIdList);
  const genericList = useSelector(state => state.genericList);
  const timeMotionList = useSelector(state => state.timeMotionList);
  const formulation = useSelector(state => state.formulation);
  const formulationIdList = useSelector(state => state.formulationIdsForImport);

  const [confirmLoading, setConfirmLoading] = useState(false);

  useEffect(() => {
    dispatch(getMaterialIdList());
    dispatch(getHSNCodeIdList());
    dispatch(getUnitIdList());
    dispatch(getGenericList());
    dispatch(getTimeMotionList());
    dispatch(getFormulation(match.params.id));
    dispatch(getFormulationIdForImport());
    return () => {
      dispatch(clearMaterialIdList());
      dispatch(clearHSNCodeIdList());
      dispatch(clearUnitIdList());
      dispatch(clearGenericList());
      dispatch(clearTimeMotionList());
      dispatch(clearFormulation());
      dispatch(clearFormulationIdForImport());
    };
  }, []);

  useEffect(() => {
    reset(formulation);
  }, [formulation]);

  useEffect(() => {
    const batchSize = getValues('batchSize');
    const packSize = getValues('packSize');

    if (batchSize && packSize) {
      const theoroticalYeild = (+batchSize / +packSize).toFixed(2);
      setValue('theoroticalYeild', theoroticalYeild);
    }
  }, [watch('batchSize'), watch('packSize')]);

  const onSubmit = async formValues => {
    try {
      setConfirmLoading(true);
      await Api.put(`/formulations/${match.params.id}`, formValues);
      successNotification('Formulation updated');
      setConfirmLoading(false);
      history.push('/formulations/list');
    } catch (err) {
      setConfirmLoading(false);
      errorNotification(err);
    }
  };

  const handleSelectChange = async value => {
    const { data } = await Api.get(`/formulations/${value}`);

    setValue('recipe', data.recipe);
  };

  return (
    <Structure>
      <>
        <Heading text="Edit Formulation" />
        <Row>
          <CenterGrid>
            <div>Select formulation to copy materials from</div>
            <Select
              allowClear
              placeholder="Select formulation"
              showSearch
              optionFilterProp="children"
              filterOption={(input, option) => option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}
              onChange={handleSelectChange}
            >
              {formulationIdList.map(el => (
                <Option key={el.id} value={el.id}>
                  {el.name}
                </Option>
              ))}
            </Select>
          </CenterGrid>
        </Row>
        <br />
        <form onSubmit={handleSubmit(onSubmit)}>
          <Row>
            <LeftGrid>
              <Controller
                name="name"
                control={control}
                render={({ field }) => (
                  <AntInput
                    label="Finished Good Name"
                    allowClear
                    required
                    placeholder="Finished Good Name"
                    {...field}
                  />
                )}
              />
            </LeftGrid>
            <RightGrid>
              <Controller
                name="genericId"
                control={control}
                render={({ field }) => (
                  <AntSelect label="Generic Name" allowClear required placeholder="Select generic name" {...field}>
                    {genericList.map(generic => (
                      <Option key={generic.id} value={generic.id}>
                        {generic.genericName}
                      </Option>
                    ))}
                  </AntSelect>
                )}
              />
            </RightGrid>
          </Row>
          <Row>
            <LeftGrid>
              <Controller
                name="genericId"
                control={control}
                render={({ field }) => (
                  <AntSelect label="Consignor" allowClear disabled required placeholder="Select generic" {...field}>
                    {genericList.map(generic => (
                      <Option key={generic.id} value={generic.id}>
                        {generic.consignor}
                      </Option>
                    ))}
                  </AntSelect>
                )}
              />
            </LeftGrid>
            <RightGrid>
              <Controller
                name="genericId"
                control={control}
                render={({ field }) => (
                  <AntSelect label="Category" allowClear disabled required placeholder="Select category" {...field}>
                    {genericList.map(generic => (
                      <Option key={generic.id} value={generic.id}>
                        {generic.category}
                      </Option>
                    ))}
                  </AntSelect>
                )}
              />
            </RightGrid>
          </Row>
          <Row>
            <LeftGrid>
              <Controller
                name="genericId"
                control={control}
                render={({ field }) => (
                  <AntSelect
                    label="Subcategory"
                    allowClear
                    disabled
                    required
                    placeholder="Select subcategory"
                    {...field}
                  >
                    {genericList.map(generic => (
                      <Option key={generic.id} value={generic.id}>
                        {generic.subcategory}
                      </Option>
                    ))}
                  </AntSelect>
                )}
              />
            </LeftGrid>
            <RightGrid>
              <Controller
                name="genericId"
                control={control}
                render={({ field }) => (
                  <AntSelect label="Section" allowClear disabled required placeholder="Select section" {...field}>
                    {genericList.map(generic => (
                      <Option key={generic.id} value={generic.id}>
                        {generic.section}
                      </Option>
                    ))}
                  </AntSelect>
                )}
              />
            </RightGrid>
          </Row>
          <Row>
            <LeftGrid>
              <Controller
                name="genericId"
                control={control}
                render={({ field }) => (
                  <AntSelect
                    label="Dosage Form"
                    allowClear
                    disabled
                    required
                    placeholder="Select dosage form"
                    {...field}
                  >
                    {genericList.map(generic => (
                      <Option key={generic.id} value={generic.id}>
                        {generic.dosageForm}
                      </Option>
                    ))}
                  </AntSelect>
                )}
              />
            </LeftGrid>
            <RightGrid>
              <Controller
                name="unitId"
                control={control}
                render={({ field }) => (
                  <AntSelect label="Unit" allowClear required placeholder="Select unit" {...field}>
                    {unitIdList.map(unit => (
                      <Option key={unit.id} value={unit.id}>
                        {unit.unit}
                      </Option>
                    ))}
                  </AntSelect>
                )}
              />
            </RightGrid>
          </Row>

          <Row>
            <LeftGrid>
              <Controller
                name="batchSize"
                control={control}
                render={({ field }) => (
                  <AntInput
                    label="Batch Size"
                    required
                    allowClear
                    type="number"
                    min={0}
                    placeholder="Batch Size"
                    {...field}
                  />
                )}
              />
            </LeftGrid>
            <RightGrid>
              <Controller
                name="packSize"
                control={control}
                render={({ field }) => (
                  <AntInput
                    label="Average/Fill Weight or Fill Volume"
                    required
                    allowClear
                    type="number"
                    min={0}
                    placeholder="Average/Fill Weight or Fill Volume"
                    {...field}
                  />
                )}
              />
            </RightGrid>
          </Row>
          <Row>
            <LeftGrid>
              <Controller
                name="theoroticalYeild"
                control={control}
                render={({ field }) => (
                  <AntInput
                    label="Theorotical Yeild"
                    required
                    allowClear
                    disabled
                    type="number"
                    min={0}
                    placeholder="Theorotical Yeild"
                    {...field}
                  />
                )}
              />
            </LeftGrid>
            <RightGrid>
              <Controller
                name="hsnId"
                control={control}
                render={({ field }) => (
                  <AntSelect label="HSN Code" allowClear required placeholder="Select HSN Code" {...field}>
                    {hsnCodeIdList.map(hsn => (
                      <Option key={hsn.id} value={hsn.id}>
                        {hsn.hsn}
                      </Option>
                    ))}
                  </AntSelect>
                )}
              />
            </RightGrid>
          </Row>
          <Row>
            <LeftGrid>
              <Controller
                name="timeMotionId"
                control={control}
                render={({ field }) => (
                  <AntSelect
                    label="Time Motion Category"
                    allowClear
                    required
                    placeholder="Select time motion category"
                    {...field}
                  >
                    {timeMotionList.map(motion => (
                      <Option key={motion.id} value={motion.id}>
                        {motion.category}
                      </Option>
                    ))}
                  </AntSelect>
                )}
              />
            </LeftGrid>
            <RightGrid>
              <Controller
                name="remarks"
                control={control}
                render={({ field }) => (
                  <AntInputTextArea
                    allowClear
                    label="Remark"
                    placeholder="Remark"
                    {...field}
                    autoSize={{ minRows: 1, maxRows: 6 }}
                  />
                )}
              />
            </RightGrid>
          </Row>
          <Row>
            <LeftGrid>
              <Controller
                name="converionCharge"
                control={control}
                render={({ field }) => (
                  <AntInput
                    label="Converion Charge"
                    required
                    allowClear
                    type="number"
                    min={0}
                    placeholder="Converion Charge"
                    {...field}
                  />
                )}
              />
            </LeftGrid>
            <RightGrid>
              <Controller
                name="packingCharge"
                control={control}
                render={({ field }) => (
                  <AntInput
                    label="Packing Charge"
                    required
                    allowClear
                    type="number"
                    min={0}
                    placeholder="Packing Charge"
                    {...field}
                  />
                )}
              />
            </RightGrid>
          </Row>

          <Row>
            <Col xs={{ span: 22, offset: 1 }}>
              <div style={{ overflow: 'auto' }}>
                <div>
                  <table className={classes.inputTable}>
                    <thead className={classes.inputThead}>
                      <tr key="header">
                        <th className={classes.inputTh}>Material</th>
                        <th className={classes.inputTh} style={{ width: '20%' }}>
                          Type
                        </th>
                        <th className={classes.inputTh} style={{ width: '8%' }}>
                          Unit
                        </th>
                        <th className={classes.inputTh} style={{ width: '10%' }}>
                          Quantity
                        </th>
                        <th className={classes.inputTh} style={{ width: '8%' }}>
                          Remove
                        </th>
                      </tr>
                    </thead>
                    <tbody>
                      {fields.map((item, index) => (
                        <MaterialTable
                          key={item.id}
                          item={item}
                          index={index}
                          remove={remove}
                          control={control}
                          getValues={getValues}
                          watch={watch}
                        />
                      ))}
                    </tbody>
                  </table>
                </div>
              </div>
              <br />
              <Button
                type="primary"
                htmlType="button"
                onClick={useCallback(() => append({ materialId: null, quantity: 0 }), [append])}
              >
                Add Material
              </Button>
            </Col>
          </Row>

          <Row className={classes.buttonContainer}>
            <Button type="primary" loading={confirmLoading} htmlType="submit">
              Submit
            </Button>
          </Row>
        </form>
      </>
    </Structure>
  );
};

EditFromulation.propTypes = {
  classes: PropTypes.object.isRequired,
  match: PropTypes.object.isRequired,
};

export default withStyles(styles)(EditFromulation);

import React, { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import withStyles from 'react-jss';
import PropTypes from 'prop-types';
import { Table, Popconfirm, Tooltip, Typography, Row, Select, Button, Col } from 'antd';
import { DeleteOutlined, EditOutlined } from '@ant-design/icons';
import startCase from 'lodash/startCase';
import moment from 'moment';
import { Field, getFormValues, reduxForm } from 'redux-form';
import fileDownload from 'js-file-download';
import { Link } from 'react-router-dom';
import Structure from '../Structure/Structure';
import { sorter, onFilter, filterDropdown, get, onDateFilter, dateFilterDropdown, dateSorter } from '../utils';
import Api from '../Api';
import { getTransactionList, clearTransactionList, getBankList, clearBankList } from '../Actions';
import { errorNotification, successNotification } from '../Snackbar';
import styles from '../CommonStyles';
import Heading from '../Components/Heading';
import constants from '../constants';
import GetField from '../Components/GetField';

const { Text } = Typography;
const AntSelect = GetField(Select);

const TransactionList = props => {
  const { classes, change } = props;
  const dispatch = useDispatch();

  const auth = useSelector(state => state.auth);
  const permissions = get(auth, ['permissions'], []);
  const transactionList = useSelector(state => state.transactionList);
  const bankList = useSelector(state => state.bankList);

  const [rowCounts, setRowCounts] = useState(0);
  const [financialYear, setFinancialYear] = useState('2024');

  const FormValues = useSelector(state => getFormValues('TransactionList')(state));

  useEffect(() => {
    // dispatch(getTransactionList());
    dispatch(getBankList());
    return () => {
      dispatch(clearTransactionList());
      dispatch(clearBankList());
    };
  }, []);

  useEffect(() => {
    dispatch(getTransactionList({ financialYear }));
  }, [financialYear]);

  useEffect(() => {
    if (FormValues && FormValues.bankId) {
      dispatch(getTransactionList({ bankId: FormValues.bankId }));
    }
  }, [FormValues && FormValues.bankId]);

  useEffect(() => {
    if (transactionList) {
      setRowCounts(transactionList.length);
    }
  }, [transactionList]);

  const resetTransactionList = () => {
    change('bankId', '');
    dispatch(getTransactionList());
  };

  const exportTransactionList = async () => {
    try {
      if (!FormValues || !FormValues.bankId) {
        throw new Error('Select Bank First');
      }
      const { data } = await Api.getFile(`/bank-statements/export/${FormValues.bankId}`);
      fileDownload(data, `transactions-${FormValues.bankId}.csv`);
    } catch (error) {
      errorNotification(error);
    }
  };

  const onDelete = async id => {
    try {
      await Api.delete(`/bank-statements/${id}`);
      successNotification('Transaction deleted');
      dispatch(getTransactionList());
    } catch (err) {
      errorNotification(err);
    }
  };

  const tooltipShow = arg => (
    <Tooltip placement="topLeft" title={Object.values(constants.bank.types).includes(`${arg}`) ? startCase(arg) : arg}>
      {Object.values(constants.bank.types).includes(`${arg}`) ? startCase(arg) : arg}
    </Tooltip>
  );

  const columns = [
    {
      title: 'Type',
      dataIndex: 'type',
      sorter: sorter('type'),
      filterDropdown,
      onFilter: onFilter('type'),
      width: 80,
      render: type => startCase(type),
    },
    {
      title: 'Bank',
      dataIndex: 'bank',
      sorter: sorter('bank'),
      filterDropdown,
      onFilter: onFilter('bank'),
      render: tooltipShow,
      width: 170,
    },
    {
      title: 'Narration',
      dataIndex: 'narration',
      sorter: sorter('narration'),
      filterDropdown,
      onFilter: onFilter('narration'),
      render: tooltipShow,
      width: 170,
    },
    {
      title: 'Head',
      dataIndex: 'parentHead',
      sorter: sorter('parentHead'),
      filterDropdown,
      onFilter: onFilter('parentHead'),
      render: tooltipShow,
      width: 170,
    },
    {
      title: 'Summary Head',
      dataIndex: 'head',
      sorter: sorter('head'),
      filterDropdown,
      onFilter: onFilter('head'),
      render: tooltipShow,
      width: 170,
    },
    {
      title: 'Date',
      dataIndex: 'transDate',
      sorter: dateSorter('transDate'),
      filterDropdown: dateFilterDropdown,
      onFilter: onDateFilter('transDate'),
      render: transDate => (transDate ? moment(transDate).format('DD MMM YYYY') : null),
      width: 120,
    },
    {
      title: 'Amount',
      dataIndex: 'amount',
      sorter: sorter('amount'),
      filterDropdown,
      onFilter: onFilter('amount'),
      render: amount => Number(amount).toLocaleString('en-IN'),
      width: 150,
      align: 'right',
    },
    {
      title: 'Closing Balance',
      dataIndex: 'closingBalance',
      sorter: sorter('closingBalance'),
      filterDropdown,
      onFilter: onFilter('closingBalance'),
      render: closingBalance => Number(closingBalance).toLocaleString('en-IN'),
      width: 150,
      align: 'right',
    },
    {
      title: 'Customer',
      dataIndex: 'customer',
      sorter: sorter('customer'),
      filterDropdown,
      onFilter: onFilter('customer'),
      render: tooltipShow,
      ellipsis: { showTitle: false },
      width: 150,
    },
    {
      title: 'Supplier',
      dataIndex: 'supplier',
      sorter: sorter('supplier'),
      filterDropdown,
      onFilter: onFilter('supplier'),
      render: tooltipShow,
      ellipsis: { showTitle: false },
      width: 150,
    },
    {
      title: 'Employee',
      dataIndex: 'employee',
      sorter: sorter('employee'),
      filterDropdown,
      onFilter: onFilter('employee'),
      render: tooltipShow,
      ellipsis: { showTitle: false },
      width: 150,
    },
    {
      title: 'Contact',
      dataIndex: 'contact',
      sorter: sorter('contact'),
      filterDropdown,
      onFilter: onFilter('contact'),
      render: tooltipShow,
      ellipsis: { showTitle: false },
      width: 150,
    },
    {
      title: 'Asset',
      dataIndex: 'asset',
      sorter: sorter('asset'),
      filterDropdown,
      onFilter: onFilter('asset'),
      render: tooltipShow,
      ellipsis: { showTitle: false },
      width: 100,
    },
    {
      title: 'Reference',
      dataIndex: 'refNo',
      sorter: sorter('refNo'),
      filterDropdown,
      onFilter: onFilter('refNo'),
      render: tooltipShow,
      ellipsis: { showTitle: false },
      width: 200,
    },
    {
      title: 'Remarks',
      dataIndex: 'remarks',
      sorter: sorter('remarks'),
      filterDropdown,
      onFilter: onFilter('remarks'),
      render: tooltipShow,
      ellipsis: { showTitle: false },
      width: 200,
    },
    {
      title: 'Done By',
      dataIndex: 'createdByUser',
      sorter: sorter('createdByUser'),
      filterDropdown,
      onFilter: onFilter('createdByUser'),
      width: 120,
    },
  ];

  const renderActionIcons = record => (
    <div>
      {permissions.includes('edit_transaction') ? (
        <>
          <Tooltip placement="bottom" title="Edit">
            <Link to={`/transaction/edit/${record.id}`}>
              <EditOutlined className={classes.tableIcon} />
            </Link>
          </Tooltip>
          &nbsp;&nbsp;&nbsp;&nbsp;
        </>
      ) : null}
      {permissions.includes('delete_transaction') ? (
        <>
          <Tooltip placement="bottom" title="Delete">
            <Popconfirm
              title="Are you sure you want to delete?"
              onConfirm={() => onDelete(record.id)}
              okText="Yes"
              cancelText="No"
            >
              <DeleteOutlined className={classes.tableIcon} />
            </Popconfirm>
          </Tooltip>
        </>
      ) : null}
    </div>
  );

  if (permissions.includes('edit_transaction') || permissions.includes('delete_transaction')) {
    columns.push({
      title: 'Actions',
      width: 70,
      render: renderActionIcons,
      // fixed: 'right',
    });
  }

  return (
    <Structure>
      <>
        <Heading text={`Transaction List (${rowCounts})`} />
        <Row gutter={[32, 8]}>
          <Col>
            <Select placeholder="Select year" onChange={value => setFinancialYear(value)} defaultValue={financialYear}>
              <Select.Option value="2019">19-20</Select.Option>
              <Select.Option value="2020">20-21</Select.Option>
              <Select.Option value="2021">21-22</Select.Option>
              <Select.Option value="2022">22-23</Select.Option>
              <Select.Option value="2023">23-24</Select.Option>
              <Select.Option value="2024">24-25</Select.Option>
              <Select.Option value="2025">25-26</Select.Option>
              <Select.Option value="2026">26-27</Select.Option>
              <Select.Option value="2027">27-28</Select.Option>
              <Select.Option value="2028">28-29</Select.Option>
              <Select.Option value="2029">29-30</Select.Option>
              <Select.Option value="2030">30-31</Select.Option>
            </Select>
          </Col>
          <Col>
            <div className={classes.selectListWidth}>
              <Field allowClear name="bankId" component={AntSelect}>
                <Select.Option key={null} value="" disabled>
                  Select bank
                </Select.Option>
                {bankList.map(bank => (
                  <Select.Option key={bank.id} value={bank.id}>
                    {bank.consignor} ({bank.bank})
                  </Select.Option>
                ))}
              </Field>
            </div>
            &nbsp;
          </Col>
          <Col>
            <Button type="default" onClick={resetTransactionList}>
              Reset
            </Button>
            &nbsp;
          </Col>
          <Col>
            <Button type="default" onClick={exportTransactionList}>
              Export
            </Button>
          </Col>
        </Row>
        <Table
          columns={columns}
          bordered
          rowKey={record => record.id}
          pagination={{ defaultPageSize: 20, position: ['bottomLeft'] }}
          dataSource={transactionList}
          size="small"
          scroll={{ x: 1050, y: window.innerHeight - 300 }}
          onChange={(_page, _filters, _sorter, { currentDataSource }) => {
            setRowCounts((currentDataSource || []).length);
          }}
          summary={pageData => {
            let total = 0;
            pageData.forEach(({ amount, type }) => {
              if (type === 'expense') {
                total -= +amount;
              }
              if (type === 'income') {
                total += +amount;
              }
            });
            return (
              <>
                <Table.Summary.Row>
                  <Table.Summary.Cell colSpan={5}>Total Amount (Income - Expense)</Table.Summary.Cell>
                  <Table.Summary.Cell className={classes.textAlignRight}>
                    <Text>{Number(total.toFixed(2)).toLocaleString('en-IN')}</Text>
                  </Table.Summary.Cell>
                  <Table.Summary.Cell colSpan={7} />
                </Table.Summary.Row>
              </>
            );
          }}
        />
      </>
    </Structure>
  );
};

TransactionList.propTypes = {
  classes: PropTypes.object.isRequired,
  change: PropTypes.func.isRequired,
};

const StyledTransactionList = withStyles(styles)(TransactionList);

const FormedTransactionList = reduxForm({
  form: 'TransactionList',
  enableReinitialize: true,
})(StyledTransactionList);

export default FormedTransactionList;

import React, { useCallback, useEffect, useMemo, useState } from 'react';
import PropTypes from 'prop-types';
import withStyles from 'react-jss';
import { useDispatch, useSelector } from 'react-redux';
import { Button, Input, Popconfirm, Select, Table, Tooltip } from 'antd';
import { DeleteOutlined, EditOutlined, PlusOutlined } from '@ant-design/icons';
import Modal from 'antd/lib/modal/Modal';
import { Controller, useForm } from 'react-hook-form';
import styles from '../CommonStyles';
import { errorNotification, successNotification } from '../Snackbar';
import GetField from '../Components/GetRHFField';

import { clearCharge, clearCharges, getCharge, getCharges, getHSNCodeIdList, clearHSNCodeIdList } from '../Actions';
import Api from '../Api';
import { sorter, onFilter, filterDropdown } from '../utils';
import Structure from '../Structure/Structure';
import Heading from '../Components/Heading';

const AntInput = GetField(Input);
const AntSelect = GetField(Select);
const { Option } = Select;

const OtherCharge = props => {
  const { classes } = props;
  const dispatch = useDispatch();

  const { control, getValues, reset } = useForm();

  const auth = useSelector(state => state.auth);
  const permissions = useMemo(() => auth?.permissions || [], [auth]);
  const charges = useSelector(state => state.charges);
  const hsnIds = useSelector(state => state.hsnCodeIdList);
  const charge = useSelector(state => state.charge);

  const [viewModal, setViewModal] = useState(false);
  const [recordId, setRecordId] = useState(null);
  const [confirmLoading, setConfirmLoading] = useState(false);

  useEffect(() => {
    dispatch(getCharges());
    dispatch(getHSNCodeIdList());
    return () => {
      dispatch(clearCharges());
      dispatch(clearHSNCodeIdList());
    };
  }, []);

  useEffect(() => {
    reset(charge);
  }, [charge]);

  const onDelete = useCallback(async id => {
    try {
      await Api.delete(`/dropdowns/charges/${id}`);
      successNotification('charge deleted');
      dispatch(getCharges());
    } catch (err) {
      errorNotification(err);
    }
  }, []);

  const onSubmit = useCallback(async () => {
    try {
      const formValues = getValues();
      setConfirmLoading(true);
      await Api.post('/dropdowns/charges', formValues);
      setConfirmLoading(false);
      reset();
      setViewModal(false);
      successNotification('charge created');
      dispatch(clearCharge());
      dispatch(getCharges());
    } catch (err) {
      setConfirmLoading(false);
      errorNotification(err);
    }
  }, []);

  const onEditSubmit = useCallback(async () => {
    try {
      const formValues = getValues();
      setConfirmLoading(true);
      await Api.put(`/dropdowns/charges/${recordId}`, formValues);
      setConfirmLoading(false);
      reset();
      setViewModal(false);
      successNotification('charge updated');
      dispatch(clearCharge());
      dispatch(getCharges());
    } catch (err) {
      setConfirmLoading(false);
      errorNotification(err);
    }
  }, [recordId]);

  const showModal = useCallback(() => {
    setRecordId(null);
    setViewModal(true);
  }, []);

  const showEditModal = useCallback(id => {
    setRecordId(id);
    dispatch(getCharge(id));
    setViewModal(true);
  }, []);

  const hideModal = useCallback(() => {
    setRecordId(null);
    dispatch(clearCharge());
    setViewModal(false);
  }, []);

  const updateColumn = useCallback(
    (_text, record) =>
      permissions.includes('updateCharge') ? (
        <Tooltip placement="bottom" title="Edit">
          <EditOutlined className={classes.tableIcon} onClick={() => showEditModal(record.id)} />
        </Tooltip>
      ) : null,
    []
  );

  const deleteColumn = useCallback(
    (_text, record) =>
      permissions.includes('deleteCharge') ? (
        <Tooltip placement="bottom" title="Delete">
          <Popconfirm
            title="Are you sure you want to delete?"
            onConfirm={() => onDelete(record.id)}
            okText="Yes"
            cancelText="No"
          >
            <DeleteOutlined className={classes.tableIcon} />
          </Popconfirm>
        </Tooltip>
      ) : null,
    []
  );

  const columns = useMemo(() => {
    const _columns = [
      {
        title: 'Charge',
        dataIndex: 'name',
        sorter: sorter('name'),
        onFilter: onFilter('name'),
        filterDropdown,
      },
      {
        title: 'HSN Code',
        dataIndex: 'hsn',
        sorter: sorter('hsn'),
        onFilter: onFilter('hsn'),
        filterDropdown,
      },
      {
        title: 'GST(%)',
        dataIndex: 'gst',
        sorter: sorter('gst'),
        onFilter: onFilter('gst'),
        filterDropdown,
      },
    ];

    if (permissions.includes('updateCharge')) {
      _columns.push({
        title: 'Update',
        width: '7%',
        render: updateColumn,
      });
    }

    if (permissions.includes('deleteCharge')) {
      _columns.push({
        title: 'Delete',
        width: '6%',
        render: deleteColumn,
      });
    }

    return _columns;
  }, []);

  const rowKey = useCallback(record => record.id, []);

  const pagination = useCallback(
    {
      defaultPageSize: 20,
      position: ['bottomLeft'],
      showTotal: total => `Total ${total} Records`,
    },
    []
  );

  const scroll = useCallback({ x: 1024, y: window.innerHeight - 300 }, []);

  return (
    <Structure>
      <>
        <Heading text="Charges">
          {permissions.includes('addCharge') ? (
            <Button type="primary" className={classes.createButton} onClick={showModal}>
              <PlusOutlined /> Charge
            </Button>
          ) : null}
        </Heading>
        <Table
          bordered
          size="small"
          columns={columns}
          dataSource={charges}
          rowKey={rowKey}
          pagination={pagination}
          scroll={scroll}
        />
        <Modal
          title="Charge form."
          open={viewModal}
          onCancel={hideModal}
          confirmLoading={confirmLoading}
          maskClosable={false}
          onOk={recordId ? onEditSubmit : onSubmit}
          okText="Submit"
        >
          <Controller
            name="name"
            control={control}
            render={({ field }) => <AntInput allowClear label="Charge" placeholder="Charge" {...field} />}
          />
          <Controller
            name="hsnId"
            control={control}
            render={({ field }) => (
              <AntSelect allowClear label="HSN/SAC" placeholder="Select hsn" {...field}>
                {hsnIds.map(hsn => (
                  <Option key={hsn.id} value={hsn.id}>
                    {hsn.hsn}
                  </Option>
                ))}
              </AntSelect>
            )}
          />
        </Modal>
      </>
    </Structure>
  );
};

OtherCharge.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(OtherCharge);

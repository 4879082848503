import Api from '../Api';

export const getFilesList = () => async dispatch => {
  try {
    const { data } = await Api.get(`/files`);
    dispatch({
      type: 'FETCH_FILES_LIST',
      payload: data,
    });
  } catch (err) {
    throw err;
  }
};

export const clearFilesList = () => {
  return {
    type: 'CLEAR_FILES_LIST',
  };
};

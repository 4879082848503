import Api from '../Api';

export const getCharges = () => async dispatch => {
  const { data } = await Api.get('/dropdowns/charges');
  dispatch({
    type: 'FETCH_CHARGES',
    payload: data,
  });
};

export const clearCharges = () => {
  return {
    type: 'CLEAR_CHARGES',
  };
};

export const getCharge = id => async dispatch => {
  const { data } = await Api.get(`/dropdowns/charges/${id}`);
  dispatch({
    type: 'FETCH_CHARGE',
    payload: data,
  });
};

export const clearCharge = () => {
  return {
    type: 'CLEAR_CHARGE',
  };
};

export const getChargeIds = () => async dispatch => {
  const { data } = await Api.get('/dropdowns/charges/ids');
  dispatch({
    type: 'FETCH_CHARGE_IDS',
    payload: data,
  });
};

export const clearChargeIds = () => {
  return {
    type: 'CLEAR_CHARGE_IDS',
  };
};

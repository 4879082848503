export const proformaInvoices = (state = [], action = {}) => {
  if (action.type === 'FETCH_PROFORMA_INVOICES') {
    return action.payload;
  }
  if (action.type === 'CLEAR_PROFORMA_INVOICES') {
    return [];
  }
  return state;
};

export const proformaInvoice = (state = {}, action = {}) => {
  if (action.type === 'FETCH_PROFORMA_INVOICE') {
    return action.payload;
  }
  if (action.type === 'CLEAR_PROFORMA_INVOICE') {
    return {};
  }
  return state;
};

export const piFilesList = (state = [], action) => {
  if (action.type === 'FETCH_PI_FILES_LIST') {
    return action.payload;
  }
  if (action.type === 'CLEAR_PI_FILES_LIST') {
    return [];
  }
  return state;
};

import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';
import { Table, Tooltip } from 'antd';
import startCase from 'lodash/startCase';
import moment from 'moment';

import Heading from '../Components/Heading';
import { filterDropdown, get, onFilter, sorter, onFilterInsideObject, sorterInsideObject, dateSorter } from '../utils';
import { getSupplierWisePOList, clearSupplierWisePOList } from '../Actions';
import { materialTypes } from '../constants';

const DetailsView = props => {
  const { match } = props;
  const dispatch = useDispatch();

  const supplierWisePOList = useSelector(state => state.supplierWisePOList);

  useEffect(() => {
    dispatch(getSupplierWisePOList(match.params.id));
    return () => {
      dispatch(clearSupplierWisePOList());
    };
  }, []);

  const tooltipShowInsideObjectWithColors = (keys, arg, record) => {
    let color;
    if (record.status) {
      if (record.status === 'Received') {
        color = 'Green';
      } else if (record.status === 'Pending') {
        color = 'red';
      } else {
        color = '#096dd9';
      }
    }
    const value = get(arg, keys, null);

    return (
      <Tooltip placement="topLeft" title={materialTypes.includes(`${value}`) ? startCase(value) : value}>
        <span style={{ color }}>{materialTypes.includes(`${value}`) ? startCase(value) : value}</span>
      </Tooltip>
    );
  };

  const tooltipShowWithColorsInINRFormat = (arg, record) => {
    let color;
    if (record.status) {
      if (record.status === 'Received') {
        color = 'Green';
      } else if (record.status === 'Pending') {
        color = 'red';
      } else {
        color = '#096dd9';
      }
    }

    return (
      <Tooltip placement="topLeft" title={arg}>
        <span style={{ color }}>{Number(arg).toLocaleString('en-IN')}</span>
      </Tooltip>
    );
  };

  const tooltipShowWithColors = (arg, record) => {
    let color;
    if (record.status) {
      if (record.status === 'Received') {
        color = 'Green';
      } else if (record.status === 'Pending') {
        color = 'red';
      } else {
        color = '#096dd9';
      }
    }

    return (
      <Tooltip placement="topLeft" title={materialTypes.includes(`${arg}`) ? startCase(arg) : arg}>
        <span style={{ color }}>{materialTypes.includes(`${arg}`) ? startCase(arg) : arg}</span>
      </Tooltip>
    );
  };

  const dateTooltipShowWithColors = (arg, record) => {
    let color;
    if (record.status) {
      if (record.status === 'Received') {
        color = 'Green';
      } else if (record.status === 'Pending') {
        color = 'red';
      } else {
        color = '#096dd9';
      }
    }

    return (
      <Tooltip placement="topLeft" title={moment(arg).format('DD MMM YYYY')}>
        <span style={{ color }}>{moment(arg).format('DD MMM YYYY')}</span>
      </Tooltip>
    );
  };

  const columns = [
    {
      title: 'Created On',
      dataIndex: 'createdAt',
      sorter: dateSorter('createdAt'),
      render: dateTooltipShowWithColors,
      width: 110,
    },
    {
      title: 'PO No.',
      dataIndex: 'id',
      sorter: sorter('id'),
      filterDropdown,
      onFilter: onFilter('id'),
      width: 70,
      fixed: 'left',
      render: tooltipShowWithColors,
    },
    {
      title: 'Material',
      dataIndex: 'material',
      sorter: sorterInsideObject('material', 'name'),
      filterDropdown,
      onFilter: onFilterInsideObject('material', 'name'),
      render: tooltipShowInsideObjectWithColors.bind(null, ['name']),
      width: 250,
      fixed: 'left',
    },
    {
      title: 'Quantity',
      dataIndex: 'quantity',
      sorter: sorter('quantity'),
      filterDropdown,
      onFilter: onFilter('quantity'),
      render: tooltipShowWithColors,
      width: 130,
      align: 'right',
    },
    {
      title: 'Rate',
      dataIndex: 'rate',
      sorter: sorter('rate'),
      filterDropdown,
      onFilter: onFilter('rate'),
      render: tooltipShowWithColorsInINRFormat,
      width: 130,
      align: 'right',
    },
    {
      title: 'Lead Time',
      dataIndex: 'leadTime',
      sorter: sorter('leadTime'),
      filterDropdown,
      onFilter: onFilter('leadTime'),
      render: tooltipShowWithColors,
      width: 80,
      align: 'right',
    },
    {
      title: 'Expected On',
      dataIndex: 'expectedBy',
      sorter: dateSorter('expectedBy'),
      render: dateTooltipShowWithColors,
      width: 110,
    },
    {
      title: 'Remarks',
      dataIndex: 'remarks',
      sorter: sorter('remarks'),
      filterDropdown,
      onFilter: onFilter('remarks'),
      ellipsis: { showTitle: false },
      render: tooltipShowWithColors,
      width: 200,
    },
    {
      title: 'Consignor',
      dataIndex: 'consignor',
      sorter: sorter('consignor'),
      filterDropdown,
      onFilter: onFilter('consignor'),
      render: tooltipShowWithColors,
      width: 120,
    },
    {
      title: 'Status',
      dataIndex: 'status',
      sorter: sorter('status'),
      filterDropdown,
      onFilter: onFilter('status'),
      render: tooltipShowWithColors,
      width: 200,
    },
  ];

  return (
    <>
      <Heading text="Purchase Order List" />
      <Table
        columns={columns}
        bordered
        rowKey={record => record.id}
        dataSource={supplierWisePOList}
        size="small"
        scroll={{ x: 1050, y: window.innerHeight - 300 }}
        pagination={{ defaultPageSize: 20, position: ['bottomLeft'] }}
      />
    </>
  );
};

DetailsView.propTypes = {
  match: PropTypes.object.isRequired,
};

export default DetailsView;

/* eslint-disable no-nested-ternary */
import React, { useCallback, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';
import { Col, DatePicker, Input, Modal, Row, Select, Popconfirm } from 'antd';
import { useForm, Controller } from 'react-hook-form';
import moment from 'moment';
import startCase from 'lodash/startCase';

import Api from '../../Api';
import { errorNotification, successNotification } from '../../Snackbar';
import GetRHFField from '../../Components/GetRHFField';
import constants from '../../constants';
import {
  clearFilteredUserList,
  clearTask,
  clearUserList,
  getFilteredUserList,
  getTask,
  getUserList,
} from '../../Actions';

const AntInput = GetRHFField(Input);
const AntDatePicker = GetRHFField(DatePicker);

const AntSelect = GetRHFField(Select);
const { Option } = Select;

const TaskModal = props => {
  const { recordId, setModaltype, todoData = {} } = props;

  const dispatch = useDispatch();

  const { control, reset, watch, getValues, setValue } = useForm({
    shouldUnregister: false,
  });

  const auth = useSelector(state => state.auth);
  const filteredUserList = useSelector(state => state.filteredUserList);
  const userList = useSelector(state => state.userList);
  const taskData = useSelector(state => state.taskData);

  const [confirmLoading, setConfirmLoading] = useState(false);

  useEffect(() => {
    dispatch(getUserList());
    if (recordId) {
      dispatch(getTask(recordId));
    }

    if (auth.user.id === 7) {
      dispatch(getFilteredUserList({ role: 'Owner' }));
    }

    return () => {
      dispatch(clearUserList());
      dispatch(clearTask());
      dispatch(clearFilteredUserList());
    };
  }, []);

  const disabledDate = useCallback(current => {
    return current && current < moment().subtract(1, 'day').endOf('day');
  }, []);

  useEffect(() => {
    reset(taskData);
  }, [taskData]);

  const onSubmit = useCallback(async () => {
    try {
      const formValues = getValues();

      setConfirmLoading(true);
      await Api.post('/tasks', {
        ...formValues,
        weeklyScheduleId: todoData.id,
        assigneeIds: formValues.assigneeIds || [auth.user.id],
        endAt:
          formValues.durationType === 'days'
            ? moment(formValues.startAt).add(formValues.duration, 'days')
            : formValues.durationType === 'hours'
            ? moment(formValues.startAt).add(formValues.duration, 'hours')
            : moment(formValues.startAt).add(formValues.duration, 'minutes'),

        subTasks:
          formValues.subTasks &&
          formValues.subTasks.map(task => ({
            ...task,
            assigneeId: task.assigneeId || auth.user.id,
            endAt:
              task.durationType === 'days'
                ? moment(task.startAt).add(task.duration, 'days')
                : task.durationType === 'hours'
                ? moment(task.startAt).add(task.duration, 'hours')
                : moment(task.startAt).add(task.duration, 'minutes'),
          })),
      });

      reset();
      setConfirmLoading(false);
      successNotification('Added successfully');
      setModaltype(null);
      dispatch(clearTask());
      if (props.onSubmit) {
        props.onSubmit();
      }
    } catch (err) {
      setConfirmLoading(false);
      errorNotification(err);
    }
  }, []);

  const onEdit = useCallback(async () => {
    try {
      const formValues = getValues();

      setConfirmLoading(true);
      await Api.put(`/tasks/${recordId}`, {
        ...formValues,
        assigneeIds: formValues.assigneeIds || [auth.user.id],
        endAt:
          formValues.durationType === 'days'
            ? moment(formValues.startAt).add(formValues.duration, 'days')
            : formValues.durationType === 'hours'
            ? moment(formValues.startAt).add(formValues.duration, 'hours')
            : moment(formValues.startAt).add(formValues.duration, 'minutes'),
        subTasks:
          formValues.subTasks &&
          formValues.subTasks.map(task => ({
            ...task,
            assigneeId: task.assigneeId || auth.user.id,
            endAt:
              task.durationType === 'days'
                ? moment(task.startAt).add(task.duration, 'days')
                : task.durationType === 'hours'
                ? moment(task.startAt).add(task.duration, 'hours')
                : moment(task.startAt).add(task.duration, 'minutes'),
          })),
      });
      reset();
      setConfirmLoading(false);
      successNotification('Updated successfully');
      setModaltype(null);
      dispatch(clearTask());
      if (props.onSubmit) {
        props.onSubmit();
      }
    } catch (err) {
      setConfirmLoading(false);
      errorNotification(err);
    }
  }, [recordId]);

  return (
    <Modal
      title="Task Form"
      confirmLoading={confirmLoading}
      open
      onCancel={() => setModaltype(null)}
      maskClosable={false}
      cancelText="Back"
      okText={
        <Popconfirm
          title="Sure want to submit form?"
          onConfirm={() => (recordId ? onEdit() : onSubmit())}
          okText="Confirm"
          cancelText="Cancel"
        >
          Submit
        </Popconfirm>
      }
      style={{ top: '20px' }}
    >
      <form>
        <Row>
          <Col span={24}>
            <Controller
              name="type"
              control={control}
              render={({ field }) => (
                <AntSelect
                  allowClear
                  required
                  placeholder="Select type"
                  {...field}
                  onChange={value => {
                    field.onChange(value);
                    setValue('durationType', null);
                    setValue('duration', null);
                    setValue('subTasks', []);
                  }}
                >
                  {Object.entries(constants.tasks.types).map(([key, value]) => (
                    <Option key={key} value={value}>
                      {startCase(value)}
                    </Option>
                  ))}
                </AntSelect>
              )}
            />
          </Col>
        </Row>
        <Row>
          <Col span={24}>
            <Controller
              name="name"
              control={control}
              defaultValue={todoData.name}
              render={({ field }) => <AntInput allowClear required placeholder="Name" {...field} />}
            />
          </Col>
        </Row>
        {/* <Row>
          <Col span={24}>
            <Controller
              name="description"
              control={control}
              render={({ field }) => <AntInput allowClear required placeholder="Description" {...field} />}
            />
          </Col>
        </Row> */}
        <Row>
          <Col span={24}>
            <Controller
              name="remark"
              control={control}
              defaultValue={todoData.remark}
              render={({ field }) => (
                <AntInput allowClear required placeholder="Remark" {...field} autoSize={{ minRows: 1, maxRows: 6 }} />
              )}
            />
          </Col>
        </Row>
        <Row>
          <Col span={24}>
            <Controller
              name="assigneeIds"
              control={control}
              render={({ field }) => (
                <AntSelect allowClear mode="multiple" placeholder="Select user to assigne" {...field}>
                  {userList.map(user => (
                    <Option key={user.id} value={user.id}>
                      {user.name}
                    </Option>
                  ))}
                </AntSelect>
              )}
            />
          </Col>
        </Row>
        <Row>
          <Col span={24}>
            <Controller
              name="startAt"
              control={control}
              render={({ field }) => (
                <AntDatePicker
                  allowClear
                  showTime={{ format: 'hh:mm A' }}
                  format="YYYY-MM-DD hh:mm A"
                  disabledDate={disabledDate}
                  placeholder="Start date"
                  {...field}
                />
              )}
            />
          </Col>
        </Row>
        {auth.user.id === 7 ? (
          <>
            <Row>
              <Col span={24}>
                <Controller
                  name="createdBy"
                  control={control}
                  render={({ field }) => (
                    <AntSelect allowClear required placeholder="On Behalf Of" {...field}>
                      {filteredUserList.map(user => (
                        <Option key={user.id} value={user.id}>
                          {user.name}
                        </Option>
                      ))}
                    </AntSelect>
                  )}
                />
              </Col>
            </Row>
          </>
        ) : null}
        {[constants.tasks.types.reminder].includes(watch('type')) ? (
          <>
            <Row>
              <Col span={24}>
                <Controller
                  name="whatsappTo"
                  control={control}
                  render={({ field }) => <AntInput allowClear required placeholder="Whatsapp this to" {...field} />}
                />
              </Col>
            </Row>
          </>
        ) : null}
        {[constants.tasks.types.task, constants.tasks.types.event].includes(watch('type')) ? (
          <>
            <Row>
              <Col span={12}>
                <Controller
                  name="durationType"
                  control={control}
                  render={({ field }) => (
                    <AntSelect allowClear required placeholder="Select type" {...field}>
                      <Option key="minutes" value="minutes">
                        Minutes
                      </Option>
                      <Option key="hours" value="hours">
                        Hours
                      </Option>
                      <Option key="days" value="days">
                        Days
                      </Option>
                    </AntSelect>
                  )}
                />
              </Col>
              <Col span={12}>
                <Controller
                  name="duration"
                  control={control}
                  render={({ field }) => (
                    <AntInput allowClear required type="number" min={1} placeholder="Task duration" {...field} />
                  )}
                />
              </Col>
            </Row>
            {/* <Row>
              <div style={{ overflow: 'auto' }}>
                <div style={{ minWidth: '1024px' }}>
                  <table className={classes.newInputTable}>
                    <thead className={classes.newInputThead}>
                      <tr key="header">
                        <th className={classes.newInputTh} style={{ width: '18%' }}>
                          Name
                        </th>
                        <th className={classes.newInputTh} style={{ width: '20%' }}>
                          Description
                        </th>
                        <th className={classes.newInputTh} style={{ width: '15%' }}>
                          Assigne
                        </th>
                        <th className={classes.newInputTh} style={{ width: '15%' }}>
                          Start Date
                        </th>
                        <th className={classes.newInputTh} style={{ width: '22%' }}>
                          Duration
                        </th>
                        <th className={classes.newInputTh} style={{ width: '10%' }}>
                          Remove
                        </th>
                      </tr>
                    </thead>
                    <tbody>
                      {subTaskFields.map((item, index) => (
                        <tr key={index}>
                          <td className={classes.newInputTd}>
                            <Controller
                              name={`subTasks[${index}].name`}
                              defaultValue={item.name}
                              control={control}
                              render={({ field }) => <AntInput placeholder="Name" {...field} />}
                            />
                          </td>
                          <td className={classes.newInputTd}>
                            <Controller
                              name={`subTasks[${index}].description`}
                              defaultValue={item.description}
                              control={control}
                              render={({ field }) => <AntInput placeholder="Description" {...field} />}
                            />
                          </td>
                          <td className={classes.newInputTd}>
                            <Controller
                              name={`subTasks[${index}].assigneeId`}
                              defaultValue={item.assigneeId}
                              control={control}
                              render={({ field }) => (
                                <AntSelect allowClear placeholder="Select user" {...field}>
                                  {userList.map(user => (
                                    <Option key={user.id} value={user.id}>
                                      {user.name}
                                    </Option>
                                  ))}
                                </AntSelect>
                              )}
                            />
                          </td>
                          <td className={classes.newInputTd}>
                            <Controller
                              name={`subTasks[${index}].startAt`}
                              defaultValue={item.startAt}
                              control={control}
                              render={({ field }) => (
                                <AntDatePicker
                                  allowClear
                                  showTime={{ format: 'hh:mm A' }}
                                  format="YYYY-MM-DD hh:mm A"
                                  disabledDate={disabledDate}
                                  placeholder="Start date"
                                  {...field}
                                />
                              )}
                            />
                          </td>
                          <td className={classes.newInputTd}>
                            <Row>
                              <Col span={12}>
                                <Controller
                                  name={`subTasks[${index}].durationType`}
                                  defaultValue={item.durationType}
                                  control={control}
                                  render={({ field }) => (
                                    <AntSelect allowClear placeholder="Select type" {...field}>
                                      <Option key="minutes" value="minutes">
                                        Minutes
                                      </Option>
                                      <Option key="hours" value="hours">
                                        Hours
                                      </Option>
                                      <Option key="days" value="days">
                                        Days
                                      </Option>
                                    </AntSelect>
                                  )}
                                />
                              </Col>
                              <Col span={12}>
                                <Controller
                                  name={`subTasks[${index}].duration`}
                                  defaultValue={item.duration}
                                  control={control}
                                  render={({ field }) => (
                                    <AntInput type="number" min={0} placeholder="Task duration" {...field} />
                                  )}
                                />
                              </Col>
                            </Row>
                          </td>
                          <td
                            className={classes.newInputTd}
                            style={{
                              textAlign: 'center',
                            }}
                          >
                            <DeleteOutlined
                              className={classes.tableIcon}
                              onClick={() => {
                                const subTasksArr = getValues('subTasks');

                                if (subTasksArr && subTasksArr.length > 1 && index !== 0) {
                                  subTaskRemove(index);
                                }
                              }}
                            />
                          </td>
                        </tr>
                      ))}
                    </tbody>
                  </table>
                </div>
              </div>
            </Row> */}
            {/* <br />
            <Row>
              <Button
                type="primary"
                htmlType="button"
                onClick={() =>
                  subTaskAppend({
                    productId: null,
                  })
                }
              >
                <PlusOutlined /> Subtask
              </Button>
            </Row> */}
          </>
        ) : null}
      </form>
    </Modal>
  );
};

TaskModal.propTypes = {
  recordId: PropTypes.number,
  setModaltype: PropTypes.func,
  onSubmit: PropTypes.func,
  todoData: PropTypes.object,
};

export default TaskModal;

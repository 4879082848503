import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Layout } from 'antd';
import withStyles from 'react-jss';
import { useHistory } from 'react-router';
import Sidebar from './Sidebar';
import Navbar from './Navbar';
import styles from './styles';

const { Content } = Layout;

const Structure = props => {
  const { classes } = props;
  const [collapsed, setCollapsed] = useState(false);
  const [open, setVisible] = useState(false);
  const history = useHistory();

  return (
    <Layout className={classes.layout}>
      <Sidebar collapsed={collapsed} open={open} setVisible={setVisible} history={history} />
      <Layout>
        <Navbar
          collapsed={collapsed}
          setCollapsed={setCollapsed}
          open={open}
          setVisible={setVisible}
          history={history}
        />
        <Content className={classes.content}>
          <div className={classes.contentContainer}>{props.children}</div>
        </Content>
      </Layout>
    </Layout>
  );
};

Structure.propTypes = {
  children: PropTypes.oneOfType([PropTypes.element, PropTypes.array]),
  classes: PropTypes.object,
};

export default withStyles(styles)(Structure);

import React, { useCallback, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import withStyles from 'react-jss';
import { useDispatch, useSelector } from 'react-redux';
import { Card, Col, DatePicker, Row, Table, Tooltip, Typography, Collapse, Select } from 'antd';
import Modal from 'antd/lib/modal/Modal';
import moment from 'moment';
import startCase from 'lodash/startCase';
import { Link } from 'react-router-dom';
import Structure from '../Structure/Structure';
import styles from '../CommonStyles';
import { getFinancialSummary, clearFinancialSummary, getTransactionList, clearTransactionList } from '../Actions';
import { get, onFilter, sorter, filterDropdown, onDateFilter, dateFilterDropdown, dateSorter } from '../utils';
import Heading from '../Components/Heading';
import constants from '../constants';

const { Text } = Typography;
const { Panel } = Collapse;

const FinancialSummary = props => {
  const { classes } = props;
  const dispatch = useDispatch();

  const financialSummary = useSelector(state => state.financialSummary);
  const transactionList = useSelector(state => state.transactionList);

  const [From, SetFrom] = useState(null);
  const [To, SetTo] = useState(null);
  const [Consignor, SetConsignor] = useState(constants.consignors.list.plusFormulationsHeadOffice);

  const [viewModal, setViewModal] = useState(false);
  const [narrationId, setNarrationId] = useState(null);

  useEffect(() => {
    return () => {
      dispatch(clearFinancialSummary());
      dispatch(clearTransactionList());
    };
  }, []);

  useEffect(() => {
    dispatch(clearFinancialSummary());

    if (From && To && Consignor) {
      dispatch(getFinancialSummary({ from: From, to: To, consignor: Consignor }));
    }
  }, [From, To, Consignor]);

  useEffect(() => {
    dispatch(clearTransactionList());

    if (narrationId && From && To) {
      dispatch(getTransactionList({ from: From, to: To, narrationId }));
    }
  }, [financialSummary, narrationId, From, To]);

  const disabledDate = useCallback(current => {
    return current && current > moment().subtract(1, 'day').endOf('day');
  }, []);

  const showModal = id => {
    setViewModal(true);
    setNarrationId(id);
  };

  const hideModal = () => {
    setViewModal(false);
    setNarrationId(null);
    dispatch(clearTransactionList());
  };

  const tooltipShow = arg => (
    <Tooltip placement="topLeft" title={Object.values(constants.bank.types).includes(`${arg}`) ? startCase(arg) : arg}>
      {Object.values(constants.bank.types).includes(`${arg}`) ? startCase(arg) : arg}
    </Tooltip>
  );

  const TransactionColumns = [
    {
      title: 'Type',
      dataIndex: 'type',
      sorter: sorter('type'),
      filterDropdown,
      onFilter: onFilter('type'),
      width: 80,
      render: type => startCase(type),
    },
    {
      title: 'Bank',
      dataIndex: 'bank',
      sorter: sorter('bank'),
      filterDropdown,
      onFilter: onFilter('bank'),
      render: tooltipShow,
      width: 170,
    },
    {
      title: 'Narration',
      dataIndex: 'narration',
      sorter: sorter('narration'),
      filterDropdown,
      onFilter: onFilter('narration'),
      render: tooltipShow,
      width: 170,
    },
    {
      title: 'Head',
      dataIndex: 'parentHead',
      sorter: sorter('parentHead'),
      filterDropdown,
      onFilter: onFilter('parentHead'),
      render: tooltipShow,
      width: 170,
    },
    {
      title: 'Summary Head',
      dataIndex: 'head',
      sorter: sorter('head'),
      filterDropdown,
      onFilter: onFilter('head'),
      render: tooltipShow,
      width: 170,
    },
    {
      title: 'Date',
      dataIndex: 'transDate',
      sorter: dateSorter('transDate'),
      filterDropdown: dateFilterDropdown,
      onFilter: onDateFilter('transDate'),
      render: transDate => (transDate ? moment(transDate).format('DD MMM YYYY') : null),
      width: 120,
    },
    {
      title: 'Amount',
      dataIndex: 'amount',
      sorter: sorter('amount'),
      filterDropdown,
      onFilter: onFilter('amount'),
      render: amount => Number(amount).toLocaleString('en-IN'),
      width: 150,
      align: 'right',
    },
    {
      title: 'Closing Balance',
      dataIndex: 'closingBalance',
      sorter: sorter('closingBalance'),
      filterDropdown,
      onFilter: onFilter('closingBalance'),
      render: closingBalance => Number(closingBalance).toLocaleString('en-IN'),
      width: 150,
      align: 'right',
    },
    {
      title: 'Customer',
      dataIndex: 'customer',
      sorter: sorter('customer'),
      filterDropdown,
      onFilter: onFilter('customer'),
      render: tooltipShow,
      ellipsis: { showTitle: false },
      width: 150,
    },
    {
      title: 'Supplier',
      dataIndex: 'supplier',
      sorter: sorter('supplier'),
      filterDropdown,
      onFilter: onFilter('supplier'),
      render: tooltipShow,
      ellipsis: { showTitle: false },
      width: 150,
    },
    {
      title: 'Employee',
      dataIndex: 'employee',
      sorter: sorter('employee'),
      filterDropdown,
      onFilter: onFilter('employee'),
      render: tooltipShow,
      ellipsis: { showTitle: false },
      width: 150,
    },
    {
      title: 'Contact',
      dataIndex: 'contact',
      sorter: sorter('contact'),
      filterDropdown,
      onFilter: onFilter('contact'),
      render: tooltipShow,
      ellipsis: { showTitle: false },
      width: 150,
    },
    {
      title: 'Asset',
      dataIndex: 'asset',
      sorter: sorter('asset'),
      filterDropdown,
      onFilter: onFilter('asset'),
      render: tooltipShow,
      ellipsis: { showTitle: false },
      width: 100,
    },
    {
      title: 'Reference',
      dataIndex: 'refNo',
      sorter: sorter('refNo'),
      filterDropdown,
      onFilter: onFilter('refNo'),
      render: tooltipShow,
      ellipsis: { showTitle: false },
      width: 200,
    },
    {
      title: 'Remarks',
      dataIndex: 'remarks',
      sorter: sorter('remarks'),
      filterDropdown,
      onFilter: onFilter('remarks'),
      render: tooltipShow,
      ellipsis: { showTitle: false },
      width: 200,
    },
    {
      title: 'Done By',
      dataIndex: 'createdByUser',
      sorter: sorter('createdByUser'),
      filterDropdown,
      onFilter: onFilter('createdByUser'),
      width: 120,
    },
  ];

  const columns = [
    {
      title: 'Consignor',
      dataIndex: 'consignor',
    },
    {
      title: 'Bank',
      dataIndex: 'bank',
    },
    {
      title: 'Opening Balance',
      dataIndex: 'initialBalance',
      render: initialBalance => Number(initialBalance).toLocaleString('en-IN'),
      align: 'right',
    },
    {
      title: 'Closing Balance',
      dataIndex: 'closingBalance',
      render: closingBalance => Number(closingBalance).toLocaleString('en-IN'),
      align: 'right',
    },
  ];

  return (
    <Structure>
      <>
        <Heading text="Financial Summary" />
        <Row gutter={[8, 8]}>
          <Col>
            <DatePicker onChange={SetFrom} disabledDate={disabledDate} placeholder="From" value={From} />
          </Col>
          <Col>
            <DatePicker onChange={SetTo} disabledDate={disabledDate} placeholder="To" value={To} />
          </Col>
          <Col>
            <Select onChange={SetConsignor} placeholder="Consignor" value={Consignor} style={{ width: 280 }}>
              {Object.values(constants.consignors.list).map(consignor => (
                <Select.Option key={consignor} value={consignor}>
                  {consignor}
                </Select.Option>
              ))}
            </Select>
          </Col>
        </Row>
        <br />

        <Row gutter={[8, 8]}>
          <Col xs={24} lg={12}>
            <Card>
              <Collapse bordered={false} expandIconPosition="end">
                <Panel
                  header={`Opening Inventory Total: ${Number(
                    get(financialSummary, ['totalOpeningInventory'], 0)
                  ).toLocaleString('en-IN')}`}
                  key="totalOpeningInventory"
                >
                  <Row>
                    Opening Raw Material Inventory:
                    <span className={classes.detailColor}>
                      {Number(get(financialSummary, ['openingRawMaterialInventory'], 0)).toLocaleString('en-IN')}
                    </span>
                  </Row>
                  <Row>
                    Opening Packing Material Inventory:
                    <span className={classes.detailColor}>
                      {Number(get(financialSummary, ['openingPackingMaterialInventory'], 0)).toLocaleString('en-IN')}
                    </span>
                  </Row>
                  <Row>
                    Opening Printed Packing Material Inventory:
                    <span className={classes.detailColor}>
                      {Number(get(financialSummary, ['openingPrintedPackagingMaterialInventory'], 0)).toLocaleString(
                        'en-IN'
                      )}
                    </span>
                  </Row>
                  <Row>
                    Opening Consumable Material Inventory:
                    <span className={classes.detailColor}>
                      {Number(get(financialSummary, ['openingConsumableMaterialInventory'], 0)).toLocaleString('en-IN')}
                    </span>
                  </Row>
                  <Row>
                    Opening Manufacturing Materials Inventory:
                    <span className={classes.detailColor}>
                      {Number(get(financialSummary, ['openingManufacturingMaterialInventory'], 0)).toLocaleString(
                        'en-IN'
                      )}
                    </span>
                  </Row>
                  <Row>
                    Opening Ready To Dispatch Materials Inventory:
                    <span className={classes.detailColor}>
                      {Number(get(financialSummary, ['openingReadyToDispatchMaterialInventory'], 0)).toLocaleString(
                        'en-IN'
                      )}
                    </span>
                  </Row>
                </Panel>
              </Collapse>
              <br />
              <Collapse bordered={false} expandIconPosition="end">
                <Panel
                  header={`Purchase Total: ${Number(get(financialSummary, ['totalPurchase'], 0)).toLocaleString(
                    'en-IN'
                  )}`}
                  key="totalPurchase"
                >
                  <Row>
                    <Link to="/purchase/list">Purchase:&nbsp;</Link>
                    <span>{Number(get(financialSummary, ['purchases'], 0)).toLocaleString('en-IN')}</span>
                  </Row>
                  <Row>
                    <Link to="/creditdebitnote/list">Credit Note:&nbsp;</Link>
                    <span>{Number(get(financialSummary, ['supplierCreditNotes'], 0)).toLocaleString('en-IN')}</span>
                  </Row>
                  <Row>
                    <Link to="/creditdebitnote/list">Debit Note:&nbsp;</Link>
                    <span>{Number(get(financialSummary, ['supplierDebitNotes'], 0)).toLocaleString('en-IN')}</span>
                  </Row>
                </Panel>
              </Collapse>
              <br />
              <Collapse bordered={false} expandIconPosition="end">
                <Panel
                  header={`Direct Expenses: ${Number(
                    get(financialSummary, ['totalDirectExpenseNarrations'], 0)
                  ).toLocaleString('en-IN')}`}
                  key="totalDirectExpenseNarrations"
                >
                  {get(financialSummary, ['directExpenseNarrations'], []).map(narration => (
                    <Row key={narration.id}>
                      <span
                        className={classes.headingColor}
                        onClick={() => {
                          showModal(narration.id);
                        }}
                        onKeyDown={() => {
                          showModal(narration.id);
                        }}
                        role="button"
                        tabIndex={0}
                      >
                        {narration?.narration}:&nbsp;
                      </span>
                      <span>{Number(narration.value).toLocaleString('en-IN')}</span>
                    </Row>
                  ))}
                </Panel>
              </Collapse>
              <br />
              {/* <Collapse bordered={false} expandIconPosition="end">
                <Panel
                  header={`Manufacturing Expenses: ${Number(
                    get(financialSummary, ['totalManufacturingExpenseNarrations'], 0)
                  ).toLocaleString('en-IN')}`}
                  key="totalManufacturingExpenseNarrations"
                >
                  {get(financialSummary, ['manufacturingExpenseNarrations'], []).map(narration => (
                    <Row key={narration.id}>
                      <span
                        className={classes.headingColor}
                        onClick={() => {
                          showModal(narration.id);
                        }}
                        onKeyDown={() => {
                          showModal(narration.id);
                        }}
                        role="button"
                        tabIndex={0}
                      >
                        {narration?.narration}:&nbsp;
                      </span>
                      <span>{Number(narration.value).toLocaleString('en-IN')}</span>
                    </Row>
                  ))}
                </Panel>
              </Collapse>
              <br /> */}
              <Row>
                TVC Total:
                <span className={classes.detailColor}>
                  {Number(get(financialSummary, ['tvc'], 0)).toLocaleString('en-IN')}
                </span>
              </Row>
              <Row>
                Gross Profit:
                <span className={classes.detailColor}>
                  {Number(get(financialSummary, ['grossProfit'], 0)).toLocaleString('en-IN')}
                </span>
              </Row>
              <Row>
                Gross Profit %:
                <span className={classes.detailColor}>{get(financialSummary, ['grossProfitPercentage'], 0)}</span>
              </Row>
              <br />
              <Collapse bordered={false} expandIconPosition="end">
                <Panel
                  header={`Indirect Expenses: ${Number(
                    get(financialSummary, ['totalIndirectExpenseNarrations'], 0)
                  ).toLocaleString('en-IN')}`}
                  key="totalIndirectExpenseNarrations"
                >
                  {get(financialSummary, ['inDirectExpenseNarrations'], []).map(narration => (
                    <Row key={narration.id}>
                      <span
                        className={classes.headingColor}
                        onClick={() => {
                          showModal(narration.id);
                        }}
                        onKeyDown={() => {
                          showModal(narration.id);
                        }}
                        role="button"
                        tabIndex={0}
                      >
                        {narration?.narration}:&nbsp;
                      </span>
                      <span>{Number(narration.value).toLocaleString('en-IN')}</span>
                    </Row>
                  ))}
                </Panel>
              </Collapse>
              <br />
              <Row>
                Net Profit Margin:
                <span className={classes.detailColor}>
                  {Number(get(financialSummary, ['netProfitMargin'], 0)).toLocaleString('en-IN')}
                </span>
              </Row>
              <Row>
                Net Profit Margin %:
                <span className={classes.detailColor}>
                  {Number(get(financialSummary, ['netProfitMarginPercentage'], 0)).toLocaleString('en-IN')}
                </span>
              </Row>
              <br />
              <Row>
                Depriciation Total:
                <span className={classes.detailColor}>
                  {Number(get(financialSummary, ['totalDepriciation'], 0)).toLocaleString('en-IN')}
                </span>
              </Row>
              {/* <Row>
                Working Capital:
                <span className={classes.detailColor}>
                  {Number(get(financialSummary, ['workingCapital'], 0)).toLocaleString('en-IN')}
                </span>
              </Row> */}
            </Card>
          </Col>
          <Col xs={24} lg={12}>
            <Card>
              <Collapse bordered={false} expandIconPosition="end">
                <Panel
                  header={`Sale Total: ${Number(get(financialSummary, ['totalSales'], 0)).toLocaleString('en-IN')}`}
                  key="totalSales"
                >
                  <Row>
                    <Link to="/sale/list">Sale:&nbsp;</Link>
                    <span>{Number(get(financialSummary, ['orderSales'], 0)).toLocaleString('en-IN')}</span>
                  </Row>
                  <Row>
                    <Link to="/material/sale/list">Material Sale:&nbsp;</Link>
                    <span>{Number(get(financialSummary, ['materialSales'], 0)).toLocaleString('en-IN')}</span>
                  </Row>

                  <Row>
                    <Link to="/creditdebitnote/list">Credit Note:&nbsp;</Link>
                    <span>{Number(get(financialSummary, ['customerCreditNotes'], 0)).toLocaleString('en-IN')}</span>
                  </Row>
                  <Row>
                    <Link to="/creditdebitnote/list">Debit Note:&nbsp;</Link>
                    <span>{Number(get(financialSummary, ['customerDebitNotes'], 0)).toLocaleString('en-IN')}</span>
                  </Row>
                  {/* <Row>
                    Sale Return:
                    <span className={classes.detailColor}>
                      {Number(get(financialSummary, ['saleReturns'], 0)).toLocaleString('en-IN')}
                    </span>
                  </Row> */}
                </Panel>
              </Collapse>
              <br />
              <Collapse bordered={false} expandIconPosition="end">
                <Panel
                  header={`Other Income: ${Number(get(financialSummary, ['totalOtherIncome'], 0)).toLocaleString(
                    'en-IN'
                  )}`}
                  key="totalOtherIncome"
                >
                  {get(financialSummary, ['otherIncome'], []).map(narration => (
                    <Row key={narration.id} e>
                      <span
                        className={classes.headingColor}
                        onClick={() => {
                          showModal(narration.id);
                        }}
                        onKeyDown={() => {
                          showModal(narration.id);
                        }}
                        role="button"
                        tabIndex={0}
                      >
                        {narration?.narration}:&nbsp;
                      </span>
                      <span>{Number(narration.value).toLocaleString('en-IN')}</span>
                    </Row>
                  ))}
                </Panel>
              </Collapse>
              <br />
              <Collapse bordered={false} expandIconPosition="end">
                <Panel
                  header={`Closing Inventory Total: ${Number(
                    get(financialSummary, ['totalClosingInventory'], 0)
                  ).toLocaleString('en-IN')}`}
                  key="totalClosingInventory"
                >
                  <Row>
                    Closing Raw Material Inventory:
                    <span className={classes.detailColor}>
                      {Number(get(financialSummary, ['closingRawMaterialInventory'], 0)).toLocaleString('en-IN')}
                    </span>
                  </Row>
                  <Row>
                    Closing Packing Material Inventory:
                    <span className={classes.detailColor}>
                      {Number(get(financialSummary, ['closingPackingMaterialInventory'], 0)).toLocaleString('en-IN')}
                    </span>
                  </Row>
                  <Row>
                    Closing Printed Packing Material Inventory:
                    <span className={classes.detailColor}>
                      {Number(get(financialSummary, ['closingPrintedPackagingMaterialInventory'], 0)).toLocaleString(
                        'en-IN'
                      )}
                    </span>
                  </Row>
                  <Row>
                    Closing Consumable Material Inventory:
                    <span className={classes.detailColor}>
                      {Number(get(financialSummary, ['closingConsumableMaterialInventory'], 0)).toLocaleString('en-IN')}
                    </span>
                  </Row>
                  <Row>
                    Closing Manufacturing Materials Inventory:
                    <span className={classes.detailColor}>
                      {Number(get(financialSummary, ['closingManufacturingMaterialInventory'], 0)).toLocaleString(
                        'en-IN'
                      )}
                    </span>
                  </Row>
                  <Row>
                    Closing Ready To Dispatch Materials Inventory:
                    <span className={classes.detailColor}>
                      {Number(get(financialSummary, ['closingReadyToDispatchMaterialInventory'], 0)).toLocaleString(
                        'en-IN'
                      )}
                    </span>
                  </Row>
                </Panel>
              </Collapse>
              {/* <Row>
                Break Even Point:
                <span className={classes.detailColor}>
                  {Number(get(financialSummary, ['breakEvenPoint'], 0)).toLocaleString('en-IN')}
                </span>
              </Row> */}
            </Card>
          </Col>
        </Row>
        <br />
        <Row gutter={[8, 8]}>
          <Col span={24}>
            <Table
              columns={columns}
              bordered
              rowKey={record => record.id}
              pagination={false}
              dataSource={get(financialSummary, ['banks'], [])}
              size="small"
              scroll={{ x: 425 }}
              summary={pageData => {
                let totalOpeningBalance = 0;
                let totalClosinngBalance = 0;
                pageData.forEach(({ initialBalance, closingBalance }) => {
                  totalOpeningBalance += +initialBalance;
                  totalClosinngBalance += +closingBalance;
                });
                return (
                  <>
                    <Table.Summary.Row>
                      <Table.Summary.Cell colSpan={2}>Total</Table.Summary.Cell>
                      <Table.Summary.Cell className={classes.textAlignRight}>
                        <Text>{Number(totalOpeningBalance.toFixed(2)).toLocaleString('en-IN')}</Text>
                      </Table.Summary.Cell>
                      <Table.Summary.Cell className={classes.textAlignRight}>
                        <Text>{Number(totalClosinngBalance.toFixed(2)).toLocaleString('en-IN')}</Text>
                      </Table.Summary.Cell>
                    </Table.Summary.Row>
                  </>
                );
              }}
            />
          </Col>
        </Row>
        <Modal
          open={viewModal}
          onOk={hideModal}
          style={{ top: 20 }}
          maskClosable={false}
          closable={false}
          width="1300px"
          okText="Close"
          cancelButtonProps={{ className: classes.hide }}
          okButtonProps={{ className: classes.button }}
        >
          <Table
            columns={TransactionColumns}
            bordered
            rowKey={record => record.id}
            pagination={{ defaultPageSize: 50, position: ['bottomLeft'] }}
            dataSource={transactionList}
            size="small"
            scroll={{ x: 1280, y: 600 }}
          />
        </Modal>
      </>
    </Structure>
  );
};

FinancialSummary.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(FinancialSummary);

import React from 'react';
import PropTypes from 'prop-types';
import { Controller } from 'react-hook-form';
import { Input, Select } from 'antd';
import withStyles from 'react-jss';
import { useSelector } from 'react-redux';

import { DeleteOutlined } from '@ant-design/icons';
import styles from '../CommonStyles';
import GetField from '../Components/GetRHFField';

const AntInput = GetField(Input);
const AntSelect = GetField(Select);

const ProductTable = props => {
  const { classes, control, index, remove } = props;

  const completeOrdersIds = useSelector(state => state.completeOrdersIds);

  return (
    <tr>
      <td
        className={classes.inputTd}
        style={{
          textAlign: 'center',
        }}
      >
        <DeleteOutlined
          onClick={() => {
            remove(index);
          }}
        />
      </td>
      <td className={classes.inputTd}>
        <Controller
          name={`products[${index}].orderId`}
          control={control}
          render={({ field }) => (
            <AntSelect allowClear placeholder="Order #" {...field}>
              {completeOrdersIds.map(order => (
                <Select.Option key={order.id} value={order.id}>
                  {order.orderId}
                </Select.Option>
              ))}
            </AntSelect>
          )}
        />
      </td>
      <td className={classes.inputTd}>
        <Controller
          name={`products[${index}].orderId`}
          control={control}
          render={({ field }) => (
            <AntSelect {...field} allowClear placeholder="Formulation" disabled>
              {completeOrdersIds.map(order => (
                <Select.Option key={order.id} value={order.id}>
                  {order.formulation}
                </Select.Option>
              ))}
            </AntSelect>
          )}
        />
      </td>
      <td className={classes.inputTd}>
        <Controller
          name={`products[${index}].orderId`}
          control={control}
          render={({ field }) => (
            <AntSelect {...field} allowClear placeholder="Batch" disabled>
              {completeOrdersIds.map(order => (
                <Select.Option key={order.id} value={order.id}>
                  {order.batch}
                </Select.Option>
              ))}
            </AntSelect>
          )}
        />
      </td>
      <td className={classes.inputTd}>
        <Controller
          name={`products[${index}].orderId`}
          control={control}
          render={({ field }) => (
            <AntSelect {...field} allowClear placeholder="Unit" disabled>
              {completeOrdersIds.map(order => (
                <Select.Option key={order.id} value={order.id}>
                  {order.unit}
                </Select.Option>
              ))}
            </AntSelect>
          )}
        />
      </td>
      <td className={classes.inputTd}>
        <Controller
          name={`products[${index}].quantity`}
          control={control}
          render={({ field }) => <AntInput type="number" min={0} {...field} placeholder="Quantity" />}
        />
      </td>
      <td className={classes.inputTd}>
        <Controller
          name={`products[${index}].rate`}
          control={control}
          render={({ field }) => <AntInput type="number" {...field} placeholder="Rate" step={0.001} min={0} />}
        />
      </td>
      <td className={classes.inputTd}>
        <Controller
          name={`products[${index}].discountValue`}
          control={control}
          render={({ field }) => <AntInput type="number" {...field} placeholder="Discount Value" step={0.01} min={0} />}
        />
      </td>
      <td className={classes.inputTd}>
        <Controller
          name={`products[${index}].orderId`}
          control={control}
          render={({ field }) => (
            <AntSelect {...field} allowClear placeholder="HSN" disabled>
              {completeOrdersIds.map(order => (
                <Select.Option key={order.id} value={order.id}>
                  {order.hsn}
                </Select.Option>
              ))}
            </AntSelect>
          )}
        />
      </td>
      <td className={classes.inputTd}>
        <Controller
          name={`products[${index}].orderId`}
          control={control}
          render={({ field }) => (
            <AntSelect {...field} allowClear placeholder="Tax" disabled>
              {completeOrdersIds.map(order => (
                <Select.Option key={order.id} value={order.id}>
                  {order.tax}
                </Select.Option>
              ))}
            </AntSelect>
          )}
        />
      </td>
    </tr>
  );
};

ProductTable.propTypes = {
  classes: PropTypes.object.isRequired,
  control: PropTypes.any.isRequired,
  item: PropTypes.object.isRequired,
  index: PropTypes.number.isRequired,
  remove: PropTypes.func.isRequired,
};

export default withStyles(styles)(ProductTable);

import React, { useEffect, Fragment } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Table, Tag, Tooltip } from 'antd';
import startCase from 'lodash/startCase';
import { useHistory } from 'react-router';
import propTypes from 'prop-types';
import { filterDropdown, get, onFilter, sorter, filterItemToOrderDropdown, onItemToOrderFilter } from '../utils';
import { setPurchaseOrder } from '../Actions';

const ItemsToOrderTable = ({ setRowCounts }) => {
  const dispatch = useDispatch();
  const history = useHistory();

  const auth = useSelector(state => state.auth);
  const permissions = get(auth, ['permissions'], []);
  const itemsToOrderList = useSelector(state => state.itemsToOrderList);

  useEffect(() => {
    if (itemsToOrderList && setRowCounts) {
      setRowCounts(itemsToOrderList.length);
    }
  }, [itemsToOrderList]);

  const tooltipShow = arg => (
    <Tooltip placement="topLeft" title={arg}>
      {arg}
    </Tooltip>
  );

  const itemStatus = record => {
    if (record.status === 'Reorder') {
      return <Tag color="red">Reorder</Tag>;
    }
    if (record.status === 'Prompt') {
      return <Tag color="green">Prompt</Tag>;
    }
    if (record.status === 'Excess') {
      return <Tag color="purple">Excess</Tag>;
    }
    return null;
  };

  const columns = [
    {
      title: 'Name',
      dataIndex: 'name',
      sorter: sorter('name'),
      filterDropdown,
      onFilter: onFilter('name'),
      render: tooltipShow,
      width: '14%',
    },
    {
      title: 'Type',
      dataIndex: 'type',
      sorter: sorter('type'),
      filterDropdown,
      onFilter: onFilter('type', true),
      render: type => startCase(type),
      width: '9%',
    },
    {
      title: 'Unit',
      dataIndex: 'unit',
      sorter: sorter('unit'),
      filterDropdown,
      onFilter: onFilter('unit'),
      width: '7%',
    },
    {
      title: 'Maximum Stock',
      dataIndex: 'maximumStock',
      sorter: sorter('maximumStock'),
      filterDropdown,
      onFilter: onFilter('maximumStock'),
      width: '8%',
    },
    {
      title: 'Stock',
      dataIndex: 'stock',
      sorter: sorter('stock'),
      filterDropdown,
      onFilter: onFilter('stock'),
      width: '7%',
    },
    {
      title: 'Total Usage',
      dataIndex: 'totalUsage',
      sorter: sorter('totalUsage'),
      filterDropdown,
      onFilter: onFilter('totalUsage'),
      width: '7%',
    },
    {
      title: 'PO Quantity',
      dataIndex: 'poQuantity',
      sorter: sorter('poQuantity'),
      filterDropdown,
      onFilter: onFilter('poQuantity'),
      width: '8%',
    },
    {
      title: 'Last Purchase Rate',
      dataIndex: 'lastPurchaseRate',
      sorter: sorter('lastPurchaseRate'),
      filterDropdown,
      onFilter: onFilter('lastPurchaseRate'),
      width: '8%',
    },
    {
      title: 'Last Purchase Supplier',
      dataIndex: 'lastPurchaseSupplier',
      sorter: sorter('lastPurchaseSupplier'),
      filterDropdown,
      onFilter: onFilter('lastPurchaseSupplier'),
      width: '8%',
    },
    {
      title: 'Requirement contributed by',
      dataIndex: 'usedByFormulations',
      key: 'usedByFormulations',
      sorter: sorter('usedByFormulations'),
      filterDropdown,
      onFilter: onFilter('usedByFormulations'),
      // render: usedByFormulations => (usedByFormulations || []).join(', '),
      render: (_data, record) => {
        let acc = 0;
        const totalInStock = +record.stock + +record.poQuantity;

        return record?.usedByFormulations?.map((el, index) => {
          acc += +record.usedQuantities[index];
          return (
            <Fragment key={index}>
              <span style={{ color: acc > totalInStock ? 'Red' : 'Green' }}>{el}</span>
              <br />
              <br />
            </Fragment>
          );
        });
      },
      width: '20%',
    },
    {
      title: 'Status',
      dataIndex: 'status',
      sorter: sorter('status'),
      filterDropdown,
      onFilter: onFilter('status'),
      render: (_data, record) => itemStatus(record),
      width: '7%',
    },
    {
      title: 'To Order',
      dataIndex: 'toOrder',
      sorter: sorter('toOrder'),
      filterDropdown: filterItemToOrderDropdown,
      onFilter: onItemToOrderFilter('toOrder'),
      width: '8%',
    },
  ];

  const setPurchaseOrderValues = (materialId, toOrder) => {
    dispatch(setPurchaseOrder({ materialId, quantity: toOrder }));
    history.push('/purchaseorder/new');
  };

  const renderCreatePurchaseOrderColumn = record => {
    if (permissions.includes('shortage_item_list')) {
      if (record.toOrder > 0) {
        return (
          <Tooltip placement="bottom" title="Create Purchase Order">
            <a
              role="link"
              tabIndex={0}
              onClick={() => setPurchaseOrderValues(record.materialId, record.toOrder)}
              onKeyPress={() => setPurchaseOrderValues(record.materialId, record.toOrder)}
            >
              Create
            </a>
          </Tooltip>
        );
      }
      return null;
    }
    return null;
  };

  if (permissions.includes('new_purchase_order')) {
    columns.push({
      title: 'Create PO',
      render: renderCreatePurchaseOrderColumn,
      width: '7%',
    });
  }

  return (
    <Table
      columns={columns}
      bordered
      rowKey={record => record.materialId}
      pagination={{ defaultPageSize: 20, position: ['bottomLeft'] }}
      dataSource={itemsToOrderList}
      size="small"
      scroll={{ x: 1600, y: window.innerHeight - 300 }}
      onChange={
        setRowCounts
          ? (_page, _filters, _sorter, { currentDataSource }) => {
              setRowCounts((currentDataSource || []).length);
            }
          : () => {}
      }
    />
  );
};

ItemsToOrderTable.propTypes = {
  setRowCounts: propTypes.func,
};

export default ItemsToOrderTable;

import Api from '../Api';

export const getSaleReturnList = () => async dispatch => {
  try {
    const { data } = await Api.get('/order-sale-returns');
    dispatch({
      type: 'FETCH_SALE_RETURN_LIST',
      payload: data,
    });
  } catch (err) {
    throw err;
  }
};

export const clearSaleReturnList = () => {
  return {
    type: 'CLEAR_SALE_RETURN_LIST',
  };
};

export const getSaleReturn = id => async dispatch => {
  try {
    const { data } = await Api.get(`/order-sale-returns/${id}`);
    dispatch({
      type: 'FETCH_SALE_RETURN',
      payload: data,
    });
  } catch (err) {
    throw err;
  }
};

export const clearSaleReturn = () => {
  return {
    type: 'CLEAR_SALE_RETURN',
  };
};

// export const getSaleReturnFilesList = id => async dispatch => {
//   try {
//     const { data } = await Api.get(`/order-sale-returns/${id}/files`);
//     dispatch({
//       type: 'FETCH_SALE_RETURN_FILES_LIST',
//       payload: data,
//     });
//   } catch (err) {
//     throw err;
//   }
// };

// export const clearSaleReturnFilesList = () => {
//   return {
//     type: 'CLEAR_SALE_RETURN_FILES_LIST',
//   };
// };

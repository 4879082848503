import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { Table, Popconfirm, Tooltip, Modal, Upload, Button } from 'antd';
import PropTypes from 'prop-types';
import { useSelector, useDispatch } from 'react-redux';
import {
  CloudDownloadOutlined,
  CloudUploadOutlined,
  DeleteOutlined,
  EditOutlined,
  FileTextOutlined,
} from '@ant-design/icons';
import { Link } from 'react-router-dom';
import moment from 'moment';

import withStyles from 'react-jss';
import { errorNotification, infoNotification, successNotification } from '../Snackbar';
import { sorter, onFilter, filterDropdown, dateSorter, onDateFilter, dateFilterDropdown } from '../utils';
import Api from '../Api';
import { getJobWorks, clearJobWorks, clearJobWorkFiles, getJobWorkFiles } from '../Actions';
import Structure from '../Structure/Structure';
import Heading from '../Components/Heading';
import styles from '../CommonStyles';

const JobWorks = props => {
  const { classes } = props;

  const dispatch = useDispatch();

  const jobworks = useSelector(state => state.jobworks);
  const jobworkFiles = useSelector(state => state.jobworkFiles);

  const [FileModal, setFileModal] = useState(null);
  const [fileList, setFileList] = useState([]);
  const [uploading, setUploading] = useState(false);
  const [RecordId, setRecordId] = useState(null);

  useEffect(() => {
    dispatch(getJobWorks());
    return () => {
      dispatch(clearJobWorks());
    };
  }, []);

  useEffect(() => {
    if (RecordId && FileModal === 'ViewFiles') {
      dispatch(getJobWorkFiles(RecordId));
    }
  }, [RecordId, FileModal]);

  const onDelete = useCallback(async id => {
    try {
      await Api.delete(`/jobworks/${id}`);
      successNotification('Job work deleted');
      dispatch(getJobWorks());
    } catch (err) {
      errorNotification(err);
    }
  }, []);

  const showModal = (id, type) => {
    setRecordId(id);
    setFileModal(type);
  };

  const hideModal = () => {
    setRecordId(null);
    setFileModal(null);
    setFileList([]);
    dispatch(clearJobWorkFiles());
  };

  const onUpload = async () => {
    try {
      setUploading(true);
      const formData = new FormData();
      fileList.forEach((file, index) => {
        formData.append(`files[${index}]`, file);
      });
      const { data } = await Api.post(`/jobworks/${RecordId}/files`, formData, {
        headers: { 'content-type': 'multipart/form-data' },
      });
      infoNotification(data);
      setUploading(false);
      hideModal();
      setFileList([]);
    } catch (err) {
      errorNotification(err);
    }
  };

  const onFileDelete = async id => {
    try {
      await Api.delete(`/jobworks/${RecordId}/files/${id}`);
      successNotification('File delete');
      dispatch(getJobWorkFiles(RecordId));
    } catch (err) {
      errorNotification(err);
    }
  };

  const onRemove = file => {
    const index = fileList.indexOf(file);
    const newFileList = fileList.slice();
    newFileList.splice(index, 1);
    setFileList(newFileList);
  };

  const beforeUpload = (file, files) => {
    setFileList([...fileList, ...files]);
    return false;
  };

  const columns = useMemo(() => {
    const _columns = [
      {
        title: 'Consignor',
        dataIndex: 'consignor',
        sorter: sorter('consignor'),
        filterDropdown,
        onFilter: onFilter('consignor'),
        width: 120,
      },
      {
        title: 'Job Work #',
        dataIndex: 'jwNo',
        sorter: sorter('jwNo'),
        onFilter: onFilter('jwNo'),
        filterDropdown,
        width: 100,
      },
      {
        title: 'Revision',
        dataIndex: 'revisionNo',
        width: 70,
      },
      {
        title: 'Date',
        dataIndex: 'jwDate',
        sorter: dateSorter('jwDate'),
        onFilter: onDateFilter('jwDate'),
        filterDropdown: dateFilterDropdown,
        render: text => (text ? moment(text).format('DD MMM YYYY') : null),
        width: 80,
      },
      {
        title: 'Party',
        dataIndex: 'party',
        sorter: sorter('party'),
        onFilter: onFilter('party'),
        filterDropdown,
        width: 130,
      },
      {
        title: 'Grand Total',
        dataIndex: 'total',
        sorter: sorter('total'),
        onFilter: onFilter('total'),
        filterDropdown,
        render: text => Number(text).toLocaleString('en-IN'),
        width: 100,
      },
      {
        title: 'Remark',
        dataIndex: 'remark',
        sorter: sorter('remark'),
        onFilter: onFilter('remark'),
        filterDropdown,
        ellipsis: { showTitle: false },
      },
      {
        title: 'Upload Files',
        width: 70,
        render: (_text, record) => (
          <Tooltip placement="bottom" title="Upload Files">
            <CloudUploadOutlined className={classes.tableIcon} onClick={() => showModal(record.id, 'UploadFiles')} />
          </Tooltip>
        ),
      },
      {
        title: 'View Files',
        width: 70,
        render: (_text, record) => {
          let color;
          if (record.files) {
            if (record.files > 0) {
              color = 'Green';
            } else {
              color = 'Red';
            }
          }
          return (
            <Tooltip placement="bottom" title="View Files">
              <CloudDownloadOutlined
                style={{ color }}
                className={classes.tableIcon}
                onClick={() => showModal(record.id, 'ViewFiles')}
              />
            </Tooltip>
          );
        },
      },
      {
        title: 'Edit',
        key: 'edit',
        render: (_text, record) => (
          <Tooltip placement="bottom" title="Edit">
            <Link to={`/jobworks/edit/${record.id}`}>
              <EditOutlined />
            </Link>
          </Tooltip>
        ),
        width: 45,
      },
      {
        title: 'Delete',
        key: 'delete',
        render: (_text, record) => (
          <Tooltip placement="bottom" title="Delete">
            <Popconfirm
              title="Are you sure you want to delete?"
              onConfirm={() => onDelete(record.id)}
              okText="Yes"
              cancelText="No"
            >
              <DeleteOutlined />
            </Popconfirm>
          </Tooltip>
        ),
        width: 65,
      },
      {
        title: 'Detail',
        key: 'Detail',
        render: (_text, record) => (
          <Tooltip placement="bottom" title="Detail">
            <Link to={`/jobworks/view/${record.id}`}>
              <FileTextOutlined />
            </Link>
          </Tooltip>
        ),
        width: 70,
      },
    ];

    return _columns;
  }, []);

  const rowKey = useMemo(() => record => record.id, []);

  const pagination = useMemo(
    () => ({
      defaultPageSize: 20,
      position: ['bottomLeft'],
      showTotal: total => `Total ${total} Records`,
    }),
    []
  );

  const scroll = useMemo(() => ({ x: 1024, y: window.innerHeight - 300 }), []);

  return (
    <Structure>
      <Heading text="Job Work List" />
      <Table
        bordered
        size="small"
        columns={columns}
        dataSource={jobworks}
        rowKey={rowKey}
        pagination={pagination}
        scroll={scroll}
      />
      <Modal
        onOk={hideModal}
        open={FileModal === 'UploadFiles'}
        centered
        maskClosable={false}
        title="Upload Job Work Files."
        width="500px"
        okText="Close"
        cancelButtonProps={{ className: classes.hide }}
        okButtonProps={{ className: classes.button }}
        closable={false}
      >
        <Upload multiple onRemove={onRemove} beforeUpload={beforeUpload} fileList={fileList}>
          <Button icon={<CloudUploadOutlined />}>Select Files</Button>
        </Upload>
        <Button
          type="primary"
          onClick={onUpload}
          disabled={fileList.length === 0}
          loading={uploading}
          style={{ marginTop: 16 }}
        >
          {uploading ? 'Uploading' : 'Upload'}
        </Button>
      </Modal>
      <Modal
        onOk={hideModal}
        open={FileModal === 'ViewFiles'}
        centered
        maskClosable={false}
        title="View Job Work Files."
        width="800px"
        okText="Close"
        cancelButtonProps={{ className: classes.hide }}
        closable={false}
        okButtonProps={{ className: classes.button }}
      >
        <Table
          columns={[
            {
              title: 'File',
              render: (_text, record) => (
                <a href={record.url} rel="noreferrer noopener" target="_blank">
                  <Tooltip placement="bottom" title={`Click to open/download "${record.fileName}" file.`}>
                    {record.fileName}
                  </Tooltip>
                </a>
              ),
            },
            {
              title: 'Delete',
              render: (_text, record) => (
                <Tooltip placement="bottom" title="Delete File">
                  <Popconfirm
                    title="Are you sure you want to delete?"
                    onConfirm={() => onFileDelete(record.id)}
                    okText="Yes"
                    cancelText="No"
                  >
                    <DeleteOutlined className={classes.tableIcon} />
                  </Popconfirm>
                </Tooltip>
              ),
              width: 70,
            },
          ]}
          bordered
          rowKey={record => record.id}
          dataSource={jobworkFiles}
          size="small"
          pagination={{ position: ['bottomLeft'] }}
        />
      </Modal>
    </Structure>
  );
};

JobWorks.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(JobWorks);

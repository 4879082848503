import React, { useCallback, useEffect, useRef, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import withStyles from 'react-jss';
import PropTypes from 'prop-types';
import { Table, Popconfirm, Tooltip, Upload, Button, Modal, DatePicker, Row, Col, Select } from 'antd';
import { Link } from 'react-router-dom';
import {
  CloudDownloadOutlined,
  CloudUploadOutlined,
  DeleteOutlined,
  DownloadOutlined,
  EditOutlined,
  FileTextOutlined,
} from '@ant-design/icons';
import moment from 'moment';
import { useReactToPrint } from 'react-to-print';
import fileDownload from 'js-file-download';
import { reduxForm } from 'redux-form';
import Structure from '../Structure/Structure';
import { sorter, onFilter, filterDropdown, get, dateSorter, dateFilterDropdown, onDateFilter } from '../utils';
import Api from '../Api';
import { getEmployeeList, clearEmployeeList, getEmployeeFilesList, clearEmployeeFilesList } from '../Actions';
import { errorNotification, successNotification, infoNotification, errorNotificationOnBlob } from '../Snackbar';
import styles from '../CommonStyles';
import Heading from '../Components/Heading';
import Advance from './Advance';
import Ledger from './Ledger';
import Record from './Record';
import MonthWiseDetail from './MonthWiseDetail';

const { Option } = Select;

const EmployeeList = props => {
  const { classes } = props;
  const dispatch = useDispatch();

  const printRef = useRef();
  const handlePrint = useReactToPrint({
    content: () => printRef.current,
    pageStyle: '',
  });

  const auth = useSelector(state => state.auth);
  const permissions = get(auth, ['permissions'], []);
  const employeeList = useSelector(state => state.employeeList);
  const employeeFilesList = useSelector(state => state.employeeFilesList);

  const [employeeId, setEmployeeId] = useState(null);
  const [modalType, setModalType] = useState(null);
  const [fileList, setFileList] = useState([]);
  const [uploading, setUploading] = useState(false);

  useEffect(() => {
    dispatch(getEmployeeList({ status: 'Active' }));
    return () => {
      dispatch(clearEmployeeList());
      dispatch(clearEmployeeFilesList());
    };
  }, []);

  useEffect(() => {
    if (employeeId && modalType === 'ViewFiles') {
      dispatch(getEmployeeFilesList(employeeId));
    }
  }, [employeeId, modalType]);

  const handleChange = value => {
    dispatch(clearEmployeeList());
    if (value === 'Active') {
      dispatch(getEmployeeList({ status: 'Active' }));
    }
    if (value === 'Inactive') {
      dispatch(getEmployeeList({ status: 'Inactive' }));
    }
  };

  const showModal = (id, type) => {
    setEmployeeId(id);
    setModalType(type);
  };

  const hideModal = () => {
    setEmployeeId(null);
    setModalType(null);
    setFileList([]);
    dispatch(clearEmployeeFilesList());
  };

  const onDelete = async id => {
    try {
      await Api.delete(`/employees/${id}`);
      successNotification('Employee deleted');
      dispatch(getEmployeeList());
    } catch (err) {
      errorNotification(err);
    }
  };

  const exportEmployeeList = async () => {
    try {
      const { data } = await Api.getFile(`/employees/export`);
      fileDownload(data, `employee-list.csv`);
    } catch (error) {
      errorNotification(error);
    }
  };

  const onUpload = async () => {
    try {
      setUploading(true);
      const formData = new FormData();
      fileList.forEach((file, index) => {
        formData.append(`files[${index}]`, file);
      });
      const { data } = await Api.post(`/employees/${employeeId}/files`, formData, {
        headers: { 'content-type': 'multipart/form-data' },
      });
      infoNotification(data);
      setUploading(false);
      hideModal();
      setFileList([]);
    } catch (err) {
      errorNotification(err);
    }
  };

  const onFileDelete = async id => {
    try {
      await Api.delete(`/employees/${employeeId}/files/${id}`);
      successNotification('File delete');
      dispatch(getEmployeeFilesList(employeeId));
    } catch (err) {
      errorNotification(err);
    }
  };

  const onRemove = file => {
    const index = fileList.indexOf(file);
    const newFileList = fileList.slice();
    newFileList.splice(index, 1);
    setFileList(newFileList);
  };

  const beforeUpload = (file, files) => {
    setFileList([...fileList, ...files]);
    return false;
  };

  const tooltipShow = arg => (
    <Tooltip placement="topLeft" title={arg}>
      {arg}
    </Tooltip>
  );

  const exportData = async id => {
    try {
      const { data } = await Api.getFile(`/attendances/${id}/export`);
      fileDownload(data, `Attendence-${id}.csv`);
    } catch (error) {
      errorNotification(error);
    }
  };

  const columns = [
    {
      title: 'Name',
      dataIndex: 'name',
      filterDropdown,
      sorter: sorter('name'),
      onFilter: onFilter('name'),
      width: '11%',
      fixed: 'left',
    },
    {
      title: 'Aadhar No.',
      dataIndex: 'aadhar',
      sorter: sorter('aadhar'),
      filterDropdown,
      onFilter: onFilter('aadhar'),
      render: tooltipShow,
    },
    {
      title: 'Address',
      dataIndex: 'address',
      sorter: sorter('address'),
      filterDropdown,
      onFilter: onFilter('address'),
      ellipsis: { showTitle: false },
      render: tooltipShow,
    },
    {
      title: 'Mobile',
      dataIndex: 'mobile',
      sorter: sorter('mobile'),
      filterDropdown,
      onFilter: onFilter('mobile'),
      render: tooltipShow,
    },
    {
      title: 'Emergency Name',
      dataIndex: 'emergencyName',
      sorter: sorter('emergencyName'),
      filterDropdown,
      onFilter: onFilter('emergencyName'),
      render: tooltipShow,
    },
    {
      title: 'Emergency Mobile',
      dataIndex: 'emergencyMobile',
      sorter: sorter('emergencyMobile'),
      filterDropdown,
      onFilter: onFilter('emergencyMobile'),
      render: tooltipShow,
    },
    {
      title: 'Joining Date',
      dataIndex: 'joiningDate',
      sorter: dateSorter('joiningDate'),
      filterDropdown: dateFilterDropdown,
      onFilter: onDateFilter('joiningDate'),
      render: joiningDate => (joiningDate ? moment(joiningDate).format('DD MMM YYYY') : null),
    },
    {
      title: 'Salary per  Month',
      dataIndex: 'salary',
      sorter: sorter('salary'),
      filterDropdown,
      onFilter: onFilter('salary'),
    },
    {
      title: 'Role',
      dataIndex: 'role',
      sorter: sorter('role'),
      filterDropdown,
      onFilter: onFilter('role'),
      ellipsis: { showTitle: false },
      render: tooltipShow,
    },
  ];

  const renderActionIcons = record => (
    <div>
      {permissions.includes('edit_employee') ? (
        <>
          <Tooltip placement="bottom" title="Edit">
            <Link to={`/employee/edit/${record.id}`}>
              <EditOutlined className={classes.tableIcon} />
            </Link>
          </Tooltip>
          &nbsp;&nbsp;&nbsp;&nbsp;
        </>
      ) : null}
      {permissions.includes('delete_employee') ? (
        <>
          <Tooltip placement="bottom" title="Delete">
            <Popconfirm
              title="Are you sure you want to delete?"
              onConfirm={() => onDelete(record.id)}
              okText="Yes"
              cancelText="No"
            >
              <DeleteOutlined className={classes.tableIcon} />
            </Popconfirm>
          </Tooltip>
        </>
      ) : null}
    </div>
  );

  if (permissions.includes('edit_employee') || permissions.includes('delete_employee')) {
    columns.push({
      title: 'Actions',
      width: '6%',
      render: renderActionIcons,
    });
  }

  const renderUploadColumn = record => (
    <Tooltip placement="bottom" title="Upload Files">
      <CloudUploadOutlined className={classes.tableIcon} onClick={() => showModal(record.id, 'UploadFiles')} />
    </Tooltip>
  );

  const renderFilesViewColumn = record => {
    let color;
    if (record.files) {
      if (record.files > 0) {
        color = 'Green';
      } else {
        color = 'Red';
      }
    }
    return (
      <Tooltip placement="bottom" title="View Files">
        <CloudDownloadOutlined
          style={{ color }}
          className={classes.tableIcon}
          onClick={() => showModal(record.id, 'ViewFiles')}
        />
      </Tooltip>
    );
  };

  const advance = record => (
    <Tooltip placement="bottom" title="Advance">
      <FileTextOutlined className={classes.tableIcon} onClick={() => showModal(record.id, 'Advance')} />
    </Tooltip>
  );

  const ledger = record => (
    <Tooltip placement="bottom" title="Ledger">
      <FileTextOutlined className={classes.tableIcon} onClick={() => showModal(record.id, 'Ledger')} />
    </Tooltip>
  );

  const details = record => (
    <Tooltip placement="bottom" title="Details">
      <FileTextOutlined className={classes.tableIcon} onClick={() => showModal(record.id, 'Details')} />
    </Tooltip>
  );

  const MonthWiseDetails = record => (
    <Tooltip placement="bottom" title="Month Wise Details">
      <FileTextOutlined className={classes.tableIcon} onClick={() => showModal(record.id, 'MonthWiseDetails')} />
    </Tooltip>
  );

  const downloadLastMonthAttendance = record => (
    <Tooltip placement="bottom" title="Download">
      <DownloadOutlined className={classes.tableIcon} onClick={() => exportData(record.id)} />
    </Tooltip>
  );

  columns.push(
    {
      title: 'Upload Files',
      width: '5%',
      render: renderUploadColumn,
    },
    {
      title: 'View Files',
      width: '4%',
      render: renderFilesViewColumn,
    },
    {
      title: 'Advance',
      width: '6%',
      render: advance,
    },
    {
      title: 'Ledger',
      width: '5%',
      render: ledger,
    },
    {
      title: 'Salary Details',
      width: '5%',
      render: details,
    },
    {
      title: 'Last Month Attendance',
      width: '5%',
      render: downloadLastMonthAttendance,
    },
    {
      title: 'Monthly Salary Record',
      width: '5%',
      render: MonthWiseDetails,
      fixed: 'right',
    }
  );

  const renderFileTableColumn = record => (
    <a href={record.url} rel="noreferrer noopener" target="_blank">
      <Tooltip placement="bottom" title={`Click to open/download "${record.fileName}" file.`}>
        {record.fileName}
      </Tooltip>
    </a>
  );

  const renderFileTableAction = record => {
    return (
      <Tooltip placement="bottom" title="Delete File">
        <Popconfirm
          title="Are you sure you want to delete?"
          onConfirm={() => onFileDelete(record.id)}
          okText="Yes"
          cancelText="No"
        >
          <DeleteOutlined className={classes.tableIcon} />
        </Popconfirm>
      </Tooltip>
    );
  };

  const filesTableColumns = [];

  filesTableColumns.push(
    {
      title: 'File',
      render: renderFileTableColumn,
    },
    {
      title: 'Delete',
      render: renderFileTableAction,
      width: '8%',
    }
  );

  const onFileDownload = useCallback(async date => {
    try {
      const { data } = await Api.getFile(`/employees/download/${moment(date).toISOString()}`);
      fileDownload(data, `salary-${moment(date).toISOString()}.pdf`);
      successNotification('File downloaded');
    } catch (err) {
      errorNotificationOnBlob(err);
    }
  }, []);

  const recalculatePreviousMonthSalaryDetails = useCallback(async () => {
    try {
      const { data } = await Api.get(`/employees/salary/recalculate/month/last`);
      infoNotification(data);
    } catch (err) {
      errorNotification(err);
    }
  }, []);

  return (
    <Structure>
      <>
        <Heading text="Employee List" />
        <Row gutter={[16, 16]}>
          <Col>
            <Select defaultValue="Active" style={{ width: 150 }} onChange={handleChange}>
              <Option key="Active" value="Active">
                Active
              </Option>
              <Option key="Inactive" value="Inactive">
                Inactive
              </Option>
            </Select>
          </Col>
          <Col>
            <Button type="default" onClick={exportEmployeeList}>
              Export Employee List
            </Button>
          </Col>
          <Col>
            <Button type="default" onClick={recalculatePreviousMonthSalaryDetails}>
              Refresh Last Month Salary
            </Button>
          </Col>
          <Col>
            <DatePicker picker="month" onChange={onFileDownload} />
          </Col>
        </Row>
        <br />
        <Table
          columns={columns}
          bordered
          rowKey={record => record.id}
          pagination={{ defaultPageSize: 50, position: ['bottomLeft'], showTotal: total => `Total ${total} Items` }}
          dataSource={employeeList}
          size="small"
          scroll={{ x: 2000, y: window.innerHeight - 300 }}
        />
        <Modal
          onCancel={hideModal}
          open={modalType === 'UploadFiles'}
          centered
          maskClosable={false}
          title="Upload Files."
          width="500px"
          cancelText="Close"
          okButtonProps={{ className: classes.hide }}
          closable={false}
        >
          <Upload multiple onRemove={onRemove} beforeUpload={beforeUpload} fileList={fileList}>
            <Button icon={<CloudUploadOutlined />}>Select Files</Button>
          </Upload>
          <Button
            type="primary"
            onClick={onUpload}
            disabled={fileList.length === 0}
            loading={uploading}
            style={{ marginTop: 16 }}
          >
            {uploading ? 'Uploading' : 'Upload'}
          </Button>
        </Modal>
        <Modal
          onCancel={hideModal}
          open={modalType === 'ViewFiles'}
          centered
          maskClosable={false}
          title="View Files."
          width="800px"
          cancelText="Close"
          closable={false}
          okButtonProps={{ className: classes.hide }}
        >
          <Table
            columns={filesTableColumns}
            bordered
            rowKey={record => record.id}
            dataSource={employeeFilesList}
            size="small"
            pagination={{ position: ['bottomLeft'] }}
          />
        </Modal>
        <Modal
          onCancel={hideModal}
          open={modalType === 'Advance'}
          centered
          maskClosable={false}
          width="1000px"
          cancelText="Close"
          closable={false}
          okButtonProps={{ className: classes.hide }}
        >
          <Advance employeeId={employeeId} />
        </Modal>
        <Modal
          onOk={hideModal}
          onCancel={handlePrint}
          open={modalType === 'Ledger'}
          centered
          maskClosable={false}
          width="1000px"
          okText="Close"
          cancelText="Print"
          closable={false}
          okButtonProps={{ className: classes.button }}
          cancelButtonProps={{ className: classes.button }}
        >
          <Ledger employeeId={employeeId} Reference={printRef} />
        </Modal>
        {employeeId ? (
          <Modal
            onOk={hideModal}
            open={modalType === 'Details'}
            centered
            maskClosable={false}
            width="80%"
            okText="Close"
            closable={false}
            okButtonProps={{ className: classes.button }}
            cancelButtonProps={{ className: classes.hide }}
          >
            <Record employeeId={employeeId} />
          </Modal>
        ) : null}
        {employeeId ? (
          <Modal
            onCancel={hideModal}
            open={modalType === 'MonthWiseDetails'}
            maskClosable={false}
            centered
            width="80%"
            cancelText="Close"
            okButtonProps={{ className: classes.hide }}
            cancelButtonProps={{ className: classes.button }}
          >
            <MonthWiseDetail employeeId={employeeId} />
          </Modal>
        ) : null}
      </>
    </Structure>
  );
};

EmployeeList.propTypes = {
  classes: PropTypes.object.isRequired,
};

const StyledEmployeeList = withStyles(styles)(EmployeeList);

const FormedEmployeeList = reduxForm({
  form: 'EmployeeList',
  enableReinitialize: true,
})(StyledEmployeeList);

export default FormedEmployeeList;

import React, { useEffect, useState } from 'react';
import { useSelector, useDispatch, connect } from 'react-redux';
import withStyles from 'react-jss';
import PropTypes from 'prop-types';
import { Table, Popconfirm, Tooltip, Button, Input, Modal } from 'antd';
import { EditOutlined, DeleteOutlined } from '@ant-design/icons';
import { reduxForm, Field, getFormValues, reset } from 'redux-form';
import Structure from '../Structure/Structure';
import { sorter, onFilter, filterDropdown, get } from '../utils';
import Api from '../Api';
import { getUnitList, clearUnitList, getUnit, clearUnit } from '../Actions';
import { errorNotification, successNotification } from '../Snackbar';
import styles from '../CommonStyles';
import Heading from '../Components/Heading';
import GetField from '../Components/GetField';

const AntInput = GetField(Input);

const UnitList = props => {
  const { classes } = props;
  const dispatch = useDispatch();

  const auth = useSelector(state => state.auth);
  const permissions = get(auth, ['permissions'], []);
  const unitList = useSelector(state => state.unitList);

  const [unitModal, setUnitModal] = useState(false);
  const [confirmLoading, setConfirmLoading] = useState(false);
  const [editUnitId, setEditUnitId] = useState(null);

  const FormValues = useSelector(state => getFormValues('UnitForm')(state));

  useEffect(() => {
    dispatch(getUnitList());
    return () => {
      dispatch(clearUnitList());
    };
  }, []);

  const onDelete = async id => {
    try {
      await Api.delete(`/dropdowns/units/${id}`);
      successNotification('Unit Of Measurement deleted');
      dispatch(getUnitList());
    } catch (err) {
      errorNotification(err);
    }
  };

  const showUnitModal = () => {
    setEditUnitId(null);
    setUnitModal(true);
  };

  const showEditUnitModal = id => {
    setEditUnitId(id);
    dispatch(getUnit(id));
    setUnitModal(true);
  };

  const hideUnitModal = () => {
    dispatch(reset('UnitForm'));
    setEditUnitId(null);
    dispatch(clearUnit());
    setUnitModal(false);
    setConfirmLoading(false);
  };

  const onNew = async () => {
    try {
      setConfirmLoading(true);
      await Api.post('/dropdowns/units', FormValues);
      dispatch(reset('UnitForm'));
      setConfirmLoading(false);
      setUnitModal(false);
      successNotification('Unit Of Measurement added');
      dispatch(clearUnit());
      dispatch(getUnitList());
    } catch (err) {
      setConfirmLoading(false);
      errorNotification(err);
    }
  };

  const onEdit = async () => {
    try {
      setConfirmLoading(true);
      await Api.put(`/dropdowns/units/${editUnitId}`, FormValues);
      dispatch(reset('UnitForm'));
      setUnitModal(false);
      successNotification('Unit Of Measurement updated');
      dispatch(clearUnit());
      dispatch(getUnitList());
    } catch (err) {
      errorNotification(err);
    }
    setConfirmLoading(false);
  };

  const columns = [
    {
      title: 'Unit',
      dataIndex: 'unit',
      sorter: sorter('unit'),
      filterDropdown,
      onFilter: onFilter('unit'),
    },
    {
      title: 'Abbreviation',
      dataIndex: 'abbreviation',
      sorter: sorter('abbreviation'),
      filterDropdown,
      onFilter: onFilter('abbreviation'),
    },
    {
      title: 'Unit Of',
      dataIndex: 'unitOf',
      sorter: sorter('unitOf'),
      filterDropdown,
      onFilter: onFilter('unitOf'),
    },
  ];

  const renderActionIcons = record => (
    <div>
      {permissions.includes('edit_unit_of_measurement') ? (
        <>
          <Tooltip placement="bottom" title="Edit">
            <EditOutlined className={classes.tableIcon} onClick={() => showEditUnitModal(record.id)} />
          </Tooltip>
          &nbsp;&nbsp;&nbsp;&nbsp;
        </>
      ) : null}
      {permissions.includes('delete_unit_of_measurement') ? (
        <>
          <Tooltip placement="bottom" title="Delete">
            <Popconfirm
              title="Are you sure you want to delete?"
              onConfirm={() => onDelete(record.id)}
              okText="Yes"
              cancelText="No"
            >
              <DeleteOutlined className={classes.tableIcon} />
            </Popconfirm>
          </Tooltip>
        </>
      ) : null}
    </div>
  );

  if (permissions.includes('edit_unit_of_measurement') || permissions.includes('delete_unit_of_measurement')) {
    columns.push({
      title: 'Actions',
      width: 70,
      render: renderActionIcons,
    });
  }

  return (
    <Structure>
      <>
        <Heading text="Unit Of Measurement List">
          {permissions.includes('new_unit_of_measurement') ? (
            <Button className={classes.button} onClick={showUnitModal}>
              New Unit Of Measurement
            </Button>
          ) : null}
        </Heading>
        <Table
          columns={columns}
          bordered
          rowKey={record => record.id}
          pagination={{ defaultPageSize: 20, position: ['bottomLeft'], showTotal: total => `Total ${total} Items` }}
          dataSource={unitList}
          size="small"
          scroll={{ x: 1050, y: window.innerHeight - 300 }}
        />
        <Modal
          title={editUnitId ? 'Edit Unit Of Measurement.' : 'New Unit Of Measurement.'}
          confirmLoading={confirmLoading}
          open={unitModal}
          onCancel={hideUnitModal}
          centered
          maskClosable={false}
          closable={false}
          width="400px"
          cancelText="Back"
          okText={
            editUnitId ? (
              <Popconfirm
                title="Sure want to submit form?"
                onConfirm={() => onEdit()}
                okText="Confirm"
                cancelText="Cancel"
              >
                Submit
              </Popconfirm>
            ) : (
              <Popconfirm
                title="Sure want to submit form?"
                onConfirm={() => onNew()}
                okText="Confirm"
                cancelText="Cancel"
              >
                Submit
              </Popconfirm>
            )
          }
          okButtonProps={{ className: classes.button }}
        >
          <Field label="Unit" required allowClear name="unit" placeholder="Unit" component={AntInput} />
          <Field
            label="Abbreviation"
            required
            allowClear
            name="abbreviation"
            placeholder="Abbreviation"
            component={AntInput}
          />
          <Field label="Unit Of" required allowClear name="unitOf" placeholder="Unit Of" component={AntInput} />
        </Modal>
      </>
    </Structure>
  );
};

UnitList.propTypes = {
  classes: PropTypes.object.isRequired,
};

const StyledUnitList = withStyles(styles)(UnitList);

const FormedUnitList = reduxForm({
  form: 'UnitForm',
  enableReinitialize: true,
})(StyledUnitList);

export default connect(({ unit }) => ({ initialValues: unit }), {})(FormedUnitList);

export const leads = (state = [], action) => {
  if (action.type === 'FETCH_LEADS') {
    return action.payload;
  }
  if (action.type === 'CLEAR_LEADS') {
    return [];
  }
  return state;
};

export const lead = (state = {}, action) => {
  if (action.type === 'FETCH_LEAD') {
    return action.payload;
  }
  if (action.type === 'CLEAR_LEAD') {
    return {};
  }
  return state;
};

export const leadIds = (state = [], action) => {
  if (action.type === 'FETCH_LEAD_IDS') {
    return action.payload;
  }
  if (action.type === 'CLEAR_LEAD_IDS') {
    return [];
  }
  return state;
};

export const interactions = (state = [], action) => {
  if (action.type === 'FETCH_INTERACTIONS') {
    return action.payload;
  }
  if (action.type === 'CLEAR_INTERACTIONS') {
    return [];
  }
  return state;
};

export const queryIds = (state = [], action) => {
  if (action.type === 'FETCH_LEAD_QUERIES') {
    return action.payload;
  }
  if (action.type === 'CLEAR_LEAD_QUERIES') {
    return [];
  }
  return state;
};

export const query = (state = {}, action) => {
  if (action.type === 'FETCH_LEAD_QUERY') {
    return action.payload;
  }
  if (action.type === 'CLEAR_LEAD_QUERY') {
    return {};
  }
  return state;
};

export const queryFiles = (state = [], action) => {
  if (action.type === 'FETCH_QUERY_FILES_LIST') {
    return action.payload;
  }
  if (action.type === 'CLEAR_QUERY_FILES_LIST') {
    return [];
  }
  return state;
};

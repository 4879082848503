import React from 'react';
import PropTypes from 'prop-types';
import { Controller } from 'react-hook-form';
import { DeleteOutlined } from '@ant-design/icons';
import { Input, Select } from 'antd';
import withStyles from 'react-jss';
import { useSelector } from 'react-redux';

import styles from '../CommonStyles';
import GetField from '../Components/GetRHFField';

const AntInput = GetField(Input);
const AntSelect = GetField(Select);
const { Option } = Select;

const ChargeTable = props => {
  const { classes, control, index, chargeRemove } = props;

  const chargeIds = useSelector(state => state.chargeIds);

  return (
    <tr>
      <td
        className={classes.inputTd}
        style={{
          textAlign: 'center',
        }}
      >
        <DeleteOutlined
          onClick={() => {
            chargeRemove(index);
          }}
        />
      </td>
      <td className={classes.inputTd}>
        <Controller
          name={`charges[${index}].chargeId`}
          control={control}
          render={({ field }) => (
            <AntSelect placeholder="Select charge" allowClear {...field}>
              {chargeIds.map(charge => (
                <Option key={charge.id} value={charge.id}>
                  {charge.name}
                </Option>
              ))}
            </AntSelect>
          )}
        />
      </td>
      <td className={classes.inputTd}>
        <Controller
          name={`charges[${index}].value`}
          control={control}
          render={({ field }) => <AntInput type="number" min={0} {...field} />}
        />
      </td>
      <td className={classes.inputTd}>
        <Controller
          name={`charges[${index}].chargeId`}
          control={control}
          render={({ field }) => (
            <AntSelect placeholder="Select charge" allowClear {...field} disabled>
              {chargeIds.map(charge => (
                <Option key={charge.id} value={charge.id}>
                  {charge.hsn}
                </Option>
              ))}
            </AntSelect>
          )}
        />
      </td>
      <td className={classes.inputTd}>
        <Controller
          name={`charges[${index}].chargeId`}
          control={control}
          render={({ field }) => (
            <AntSelect placeholder="Select charge" allowClear {...field} disabled>
              {chargeIds.map(charge => (
                <Option key={charge.id} value={charge.id}>
                  {charge.gst}
                </Option>
              ))}
            </AntSelect>
          )}
        />
      </td>
    </tr>
  );
};

ChargeTable.propTypes = {
  classes: PropTypes.object.isRequired,
  control: PropTypes.any.isRequired,
  item: PropTypes.object.isRequired,
  index: PropTypes.number.isRequired,
  chargeRemove: PropTypes.func.isRequired,
};

export default withStyles(styles)(ChargeTable);

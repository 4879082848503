import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import withStyles from 'react-jss';
import { Button, Card, Col, Row, Table, Tooltip } from 'antd';
import { useDispatch, useSelector } from 'react-redux';
import fileDownload from 'js-file-download';
import styles from '../CommonStyles';
import Structure from '../Structure/Structure';
import {
  getOrderUsersReport,
  clearOrderUsersReport,
  getOrderReport,
  clearOrderReport,
  getPaymentCategoryReport,
  clearPaymentCategoryReport,
} from '../Actions';
import { get } from '../utils';
import Api from '../Api';
import { errorNotification } from '../Snackbar';

const TeamReport = props => {
  const { classes } = props;
  const dispatch = useDispatch();

  const orderReport = useSelector(state => state.orderReport);
  const orderUsersReport = useSelector(state => state.orderUsersReport);
  const paymentCategoryReport = useSelector(state => state.paymentCategoryReport);

  useEffect(() => {
    dispatch(getOrderReport());
    dispatch(getOrderUsersReport());
    dispatch(getPaymentCategoryReport());
    return () => {
      dispatch(clearOrderUsersReport());
      dispatch(clearOrderReport());
      dispatch(clearPaymentCategoryReport());
    };
  }, []);

  const exportThroughputData = async () => {
    try {
      const { data } = await Api.getFile(`/orders/reports/throughput/export`);
      fileDownload(data, `Throughput-details.csv`);
    } catch (error) {
      errorNotification(error);
    }
  };

  const paymentCategoryTableColumns = [
    {
      title: 'Category',
      dataIndex: 'category',
    },
    {
      title: 'Number of Active Customer in Category',
      dataIndex: 'customers',
    },
    {
      title: 'Number of Un-Authorised Order',
      dataIndex: 'unAuthorizedOrders',
    },
    {
      title: 'Number of Authorised Order',
      dataIndex: 'authorizedOrders',
    },
    {
      title: 'Authorised Order Amount',
      dataIndex: 'authorizedOrdersAmount',
      render: text => Number(text).toLocaleString('en-IN'),
    },
    {
      title: 'Throughput Deficit ',
      dataIndex: 'throughputDeficitAmount',
      render: text => Number(text).toLocaleString('en-IN'),
    },
  ];

  const throughputDeficitFormulationsTableColumns = [
    {
      title: 'Formulation',
      dataIndex: 'name',
      render: (_name, record) => record,
    },
  ];

  const sectionWiseTableColumns = [
    {
      title: 'Section',
      dataIndex: 'section',
    },
    {
      title: 'Total Orders',
      dataIndex: 'value',
      align: 'right',
    },
  ];

  const BrandsForWhichRawMaterialNotOrderedTableColumns = [
    {
      title: 'Order Number',
      dataIndex: 'id',
    },
    {
      title: 'Formulation',
      dataIndex: 'formulation',
    },
  ];

  const BrandsForWhichPrintedPackagingNotOrderedTableColumns = [
    {
      title: 'Order Number',
      dataIndex: 'id',
    },
    {
      title: 'Formulation',
      dataIndex: 'formulation',
    },
  ];

  const BrandsForWhichPackingMaterialNotOrderedTableColumns = [
    {
      title: 'Order Number',
      dataIndex: 'id',
    },
    {
      title: 'Formulation',
      dataIndex: 'formulation',
    },
  ];

  const exportThroughputButton = () => {
    return (
      <>
        <Tooltip
          title={`Throughput Deficit Brands (${get(orderReport, ['throughputDeficitFormulations'], []).length})`}
        >
          Throughput Deficit Brands ({get(orderReport, ['throughputDeficitFormulations'], []).length})
        </Tooltip>
        &nbsp;&nbsp;
        <Button type="default" onClick={exportThroughputData}>
          Export
        </Button>
      </>
    );
  };

  return (
    <Structure>
      <>
        <Card size="default" title="Order">
          <Row gutter={[8, 8]}>
            <Col xs={24} lg={24}>
              <Row gutter={[8, 8]}>
                <Col xs={24} lg={24}>
                  <Card size="small">
                    <Table
                      columns={paymentCategoryTableColumns}
                      bordered
                      rowKey={record => record}
                      pagination={{
                        defaultPageSize: 10,
                        position: ['bottomLeft'],
                      }}
                      dataSource={paymentCategoryReport || []}
                      size="small"
                      scroll={{ x: 425, y: 400 }}
                    />
                  </Card>
                </Col>
              </Row>
              <Row gutter={[8, 8]}>
                <Col xs={24} lg={12}>
                  <Row gutter={[8, 8]}>
                    <Col xs={24} lg={12}>
                      <Card size="small" title="Orders Not Authorised">
                        <span className={classes.dashboardNumberFont}>
                          {get(orderUsersReport, ['notAuthorized'], '--')}
                        </span>
                      </Card>
                    </Col>
                    <Col xs={24} lg={12}>
                      <Card size="small" title={<Tooltip title="Throughput Deficit">Throughput Deficit</Tooltip>}>
                        <span className={classes.dashboardNumberFont}>
                          {Number(get(orderReport, ['throughputDeficit'], 0)).toLocaleString('en-IN')}
                        </span>
                      </Card>
                    </Col>
                  </Row>
                  <Row gutter={[8, 8]}>
                    <Col xs={24} lg={24}>
                      <Card
                        size="small"
                        title={`Orders Ready to Dispatch - Section Wise (${
                          get(orderUsersReport, ['sectionWiseOrders'], []).length
                        })`}
                      >
                        <Table
                          columns={sectionWiseTableColumns}
                          bordered
                          rowKey={record => record.section}
                          dataSource={get(orderUsersReport, ['sectionWiseOrders'], [])}
                          size="small"
                          pagination={{
                            defaultPageSize: 10,
                            position: ['bottomLeft'],
                          }}
                          scroll={{ x: 425, y: 400 }}
                        />
                      </Card>
                    </Col>
                  </Row>
                </Col>
                <Col xs={24} lg={12}>
                  <Card size="small" title={exportThroughputButton()}>
                    <Table
                      columns={throughputDeficitFormulationsTableColumns}
                      bordered
                      rowKey={record => record}
                      dataSource={get(orderReport, ['throughputDeficitFormulations'], [])}
                      size="small"
                      pagination={{
                        defaultPageSize: 10,
                        position: ['bottomLeft'],
                      }}
                      scroll={{ x: 425, y: 400 }}
                    />
                  </Card>
                </Col>
              </Row>
            </Col>
          </Row>
          <Row gutter={[8, 8]}>
            <Col xs={24} lg={8}>
              <Card
                size="small"
                title={`Orders/Brands for which Raw Material - Not Ordered (${
                  get(orderUsersReport, ['rawMaterialOrders'], []).length
                })`}
              >
                <Table
                  columns={BrandsForWhichRawMaterialNotOrderedTableColumns}
                  bordered
                  rowKey={record => record.id}
                  dataSource={get(orderUsersReport, ['rawMaterialOrders'], [])}
                  size="small"
                  pagination={{
                    defaultPageSize: 20,
                    position: ['bottomLeft'],
                  }}
                  scroll={{ x: 425 }}
                />
              </Card>
            </Col>
            <Col xs={24} lg={8}>
              <Card
                size="small"
                title={`Orders/Brands for which Packing Material - Not Ordered (${
                  get(orderUsersReport, ['packingMaterialOrders'], []).length
                })`}
              >
                <Table
                  columns={BrandsForWhichPackingMaterialNotOrderedTableColumns}
                  bordered
                  rowKey={record => record.id}
                  dataSource={get(orderUsersReport, ['packingMaterialOrders'], [])}
                  size="small"
                  pagination={{
                    defaultPageSize: 20,
                    position: ['bottomLeft'],
                  }}
                  scroll={{ x: 425 }}
                />
              </Card>
            </Col>
            <Col xs={24} lg={8}>
              <Card
                size="small"
                title={`Orders/Brands for which Printed Packaging - Not Ordered (${
                  get(orderUsersReport, ['printedPackagingMaterialOrders'], []).length
                })`}
              >
                <Table
                  columns={BrandsForWhichPrintedPackagingNotOrderedTableColumns}
                  bordered
                  rowKey={record => record.id}
                  dataSource={get(orderUsersReport, ['printedPackagingMaterialOrders'], [])}
                  size="small"
                  pagination={{
                    defaultPageSize: 20,
                    position: ['bottomLeft'],
                  }}
                  scroll={{ x: 425 }}
                />
              </Card>
            </Col>
          </Row>
        </Card>
      </>
    </Structure>
  );
};

TeamReport.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(TeamReport);

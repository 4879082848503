import Api from '../Api';

export const getLeaveList = () => async dispatch => {
  try {
    const { data } = await Api.get('/leave-request');
    dispatch({
      type: 'FETCH_LEAVE_LIST',
      payload: data,
    });
  } catch (err) {
    throw err;
  }
};

export const clearLeaveList = () => {
  return {
    type: 'CLEAR_LEAVE_LIST',
  };
};

export const getLeave = id => async dispatch => {
  try {
    const { data } = await Api.get(`/leave-request/${id}`);
    dispatch({
      type: 'FETCH_LEAVE',
      payload: data,
    });
  } catch (err) {
    throw err;
  }
};

export const clearLeave = () => {
  return {
    type: 'CLEAR_LEAVE',
  };
};

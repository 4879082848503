/* eslint-disable no-nested-ternary */
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import PropTypes from 'prop-types';
import withStyles from 'react-jss';
import { useDispatch, useSelector } from 'react-redux';
import {
  Button,
  Checkbox,
  Col,
  DatePicker,
  Input,
  Modal,
  Row,
  Select,
  Tooltip,
  TimePicker,
  Table,
  Popconfirm,
} from 'antd';
import { useForm, Controller } from 'react-hook-form';
import moment from 'moment';
import startCase from 'lodash/startCase';

import { DeleteOutlined, EditOutlined, PlusOutlined } from '@ant-design/icons';
import styles from '../../CommonStyles';
import Structure from '../../Structure/Structure';
import Heading from '../../Components/Heading';
import { dateFilterDropdown, dateSorter, get, onDateFilter, onFilter, sorter, filterDropdown } from '../../utils';
import Api from '../../Api';
import { errorNotification, infoNotification, successNotification } from '../../Snackbar';
import GetRHFField from '../../Components/GetRHFField';
import { clearKRA, clearKRAList, clearUserList, getKRA, getKRAList, getUserList } from '../../Actions';
import constants from '../../constants';

const AntInput = GetRHFField(Input);
const AntDatePicker = GetRHFField(DatePicker);
const AntTimePicker = GetRHFField(TimePicker);
const AntCheckBoxGroup = GetRHFField(Checkbox.Group);

const AntSelect = GetRHFField(Select);
const { Option } = Select;

const KRAList = props => {
  const { classes } = props;

  const dispatch = useDispatch();

  const { handleSubmit, control, setValue, reset, watch, getValues } = useForm({
    shouldUnregister: false,
  });

  const auth = useSelector(state => state.auth);
  const permissions = get(auth, ['permissions'], []);

  const userList = useSelector(state => state.userList);
  const kraList = useSelector(state => state.kraList);
  const kra = useSelector(state => state.kra);

  const [modalView, setModalView] = useState(false);
  const [confirmLoading, setConfirmLoading] = useState(false);
  const [recordId, setRecordId] = useState(null);

  useEffect(() => {
    dispatch(getUserList());
    dispatch(getKRAList());
    return () => {
      dispatch(clearUserList());
      dispatch(clearKRAList());
      dispatch(clearKRA());
    };
  }, []);

  useEffect(() => {
    reset({
      ...kra,
      taskDuration:
        kra.taskDuration > 1440
          ? (+kra.taskDuration / 1440).toFixed(1)
          : kra.taskDuration > 60
          ? (+kra.taskDuration / 60).toFixed(1)
          : kra.taskDuration,
      durationType: kra.taskDuration > 1440 ? 'days' : kra.taskDuration > 60 ? 'hours' : 'minutes',
      monthlyRepeatType: (kra.repeatCycleMonthDates || []).length
        ? 'dates'
        : (kra.repeatCycleMonthDays || []).length
        ? 'days'
        : null,
      taskStartsAt: kra.taskStartsAt
        ? moment()
            .hours(0)
            .minutes(0)
            .seconds(0)
            .milliseconds(0)
            .add(kra.taskStartsAt.slice(0, 2), 'hours')
            .add(kra.taskStartsAt.slice(3, 5), 'minutes')
        : moment().hours(9).minutes(0).seconds(0).milliseconds(0),
    });
  }, [kra]);

  const onDelete = async id => {
    try {
      await Api.delete(`/tasks/kras/${id}`);
      successNotification('KRA deleted');
      dispatch(getKRAList());
    } catch (err) {
      errorNotification(err);
    }
  };

  const showModal = () => {
    setRecordId(null);
    setModalView(true);
  };

  const showEditModal = useCallback(id => {
    setRecordId(id);
    dispatch(getKRA(id));
    setModalView(true);
  }, []);

  const hideModal = () => {
    reset();
    setRecordId(null);
    setModalView(false);
    setConfirmLoading(false);
    dispatch(clearKRA());
  };

  const updateColumn = useCallback(
    (_text, record) =>
      permissions.includes('todo') ? (
        <Tooltip placement="bottom" title="Edit">
          <EditOutlined className={classes.tableIcon} onClick={() => showEditModal(record.id)} />
        </Tooltip>
      ) : null,
    []
  );

  const deleteColumn = useCallback(
    (_text, record) =>
      permissions.includes('todo') ? (
        <Tooltip placement="bottom" title="Delete">
          <Popconfirm
            title="Are you sure you want to delete?"
            onConfirm={() => onDelete(record.id)}
            okText="Yes"
            cancelText="No"
          >
            <DeleteOutlined className={classes.tableIcon} />
          </Popconfirm>
        </Tooltip>
      ) : null,
    []
  );

  const disabledDate = useCallback(current => {
    return (
      current && (current < moment().subtract(1, 'day').endOf('day') || current > moment().add(364, 'day').endOf('day'))
    );
  }, []);

  const onSubmit = useCallback(async () => {
    try {
      const formValues = getValues();

      setConfirmLoading(true);
      await Api.post('/tasks/kras', {
        ...formValues,
        taskDuration:
          formValues.durationType === 'days'
            ? +formValues.taskDuration * 24 * 60
            : formValues.durationType === 'hours'
            ? +formValues.taskDuration * 60
            : formValues.taskDuration,
        taskStartsAt: moment(formValues.taskStartsAt).format('HH:mm'),
      });
      hideModal();
      infoNotification('KRA tasks will be added in calendar');
      dispatch(getKRAList());
    } catch (err) {
      setConfirmLoading(false);
      errorNotification(err);
    }
  }, []);

  const onEdit = useCallback(async () => {
    try {
      const formValues = getValues();

      setConfirmLoading(true);
      await Api.put(`/tasks/kras/${recordId}`, {
        ...formValues,
        taskDuration:
          formValues.durationType === 'days'
            ? +formValues.taskDuration * 24 * 60
            : formValues.durationType === 'hours'
            ? +formValues.taskDuration * 60
            : formValues.taskDuration,
        taskStartsAt: moment(formValues.taskStartsAt).format('HH:mm'),
        updateExisting: true,
      });
      hideModal();
      infoNotification('KRA tasks will be updated in calendar');
      dispatch(getKRAList());
    } catch (err) {
      setConfirmLoading(false);
      errorNotification(err);
    }
  }, [recordId]);

  const rowKey = useCallback(record => record.id, []);

  const pagination = useCallback(
    {
      defaultPageSize: 20,
      position: ['bottomLeft'],
      showTotal: total => `Total ${total} Records`,
    },
    []
  );

  const scroll = useCallback({ x: 1400, y: window.innerHeight - 300 }, []);

  const columns = useMemo(() => {
    const _columns = [
      {
        title: 'Assignee To',
        dataIndex: 'assigneeTo',
        sorter: sorter('assigneeTo'),
        onFilter: onFilter('assigneeTo'),
        filterDropdown,
      },
      {
        title: 'Name',
        dataIndex: 'name',
        sorter: sorter('name'),
        onFilter: onFilter('name'),
        filterDropdown,
      },
      {
        title: 'Task',
        dataIndex: 'taskName',
        sorter: sorter('taskName'),
        onFilter: onFilter('taskName'),
        filterDropdown,
      },
      {
        title: 'Description',
        dataIndex: 'taskDescription',
        sorter: sorter('taskDescription'),
        onFilter: onFilter('taskDescription'),
        filterDropdown,
      },
      {
        title: 'Remark',
        dataIndex: 'taskRemark',
        sorter: sorter('taskRemark'),
        onFilter: onFilter('taskRemark'),
        filterDropdown,
      },
      {
        title: 'Repeat Cycle',
        dataIndex: 'repeatCycle',
        sorter: sorter('repeatCycle'),
        onFilter: onFilter('repeatCycle'),
        filterDropdown,
        render: text => startCase(text),
      },
      {
        title: 'Start Date',
        dataIndex: 'createdAt',
        sorter: dateSorter('createdAt'),
        onFilter: onDateFilter('createdAt'),
        filterDropdown: dateFilterDropdown,
        render: createdAt => (createdAt ? moment(createdAt).format('DD MMM YYYY') : null),
      },
      {
        title: 'Task Time',
        dataIndex: 'taskStartsAt',
        sorter: sorter('taskStartsAt'),
        onFilter: onFilter('taskStartsAt'),
        filterDropdown,
      },
      {
        title: 'Duration',
        dataIndex: 'taskDuration',
        sorter: sorter('taskDuration'),
        onFilter: onFilter('taskDuration'),
        filterDropdown,
        render: text =>
          text > 1440
            ? `${+text / 1440} ${+text / 1440 === 1 ? 'Day' : 'Days'}`
            : text > 60
            ? `${+text / 60} ${+text / 60 === 1 ? 'Hour' : 'Hours'}`
            : `${text} ${text === 1 ? 'Minute' : 'Minutes'}`,
      },
      {
        title: 'Repeat Till',
        dataIndex: 'repeatTill',
        sorter: dateSorter('repeatTill'),
        onFilter: onDateFilter('repeatTill'),
        filterDropdown: dateFilterDropdown,
        render: repeatTill => (repeatTill ? moment(repeatTill).format('DD MMM YYYY') : null),
      },
    ];

    if (permissions.includes('todo')) {
      _columns.push({
        title: 'Update',
        width: '7%',
        render: updateColumn,
      });
    }

    if (permissions.includes('todo')) {
      _columns.push({
        title: 'Delete',
        width: '6%',
        render: deleteColumn,
      });
    }

    return _columns;
  }, []);

  return (
    <Structure>
      <>
        <Heading text="KRA">
          {permissions.includes('todo') ? (
            <Button className={classes.button} onClick={showModal}>
              <PlusOutlined /> KRA
            </Button>
          ) : null}
        </Heading>
        <Table
          bordered
          size="small"
          columns={columns}
          dataSource={kraList}
          rowKey={rowKey}
          pagination={pagination}
          scroll={scroll}
        />
        <Modal
          title="KRA Form"
          confirmLoading={confirmLoading}
          open={modalView}
          onCancel={hideModal}
          centered
          maskClosable={false}
          cancelText="Back"
          okText={
            <Popconfirm
              title="Sure want to submit form?"
              onConfirm={() => (recordId ? onEdit() : onSubmit())}
              okText="Confirm"
              cancelText="Cancel"
            >
              Submit
            </Popconfirm>
          }
        >
          <form onSubmit={handleSubmit(onSubmit)}>
            <Row>
              <Col span={24}>
                <Controller
                  name="name"
                  control={control}
                  render={({ field }) => <AntInput allowClear required placeholder="KRA" {...field} />}
                />
              </Col>
            </Row>
            <Row>
              <Col span={24}>
                <Controller
                  name="remark"
                  control={control}
                  render={({ field }) => (
                    <AntInput
                      allowClear
                      required
                      placeholder="Remark"
                      {...field}
                      autoSize={{ minRows: 1, maxRows: 6 }}
                    />
                  )}
                />
              </Col>
            </Row>
            {/* <Row>
              <Col span={24}>
                <Controller
                  name="taskName"
                  control={control}
                  render={({ field }) => <AntInput allowClear required placeholder="Title" {...field} />}
                />
              </Col>
            </Row> */}
            {/* <Row>
              <Col span={24}>
                <Controller
                  name="taskDescription"
                  control={control}
                  render={({ field }) => <AntInput allowClear required placeholder="Description" {...field} />}
                />
              </Col>
            </Row> */}
            {/* <Row>
              <Col span={24}>
                <Controller
                  name="taskRemark"
                  control={control}
                  render={({ field }) => <AntInput allowClear required placeholder="Remark" {...field} />}
                />
              </Col>
            </Row> */}
            <Row>
              <Col span={24}>
                <Controller
                  name="assigneeId"
                  control={control}
                  render={({ field }) => (
                    <AntSelect allowClear required placeholder="Select user" {...field}>
                      {userList
                        // .filter(user => user.id !== auth.user.id)
                        .map(user => (
                          <Option key={user.id} value={user.id}>
                            {user.name}
                          </Option>
                        ))}
                    </AntSelect>
                  )}
                />
              </Col>
            </Row>
            <Row>
              <Col span={24}>
                <Controller
                  name="repeatCycle"
                  control={control}
                  render={({ field }) => (
                    <AntSelect
                      allowClear
                      required
                      placeholder="Select repeat"
                      {...field}
                      onChange={value => {
                        field.onChange(value);
                        setValue('repeatCycleWeeklyDays', null);
                        setValue('repeatCycleMonths', null);
                        setValue('repeatCycleMonthDates', null);
                        setValue('repeatCycleMonthDays', null);
                      }}
                    >
                      {Object.entries(constants.tasks.kras.repeatCycles).map(([key, value]) => (
                        <Option key={key} value={value}>
                          {startCase(value)}
                        </Option>
                      ))}
                    </AntSelect>
                  )}
                />
              </Col>
            </Row>
            {watch('repeatCycle') === constants.tasks.kras.repeatCycles.weekly ? (
              <Controller
                name="repeatCycleWeeklyDays"
                control={control}
                render={({ field }) => (
                  <AntCheckBoxGroup {...field} componentStyle={{ width: '100%' }}>
                    {[0].map(multiplier => (
                      <>
                        <h4>Week</h4>
                        <Row key={multiplier}>
                          {[
                            [1, 'Sun', 'Sunday'],
                            [2, 'Mon', 'Monday'],
                            [3, 'Tue', 'Tuesday'],
                            [4, 'Wed', 'Wednesday'],
                            [5, 'Thu', 'Thursday'],
                            [6, 'Fri', 'Friday'],
                            [7, 'Sat', 'Saturday'],
                          ].map(([day, label, name]) => (
                            <Col span={3} key={day}>
                              <Tooltip title={`${name}`} placement="bottom">
                                <Checkbox value={multiplier * 7 + day - 1}>{label}</Checkbox>
                              </Tooltip>
                            </Col>
                          ))}
                        </Row>
                      </>
                    ))}
                  </AntCheckBoxGroup>
                )}
              />
            ) : null}
            {watch('repeatCycle') === constants.tasks.kras.repeatCycles.biWeekly ? (
              <Controller
                name="repeatCycleWeeklyDays"
                control={control}
                render={({ field }) => (
                  <AntCheckBoxGroup {...field} componentStyle={{ width: '100%' }}>
                    {[0, 1].map(multiplier => (
                      <>
                        <h4>{multiplier === 0 ? 'First' : 'Second'}</h4>
                        <Row key={multiplier}>
                          {[
                            [1, 'Sun', 'Sunday'],
                            [2, 'Mon', 'Monday'],
                            [3, 'Tue', 'Tuesday'],
                            [4, 'Wed', 'Wednesday'],
                            [5, 'Thu', 'Thursday'],
                            [6, 'Fri', 'Friday'],
                            [7, 'Sat', 'Saturday'],
                          ].map(([day, label, name]) => (
                            <Col span={3} key={day}>
                              <Tooltip title={`${name}`} placement="bottom">
                                <Checkbox value={multiplier * 7 + day - 1}>{label}</Checkbox>
                              </Tooltip>
                            </Col>
                          ))}
                        </Row>
                      </>
                    ))}
                  </AntCheckBoxGroup>
                )}
              />
            ) : null}
            {watch('repeatCycle') === constants.tasks.kras.repeatCycles.customMonths ? (
              <Controller
                name="repeatCycleMonths"
                control={control}
                render={({ field }) => (
                  <AntCheckBoxGroup {...field} componentStyle={{ width: '100%' }}>
                    {[0].map(multiplier => (
                      <Row key={multiplier}>
                        {[
                          [1, 'Jan', 'January'],
                          [2, 'Feb', 'February'],
                          [3, 'Mar', 'March'],
                          [4, 'Apr', 'April'],
                          [5, 'May', 'May'],
                          [6, 'Jun', 'June'],
                          [7, 'Jul', 'July'],
                          [8, 'Aug', 'August'],
                          [9, 'Sep', 'September'],
                          [10, 'Oct', 'October'],
                          [11, 'Nov', 'November'],
                          [12, 'Dec', 'December'],
                        ].map(([day, label]) => (
                          <Col span={2} key={day}>
                            <Checkbox value={multiplier * 1 + day}>{label}</Checkbox>
                          </Col>
                        ))}
                      </Row>
                    ))}
                  </AntCheckBoxGroup>
                )}
              />
            ) : null}
            {watch('repeatCycle') === constants.tasks.kras.repeatCycles.monthly ||
            watch('repeatCycle') === constants.tasks.kras.repeatCycles.customMonths ? (
              <Row>
                <Col span={24}>
                  <Controller
                    name="monthlyRepeatType"
                    control={control}
                    render={({ field }) => (
                      <AntSelect allowClear placeholder="Select repeat type" {...field}>
                        <Option key="dates" value="dates">
                          Dates of month
                        </Option>
                        <Option key="days" value="days">
                          Days of month
                        </Option>
                      </AntSelect>
                    )}
                  />
                </Col>
              </Row>
            ) : null}
            {(watch('repeatCycle') === constants.tasks.kras.repeatCycles.monthly ||
              watch('repeatCycle') === constants.tasks.kras.repeatCycles.customMonths) &&
            watch('monthlyRepeatType') === 'dates' ? (
              <Controller
                name="repeatCycleMonthDates"
                control={control}
                render={({ field }) => (
                  <AntCheckBoxGroup {...field} componentStyle={{ width: '100%' }}>
                    {[0, 1, 2, 3, 4].map(multiplier => (
                      <>
                        <Row key={multiplier}>
                          {[
                            [1, '1'],
                            [2, '2'],
                            [3, '3'],
                            [4, '4'],
                            [5, '5'],
                            [6, '6'],
                            [7, '7'],
                          ].map(([day]) => {
                            if (multiplier * 7 + day < 32) {
                              return (
                                <Col span={3} key={day}>
                                  <Checkbox value={multiplier * 7 + day}>{multiplier * 7 + day}</Checkbox>
                                </Col>
                              );
                            }
                          })}
                        </Row>
                      </>
                    ))}
                  </AntCheckBoxGroup>
                )}
              />
            ) : null}
            {(watch('repeatCycle') === constants.tasks.kras.repeatCycles.monthly ||
              watch('repeatCycle') === constants.tasks.kras.repeatCycles.customMonths) &&
            watch('monthlyRepeatType') === 'days' ? (
              <Controller
                name="repeatCycleMonthDays"
                control={control}
                render={({ field }) => (
                  <AntCheckBoxGroup {...field} componentStyle={{ width: '100%' }}>
                    {[0, 1, 2, 3, 4].map(multiplier => (
                      <>
                        <h4>
                          {multiplier === 0
                            ? 'First'
                            : multiplier === 1
                            ? 'Second'
                            : multiplier === 2
                            ? 'Third'
                            : multiplier === 3
                            ? 'Fourth'
                            : 'Last'}
                        </h4>
                        <Row key={multiplier}>
                          {[
                            [1, 'Sun', 'Sunday'],
                            [2, 'Mon', 'Monday'],
                            [3, 'Tue', 'Tuesday'],
                            [4, 'Wed', 'Wednesday'],
                            [5, 'Thu', 'Thursday'],
                            [6, 'Fri', 'Friday'],
                            [7, 'Sat', 'Saturday'],
                          ].map(([day, label, name]) => (
                            <Col span={3} key={day}>
                              <Tooltip title={`${name}`} placement="bottom">
                                <Checkbox value={multiplier * 7 + day - 1}>{label}</Checkbox>
                              </Tooltip>
                            </Col>
                          ))}
                        </Row>
                      </>
                    ))}
                  </AntCheckBoxGroup>
                )}
              />
            ) : null}
            <Row>
              <Col span={24}>
                <Controller
                  name="taskStartsAt"
                  control={control}
                  render={({ field }) => (
                    <AntTimePicker
                      allowClear
                      showTime={{ format: 'hh:mm A' }}
                      format="hh:mm A"
                      placeholder="Task start time"
                      {...field}
                    />
                  )}
                />
              </Col>
            </Row>
            <Row>
              <Col span={12}>
                <Controller
                  name="durationType"
                  control={control}
                  render={({ field }) => (
                    <AntSelect allowClear required placeholder="Select duration type" {...field}>
                      <Option key="minutes" value="minutes">
                        Minutes
                      </Option>
                      <Option key="hours" value="hours">
                        Hours
                      </Option>
                      <Option key="days" value="days">
                        Days
                      </Option>
                    </AntSelect>
                  )}
                />
              </Col>
              <Col span={12}>
                <Controller
                  name="taskDuration"
                  control={control}
                  render={({ field }) => (
                    <AntInput allowClear required type="number" min={1} placeholder="Task duration" {...field} />
                  )}
                />
              </Col>
            </Row>
            <Row>
              <Col span={24}>
                <Controller
                  name="repeatTill"
                  control={control}
                  render={({ field }) => (
                    <AntDatePicker allowClear disabledDate={disabledDate} placeholder="Repeat till date" {...field} />
                  )}
                />
              </Col>
            </Row>
            {/* <Row className={classes.buttonContainer}>
              <Button type="primary" loading={confirmLoading} htmlType="submit">
                Submit
              </Button>
            </Row> */}
          </form>
        </Modal>
      </>
    </Structure>
  );
};

KRAList.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(KRAList);

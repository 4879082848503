import React from 'react';
import PropTypes from 'prop-types';
import { Col } from 'antd';

const CenterGrid = props => {
  const { children, style, className } = props;
  return (
    <Col
      xs={{ span: 20, offset: 2 }}
      sm={{ span: 16, offset: 4 }}
      md={{ span: 16, offset: 4 }}
      lg={{ span: 13, offset: 5 }}
      xl={{ span: 11, offset: 6 }}
      style={style}
      className={className}
    >
      {children}
    </Col>
  );
};

CenterGrid.propTypes = {
  children: PropTypes.oneOfType([PropTypes.element, PropTypes.string, PropTypes.number, PropTypes.array]),
  style: PropTypes.object,
  className: PropTypes.string,
};

export default CenterGrid;

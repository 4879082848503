import { primary as background, secondary as color } from '../theme';

const verticalCenter = {
  display: 'flex',
  alignItems: 'center',
};

export default {
  layout: {
    minHeight: '100vh',
    background: '#fff !important',
  },
  content: {
    margin: '10px',
    borderRadius: '5px',
  },
  contentContainer: {
    padding: '20px 10px 10px 10px',
    background: '#fff',
    minHeight: '100%',
    overflow: 'auto',
  },
  header: {
    background,
    color,
    ...verticalCenter,
    padding: '0px !important',
  },
  sidebar: {
    background,
    color,
    height: '100%',
  },
  drawer: {
    height: '100%',
    '& div.ant-drawer-body': {
      color: 'white !important',
      background: 'rgb(29, 54, 75) !important',
      padding: 0,
    },
    '& div.ant-drawer-wrapper-body': {
      color: 'white !important',
      background: 'rgb(29, 54, 75) !important',
    },
    '& div.ant-drawer-content-wrapper': {
      width: '200px !important',
    },
  },
  '@media (min-width: 768px)': {
    drawer: {
      display: 'none',
    },
    collapseDrawerIcon: {
      display: 'none',
    },
  },
  '@media (max-width: 767px)': {
    sidebar: {
      display: 'none',
    },
    collapseIcon: {
      display: 'none',
    },
  },
  logo: {
    height: 32,
    // color,
    marginTop: '15px',
    // marginBottom: '15px',
  },
  logoName: {
    width: 120,
    height: 45,
    // color,
    marginTop: '10px',
    // marginBottom: '15px',
  },
  logoCenterDiv: {
    display: 'flex',
    flexDirection: 'row',
    flexWrap: 'wrap',
    justifyContent: 'center',
    alignItems: 'center',
  },
  collapseIcon: {
    fontSize: '18px',
    marginLeft: '20px',
    cursor: 'pointer',
    outline: 'none!important',
  },
  collapseDrawerIcon: {
    fontSize: '18px',
    marginLeft: '20px',
    cursor: 'pointer',
    outline: 'none!important',
  },
  icon: {
    fontSize: '19px !important',
  },
  menu: {
    background,
    '& ul': {
      background,
    },
  },
  navBarMenu: {
    ':global(.anticon)': {
      marginRight: '8px',
    },
    ':global(.ant-dropdown-menu-item)': {
      minWidth: '160px',
    },
  },
  action: {
    display: 'flex',
    float: 'right',
    marginLeft: 'auto',
    overflow: 'hidden',
    alignItems: 'center',
    height: '100%',
    padding: '0 12px',
    cursor: 'pointer',
    transition: 'all 0.3s',
    '> span': {
      verticalAlign: 'middle',
    },
    '&:hover': {
      background: 'rgba(0, 0, 0, 0.025)',
    },
    '&:global(.opened)': {
      background: 'rgba(0, 0, 0, 0.025)',
    },
  },
  avatar: {
    margin: '~calc((@{layout-header-height} - 24px) / 2',
    marginRight: '8px',
    color: 'rgb(29, 54, 75)',
    verticalAlign: 'top',
    background: 'rgba(255, 255, 255, 0.85)',
  },
};

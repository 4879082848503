import React, { useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Table } from 'antd';
import moment from 'moment';
import Structure from '../Structure/Structure';
import { getReceivedWhatsAppMessages, clearWhatsAppMessages } from '../Actions';
import { onDateFilter, dateFilterDropdown, dateSorter, sorter, onFilter, filterDropdown } from '../utils';

const Received = () => {
  const dispatch = useDispatch();

  const receivedWhatsAppMessages = useSelector(state => state.receivedWhatsAppMessages);

  useEffect(() => {
    dispatch(getReceivedWhatsAppMessages());
    return () => {
      dispatch(clearWhatsAppMessages());
    };
  }, []);

  const columns = [
    {
      title: 'Sender',
      dataIndex: 'party',
      key: 'party',
      sorter: sorter('party'),
      filterDropdown,
      onFilter: onFilter('party'),
      width: '15%',
    },
    {
      title: 'Message',
      dataIndex: 'template',
      render: (_text, record) => record.payload.text,
    },
    {
      title: 'Created',
      dataIndex: 'created',
      sorter: dateSorter('created'),
      filterDropdown: dateFilterDropdown,
      onFilter: onDateFilter('created'),
      render: created => (created ? moment(created).format('LLLL') : null),
      width: '10%',
    },
  ];

  return (
    <Structure>
      <>
        <Table
          columns={columns}
          bordered
          rowKey={record => record.uuid}
          pagination={{ defaultPageSize: 20, position: ['bottomLeft'], showTotal: total => `Total ${total} Records` }}
          dataSource={receivedWhatsAppMessages}
          size="small"
          scroll={{ x: 1050, y: window.innerHeight - 300 }}
        />
      </>
    </Structure>
  );
};

export default Received;

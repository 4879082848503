export const creditDebitNoteList = (state = [], action) => {
  if (action.type === 'FETCH_CREDIT_DEBIT_NOTE_LIST') {
    return action.payload;
  }
  if (action.type === 'CLEAR_CREDIT_DEBIT_NOTE_LIST') {
    return [];
  }
  return state;
};

export const creditDebitNoteFilesList = (state = [], action) => {
  if (action.type === 'FETCH_CREDIT_DEBIT_NOTE_FILES_LIST') {
    return action.payload;
  }
  if (action.type === 'CLEAR_CREDIT_DEBIT_NOTE_FILES_LIST') {
    return [];
  }
  return state;
};

export const creditDebitNote = (state = {}, action) => {
  if (action.type === 'FETCH_CREDIT_DEBIT_NOTE') {
    return action.payload;
  }
  if (action.type === 'CLEAR_CREDIT_DEBIT_NOTE') {
    return {};
  }
  return state;
};

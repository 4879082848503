import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import withStyles from 'react-jss';
import { reduxForm, Field, getFormValues } from 'redux-form';
import { Input, Select, Row, Modal, Button, DatePicker, Popconfirm, Col } from 'antd';
import { useHistory } from 'react-router';
import { connect, useDispatch, useSelector } from 'react-redux';
import startCase from 'lodash/startCase';
import moment from 'moment';
import styles from '../CommonStyles';
import LeftGrid from '../Components/LeftGrid';
import RightGrid from '../Components/RightGrid';
import GetField from '../Components/GetField';
import { errorNotification, successNotification } from '../Snackbar';
import Api from '../Api';
import Structure from '../Structure/Structure';
import { consignorList, materialTypes } from '../constants';
import Heading from '../Components/Heading';
import { getDebitNote, clearDebitNote } from '../Actions';
import { get } from '../utils';

const AntInput = GetField(Input);
const AntSelect = GetField(Select);
const AntDatePicker = GetField(DatePicker);

const EditDebitNote = props => {
  const { handleSubmit, classes, change, match } = props;
  const history = useHistory();
  const dispatch = useDispatch();

  const [formValueModal, setFormValuesModal] = useState(false);
  const [confirmLoading, setConfirmLoading] = useState(false);
  const [preview, setPreview] = useState({});
  const FormValues = useSelector(state => getFormValues('EditDebitNote')(state));

  useEffect(() => {
    dispatch(getDebitNote(match.params.id));
    return () => {
      dispatch(clearDebitNote());
    };
  }, []);

  useEffect(() => {
    if (FormValues && FormValues.quantity && FormValues.rate) {
      const value = (+FormValues.quantity * +FormValues.rate).toFixed(2);
      change('value', value);
    } else {
      change('value', '');
    }
  }, [FormValues && FormValues.quantity, FormValues && FormValues.rate]);

  useEffect(() => {
    if (FormValues && FormValues.value && (FormValues.gst || FormValues.gst === 0)) {
      const gstValue = ((+FormValues.value * +FormValues.gst) / 100).toFixed(2);
      change('gstValue', gstValue);
    } else {
      change('gstValue', '');
    }
  }, [FormValues && FormValues.value, FormValues && (FormValues.gst || FormValues.gst === 0)]);

  useEffect(() => {
    if (FormValues && FormValues.forwardingCharges) {
      const forwardingChargesTax = ((+FormValues.forwardingCharges * 18) / 100).toFixed(2);
      change('forwardingChargesTax', forwardingChargesTax);
    } else {
      change('forwardingChargesTax', '');
    }
  }, [FormValues && FormValues.forwardingCharges]);

  useEffect(() => {
    if (FormValues && FormValues.value && FormValues && FormValues.gstValue) {
      const total = (
        +FormValues.value +
        +FormValues.gstValue +
        (+FormValues.forwardingCharges || 0) +
        (+FormValues.forwardingChargesTax || 0) +
        (+FormValues.otherChargesDiscount || 0) +
        (+FormValues.roundOf || 0)
      ).toFixed(2);
      change('total', total);
    }
  }, [
    FormValues && FormValues.value,
    FormValues && FormValues.gstValue,
    FormValues && FormValues.forwardingCharges,
    FormValues && FormValues.forwardingChargesTax,
    FormValues && FormValues.otherChargesDiscount,
    FormValues && FormValues.roundOf,
  ]);

  const showFormValuesModal = () => {
    const debitDate = get(FormValues, ['debitDate']);
    const type = get(FormValues, ['type']);
    const materialType = get(FormValues, ['materialType']);

    const _preview = {
      material: get(FormValues, ['material'], '--'),
      purchaseNo: get(FormValues, ['purchaseId'], '--'),
      supplier: get(FormValues, ['supplier'], '--'),
      consignor: get(FormValues, ['consignor'], '--'),
      type: type ? startCase(type) : '--',
      debitDate: debitDate ? moment(debitDate).format('DD MMM YYYY') : '--',
      materialType: materialType ? startCase(materialType) : '--',
      unit: get(FormValues, ['unit'], '--'),
      hsn: get(FormValues, ['hsn'], '--'),
      quantity: get(FormValues, ['quantity'], '--'),
      rate: get(FormValues, ['rate'], '--'),
      value: get(FormValues, ['value'], '--'),
      gst: get(FormValues, ['gst'], '--'),
      gstValue: get(FormValues, ['gstValue'], '--'),
      forwardingCharges: get(FormValues, ['forwardingCharges'], '--'),
      forwardingChargesTax: get(FormValues, ['forwardingChargesTax'], '--'),
      otherChargesDiscount: get(FormValues, ['otherChargesDiscount'], '--'),
      roundOf: get(FormValues, ['roundOf'], '--'),
      total: get(FormValues, ['total'], '--'),
      remarks: get(FormValues, ['remarks'], '--'),
    };

    setPreview(_preview);
    setFormValuesModal(true);
  };

  const hideFormValuesModal = () => {
    setFormValuesModal(false);
    setPreview({});
  };

  const onSubmit = async () => {
    try {
      setConfirmLoading(true);
      await Api.put(`/debit-note/${match.params.id}`, FormValues);
      successNotification('Debit note added');
      setConfirmLoading(false);
      setConfirmLoading(false);
      history.push('/debitnote/list');
    } catch (err) {
      setConfirmLoading(false);
      errorNotification(err);
    }
  };

  return (
    <Structure>
      <>
        <Heading text="Edit Debit Note" />
        <form onSubmit={handleSubmit(onSubmit)}>
          <Row>
            <LeftGrid>
              <Field label="Material" disabled allowClear name="material" placeholder="Material" component={AntInput} />
            </LeftGrid>
            <RightGrid>
              <Field
                label="Purchase Number"
                disabled
                allowClear
                name="purchaseId"
                placeholder="Purchase Number"
                component={AntInput}
              />
            </RightGrid>
          </Row>
          <Row>
            <LeftGrid>
              <Field label="Supplier" disabled allowClear name="supplier" placeholder="Supplier" component={AntInput} />
            </LeftGrid>
            <RightGrid>
              <Field label="Consignor" required name="consignor" component={AntSelect}>
                <Select.Option key={null} value="" disabled>
                  Select consignor
                </Select.Option>
                {consignorList.map(consignor => (
                  <Select.Option key={consignor} value={consignor}>
                    {consignor}
                  </Select.Option>
                ))}
              </Field>
            </RightGrid>
          </Row>
          <Row>
            <LeftGrid>
              <Field label="Purchase Type" required name="type" component={AntSelect}>
                <Select.Option key={null} value="" disabled>
                  Select type
                </Select.Option>
                <Select.Option key="invoice" value="invoice">
                  Invoice
                </Select.Option>
                <Select.Option key="challan" value="challan">
                  Challan
                </Select.Option>
              </Field>
            </LeftGrid>
            <RightGrid>
              <Field
                allowClear
                label="Debit Note Date"
                name="debitDate"
                placeholder="Debit Note Date"
                component={AntDatePicker}
              />
            </RightGrid>
          </Row>
          <Row>
            <LeftGrid>
              <Field label="Type" allowClear disabled name="materialType" component={AntSelect}>
                {materialTypes.map(type => (
                  <Select.Option key={type} value={type}>
                    {startCase(type)}
                  </Select.Option>
                ))}
              </Field>
            </LeftGrid>
            <RightGrid>
              <Field label="Unit" disabled allowClear name="unit" placeholder="Unit" component={AntInput} />
            </RightGrid>
          </Row>
          <Row>
            <LeftGrid>
              <Field label="HSN Code" disabled allowClear name="hsn" placeholder="HSN Code" component={AntInput} />
            </LeftGrid>
            <RightGrid>
              <Field
                label="Quantity"
                allowClear
                type="number"
                min={0}
                name="quantity"
                placeholder="Quantity"
                component={AntInput}
              />
            </RightGrid>
          </Row>
          <Row>
            <LeftGrid>
              <Field
                label="Rate"
                allowClear
                type="number"
                min={0}
                name="rate"
                placeholder="Rate"
                component={AntInput}
              />
            </LeftGrid>
            <RightGrid>
              <Field
                label="Purchase Value"
                disabled
                allowClear
                type="number"
                min={0}
                name="value"
                placeholder="Purchase Value"
                component={AntInput}
              />
            </RightGrid>
          </Row>
          <Row>
            <LeftGrid>
              <Field
                label="GST (in %)"
                allowClear
                type="number"
                min={0}
                name="gst"
                placeholder="GST (in %)"
                component={AntInput}
              />
            </LeftGrid>
            <RightGrid>
              <Field
                label="GST Value"
                disabled
                allowClear
                type="number"
                min={0}
                name="gstValue"
                placeholder="GST Value"
                component={AntInput}
              />
            </RightGrid>
          </Row>
          <Row>
            <LeftGrid>
              <Field
                label="Forwarding Charges"
                allowClear
                type="number"
                min={0}
                name="forwardingCharges"
                placeholder="Forwarding Charges"
                component={AntInput}
              />
            </LeftGrid>
            <RightGrid>
              <Field
                label="Forwarding Charges Tax Value (Tax 18%)"
                allowClear
                disabled
                type="number"
                min={0}
                name="forwardingChargesTax"
                placeholder="Forwarding Charges Tax Value (Tax 18%)"
                component={AntInput}
              />
            </RightGrid>
          </Row>
          <Row>
            <LeftGrid>
              <Field
                label="Other Charges/Discount (+/-)"
                allowClear
                type="number"
                name="otherChargesDiscount"
                placeholder="Other Charges/Discount (+/-)"
                component={AntInput}
              />
            </LeftGrid>
            <RightGrid>
              <Field
                label="Round of (+/-)"
                allowClear
                type="number"
                name="roundOf"
                placeholder="Round of (+/-)"
                component={AntInput}
              />
            </RightGrid>
          </Row>
          <Row>
            <LeftGrid>
              <Field
                label="Total"
                disabled
                allowClear
                type="number"
                min={0}
                name="total"
                placeholder="Total"
                component={AntInput}
              />
            </LeftGrid>
            <RightGrid>
              <Field label="Remarks" allowClear name="remarks" placeholder="Remarks" component={AntInput} />
            </RightGrid>
          </Row>
          <Row className={classes.buttonContainer}>
            <Button className={classes.button} type="primary" onClick={showFormValuesModal}>
              Preview
            </Button>
          </Row>
        </form>
        <Modal
          onCancel={hideFormValuesModal}
          open={formValueModal}
          confirmLoading={confirmLoading}
          centered
          width="800px"
          maskClosable={false}
          closable={false}
          cancelText="Back"
          okButtonProps={{ className: classes.button }}
          okText={
            <Popconfirm
              title="Sure want to submit form?"
              onConfirm={() => onSubmit()}
              okText="Confirm"
              cancelText="Cancel"
            >
              Submit
            </Popconfirm>
          }
        >
          <Row>
            <Col xs={24} lg={12}>
              <p>
                Material:
                <span className={classes.detailColor}>{preview.material}</span>
              </p>
            </Col>
            <Col xs={24} lg={12}>
              <p>
                Purchase Number:
                <span className={classes.detailColor}>{preview.purchaseNo}</span>
              </p>
            </Col>
          </Row>
          <Row>
            <Col xs={24} lg={12}>
              <p>
                Supplier:
                <span className={classes.detailColor}>{preview.supplier}</span>
              </p>
            </Col>
            <Col xs={24} lg={12}>
              <p>
                Consignor:
                <span className={classes.detailColor}>{preview.consignor}</span>
              </p>
            </Col>
          </Row>
          <Row>
            <Col xs={24} lg={12}>
              <p>
                Purchase Type:
                <span className={classes.detailColor}>{preview.type}</span>
              </p>
            </Col>
            <Col xs={24} lg={12}>
              <p>
                Debit Note Date:
                <span className={classes.detailColor}>{preview.debitDate}</span>
              </p>
            </Col>
          </Row>
          <Row>
            <Col xs={24} lg={12}>
              <p>
                Type:
                <span className={classes.detailColor}>{preview.materialType}</span>
              </p>
            </Col>
            <Col xs={24} lg={12}>
              <p>
                Unit:
                <span className={classes.detailColor}>{preview.unit}</span>
              </p>
            </Col>
          </Row>
          <Row>
            <Col xs={24} lg={12}>
              <p>
                HSN Code:
                <span className={classes.detailColor}>{preview.hsn}</span>
              </p>
            </Col>
            <Col xs={24} lg={12}>
              <p>
                Quantity:
                <span className={classes.detailColor}>{preview.quantity}</span>
              </p>
            </Col>
          </Row>
          <Row>
            <Col xs={24} lg={12}>
              <p>
                Rate:
                <span className={classes.detailColor}>{preview.rate}</span>
              </p>
            </Col>
            <Col xs={24} lg={12}>
              <p>
                Purchase Value:
                <span className={classes.detailColor}>{preview.value}</span>
              </p>
            </Col>
          </Row>
          <Row>
            <Col xs={24} lg={12}>
              <p>
                GST (in %):
                <span className={classes.detailColor}>{preview.gst}</span>
              </p>
            </Col>
            <Col xs={24} lg={12}>
              <p>
                GST Value:
                <span className={classes.detailColor}>{preview.gstValue}</span>
              </p>
            </Col>
          </Row>
          <Row>
            <Col xs={24} lg={12}>
              <p>
                Forwarding Charges:
                <span className={classes.detailColor}>{preview.forwardingCharges}</span>
              </p>
            </Col>
            <Col xs={24} lg={12}>
              <p>
                Forwarding Charges Tax Value (Tax 18%):
                <span className={classes.detailColor}>{preview.forwardingChargesTax}</span>
              </p>
            </Col>
          </Row>
          <Row>
            <Col xs={24} lg={12}>
              <p>
                Other Charges/Discount (+/-):
                <span className={classes.detailColor}>{preview.otherChargesDiscount}</span>
              </p>
            </Col>
            <Col xs={24} lg={12}>
              <p>
                Round of (+/-):
                <span className={classes.detailColor}>{preview.roundOf}</span>
              </p>
            </Col>
          </Row>
          <Row>
            <Col xs={24} lg={12}>
              <p>
                Total:
                <span className={classes.detailColor}>{preview.total}</span>
              </p>
            </Col>
            <Col xs={24} lg={12}>
              <p>
                Remarks:
                <span className={classes.detailColor}>{preview.remarks}</span>
              </p>
            </Col>
          </Row>
        </Modal>
      </>
    </Structure>
  );
};

EditDebitNote.propTypes = {
  handleSubmit: PropTypes.func.isRequired,
  classes: PropTypes.object.isRequired,
  change: PropTypes.func.isRequired,
  match: PropTypes.object.isRequired,
};

const StyledEditDebitNote = withStyles(styles)(EditDebitNote);

const FormedEditDebitNote = reduxForm({
  form: 'EditDebitNote',
  enableReinitialize: true,
})(StyledEditDebitNote);

export default connect(({ debitNote }) => ({ initialValues: debitNote }), {})(FormedEditDebitNote);

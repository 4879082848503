import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { Popconfirm, Table, Tooltip } from 'antd';
import Modal from 'antd/lib/modal/Modal';
import { CloudDownloadOutlined, DeleteOutlined } from '@ant-design/icons';
import { useDispatch, useSelector } from 'react-redux';
import withStyles from 'react-jss';
import moment from 'moment';
import Structure from '../Structure/Structure';
import Api from '../Api';
import { errorNotification, successNotification } from '../Snackbar';
import styles from '../CommonStyles';
import {
  clearTransactionFileDateWiseList,
  clearTransactionFilesList,
  getTransactionFileDateWiseList,
  getTransactionFilesList,
} from '../Actions';
import { dateFilterDropdown, dateSorter, onDateFilter } from '../utils';

const TransactionFileList = props => {
  const { classes } = props;
  const dispatch = useDispatch();

  const [filesModal, setFilesModal] = useState(false);
  const [recordDate, setRecordDate] = useState(null);

  const transactionFileList = useSelector(state => state.transactionFileList);
  const transactionFiles = useSelector(state => state.transactionFiles);

  useEffect(() => {
    dispatch(getTransactionFileDateWiseList());
    return () => {
      dispatch(clearTransactionFileDateWiseList());
      dispatch(clearTransactionFilesList());
    };
  }, []);

  const showModal = date => {
    setFilesModal(true);
    setRecordDate(date);
  };

  const hideModal = () => {
    setFilesModal(false);
    setRecordDate(null);
    dispatch(clearTransactionFilesList());
  };

  useEffect(() => {
    if (filesModal && recordDate) {
      dispatch(getTransactionFilesList(recordDate));
    }
  }, [filesModal, recordDate]);

  const onFileDelete = async id => {
    try {
      await Api.delete(`/financial-files/${id}`);
      successNotification('File delete');
      dispatch(getTransactionFilesList(recordDate));
    } catch (err) {
      errorNotification(err);
    }
  };

  const renderDownloadColumn = record => (
    <Tooltip placement="bottom" title="Download">
      <CloudDownloadOutlined className={classes.tableIcon} onClick={() => showModal(record.fileDate)} />
    </Tooltip>
  );

  const columns = [
    {
      title: 'Date',
      dataIndex: 'fileDate',
      sorter: dateSorter('fileDate'),
      filterDropdown: dateFilterDropdown,
      onFilter: onDateFilter('fileDate'),
      render: fileDate => (fileDate ? moment(fileDate).format('DD MMM YYYY') : null),
    },
    {
      title: 'View',
      render: renderDownloadColumn,
    },
  ];

  const filesTableColumns = [];

  const renderFileTableNameColumn = record => (
    <a href={record.url} rel="noreferrer noopener" target="_blank">
      <Tooltip placement="bottom" title={`Click to open/download "${record.fileName}" file.`}>
        {record.fileName}
      </Tooltip>
    </a>
  );

  const renderFileTableActionColumn = record => {
    return (
      <Tooltip placement="bottom" title="Delete File">
        <Popconfirm
          title="Are you sure you want to delete?"
          onConfirm={() => onFileDelete(record.id)}
          okText="Yes"
          cancelText="No"
        >
          <DeleteOutlined className={classes.tableIcon} />
        </Popconfirm>
      </Tooltip>
    );
  };

  filesTableColumns.push({
    title: 'File',
    render: renderFileTableNameColumn,
  });

  filesTableColumns.push({
    title: 'Delete',
    render: renderFileTableActionColumn,
    width: 70,
  });

  return (
    <Structure>
      <>
        <Table
          columns={columns}
          bordered
          rowKey={record => record.fileDate}
          dataSource={transactionFileList}
          size="small"
          pagination={{ defaultPageSize: 20, position: ['bottomLeft'] }}
          scroll={{ x: 1024, y: window.innerHeight - 300 }}
        />
        <Modal
          onOk={hideModal}
          open={filesModal}
          centered
          maskClosable={false}
          title="View Files."
          okText="Close"
          cancelButtonProps={{ className: classes.hide }}
          closable={false}
          okButtonProps={{ className: classes.button }}
        >
          <Table
            columns={filesTableColumns}
            bordered
            rowKey={record => record.id}
            dataSource={transactionFiles}
            size="small"
            pagination={{ position: ['bottomLeft'] }}
          />
        </Modal>
      </>
    </Structure>
  );
};

TransactionFileList.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(TransactionFileList);

import Api from '../../Api';

export const getSubcategoryList = () => async dispatch => {
  try {
    const { data } = await Api.get('/dropdowns/formulation-subcategories');
    dispatch({
      type: 'FETCH_SUBCATEGORY_LIST',
      payload: data,
    });
  } catch (err) {
    throw err;
  }
};

export const clearSubcategoryList = () => {
  return {
    type: 'CLEAR_SUBCATEGORY_LIST',
  };
};

export const getSubcategory = id => async dispatch => {
  try {
    const { data } = await Api.get(`/dropdowns/formulation-subcategories/${id}`);
    dispatch({
      type: 'FETCH_SUBCATEGORY',
      payload: data,
    });
  } catch (err) {
    throw err;
  }
};

export const clearSubcategory = () => {
  return {
    type: 'CLEAR_SUBCATEGORY',
  };
};

import React from 'react';
import { notification } from 'antd';
import { ExclamationCircleOutlined } from '@ant-design/icons';

export const successNotification = message => {
  try {
    notification.success({
      message,
    });
  } catch (err) {
    throw err;
  }
};

export const infoNotification = message => {
  try {
    notification.info({
      message,
    });
  } catch (err) {
    throw err;
  }
};

export const errorNotification = err => {
  try {
    if (!err.response) {
      notification.open({
        message: err.message || 'No response from server!',
        icon: <ExclamationCircleOutlined style={{ color: 'red' }} />,
      });
    } else {
      notification.error({
        message: err.response.data.message,
        icon: <ExclamationCircleOutlined style={{ color: 'red' }} />,
      });
    }
  } catch (err) {
    throw err;
  }
};

export const errorNotificationOnBlob = async err => {
  if (!err.response) {
    notification.open({
      message: err?.message || 'No response from server!',
      icon: <ExclamationCircleOutlined style={{ color: 'red' }} />,
    });
  } else {
    notification.error({
      message: JSON.parse(await err.response.data.text()).message || 'No response from server',
      icon: <ExclamationCircleOutlined style={{ color: 'red' }} />,
    });
  }
};

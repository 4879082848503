import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { useSelector, useDispatch } from 'react-redux';
import withStyles from 'react-jss';
import { Table, Popconfirm, Tooltip, Button } from 'antd';
import { DeleteOutlined, EditOutlined } from '@ant-design/icons';
import { Link } from 'react-router-dom';
import moment from 'moment';
import Structure from '../Structure/Structure';
import { dateFilterDropdown, dateSorter, filterDropdown, get, onDateFilter, onFilter, sorter } from '../utils';
import Api from '../Api';
import { errorNotification, successNotification } from '../Snackbar';
import styles from '../CommonStyles';
import Heading from '../Components/Heading';
import { getLeaveList, clearLeaveList } from '../Actions';

const LeaveList = props => {
  const { classes } = props;
  const dispatch = useDispatch();

  const auth = useSelector(state => state.auth);
  const permissions = get(auth, ['permissions'], []);
  const leaveList = useSelector(state => state.leaveList);

  useEffect(() => {
    dispatch(getLeaveList());
    return () => {
      dispatch(clearLeaveList());
    };
  }, []);

  const onDelete = async id => {
    try {
      await Api.delete(`/leave-request/${id}`);
      successNotification('Leave request deleted');
      dispatch(getLeaveList());
    } catch (err) {
      errorNotification(err);
    }
  };

  const onApprove = async id => {
    try {
      await Api.put(`/leave-request/${id}/approve`);
      successNotification('Leave request approved');
      dispatch(getLeaveList());
    } catch (err) {
      errorNotification(err);
    }
  };

  const onReject = async id => {
    try {
      await Api.put(`/leave-request/${id}/reject`);
      successNotification('Leave request rejected');
      dispatch(getLeaveList());
    } catch (err) {
      errorNotification(err);
    }
  };

  const renderActionIcons = record => (
    <div>
      {permissions.includes('edit_leave') && (record.approvedAt === null || record.rejectedAt === null) ? (
        <>
          <Tooltip placement="bottom" title="Edit">
            <Link to={`/leave/edit/${record.id}`}>
              <EditOutlined className={classes.tableIcon} />
            </Link>
          </Tooltip>
          &nbsp;&nbsp;&nbsp;&nbsp;
        </>
      ) : null}
      {permissions.includes('delete_leave') && (record.approvedAt === null || record.rejectedAt === null) ? (
        <>
          <Tooltip placement="bottom" title="Delete">
            <Popconfirm
              title="Are you sure you want to delete?"
              onConfirm={() => onDelete(record.id)}
              okText="Yes"
              cancelText="No"
            >
              <DeleteOutlined className={classes.tableIcon} />
            </Popconfirm>
          </Tooltip>
        </>
      ) : null}
    </div>
  );

  const managementColumn = record => {
    if (permissions.includes('leave_management') && record.approvedAt === null && record.rejectedAt === null) {
      return (
        <>
          <Popconfirm
            title="Are you sure you want to approve?"
            onConfirm={() => onApprove(record.id)}
            okText="Yes"
            cancelText="No"
          >
            <Button type="primary">Approve</Button>
          </Popconfirm>
          &nbsp;&nbsp;
          <Popconfirm
            title="Are you sure you want to reject?"
            onConfirm={() => onReject(record.id)}
            okText="Yes"
            cancelText="No"
          >
            <Button type="primary" danger>
              Reject
            </Button>
          </Popconfirm>
        </>
      );
    }
    if (record.approvedAt !== null || record.rejectedAt !== null) {
      if (record.approvedAt !== null) {
        return `Approved at ${record.approvedAt ? moment(record.approvedAt).format('DD MMM YYYY hh:mm A') : null}`;
      }
      return `Rejected at ${record.rejectedAt ? moment(record.rejectedAt).format('DD MMM YYYY hh:mm A') : null}`;
    }
  };

  const columns = [
    {
      title: 'Employee',
      dataIndex: 'name',
      sorter: sorter('name'),
      filterDropdown,
      onFilter: onFilter('name'),
      width: '10%',
    },
    {
      title: 'Reason',
      dataIndex: 'reason',
      sorter: sorter('reason'),
      filterDropdown,
      onFilter: onFilter('reason'),
      width: '27%',
    },
    {
      title: 'From',
      dataIndex: 'from',
      sorter: dateSorter('from'),
      filterDropdown: dateFilterDropdown,
      onFilter: onDateFilter('from'),
      render: from => (from ? moment(from).format('DD MMM YYYY hh:mm A') : null),
      width: '10%',
    },
    {
      title: 'To',
      dataIndex: 'to',
      sorter: dateSorter('to'),
      filterDropdown: dateFilterDropdown,
      onFilter: onDateFilter('to'),
      render: to => (to ? moment(to).format('DD MMM YYYY hh:mm A') : null),
      width: '10%',
    },
    {
      title: 'Actions',
      width: '7%',
      render: renderActionIcons,
    },
    {
      title: 'Status',
      width: '16%',
      render: managementColumn,
    },
    {
      title: 'Requested By',
      dataIndex: 'creator',
      sorter: sorter('creator'),
      filterDropdown,
      onFilter: onFilter('creator'),
      width: '10%',
    },
    {
      title: 'Managed By',
      dataIndex: 'approver',
      sorter: sorter('approver'),
      filterDropdown,
      onFilter: onFilter('approver'),
      width: '10%',
    },
  ];

  return (
    <Structure>
      <>
        <Heading text="Leave List" />
        <Table
          columns={columns}
          bordered
          rowKey={record => record.id}
          pagination={{ defaultPageSize: 20, position: ['bottomLeft'], showTotal: total => `Total ${total} Items` }}
          dataSource={leaveList}
          size="small"
          scroll={{ x: 1050, y: window.innerHeight - 300 }}
        />
      </>
    </Structure>
  );
};

LeaveList.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(LeaveList);

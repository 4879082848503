import React, { useCallback, useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import withStyles from 'react-jss';
import PropTypes from 'prop-types';
import { Table, Popconfirm, Tooltip, Button, Tabs } from 'antd';
import { Link } from 'react-router-dom';
import { EditOutlined, DeleteOutlined, UndoOutlined } from '@ant-design/icons';
import startCase from 'lodash/startCase';
import fileDownload from 'js-file-download';

import Structure from '../Structure/Structure';
import { sorter, onFilter, filterDropdown, get } from '../utils';
import Api from '../Api';
import { getMaterialList, clearMaterialList } from '../Actions';
import { errorNotification, successNotification } from '../Snackbar';
import styles from '../CommonStyles';
import Heading from '../Components/Heading';

const { TabPane } = Tabs;

const MaterialList = props => {
  const { classes } = props;
  const dispatch = useDispatch();

  const auth = useSelector(state => state.auth);
  const permissions = get(auth, ['permissions'], []);
  const materialList = useSelector(state => state.materialList);

  const [activeKey, setActiveKey] = useState('false');

  const [rowCounts, setRowCounts] = useState(0);

  useEffect(() => {
    dispatch(getMaterialList());
    return () => {
      dispatch(clearMaterialList());
    };
  }, []);

  useEffect(() => {
    if (materialList) {
      setRowCounts(materialList.length);
    }
  }, [materialList]);

  const exportMaterialList = async () => {
    try {
      const { data } = await Api.getFile(`/materials/export`);
      fileDownload(data, `materials-list.csv`);
    } catch (error) {
      errorNotification(error);
    }
  };

  const onDelete = async id => {
    try {
      await Api.delete(`/materials/${id}`);
      successNotification('Material deleted');
      dispatch(getMaterialList());
    } catch (err) {
      errorNotification(err);
    }
  };

  const onRestore = async id => {
    try {
      await Api.put(`/materials/restore/${id}`);
      successNotification('Material restored');
      setActiveKey('true');
      dispatch(getMaterialList({ isDeleted: true }));
    } catch (err) {
      errorNotification(err);
    }
  };

  const fetchMaterials = useCallback(key => {
    setActiveKey(key);
    dispatch(clearMaterialList());
    dispatch(getMaterialList({ isDeleted: key }));
  }, []);

  const tooltipShow = arg => (
    <Tooltip placement="topLeft" title={arg}>
      {arg}
    </Tooltip>
  );

  const columns = [
    {
      title: 'Name',
      dataIndex: 'name',
      sorter: sorter('name'),
      filterDropdown,
      onFilter: onFilter('name'),
      render: tooltipShow,
      width: '10%',
      fixed: 'left',
    },
    {
      title: 'Type',
      dataIndex: 'type',
      sorter: sorter('type'),
      filterDropdown,
      onFilter: onFilter('type', true),
      render: type => startCase(type),
      width: '8%',
    },
    {
      title: 'Unit',
      dataIndex: 'unit',
      sorter: sorter('unit'),
      filterDropdown,
      onFilter: onFilter('unit'),
      width: '6%',
    },
    {
      title: 'HSN Code',
      dataIndex: 'hsnCode',
      sorter: sorter('hsnCode'),
      filterDropdown,
      onFilter: onFilter('hsnCode'),
      width: '6%',
    },
    {
      title: 'Manual Rate',
      dataIndex: 'manualRate',
      sorter: sorter('manualRate'),
      filterDropdown,
      onFilter: onFilter('manualRate'),
      render: manualRate => Number(manualRate).toLocaleString('en-IN'),
      width: '7%',
      align: 'right',
    },
    {
      title: 'Maximum Stock',
      dataIndex: 'maximumStock',
      sorter: sorter('maximumStock'),
      filterDropdown,
      onFilter: onFilter('maximumStock'),
      width: '7%',
      align: 'right',
    },
    {
      title: 'Formulations',
      dataIndex: 'usedByFormulations',
      render: usedByFormulations => (usedByFormulations || []).join(', '),
      sorter: sorter('usedByFormulations'),
      filterDropdown,
      onFilter: onFilter('usedByFormulations'),
    },
    {
      title: 'Customer',
      dataIndex: 'customer',
      sorter: sorter('customer'),
      filterDropdown,
      onFilter: onFilter('customer'),
      render: tooltipShow,
      ellipsis: { showTitle: false },
      width: '8%',
    },
    {
      title: 'Location',
      dataIndex: 'location',
      sorter: sorter('location'),
      filterDropdown,
      onFilter: onFilter('location'),
      render: tooltipShow,
      ellipsis: { showTitle: false },
      width: '8%',
    },
    {
      title: 'Done By',
      dataIndex: 'createdByUser',
      sorter: sorter('createdByUser'),
      filterDropdown,
      onFilter: onFilter('createdByUser'),
      width: '8%',
    },
    {
      title: 'Remarks',
      dataIndex: 'remarks',
      sorter: sorter('remarks'),
      filterDropdown,
      onFilter: onFilter('remarks'),
      ellipsis: { showTitle: false },
      render: tooltipShow,
    },
  ];

  const renderActionIcons = record => (
    <div>
      {permissions.includes('edit_material') ? (
        <>
          <Tooltip placement="bottom" title="Edit">
            <Link to={`/material/edit/${record.id}`}>
              <EditOutlined className={classes.tableIcon} />
            </Link>
          </Tooltip>
          &nbsp;&nbsp;&nbsp;&nbsp;
        </>
      ) : null}
      {permissions.includes('delete_material') ? (
        <>
          <Tooltip placement="bottom" title="Delete">
            <Popconfirm
              title="Are you sure you want to delete?"
              onConfirm={() => onDelete(record.id)}
              okText="Yes"
              cancelText="No"
            >
              <DeleteOutlined className={classes.tableIcon} />
            </Popconfirm>
          </Tooltip>
        </>
      ) : null}
    </div>
  );

  if (permissions.includes('edit_material') || permissions.includes('delete_material')) {
    columns.push({
      title: 'Actions',
      width: 70,
      render: renderActionIcons,
    });
  }

  const deleteTableColumns = [
    {
      title: 'Name',
      dataIndex: 'name',
      sorter: sorter('name'),
      filterDropdown,
      onFilter: onFilter('name'),
      render: tooltipShow,
      width: '10%',
      fixed: 'left',
    },
    {
      title: 'Type',
      dataIndex: 'type',
      sorter: sorter('type'),
      filterDropdown,
      onFilter: onFilter('type', true),
      render: type => startCase(type),
      width: '8%',
    },
    {
      title: 'Unit',
      dataIndex: 'unit',
      sorter: sorter('unit'),
      filterDropdown,
      onFilter: onFilter('unit'),
      width: '6%',
    },
    {
      title: 'HSN Code',
      dataIndex: 'hsnCode',
      sorter: sorter('hsnCode'),
      filterDropdown,
      onFilter: onFilter('hsnCode'),
      width: '6%',
    },
    {
      title: 'Manual Rate',
      dataIndex: 'manualRate',
      sorter: sorter('manualRate'),
      filterDropdown,
      onFilter: onFilter('manualRate'),
      render: manualRate => Number(manualRate).toLocaleString('en-IN'),
      width: '7%',
      align: 'right',
    },
    {
      title: 'Maximum Stock',
      dataIndex: 'maximumStock',
      sorter: sorter('maximumStock'),
      filterDropdown,
      onFilter: onFilter('maximumStock'),
      width: '7%',
      align: 'right',
    },
    {
      title: 'Formulations',
      dataIndex: 'usedByFormulations',
      render: usedByFormulations => (usedByFormulations || []).join(', '),
      sorter: sorter('usedByFormulations'),
      filterDropdown,
      onFilter: onFilter('usedByFormulations'),
    },
    {
      title: 'Customer',
      dataIndex: 'customer',
      sorter: sorter('customer'),
      filterDropdown,
      onFilter: onFilter('customer'),
      render: tooltipShow,
      ellipsis: { showTitle: false },
      width: '8%',
    },
    {
      title: 'Done By',
      dataIndex: 'createdByUser',
      sorter: sorter('createdByUser'),
      filterDropdown,
      onFilter: onFilter('createdByUser'),
      width: '8%',
    },
    {
      title: 'Remarks',
      dataIndex: 'remarks',
      sorter: sorter('remarks'),
      filterDropdown,
      onFilter: onFilter('remarks'),
      ellipsis: { showTitle: false },
      render: tooltipShow,
    },
  ];

  const renderRestoreIcon = record => (
    <div>
      {permissions.includes('delete_material') ? (
        <>
          <Tooltip placement="bottom" title="Restore">
            <Popconfirm
              title="Are you sure you want to restore?"
              onConfirm={() => onRestore(record.id)}
              okText="Yes"
              cancelText="No"
            >
              <UndoOutlined className={classes.tableIcon} />
            </Popconfirm>
          </Tooltip>
        </>
      ) : null}
    </div>
  );

  if (permissions.includes('delete_material')) {
    deleteTableColumns.push({
      title: 'Actions',
      width: 70,
      render: renderRestoreIcon,
    });
  }

  return (
    <Structure>
      <>
        <Heading text={`Material List (${rowCounts})`}>
          <Button type="default" onClick={exportMaterialList}>
            Export
          </Button>
        </Heading>
        <Tabs activeKey={activeKey} onChange={fetchMaterials}>
          <TabPane tab="Active" key="false">
            <Table
              columns={columns}
              bordered
              rowKey={record => record.id}
              pagination={{ defaultPageSize: 20, position: ['bottomLeft'] }}
              dataSource={materialList}
              size="small"
              scroll={{ x: 1400, y: window.innerHeight - 300 }}
              onChange={(_page, _filters, _sorter, { currentDataSource }) => {
                setRowCounts((currentDataSource || []).length);
              }}
            />
          </TabPane>
          <TabPane tab="Deleted" key="true">
            <Table
              columns={deleteTableColumns}
              bordered
              rowKey={record => record.id}
              pagination={{ defaultPageSize: 20, position: ['bottomLeft'] }}
              dataSource={materialList}
              size="small"
              scroll={{ x: 1400, y: window.innerHeight - 300 }}
              onChange={(_page, _filters, _sorter, { currentDataSource }) => {
                setRowCounts((currentDataSource || []).length);
              }}
            />
          </TabPane>
        </Tabs>
      </>
    </Structure>
  );
};

MaterialList.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(MaterialList);

import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { useSelector, useDispatch } from 'react-redux';
import withStyles from 'react-jss';
import { Table, Popconfirm, Tooltip, Upload, Button, Modal } from 'antd';
import { CloudDownloadOutlined, CloudUploadOutlined, DeleteOutlined, EditOutlined } from '@ant-design/icons';
import startCase from 'lodash/startCase';
import moment from 'moment';
import { Link } from 'react-router-dom';
import Structure from '../Structure/Structure';
import { filterDropdown, get, onFilter, sorter, dateSorter, dateFilterDropdown, onDateFilter } from '../utils';
import Api from '../Api';
import { errorNotification, successNotification, infoNotification } from '../Snackbar';
import styles from '../CommonStyles';
import Heading from '../Components/Heading';
import {
  getCreditDebitNoteList,
  clearCreditDebitNoteList,
  getCreditDebitNoteFilesList,
  clearCreditDebitNoteFilesList,
} from '../Actions';

const CreditDebitNoteList = props => {
  const { classes } = props;
  const dispatch = useDispatch();

  const auth = useSelector(state => state.auth);
  const permissions = get(auth, ['permissions'], []);
  const creditDebitNoteList = useSelector(state => state.creditDebitNoteList);
  const creditDebitNoteFilesList = useSelector(state => state.creditDebitNoteFilesList);

  const [cdnId, setCDNId] = useState(null);
  const [modalType, setModalType] = useState(null);
  const [fileList, setFileList] = useState([]);
  const [uploading, setUploading] = useState(false);
  const [rowCounts, setRowCounts] = useState(0);

  useEffect(() => {
    dispatch(getCreditDebitNoteList());
    return () => {
      dispatch(clearCreditDebitNoteList());
      dispatch(clearCreditDebitNoteFilesList());
    };
  }, []);

  useEffect(() => {
    if (creditDebitNoteList) {
      setRowCounts(creditDebitNoteList.length);
    }
  }, [creditDebitNoteList]);

  useEffect(() => {
    if (cdnId && modalType === 'ViewFiles') {
      dispatch(getCreditDebitNoteFilesList(cdnId));
    }
  }, [cdnId, modalType]);

  const showModal = (id, type) => {
    setCDNId(id);
    setModalType(type);
  };

  const hideModal = () => {
    setCDNId(null);
    setModalType(null);
    setFileList([]);
    dispatch(clearCreditDebitNoteFilesList());
  };

  const onDelete = async id => {
    try {
      await Api.delete(`/cdnotes/${id}`);
      successNotification('Credit Note deleted');
      dispatch(getCreditDebitNoteList());
    } catch (err) {
      errorNotification(err);
    }
  };

  const onUpload = async () => {
    try {
      setUploading(true);
      const formData = new FormData();
      fileList.forEach((file, index) => {
        formData.append(`files[${index}]`, file);
      });
      const { data } = await Api.post(`/cdnotes/${cdnId}/files`, formData, {
        headers: { 'content-type': 'multipart/form-data' },
      });
      infoNotification(data);
      setUploading(false);
      hideModal();
      setFileList([]);
    } catch (err) {
      errorNotification(err);
    }
  };

  const onFileDelete = async id => {
    try {
      await Api.delete(`/cdnotes/${cdnId}/files/${id}`);
      successNotification('File delete');
      dispatch(getCreditDebitNoteFilesList(cdnId));
    } catch (err) {
      errorNotification(err);
    }
  };

  const onRemove = file => {
    const index = fileList.indexOf(file);
    const newFileList = fileList.slice();
    newFileList.splice(index, 1);
    setFileList(newFileList);
  };

  const beforeUpload = (file, files) => {
    setFileList([...fileList, ...files]);
    return false;
  };

  const tooltipShow = arg => (
    <Tooltip placement="topLeft" title={arg}>
      {arg}
    </Tooltip>
  );

  const renderActionIcons = record => (
    <div>
      {permissions.includes('edit_credit_debit_note') ? (
        <>
          <Tooltip placement="bottom" title="Edit">
            <Link to={`/creditdebitnote/edit/${record.id}`}>
              <EditOutlined className={classes.tableIcon} />
            </Link>
          </Tooltip>
          &nbsp;&nbsp;&nbsp;&nbsp;
        </>
      ) : null}
      {permissions.includes('delete_credit_debit_note') ? (
        <>
          <Tooltip placement="bottom" title="Delete">
            <Popconfirm
              title="Are you sure you want to delete?"
              onConfirm={() => onDelete(record.id)}
              okText="Yes"
              cancelText="No"
            >
              <DeleteOutlined className={classes.tableIcon} />
            </Popconfirm>
          </Tooltip>
        </>
      ) : null}
    </div>
  );

  const renderFileUploadColumn = record => {
    return (
      <Tooltip placement="bottom" title="Upload Files">
        <CloudUploadOutlined className={classes.tableIcon} onClick={() => showModal(record.id, 'UploadFiles')} />
      </Tooltip>
    );
  };

  const renderFileViewColumn = record => {
    let color;
    if (record.files) {
      if (record.files > 0) {
        color = 'Green';
      } else {
        color = 'Red';
      }
    }
    return (
      <Tooltip placement="bottom" title="View Files">
        <CloudDownloadOutlined
          style={{ color }}
          className={classes.tableIcon}
          onClick={() => showModal(record.id, 'ViewFiles')}
        />
      </Tooltip>
    );
  };

  const columns = [
    // {
    //   title: 'Credit/Debit Note No.',
    //   dataIndex: 'id',
    //   sorter: sorter('id'),
    //   filterDropdown,
    //   onFilter: onFilter('id'),
    //   width: 80,
    //   fixed: 'left',
    // },
    {
      title: 'Type',
      dataIndex: 'type',
      sorter: sorter('type'),
      filterDropdown,
      onFilter: onFilter('type'),
      render: text => startCase(text),
      width: 90,
      fixed: 'left',
    },
    {
      title: 'Party',
      dataIndex: 'party',
      sorter: sorter('party'),
      filterDropdown,
      onFilter: onFilter('party'),
      render: tooltipShow,
      ellipsis: { showTitle: false },
      width: 150,
    },
    // {
    //   title: 'Supplier',
    //   dataIndex: 'supplier',
    //   sorter: sorter('supplier'),
    //   filterDropdown,
    //   onFilter: onFilter('supplier'),
    //   render: tooltipShow,
    //   ellipsis: { showTitle: false },
    //   width: 150,
    // },
    {
      title: 'Consignor',
      dataIndex: 'consignor',
      sorter: sorter('consignor'),
      filterDropdown,
      onFilter: onFilter('consignor'),
      width: 130,
    },
    {
      title: 'Voucher No.',
      dataIndex: 'voucherNo',
      sorter: sorter('voucherNo'),
      filterDropdown,
      onFilter: onFilter('voucherNo'),
      width: 90,
    },
    {
      title: 'Voucher Date',
      dataIndex: 'voucherDate',
      sorter: dateSorter('voucherDate'),
      filterDropdown: dateFilterDropdown,
      onFilter: onDateFilter('voucherDate'),
      render: voucherDate => (voucherDate ? moment(voucherDate).format('DD MMM YYYY') : null),
      width: 120,
    },
    {
      title: 'Amount',
      dataIndex: 'amount',
      sorter: sorter('amount'),
      filterDropdown,
      onFilter: onFilter('amount'),
      render: amount => Number(amount).toLocaleString('en-IN'),
      width: 150,
      align: 'right',
    },
    {
      title: 'Remarks',
      dataIndex: 'remarks',
      sorter: sorter('remarks'),
      filterDropdown,
      onFilter: onFilter('remarks'),
      ellipsis: { showTitle: false },
      render: tooltipShow,
      width: 150,
    },
    {
      title: 'Done By',
      dataIndex: 'createdByUser',
      sorter: sorter('createdByUser'),
      filterDropdown,
      onFilter: onFilter('createdByUser'),
      render: tooltipShow,
      width: 120,
    },
    {
      title: 'Actions',
      width: 70,
      render: renderActionIcons,
    },
    {
      title: 'Upload Files',
      width: 70,
      render: renderFileUploadColumn,
    },
    {
      title: 'View Files',
      width: 70,
      render: renderFileViewColumn,
    },
  ];

  const filesTableColumns = [];

  const renderFileTableActionColumn = record => {
    return (
      <Tooltip placement="bottom" title="Delete File">
        <Popconfirm
          title="Are you sure you want to delete?"
          onConfirm={() => onFileDelete(record.id)}
          okText="Yes"
          cancelText="No"
        >
          <DeleteOutlined className={classes.tableIcon} />
        </Popconfirm>
      </Tooltip>
    );
  };

  const renderFileTableNameColumn = record => (
    <a href={record.url} rel="noreferrer noopener" target="_blank">
      <Tooltip placement="bottom" title={`Click to open/download "${record.fileName}" file.`}>
        {record.fileName}
      </Tooltip>
    </a>
  );

  filesTableColumns.push(
    {
      title: 'File',
      render: renderFileTableNameColumn,
    },
    {
      title: 'Delete',
      render: renderFileTableActionColumn,
      width: 70,
    }
  );

  return (
    <Structure>
      <>
        <Heading text={`Credit/Debit Note List (${rowCounts})`} />
        <Table
          columns={columns}
          bordered
          rowKey={record => record.id}
          pagination={{ defaultPageSize: 20, position: ['bottomLeft'] }}
          dataSource={creditDebitNoteList}
          size="small"
          scroll={{ x: 1050, y: window.innerHeight - 300 }}
          onChange={(_page, _filters, _sorter, { currentDataSource }) => {
            setRowCounts((currentDataSource || []).length);
          }}
        />
        <Modal
          onOk={hideModal}
          open={modalType === 'UploadFiles'}
          centered
          maskClosable={false}
          title="Upload Sale Files."
          width="500px"
          okText="Close"
          cancelButtonProps={{ className: classes.hide }}
          okButtonProps={{ className: classes.button }}
          closable={false}
        >
          <Upload multiple onRemove={onRemove} beforeUpload={beforeUpload} fileList={fileList}>
            <Button icon={<CloudUploadOutlined />}>Select Files</Button>
          </Upload>
          <Button
            type="primary"
            onClick={onUpload}
            disabled={fileList.length === 0}
            loading={uploading}
            style={{ marginTop: 16 }}
          >
            {uploading ? 'Uploading' : 'Upload'}
          </Button>
        </Modal>
        <Modal
          onOk={hideModal}
          open={modalType === 'ViewFiles'}
          centered
          maskClosable={false}
          title="View Sale Files."
          width="800px"
          okText="Close"
          cancelButtonProps={{ className: classes.hide }}
          closable={false}
          okButtonProps={{ className: classes.button }}
        >
          <Table
            columns={filesTableColumns}
            bordered
            rowKey={record => record.id}
            dataSource={creditDebitNoteFilesList}
            size="small"
            pagination={{ position: ['bottomLeft'] }}
          />
        </Modal>
      </>
    </Structure>
  );
};

CreditDebitNoteList.propTypes = {
  classes: PropTypes.object.isRequired,
};

const StylesCreditDebitNoteList = withStyles(styles)(CreditDebitNoteList);

export default StylesCreditDebitNoteList;

import React, { useEffect, Fragment } from 'react';
import PropTypes from 'prop-types';
import { DatePicker, Row, Table } from 'antd';
import { useDispatch, useSelector } from 'react-redux';
import { Field, getFormValues, reduxForm } from 'redux-form';
import moment from 'moment';
import GetField from '../Components/GetField';
import { clearTeamMIS, getTeamMIS } from '../Actions';
import { onFilter, sorter, filterDropdown } from '../utils';
import Structure from '../Structure/Structure';

const AntDatePicker = GetField(DatePicker);

const TeamMIS = props => {
  const { change } = props;
  const dispatch = useDispatch();

  const teamMIS = useSelector(state => state.teamMIS);

  const FormValues = useSelector(state => getFormValues('TeamMIS')(state));

  useEffect(() => {
    change('from', moment().startOf('month'));
    change('to', moment().endOf('month'));
    return () => {
      dispatch(clearTeamMIS());
    };
  }, []);

  useEffect(() => {
    if (FormValues && FormValues.from && FormValues.to) {
      dispatch(getTeamMIS(FormValues));
    }
  }, [FormValues && FormValues.from, FormValues && FormValues.to]);

  const scoringColumn = record => {
    let score = +record.total - +record.done;
    if (score === 0) {
      return <span style={{ color: 'green' }}>{score}</span>;
    }
    return <span style={{ color: 'red' }}>{(score *= -1)}</span>;
  };

  const columns = [
    {
      title: 'Name',
      dataIndex: 'user',
      sorter: sorter('user'),
      onFilter: onFilter('user'),
      filterDropdown,
    },
    {
      title: 'KPI',
      dataIndex: 'kpi',
      sorter: sorter('kpi'),
      onFilter: onFilter('kpi'),
      filterDropdown,
    },
    {
      title: 'Valid Time',
      dataIndex: 'validTime',
      sorter: sorter('validTime'),
      onFilter: onFilter('validTime'),
      filterDropdown,
      align: 'right',
    },
    {
      title: 'Total',
      dataIndex: 'total',
      sorter: sorter('total'),
      onFilter: onFilter('total'),
      filterDropdown,
      align: 'right',
    },
    {
      title: 'Done',
      dataIndex: 'done',
      sorter: sorter('done'),
      onFilter: onFilter('done'),
      filterDropdown,
      align: 'right',
    },
    {
      title: 'Score',
      render: scoringColumn,
      align: 'right',
    },
  ];

  return (
    <Structure>
      <>
        <Row>
          <Field allowClear required name="from" placeholder="From" component={AntDatePicker} />
          &nbsp;
          <Field allowClear required name="to" placeholder="To" component={AntDatePicker} />
        </Row>
        <Table
          columns={columns}
          bordered
          rowKey={record => record.kpi}
          dataSource={teamMIS}
          size="small"
          pagination={{
            defaultPageSize: 20,
            position: ['bottomLeft'],
            showTotal: total => `Total ${total} Items`,
          }}
          scroll={{ x: 1050, y: window.innerHeight - 300 }}
        />
      </>
    </Structure>
  );
};

TeamMIS.propTypes = {
  change: PropTypes.func.isRequired,
};

const FormedTeamMIS = reduxForm({
  form: 'TeamMIS',
  enableReinitialize: true,
})(TeamMIS);

export default FormedTeamMIS;

export const employeeRoleList = (state = [], action) => {
  if (action.type === 'FETCH_EMPLOYEE_ROLE_LIST') {
    return action.payload;
  }
  if (action.type === 'CLEAR_EMPLOYEE_ROLE_LIST') {
    return [];
  }
  return state;
};

export const employeeRole = (state = {}, action) => {
  if (action.type === 'FETCH_EMPLOYEE_ROLE') {
    return action.payload;
  }
  if (action.type === 'CLEAR_EMPLOYEE_ROLE') {
    return {};
  }
  return state;
};

export const employeeRoleIdList = (state = [], action) => {
  if (action.type === 'FETCH_EMPLOYEE_ROLE_ID_LIST') {
    return action.payload;
  }
  if (action.type === 'CLEAR_EMPLOYEE_ROLE_ID_LIST') {
    return [];
  }
  return state;
};

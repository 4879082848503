import Api from '../Api';

export const getFormulationInventory = () => async dispatch => {
  try {
    const { data } = await Api.get('/formulation-inventory');
    dispatch({
      type: 'FETCH_FORMULATION_INVENTORY',
      payload: data,
    });
  } catch (err) {
    throw err;
  }
};

export const clearFormulationInventory = () => {
  return {
    type: 'CLEAR_FORMULATION_INVENTORY',
  };
};

export const getFormulationStockAdjustments = () => async dispatch => {
  try {
    const { data } = await Api.get('/formulation-inventory/adjustments');
    dispatch({
      type: 'FETCH_FORMULATION_STOCK_ADJUSTMENTS',
      payload: data,
    });
  } catch (err) {
    throw err;
  }
};

export const clearFormulationStockAdjustments = () => {
  return {
    type: 'CLEAR_FORMULATION_STOCK_ADJUSTMENTS',
  };
};

import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import withStyles from 'react-jss';
import { Field, getFormValues, reduxForm, reset } from 'redux-form';
import { connect, useDispatch, useSelector } from 'react-redux';
import { DatePicker, Input, Select, Row, Table, Button } from 'antd';
import Modal from 'antd/lib/modal/Modal';
import { MinusCircleOutlined } from '@ant-design/icons';
import styles from '../CommonStyles';
import { errorNotification, successNotification } from '../Snackbar';
import Api from '../Api';
import GetField from '../Components/GetField';
import LeftGrid from '../Components/LeftGrid';
import RightGrid from '../Components/RightGrid';
import { consignorList } from '../constants';
import { get } from '../utils';

const AntInput = GetField(Input);
const AntSelect = GetField(Select);
const AntDatePicker = GetField(DatePicker);

const ProformaInvoice = props => {
  const { modalType, classes, enquiryId, hideModal, change, handleSubmit } = props;
  const dispatch = useDispatch();

  const FormValues = useSelector(state => getFormValues('ProformaInvoice')(state));
  const [Rows, setRows] = useState([{ index: 1 }]);

  const quotation = useSelector(state => state.quotation);

  useEffect(() => {
    const len = get(quotation, ['items', 'length'], 1);
    const arr = Array.from(new Array(len)).map((el, index) => ({
      index: index + 1,
    }));
    setRows(arr);
  }, [quotation]);

  const calculateTotals = () => {
    if (FormValues && FormValues.items) {
      FormValues.items.map((item, index) => {
        if (item) {
          const value = Number(item.quantity * item.rate).toFixed(2);
          const gstValue = Number((value * item.gst) / 100).toFixed(2);
          const total = +(+value + +gstValue).toFixed(2);
          change(`items[${index}][value]`, value);
          change(`items[${index}][gstValue]`, gstValue);
          change(`items[${index}][total]`, total);
        }
      });
    }
  };

  const onSubmit = async () => {
    try {
      await Api.post(`/inquiries/pi/${enquiryId}`, FormValues);
      successNotification('Proforma Invoice sent');
      dispatch(reset('ProformaInvoice'));
      hideModal();
    } catch (err) {
      errorNotification(err);
    }
  };

  const addRow = () => {
    const tablerows = Rows.slice(0);
    tablerows.push({ index: Rows.length + 1 });
    setRows(tablerows);
  };

  const removeRow = index => {
    if (Rows.length > 1) {
      if (FormValues && FormValues.items && FormValues.items.length > 1) {
        const FormItems = FormValues.items.slice(0);
        FormItems.splice(index, 1);
        change(`items`, FormItems);
      }
      const tablerows = Rows.slice(0);
      tablerows.pop();
      setRows(tablerows);
    }
  };

  const renderText = ({ key, type = 'text', disabled = false } = {}) => {
    const TableField = (text, record) => (
      <Field
        name={`items[${record.index - 1}][${key}]`}
        onWheel={event => {
          event.currentTarget.blur();
        }}
        disabled={disabled}
        min="0"
        type={type}
        component={AntInput}
      />
    );
    return TableField;
  };

  const columns = [
    {
      title: 'Brand Name',
      dataIndex: 'name',
      key: 'name',
      width: '30%',
      render: renderText({ key: 'name', disabled: false }),
    },
    {
      title: 'HSN',
      dataIndex: 'hsn',
      key: 'hsn',
      render: renderText({ key: 'hsn', disabled: false }),
    },
    {
      title: 'Pack Size',
      dataIndex: 'packSize',
      key: 'packSize',
      render: renderText({ key: 'packSize', disabled: false }),
    },
    {
      title: 'Quantity',
      dataIndex: 'quantity',
      key: 'quantity',
      render: renderText({ key: 'quantity', disabled: false }),
    },
    {
      title: 'Rate',
      dataIndex: 'rate',
      key: 'rate',
      render: renderText({ key: 'rate', disabled: false }),
    },
    {
      title: 'Value',
      dataIndex: 'value',
      key: 'value',
      render: renderText({ key: 'value', disabled: true }),
    },
    {
      title: 'GST (in %)',
      dataIndex: 'gst',
      key: 'gst',
      render: renderText({ key: 'gst', disabled: false }),
    },
    {
      title: 'GST Value',
      dataIndex: 'gstValue',
      key: 'gstValue',
      render: renderText({ key: 'gstValue', disabled: true }),
    },
    {
      title: 'Total',
      dataIndex: 'total',
      key: 'total',
      render: renderText({ key: 'total', disabled: true }),
    },
    {
      title: 'Remove',
      dataIndex: 'remove',
      key: 'remove',
      // eslint-disable-next-line react/display-name
      render: (_text, record) => <MinusCircleOutlined type="link" onClick={() => removeRow(record.index - 1)} />,
    },
  ];

  return (
    <Modal
      open={modalType === 'CreatePI' || modalType === 'EditPI'}
      onCancel={hideModal}
      maskClosable={false}
      title="Proforma Invoice Form."
      width="90%"
      style={{ top: 20 }}
      footer={null}
    >
      <form onSubmit={handleSubmit(onSubmit)}>
        <Row>
          <LeftGrid>
            <Field allowClear required name="date" label="Date" placeholder="Date" component={AntDatePicker} />
          </LeftGrid>
          <RightGrid>
            <Field
              allowClear
              required
              name="validFor"
              type="number"
              min={0}
              step="any"
              label="Valid for (in days)"
              placeholder="Valid for (in days)"
              component={AntInput}
            />
          </RightGrid>
        </Row>
        <Row>
          <LeftGrid>
            <Field label="Consignor" allowClear required name="consignor" component={AntSelect}>
              <Select.Option key={null} value="" disabled>
                Select consignor
              </Select.Option>
              {consignorList.map(consignor => (
                <Select.Option key={consignor} value={consignor}>
                  {consignor}
                </Select.Option>
              ))}
            </Field>
          </LeftGrid>
          <RightGrid>
            <Field
              required
              allowClear
              type="number"
              min={0}
              step="any"
              name="paymentTerms"
              label="Payment Term (in days)"
              placeholder="Payment Term (in days)"
              component={AntInput}
            />
          </RightGrid>
        </Row>
        <Row>
          <LeftGrid>
            <Field required allowClear name="delivery" label="Delivery" placeholder="Delivery" component={AntInput} />
          </LeftGrid>
          <RightGrid>
            <Field required allowClear name="freight" label="Freight" placeholder="Freight" component={AntInput} />
          </RightGrid>
        </Row>
        <Row>
          <LeftGrid>
            <Field
              required
              allowClear
              name="transport"
              label="Transport"
              placeholder="Transport"
              component={AntInput}
            />
          </LeftGrid>
          <RightGrid>
            <Field required allowClear name="remarks" label="Remarks" placeholder="Remarks" component={AntInput} />
          </RightGrid>
        </Row>
        <Row>
          <div className={classes.inputTable}>
            <Table
              columns={columns}
              bordered
              rowKey={record => record.index}
              pagination={false}
              dataSource={Rows}
              size="small"
            />
          </div>
        </Row>
        <br />
        <Row>
          <Button onClick={addRow}>Add Row</Button>
          &nbsp;&nbsp;
          <Button onClick={calculateTotals}>Calculate Total</Button>
        </Row>
        <br />
        <br />
        <Row>
          <Button htmlType="submit" className={classes.button}>
            Submit
          </Button>
        </Row>
      </form>
    </Modal>
  );
};

ProformaInvoice.propTypes = {
  modalType: PropTypes.string,
  hideModal: PropTypes.func,
  enquiryId: PropTypes.number,
  classes: PropTypes.object.isRequired,
  change: PropTypes.func.isRequired,
  handleSubmit: PropTypes.func.isRequired,
};

const StylesProformaInvoice = withStyles(styles)(ProformaInvoice);

const FormedProformaInvoice = reduxForm({
  form: 'ProformaInvoice',
  enableReinitialize: true,
})(StylesProformaInvoice);

export default connect(({ quotation }) => ({ initialValues: quotation }), {})(FormedProformaInvoice);

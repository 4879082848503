export const narrationList = (state = [], action) => {
  if (action.type === 'FETCH_NARRATION_LIST') {
    return action.payload;
  }
  if (action.type === 'CLEAR_NARRATION_LIST') {
    return [];
  }
  return state;
};

export const narration = (state = {}, action) => {
  if (action.type === 'FETCH_NARRATION') {
    return action.payload;
  }
  if (action.type === 'CLEAR_NARRATION') {
    return {};
  }
  return state;
};

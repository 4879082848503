import React, { useEffect, Fragment } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Table } from 'antd';
import { getFormulationInventory, clearFormulationInventory } from '../Actions';
import { filterDropdown, onFilter, sorter } from '../utils';
import Structure from '../Structure/Structure';
import Heading from '../Components/Heading';

const Inventory = () => {
  const dispatch = useDispatch();

  const formulationInventory = useSelector(state => state.formulationInventory);

  useEffect(() => {
    dispatch(getFormulationInventory());
    return () => {
      dispatch(clearFormulationInventory());
    };
  }, []);

  const columns = [
    {
      title: 'Formulation',
      dataIndex: 'formulation',
      sorter: sorter('formulation'),
      filterDropdown,
      onFilter: onFilter('formulation'),
    },
    {
      title: 'Category',
      dataIndex: 'category',
      sorter: sorter('category'),
      filterDropdown,
      onFilter: onFilter('category'),
    },
    {
      title: 'Section',
      dataIndex: 'section',
      sorter: sorter('section'),
      filterDropdown,
      onFilter: onFilter('section'),
    },
    {
      title: 'Stock',
      dataIndex: 'stock',
      sorter: sorter('stock'),
      filterDropdown,
      onFilter: onFilter('stock'),
    },
  ];

  return (
    <Structure>
      <>
        <Heading text="Remaining Formulation Inventory" />
        <Table
          columns={columns}
          bordered
          rowKey={record => record.id}
          pagination={{ defaultPageSize: 20, position: ['bottomLeft'] }}
          dataSource={formulationInventory}
          size="small"
          scroll={{ x: 1024, y: window.innerHeight - 300 }}
        />
      </>
    </Structure>
  );
};

export default Inventory;

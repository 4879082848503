import React, { useCallback, useState } from 'react';
import PropTypes from 'prop-types';
import { Input, Modal, Popconfirm } from 'antd';
import { useForm, Controller } from 'react-hook-form';
import startCase from 'lodash/startCase';

import Api from '../../Api';
import { errorNotification, successNotification } from '../../Snackbar';
import GetRHFField from '../../Components/GetRHFField';

const AntInput = GetRHFField(Input);

const TaskComeplete = props => {
  const { setModaltype, refetch, setRefetch, event, setEvent } = props;

  const { control, getValues } = useForm({
    shouldUnregister: false,
  });

  const [confirmLoading, setConfirmLoading] = useState(false);

  const onComplete = useCallback(async () => {
    try {
      const formValues = getValues();

      setConfirmLoading(true);
      if (
        (event && event.type === 'task') ||
        event.type === 'kra' ||
        event.type === 'reminder' ||
        event.type === 'event'
      ) {
        await Api.put(`/tasks/complete/${event.id}`, formValues);
      } else if (event && event.type === 'subTask') {
        await Api.put(`/tasks/complete/${event.taskId}/${event.id}`, formValues);
      }
      setConfirmLoading(false);
      successNotification('Task marked as completed');
      setModaltype(null);
      setEvent(null);
      setRefetch(!refetch);
    } catch (err) {
      setConfirmLoading(false);
      errorNotification(err);
    }
  }, []);

  return (
    <Modal
      title={`Complete ${startCase(event.type)}`}
      confirmLoading={confirmLoading}
      open
      onCancel={() => setModaltype(null)}
      centered
      closable={false}
      maskClosable={false}
      cancelText="Back"
      okText={
        <Popconfirm
          title="Sure want to submit form?"
          onConfirm={() => onComplete()}
          okText="Confirm"
          cancelText="Cancel"
        >
          Submit
        </Popconfirm>
      }
    >
      <form>
        <Controller
          name="completeRemark"
          control={control}
          render={({ field }) => (
            <AntInput allowClear required autoSize={{ minRows: 1, maxRows: 6 }} placeholder="Remark" {...field} />
          )}
        />
      </form>
    </Modal>
  );
};

TaskComeplete.propTypes = {
  setModaltype: PropTypes.func.isRequired,
  refetch: PropTypes.bool,
  setRefetch: PropTypes.func.isRequired,
  event: PropTypes.object.isRequired,
  setEvent: PropTypes.func.isRequired,
};

export default TaskComeplete;

import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { useSelector, useDispatch } from 'react-redux';
import withStyles from 'react-jss';
import { Table, Popconfirm, Tooltip, Row, Button, DatePicker } from 'antd';
import { DeleteOutlined, EditOutlined } from '@ant-design/icons';
import { Link } from 'react-router-dom';
import moment from 'moment';
import startCase from 'lodash/startCase';
import fileDownload from 'js-file-download';
import { Field, getFormValues, reduxForm } from 'redux-form';
import Structure from '../Structure/Structure';
import { dateFilterDropdown, dateSorter, filterDropdown, get, onDateFilter, onFilter, sorter } from '../utils';
import Api from '../Api';
import { errorNotification, successNotification } from '../Snackbar';
import styles from '../CommonStyles';
import Heading from '../Components/Heading';
import { getAttendanceList, clearAttendanceList } from '../Actions';
import GetField from '../Components/GetField';

const AntDatePicker = GetField(DatePicker);

const AttendanceList = props => {
  const { classes } = props;
  const dispatch = useDispatch();

  const FormValues = useSelector(state => getFormValues('AttendanceList')(state));

  const auth = useSelector(state => state.auth);
  const permissions = get(auth, ['permissions'], []);
  const attendanceList = useSelector(state => state.attendanceList);

  useEffect(() => {
    dispatch(getAttendanceList());
    return () => {
      dispatch(clearAttendanceList());
    };
  }, []);

  const onDelete = async id => {
    try {
      await Api.delete(`/attendances/${id}`);
      successNotification('Attendance deleted');
      dispatch(getAttendanceList());
    } catch (err) {
      errorNotification(err);
    }
  };

  const columns = [
    {
      title: 'Type',
      dataIndex: 'type',
      sorter: sorter('type'),
      filterDropdown,
      onFilter: onFilter('type'),
      render: type => startCase(type),
    },
    {
      title: 'Employee',
      dataIndex: 'name',
      sorter: sorter('name'),
      filterDropdown,
      onFilter: onFilter('name'),
    },
    {
      title: 'Timestamp',
      dataIndex: 'ts',
      sorter: dateSorter('ts'),
      filterDropdown: dateFilterDropdown,
      onFilter: onDateFilter('ts'),
      render: ts => (ts ? moment(ts).format('ddd DD MMM YYYY hh:mm:ss A') : null),
    },
  ];

  const renderActionIcons = record => (
    <div>
      {permissions.includes('edit_attendance') ? (
        <>
          <Tooltip placement="bottom" title="Edit">
            <Link to={`/attendance/edit/${record.id}`}>
              <EditOutlined className={classes.tableIcon} />
            </Link>
          </Tooltip>
          &nbsp;&nbsp;&nbsp;&nbsp;
        </>
      ) : null}
      {permissions.includes('delete_attendance') ? (
        <>
          <Tooltip placement="bottom" title="Delete">
            <Popconfirm
              title="Are you sure you want to delete?"
              onConfirm={() => onDelete(record.id)}
              okText="Yes"
              cancelText="No"
            >
              <DeleteOutlined className={classes.tableIcon} />
            </Popconfirm>
          </Tooltip>
        </>
      ) : null}
    </div>
  );

  if (permissions.includes('edit_attendance') || permissions.includes('delete_attendance')) {
    columns.push({
      title: 'Actions',
      width: 70,
      render: renderActionIcons,
    });
  }

  const exportData = async () => {
    try {
      const { data } = await Api.getFile(`/attendances/export`, { from: FormValues.from, to: FormValues.to });
      fileDownload(data, `Attendence.csv`);
    } catch (error) {
      errorNotification(error);
    }
  };

  return (
    <Structure>
      <>
        <Heading text="Attendance List" />
        <Row>
          <Row>
            <Field allowClear required name="from" placeholder="From" component={AntDatePicker} />
            &nbsp;
            <Field allowClear required name="to" placeholder="To" component={AntDatePicker} />
            &nbsp;
            <Button type="default" onClick={exportData}>
              Export
            </Button>
          </Row>
        </Row>
        <br />
        <Table
          columns={columns}
          bordered
          rowKey={record => record.id}
          pagination={{ defaultPageSize: 20, position: ['bottomLeft'], showTotal: total => `Total ${total} Items` }}
          dataSource={attendanceList}
          size="small"
          scroll={{ x: 1050, y: window.innerHeight - 300 }}
        />
      </>
    </Structure>
  );
};

AttendanceList.propTypes = {
  classes: PropTypes.object.isRequired,
};

const StyledAttendanceList = withStyles(styles)(AttendanceList);

const FormedAttendanceList = reduxForm({
  form: 'AttendanceList',
  enableReinitialize: true,
})(StyledAttendanceList);

export default FormedAttendanceList;

/* eslint-disable react/prop-types */
/* eslint-disable no-nested-ternary */
import React from 'react';
import { Form, Select, DatePicker } from 'antd';
import moment from 'moment';

const layout = {
  labelCol: {
    span: 24,
  },
};

const GetField =
  Component =>
  ({ input, meta, children, hasFeedback, style, label, ...rest }) => {
    const hasError = meta && meta.touched && meta.invalid;
    if (Component === Select) {
      input.showSearch = input.showSearch || (rest ? rest.showSearch : null) || true;
      input.optionFilterProp = input.optionFilterProp || (rest ? rest.optionFilterProp : 'children') || 'children';
      delete input.onBlur;
      if (rest.mode === 'multiple' || input.mode === 'multiple') {
        input.value = input.value || [];
      }
    }

    return (
      <Form.Item
        {...layout}
        label={label}
        validateStatus={hasError ? 'error' : 'success'}
        hasFeedback={hasFeedback && hasError}
        help={hasError && meta.error}
        style={style}
      >
        <Component
          className="input"
          {...rest}
          {...input}
          step="any"
          value={
            Component === DatePicker || Component === DatePicker.MonthPicker
              ? input.value
                ? moment(input.value)
                : null
              : input.value
          }
        >
          {children}
        </Component>
      </Form.Item>
    );
  };

export default GetField;

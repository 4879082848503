import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import withStyles from 'react-jss';
import PropTypes from 'prop-types';
import { Table, Popconfirm, Tooltip, Upload, Button, Modal, Select, Col, Row } from 'antd';
import { Link } from 'react-router-dom';
import {
  EditOutlined,
  DeleteOutlined,
  FileDoneOutlined,
  CloudUploadOutlined,
  CloudDownloadOutlined,
  FileTextOutlined,
} from '@ant-design/icons';
import startCase from 'lodash/startCase';
import moment from 'moment';
import fileDownload from 'js-file-download';

import Structure from '../Structure/Structure';
import { sorter, onFilter, filterDropdown, get, onDateFilter, dateFilterDropdown, dateSorter } from '../utils';
import Api from '../Api';
import {
  getOrdersWithLessDetails,
  clearOrdersWithLessDetails,
  getOrderFilesList,
  clearOrderFilesList,
  getJobCard,
  clearJobCard,
  getFormulationFilesList,
  clearFormulationFilesList,
} from '../Actions';
import { errorNotification, successNotification, infoNotification } from '../Snackbar';
import styles from '../CommonStyles';
import JobCard from './JobCard';
import EditJobCardModal from './ProductionModals/EditJobCardModal';

const OrderList = props => {
  const { classes } = props;
  const dispatch = useDispatch();

  const auth = useSelector(state => state.auth);
  const permissions = get(auth, ['permissions'], []);
  const role = get(auth, ['user', 'role'], []);
  const orderList = useSelector(state => state.ordersWithLessDetails);
  const orderFilesList = useSelector(state => state.orderFilesList);
  const formulationFilesList = useSelector(state => state.formulationFilesList);

  const [orderId, setOrderId] = useState(null);
  const [formulationId, setFormulationId] = useState(null);
  const [materialIds, setMaterialIds] = useState([]);
  const [confirmLoading, setConfirmLoading] = useState(false);
  const [modalType, setModalType] = useState(null);
  const [fileList, setFileList] = useState([]);
  const [uploading, setUploading] = useState(false);
  const [fileType, setFileType] = useState(false);
  const [Tab, SetTab] = useState('unauthorized');
  const [financialYear, setFinancialYear] = useState('2024');

  const order = useMemo(() => orderList.find(el => el.id === orderId), [orderId, orderList]);

  // const [Consignor, SetConsignor] = useState(constants.consignors.list.plusFormulationsHeadOffice);

  useEffect(() => {
    let isAuthorized = false;
    let isDispatched = false;

    if (Tab === 'unauthorized') {
      isAuthorized = false;
      isDispatched = false;
    }
    if (Tab === 'authorized') {
      isAuthorized = true;
      isDispatched = false;
    }
    if (Tab === 'dispatched') {
      isAuthorized = true;
      isDispatched = true;
    }

    dispatch(
      getOrdersWithLessDetails({
        // consignor: Consignor,
        isAuthorized,
        isDispatched,
        financialYear,
      })
    );

    return () => {
      dispatch(clearFormulationFilesList());
      dispatch(clearOrdersWithLessDetails());
      dispatch(clearOrderFilesList());
      dispatch(clearJobCard());
    };
  }, [Tab, financialYear]);

  useEffect(() => {
    if (orderId && modalType === 'ViewFiles') {
      dispatch(getOrderFilesList(orderId, fileType));
    }
    if (orderId && modalType === 'ViewOrderDetails') {
      dispatch(getJobCard(orderId));
    }
    if (formulationId && modalType === 'ViewFormulationFiles') {
      dispatch(getFormulationFilesList(formulationId));
    }
  }, [orderId, modalType, materialIds, formulationId]);

  const showModal = (id, type, _fileType, _materialIds = null, _formulationId) => {
    setOrderId(id);
    setModalType(type);
    setFileType(_fileType);
    setMaterialIds(_materialIds || []);
    setFormulationId(_formulationId);
  };

  const hideModal = () => {
    setOrderId(null);
    setMaterialIds([]);
    setModalType(null);
    setFileList([]);
    setFormulationId(null);
    dispatch(clearFormulationFilesList());
    dispatch(clearOrderFilesList());
    dispatch(clearJobCard());
  };

  const onDelete = async id => {
    try {
      await Api.delete(`/orders/${id}`);
      successNotification('Order deleted');
      dispatch(getOrdersWithLessDetails({ isAuthorized: false, isDispatched: false }));
    } catch (err) {
      errorNotification(err);
    }
  };

  const onAuthorize = async () => {
    try {
      setConfirmLoading(true);
      await Api.put(`/orders/${orderId}/authorize`);
      successNotification('Order Authorized');
      setConfirmLoading(false);
      hideModal();
      dispatch(getOrdersWithLessDetails({ isAuthorized: false, isDispatched: false }));
    } catch (err) {
      setConfirmLoading(false);
      errorNotification(err);
    }
  };

  const onUpload = async () => {
    try {
      setUploading(true);
      const formData = new FormData();
      fileList.forEach((file, index) => {
        formData.append(`files[${index}]`, file);
      });
      const { data } = await Api.post(`/orders/${orderId}/files?type=${fileType}`, formData, {
        headers: { 'content-type': 'multipart/form-data' },
      });
      infoNotification(data);
      hideModal();
      setFileList([]);
    } catch (err) {
      errorNotification(err);
    }
    setUploading(false);
  };

  const onFileDelete = async id => {
    try {
      await Api.delete(`/orders/${orderId}/files/${id}`);
      successNotification('File delete');
      dispatch(getOrderFilesList(orderId));
    } catch (err) {
      errorNotification(err);
    }
  };

  const onRemove = file => {
    const index = fileList.indexOf(file);
    const newFileList = fileList.slice();
    newFileList.splice(index, 1);
    setFileList(newFileList);
  };

  const beforeUpload = (file, files) => {
    setFileList([...fileList, ...files]);
    return false;
  };

  const tooltipShow = arg => (
    <Tooltip placement="topLeft" title={arg}>
      {arg}
    </Tooltip>
  );

  const ArtworkStatusWithColors = arg => {
    let color;
    if (arg) {
      if (arg === 'Done') {
        color = 'Green';
      } else if (arg === 'Pending') {
        color = 'red';
      }
    }
    return (
      <Tooltip placement="topLeft" title={arg}>
        <span style={{ color }}>{arg}</span>
      </Tooltip>
    );
  };

  const renderFormulationFileViewColumn = record => (
    <Tooltip placement="bottom" title="View Files">
      <CloudDownloadOutlined
        className={classes.tableIcon}
        onClick={() => showModal(record.id, 'ViewFormulationFiles', false, [], record.formulationId)}
      />
    </Tooltip>
  );

  const columns = [
    {
      title: 'Order No.',
      dataIndex: 'id',
      sorter: sorter('id'),
      filterDropdown,
      onFilter: onFilter('id'),
      width: 90,
      fixed: 'left',
    },
    {
      title: 'Finished Good Name',
      dataIndex: 'formulation',
      sorter: sorter('formulation'),
      onFilter: onFilter('formulation'),
      filterDropdown,
      width: 200,
      fixed: 'left',
    },
    {
      title: 'Consignor',
      dataIndex: 'consignor',
      sorter: sorter('consignor'),
      onFilter: onFilter('consignor'),
      filterDropdown,
      width: 120,
    },
    {
      title: 'Created On',
      dataIndex: 'createdAt',
      sorter: dateSorter('createdAt'),
      filterDropdown: dateFilterDropdown,
      onFilter: onDateFilter('createdAt'),
      render: createdAt => (createdAt ? moment(createdAt).format('DD MMM YYYY') : null),
      width: 110,
    },
    {
      title: 'Authorized On',
      dataIndex: 'authorizedAt',
      sorter: dateSorter('authorizedAt'),
      filterDropdown: dateFilterDropdown,
      onFilter: onDateFilter('authorizedAt'),
      render: authorizedAt => (authorizedAt ? moment(authorizedAt).format('DD MMM YYYY') : null),
      width: 120,
    },
    {
      title: 'Estimated Dispatch Date',
      dataIndex: 'estDispatchDate',
      sorter: dateSorter('estDispatchDate'),
      filterDropdown: dateFilterDropdown,
      onFilter: onDateFilter('estDispatchDate'),
      render: estDispatchDate => (estDispatchDate ? moment(estDispatchDate).format('DD MMM YYYY') : null),
      width: 120,
    },
    {
      title: 'Customer',
      dataIndex: 'customer',
      sorter: sorter('customer'),
      filterDropdown,
      onFilter: onFilter('customer'),
      ellipsis: { showTitle: false },
      render: tooltipShow,
      width: 120,
    },
    {
      title: 'Section',
      dataIndex: 'section',
      sorter: sorter('section'),
      onFilter: onFilter('section'),
      filterDropdown,
      width: 110,
    },
    {
      title: 'Quantity',
      dataIndex: 'quantity',
      sorter: sorter('quantity'),
      filterDropdown,
      onFilter: onFilter('quantity'),
      width: 130,
      align: 'right',
    },
    {
      title: 'Rate',
      dataIndex: 'rate',
      sorter: sorter('rate'),
      filterDropdown,
      onFilter: onFilter('rate'),
      render: rate => Number(rate).toLocaleString('en-IN'),
      width: 130,
      align: 'right',
    },
    {
      title: 'MRP',
      dataIndex: 'mrp',
      sorter: sorter('mrp'),
      filterDropdown,
      onFilter: onFilter('mrp'),
      render: mrp => Number(mrp).toLocaleString('en-IN'),
      width: 130,
      align: 'right',
    },
    {
      title: 'Nature',
      dataIndex: 'nature',
      sorter: sorter('nature'),
      filterDropdown,
      onFilter: onFilter('nature'),
      render: nature => startCase(nature),
      width: 110,
    },
    {
      title: 'Transport',
      dataIndex: 'transport',
      filterDropdown,
      sorter: sorter('transport'),
      onFilter: onFilter('transport'),
      width: 110,
    },
    {
      title: 'Payment Terms',
      dataIndex: 'paymentTerms',
      sorter: sorter('paymentTerms'),
      filterDropdown,
      onFilter: onFilter('paymentTerms'),
      render: paymentTerms => startCase(paymentTerms),
      width: 110,
    },
    {
      title: 'Advance Deposit Date',
      dataIndex: 'dateOfAdvanceDeposit',
      sorter: dateSorter('dateOfAdvanceDeposit'),
      filterDropdown: dateFilterDropdown,
      onFilter: onDateFilter('dateOfAdvanceDeposit'),
      width: 110,
      render: dateOfAdvanceDeposit =>
        dateOfAdvanceDeposit ? moment(dateOfAdvanceDeposit).format('DD MMM YYYY') : null,
    },
    {
      title: 'Deposited Amount',
      dataIndex: 'depositedAmount',
      sorter: sorter('depositedAmount'),
      filterDropdown,
      onFilter: onFilter('depositedAmount'),
      width: 130,
    },
    {
      title: 'Customer Remarks',
      dataIndex: 'remarks',
      ellipsis: { showTitle: false },
      render: tooltipShow,
      width: 110,
    },
    {
      title: 'Internal Remarks',
      dataIndex: 'internalRemarks',
      ellipsis: { showTitle: false },
      render: tooltipShow,
      width: 110,
    },
    {
      title: 'Change Req. in Artwork',
      dataIndex: 'isChangeRequired',
      sorter: sorter('isChangeRequired'),
      filterDropdown,
      onFilter: onFilter('isChangeRequired'),
      render: isChangeRequired => startCase(isChangeRequired),
      width: 110,
    },
    {
      title: 'Artwork Status',
      dataIndex: 'artworkStatus',
      sorter: sorter('artworkStatus'),
      filterDropdown,
      onFilter: onFilter('artworkStatus'),
      width: 110,
      render: ArtworkStatusWithColors,
    },
    {
      title: 'Done By',
      dataIndex: 'createdByUser',
      sorter: sorter('createdByUser'),
      filterDropdown,
      onFilter: onFilter('createdByUser'),
      render: tooltipShow,
      width: 120,
    },
  ];

  const renderActionIcons = record => (
    <div>
      {permissions.includes('edit_order') ? (
        <>
          <Tooltip placement="bottom" title="Edit">
            <Link to={`/order/edit/${record.id}`}>
              <EditOutlined className={classes.tableIcon} />
            </Link>
          </Tooltip>
          &nbsp;&nbsp;&nbsp;&nbsp;
        </>
      ) : null}
      {permissions.includes('delete_order') ? (
        <>
          <Tooltip placement="bottom" title="Delete">
            <Popconfirm
              title="Are you sure you want to delete?"
              onConfirm={() => onDelete(record.id)}
              okText="Yes"
              cancelText="No"
            >
              <DeleteOutlined className={classes.tableIcon} />
            </Popconfirm>
          </Tooltip>
        </>
      ) : null}
    </div>
  );

  if (Tab === 'unauthorized' && (permissions.includes('edit_order') || permissions.includes('delete_order'))) {
    columns.push({
      title: 'Actions',
      width: 70,
      render: renderActionIcons,
    });
  }

  const renderAuthorizationColumn = record => {
    if (permissions.includes('authorize_order')) {
      if (!record.isAuthorized) {
        return (
          <Tooltip placement="bottom" title="Authorize Order">
            <FileDoneOutlined className={classes.tableIcon} onClick={() => showModal(record.id, 'Authorize')} />
          </Tooltip>
        );
      }
      return 'True';
    }
    return null;
  };

  if (permissions.includes('authorize_order')) {
    columns.push({
      title: 'Authorize',
      width: 85,
      render: renderAuthorizationColumn,
    });
  }

  const renderArtworkViewColumn = record => {
    if (permissions.includes('order_artwork_view') && record.isAuthorized) {
      let color;
      if (record.artworkStatus) {
        if (record.artworkStatus === 'Done') {
          color = 'Green';
        } else if (record.artworkStatus === 'Pending') {
          color = 'red';
        }
      }
      return (
        <Tooltip placement="bottom" title="View Artwork">
          <CloudDownloadOutlined
            style={{ color }}
            className={classes.tableIcon}
            onClick={() => showModal(record.id, 'ViewFiles', 'artwork')}
          />
        </Tooltip>
      );
    }
    return null;
  };

  if (permissions.includes('order_artwork_view')) {
    columns.push({
      title: 'View Artwork',
      width: 75,
      render: renderArtworkViewColumn,
    });
  }

  columns.push({
    title: 'View Product Files',
    width: 75,
    render: renderFormulationFileViewColumn,
  });

  const filesTableColumns = [];

  const renderFileTableActionColumn = record => {
    return (
      <Tooltip placement="bottom" title="Delete File">
        <Popconfirm
          title="Are you sure you want to delete?"
          onConfirm={() => onFileDelete(record.id)}
          okText="Yes"
          cancelText="No"
        >
          <DeleteOutlined className={classes.tableIcon} />
        </Popconfirm>
      </Tooltip>
    );
  };

  const renderFileTableNameColumn = record => (
    <a href={record.url} rel="noreferrer noopener" target="_blank">
      <Tooltip placement="bottom" title={`Click to open/download "${record.fileName}" file.`}>
        {record.fileName}
      </Tooltip>
    </a>
  );

  filesTableColumns.push({
    title: 'File',
    render: renderFileTableNameColumn,
  });

  filesTableColumns.push({
    title: 'Delete',
    render: renderFileTableActionColumn,
    width: 70,
  });

  const renderOrderDetailsColumn = record => {
    if (permissions.includes('view_job_card')) {
      return (
        <Tooltip placement="top" title="View Job Card">
          <FileTextOutlined className={classes.tableIcon} onClick={() => showModal(record.id, 'ViewOrderDetails')} />
        </Tooltip>
      );
    }
  };

  if (permissions.includes('view_job_card')) {
    columns.push(
      {
        title: 'Job Card',
        width: 70,
        render: renderOrderDetailsColumn,
      },
      ...(role === 'Owner'
        ? [
            {
              title: 'Edit Job Card',
              key: 'Edit Job Card',
              width: 60,
              render: (_text, record) =>
                record.PackingEndTime ? <FileTextOutlined onClick={() => showModal(record.id, 'EditJobCard')} /> : null,
            },
          ]
        : [])
    );
  }

  const renderFilesUploadColumn = record => (
    <Tooltip placement="bottom" title="Upload Files">
      <CloudUploadOutlined className={classes.tableIcon} onClick={() => showModal(record.id, 'UploadFiles', 'file')} />
    </Tooltip>
  );

  if (permissions.includes('order_files_upload')) {
    columns.push({
      title: 'Upload Files',
      width: 70,
      render: renderFilesUploadColumn,
    });
  }

  const renderFilesViewColumn = record => {
    let color;
    if (record.totalFiles) {
      if (record.totalFiles > 0) {
        color = 'Green';
      } else {
        color = 'Red';
      }
    }
    return (
      <Tooltip placement="bottom" title="View Files">
        <CloudDownloadOutlined
          style={{ color }}
          className={classes.tableIcon}
          onClick={() => showModal(record.id, 'ViewFiles', 'file')}
        />
      </Tooltip>
    );
  };

  if (permissions.includes('order_files_view')) {
    columns.push({
      title: 'View Files',
      width: 70,
      render: renderFilesViewColumn,
    });
  }

  const exportOrders = useCallback(async () => {
    try {
      let file;

      if (Tab === 'unauthorized') {
        const response = await Api.getFile(`/orders/export`, { isAuthorized: false, isDispatched: false });
        file = response.data;
      }

      if (Tab === 'dispatched') {
        const response = await Api.getFile(`/orders/export`, { isAuthorized: true, isDispatched: true });
        file = response.data;
      }

      fileDownload(file, `${Tab}-order-list.csv`);
    } catch (error) {
      errorNotification(error);
    }
  }, [Tab]);

  return (
    <Structure>
      <>
        <Row gutter={[8, 8]}>
          <Col>
            <Select placeholder="Select year" onChange={value => setFinancialYear(value)} defaultValue={financialYear}>
              <Select.Option value="2019">19-20</Select.Option>
              <Select.Option value="2020">20-21</Select.Option>
              <Select.Option value="2021">21-22</Select.Option>
              <Select.Option value="2022">22-23</Select.Option>
              <Select.Option value="2023">23-24</Select.Option>
              <Select.Option value="2024">24-25</Select.Option>
              <Select.Option value="2025">25-26</Select.Option>
              <Select.Option value="2026">26-27</Select.Option>
              <Select.Option value="2027">27-28</Select.Option>
              <Select.Option value="2028">28-29</Select.Option>
              <Select.Option value="2029">29-30</Select.Option>
              <Select.Option value="2030">30-31</Select.Option>
            </Select>
          </Col>
          <Col>
            <Select defaultValue="unauthorized" style={{ width: 130 }} onChange={SetTab}>
              <Select.Option key="unauthorized" value="unauthorized">
                Unauthorized
              </Select.Option>
              {/* <Select.Option key="authorized" value="authorized">
                Authorized
              </Select.Option> */}
              <Select.Option key="dispatched" value="dispatched">
                Dispatched
              </Select.Option>
            </Select>
          </Col>
          {/* <Col>
            <Select onChange={SetConsignor} placeholder="Consignor" value={Consignor} style={{ width: 280 }}>
              {Object.values(constants.consignors.list).map(consignor => (
                <Select.Option key={consignor} value={consignor}>
                  {consignor}
                </Select.Option>
              ))}
            </Select>
          </Col> */}
          <Col>
            <Button type="default" onClick={exportOrders}>
              Export
            </Button>
          </Col>
        </Row>
        <br />
        <Table
          columns={columns}
          bordered
          rowKey={record => record.id}
          pagination={{ defaultPageSize: 20, position: ['bottomLeft'], showTotal: total => `Total ${total} Items` }}
          dataSource={orderList}
          size="small"
          scroll={{ x: 2000, y: window.innerHeight - 300 }}
        />
        {modalType === 'Authorize' ? (
          <Modal
            open={modalType === 'Authorize'}
            confirmLoading={confirmLoading}
            onCancel={hideModal}
            centered
            maskClosable={false}
            closable={false}
            title="Order Authorization."
            width="400px"
            cancelText="Back"
            okText={
              <Popconfirm title="Are you sure?" onConfirm={() => onAuthorize()} okText="Confirm" cancelText="Cancel">
                Authorize
              </Popconfirm>
            }
            okButtonProps={{ className: classes.button }}
          >
            <p>Sure you want to authorize the order?</p>
          </Modal>
        ) : null}
        {modalType === 'UploadFiles' ? (
          <Modal
            onOk={hideModal}
            open={modalType === 'UploadFiles'}
            centered
            maskClosable={false}
            closable={false}
            title="Upload."
            width="600px"
            okText="Close"
            cancelButtonProps={{ className: classes.hide }}
            okButtonProps={{ className: classes.button }}
          >
            <Upload multiple onRemove={onRemove} beforeUpload={beforeUpload} fileList={fileList}>
              <Button icon={<CloudUploadOutlined />}>Select Files</Button>
            </Upload>
            <Button
              type="primary"
              onClick={() => onUpload()}
              disabled={fileList.length === 0}
              loading={uploading}
              style={{ marginTop: 16 }}
            >
              {uploading ? 'Uploading' : 'Upload'}
            </Button>
          </Modal>
        ) : null}
        {modalType === 'ViewFiles' ? (
          <Modal
            onOk={hideModal}
            open={modalType === 'ViewFiles'}
            centered
            maskClosable={false}
            title="View Files."
            width="800px"
            okText="Close"
            cancelButtonProps={{ className: classes.hide }}
            closable={false}
            okButtonProps={{ className: classes.button }}
          >
            <Table
              columns={filesTableColumns}
              bordered
              rowKey={record => record.id}
              dataSource={orderFilesList}
              size="small"
              pagination={false}
              scroll={{ x: 425 }}
            />
          </Modal>
        ) : null}

        {modalType === 'ViewOrderDetails' ? <JobCard order={order} hideModal={hideModal} /> : null}
        {order && orderId && modalType === 'EditJobCard' && (
          <EditJobCardModal recordId={orderId} order={order} hideModal={hideModal} />
        )}
        <Modal
          onOk={hideModal}
          open={modalType === 'ViewFormulationFiles'}
          centered
          maskClosable={false}
          title="View Product Files."
          width="800px"
          okText="Close"
          cancelButtonProps={{ className: classes.hide }}
          closable={false}
          okButtonProps={{ className: classes.button }}
        >
          <Table
            columns={[
              {
                title: 'File',
                render: record => (
                  <a href={record.url} rel="noreferrer noopener" target="_blank">
                    <Tooltip placement="bottom" title={`Click to open/download "${record.fileName}" file.`}>
                      {record.fileName}
                    </Tooltip>
                  </a>
                ),
              },
            ]}
            bordered
            rowKey={record => record.id}
            dataSource={formulationFilesList}
            size="small"
            pagination={{ position: ['bottomLeft'] }}
          />
        </Modal>
      </>
    </Structure>
  );
};

OrderList.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(OrderList);

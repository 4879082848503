import Api from '../Api';

export const getPaymentCategoryList = () => async dispatch => {
  try {
    const { data } = await Api.get('/dropdowns/payment-category');
    dispatch({
      type: 'FETCH_PAYMENT_CATEGORY_LIST',
      payload: data,
    });
  } catch (err) {
    throw err;
  }
};

export const clearPaymentCategoryList = () => {
  return {
    type: 'CLEAR_PAYMENT_CATEGORY_LIST',
  };
};

export const getPaymentCategory = id => async dispatch => {
  try {
    const { data } = await Api.get(`/dropdowns/payment-category/${id}`);
    dispatch({
      type: 'FETCH_PAYMENT_CATEGORY',
      payload: data,
    });
  } catch (err) {
    throw err;
  }
};

export const clearPaymentCategory = () => {
  return {
    type: 'CLEAR_PAYMENT_CATEGORY',
  };
};

export const getPaymentCategoryReport = () => async dispatch => {
  try {
    const { data } = await Api.get('/dropdowns/payment-category/report');
    dispatch({
      type: 'FETCH_PAYMENT_CATEGORY_REPPORT',
      payload: data,
    });
  } catch (err) {
    throw err;
  }
};

export const clearPaymentCategoryReport = () => {
  return {
    type: 'CLEAR_PAYMENT_CATEGORY_REPORT',
  };
};

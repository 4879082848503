import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import withStyles from 'react-jss';
import { reduxForm, Field, getFormValues } from 'redux-form';
import { useHistory } from 'react-router';
import { useSelector, useDispatch, connect } from 'react-redux';
import { Button, Input, Row, Select, Modal, Col, Popconfirm } from 'antd';
import startCase from 'lodash/startCase';
import Structure from '../Structure/Structure';
import styles from '../CommonStyles';
import { errorNotification, successNotification } from '../Snackbar';
import Api from '../Api';
import LeftGrid from '../Components/LeftGrid';
import RightGrid from '../Components/RightGrid';
import GetField from '../Components/GetField';
import Heading from '../Components/Heading';
import {
  getOrderList,
  clearOrderList,
  getMaterialIdList,
  clearMaterialIdList,
  getSupplierIdList,
  clearSupplierIdList,
  getPurchaseOrder,
  clearPurchaseOrder,
} from '../Actions';
import { get } from '../utils';
import constants from '../constants';

const AntSelect = GetField(Select);
const AntInput = GetField(Input);

const EditPurchaseOrder = props => {
  const { handleSubmit, classes, match, change } = props;
  const history = useHistory();
  const dispatch = useDispatch();

  const materialIdList = useSelector(state => state.materialIdList);
  const orderList = useSelector(state => state.orderList);
  const supplierIdList = useSelector(state => state.supplierIdList);

  const [formValueModal, setFormValuesModal] = useState(false);
  const [confirmLoading, setConfirmLoading] = useState(false);
  const [preview, setPreview] = useState({});
  const FormValues = useSelector(state => getFormValues('EditPurchaseOrder')(state));

  useEffect(() => {
    dispatch(getOrderList({ isAuthorized: true, isDispatched: false }));
    dispatch(getMaterialIdList());
    dispatch(getSupplierIdList({ status: 'Active' }));
    dispatch(getPurchaseOrder(match.params.id));
    return () => {
      dispatch(clearOrderList());
      dispatch(clearMaterialIdList());
      dispatch(clearSupplierIdList());
      dispatch(clearPurchaseOrder());
    };
  }, []);

  useEffect(() => {
    if (FormValues && FormValues.materialId) {
      const materialObj = materialIdList.find(material => material.id === +FormValues.materialId);
      if (materialObj) {
        change('lastPurchaseRate', get(materialObj, ['lastPurchase', 'rate'], null));
        change('lastPurchaseSuppier', get(materialObj, ['lastPurchase', 'supplier'], null));
      }
    }
  }, [FormValues && FormValues.materialId, materialIdList]);

  const showFormValuesModal = () => {
    const _preview = {
      orderNo: '--',
      formulation: '--',
      material: '--',
      type: '--',
      unit: '--',
      supplier: '--',
      quantity: get(FormValues, ['quantity'], '--'),
      rate: get(FormValues, ['rate'], '--'),
      leadTime: get(FormValues, ['leadTime'], '--'),
      remarks: get(FormValues, ['remarks'], '--'),
      consignor: get(FormValues, ['consignor'], '--'),
    };

    const orderId = get(FormValues, ['orderId'], null);
    if (orderId) {
      const orderObj = orderList.find(order => order.id === +orderId);
      if (orderObj) {
        _preview.orderNo = orderObj.id;
        _preview.formulation = orderObj.formulation.name;
      }
    }

    const materialNo = get(FormValues, ['materialId'], null);
    if (materialNo) {
      const materialObj = materialIdList.find(material => material.id === +materialNo);
      if (materialObj) {
        _preview.material = materialObj.name;
        _preview.type = startCase(materialObj.type);
        _preview.unit = materialObj.unit;
      }
    }

    const supplierId = get(FormValues, ['supplierId'], null);
    if (supplierId) {
      const supplierObj = supplierIdList.find(supplier => supplier.id === +supplierId);
      if (supplierObj) {
        _preview.supplier = supplierObj.name;
      }
    }

    setPreview(_preview);
    setFormValuesModal(true);
  };

  const hideFormValuesModal = () => {
    setFormValuesModal(false);
    setPreview({});
  };

  const onSubmit = async () => {
    try {
      setConfirmLoading(true);
      await Api.put(`/purchase-orders/${match.params.id}`, FormValues);
      successNotification('Purchase Order updated');
      setConfirmLoading(false);
      hideFormValuesModal();
      history.push('/purchaseorder/list');
    } catch (err) {
      setConfirmLoading(false);
      errorNotification(err);
    }
  };

  return (
    <Structure>
      <>
        <Heading text="Edit Purchase Order" />
        <form onSubmit={handleSubmit(onSubmit)}>
          <Row>
            <LeftGrid>
              <Field label="Material" allowClear disabled required name="materialId" component={AntSelect}>
                <Select.Option key={null} value="" disabled>
                  Select material
                </Select.Option>
                {materialIdList.map(material => (
                  <Select.Option key={material.id} value={material.id}>
                    {material.name}
                  </Select.Option>
                ))}
              </Field>
            </LeftGrid>
            <RightGrid>
              <Field label="Finished Good Name" disabled required name="formulation" component={AntInput} />
            </RightGrid>
          </Row>
          <Row>
            <LeftGrid>
              <Field label="Order Number" disabled required name="orderNo" component={AntInput} />
            </LeftGrid>
            <RightGrid>
              <Field label="Consignor" required name="consignor" component={AntSelect}>
                <Select.Option key={null} value="" disabled>
                  Select consignor
                </Select.Option>
                {Object.values(constants.consignors.list).map(consignor => (
                  <Select.Option key={consignor} value={consignor}>
                    {consignor}
                  </Select.Option>
                ))}
              </Field>
            </RightGrid>
          </Row>
          <Row>
            <LeftGrid>
              <Field
                label="Last Supplier"
                disabled
                allowClear
                name="lastPurchaseSuppier"
                placeholder="Last Supplier"
                component={AntInput}
              />
            </LeftGrid>
            <RightGrid>
              <Field label="Supplier" allowClear required name="supplierId" component={AntSelect}>
                <Select.Option key={null} value="" disabled>
                  Select supplier
                </Select.Option>
                {supplierIdList.map(supplier => (
                  <Select.Option key={supplier.id} value={supplier.id}>
                    {supplier.name}
                  </Select.Option>
                ))}
              </Field>
            </RightGrid>
          </Row>
          <Row>
            <LeftGrid>
              <Field label="Type" allowClear disabled required name="materialId" component={AntSelect}>
                <Select.Option key={null} value="" disabled>
                  Select material
                </Select.Option>
                {materialIdList.map(material => (
                  <Select.Option key={material.id} value={material.id}>
                    {startCase(material.type)}
                  </Select.Option>
                ))}
              </Field>
            </LeftGrid>
            <RightGrid>
              <Field label="Unit" allowClear disabled required name="materialId" component={AntSelect}>
                <Select.Option key={null} value="" disabled>
                  Select material
                </Select.Option>
                {materialIdList.map(material => (
                  <Select.Option key={material.id} value={material.id}>
                    {material.unit}
                  </Select.Option>
                ))}
              </Field>
            </RightGrid>
          </Row>
          <Row>
            <LeftGrid>
              <Field
                label="Quantity"
                required
                allowClear
                name="quantity"
                placeholder="Quantity"
                type="number"
                min={0}
                component={AntInput}
              />
            </LeftGrid>
            <RightGrid>
              <Field
                label="Last Purchase Rate"
                disabled
                allowClear
                name="lastPurchaseRate"
                placeholder="Last Purchase Rate"
                type="number"
                min={0}
                component={AntInput}
              />
            </RightGrid>
          </Row>
          <Row>
            <LeftGrid>
              <Field
                label="Rate"
                required
                allowClear
                type="number"
                min={0}
                name="rate"
                placeholder="Rate"
                component={AntInput}
              />
            </LeftGrid>
            <RightGrid>
              <Field
                label="Lead Time (in days)"
                allowClear
                type="number"
                min={0}
                name="leadTime"
                placeholder="Lead Time (in days)"
                component={AntInput}
              />
            </RightGrid>
          </Row>
          <Row>
            <LeftGrid>
              <Field label="Remarks" allowClear name="remarks" placeholder="Remarks" component={AntInput} />
            </LeftGrid>
          </Row>
          <Row className={classes.buttonContainer}>
            <Button className={classes.button} type="primary" onClick={showFormValuesModal}>
              Preview
            </Button>
          </Row>
        </form>
        <Modal
          onCancel={hideFormValuesModal}
          open={formValueModal}
          confirmLoading={confirmLoading}
          centered
          width="800px"
          maskClosable={false}
          closable={false}
          cancelText="Back"
          okButtonProps={{ className: classes.button }}
          okText={
            <Popconfirm
              title="Sure want to submit form?"
              onConfirm={() => onSubmit()}
              okText="Confirm"
              cancelText="Cancel"
            >
              Submit
            </Popconfirm>
          }
        >
          <Row>
            <Col xs={24} lg={12}>
              <p>
                Order Number:
                <span className={classes.detailColor}>{preview.orderNo}</span>
              </p>
            </Col>
            <Col xs={24} lg={12}>
              <p>
                Finished Good Name:
                <span className={classes.detailColor}>{preview.formulation}</span>
              </p>
            </Col>
          </Row>
          <Row>
            <Col xs={24} lg={12}>
              <p>
                Material:
                <span className={classes.detailColor}>{preview.material}</span>
              </p>
            </Col>
            <Col xs={24} lg={12}>
              <p>
                Type:
                <span className={classes.detailColor}>{preview.type}</span>
              </p>
            </Col>
          </Row>
          <Row>
            <Col xs={24} lg={12}>
              <p>
                Unit:
                <span className={classes.detailColor}>{preview.unit}</span>
              </p>
            </Col>
            <Col xs={24} lg={12}>
              <p>
                Supplier:
                <span className={classes.detailColor}>{preview.supplier}</span>
              </p>
            </Col>
          </Row>
          <Row>
            <Col xs={24} lg={12}>
              <p>
                Quantity:
                <span className={classes.detailColor}>{preview.quantity}</span>
              </p>
            </Col>
            <Col xs={24} lg={12}>
              <p>
                Rate:
                <span className={classes.detailColor}>{preview.rate}</span>
              </p>
            </Col>
          </Row>
          <Row>
            <Col xs={24} lg={12}>
              <p>
                Lead Time (in days):
                <span className={classes.detailColor}>{preview.leadTime}</span>
              </p>
            </Col>
            <Col xs={24} lg={12}>
              <p>
                Consignor:
                <span className={classes.detailColor}>{preview.consignor}</span>
              </p>
            </Col>
          </Row>
          <Row>
            <Col xs={24} lg={12}>
              <p>
                Remarks:
                <span className={classes.detailColor}>{preview.remarks}</span>
              </p>
            </Col>
          </Row>
        </Modal>
      </>
    </Structure>
  );
};

EditPurchaseOrder.propTypes = {
  handleSubmit: PropTypes.func.isRequired,
  classes: PropTypes.object.isRequired,
  match: PropTypes.object.isRequired,
  change: PropTypes.func.isRequired,
};

const StyledEditPurchaseOrder = withStyles(styles)(EditPurchaseOrder);

const FormedEditPurchaseOrder = reduxForm({
  form: 'EditPurchaseOrder',
  enableReinitialize: true,
})(StyledEditPurchaseOrder);

export default connect(({ purchaseOrder }) => ({ initialValues: purchaseOrder }), {})(FormedEditPurchaseOrder);

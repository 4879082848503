import Api from '../Api';

export const getSupplierList = params => async dispatch => {
  try {
    const { data } = await Api.get('/suppliers', params);
    dispatch({
      type: 'FETCH_SUPPLIER_LIST',
      payload: data,
    });
  } catch (err) {
    throw err;
  }
};

export const clearSupplierList = () => {
  return {
    type: 'CLEAR_SUPPLIER_LIST',
  };
};

export const getSupplier = id => async dispatch => {
  try {
    const { data } = await Api.get(`/suppliers/${id}`);
    dispatch({
      type: 'FETCH_SUPPLIER',
      payload: data,
    });
  } catch (err) {
    throw err;
  }
};

export const clearSupplier = () => {
  return {
    type: 'CLEAR_SUPPLIER',
  };
};

export const getSupplierIdList = params => async dispatch => {
  try {
    const { data } = await Api.get('/suppliers/ids', params);
    dispatch({
      type: 'FETCH_SUPPLIER_ID_LIST',
      payload: data,
    });
  } catch (err) {
    throw err;
  }
};

export const clearSupplierIdList = () => {
  return {
    type: 'CLEAR_SUPPLIER_ID_LIST',
  };
};

export const getSupplierLedger = id => async dispatch => {
  try {
    const { data } = await Api.get(`/suppliers/${id}/ledger`);
    dispatch({
      type: 'FETCH_SUPPLIER_LEDGER',
      payload: data,
    });
  } catch (err) {
    throw err;
  }
};

export const clearSupplierLedger = () => {
  return {
    type: 'CLEAR_SUPPLIER_LEDGER',
  };
};

export const getSupplierOpeningBalances = () => async dispatch => {
  try {
    const { data } = await Api.get(`/suppliers/openingbalances`);
    dispatch({
      type: 'FETCH_SUPPLIER_OPENING_BALANCES_LIST',
      payload: data,
    });
  } catch (err) {
    throw err;
  }
};

export const clearSupplierOpeningBalances = () => {
  return {
    type: 'CLEAR_SUPPLIER_OPENING_BALANCES_LIST',
  };
};

export const getSupplierOpeningBalance = id => async dispatch => {
  try {
    const { data } = await Api.get(`/suppliers/openingbalances/${id}`);
    dispatch({
      type: 'FETCH_SUPPLIER_OPENING_BALANCE',
      payload: data,
    });
  } catch (err) {
    throw err;
  }
};

export const clearSupplierOpeningBalance = () => {
  return {
    type: 'CLEAR_SUPPLIER_OPENING_BALANCE',
  };
};

export const getSupplierWisePayables = query => async dispatch => {
  try {
    const { data } = await Api.get(`/suppliers/dues`, query);
    dispatch({
      type: 'FETCH_SUPPLIER_WISE_PAYABLES',
      payload: data,
    });
  } catch (err) {
    throw err;
  }
};

export const clearSupplierWisePayables = () => {
  return {
    type: 'CLEAR_SUPPLIER_WISE_PAYABLES',
  };
};

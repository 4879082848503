import Api from '../Api';

export const getLedgerContactList = params => async dispatch => {
  try {
    const { data } = await Api.get('/contacts', params);
    dispatch({
      type: 'FETCH_LEDGER_CONTACT_LIST',
      payload: data,
    });
  } catch (err) {
    throw err;
  }
};

export const clearLedgerContactList = () => {
  return {
    type: 'CLEAR_LEDGER_CONTACT_LIST',
  };
};

export const getLedgerContact = id => async dispatch => {
  try {
    const { data } = await Api.get(`/contacts/${id}`);
    dispatch({
      type: 'FETCH_LEDGER_CONTACT',
      payload: data,
    });
  } catch (err) {
    throw err;
  }
};

export const clearLedgerContact = () => {
  return {
    type: 'CLEAR_LEDGER_CONTACT',
  };
};

export const getLedgerContactIdList = params => async dispatch => {
  try {
    const { data } = await Api.get('/contacts/ids', params);
    dispatch({
      type: 'FETCH_LEDGER_CONTACT_ID_LIST',
      payload: data,
    });
  } catch (err) {
    throw err;
  }
};

export const clearLedgerContactIdList = () => {
  return {
    type: 'CLEAR_LEDGER_CONTACT_ID_LIST',
  };
};

export const getLedgerContactLedger = id => async dispatch => {
  try {
    const { data } = await Api.get(`/contacts/${id}/ledger`);
    dispatch({
      type: 'FETCH_LEDGER_CONTACT_LEDGER',
      payload: data,
    });
  } catch (err) {
    throw err;
  }
};

export const clearLedgerContactLedger = () => {
  return {
    type: 'CLEAR_LEDGER_CONTACT_LEDGER',
  };
};

import React, { useCallback, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { Col, DatePicker, Input, Modal, Row, Popconfirm } from 'antd';
import { useForm, Controller } from 'react-hook-form';
import moment from 'moment';

import Api from '../../Api';
import { errorNotification, successNotification } from '../../Snackbar';
import GetRHFField from '../../Components/GetRHFField';

const AntInput = GetRHFField(Input);
const AntDatePicker = GetRHFField(DatePicker);

const AcceptExtension = props => {
  const { recordData, modalType, hideModal } = props;

  const { control, reset, getValues } = useForm({
    shouldUnregister: false,
  });

  const [confirmLoading, setConfirmLoading] = useState(false);

  const disabledDate = useCallback(current => {
    return current && current < moment().subtract(1, 'day').endOf('day');
  }, []);

  useEffect(() => {
    reset({
      newEndAt: moment(recordData?.requestedEndAt).format('YYYY-MM-DD HH:mm:ss'),
    });
  }, [recordData]);

  const onSubmit = useCallback(async () => {
    try {
      const formValues = getValues();

      setConfirmLoading(true);

      if (modalType === 'approveTask') {
        await Api.put(`/tasks/extensions/approve/${recordData.id}`, formValues);
      } else if (modalType === 'approveSubTask') {
        await Api.put(`/tasks/extensions/approve/subtasks/${recordData.id}`, formValues);
      }
      reset();
      setConfirmLoading(false);
      successNotification('Request approved Successfully');
      hideModal();
    } catch (err) {
      setConfirmLoading(false);
      errorNotification(err);
    }
  }, []);

  return (
    <Modal
      title="Extension Request Approval"
      confirmLoading={confirmLoading}
      open
      onCancel={hideModal}
      centered
      maskClosable={false}
      cancelText="Back"
      okText={
        <Popconfirm title="Sure want to submit form?" onConfirm={() => onSubmit()} okText="Confirm" cancelText="Cancel">
          Submit
        </Popconfirm>
      }
    >
      <form>
        <Row>
          <Col span={24}>
            <Controller
              name="newEndAt"
              control={control}
              render={({ field }) => (
                <AntDatePicker
                  allowClear
                  showTime={{ format: 'hh:mm A' }}
                  format="YYYY-MM-DD hh:mm A"
                  disabledDate={disabledDate}
                  label="End Date"
                  placeholder="End Date"
                  {...field}
                />
              )}
            />
          </Col>
        </Row>
        <Row>
          <Col span={24}>
            <Controller
              name="respondedRemark"
              control={control}
              render={({ field }) => (
                <AntInput
                  allowClear
                  required
                  autoSize={{ minRows: 6 }}
                  label="Remark"
                  placeholder="Remark"
                  {...field}
                />
              )}
            />
          </Col>
        </Row>
      </form>
    </Modal>
  );
};

AcceptExtension.propTypes = {
  recordData: PropTypes.object,
  modalType: PropTypes.string,
  hideModal: PropTypes.func.isRequired,
};

export default AcceptExtension;

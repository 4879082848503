import Api from '../Api';

export const getBankList = () => async dispatch => {
  try {
    const { data } = await Api.get('/bank-statements/banks');
    dispatch({
      type: 'FETCH_BANK_LIST',
      payload: data,
    });
  } catch (err) {
    throw err;
  }
};

export const clearBankList = () => {
  return {
    type: 'CLEAR_BANK_LIST',
  };
};

export const getTransactionList = query => async dispatch => {
  try {
    const { data } = await Api.get('/bank-statements', query);
    dispatch({
      type: 'FETCH_TRANSACTION_LIST',
      payload: data,
    });
  } catch (err) {
    throw err;
  }
};

export const clearTransactionList = () => {
  return {
    type: 'CLEAR_TRANSACTION_LIST',
  };
};

export const getTransaction = id => async dispatch => {
  try {
    const { data } = await Api.get(`/bank-statements/${id}`);
    dispatch({
      type: 'FETCH_TRANSACTION',
      payload: data,
    });
  } catch (err) {
    throw err;
  }
};

export const clearTransaction = () => {
  return {
    type: 'CLEAR_TRANSACTION',
  };
};

export const getFinancialSummary =
  ({ from, to, consignor }) =>
  async dispatch => {
    try {
      const { data } = await Api.post('/financial-reports/financialsummary', {
        from,
        to,
        consignor,
      });
      dispatch({
        type: 'FETCH_FINANCIAL_SUMMARY',
        payload: data,
      });
    } catch (err) {
      throw err;
    }
  };

export const clearFinancialSummary = () => {
  return {
    type: 'CLEAR_FINANCIAL_SUMMARY',
  };
};

export const getBalanceSheet = query => async dispatch => {
  try {
    const { data } = await Api.post('/financial-reports/balancesheet', query);
    dispatch({
      type: 'FETCH_BALANCE_SHEET',
      payload: data,
    });
  } catch (err) {
    throw err;
  }
};

export const clearBalanceSheet = () => {
  return {
    type: 'CLEAR_BALANCE_SHEET',
  };
};

export const getFinanceMIS = query => async dispatch => {
  try {
    const { data } = await Api.post('/financial-reports/mis', query);
    dispatch({
      type: 'FETCH_FINANCE_MIS',
      payload: data,
    });
  } catch (err) {
    throw err;
  }
};

export const clearFinanceMIS = () => {
  return {
    type: 'CLEAR_FINANCE_MIS',
  };
};

export const getTransactionFileDateWiseList = () => async dispatch => {
  try {
    const { data } = await Api.get(`/financial-files`);
    dispatch({
      type: 'FETCH_TRANSACTION_FILE_DATE_WISE_LIST',
      payload: data,
    });
  } catch (err) {
    throw err;
  }
};

export const clearTransactionFileDateWiseList = () => {
  return {
    type: 'CLEAR_TRANSACTION_FILE_DATE_WISE_LIST',
  };
};

export const getTransactionFilesList = date => async dispatch => {
  try {
    const { data } = await Api.get(`/financial-files/${date}/files`);
    dispatch({
      type: 'FETCH_TRANSACTION_FILES_LIST',
      payload: data,
    });
  } catch (err) {
    throw err;
  }
};

export const clearTransactionFilesList = () => {
  return {
    type: 'CLEAR_TRANSACTION_FILES_LIST',
  };
};

export const getWeeklyCollections = () => async dispatch => {
  try {
    const { data } = await Api.get('/bank-statements/debts');
    dispatch({
      type: 'FETCH_WEEKLY_COLLECTIONS',
      payload: data,
    });
  } catch (err) {
    throw err;
  }
};

export const clearWeeklyCollections = () => {
  return {
    type: 'CLEAR_WEEKLY_COLLECTIONS',
  };
};

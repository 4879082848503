export const subcategoryList = (state = [], action) => {
  if (action.type === 'FETCH_SUBCATEGORY_LIST') {
    return action.payload;
  }
  if (action.type === 'CLEAR_SUBCATEGORY_LIST') {
    return [];
  }
  return state;
};

export const subcategory = (state = {}, action) => {
  if (action.type === 'FETCH_SUBCATEGORY') {
    return action.payload;
  }
  if (action.type === 'CLEAR_SUBCATEGORY') {
    return {};
  }
  return state;
};

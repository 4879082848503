import Api from '../Api';

export const getAdjustedStockList = query => async dispatch => {
  try {
    const { data } = await Api.get('/material-inventory/adjustments', query);
    dispatch({
      type: 'FETCH_ADJUSTED_STOCK_LIST',
      payload: data,
    });
  } catch (err) {
    throw err;
  }
};

export const clearAdjustedStockList = () => {
  return {
    type: 'CLEAR_ADJUSTED_STOCK_LIST',
  };
};

export const getStockTransferList = query => async dispatch => {
  try {
    const { data } = await Api.get('/material-inventory/transfers', query);
    dispatch({
      type: 'FETCH_STOCK_TRANSFER_LIST',
      payload: data,
    });
  } catch (err) {
    throw err;
  }
};

export const clearStockTransferList = () => {
  return {
    type: 'CLEAR_STOCK_TRANSFER_LIST',
  };
};

import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import withStyles from 'react-jss';
import { reduxForm, Field, getFormValues } from 'redux-form';
import { Input, Select, Row, Modal, Button, DatePicker, Popconfirm, Col } from 'antd';
import { useHistory } from 'react-router';
import { useDispatch, useSelector } from 'react-redux';
import startCase from 'lodash/startCase';
import moment from 'moment';
import styles from '../CommonStyles';
import LeftGrid from '../Components/LeftGrid';
import RightGrid from '../Components/RightGrid';
import GetField from '../Components/GetField';
import { errorNotification, successNotification } from '../Snackbar';
import Api from '../Api';
import Structure from '../Structure/Structure';
import constants from '../constants';
import Heading from '../Components/Heading';
import {
  getMaterialIdList,
  clearMaterialIdList,
  getPurchaseOrderIdList,
  clearPurchaseOrderIdList,
  getSupplierIdList,
  clearSupplierIdList,
} from '../Actions';
import { get } from '../utils';

const AntInput = GetField(Input);
const AntSelect = GetField(Select);
const AntDatePicker = GetField(DatePicker);

const NewPurchase = props => {
  const { handleSubmit, classes, change } = props;
  const history = useHistory();
  const dispatch = useDispatch();

  const materialIdList = useSelector(state => state.materialIdList);
  const purchaseOrderIdList = useSelector(state => state.purchaseOrderIdList);
  const supplierIdList = useSelector(state => state.supplierIdList);

  const [formValueModal, setFormValuesModal] = useState(false);
  const [confirmLoading, setConfirmLoading] = useState(false);
  const [preview, setPreview] = useState({});
  const FormValues = useSelector(state => getFormValues('NewPurchase')(state));

  useEffect(() => {
    dispatch(getMaterialIdList());
    dispatch(getSupplierIdList({ status: 'Active' }));
    return () => {
      dispatch(clearMaterialIdList());
      dispatch(clearSupplierIdList());
      dispatch(clearPurchaseOrderIdList());
    };
  }, []);

  useEffect(() => {
    if (FormValues && FormValues.materialId) {
      dispatch(getPurchaseOrderIdList({ materialId: FormValues && FormValues.materialId }));
      const obj = materialIdList.find(po => po.id === FormValues.materialId);
      if (obj) {
        change('gst', obj.gst);
      }
    }
  }, [FormValues && FormValues.materialId, materialIdList]);

  useEffect(() => {
    if (FormValues && FormValues.purchaseOrderId) {
      const obj = purchaseOrderIdList.find(po => po.id === FormValues.purchaseOrderId);
      if (obj) {
        change('quantity', obj.quantity);
        change('rate', obj.rate);
        change('supplierId', obj.supplierId);
      }
    }
  }, [FormValues && FormValues.purchaseOrderId, purchaseOrderIdList]);

  useEffect(() => {
    if (FormValues && FormValues.quantity && FormValues.rate) {
      const value = (+FormValues.quantity * +FormValues.rate).toFixed(2);
      change('value', value);
    } else {
      change('value', '');
    }
  }, [FormValues && FormValues.quantity, FormValues && FormValues.rate]);

  useEffect(() => {
    if (FormValues && FormValues.value && (FormValues.gst || FormValues.gst === 0)) {
      const gstValue = ((+FormValues.value * +FormValues.gst) / 100).toFixed(2);
      change('gstValue', gstValue);
    } else {
      change('gstValue', '');
    }
  }, [FormValues && FormValues.value, FormValues && FormValues.gst]);

  useEffect(() => {
    if (FormValues && FormValues.forwardingCharges) {
      const forwardingChargesTax = ((+FormValues.forwardingCharges * 18) / 100).toFixed(2);
      change('forwardingChargesTax', forwardingChargesTax);
    } else {
      change('forwardingChargesTax', '');
    }
  }, [FormValues && FormValues.forwardingCharges]);

  useEffect(() => {
    if (FormValues && FormValues.value && FormValues && FormValues.gstValue) {
      const total = (
        +FormValues.value +
        +FormValues.gstValue +
        (+FormValues.forwardingCharges || 0) +
        (+FormValues.forwardingChargesTax || 0) +
        (+FormValues.otherChargesDiscount || 0) +
        (+FormValues.roundOf || 0)
      ).toFixed(2);
      change('total', total);
    }
  }, [
    FormValues && FormValues.value,
    FormValues && FormValues.gstValue,
    FormValues && FormValues.forwardingCharges,
    FormValues && FormValues.forwardingChargesTax,
    FormValues && FormValues.otherChargesDiscount,
    FormValues && FormValues.roundOf,
  ]);

  const showFormValuesModal = () => {
    const voucherDate = get(FormValues, ['voucherDate']);
    const mfgDate = get(FormValues, ['mfgDate']);
    const expDate = get(FormValues, ['expDate']);
    const type = get(FormValues, ['type']);

    const _preview = {
      consignor: get(FormValues, ['consignor'], '--'),
      material: '--',
      materialType: '--',
      unit: '--',
      hsn: '--',
      purchaseOrderNo: '--',
      supplier: '--',
      voucherNo: get(FormValues, ['voucherNo'], '--'),
      voucherDate: voucherDate ? moment(voucherDate).format('DD MMM YYYY') : '--',
      batch: get(FormValues, ['batch'], '--'),
      mfgDate: mfgDate ? moment(mfgDate).format('DD MMM YYYY') : '--',
      expDate: expDate ? moment(expDate).format('DD MMM YYYY') : '--',
      type: type ? startCase(type) : '--',
      quantity: get(FormValues, ['quantity'], '--'),
      purchaseOrderRate: '--',
      rate: get(FormValues, ['rate'], '--'),
      value: get(FormValues, ['value'], '--'),
      gst: get(FormValues, ['gst'], '--'),
      gstValue: get(FormValues, ['gstValue'], '--'),
      forwardingCharges: get(FormValues, ['forwardingCharges'], '--'),
      forwardingChargesTax: get(FormValues, ['forwardingChargesTax'], '--'),
      otherChargesDiscount: get(FormValues, ['otherChargesDiscount'], '--'),
      roundOf: get(FormValues, ['roundOf'], '--'),
      total: get(FormValues, ['total'], '--'),
      remarks: get(FormValues, ['remarks'], '--'),
    };

    const materialId = get(FormValues, ['materialId'], null);
    if (materialId) {
      const materialObj = materialIdList.find(material => material.id === +materialId);
      if (materialObj) {
        _preview.material = materialObj.name;
        _preview.materialType = startCase(materialObj.type);
        _preview.unit = materialObj.unit;
        _preview.hsn = materialObj.hsnCode;
      }
    }

    const purchaseOrderId = get(FormValues, ['purchaseOrderId'], null);
    if (purchaseOrderId) {
      const poObj = purchaseOrderIdList.find(po => po.id === +purchaseOrderId);
      if (poObj) {
        _preview.purchaseOrderNo = poObj.id;
        _preview.purchaseOrderRate = poObj.rate;
      }
    }

    const supplierId = get(FormValues, ['supplierId'], null);
    if (supplierId) {
      const supplierObj = supplierIdList.find(supplier => supplier.id === +supplierId);
      if (supplierObj) {
        _preview.supplier = supplierObj.name;
      }
    }

    setPreview(_preview);
    setFormValuesModal(true);
  };

  const hideFormValuesModal = () => {
    setFormValuesModal(false);
    setPreview({});
  };

  const onSubmit = async () => {
    try {
      setConfirmLoading(true);
      await Api.post('/purchases', FormValues);
      successNotification('Purchase added');
      setConfirmLoading(false);
      hideFormValuesModal();
      setConfirmLoading(false);
      history.push('/purchase/list');
    } catch (err) {
      setConfirmLoading(false);
      errorNotification(err);
    }
  };

  return (
    <Structure>
      <>
        <Heading text="New Purchase" />
        <form onSubmit={handleSubmit(onSubmit)}>
          <Row>
            <LeftGrid>
              <Field label="Consignor" required name="consignor" component={AntSelect}>
                <Select.Option key={null} value="" disabled>
                  Select consignor
                </Select.Option>
                {Object.values(constants.consignors.list).map(consignor => (
                  <Select.Option key={consignor} value={consignor}>
                    {consignor}
                  </Select.Option>
                ))}
              </Field>
            </LeftGrid>
            <RightGrid>
              <Field label="Material" allowClear required name="materialId" component={AntSelect}>
                <Select.Option key={null} value="" disabled>
                  Select material
                </Select.Option>
                {materialIdList.map(material => (
                  <Select.Option key={material.id} value={material.id}>
                    {material.name}
                  </Select.Option>
                ))}
              </Field>
            </RightGrid>
          </Row>
          <Row>
            <LeftGrid>
              <Field label="Purchase Order Number" required name="purchaseOrderId" component={AntSelect}>
                <Select.Option key={null} value="">
                  Select purchase order number
                </Select.Option>
                {purchaseOrderIdList.map(po => (
                  <Select.Option key={po.id} value={po.id}>
                    {po.id}
                  </Select.Option>
                ))}
              </Field>
            </LeftGrid>
            <RightGrid>
              <Field label="Supplier" required name="supplierId" component={AntSelect}>
                <Select.Option key={null} value="" disabled>
                  Select supplier
                </Select.Option>
                {supplierIdList.map(supplier => (
                  <Select.Option key={supplier.id} value={supplier.id}>
                    {supplier.name}
                  </Select.Option>
                ))}
              </Field>
            </RightGrid>
          </Row>
          <Row>
            <LeftGrid>
              <Field label="Type" allowClear disabled required name="materialId" component={AntSelect}>
                <Select.Option key={null} value="" disabled>
                  Select material
                </Select.Option>
                {materialIdList.map(material => (
                  <Select.Option key={material.id} value={material.id}>
                    {startCase(material.type)}
                  </Select.Option>
                ))}
              </Field>
            </LeftGrid>
            <RightGrid>
              <Field label="Unit" allowClear disabled required name="materialId" component={AntSelect}>
                <Select.Option key={null} value="" disabled>
                  Select material
                </Select.Option>
                {materialIdList.map(material => (
                  <Select.Option key={material.id} value={material.id}>
                    {material.unit}
                  </Select.Option>
                ))}
              </Field>
            </RightGrid>
          </Row>
          <Row>
            <LeftGrid>
              <Field label="HSN Code" allowClear disabled required name="materialId" component={AntSelect}>
                <Select.Option key={null} value="" disabled>
                  Select material
                </Select.Option>
                {materialIdList.map(material => (
                  <Select.Option key={material.id} value={material.id}>
                    {material.hsnCode}
                  </Select.Option>
                ))}
              </Field>
            </LeftGrid>
            <RightGrid>
              <Field label="Purchase Type" required name="type" component={AntSelect}>
                <Select.Option key={null} value="" disabled>
                  Select purchase type
                </Select.Option>
                <Select.Option key="invoice" value="invoice">
                  Invoice
                </Select.Option>
                <Select.Option key="challan" value="challan">
                  Challan
                </Select.Option>
              </Field>
            </RightGrid>
          </Row>
          <Row>
            <LeftGrid>
              <Field
                label="Voucher Number"
                allowClear
                name="voucherNo"
                placeholder="Voucher Number"
                component={AntInput}
              />
            </LeftGrid>
            <RightGrid>
              <Field
                allowClear
                label="Voucher Date"
                name="voucherDate"
                placeholder="Voucher Date"
                component={AntDatePicker}
              />
            </RightGrid>
          </Row>
          <Row>
            <LeftGrid>
              <Field
                label="Quantity"
                allowClear
                type="number"
                min={0}
                name="quantity"
                placeholder="Quantity"
                component={AntInput}
              />
            </LeftGrid>
            <RightGrid>
              <Field label="Batch" allowClear name="batch" placeholder="Batch" component={AntInput} />
            </RightGrid>
          </Row>
          <Row>
            <LeftGrid>
              <Field
                allowClear
                name="mfgDate"
                label="Manufacturing Date"
                placeholder="Manufacturing Date"
                component={AntDatePicker}
              />
            </LeftGrid>
            <RightGrid>
              <Field
                allowClear
                name="expDate"
                label="Expiry Date"
                placeholder="Expiry Date"
                component={AntDatePicker}
              />
            </RightGrid>
          </Row>
          <Row>
            <LeftGrid>
              <Field label="Purchase Order Rate" disabled required name="purchaseOrderId" component={AntSelect}>
                <Select.Option key={null} value="" disabled>
                  Select purchase order number
                </Select.Option>
                {purchaseOrderIdList.map(po => (
                  <Select.Option key={po.id} value={po.id}>
                    {po.rate}
                  </Select.Option>
                ))}
              </Field>
            </LeftGrid>
            <RightGrid>
              <Field
                label="Rate"
                allowClear
                type="number"
                min={0}
                name="rate"
                placeholder="Rate"
                component={AntInput}
              />
            </RightGrid>
          </Row>
          <Row>
            <LeftGrid>
              <Field
                label="Purchase Value"
                disabled
                allowClear
                type="number"
                min={0}
                name="value"
                placeholder="Purchase Value"
                component={AntInput}
              />
            </LeftGrid>
            <RightGrid>
              <Field
                label="GST (in %)"
                allowClear
                type="number"
                min={0}
                name="gst"
                placeholder="GST (in %)"
                component={AntInput}
              />
            </RightGrid>
          </Row>
          <Row>
            <LeftGrid>
              <Field
                label="GST Value"
                disabled
                allowClear
                type="number"
                min={0}
                name="gstValue"
                placeholder="GST Value"
                component={AntInput}
              />
            </LeftGrid>
            <RightGrid>
              <Field
                label="Forwarding Charges"
                allowClear
                type="number"
                min={0}
                name="forwardingCharges"
                placeholder="Forwarding Charges"
                component={AntInput}
              />
            </RightGrid>
          </Row>
          <Row>
            <LeftGrid>
              <Field
                label="Forwarding Charges Tax Value (Tax 18%)"
                allowClear
                disabled
                type="number"
                min={0}
                name="forwardingChargesTax"
                placeholder="Forwarding Charges Tax Value (Tax 18%)"
                component={AntInput}
              />
            </LeftGrid>
            <RightGrid>
              <Field
                label="Other Charges/Discount (+/-)"
                allowClear
                type="number"
                name="otherChargesDiscount"
                placeholder="Other Charges/Discount (+/-)"
                component={AntInput}
              />
            </RightGrid>
          </Row>
          <Row>
            <LeftGrid>
              <Field
                label="Round of (+/-)"
                allowClear
                type="number"
                name="roundOf"
                placeholder="Round of (+/-)"
                component={AntInput}
              />
            </LeftGrid>
            <RightGrid>
              <Field
                label="Total"
                disabled
                allowClear
                type="number"
                min={0}
                name="total"
                placeholder="Total"
                component={AntInput}
              />
            </RightGrid>
          </Row>
          <Row>
            <LeftGrid>
              <Field label="Remarks" allowClear name="remarks" placeholder="Remarks" component={AntInput} />
            </LeftGrid>
          </Row>
          <Row className={classes.buttonContainer}>
            <Button className={classes.button} type="primary" onClick={showFormValuesModal}>
              Preview
            </Button>
          </Row>
        </form>
        <Modal
          onCancel={hideFormValuesModal}
          open={formValueModal}
          confirmLoading={confirmLoading}
          style={{ top: 10 }}
          maskClosable={false}
          closable={false}
          cancelText="Back"
          okButtonProps={{ className: classes.button }}
          okText={
            <Popconfirm
              title="Sure want to submit form?"
              onConfirm={() => onSubmit()}
              okText="Confirm"
              cancelText="Cancel"
            >
              Submit
            </Popconfirm>
          }
          title="Purchase Details"
        >
          <Row>
            <Col span={24}>
              <p>
                Consignor:
                <span className={classes.detailColor}>{preview.consignor}</span>
              </p>
            </Col>
            <Col span={24}>
              <p>
                Material:
                <span className={classes.detailColor}>{preview.material}</span>
              </p>
            </Col>
            <Col span={24}>
              <p>
                Type:
                <span className={classes.detailColor}>{preview.materialType}</span>
              </p>
            </Col>
            <Col span={24}>
              <p>
                Purchase Order Number:
                <span className={classes.detailColor}>{preview.purchaseOrderNo}</span>
              </p>
            </Col>
            <Col span={24}>
              <p>
                Supplier:
                <span className={classes.detailColor}>{preview.supplier}</span>
              </p>
            </Col>
            <Col span={24}>
              <p>
                Unit:
                <span className={classes.detailColor}>{preview.unit}</span>
              </p>
            </Col>
            <Col span={24}>
              <p>
                HSN Code:
                <span className={classes.detailColor}>{preview.hsn}</span>
              </p>
            </Col>
            <Col span={24}>
              <p>
                Voucher Number:
                <span className={classes.detailColor}>{preview.voucherNo}</span>
              </p>
            </Col>
            <Col span={24}>
              <p>
                Voucher Date:
                <span className={classes.detailColor}>{preview.voucherDate}</span>
              </p>
            </Col>
            <Col span={24}>
              <p>
                Batch:
                <span className={classes.detailColor}>{preview.batch}</span>
              </p>
            </Col>
            <Col span={24}>
              <p>
                Manufacturing Date:
                <span className={classes.detailColor}>{preview.mfgDate}</span>
              </p>
            </Col>
            <Col span={24}>
              <p>
                Expiry Date:
                <span className={classes.detailColor}>{preview.expDate}</span>
              </p>
            </Col>
            <Col span={24}>
              <p>
                Purchase Type:
                <span className={classes.detailColor}>{preview.type}</span>
              </p>
            </Col>
            <Col span={24}>
              <p>
                Quantity:
                <span className={classes.detailColor}>{preview.quantity}</span>
              </p>
            </Col>
            <Col span={24}>
              <p>
                Purchase Order Rate:
                <span className={classes.detailColor}>{preview.purchaseOrderRate}</span>
              </p>
            </Col>
            <Col span={24}>
              <p>
                Rate:
                <span className={classes.detailColor}>{preview.rate}</span>
              </p>
            </Col>
            <Col span={24}>
              <p>
                Purchase Value:
                <span className={classes.detailColor}>{preview.value}</span>
              </p>
            </Col>
            <Col span={24}>
              <p>
                GST (in %):
                <span className={classes.detailColor}>{preview.gst}</span>
              </p>
            </Col>
            <Col span={24}>
              <p>
                GST Value:
                <span className={classes.detailColor}>{preview.gstValue}</span>
              </p>
            </Col>
            <Col span={24}>
              <p>
                Forwarding Charges:
                <span className={classes.detailColor}>{preview.forwardingCharges}</span>
              </p>
            </Col>
            <Col span={24}>
              <p>
                Forwarding Charges Tax Value (Tax 18%):
                <span className={classes.detailColor}>{preview.forwardingChargesTax}</span>
              </p>
            </Col>
            <Col span={24}>
              <p>
                Other Charges/Discount (+/-):
                <span className={classes.detailColor}>{preview.otherChargesDiscount}</span>
              </p>
            </Col>
            <Col span={24}>
              <p>
                Round of (+/-):
                <span className={classes.detailColor}>{preview.roundOf}</span>
              </p>
            </Col>
            <Col span={24}>
              <p>
                Total:
                <span className={classes.detailColor}>{preview.total}</span>
              </p>
            </Col>
            <Col span={24}>
              <p>
                Remarks:
                <span className={classes.detailColor}>{preview.remarks}</span>
              </p>
            </Col>
          </Row>
        </Modal>
      </>
    </Structure>
  );
};

NewPurchase.propTypes = {
  handleSubmit: PropTypes.func.isRequired,
  classes: PropTypes.object.isRequired,
  change: PropTypes.func.isRequired,
};

const StyledNewPurchase = withStyles(styles)(NewPurchase);

const FormedNewPurchase = reduxForm({
  form: 'NewPurchase',
  enableReinitialize: true,
})(StyledNewPurchase);

export default FormedNewPurchase;

import React, { useCallback, useEffect, useMemo, useState } from 'react';
import PropTypes from 'prop-types';
import withStyles from 'react-jss';
import {
  Descriptions,
  Tabs,
  Button,
  Timeline,
  Tooltip,
  Comment,
  Row,
  Col,
  Popconfirm,
  Table,
  Collapse,
  List,
} from 'antd';
import { useDispatch, useSelector } from 'react-redux';
import moment from 'moment';
import {
  CloudUploadOutlined,
  CommentOutlined,
  DeleteOutlined,
  EditOutlined,
  FileTextOutlined,
  PlusOutlined,
} from '@ant-design/icons';
import { Link } from 'react-router-dom/cjs/react-router-dom.min';
import {
  getCustomer,
  clearCustomer,
  getCustomerNotes,
  clearCustomerNotes,
  clearProformaInvoices,
  getProformaInvoices,
} from '../Actions';
import styles from '../CommonStyles';
import Structure from '../Structure/Structure';
import Heading from '../Components/Heading';
import { errorNotification, successNotification } from '../Snackbar';
import Api from '../Api';
import { get, onFilter, sorter, filterDropdown, dateSorter, onDateFilter, dateFilterDropdown } from '../utils';
import LeadQueryModal from '../CRM/Lead/LeadQueryModal';
import QueryCommentModal from '../CRM/Lead/Query-Comment-Modal';
import QueryFileModal from '../CRM/Lead/Query-File-Modal';

const { TabPane } = Tabs;
const { Panel } = Collapse;

const ViewCustomer = props => {
  const { classes, match } = props;

  const dispatch = useDispatch();

  const auth = useSelector(state => state.auth);
  const role = get(auth, ['user', 'role'], 'team');

  const [ModalType, SetModalType] = useState(null);
  const [QueryId, SetQueryId] = useState(null);

  useEffect(() => {
    dispatch(getCustomer(match.params.id));
    dispatch(getCustomerNotes(match.params.id));
    return () => {
      dispatch(clearCustomer());
      dispatch(clearCustomerNotes());
    };
  }, []);

  const customer = useSelector(state => state.customer);
  const customerNotes = useSelector(state => state.customerNotes);
  const proformaInvoices = useSelector(state => state.proformaInvoices);

  const Query = useMemo(() => customer?.queries?.find(el => el.id === QueryId), [QueryId, customer]);

  const showModal = useCallback((type, id) => {
    SetModalType(type);
    SetQueryId(id);
  }, []);

  const hideModal = useCallback(() => {
    SetModalType(null);
    SetQueryId(null);
    dispatch(getCustomer(match.params.id));
  }, []);

  const deleteQuery = useCallback(async id => {
    try {
      await Api.delete(`/leads/queries/${id}`);
      successNotification('Query deleted');
      dispatch(getCustomer(match.params.id));
    } catch (err) {
      errorNotification(err);
    }
  });

  const deleteQueryRemark = useCallback(async id => {
    try {
      await Api.delete(`/leads/queries/remarks/${id}`);
      successNotification('Remark deleted');
      dispatch(getCustomer(match.params.id));
    } catch (err) {
      errorNotification(err);
    }
  });

  const fetchTabData = useCallback(key => {
    if (key === 'Proforma_Invoice') {
      dispatch(clearProformaInvoices());
      dispatch(getProformaInvoices({ partyId: match.params.id }));
    }
  }, []);

  const columns = useMemo(() => {
    const _columns = [
      {
        title: 'PI #',
        dataIndex: 'piNo',
        sorter: sorter('piNo'),
        onFilter: onFilter('piNo'),
        filterDropdown,
        width: '8%',
      },
      {
        title: 'Date',
        dataIndex: 'createdAt',
        sorter: dateSorter('createdAt'),
        onFilter: onDateFilter('createdAt'),
        filterDropdown: dateFilterDropdown,
        render: date => (date ? moment(date).format('DD MMM YYYY') : null),
        width: '8%',
      },
      {
        title: 'Query',
        dataIndex: 'query',
        sorter: sorter('query'),
        onFilter: onFilter('query'),
        filterDropdown,
        width: '12%',
      },
      {
        title: 'Products',
        dataIndex: 'products',
        sorter: sorter('products'),
        onFilter: onFilter('products'),
        filterDropdown,
        render: text => (text.length ? text.join(', ') : '-'),
      },
      {
        title: 'Grand Total',
        dataIndex: 'grandTotal',
        render: text => Number(text).toLocaleString('en-IN'),
        width: '8%',
      },
      {
        title: 'Remark',
        dataIndex: 'remark',
      },
      {
        title: 'Detail',
        key: 'Detail',
        width: '6%',
        render: (_text, record) => (
          <Tooltip placement="bottom" title="Detail">
            <Link to={`/proformainvoices/detail/${record.id}`}>
              <FileTextOutlined />
            </Link>
          </Tooltip>
        ),
      },
    ];

    return _columns;
  }, []);

  const rowKey = useCallback(record => record.id, []);
  const scroll = useMemo(() => ({ x: 1024, y: window.innerHeight - 300 }), []);

  return (
    <Structure>
      <>
        <Heading text="Customer Details" />
        <Descriptions size="small" bordered column={{ xs: 1, md: 2, lg: 2, xl: 2, xxl: 3 }}>
          <Descriptions.Item label="Party">{customer.name}</Descriptions.Item>
          <Descriptions.Item label="Category">{customer.categories}</Descriptions.Item>
          <Descriptions.Item label="Billing Address">{customer.billingAddress}</Descriptions.Item>
          <Descriptions.Item label="Corr. Address">{customer.corrAddress}</Descriptions.Item>
          <Descriptions.Item label="GST #">{customer.gstNo}</Descriptions.Item>
          <Descriptions.Item label="State">{customer.state}</Descriptions.Item>
          <Descriptions.Item label="Primary Contact">{customer.primaryContact}</Descriptions.Item>
          <Descriptions.Item label="Contacts">{customer.contacts}</Descriptions.Item>
          <Descriptions.Item label="Primary Email">{customer.primaryEmail}</Descriptions.Item>
          <Descriptions.Item label="Emails">{customer.emails}</Descriptions.Item>
          <Descriptions.Item label="Contact Person">{customer.contactPerson}</Descriptions.Item>
          <Descriptions.Item label="Handler">{customer.handler}</Descriptions.Item>
          <Descriptions.Item label="Payment Terms (in days)">{customer.paymentTerms}</Descriptions.Item>
          <Descriptions.Item label="Payment Category">{customer.paymentCategory}</Descriptions.Item>
          <Descriptions.Item label="Remark">{customer.remarks}</Descriptions.Item>
        </Descriptions>
        <br />
        <Row>
          <Col
            sm={{ span: 22, offset: 1 }}
            md={{ span: 22, offset: 1 }}
            lg={{ span: 20, offset: 2 }}
            xl={{ span: 20, offset: 2 }}
          >
            <Tabs defaultActiveKey="Queries" centered onChange={fetchTabData}>
              <TabPane tab="Queries" key="Queries">
                <Button type="primary" onClick={() => showModal('Add_Query_Modal')}>
                  Add Query
                </Button>
                <br />
                <Timeline>
                  {(customer.queries || []).map(query => (
                    <Timeline.Item key={query.id} dot={<CommentOutlined className={classes.commentIcon} />}>
                      <Comment
                        actions={
                          role === 'Owner'
                            ? [
                                <Tooltip placement="bottom" title="Edit">
                                  <EditOutlined onClick={() => showModal('Add_Query_Modal', query.id)} />
                                </Tooltip>,
                                <Tooltip placement="bottom" title="Delete">
                                  <Popconfirm
                                    title="Are you sure you want to delete?"
                                    onConfirm={() => deleteQuery(query.id)}
                                    okText="Yes"
                                    cancelText="No"
                                  >
                                    <DeleteOutlined />
                                  </Popconfirm>
                                </Tooltip>,
                              ]
                            : null
                        }
                        author={query.createdBy}
                        content={
                          <Collapse>
                            <Panel header={query?.query} key={query?.id}>
                              <Button type="primary" onClick={() => showModal('Add_Query_Remark', query.id)}>
                                <PlusOutlined /> Remark
                              </Button>
                              &nbsp;&nbsp;&nbsp;&nbsp;
                              <Button
                                type="primary"
                                icon={<CloudUploadOutlined />}
                                onClick={() => showModal('QueryFileModal', query.id)}
                              >
                                Files
                              </Button>
                              <br />
                              <br />
                              <List
                                size="small"
                                header={false}
                                footer={false}
                                borderless="true"
                                dataSource={query?.query_remarks || []}
                                renderItem={item => (
                                  <List.Item
                                    actions={[
                                      <Tooltip placement="bottom" title="Delete">
                                        <Popconfirm
                                          title="Are you sure you want to delete?"
                                          onConfirm={() => deleteQueryRemark(item.id)}
                                          okText="Yes"
                                          cancelText="No"
                                        >
                                          <DeleteOutlined />
                                        </Popconfirm>
                                      </Tooltip>,
                                    ]}
                                  >
                                    <List.Item.Meta
                                      title={
                                        <span>
                                          {item.createdBy} | {moment(item.createdAt).fromNow()}
                                        </span>
                                      }
                                      description={<span>Assigned To: {item.assignedTo}</span>}
                                    />
                                    {item.remark}
                                  </List.Item>
                                )}
                              />
                            </Panel>
                          </Collapse>
                        }
                        datetime={
                          <Tooltip title={moment(query.createdAt).format('YYYY-MM-DD HH:mm:ss')}>
                            <span>{moment(query.createdAt).fromNow()}</span>
                          </Tooltip>
                        }
                      />
                    </Timeline.Item>
                  ))}
                </Timeline>
              </TabPane>
              <TabPane tab="Proforma Invoice" key="Proforma_Invoice">
                <Table
                  bordered
                  size="small"
                  columns={columns}
                  dataSource={proformaInvoices}
                  rowKey={rowKey}
                  pagination={false}
                  scroll={scroll}
                />
              </TabPane>
              <TabPane tab="Payment Follow-ups" key="Payment_Followups">
                <Timeline>
                  {(customerNotes || []).map((note, index) => (
                    <Timeline.Item key={index}>
                      <Comment
                        author={<a>{note.createdBy}</a>}
                        content={note.note}
                        datetime={
                          <Tooltip title={moment(note.createdAt).format('YYYY-MM-DD HH:mm:ss')}>
                            <span>{moment(note.createdAt).fromNow()}</span>
                          </Tooltip>
                        }
                      />
                    </Timeline.Item>
                  ))}
                </Timeline>
              </TabPane>
            </Tabs>
          </Col>
        </Row>
        {ModalType === 'Add_Query_Modal' ? (
          <LeadQueryModal query={Query} partyId={match.params.id} modalType={ModalType} hideModal={hideModal} />
        ) : null}
        {ModalType === 'Add_Query_Remark' ? <QueryCommentModal query={Query} hideModal={hideModal} /> : null}
        {ModalType === 'QueryFileModal' && <QueryFileModal query={Query} hideModal={hideModal} />}
      </>
    </Structure>
  );
};

ViewCustomer.propTypes = {
  classes: PropTypes.object.isRequired,
  match: PropTypes.object.isRequired,
};

export default withStyles(styles)(ViewCustomer);

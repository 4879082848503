/* eslint-disable no-nested-ternary */
import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { Button, Col, DatePicker, Input, Popconfirm, Row, Select } from 'antd';
import withStyles from 'react-jss';
import { useHistory } from 'react-router';
import { Field, getFormValues, reduxForm } from 'redux-form';
import { connect, useDispatch, useSelector } from 'react-redux';
import Modal from 'antd/lib/modal/Modal';
import moment from 'moment';
import startCase from 'lodash/startCase';
import GetField from '../Components/GetField';
import styles from '../CommonStyles';
import { errorNotification, successNotification } from '../Snackbar';
import Api from '../Api';
import { get } from '../utils';
import Structure from '../Structure/Structure';

import Heading from '../Components/Heading';
import LeftGrid from '../Components/LeftGrid';
import RightGrid from '../Components/RightGrid';
import {
  clearCourier,
  clearCustomerIdList,
  clearSupplierIdList,
  getCourier,
  getCustomerIdList,
  getSupplierIdList,
} from '../Actions';

const AntInput = GetField(Input);
const AntSelect = GetField(Select);
const AntDatePicker = GetField(DatePicker);

const EditCourier = props => {
  const { handleSubmit, classes, change, match } = props;
  const history = useHistory();
  const dispatch = useDispatch();

  const customerIdList = useSelector(state => state.customerIdList);
  const supplierIdList = useSelector(state => state.supplierIdList);

  const [formValueModal, setFormValuesModal] = useState(false);
  const [confirmLoading, setConfirmLoading] = useState(false);
  const [preview, setPreview] = useState({});
  const FormValues = useSelector(state => getFormValues('EditCourier')(state));

  useEffect(() => {
    dispatch(getCourier(match.params.id));
    dispatch(getCustomerIdList({ status: 'Active' }));
    dispatch(getSupplierIdList({ status: 'Active' }));
    return () => {
      dispatch(clearCourier());
      dispatch(clearCustomerIdList());
      dispatch(clearSupplierIdList());
    };
  }, []);

  useEffect(() => {
    if (FormValues && FormValues.consigneeType === 'customer') {
      change('supplierId', null);
      change('consignee', null);
      change('primaryContact', null);
      change('primaryEmail', null);
      change('corrAddress', null);
    }
    if (FormValues && FormValues.consigneeType === 'supplier') {
      change('customerId', null);
      change('consignee', null);
      change('primaryContact', null);
      change('primaryEmail', null);
      change('corrAddress', null);
    }
    if (FormValues && FormValues.consigneeType === 'miscellaneous') {
      change('customerId', null);
      change('supplierId', null);
    }
  }, [FormValues && FormValues.consigneeType]);

  const showFormValuesModal = () => {
    const courierDate = get(FormValues, ['courierDate']);
    const _preview = {
      courierDate: courierDate ? moment(courierDate).format('DD MMM YYYY') : '--',
      consigneeType: startCase(get(FormValues, ['consigneeType'], '--')),
      consignee: '--',
      grossWeight: get(FormValues, ['grossWeight'], '--'),
      content: get(FormValues, ['content'], '--'),
      remarks: get(FormValues, ['remarks'], '--'),
      primaryContact: get(FormValues, ['primaryContact'], '--'),
      primaryEmail: get(FormValues, ['primaryEmail'], '--'),
      corrAddress: get(FormValues, ['corrAddress'], '--'),
    };

    const consigneeType = get(FormValues, ['consigneeType'], null);
    const customerId = get(FormValues, ['customerId'], null);
    const supplierId = get(FormValues, ['supplierId'], null);

    if (consigneeType === 'customer') {
      const customerObj = customerIdList.find(customer => customer.id === +customerId);
      if (customerObj) {
        _preview.consignee = customerObj.name;
      }
    }

    if (consigneeType === 'supplier') {
      const supplierObj = supplierIdList.find(supplier => supplier.id === +supplierId);
      if (supplierObj) {
        _preview.consignee = supplierObj.name;
      }
    }

    if (consigneeType === 'miscellaneous') {
      _preview.consignee = get(FormValues, ['consignee'], null);
    }

    setPreview(_preview);
    setFormValuesModal(true);
  };

  const hideFormValuesModal = () => {
    setFormValuesModal(false);
    setPreview({});
  };

  const onSubmit = async () => {
    try {
      setConfirmLoading(true);
      await Api.put(`/courier-entries/${match.params.id}`, FormValues);
      successNotification('Courier updated');
      setConfirmLoading(false);
      hideFormValuesModal();
      history.push('/courier/list');
    } catch (err) {
      setConfirmLoading(false);
      errorNotification(err);
    }
  };

  return (
    <Structure>
      <>
        <Heading text="Edit Courier" />
        <form onSubmit={handleSubmit(onSubmit)}>
          <Row>
            <LeftGrid>
              <Field
                allowClear
                required
                label="Courier Date"
                name="courierDate"
                placeholder="Courier Date"
                component={AntDatePicker}
              />
            </LeftGrid>
            <RightGrid>
              <Field label="Consignee Type" allowClear required name="consigneeType" component={AntSelect}>
                <Select.Option key={null} value="" disabled>
                  Select consignee type
                </Select.Option>
                <Select.Option key="customer" value="customer">
                  Customer
                </Select.Option>
                <Select.Option key="supplier" value="supplier">
                  Supplier
                </Select.Option>
                <Select.Option key="miscellaneous" value="miscellaneous">
                  Miscellaneous
                </Select.Option>
              </Field>
            </RightGrid>
          </Row>
          <Row>
            <LeftGrid>
              {FormValues && FormValues.consigneeType === 'customer' ? (
                <>
                  <Field label="Consignee" allowClear required name="customerId" component={AntSelect}>
                    <Select.Option key={null} value="" disabled>
                      Select customer
                    </Select.Option>
                    {customerIdList.map(customer => (
                      <Select.Option key={customer.id} value={customer.id}>
                        {customer.name}
                      </Select.Option>
                    ))}
                  </Field>
                </>
              ) : FormValues && FormValues.consigneeType === 'supplier' ? (
                <>
                  <Field label="Consignee" allowClear required name="supplierId" component={AntSelect}>
                    <Select.Option key={null} value="" disabled>
                      Select supplier
                    </Select.Option>
                    {supplierIdList.map(supplier => (
                      <Select.Option key={supplier.id} value={supplier.id}>
                        {supplier.name}
                      </Select.Option>
                    ))}
                  </Field>
                </>
              ) : (
                <>
                  <Field label="Consignee" allowClear name="consignee" placeholder="Consignee" component={AntInput} />
                </>
              )}
            </LeftGrid>
            <RightGrid>
              <Field
                label="Gross Weight (in Kg)"
                allowClear
                type="number"
                min={0}
                name="grossWeight"
                placeholder="Gross Weight (in Kg)"
                component={AntInput}
              />
            </RightGrid>
          </Row>
          <Row>
            <LeftGrid>
              <Field label="Content" allowClear name="content" placeholder="Content" component={AntInput} />
            </LeftGrid>
            <RightGrid>
              <Field label="Remarks" allowClear name="remarks" placeholder="Remarks" component={AntInput} />
            </RightGrid>
          </Row>
          {FormValues && FormValues.consigneeType === 'miscellaneous' ? (
            <>
              <Row>
                <LeftGrid>
                  <Field
                    label="Contact"
                    required
                    allowClear
                    name="primaryContact"
                    placeholder="Contact Number"
                    component={AntInput}
                  />
                </LeftGrid>
                <RightGrid>
                  <Field
                    label="Email"
                    required
                    allowClear
                    name="primaryEmail"
                    placeholder="Email"
                    component={AntInput}
                  />
                </RightGrid>
              </Row>
              <Row>
                <LeftGrid>
                  <Field
                    label="Correspondence Address"
                    allowClear
                    name="corrAddress"
                    placeholder="Correspondence Address"
                    component={AntInput}
                  />
                </LeftGrid>
              </Row>
            </>
          ) : null}
          <Row className={classes.buttonContainer}>
            <Button className={classes.button} type="primary" onClick={showFormValuesModal}>
              Preview
            </Button>
          </Row>
        </form>
        <Modal
          onCancel={hideFormValuesModal}
          open={formValueModal}
          confirmLoading={confirmLoading}
          centered
          width="800px"
          maskClosable={false}
          closable={false}
          cancelText="Back"
          okButtonProps={{ className: classes.button }}
          okText={
            <Popconfirm
              title="Sure want to submit form ?"
              onConfirm={() => onSubmit()}
              okText="Confirm"
              cancelText="Cancel"
            >
              Submit
            </Popconfirm>
          }
        >
          <Row>
            <Col xs={24} lg={12}>
              <p>
                Courier Date:
                <span className={classes.detailColor}>{preview.courierDate}</span>
              </p>
            </Col>
            <Col xs={24} lg={12}>
              <p>
                Consignee Type:
                <span className={classes.detailColor}>{preview.consigneeType}</span>
              </p>
            </Col>
          </Row>
          <Row>
            <Col xs={24} lg={12}>
              <p>
                Consignee:
                <span className={classes.detailColor}>{preview.consignee}</span>
              </p>
            </Col>
            <Col xs={24} lg={12}>
              <p>
                Gross Weight (in Kg):
                <span className={classes.detailColor}>{preview.grossWeight}</span>
              </p>
            </Col>
          </Row>
          <Row>
            <Col xs={24} lg={12}>
              <p>
                Content:
                <span className={classes.detailColor}>{preview.content}</span>
              </p>
            </Col>
            <Col xs={24} lg={12}>
              <p>
                Remarks:
                <span className={classes.detailColor}>{preview.remarks}</span>
              </p>
            </Col>
          </Row>
          {FormValues && FormValues.consigneeType === 'miscellaneous' ? (
            <>
              <Row>
                <Col xs={24} lg={12}>
                  <p>
                    Contact:
                    <span className={classes.detailColor}>{preview.primaryContact}</span>
                  </p>
                </Col>
                <Col xs={24} lg={12}>
                  <p>
                    Email:
                    <span className={classes.detailColor}>{preview.primaryEmail}</span>
                  </p>
                </Col>
              </Row>
              <Row>
                <Col xs={24} lg={12}>
                  <p>
                    Correspondence Address:
                    <span className={classes.detailColor}>{preview.corrAddress}</span>
                  </p>
                </Col>
              </Row>
            </>
          ) : null}
        </Modal>
      </>
    </Structure>
  );
};

EditCourier.propTypes = {
  handleSubmit: PropTypes.func.isRequired,
  classes: PropTypes.object.isRequired,
  change: PropTypes.func.isRequired,
  match: PropTypes.object.isRequired,
};

const StyledEditCourier = withStyles(styles)(EditCourier);

const FormedEditCourier = reduxForm({
  form: 'EditCourier',
  enableReinitialize: true,
})(StyledEditCourier);

export default connect(({ courier }) => ({ initialValues: courier }), {})(FormedEditCourier);

/* eslint-disable no-nested-ternary */
import React, { useEffect, useMemo, useState } from 'react';
import { Col, DatePicker, Popconfirm, Row, Select, Table, Tooltip, Typography, Badge, Button } from 'antd';
import { useDispatch, useSelector } from 'react-redux';
import moment from 'moment';
import { CalendarOutlined, FileTextOutlined, UndoOutlined } from '@ant-design/icons';
import fileDownload from 'js-file-download';
import Structure from '../Structure/Structure';
import Heading from '../Components/Heading';

import { getIsInPlanningOrders, clearIsInPlanningOrders, clearSectionList, getSectionList } from '../Actions';
import Api from '../Api';
import { errorNotification, successNotification } from '../Snackbar';
import constants from '../constants';
import { filterDropdown, get, onFilter, sorter, dateFilterDropdown, onDateFilter } from '../utils';
import DispatchDateModal from './DispatchDateModal';

const { Text } = Typography;

const PlanningList = () => {
  const dispatch = useDispatch();

  const isInPlanningOrders = useSelector(state => state.isInPlanningOrders);
  const sectionList = useSelector(state => state.sectionList);

  const auth = useSelector(state => state.auth);
  const permissions = get(auth, ['permissions'], []);

  const [Consignor, SetConsignor] = useState(constants.consignors.list.plusFormulationsHeadOffice);
  const [SectionId, SetSectionId] = useState([]);

  const [From, SetFrom] = useState(moment(new Date()).startOf('day').startOf('month').toISOString());

  const [To, SetTo] = useState(moment(new Date()).endOf('day').endOf('month').toISOString());

  const [RecordId, SetRecordId] = useState(null);

  const order = useMemo(() => isInPlanningOrders.find(el => el.id === RecordId), [RecordId, isInPlanningOrders]);

  useEffect(() => {
    dispatch(getSectionList());
    return () => {
      dispatch(clearIsInPlanningOrders());
      dispatch(clearSectionList());
    };
  }, []);

  useEffect(() => {
    dispatch(getIsInPlanningOrders({ from: From, to: To, sectionId: SectionId, consignor: Consignor }));
    return () => {
      dispatch(clearIsInPlanningOrders());
    };
  }, [From, To, SectionId, Consignor]);

  const HideModal = () => {
    SetRecordId(null);
    dispatch(getIsInPlanningOrders({ from: From, to: To, sectionId: SectionId, consignor: Consignor }));
  };

  const onProductionPlanningApproval = async orderId => {
    try {
      await Api.put(`/orders/${orderId}/movetoproductionplanning`);
      successNotification('Order production planning status updated');
      HideModal();
    } catch (err) {
      errorNotification(err);
    }
  };

  const onUndoPlanningApproval = async orderId => {
    try {
      await Api.put(`/orders/${orderId}/undomovetoplanning`);
      successNotification('Order moved back to authorized list');
      HideModal();
    } catch (err) {
      errorNotification(err);
    }
  };

  const exportPlanningList = async () => {
    try {
      const { data } = await Api.getFile(`/orders/planning/export`, {
        from: From,
        to: To,
        sectionId: SectionId,
        consignor: Consignor,
      });
      fileDownload(data, `monthly-planning-list.csv`);
    } catch (error) {
      errorNotification(error);
    }
  };

  const columns = [
    {
      title: 'Est. Dispatch',
      dataIndex: 'estDispatchDate',
      filterDropdown: dateFilterDropdown,
      onFilter: onDateFilter('estDispatchDate'),
      dateFilterDropdown: dateFilterDropdown('estDispatchDate'),
      width: 120,
      render: (_text, record) => {
        if (
          record.estDispatchDate &&
          moment().isBetween(moment(record.authorizedAt).add(20, 'day'), moment(record.authorizedAt).add(25, 'day'))
        ) {
          return (
            <>
              <span style={{ color: 'rgb(228, 196, 65)' }}>
                {_text && moment(_text).format('DD MMM YY hh:mm:ss A')}
              </span>
              | <CalendarOutlined onClick={() => SetRecordId(record.id)} />
            </>
          );
        }
        if (
          record.estDispatchDate &&
          moment().isBetween(moment(record.authorizedAt).add(25, 'day'), moment(record.authorizedAt).add(30, 'day'))
        ) {
          return (
            <>
              <span style={{ color: 'rgb(239, 108, 0)' }}>{_text && moment(_text).format('DD MMM YY hh:mm:ss A')}</span>{' '}
              |
              <CalendarOutlined onClick={() => SetRecordId(record.id)} />
            </>
          );
        }
        if (record.estDispatchDate && moment().isAfter(moment(record.authorizedAt).add(30, 'day'))) {
          return (
            <>
              <span style={{ color: 'rgb(216, 27, 96)' }}>{_text && moment(_text).format('DD MMM YY hh:mm:ss A')}</span>{' '}
              |
              <CalendarOutlined onClick={() => SetRecordId(record.id)} />
            </>
          );
        }
        return (
          <>
            <span>{_text && moment(_text).format('DD MMM YY hh:mm:ss A')}</span> |
            <CalendarOutlined onClick={() => SetRecordId(record.id)} />
          </>
        );
      },
    },
    {
      title: 'Order #',
      dataIndex: 'id',
      filterDropdown,
      sorter: sorter('id'),
      onFilter: onFilter('id'),
      width: 80,
    },
    {
      title: 'Section',
      dataIndex: 'section',
      sorter: sorter('section'),
      filterDropdown,
      onFilter: onFilter('section'),
      width: 100,
    },
    {
      title: 'Consignor',
      dataIndex: 'consignor',
      sorter: sorter('consignor'),
      filterDropdown,
      onFilter: onFilter('consignor'),
      width: 110,
    },
    {
      title: 'Finished',
      dataIndex: 'formulation',
      sorter: sorter('formulation'),
      filterDropdown,
      onFilter: onFilter('formulation'),
    },
    {
      title: 'Customer',
      dataIndex: 'customer',
      sorter: sorter('customer'),
      filterDropdown,
      onFilter: onFilter('customer'),
    },
    {
      title: 'Quantity',
      dataIndex: 'quantity',
      width: 80,
    },
    {
      title: 'Rate',
      dataIndex: 'rate',
      width: 80,
    },
    {
      title: 'Amount',
      dataIndex: 'amount',
      width: 100,
    },
    {
      title: 'Authorized',
      dataIndex: 'authorizedAt',
      filterDropdown: dateFilterDropdown,
      onFilter: onDateFilter('authorizedAt'),
      dateFilterDropdown: dateFilterDropdown('authorizedAt'),
      render: date => (date ? moment(date).format('DD MMM YY') : null),
      width: 100,
    },
    {
      title: 'Remarks',
      dataIndex: 'remarks',
      ellipsis: { showTitle: false },
      sorter: sorter('remarks'),
      filterDropdown,
      onFilter: onFilter('remarks'),
    },
    {
      title: 'Internal Remarks',
      dataIndex: 'internalRemarks',
      ellipsis: { showTitle: false },
      sorter: sorter('internalRemarks'),
      filterDropdown,
      onFilter: onFilter('internalRemarks'),
    },
  ];

  columns.push({
    title: 'Undo Planning',
    key: 'undoPlanning',
    width: 75,
    render: (_text, record) => (
      <Tooltip placement="bottom" title="Undo">
        <Popconfirm
          title="Are you sure you want to undo?"
          onConfirm={() => onUndoPlanningApproval(record.id)}
          okText="Yes"
          cancelText="No"
        >
          <UndoOutlined />
        </Popconfirm>
      </Tooltip>
    ),
  });

  if (permissions.includes('production_planning_approval')) {
    columns.push({
      title: 'Production Planning',
      width: 90,
      render: (_text, record) => (
        <Tooltip placement="bottom" title="Production Planning">
          <Popconfirm
            title="Are you sure you want to move this order to production planning?"
            onConfirm={() => onProductionPlanningApproval(record.id)}
            okText="Yes"
            cancelText="No"
          >
            <FileTextOutlined />
          </Popconfirm>
        </Tooltip>
      ),
    });
  }

  return (
    <Structure>
      <>
        <Heading text={`${moment(From).format('DD MMM YY')} - ${moment(To).format('DD MMM YY')} | Monthly Planning`} />
        <Row gutter={[8, 8]}>
          <Col>
            <DatePicker onChange={SetFrom} placeholder="From" />
          </Col>
          <Col>
            <DatePicker onChange={SetTo} placeholder="To" />
          </Col>
          <Col>
            <Select style={{ width: 170 }} allowClear placeholder="Select section" onChange={SetSectionId}>
              {sectionList.map(section => (
                <Select.Option key={section.id} value={section.id}>
                  {section.section}
                </Select.Option>
              ))}
            </Select>
          </Col>
          <Col>
            <Select onChange={SetConsignor} placeholder="Consignor" value={Consignor} style={{ width: 280 }}>
              {Object.values(constants.consignors.list).map(consignor => (
                <Select.Option key={consignor} value={consignor}>
                  {consignor}
                </Select.Option>
              ))}
            </Select>
          </Col>
          <Col>
            <Badge color="rgb(228, 196, 65)" text="20-25 days" />
          </Col>
          <Col>
            <Badge color="rgb(239, 108, 0)" text="25-30 days" />
          </Col>
          <Col>
            <Badge color="rgb(216, 27, 96)" text="Above 30 days" />
          </Col>
          <Col>
            <Button type="default" onClick={exportPlanningList}>
              Export
            </Button>
          </Col>
        </Row>
        <br />
        <Table
          columns={columns}
          bordered
          rowKey={record => record.id}
          pagination={{ defaultPageSize: 50, position: ['bottomLeft'], showTotal: total => `Total ${total} Records` }}
          dataSource={isInPlanningOrders}
          size="small"
          scroll={{ x: 1600, y: window.innerHeight - 150 }}
          summary={pageData => {
            let total = 0;
            pageData.forEach(({ amount }) => {
              total += +amount;
            });
            return (
              <>
                <Table.Summary.Row>
                  <Table.Summary.Cell colSpan={8}>Total</Table.Summary.Cell>
                  <Table.Summary.Cell>
                    <Text>{Number(total.toFixed(2)).toLocaleString('en-IN')}</Text>
                  </Table.Summary.Cell>
                  <Table.Summary.Cell colSpan={4} />
                </Table.Summary.Row>
              </>
            );
          }}
        />
        {order && RecordId && <DispatchDateModal recordId={RecordId} order={order} hideModal={HideModal} />}
      </>
    </Structure>
  );
};

export default PlanningList;

import Api from '../Api';

export const getNarrationList = () => async dispatch => {
  try {
    const { data } = await Api.get('/dropdowns/bank-narrations');
    dispatch({
      type: 'FETCH_NARRATION_LIST',
      payload: data,
    });
  } catch (err) {
    throw err;
  }
};

export const clearNarrationList = () => {
  return {
    type: 'CLEAR_NARRATION_LIST',
  };
};

export const getNarration = id => async dispatch => {
  try {
    const { data } = await Api.get(`/dropdowns/bank-narrations/${id}`);
    dispatch({
      type: 'FETCH_NARRATION',
      payload: data,
    });
  } catch (err) {
    throw err;
  }
};

export const clearNarration = () => {
  return {
    type: 'CLEAR_NARRATION',
  };
};

import Api from '../Api';

export const getSupplierMIS = body => async dispatch => {
  try {
    const { data } = await Api.post('/mis/supplier', body);
    dispatch({
      type: 'FETCH_SUPPLIER_MIS',
      payload: data,
    });
  } catch (err) {
    throw err;
  }
};

export const clearSupplierMIS = () => {
  return {
    type: 'CLEAR_SUPPLIER_MIS',
  };
};

export const getSupplierDelayedPOs = body => async dispatch => {
  try {
    const { data } = await Api.post('/mis/supplier/delayedpos', body);
    dispatch({
      type: 'FETCH_SUPPLIER_DELAYED_POS',
      payload: data,
    });
  } catch (err) {
    throw err;
  }
};

export const clearSupplierDelayedPOs = () => {
  return {
    type: 'CLEAR_SUPPLIER_DELAYED_POS',
  };
};

export const getOrderMIS = id => async dispatch => {
  try {
    const { data } = await Api.get(`/mis/order/${id}`);
    dispatch({
      type: 'FETCH_ORDER_MIS',
      payload: data,
    });
  } catch (err) {
    throw err;
  }
};

export const clearOrderMIS = () => {
  return {
    type: 'CLEAR_ORDER_MIS',
  };
};

export const getTeamMIS = body => async dispatch => {
  try {
    const { data } = await Api.post('/mis/team', body);
    dispatch({
      type: 'FETCH_TEAM_MIS',
      payload: data,
    });
  } catch (err) {
    throw err;
  }
};

export const clearTeamMIS = () => {
  return {
    type: 'CLEAR_TEAM_MIS',
  };
};

export const getTaskMIS = query => async dispatch => {
  try {
    const { data } = await Api.get('/tasks/mis', query);
    dispatch({
      type: 'FETCH_TASK_MIS',
      payload: data,
    });
  } catch (err) {
    throw err;
  }
};

export const clearTaskMIS = () => {
  return {
    type: 'CLEAR_TASK_MIS',
  };
};

export const getOrdersFMS = () => async dispatch => {
  try {
    const { data } = await Api.get(`/mis/orders`);
    dispatch({
      type: 'FETCH_ORDERS_FMS',
      payload: data,
    });
  } catch (err) {
    throw err;
  }
};

export const clearOrdersFMS = () => {
  return {
    type: 'CLEAR_ORDERS_FMS',
  };
};

export const getOrdersMIS = query => async dispatch => {
  try {
    const { data } = await Api.get(`/mis/orders/counts`, query);
    dispatch({
      type: 'FETCH_ORDERS_MIS',
      payload: data,
    });
  } catch (err) {
    throw err;
  }
};

export const clearOrdersMIS = () => {
  return {
    type: 'CLEAR_ORDERS_MIS',
  };
};

export const getOrdersASPReport = query => async dispatch => {
  try {
    const { data } = await Api.get(`/orders/reports/notordereddata`, query);
    dispatch({
      type: 'FETCH_ORDERS_ASP_REPORT',
      payload: data,
    });
  } catch (err) {
    throw err;
  }
};

export const clearOrdersASPReport = () => {
  return {
    type: 'CLEAR_ORDERS_ASP_REPORT',
  };
};

export const getSaleASPReport = query => async dispatch => {
  try {
    const { data } = await Api.get(`/order-sales/reports/customerwisetotal`, query);
    dispatch({
      type: 'FETCH_SALE_ASP_REPORT',
      payload: data,
    });
  } catch (err) {
    throw err;
  }
};

export const clearSaleASPReport = () => {
  return {
    type: 'CLEAR_SALE_ASP_REPORT',
  };
};

export const getProductASPTotal = query => async dispatch => {
  try {
    const { data } = await Api.get(`/order-sales/reports/productwisetotal`, query);
    dispatch({
      type: 'FETCH_PRODUCT_ASP_REPORT',
      payload: data,
    });
  } catch (err) {
    throw err;
  }
};

export const clearProductASPReport = () => {
  return {
    type: 'CLEAR_PRODUCT_ASP_REPORT',
  };
};

export const getLeadFMS = () => async dispatch => {
  try {
    const { data } = await Api.get(`/leads/mis`);
    dispatch({
      type: 'FETCH_LEADS_FMS',
      payload: data,
    });
  } catch (err) {
    throw err;
  }
};

export const clearLeadFMS = () => {
  return {
    type: 'CLEAR_LEADS_FMS',
  };
};

export const getLeadMIS = query => async dispatch => {
  const { data } = await Api.get(`/mis/lead`, query);
  dispatch({
    type: 'FETCH_LEAD_MIS',
    payload: data,
  });
};

export const clearLeadMIS = () => {
  return {
    type: 'CLEAR_LEAD_MIS',
  };
};

import React, { useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import withStyles from 'react-jss';
import PropTypes from 'prop-types';
import { Table, Popconfirm, Tooltip } from 'antd';
import { Link } from 'react-router-dom';
import { EditOutlined, DeleteOutlined } from '@ant-design/icons';
import Structure from '../Structure/Structure';
import { sorter, onFilter, filterDropdown, get } from '../utils';
import Api from '../Api';
import { getAssetList, clearAssetList } from '../Actions';
import { errorNotification, successNotification } from '../Snackbar';
import styles from '../CommonStyles';
import Heading from '../Components/Heading';

const List = props => {
  const { classes } = props;
  const dispatch = useDispatch();

  const auth = useSelector(state => state.auth);
  const permissions = get(auth, ['permissions'], []);
  const assetList = useSelector(state => state.assetList);

  useEffect(() => {
    dispatch(getAssetList());
    return () => {
      dispatch(clearAssetList());
    };
  }, []);

  const onDelete = async id => {
    try {
      await Api.delete(`/assets/${id}`);
      successNotification('Asset deleted');
      dispatch(getAssetList());
    } catch (err) {
      errorNotification(err);
    }
  };

  const columns = [
    {
      title: 'Consignor',
      dataIndex: 'consignor',
      sorter: sorter('consignor'),
      filterDropdown,
      onFilter: onFilter('consignor'),
    },
    {
      title: 'Name',
      dataIndex: 'name',
      sorter: sorter('name'),
      filterDropdown,
      onFilter: onFilter('name'),
    },
    {
      title: 'Description',
      dataIndex: 'description',
      sorter: sorter('description'),
      filterDropdown,
      onFilter: onFilter('description'),
    },
    {
      title: 'Serial #',
      dataIndex: 'serialNo',
      sorter: sorter('serialNo'),
      filterDropdown,
      onFilter: onFilter('serialNo'),
    },
    {
      title: 'Value',
      dataIndex: 'value',
      sorter: sorter('value'),
      filterDropdown,
      onFilter: onFilter('value'),
    },
    {
      title: 'Remarks',
      dataIndex: 'remarks',
      sorter: sorter('remarks'),
      filterDropdown,
      onFilter: onFilter('remarks'),
    },
  ];

  const renderActionIcons = record => (
    <div>
      {permissions.includes('edit_asset') ? (
        <>
          <Tooltip placement="bottom" title="Edit">
            <Link to={`/assets/edit/${record.id}`}>
              <EditOutlined className={classes.tableIcon} />
            </Link>
          </Tooltip>
          &nbsp;&nbsp;&nbsp;&nbsp;
        </>
      ) : null}
      {permissions.includes('delete_asset') ? (
        <>
          <Tooltip placement="bottom" title="Delete">
            <Popconfirm
              title="Are you sure you want to delete?"
              onConfirm={() => onDelete(record.id)}
              okText="Yes"
              cancelText="No"
            >
              <DeleteOutlined className={classes.tableIcon} />
            </Popconfirm>
          </Tooltip>
        </>
      ) : null}
    </div>
  );

  if (permissions.includes('edit_asset') || permissions.includes('delete_asset')) {
    columns.push({
      title: 'Actions',
      width: 70,
      render: renderActionIcons,
    });
  }

  return (
    <Structure>
      <>
        <Heading text="Assets" />
        <Table
          columns={columns}
          bordered
          rowKey={record => record.id}
          pagination={{
            defaultPageSize: 20,
            position: ['bottomLeft'],
            showTotal: total => `Total ${total} Records`,
          }}
          dataSource={assetList}
          size="small"
          scroll={{ x: 1050, y: window.innerHeight - 300 }}
        />
      </>
    </Structure>
  );
};
List.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(List);

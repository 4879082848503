/* eslint-disable no-nested-ternary */
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import withStyles from 'react-jss';
import PropTypes from 'prop-types';
import { Table, Popconfirm, Tooltip, Upload, Button, Modal, Input, Select, DatePicker, Col, Row } from 'antd';
import { Link, useHistory } from 'react-router-dom';
import {
  EditOutlined,
  DeleteOutlined,
  FileDoneOutlined,
  CloudUploadOutlined,
  CloudDownloadOutlined,
  FileTextOutlined,
} from '@ant-design/icons';
import startCase from 'lodash/startCase';
import moment from 'moment';
import { Field, getFormValues, reduxForm, reset } from 'redux-form';
import fileDownload from 'js-file-download';

import Structure from '../Structure/Structure';
import {
  sorter,
  onFilter,
  filterDropdown,
  get,
  // onFilterInsideObject,
  // sorterInsideObject,
  onDateFilter,
  dateFilterDropdown,
  dateSorter,
} from '../utils';
import Api from '../Api';
import {
  getOrderList,
  clearOrderList,
  getOrderFilesList,
  clearOrderFilesList,
  getOrderShortageItemsList,
  clearOrderShortageItemsList,
  setPurchaseOrder,
  clearPurchaseOrder,
  getJobCard,
  clearJobCard,
  getPurchaseOrderList,
  clearPurchaseOrderList,
  getOrderMIS,
  clearOrderMIS,
  getItemsToOrderList,
  clearItemsToOrderList,
  getBatchSeriesList,
  clearBatchSeriesList,
  getFormulationFilesList,
  clearFormulationFilesList,
} from '../Actions';
import { errorNotification, successNotification, infoNotification } from '../Snackbar';
import styles from '../CommonStyles';
import JobCard from './JobCard';
import GetField from '../Components/GetField';
import { materialTypes } from '../constants';
import ItemsToOrderTable from '../Inventory/ItemsToOrderTable';
import EditJobCardModal from './ProductionModals/EditJobCardModal';

const AntInput = GetField(Input);
const AntSelect = GetField(Select);
const AntDatePicker = GetField(DatePicker);
const { Option } = Select;

const OrderList = props => {
  const { classes, change } = props;
  const dispatch = useDispatch();
  const history = useHistory();

  const auth = useSelector(state => state.auth);
  const permissions = get(auth, ['permissions'], []);
  const role = get(auth, ['user', 'role'], []);
  const orderList = useSelector(state => state.orderList);
  const orderFilesList = useSelector(state => state.orderFilesList);
  const orderShortageItemsList = useSelector(state => state.orderShortageItemsList);
  const orderMIS = useSelector(state => state.orderMIS);

  const seriesList = useSelector(state => state.seriesList);
  const formulationFilesList = useSelector(state => state.formulationFilesList);

  const [orderId, setOrderId] = useState(null);
  const [formulationId, setFormulationId] = useState(null);
  const [materialIds, setMaterialIds] = useState([]);
  const [confirmLoading, setConfirmLoading] = useState(false);
  const [modalType, setModalType] = useState(null);
  const [fileList, setFileList] = useState([]);
  const [uploading, setUploading] = useState(false);
  const [fileType, setFileType] = useState(false);
  const [Tab, SetTab] = useState('authorized');
  const [financialYear, setFinancialYear] = useState('2024');

  const FormValues = useSelector(state => getFormValues('BatchDetailForm')(state));

  const order = useMemo(() => orderList.find(el => el.id === orderId), [orderId, orderList]);

  // const [Consignor, SetConsignor] = useState(constants.consignors.list.plusFormulationsHeadOffice);

  useEffect(() => {
    dispatch(getBatchSeriesList());

    return () => {
      dispatch(clearBatchSeriesList());
      dispatch(clearFormulationFilesList());
    };
  }, []);

  useEffect(() => {
    let isAuthorized = false;
    let isDispatched = false;

    if (Tab === 'unauthorized') {
      isAuthorized = false;
      isDispatched = false;
    }
    if (Tab === 'authorized') {
      isAuthorized = true;
      isDispatched = false;
    }
    if (Tab === 'dispatched') {
      isAuthorized = true;
      isDispatched = true;
    }

    dispatch(
      getOrderList({
        // consignor: Consignor,
        isAuthorized,
        isDispatched,
        financialYear,
      })
    );

    return () => {
      dispatch(clearBatchSeriesList());
      dispatch(clearOrderList());
      dispatch(clearOrderFilesList());
      dispatch(clearOrderShortageItemsList());
      dispatch(clearJobCard());
      dispatch(clearPurchaseOrderList());
      dispatch(clearOrderMIS());
    };
  }, [Tab]);

  useEffect(() => {
    if (orderId && modalType === 'ViewFiles') {
      dispatch(getOrderFilesList(orderId, fileType));
    }
    if (orderId && modalType === 'ShortageItemsList') {
      dispatch(getOrderShortageItemsList(orderId));
    }
    if (order && materialIds.length && modalType === 'CommonItemsList') {
      dispatch(getItemsToOrderList({ ids: materialIds, consignor: order.consignor }));
    }
    if (orderId && modalType === 'ViewOrderDetails') {
      dispatch(getJobCard(orderId));
    }
    if (orderId && modalType === 'ViewPOList') {
      dispatch(getPurchaseOrderList({ orderId }));
    }
    if (orderId && modalType === 'ViewOrderMIS') {
      dispatch(getOrderMIS(orderId));
    }
    if (formulationId && modalType === 'ViewFormulationFiles') {
      dispatch(getFormulationFilesList(formulationId));
    }
  }, [orderId, modalType, materialIds, formulationId]);

  const getBatchNo = async () => {
    try {
      const { data } = await Api.get(`/orders/series/${FormValues.batchSeries}`);
      change('batchNo', data);
    } catch (err) {
      errorNotification(err);
    }
  };

  useEffect(() => {
    if (orderId && modalType === 'BatchDetails' && FormValues && FormValues.batchSeries) {
      getBatchNo();
    }
  }, [orderId, modalType, FormValues && FormValues.batchSeries]);

  const showModal = (id, type, _fileType, _materialIds = null, _formulationId) => {
    setOrderId(id);
    setMaterialIds(_materialIds || []);
    setModalType(type);
    setFileType(_fileType);
    setFormulationId(_formulationId);
  };

  const showBatchModal = (id, mrp, type) => {
    setOrderId(id);
    setModalType(type);
    change('mrpAsPerOrder', mrp);
  };

  const hideModal = () => {
    setOrderId(null);
    setMaterialIds([]);
    setModalType(null);
    setFileList([]);
    dispatch(clearFormulationFilesList());
    dispatch(clearOrderFilesList());
    dispatch(clearOrderShortageItemsList());
    dispatch(clearPurchaseOrder());
    dispatch(clearJobCard());
    dispatch(clearPurchaseOrderList());
    dispatch(clearOrderMIS());
    dispatch(clearItemsToOrderList());
    dispatch(reset('BatchDetailForm'));
  };

  const onDelete = async id => {
    try {
      await Api.delete(`/orders/${id}`);
      successNotification('Order deleted');
      dispatch(getOrderList({ isAuthorized: true, isDispatched: false }));
    } catch (err) {
      errorNotification(err);
    }
  };

  // const onAuthorize = async () => {
  //   try {
  //     setConfirmLoading(true);
  //     await Api.put(`/orders/${orderId}/authorize`);
  //     successNotification('Order Authorized');
  //     setConfirmLoading(false);
  //     hideModal();
  //     dispatch(getOrderList({ isAuthorized: true, isDispatched: false }));
  //   } catch (err) {
  //     setConfirmLoading(false);
  //     errorNotification(err);
  //   }
  // };

  const onCustomerApproval = async body => {
    try {
      const { verified } = body;
      setConfirmLoading(true);
      await Api.put(`/orders/${orderId}/changeverifiedbycustomer`, body);
      successNotification(`Customer verification for change required in the artwork is ${verified}`);
      setConfirmLoading(false);
      hideModal();
      dispatch(getOrderList({ isAuthorized: true, isDispatched: false }));
    } catch (err) {
      setConfirmLoading(false);
      errorNotification(err);
    }
  };

  const onInternalApproval = async body => {
    try {
      setConfirmLoading(true);
      await Api.put(`/orders/${orderId}/changeverifiedbyuser`, body);
      successNotification('Order verified internally');
      setConfirmLoading(false);
      hideModal();
      dispatch(getOrderList({ isAuthorized: true, isDispatched: false }));
    } catch (err) {
      setConfirmLoading(false);
      errorNotification(err);
    }
  };

  const onPlanningApproval = async id => {
    try {
      setConfirmLoading(true);
      await Api.put(`/orders/${id}/movetoplanning`);
      successNotification('Order moved to planning');
      setConfirmLoading(false);
      hideModal();
      dispatch(getOrderList({ isAuthorized: true, isDispatched: false }));
    } catch (err) {
      setConfirmLoading(false);
      errorNotification(err);
    }
  };

  const onUpload = async () => {
    try {
      setUploading(true);
      const formData = new FormData();
      fileList.forEach((file, index) => {
        formData.append(`files[${index}]`, file);
      });
      const { data } = await Api.post(`/orders/${orderId}/files?type=${fileType}`, formData, {
        headers: { 'content-type': 'multipart/form-data' },
      });
      infoNotification(data);
      hideModal();
      setFileList([]);
    } catch (err) {
      errorNotification(err);
    }
    setUploading(false);
  };

  const onFileDelete = async id => {
    try {
      await Api.delete(`/orders/${orderId}/files/${id}`);
      successNotification('File delete');
      dispatch(getOrderFilesList(orderId));
    } catch (err) {
      errorNotification(err);
    }
  };

  const onRemove = file => {
    const index = fileList.indexOf(file);
    const newFileList = fileList.slice();
    newFileList.splice(index, 1);
    setFileList(newFileList);
  };

  const beforeUpload = (file, files) => {
    setFileList([...fileList, ...files]);
    return false;
  };

  const onBatchSubmit = async () => {
    try {
      setConfirmLoading(true);
      await Api.post(`/orders/${orderId}/batchdetails`, FormValues);
      successNotification('Batch detaile submit');
      setConfirmLoading(false);
      dispatch(reset('BatchDetailForm'));
      hideModal();
      dispatch(getOrderList({ isAuthorized: true, isDispatched: false }));
    } catch (err) {
      setConfirmLoading(false);
      errorNotification(err);
    }
  };

  const tooltipShow = arg => (
    <Tooltip placement="topLeft" title={arg}>
      {arg}
    </Tooltip>
  );

  const tooltipShowWithColors = (arg, record) => {
    let color;
    if (record.status) {
      if (record.status === 'Full Kitting' || record.status === 'Full Kitting and in Production Planning') {
        color = 'Green';
      } else if (
        record.status === 'Full Kitting (Individual)' ||
        record.status === 'Full Kitting (Individual) and in Production Planning'
      ) {
        color = '#b902fe';
      } else if (record.status === 'Order Dispatched') {
        color = '#eb2f96';
      } else if (record.status === 'Full Kitting Pending but in Planning') {
        color = 'rgb(239, 108, 0)';
      } else if (record.status === 'Full Kitting Pending but in Production Planning') {
        color = '#096dd9';
      } else if (record.status.startsWith('Materials Ordered')) {
        color = '#e29705';
      } else if (record.status.startsWith('Materials to be Ordered')) {
        color = 'red';
      }
    }

    return (
      <Tooltip placement="topLeft" title={arg}>
        <span style={{ color }}>{arg}</span>
      </Tooltip>
    );
  };

  // const tooltipShowInsideObject = (keys, arg) => {
  //   const value = get(arg, keys, null);
  //   return (
  //     <Tooltip placement="topLeft" title={materialTypes.includes(`${value}`) ? startCase(value) : value}>
  //       {materialTypes.includes(`${value}`) ? startCase(value) : value}
  //     </Tooltip>
  //   );
  // };

  // const tooltipShowInsideObjectWithColors = (keys, arg, record) => {
  //   let color;
  //   if (record.status) {
  //     if (record.status === 'Full Kitting' || record.status === 'Full Kitting and in Production Planning') {
  //       color = 'Green';
  //     } else if (
  //       record.status === 'Full Kitting (Individual)' ||
  //       record.status === 'Full Kitting (Individual) and in Production Planning'
  //     ) {
  //       color = '#b902fe';
  //     } else if (record.status === 'Order Dispatched') {
  //       color = '#eb2f96';
  //     } else if (record.status === 'Full Kitting Pending but in Planning') {
  //       color = 'rgb(239, 108, 0)';
  //     } else if (record.status === 'Full Kitting Pending but in Production Planning') {
  //       color = '#096dd9';
  //     } else if (record.status.startsWith('Materials Ordered')) {
  //       color = '#e29705';
  //     } else if (record.status.startsWith('Materials to be Ordered')) {
  //       color = 'red';
  //     }
  //   }

  //   const value = get(arg, keys, null);

  //   return (
  //     <Tooltip placement="topLeft" title={value}>
  //       <span style={{ color }}>{value}</span>
  //     </Tooltip>
  //   );
  // };

  const ArtworkStatusWithColors = arg => {
    let color;
    if (arg) {
      if (arg === 'Done') {
        color = 'Green';
      } else if (arg === 'Pending') {
        color = 'red';
      }
    }
    return (
      <Tooltip placement="topLeft" title={arg}>
        <span style={{ color }}>{arg}</span>
      </Tooltip>
    );
  };

  const columns = [
    {
      title: 'Order No.',
      dataIndex: 'id',
      sorter: sorter('id'),
      filterDropdown,
      onFilter: onFilter('id'),
      width: 90,
      fixed: 'left',
    },
    {
      title: 'Finished Good Name',
      dataIndex: 'formulation',
      sorter: sorter('formulation'),
      filterDropdown,
      onFilter: onFilter('formulation'),
      render: tooltipShow,
      width: 200,
      fixed: 'left',
    },
    {
      title: 'Consignor',
      dataIndex: 'consignor',
      sorter: sorter('consignor'),
      onFilter: onFilter('consignor'),
      filterDropdown,
      width: 120,
    },
    {
      title: 'Created On',
      dataIndex: 'createdAt',
      sorter: dateSorter('createdAt'),
      filterDropdown: dateFilterDropdown,
      onFilter: onDateFilter('createdAt'),
      render: createdAt => (createdAt ? moment(createdAt).format('DD MMM YYYY') : null),
      width: 110,
    },
    {
      title: 'Authorized On',
      dataIndex: 'authorizedAt',
      sorter: dateSorter('authorizedAt'),
      filterDropdown: dateFilterDropdown,
      onFilter: onDateFilter('authorizedAt'),
      render: authorizedAt => (authorizedAt ? moment(authorizedAt).format('DD MMM YYYY') : null),
      width: 120,
    },
    {
      title: 'Estimated Dispatch Date',
      dataIndex: 'estDispatchDate',
      sorter: dateSorter('estDispatchDate'),
      filterDropdown: dateFilterDropdown,
      onFilter: onDateFilter('estDispatchDate'),
      render: estDispatchDate => (estDispatchDate ? moment(estDispatchDate).format('DD MMM YYYY') : null),
      width: 120,
    },
    {
      title: 'Customer',
      dataIndex: 'customer',
      sorter: sorter('customer'),
      filterDropdown,
      onFilter: onFilter('customer'),
      ellipsis: { showTitle: false },
      render: tooltipShow,
      width: 120,
    },
    {
      title: 'Section',
      dataIndex: 'section',
      sorter: sorter('section'),
      filterDropdown,
      onFilter: onFilter('section'),
      render: tooltipShow,
      width: 110,
    },
    {
      title: 'Quantity',
      dataIndex: 'quantity',
      sorter: sorter('quantity'),
      filterDropdown,
      onFilter: onFilter('quantity'),
      width: 130,
      align: 'right',
    },
    {
      title: 'Rate',
      dataIndex: 'rate',
      sorter: sorter('rate'),
      filterDropdown,
      onFilter: onFilter('rate'),
      render: rate => Number(rate).toLocaleString('en-IN'),
      width: 130,
      align: 'right',
    },
    {
      title: 'MRP',
      dataIndex: 'mrp',
      sorter: sorter('mrp'),
      filterDropdown,
      onFilter: onFilter('mrp'),
      render: mrp => Number(mrp).toLocaleString('en-IN'),
      width: 130,
      align: 'right',
    },
    {
      title: 'Nature',
      dataIndex: 'nature',
      sorter: sorter('nature'),
      filterDropdown,
      onFilter: onFilter('nature'),
      render: nature => startCase(nature),
      width: 110,
    },
    {
      title: 'Transport',
      dataIndex: 'transport',
      filterDropdown,
      sorter: sorter('transport'),
      onFilter: onFilter('transport'),
      width: 110,
    },
    {
      title: 'Payment Terms',
      dataIndex: 'paymentTerms',
      sorter: sorter('paymentTerms'),
      filterDropdown,
      onFilter: onFilter('paymentTerms'),
      render: paymentTerms => startCase(paymentTerms),
      width: 110,
    },
    {
      title: 'Advance Deposit Date',
      dataIndex: 'dateOfAdvanceDeposit',
      sorter: dateSorter('dateOfAdvanceDeposit'),
      filterDropdown: dateFilterDropdown,
      onFilter: onDateFilter('dateOfAdvanceDeposit'),
      width: 110,
      render: dateOfAdvanceDeposit =>
        dateOfAdvanceDeposit ? moment(dateOfAdvanceDeposit).format('DD MMM YYYY') : null,
    },
    {
      title: 'Deposited Amount',
      dataIndex: 'depositedAmount',
      sorter: sorter('depositedAmount'),
      filterDropdown,
      onFilter: onFilter('depositedAmount'),
      width: 130,
    },
    {
      title: 'Customer Remarks',
      dataIndex: 'remarks',
      // sorter: sorter('remarks'),
      // filterDropdown,
      // onFilter: onFilter('remarks'),
      ellipsis: { showTitle: false },
      render: tooltipShow,
      width: 110,
    },
    {
      title: 'Internal Remarks',
      dataIndex: 'internalRemarks',
      // sorter: sorter('internalRemarks'),
      // onFilter: onFilter('internalRemarks'),
      // filterDropdown,
      ellipsis: { showTitle: false },
      render: tooltipShow,
      width: 110,
    },
    {
      title: 'Change Req. in Artwork',
      dataIndex: 'isChangeRequired',
      sorter: sorter('isChangeRequired'),
      filterDropdown,
      onFilter: onFilter('isChangeRequired'),
      render: isChangeRequired => startCase(isChangeRequired),
      width: 110,
    },
    {
      title: 'Artwork Status',
      dataIndex: 'artworkStatus',
      sorter: sorter('artworkStatus'),
      filterDropdown,
      onFilter: onFilter('artworkStatus'),
      width: 110,
      render: ArtworkStatusWithColors,
    },
    {
      title: 'Done By',
      dataIndex: 'createdByUser',
      sorter: sorter('createdByUser'),
      filterDropdown,
      onFilter: onFilter('createdByUser'),
      render: tooltipShow,
      width: 120,
    },
  ];

  const renderActionIcons = record =>
    !record.isDispensed ? (
      <div>
        {permissions.includes('edit_order') ? (
          <>
            <Tooltip placement="bottom" title="Edit">
              <Link to={`/order/edit/${record.id}`}>
                <EditOutlined className={classes.tableIcon} />
              </Link>
            </Tooltip>
            &nbsp;&nbsp;&nbsp;&nbsp;
          </>
        ) : null}
        {permissions.includes('delete_order') ? (
          <>
            <Tooltip placement="bottom" title="Delete">
              <Popconfirm
                title="Are you sure you want to delete?"
                onConfirm={() => onDelete(record.id)}
                okText="Yes"
                cancelText="No"
              >
                <DeleteOutlined className={classes.tableIcon} />
              </Popconfirm>
            </Tooltip>
          </>
        ) : null}
      </div>
    ) : role === 'Owner' ? (
      <>
        <Tooltip placement="bottom" title="Edit">
          <Link to={`/order/edit/${record.id}`}>
            <EditOutlined className={classes.tableIcon} />
          </Link>
        </Tooltip>
        &nbsp;&nbsp;&nbsp;&nbsp;
        <Tooltip placement="bottom" title="Delete">
          <Popconfirm
            title="Are you sure you want to delete?"
            onConfirm={() => onDelete(record.id)}
            okText="Yes"
            cancelText="No"
          >
            <DeleteOutlined className={classes.tableIcon} />
          </Popconfirm>
        </Tooltip>
      </>
    ) : null;

  if (permissions.includes('edit_order') || permissions.includes('delete_order')) {
    columns.push({
      title: 'Actions',
      width: 70,
      render: renderActionIcons,
    });
  }

  // const renderAuthorizationColumn = record => {
  //   if (permissions.includes('authorize_order')) {
  //     if (!record.isAuthorized) {
  //       return (
  //         <Tooltip placement="bottom" title="Authorize Order">
  //           <FileDoneOutlined className={classes.tableIcon} onClick={() => showModal(record.id, 'Authorize')} />
  //         </Tooltip>
  //       );
  //     }
  //     return 'True';
  //   }
  //   return null;
  // };

  // if (permissions.includes('authorize_order')) {
  //   columns.push({
  //     title: 'Authorize',
  //     width: 85,
  //     render: renderAuthorizationColumn,
  //   });
  // }

  const renderCustomerApprovalColumn = record => {
    if (permissions.includes('order_customer_approval') && record.isChangeRequired && record.isAuthorized) {
      if (!record.changeVerifiedByCustomerAt) {
        return (
          <Tooltip placement="bottom" title="Customer Approval">
            <FileDoneOutlined className={classes.tableIcon} onClick={() => showModal(record.id, 'CustomerApproval')} />
          </Tooltip>
        );
      }
      if (record.changeVerifiedByCustomerAt && !record.changeVerifiedByUserAt) {
        return (
          <>
            True / &nbsp;
            <Tooltip placement="bottom" title="Cancel Customer Approval">
              <FileDoneOutlined
                className={classes.tableIcon}
                onClick={() => showModal(record.id, 'CancelCustomerApproval')}
              />
            </Tooltip>
          </>
        );
      }
      return 'True';
    }
    return null;
  };

  if (permissions.includes('order_customer_approval')) {
    columns.push({
      title: 'Customer Approval',
      width: 85,
      render: renderCustomerApprovalColumn,
    });
  }

  const renderInternalApprovalColumn = record => {
    if (permissions.includes('order_internal_approval') && record.isAuthorized && record.changeVerifiedByCustomerAt) {
      if (!record.changeVerifiedByUserAt) {
        return (
          <Tooltip placement="bottom" title="Internal Approval">
            <FileDoneOutlined className={classes.tableIcon} onClick={() => showModal(record.id, 'InternalApproval')} />
          </Tooltip>
        );
      }
      return 'True';
    }
    return null;
  };

  if (permissions.includes('order_internal_approval')) {
    columns.push({
      title: 'Internal Approval',
      width: 80,
      render: renderInternalApprovalColumn,
    });
  }

  const renderArtworkUploadColumn = record => {
    if (permissions.includes('order_artwork_upload') && record.isAuthorized) {
      return (
        <Tooltip placement="bottom" title="Upload Artwork">
          <CloudUploadOutlined
            className={classes.tableIcon}
            onClick={() => showModal(record.id, 'UploadFiles', 'artwork')}
          />
        </Tooltip>
      );
    }
    return null;
  };

  if (permissions.includes('order_artwork_upload')) {
    columns.push({
      title: 'Upload Artwork',
      width: 72,
      render: renderArtworkUploadColumn,
    });
  }

  const renderArtworkViewColumn = record => {
    if (permissions.includes('order_artwork_view') && record.isAuthorized) {
      let color;
      if (record.artworkStatus) {
        if (record.artworkStatus === 'Done') {
          color = 'Green';
        } else if (record.artworkStatus === 'Pending') {
          color = 'red';
        }
      }
      return (
        <Tooltip placement="bottom" title="View Artwork">
          <CloudDownloadOutlined
            style={{ color }}
            className={classes.tableIcon}
            onClick={() => showModal(record.id, 'ViewFiles', 'artwork')}
          />
        </Tooltip>
      );
    }
    return null;
  };

  if (permissions.includes('order_artwork_view')) {
    columns.push({
      title: 'View Artwork',
      width: 72,
      render: renderArtworkViewColumn,
    });
  }

  columns.push({
    title: 'View Product Files',
    width: 75,
    render: record => (
      <Tooltip placement="bottom" title="View Files">
        <CloudDownloadOutlined
          className={classes.tableIcon}
          onClick={() => showModal(record.id, 'ViewFormulationFiles', false, [], record.formulationId)}
        />
      </Tooltip>
    ),
  });

  const filesTableColumns = [];

  const renderFileTableActionColumn = record => {
    return (
      <Tooltip placement="bottom" title="Delete File">
        <Popconfirm
          title="Are you sure you want to delete?"
          onConfirm={() => onFileDelete(record.id)}
          okText="Yes"
          cancelText="No"
        >
          <DeleteOutlined className={classes.tableIcon} />
        </Popconfirm>
      </Tooltip>
    );
  };

  const renderFileTableNameColumn = record => (
    <a href={record.url} rel="noreferrer noopener" target="_blank">
      <Tooltip placement="bottom" title={`Click to open/download "${record.fileName}" file.`}>
        {record.fileName}
      </Tooltip>
    </a>
  );

  filesTableColumns.push({
    title: 'File',
    render: renderFileTableNameColumn,
  });

  filesTableColumns.push({
    title: 'Delete',
    render: renderFileTableActionColumn,
    width: 70,
  });

  if (permissions.includes('production_planning_approval')) {
    columns.push({
      title: 'Monthly Plan',
      width: 100,
      render: (_text, record) =>
        record.isAuthorized && !record.isInPlanning && !record.isInProductionPlanning ? (
          <Tooltip placement="bottom" title="Monthly Plan">
            <Popconfirm
              title="Are you sure want to move this order to monthly plan?"
              onConfirm={() => onPlanningApproval(record.id)}
              okText="Confirm"
              cancelText="Cancel"
            >
              <FileDoneOutlined className={classes.tableIcon} />
            </Popconfirm>
          </Tooltip>
        ) : null,
    });
  }

  const renderShortageItemsColumn = record => {
    if (permissions.includes('shortage_item_list')) {
      return (
        <Tooltip placement="bottom" title="Shortage Items List">
          <a
            onClick={() => showModal(record.id, 'ShortageItemsList')}
            onKeyPress={() => showModal(record.id, 'ShortageItemsList')}
            role="link"
            tabIndex={0}
          >
            View
          </a>
        </Tooltip>
      );
    }
    return null;
  };

  const renderCommonItemsColumn = record => {
    if (permissions.includes('shortage_item_list')) {
      return (
        <Tooltip placement="bottom" title="Common Items List">
          <a
            onClick={() => showModal(record.id, 'CommonItemsList', null, record.conflictMaterials)}
            onKeyPress={() => showModal(record.id, 'CommonItemsList', null, record.conflictMaterials)}
            role="link"
            tabIndex={0}
          >
            View
          </a>
        </Tooltip>
      );
    }
    return null;
  };

  if (permissions.includes('shortage_item_list')) {
    columns.push(
      {
        title: 'Shortage Items',
        width: 85,
        render: renderShortageItemsColumn,
      },
      {
        title: 'Common Items',
        width: 80,
        render: renderCommonItemsColumn,
      }
    );
  }

  const setPurchaseOrderValues = (materialId, toOrder) => {
    dispatch(setPurchaseOrder({ orderId, materialId, quantity: toOrder }));
    history.push('/purchaseorder/new');
  };

  const renderCreatePurchaseOrderColumn = record => {
    if (permissions.includes('shortage_item_list')) {
      if (+(+record.totalUsage - +record.stock - +record.pendingPurchaseOrderQuantity) > 0) {
        return (
          <Tooltip placement="bottom" title="Create Purchase Order">
            <a
              role="link"
              tabIndex={0}
              onClick={() =>
                setPurchaseOrderValues(
                  record.materialId,
                  +record.totalUsage - +record.stock - +record.pendingPurchaseOrderQuantity
                )
              }
              onKeyPress={() =>
                setPurchaseOrderValues(
                  record.materialId,
                  +record.totalUsage - +record.stock - +record.pendingPurchaseOrderQuantity
                )
              }
            >
              Create
            </a>
          </Tooltip>
        );
      }
      return null;
    }
    return null;
  };

  const rowTextInColor = (arg, record) => {
    let color;
    if (record.pendingPOs === null) {
      color = 'Red';
    } else {
      color = '#096dd9';
    }

    return <span style={{ color }}>{materialTypes.includes(`${arg}`) ? startCase(arg) : arg}</span>;
  };

  const shortageItemTableColumns = [
    {
      title: 'Name',
      dataIndex: 'name',
      render: rowTextInColor,
    },
    {
      title: 'Type',
      dataIndex: 'type',
      render: rowTextInColor,
    },
    {
      title: 'Unit',
      dataIndex: 'unit',
      render: rowTextInColor,
    },
    {
      title: 'Stock',
      dataIndex: 'stock',
      align: 'right',
      render: rowTextInColor,
    },
    {
      title: 'Qty Req. for this Order',
      dataIndex: 'usagePerOrder',
      align: 'right',
      render: rowTextInColor,
    },
    {
      title: 'Qty Req. for all Orders',
      dataIndex: 'totalUsage',
      align: 'right',
      render: rowTextInColor,
    },
    {
      title: 'Pending PO Qty',
      dataIndex: 'pendingPurchaseOrderQuantity',
      align: 'right',
      render: rowTextInColor,
    },
    {
      title: 'Req. contributed by',
      dataIndex: 'usedByFormulations',
      // eslint-disable-next-line react/display-name
      render: (_data, record) => {
        let color;
        if (record.pendingPOs === null) {
          color = 'Red';
        } else {
          color = '#096dd9';
        }

        return <span style={{ color }}>{(record.usedByFormulations || []).join(', ')}</span>;
      },
    },
    {
      title: 'To Order',
      dataIndex: 'toOrder',
      // eslint-disable-next-line react/display-name
      render: (_data, record) => {
        let color;
        if (record.pendingPOs === null) {
          color = 'Red';
        } else {
          color = '#096dd9';
        }

        return (
          <span style={{ color }}>
            {(+record.totalUsage - +record.stock - +record.pendingPurchaseOrderQuantity).toFixed(2)}
          </span>
        );
      },
      align: 'right',
    },
    {
      title: 'Create PO',
      render: renderCreatePurchaseOrderColumn,
    },
  ];

  const ViewPOListColumns = [
    {
      title: 'Created On',
      dataIndex: 'createdAt',
      render: createdAt => (createdAt ? moment(createdAt).format('DD MMM YYYY') : null),
    },
    {
      title: 'PO No.',
      dataIndex: 'id',
    },
    {
      title: 'Supplier',
      dataIndex: 'supplier',
      ellipsis: { showTitle: false },
      render: tooltipShow,
    },
    {
      title: 'Quantity',
      dataIndex: 'quantity',
      align: 'right',
    },
    {
      title: 'Rate',
      dataIndex: 'rate',
      align: 'right',
      render: rate => Number(rate).toLocaleString('en-IN'),
    },
    {
      title: 'Qty Received',
      dataIndex: 'received',
      align: 'right',
    },
    {
      title: 'Lead Time',
      dataIndex: 'leadTime',
      align: 'right',
    },
    {
      title: 'Expected On',
      dataIndex: 'expectedBy',
      render: (_text, record) =>
        record.createdAt && record.leadTime
          ? moment(record.createdAt).add(record.leadTime, 'days').format('DD MMM YYYY')
          : null,
    },
    {
      title: 'Remarks',
      dataIndex: 'remarks',
      ellipsis: { showTitle: false },
      render: tooltipShow,
    },
  ];

  const renderBatchDetailsColumn = record => {
    if (permissions.includes('batch_details') && record.isAuthorized) {
      if (!record.filledBatchDetails) {
        return (
          <Tooltip placement="bottom" title="Fill Batch Detail">
            <FileDoneOutlined
              className={classes.tableIcon}
              onClick={() => showBatchModal(record.id, record.mrp, 'BatchDetails')}
            />
          </Tooltip>
        );
      }
      return 'Done';
    }
    return null;
  };

  if (permissions.includes('batch_details')) {
    columns.push({
      title: 'Fill Batch Detail',
      width: 70,
      render: renderBatchDetailsColumn,
    });
  }

  const renderOrderDetailsColumn = record => {
    if (permissions.includes('view_job_card')) {
      return (
        <Tooltip placement="top" title="View Job Card">
          <FileTextOutlined className={classes.tableIcon} onClick={() => showModal(record.id, 'ViewOrderDetails')} />
        </Tooltip>
      );
    }
  };

  if (permissions.includes('view_job_card')) {
    columns.push(
      {
        title: 'Job Card',
        width: 70,
        render: renderOrderDetailsColumn,
      },
      ...(role === 'Owner'
        ? [
            {
              title: 'Edit Job Card',
              key: 'Edit Job Card',
              width: 60,
              render: (_text, record) =>
                record.PackingEndTime ? <FileTextOutlined onClick={() => showModal(record.id, 'EditJobCard')} /> : null,
            },
          ]
        : [])
    );
  }

  const renderFilesUploadColumn = record => {
    if (permissions.includes('order_files_upload') && record.isAuthorized) {
      return (
        <Tooltip placement="bottom" title="Upload Files">
          <CloudUploadOutlined
            className={classes.tableIcon}
            onClick={() => showModal(record.id, 'UploadFiles', 'file')}
          />
        </Tooltip>
      );
    }
    return null;
  };

  if (permissions.includes('order_files_upload')) {
    columns.push({
      title: 'Upload Files',
      width: 70,
      render: renderFilesUploadColumn,
    });
  }

  const renderFilesViewColumn = record => {
    if (permissions.includes('order_files_view') && record.isAuthorized) {
      let color;
      if (record.totalFiles) {
        if (record.totalFiles > 0) {
          color = 'Green';
        } else {
          color = 'Red';
        }
      }
      return (
        <Tooltip placement="bottom" title="View Files">
          <CloudDownloadOutlined
            style={{ color }}
            className={classes.tableIcon}
            onClick={() => showModal(record.id, 'ViewFiles', 'file')}
          />
        </Tooltip>
      );
    }
    return null;
  };

  if (permissions.includes('order_files_view')) {
    columns.push({
      title: 'View Files',
      width: 70,
      render: renderFilesViewColumn,
    });
  }

  const renderMIS = record => {
    if (permissions.includes('order_MIS')) {
      return (
        <a
          onClick={() => showModal(record.id, 'ViewOrderMIS')}
          onKeyPress={() => showModal(record.id, 'ViewOrderMIS')}
          role="link"
          tabIndex={0}
        >
          View
        </a>
      );
    }
  };

  if (permissions.includes('order_MIS')) {
    columns.push({
      title: 'MIS',
      render: renderMIS,
      width: 60,
    });
  }

  columns.push({
    title: 'Status',
    dataIndex: 'status',
    sorter: sorter('status'),
    filterDropdown,
    onFilter: onFilter('status'),
    render: tooltipShowWithColors,
    width: 150,
    fixed: 'right',
  });

  const misTextColor = (data, record) => {
    if (!record.isvalid) {
      return <span style={{ color: 'red' }}>{data}</span>;
    }
    return data;
  };

  const misColumn = [
    {
      title: 'Name',
      dataIndex: 'user',
      sorter: sorter('user'),
      onFilter: onFilter('user'),
      filterDropdown,
      render: (data, record) => misTextColor(data, record),
    },
    {
      title: 'KPI',
      dataIndex: 'kpi',
      sorter: sorter('kpi'),
      onFilter: onFilter('kpi'),
      filterDropdown,
      render: (data, record) => misTextColor(data, record),
    },
    {
      title: 'Valid Time',
      dataIndex: 'validTime',
      sorter: sorter('validTime'),
      onFilter: onFilter('validTime'),
      filterDropdown,
      render: (data, record) => misTextColor(data, record),
    },
    {
      title: 'Done',
      dataIndex: 'done',
      sorter: sorter('done'),
      onFilter: onFilter('done'),
      filterDropdown,
      render: (data, record) => misTextColor(data, record),
    },
  ];

  const exportOrders = useCallback(async () => {
    try {
      let file;

      if (Tab === 'unauthorized') {
        const response = await Api.getFile(`/orders/export`, { isAuthorized: false, isDispatched: false });
        file = response.data;
      }
      if (Tab === 'authorized') {
        const response = await Api.getFile(`/orders/export`, { isAuthorized: true, isDispatched: false });
        file = response.data;
      }
      if (Tab === 'dispatched') {
        const response = await Api.getFile(`/orders/export`, { isAuthorized: true, isDispatched: true });
        file = response.data;
      }

      fileDownload(file, `${Tab}-order-list.csv`);
    } catch (error) {
      errorNotification(error);
    }
  }, [Tab]);

  return (
    <Structure>
      <>
        <Row gutter={[8, 8]}>
          <Col>
            <Select placeholder="Select year" onChange={value => setFinancialYear(value)} defaultValue={financialYear}>
              <Select.Option value="2019">19-20</Select.Option>
              <Select.Option value="2020">20-21</Select.Option>
              <Select.Option value="2021">21-22</Select.Option>
              <Select.Option value="2022">22-23</Select.Option>
              <Select.Option value="2023">23-24</Select.Option>
              <Select.Option value="2024">24-25</Select.Option>
              <Select.Option value="2025">25-26</Select.Option>
              <Select.Option value="2026">26-27</Select.Option>
              <Select.Option value="2027">27-28</Select.Option>
              <Select.Option value="2028">28-29</Select.Option>
              <Select.Option value="2029">29-30</Select.Option>
              <Select.Option value="2030">30-31</Select.Option>
            </Select>
          </Col>
          <Col>
            <Select defaultValue="authorized" disabled style={{ width: 130 }} onChange={SetTab}>
              {/* <Option key="unauthorized" value="unauthorized">
                Unauthorized
              </Option> */}
              <Option key="authorized" value="authorized">
                Authorized
              </Option>
              {/* <Option key="dispatched" value="dispatched">
                Dispatched
              </Option> */}
            </Select>
          </Col>
          {/* <Col>
            <Select onChange={SetConsignor} placeholder="Consignor" value={Consignor} style={{ width: 280 }}>
              {Object.values(constants.consignors.list).map(consignor => (
                <Select.Option key={consignor} value={consignor}>
                  {consignor}
                </Select.Option>
              ))}
            </Select>
          </Col> */}
          <Col>
            <Button type="default" onClick={exportOrders}>
              Export
            </Button>
          </Col>
        </Row>
        <br />
        <Table
          columns={columns}
          bordered
          rowKey={record => record.id}
          pagination={{ defaultPageSize: 20, position: ['bottomLeft'], showTotal: total => `Total ${total} Items` }}
          dataSource={orderList}
          size="small"
          scroll={{ x: 2000, y: window.innerHeight - 300 }}
        />
        {/* {modalType === 'Authorize' ? (
          <Modal
            open={modalType === 'Authorize'}
            confirmLoading={confirmLoading}
            onCancel={hideModal}
            centered
            maskClosable={false}
            closable={false}
            title="Order Authorization."
            width="400px"
            cancelText="Back"
            okText={
              <Popconfirm title="Are you sure?" onConfirm={() => onAuthorize()} okText="Confirm" cancelText="Cancel">
                Authorize
              </Popconfirm>
            }
            okButtonProps={{ className: classes.button }}
          >
            <p>Sure you want to authorize the order?</p>
          </Modal>
        ) : null} */}
        {modalType === 'CustomerApproval' ? (
          <Modal
            open={modalType === 'CustomerApproval'}
            confirmLoading={confirmLoading}
            onCancel={hideModal}
            centered
            maskClosable={false}
            closable={false}
            title="Customer Approval."
            width="400px"
            cancelText="Back"
            okText={
              <Popconfirm
                title="Are you sure?"
                onConfirm={() => onCustomerApproval({ verified: true })}
                okText="Confirm"
                cancelText="Cancel"
              >
                Approve
              </Popconfirm>
            }
            okButtonProps={{ className: classes.button }}
          >
            <p>Sure customer approved the change required in artwork for this order?</p>
          </Modal>
        ) : null}
        {modalType === 'CancelCustomerApproval' ? (
          <Modal
            open={modalType === 'CancelCustomerApproval'}
            confirmLoading={confirmLoading}
            onCancel={hideModal}
            centered
            maskClosable={false}
            closable={false}
            title="Cancel Customer Approval."
            width="400px"
            cancelText="Back"
            okText={
              <Popconfirm
                title="Are you sure?"
                onConfirm={() => onCustomerApproval({ verified: false })}
                okText="Confirm"
                cancelText="Cancel"
              >
                Cancel Approval
              </Popconfirm>
            }
            okButtonProps={{ className: classes.button }}
          >
            <p>Sure want to cancel the customer consent for the change required in artwork in this order?</p>
          </Modal>
        ) : null}
        {modalType === 'InternalApproval' ? (
          <Modal
            open={modalType === 'InternalApproval'}
            confirmLoading={confirmLoading}
            onCancel={hideModal}
            centered
            maskClosable={false}
            closable={false}
            title="Internal Approval."
            width="400px"
            cancelText="Back"
            okText={
              <Popconfirm
                title="Are you sure?"
                onConfirm={() => onInternalApproval({ verified: true })}
                okText="Confirm"
                cancelText="Cancel"
              >
                Approve
              </Popconfirm>
            }
            okButtonProps={{ className: classes.button }}
          >
            <p>Sure you want to approve the order?</p>
          </Modal>
        ) : null}
        {modalType === 'UploadFiles' ? (
          <Modal
            onOk={hideModal}
            open={modalType === 'UploadFiles'}
            centered
            maskClosable={false}
            closable={false}
            title="Upload."
            width="600px"
            okText="Close"
            cancelButtonProps={{ className: classes.hide }}
            okButtonProps={{ className: classes.button }}
          >
            <Upload multiple onRemove={onRemove} beforeUpload={beforeUpload} fileList={fileList}>
              <Button icon={<CloudUploadOutlined />}>Select Files</Button>
            </Upload>
            <Button
              type="primary"
              onClick={() => onUpload()}
              disabled={fileList.length === 0}
              loading={uploading}
              style={{ marginTop: 16 }}
            >
              {uploading ? 'Uploading' : 'Upload'}
            </Button>
          </Modal>
        ) : null}
        {modalType === 'ViewFiles' ? (
          <Modal
            onOk={hideModal}
            open={modalType === 'ViewFiles'}
            centered
            maskClosable={false}
            title="View Files."
            width="800px"
            okText="Close"
            cancelButtonProps={{ className: classes.hide }}
            closable={false}
            okButtonProps={{ className: classes.button }}
          >
            <Table
              columns={filesTableColumns}
              bordered
              rowKey={record => record.id}
              dataSource={orderFilesList}
              size="small"
              pagination={false}
              scroll={{ x: 425 }}
            />
          </Modal>
        ) : null}
        {modalType === 'PlanningApproval' ? (
          <Modal
            open={modalType === 'PlanningApproval'}
            confirmLoading={confirmLoading}
            onCancel={hideModal}
            centered
            maskClosable={false}
            closable={false}
            title="Planning Approval."
            width="400px"
            cancelText="Back"
            okText={
              <Popconfirm
                title="Are you sure?"
                onConfirm={() => onPlanningApproval()}
                okText="Confirm"
                cancelText="Cancel"
              >
                Approve
              </Popconfirm>
            }
            okButtonProps={{ className: classes.button }}
          >
            <p>Sure you want to approve the order for Planning?</p>
          </Modal>
        ) : null}
        {modalType === 'ShortageItemsList' ? (
          <Modal
            open={modalType === 'ShortageItemsList'}
            onOk={hideModal}
            centered
            maskClosable={false}
            closable={false}
            title="Shortage Items List."
            okText="Close"
            cancelButtonProps={{ className: classes.hide }}
            width="90%"
            okButtonProps={{ className: classes.button }}
          >
            <Table
              columns={shortageItemTableColumns}
              bordered
              rowKey={record => record.materialId}
              dataSource={orderShortageItemsList}
              size="small"
              pagination={false}
              scroll={{ x: 425 }}
              expandable={{
                // eslint-disable-next-line react/display-name
                expandedRowRender: record => (
                  <div style={{ margin: '0 0 0 14px' }}>
                    <Table
                      columns={ViewPOListColumns}
                      bordered
                      rowKey={data => data.id}
                      dataSource={record.pendingPOs}
                      size="small"
                      pagination={false}
                      scroll={{ x: 425 }}
                    />
                  </div>
                ),
                rowExpandable: record => record.pendingPOs !== null,
              }}
            />
          </Modal>
        ) : null}
        {modalType === 'CommonItemsList' ? (
          <Modal
            open
            onOk={hideModal}
            centered
            maskClosable={false}
            closable={false}
            title="Common Items List."
            okText="Close"
            cancelButtonProps={{ className: classes.hide }}
            width="90%"
            okButtonProps={{ className: classes.button }}
          >
            <ItemsToOrderTable />
          </Modal>
        ) : null}
        {modalType === 'BatchDetails' ? (
          <Modal
            open={modalType === 'BatchDetails'}
            onCancel={hideModal}
            confirmLoading={confirmLoading}
            title="Fill Batch Details."
            centered
            width="800px"
            maskClosable={false}
            closable={false}
            cancelText="Back"
            okButtonProps={{ className: classes.button }}
            okText={
              <Popconfirm
                title="Sure want to submit form?"
                onConfirm={() => onBatchSubmit()}
                okText="Confirm"
                cancelText="Cancel"
              >
                Submit
              </Popconfirm>
            }
          >
            <Row>
              <h3>Order Number: {orderId}</h3>
            </Row>
            <Row>
              <Col xs={{ span: 24 }} lg={{ span: 10 }}>
                <Field label="Batch Series" allowClear required name="batchSeries" component={AntSelect}>
                  <Select.Option key={null} value="" disabled>
                    Select series
                  </Select.Option>
                  {seriesList.map(series => (
                    <Select.Option key={series.series} value={series.series}>
                      {series.series}
                    </Select.Option>
                  ))}
                </Field>
              </Col>
              <Col xs={{ span: 24 }} lg={{ span: 10, offset: 4 }}>
                <Field
                  label="Batch Number"
                  type="number"
                  disabled
                  allowClear
                  name="batchNo"
                  placeholder="Batch Number"
                  component={AntInput}
                />
              </Col>
            </Row>
            <Row>
              <Col xs={{ span: 24 }} lg={{ span: 10 }}>
                <Field
                  label="MRP As Per Order"
                  type="number"
                  disabled
                  allowClear
                  name="mrpAsPerOrder"
                  placeholder="MRP As Per Order"
                  component={AntInput}
                />
              </Col>
              <Col xs={{ span: 24 }} lg={{ span: 10, offset: 4 }}>
                <Field
                  label="Batch MRP"
                  type="number"
                  required
                  allowClear
                  min={0}
                  name="mrp"
                  placeholder="Batch MRP"
                  component={AntInput}
                />
              </Col>
            </Row>
            <Row>
              <Col xs={{ span: 24 }} lg={{ span: 10 }}>
                <Field
                  required
                  allowClear
                  label="Manufactured Date"
                  name="mfgDate"
                  placeholder="Manufactured Date"
                  component={AntDatePicker}
                  picker="month"
                />
              </Col>
              <Col xs={{ span: 24 }} lg={{ span: 10, offset: 4 }}>
                <Field
                  required
                  allowClear
                  label="Valid For (Months)"
                  name="validity"
                  placeholder="Valid For (in months)"
                  component={AntInput}
                  type="number"
                  min={1}
                />
              </Col>
            </Row>
          </Modal>
        ) : null}

        {modalType === 'ViewOrderDetails' ? <JobCard order={order} hideModal={hideModal} /> : null}
        {modalType === 'ViewOrderMIS' ? (
          <Modal
            open={modalType === 'ViewOrderMIS'}
            onOk={hideModal}
            centered
            maskClosable={false}
            closable={false}
            title="MIS."
            okText="Close"
            cancelButtonProps={{ className: classes.hide }}
            width="70%"
            okButtonProps={{ className: classes.button }}
          >
            <Table
              columns={misColumn}
              bordered
              rowKey={record => record.kpi}
              dataSource={orderMIS}
              size="small"
              pagination={false}
              scroll={{ x: 425 }}
            />
          </Modal>
        ) : null}
        {order && orderId && modalType === 'EditJobCard' && (
          <EditJobCardModal recordId={orderId} order={order} hideModal={hideModal} />
        )}

        <Modal
          onOk={hideModal}
          open={modalType === 'ViewFormulationFiles'}
          centered
          maskClosable={false}
          title="View Product Files."
          width="800px"
          okText="Close"
          cancelButtonProps={{ className: classes.hide }}
          closable={false}
          okButtonProps={{ className: classes.button }}
        >
          <Table
            columns={[
              {
                title: 'File',
                render: record => (
                  <a href={record.url} rel="noreferrer noopener" target="_blank">
                    <Tooltip placement="bottom" title={`Click to open/download "${record.fileName}" file.`}>
                      {record.fileName}
                    </Tooltip>
                  </a>
                ),
              },
            ]}
            bordered
            rowKey={record => record.id}
            dataSource={formulationFilesList}
            size="small"
            pagination={{ position: ['bottomLeft'] }}
          />
        </Modal>
      </>
    </Structure>
  );
};

OrderList.propTypes = {
  classes: PropTypes.object.isRequired,
  change: PropTypes.func.isRequired,
};

const StyledOrderList = withStyles(styles)(OrderList);

const FormedOrderList = reduxForm({
  form: 'BatchDetailForm',
  enableReinitialize: true,
})(StyledOrderList);

export default FormedOrderList;

export const saleReturnList = (state = [], action) => {
  if (action.type === 'FETCH_SALE_RETURN_LIST') {
    return action.payload;
  }
  if (action.type === 'CLEAR_SALE_RETURN_LIST') {
    return [];
  }
  return state;
};

export const saleReturn = (state = {}, action) => {
  if (action.type === 'FETCH_SALE_RETURN') {
    return action.payload;
  }
  if (action.type === 'CLEAR_SALE_RETURN') {
    return {};
  }
  return state;
};

// export const saleReturnFilesList = (state = [], action) => {
//   if (action.type === 'FETCH_SALE_RETURN_FILES_LIST') {
//     return action.payload;
//   }
//   if (action.type === 'CLEAR_SALE_RETURN_FILES_LIST') {
//     return [];
//   }
//   return state;
// };

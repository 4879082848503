export const sectionList = (state = [], action) => {
  if (action.type === 'FETCH_SECTION_LIST') {
    return action.payload;
  }
  if (action.type === 'CLEAR_SECTION_LIST') {
    return [];
  }
  return state;
};

export const section = (state = {}, action) => {
  if (action.type === 'FETCH_SECTION') {
    return action.payload;
  }
  if (action.type === 'CLEAR_SECTION') {
    return {};
  }
  return state;
};

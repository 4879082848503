import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import withStyles from 'react-jss';
import { useDispatch, useSelector } from 'react-redux';
import { Card, Col, DatePicker, Row, Select, Table, Tooltip } from 'antd';
import Modal from 'antd/lib/modal/Modal';
import moment from 'moment';
import startCase from 'lodash/startCase';
import Structure from '../Structure/Structure';
import styles from '../CommonStyles';
import { getFinancialSummary, clearFinancialSummary, getTransactionList, clearTransactionList } from '../Actions';
import { get, onFilter, sorter, filterDropdown } from '../utils';
import Heading from '../Components/Heading';
import constants from '../constants';

const CashFlow = props => {
  const { classes } = props;
  const dispatch = useDispatch();

  const financialSummary = useSelector(state => state.financialSummary);
  const transactionList = useSelector(state => state.transactionList);

  const [viewModal, setViewModal] = useState(false);
  const [summaryHead, setSummaryHead] = useState(null);

  const [From, SetFrom] = useState(null);
  const [To, SetTo] = useState(null);
  const [Consignor, SetConsignor] = useState(constants.consignors.list.plusFormulationsHeadOffice);

  useEffect(() => {
    return () => {
      dispatch(clearFinancialSummary());
      dispatch(clearTransactionList());
    };
  }, []);

  useEffect(() => {
    dispatch(clearFinancialSummary());

    if (From && To && Consignor) {
      dispatch(getFinancialSummary({ from: From, to: To, consignor: Consignor }));
    }
  }, [From, To, Consignor]);

  useEffect(() => {
    dispatch(clearTransactionList());

    if (summaryHead && From && To) {
      dispatch(getTransactionList({ from: From, to: To, head: summaryHead }));
    }
  }, [financialSummary, summaryHead, From, To]);

  const showModal = head => {
    setViewModal(true);
    setSummaryHead(head);
  };

  const hideModal = () => {
    setViewModal(false);
    setSummaryHead(null);
    dispatch(clearTransactionList());
  };

  const tooltipShow = arg => (
    <Tooltip placement="topLeft" title={Object.values(constants.bank.types).includes(`${arg}`) ? startCase(arg) : arg}>
      {Object.values(constants.bank.types).includes(`${arg}`) ? startCase(arg) : arg}
    </Tooltip>
  );

  const TransactionColumns = [
    {
      title: 'Type',
      dataIndex: 'type',
      sorter: sorter('type'),
      filterDropdown,
      onFilter: onFilter('type'),
      render: type => startCase(type),
    },
    {
      title: 'Bank',
      dataIndex: 'bank',
      sorter: sorter('bank'),
      filterDropdown,
      onFilter: onFilter('bank'),
      render: tooltipShow,
    },
    {
      title: 'Narration',
      dataIndex: 'narration',
      sorter: sorter('narration'),
      filterDropdown,
      onFilter: onFilter('narration'),
      render: tooltipShow,
    },
    {
      title: 'Summary Head',
      dataIndex: 'head',
      sorter: sorter('head'),
      filterDropdown,
      onFilter: onFilter('head'),
      render: tooltipShow,
    },
    {
      title: 'Date',
      dataIndex: 'transDate',
      sorter: sorter('transDate'),
      filterDropdown,
      onFilter: onFilter('transDate'),
      render: transDate => (transDate ? moment(transDate).format('DD MMM YYYY') : null),
    },
    {
      title: 'Amount',
      dataIndex: 'amount',
      sorter: sorter('amount'),
      filterDropdown,
      onFilter: onFilter('amount'),
      render: amount => Number(amount).toLocaleString('en-IN'),
      align: 'right',
    },
    {
      title: 'Closing Balance',
      dataIndex: 'closingBalance',
      sorter: sorter('closingBalance'),
      filterDropdown,
      onFilter: onFilter('closingBalance'),
      render: closingBalance => Number(closingBalance).toLocaleString('en-IN'),
      align: 'right',
    },
    {
      title: 'Customer',
      dataIndex: 'customer',
      sorter: sorter('customer'),
      filterDropdown,
      onFilter: onFilter('customer'),
      render: tooltipShow,
      ellipsis: { showTitle: false },
    },
    {
      title: 'Supplier',
      dataIndex: 'supplier',
      sorter: sorter('supplier'),
      filterDropdown,
      onFilter: onFilter('supplier'),
      render: tooltipShow,
      ellipsis: { showTitle: false },
    },
    {
      title: 'Reference No.',
      dataIndex: 'refNo',
      sorter: sorter('refNo'),
      filterDropdown,
      onFilter: onFilter('refNo'),
    },
    {
      title: 'Remarks',
      dataIndex: 'remarks',
      sorter: sorter('remarks'),
      filterDropdown,
      onFilter: onFilter('remarks'),
      render: tooltipShow,
      ellipsis: { showTitle: false },
    },
    {
      title: 'Done By',
      dataIndex: 'createdByUser',
      sorter: sorter('createdByUser'),
      filterDropdown,
      onFilter: onFilter('createdByUser'),
    },
  ];

  // const columns = [
  //   {
  //     title: 'Consignor',
  //     dataIndex: 'consignor',
  //   },
  //   {
  //     title: 'Bank',
  //     dataIndex: 'bank',
  //   },
  //   {
  //     title: 'Opening Balance',
  //     dataIndex: 'initialBalance',
  //     render: initialBalance => Number(initialBalance).toLocaleString('en-IN'),
  //     align: 'right',
  //   },
  //   {
  //     title: 'Closing Balance',
  //     dataIndex: 'closingBalance',
  //     render: closingBalance => Number(closingBalance).toLocaleString('en-IN'),
  //     align: 'right',
  //   },
  // ];

  return (
    <Structure>
      <>
        <Heading text="Cash Flow" />
        <Row gutter={[8, 8]}>
          <Col>
            <DatePicker onChange={SetFrom} placeholder="From" value={From} />
          </Col>
          <Col>
            <DatePicker onChange={SetTo} placeholder="To" value={To} />
          </Col>
          <Col>
            <Select onChange={SetConsignor} placeholder="Consignor" value={Consignor} style={{ width: 280 }}>
              {Object.values(constants.consignors.list).map(consignor => (
                <Select.Option key={consignor} value={consignor}>
                  {consignor}
                </Select.Option>
              ))}
            </Select>
          </Col>
        </Row>
        <Row gutter={[8, 8]}>
          <Col xs={24} lg={24}>
            <Card title="Actual Cash Flow">
              <Row gutter={[8, 8]}>
                <Col xs={24} lg={12}>
                  <h3 className={classes.heading}>In Flow Of Cash</h3>
                  <Row>
                    <span
                      className={classes.headingColor}
                      onClick={() => {
                        showModal('customerReceiptBank');
                      }}
                      onKeyPress={() => {
                        showModal('customerReceiptBank');
                      }}
                      role="button"
                      tabIndex={0}
                    >
                      Customer Receipt (Bank):
                    </span>
                    <span className={classes.detailColor}>
                      {Number(get(financialSummary, ['customerReceiptBank'], 0)).toLocaleString('en-IN')}
                    </span>
                  </Row>
                  <Row>
                    <span
                      className={classes.headingColor}
                      onClick={() => {
                        showModal('customerReceiptCash');
                      }}
                      onKeyPress={() => {
                        showModal('customerReceiptCash');
                      }}
                      role="button"
                      tabIndex={0}
                    >
                      Customer Receipt (Cash):
                    </span>
                    <span className={classes.detailColor}>
                      {Number(get(financialSummary, ['customerReceiptCash'], 0)).toLocaleString('en-IN')}
                    </span>
                  </Row>
                  <Row>
                    <span
                      className={classes.headingColor}
                      onClick={() => {
                        showModal('capitalInvestmentBank');
                      }}
                      onKeyPress={() => {
                        showModal('capitalInvestmentBank');
                      }}
                      role="button"
                      tabIndex={0}
                    >
                      Capital Investment (Bank):
                    </span>
                    <span className={classes.detailColor}>
                      {Number(get(financialSummary, ['capitalInvestmentBank'], 0)).toLocaleString('en-IN')}
                    </span>
                  </Row>
                  <Row>
                    <span
                      className={classes.headingColor}
                      onClick={() => {
                        showModal('capitalInvestmentCash');
                      }}
                      onKeyPress={() => {
                        showModal('capitalInvestmentCash');
                      }}
                      role="button"
                      tabIndex={0}
                    >
                      Capital Investment (Cash):
                    </span>
                    <span className={classes.detailColor}>
                      {Number(get(financialSummary, ['capitalInvestmentCash'], 0)).toLocaleString('en-IN')}
                    </span>
                  </Row>
                  <Row>
                    <span
                      className={classes.headingColor}
                      onClick={() => {
                        showModal('otherInflowsBank');
                      }}
                      onKeyPress={() => {
                        showModal('otherInflowsBank');
                      }}
                      role="button"
                      tabIndex={0}
                    >
                      Other Inflows (Bank):
                    </span>
                    <span className={classes.detailColor}>
                      {Number(get(financialSummary, ['otherInflowsBank'], 0)).toLocaleString('en-IN')}
                    </span>
                  </Row>
                  <Row>
                    <span
                      className={classes.headingColor}
                      onClick={() => {
                        showModal('otherInflowsCash');
                      }}
                      onKeyPress={() => {
                        showModal('otherInflowsCash');
                      }}
                      role="button"
                      tabIndex={0}
                    >
                      Other Inflows (Cash):
                    </span>
                    <span className={classes.detailColor}>
                      {Number(get(financialSummary, ['otherInflowsCash'], 0)).toLocaleString('en-IN')}
                    </span>
                  </Row>
                  <Row>
                    Total Cash Inflow:
                    <span className={classes.detailColor}>
                      {Number(get(financialSummary, ['totalCashInflow'], 0)).toLocaleString('en-IN')}
                    </span>
                  </Row>
                </Col>
                <Col xs={24} lg={12}>
                  <h3 className={classes.heading}>Out Flow Of Cash</h3>
                  <Row>
                    <span
                      className={classes.headingColor}
                      onClick={() => {
                        showModal('fixedCostBank');
                      }}
                      onKeyPress={() => {
                        showModal('fixedCostBank');
                      }}
                      role="button"
                      tabIndex={0}
                    >
                      Fixed Cost (Bank):
                    </span>
                    <span className={classes.detailColor}>
                      {Number(get(financialSummary, ['fixedCostBank'], 0)).toLocaleString('en-IN')}
                    </span>
                  </Row>
                  <Row>
                    <span
                      className={classes.headingColor}
                      onClick={() => {
                        showModal('fixedCostCash');
                      }}
                      onKeyPress={() => {
                        showModal('fixedCostCash');
                      }}
                      role="button"
                      tabIndex={0}
                    >
                      Fixed Cost (Cash):
                    </span>
                    <span className={classes.detailColor}>
                      {Number(get(financialSummary, ['fixedCostCash'], 0)).toLocaleString('en-IN')}
                    </span>
                  </Row>
                  <Row>
                    <span
                      className={classes.headingColor}
                      onClick={() => {
                        showModal('supplierPaymentBank');
                      }}
                      onKeyPress={() => {
                        showModal('supplierPaymentBank');
                      }}
                      role="button"
                      tabIndex={0}
                    >
                      Supplier Payment (Bank):
                    </span>
                    <span className={classes.detailColor}>
                      {Number(get(financialSummary, ['supplierPaymentBank'], 0)).toLocaleString('en-IN')}
                    </span>
                  </Row>
                  <Row>
                    <span
                      className={classes.headingColor}
                      onClick={() => {
                        showModal('supplierPaymentCash');
                      }}
                      onKeyPress={() => {
                        showModal('supplierPaymentCash');
                      }}
                      role="button"
                      tabIndex={0}
                    >
                      Supplier Payment (Cash):
                    </span>
                    <span className={classes.detailColor}>
                      {Number(get(financialSummary, ['supplierPaymentCash'], 0)).toLocaleString('en-IN')}
                    </span>
                  </Row>
                  <Row>
                    <span
                      className={classes.headingColor}
                      onClick={() => {
                        showModal('capitalExpenditureTDBannk');
                      }}
                      onKeyPress={() => {
                        showModal('capitalExpenditureTDBannk');
                      }}
                      role="button"
                      tabIndex={0}
                    >
                      Capital Expenditure/TD (Bank):
                    </span>
                    <span className={classes.detailColor}>
                      {Number(get(financialSummary, ['capitalExpenditureTDBannk'], 0)).toLocaleString('en-IN')}
                    </span>
                  </Row>
                  <Row>
                    <span
                      className={classes.headingColor}
                      onClick={() => {
                        showModal('capitalExpenditureTDCash');
                      }}
                      onKeyPress={() => {
                        showModal('capitalExpenditureTDCash');
                      }}
                      role="button"
                      tabIndex={0}
                    >
                      Capital Expenditure/TD (Cash):
                    </span>
                    <span className={classes.detailColor}>
                      {Number(get(financialSummary, ['capitalExpenditureTDCash'], 0)).toLocaleString('en-IN')}
                    </span>
                  </Row>
                  <Row>
                    <span
                      className={classes.headingColor}
                      onClick={() => {
                        showModal('otherOutflowsBank');
                      }}
                      onKeyPress={() => {
                        showModal('otherOutflowsBank');
                      }}
                      role="button"
                      tabIndex={0}
                    >
                      Other Outflows (Bank):
                    </span>
                    <span className={classes.detailColor}>
                      {Number(get(financialSummary, ['otherOutflowsBank'], 0)).toLocaleString('en-IN')}
                    </span>
                  </Row>
                  <Row>
                    <span
                      className={classes.headingColor}
                      onClick={() => {
                        showModal('otherOutflowsCash');
                      }}
                      onKeyPress={() => {
                        showModal('otherOutflowsCash');
                      }}
                      role="button"
                      tabIndex={0}
                    >
                      Other Outflows (Cash):
                    </span>
                    <span className={classes.detailColor}>
                      {Number(get(financialSummary, ['otherOutflowsCash'], 0)).toLocaleString('en-IN')}
                    </span>
                  </Row>
                  <Row>
                    Total Cash Out Flow:
                    <span className={classes.detailColor}>
                      {Number(get(financialSummary, ['totalCashOutflow'], 0)).toLocaleString('en-IN')}
                    </span>
                  </Row>
                </Col>
              </Row>
              <Row gutter={[8, 8]}>
                <Col xs={24} lg={24}>
                  <h3 className={classes.heading}>Net Cash Flow</h3>
                  <Row>
                    Net Cash Flow:
                    <span className={classes.detailColor}>
                      {Number(get(financialSummary, ['netCashFlow'], 0)).toLocaleString('en-IN')}
                    </span>
                  </Row>
                </Col>
              </Row>
            </Card>
          </Col>
        </Row>
        <Modal
          open={viewModal}
          onOk={hideModal}
          style={{ top: 20 }}
          maskClosable={false}
          closable={false}
          width="1500px"
          okText="Close"
          cancelButtonProps={{ className: classes.hide }}
          okButtonProps={{ className: classes.button }}
        >
          <Table
            columns={TransactionColumns}
            bordered
            rowKey={record => record.id}
            pagination={{ defaultPageSize: 50, position: ['bottomLeft'] }}
            dataSource={transactionList}
            size="small"
            scroll={{ x: 1050, y: 600 }}
          />
        </Modal>
      </>
    </Structure>
  );
};

CashFlow.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(CashFlow);

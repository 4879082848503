export const jobworks = (state = [], action = {}) => {
  if (action.type === 'FETCH_JOB_WORKS') {
    return action.payload;
  }
  if (action.type === 'CLEAR_JOB_WORKS') {
    return [];
  }
  return state;
};

export const jobwork = (state = {}, action = {}) => {
  if (action.type === 'FETCH_JOB_WORK') {
    return action.payload;
  }
  if (action.type === 'CLEAR_JOB_WORK') {
    return {};
  }
  return state;
};

export const jobworkFiles = (state = [], action) => {
  if (action.type === 'FETCH_JOB_WORK_FILES') {
    return action.payload;
  }
  if (action.type === 'CLEAR_JOB_WORK_FILES') {
    return [];
  }
  return state;
};

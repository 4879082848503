import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { reduxForm, Field, getFormValues } from 'redux-form';
import { Row, Button, Input, Select, Modal, Col, Popconfirm } from 'antd';
import { useHistory } from 'react-router';
import withStyles from 'react-jss';
import startCase from 'lodash/startCase';
import { useSelector } from 'react-redux';
import Structure from '../Structure/Structure';
import styles from '../CommonStyles';
import { successNotification, errorNotification } from '../Snackbar';
import Api from '../Api';
import LeftGrid from '../Components/LeftGrid';
import RightGrid from '../Components/RightGrid';
import GetField from '../Components/GetField';
import { transporterTypeList, transporterSpecialityAreaList } from '../constants';
import Heading from '../Components/Heading';
import { get } from '../utils';

const AntInput = GetField(Input);
const AntSelect = GetField(Select);

const NewTransporter = props => {
  const { handleSubmit, classes } = props;
  const history = useHistory();

  const [formValueModal, setFormValuesModal] = useState(false);
  const [confirmLoading, setConfirmLoading] = useState(false);
  const [preview, setPreview] = useState({});
  const FormValues = useSelector(state => getFormValues('NewTransporter')(state));

  const showFormValuesModal = () => {
    const _preview = {
      name: get(FormValues, ['name'], '--'),
      address: get(FormValues, ['address'], '--'),
      type: get(FormValues, ['type']) ? startCase(get(FormValues, ['type'])) : '--',
      specialityArea: get(FormValues, ['specialityArea']) ? startCase(get(FormValues, ['specialityArea'])) : '--',
      gstNo: get(FormValues, ['gstNo'], '--'),
      contactPerson: get(FormValues, ['contactPerson'], '--'),
      primaryContact: get(FormValues, ['primaryContact'], '--'),
      contacts: get(FormValues, ['contacts'], '--'),
      primaryEmail: get(FormValues, ['primaryEmail'], '--'),
      emails: get(FormValues, ['emails'], '--'),
      remarks: get(FormValues, ['remarks'], '--'),
    };

    setPreview(_preview);
    setFormValuesModal(true);
  };

  const hideFormValuesModal = () => {
    setFormValuesModal(false);
    setPreview({});
  };

  const onSubmit = async () => {
    try {
      setConfirmLoading(true);
      await Api.post('/transports', FormValues);
      successNotification('Transporter added');
      setConfirmLoading(false);
      hideFormValuesModal();
      history.push('/transporter/list');
    } catch (err) {
      setConfirmLoading(false);
      errorNotification(err);
    }
  };

  return (
    <Structure>
      <>
        <Heading text="New Transporter" />
        <form onSubmit={handleSubmit(onSubmit)}>
          <Row>
            <LeftGrid>
              <Field
                label="Transport Name"
                required
                allowClear
                name="name"
                placeholder="Transport Name"
                component={AntInput}
              />
            </LeftGrid>
            <RightGrid>
              <Field label="Address" allowClear name="address" placeholder="Address" component={AntInput} />
            </RightGrid>
          </Row>
          <Row>
            <LeftGrid>
              <Field label="Type" allowClear required name="type" component={AntSelect}>
                <Select.Option key={null} value="" disabled>
                  Select type
                </Select.Option>
                {transporterTypeList.map(type => (
                  <Select.Option key={type} value={type}>
                    {startCase(type)}
                  </Select.Option>
                ))}
              </Field>
            </LeftGrid>
            <RightGrid>
              <Field label="Speciality Area" allowClear required name="specialityArea" component={AntSelect}>
                <Select.Option key={null} value="" disabled>
                  Select speciality area
                </Select.Option>
                {transporterSpecialityAreaList.map(area => (
                  <Select.Option key={area} value={area}>
                    {startCase(area)}
                  </Select.Option>
                ))}
              </Field>
            </RightGrid>
          </Row>
          <Row>
            <LeftGrid>
              <Field label="GST Number" allowClear name="gstNo" placeholder="GST Number" component={AntInput} />
            </LeftGrid>
            <RightGrid>
              <Field
                label="Contact Person"
                required
                allowClear
                name="contactPerson"
                placeholder="Contact Person"
                component={AntInput}
              />
            </RightGrid>
          </Row>
          <Row>
            <LeftGrid>
              <Field
                label="Primary Contact"
                required
                allowClear
                name="primaryContact"
                placeholder="Primary Contact Number"
                component={AntInput}
              />
            </LeftGrid>
            <RightGrid>
              <Field
                label="Contacts"
                allowClear
                name="contacts"
                placeholder="Contact Numbers (seperated by comma)"
                component={AntInput}
              />
            </RightGrid>
          </Row>
          <Row>
            <LeftGrid>
              <Field
                label="Primary Email"
                allowClear
                name="primaryEmail"
                placeholder="Primary Email"
                component={AntInput}
              />
            </LeftGrid>
            <RightGrid>
              <Field
                label="Emails"
                allowClear
                name="emails"
                placeholder="Emails (seperated by comma)"
                component={AntInput}
              />
            </RightGrid>
          </Row>
          <Row>
            <LeftGrid>
              <Field label="Remarks" allowClear name="remarks" placeholder="Remarks" component={AntInput} />
            </LeftGrid>
          </Row>
          <Row className={classes.buttonContainer}>
            <Button className={classes.button} type="primary" onClick={showFormValuesModal}>
              Preview
            </Button>
          </Row>
        </form>
        <Modal
          onCancel={hideFormValuesModal}
          open={formValueModal}
          confirmLoading={confirmLoading}
          centered
          width="800px"
          maskClosable={false}
          closable={false}
          cancelText="Back"
          okButtonProps={{ className: classes.button }}
          okText={
            <Popconfirm
              title="Sure want to submit form ?"
              onConfirm={() => onSubmit()}
              okText="Confirm"
              cancelText="Cancel"
            >
              Submit
            </Popconfirm>
          }
        >
          <Row>
            <Col xs={24} lg={12}>
              <p>
                Transport Name:
                <span className={classes.detailColor}>{preview.name}</span>
              </p>
            </Col>
            <Col xs={24} lg={12}>
              <p>
                Address:
                <span className={classes.detailColor}>{preview.address}</span>
              </p>
            </Col>
          </Row>
          <Row>
            <Col xs={24} lg={12}>
              <p>
                Type:
                <span className={classes.detailColor}>{preview.type}</span>
              </p>
            </Col>
            <Col xs={24} lg={12}>
              <p>
                Speciality Area:
                <span className={classes.detailColor}>{preview.specialityArea}</span>
              </p>
            </Col>
          </Row>
          <Row>
            <Col xs={24} lg={12}>
              <p>
                GST Number:
                <span className={classes.detailColor}>{preview.gstNo}</span>
              </p>
            </Col>
            <Col xs={24} lg={12}>
              <p>
                Contact Person:
                <span className={classes.detailColor}>{preview.contactPerson}</span>
              </p>
            </Col>
          </Row>
          <Row>
            <Col xs={24} lg={12}>
              <p>
                Primary Contact:
                <span className={classes.detailColor}>{preview.primaryContact}</span>
              </p>
            </Col>
            <Col xs={24} lg={12}>
              <p>
                Contacts:
                <span className={classes.detailColor}>{preview.contacts}</span>
              </p>
            </Col>
          </Row>
          <Row>
            <Col xs={24} lg={12}>
              <p>
                Primary Email:
                <span className={classes.detailColor}>{preview.primaryEmail}</span>
              </p>
            </Col>
            <Col xs={24} lg={12}>
              <p>
                Emails:
                <span className={classes.detailColor}>{preview.emails}</span>
              </p>
            </Col>
          </Row>
          <Row>
            <Col xs={24} lg={12}>
              <p>
                Remarks:
                <span className={classes.detailColor}>{preview.remarks}</span>
              </p>
            </Col>
          </Row>
        </Modal>
      </>
    </Structure>
  );
};

NewTransporter.propTypes = {
  handleSubmit: PropTypes.func.isRequired,
  classes: PropTypes.object.isRequired,
};

const StyledNewTransporter = withStyles(styles)(NewTransporter);

const FormedNewTransporter = reduxForm({
  form: 'NewTransporter',
})(StyledNewTransporter);

export default FormedNewTransporter;

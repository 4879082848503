export default {
  container: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    minHeight: '100vh',
  },
  loginBox: {
    width: '350px',
    height: 'auto',
    backgroundColor: 'white',
    boxSizing: 'border-box',
    boxShadow:
      'rgba(195, 195, 195, 0.25) 0px 4px 4px, rgba(195, 195, 195, 0.25) 0px -4px 4px, rgba(195, 195, 195, 0.50) 0px 16px 32px, rgba(195, 195, 195, 0.50) 0px -16px 32px',
    color: 'rgb(17, 17, 17)',
    maxWidth: '80vw',
    borderWidth: '1px',
    borderImage: 'initial',
    borderStyle: 'solid',
    borderColor: 'rgb(255, 255, 255)',
    borderRadius: '8px',
    overflow: 'hidden',
    margin: 'auto',
    padding: '32px',
  },
  button: {
    width: '100%',
  },
  heading: {
    boxSizing: 'border-box',
    marginBottom: '15px',
    fontSize: '18px',
    fontWeight: '700',
    lineHeight: 'normal',
    display: 'block',
    textAlign: 'center',
  },
  subHeading: {
    boxSizing: 'border-box',
    paddingBottom: '30px',
    fontSize: '14px',
    lineHeight: 'normal',
    display: 'block',
    textAlign: 'center',
  },
  linksText: {
    display: 'block',
    marginTop: '20px',
    fontSize: '14px',
    textAlign: 'center',
  },
  modalText: {
    marginBottom: '20px',
    textAlign: 'center',
  },
};

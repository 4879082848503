// kra reducer

export const kraList = (state = [], action) => {
  if (action.type === 'FETCH_KRAS') {
    return action.payload;
  }
  if (action.type === 'CLEAR_KRAS') {
    return [];
  }
  return state;
};

export const kra = (state = {}, action) => {
  if (action.type === 'FETCH_KRA') {
    return action.payload;
  }
  if (action.type === 'CLEAR_KRA') {
    return {};
  }
  return state;
};

// task reducer

export const tasksList = (state = [], action) => {
  if (action.type === 'FETCH_TASKS') {
    return action.payload;
  }
  if (action.type === 'CLEAR_TASKS') {
    return [];
  }
  return state;
};

export const taskExtensionRequests = (state = { taskExtensions: [], subTaskExtensions: [] }, action) => {
  if (action.type === 'FETCH_TASK_EXTENSIONS') {
    return action.payload;
  }
  if (action.type === 'CLEAR_TASK_EXTENSIONS') {
    return { taskExtensions: [], subTaskExtensions: [] };
  }
  return state;
};

export const taskData = (state = {}, action) => {
  if (action.type === 'FETCH_TASK') {
    return action.payload;
  }
  if (action.type === 'CLEAR_TASK') {
    return {};
  }
  return state;
};

export const subTask = (state = {}, action) => {
  if (action.type === 'FETCH_SUBTASK') {
    return action.payload;
  }
  if (action.type === 'CLEAR_SUBTASK') {
    return {};
  }
  return state;
};

// notes reducer

export const weeklySchedulers = (state = [], action) => {
  if (action.type === 'FETCH_WEEKLY_SCHEDULERS') {
    return action.payload;
  }
  if (action.type === 'CLEAR_WEEKLY_SCHEDULERS') {
    return [];
  }
  return state;
};

export const weeklySchedulerCounts = (state = { notScheduled: 0, scheduled: 0, completed: 0 }, action) => {
  if (action.type === 'FETCH_WEEKLY_SCHEDULER_COUNTS') {
    return action.payload || {};
  }

  if (action.type === 'CLEAR_WEEKLY_SCHEDULER_COUNTS') {
    return { notScheduled: 0, scheduled: 0, completed: 0 };
  }

  return state;
};

export const weeklyScheduler = (state = {}, action) => {
  if (action.type === 'FETCH_WEEKLY_SCHEDULER') {
    return action.payload;
  }
  if (action.type === 'CLEAR_WEEKLY_SCHEDULER') {
    return {};
  }
  return state;
};

import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { Button, Col, Popconfirm, Row, Table, Tooltip } from 'antd';
import { useDispatch, useSelector } from 'react-redux';
import moment from 'moment';
import withStyles from 'react-jss';
import { Link } from 'react-router-dom';
import { DeleteOutlined, EditOutlined, EyeOutlined, FileDoneOutlined, FileTextOutlined } from '@ant-design/icons';
import Modal from 'antd/lib/modal/Modal';
import Structure from '../Structure/Structure';
import Heading from '../Components/Heading';
import { onFilter, sorter, filterDropdown, dateSorter, onDateFilter, dateFilterDropdown, get } from '../utils';
import { clearTicketList, getTicketList } from '../Actions';
import styles from '../CommonStyles';
import Api from '../Api';
import { errorNotification, successNotification } from '../Snackbar';
import StylesModal from './Modals';
import TicketTimeline from './TicketTimeline';

const TicketList = props => {
  const { classes } = props;
  const dispatch = useDispatch();

  const [isModalVisible, setIsModalVisible] = useState(false);
  const [requestType, setRequestType] = useState(null);
  const [ticketId, setTicketId] = useState(null);
  const [recordData, setRecordData] = useState({});
  const [isRequestedExtensionModalVisible, setIsRequestedExtensionModalVisible] = useState(false);
  const [isTimelineModalVisible, setIsTimelineModalVisible] = useState(false);
  const [ticketData, setTicketData] = useState({});

  const ticketList = useSelector(state => state.ticketList);

  useEffect(() => {
    dispatch(getTicketList());
    return () => {
      dispatch(clearTicketList());
    };
  }, []);

  const showTimelineModal = record => {
    setIsTimelineModalVisible(true);
    setTicketData(record);
  };
  const hideTimelineModal = () => {
    setIsTimelineModalVisible(false);
    setTicketData({});
  };

  const showRequestedExtensionModal = record => {
    setIsRequestedExtensionModalVisible(true);
    setRecordData(record);
  };

  const hideRequestedExtensionModal = () => {
    setIsRequestedExtensionModalVisible(null);
    setRecordData({});
  };

  const showModal = (record, type) => {
    setIsModalVisible(true);
    setTicketId(record.id);
    setRequestType(type);
    setTicketData(record);
    hideRequestedExtensionModal();
  };

  const hideModal = () => {
    setIsModalVisible(null);
    setTicketId(null);
    setRequestType(null);
    setTicketData({});
    dispatch(getTicketList());
  };

  const onDelete = async id => {
    try {
      await Api.delete(`/tickets/${id}`);
      successNotification('Ticket deleted');
      dispatch(getTicketList());
    } catch (err) {
      errorNotification(err);
    }
  };

  const onCompleteByCreator = async id => {
    try {
      await Api.put(`/tickets/${id}/completefromcreator`);
      successNotification('Ticket closed');
      dispatch(getTicketList());
    } catch (err) {
      errorNotification(err);
    }
  };

  const assignedTableColumns = [
    {
      title: 'Ticket No',
      dataIndex: 'id',
      sorter: sorter('id'),
      onFilter: onFilter('id'),
      filterDropdown,
      width: '6%',
    },
    {
      title: 'Title',
      dataIndex: 'title',
      sorter: sorter('title'),
      onFilter: onFilter('title'),
      filterDropdown,
      render: (text, record) => (
        <Link to="/" onClick={() => showTimelineModal(record)}>
          {text}
        </Link>
      ),
    },
    {
      title: 'Description',
      dataIndex: 'description',
      sorter: sorter('description'),
      onFilter: onFilter('description'),
      filterDropdown,
    },
    {
      title: 'Assigne By',
      dataIndex: 'creator',
      sorter: sorter('creator'),
      onFilter: onFilter('creator'),
      filterDropdown,
    },
    {
      title: 'Assign Date',
      dataIndex: 'createdAt',
      sorter: dateSorter('createdAt'),
      filterDropdown: dateFilterDropdown,
      onFilter: onDateFilter('createdAt'),
      render: createdAt => (createdAt ? moment(createdAt).format('DD MMM YYYY h:mm A') : null),
    },
    {
      title: 'Due Date',
      dataIndex: 'dueDate',
      sorter: dateSorter('dueDate'),
      filterDropdown: dateFilterDropdown,
      onFilter: onDateFilter('dueDate'),
      render: dueDate => (dueDate ? moment(dueDate).format('DD MMM YYYY h:mm A') : null),
    },
    {
      title: 'Remark',
      dataIndex: 'remark',
      sorter: sorter('remark'),
      onFilter: onFilter('remark'),
      filterDropdown,
    },
  ];

  const completeByUserColumn = record => (
    <>
      {!record.completedByUserAt ? (
        <Tooltip placement="bottom" title="Complete">
          <FileDoneOutlined className={classes.tableIcon} onClick={() => showModal(record, 'complete')} />
        </Tooltip>
      ) : (
        'Completed'
      )}
    </>
  );

  const requestExtensionColumn = record => {
    if (!record.completedByUserAt) {
      if (record.lastRequestRevision === null) {
        return (
          <Tooltip placement="bottom" title="Request Extension">
            <FileTextOutlined className={classes.tableIcon} onClick={() => showModal(record, 'requestExtension')} />
          </Tooltip>
        );
      }
      if (record.lastRequestRevision !== null && record.lastRequestRevision && record.lastRequestRevision.respondedAt) {
        return (
          <Tooltip placement="bottom" title="Request Extension">
            <FileTextOutlined className={classes.tableIcon} onClick={() => showModal(record, 'requestExtension')} />
          </Tooltip>
        );
      }
      if (
        record.lastRequestRevision !== null &&
        record.lastRequestRevision &&
        !record.lastRequestRevision.respondedAt
      ) {
        return 'Requested';
      }
    }
    return null;
  };

  assignedTableColumns.push(
    {
      title: 'Complete',
      width: 90,
      render: completeByUserColumn,
    },
    {
      title: 'Request Extension',
      width: 90,
      render: requestExtensionColumn,
    }
  );

  const raisedTableColumns = [
    {
      title: 'Ticket No',
      dataIndex: 'id',
      sorter: sorter('id'),
      onFilter: onFilter('id'),
      filterDropdown,
      width: '6%',
    },
    {
      title: 'Title',
      dataIndex: 'title',
      sorter: sorter('title'),
      onFilter: onFilter('title'),
      filterDropdown,
      render: (text, record) => (
        <Link to="/" onClick={() => showTimelineModal(record)}>
          {text}
        </Link>
      ),
    },
    {
      title: 'Description',
      dataIndex: 'description',
      sorter: sorter('description'),
      onFilter: onFilter('description'),
      filterDropdown,
    },
    {
      title: 'Assigne To',
      dataIndex: 'doer',
      sorter: sorter('doer'),
      onFilter: onFilter('doer'),
      filterDropdown,
    },
    {
      title: 'Assign Date',
      dataIndex: 'createdAt',
      sorter: dateSorter('createdAt'),
      filterDropdown: dateFilterDropdown,
      onFilter: onDateFilter('createdAt'),
      render: createdAt => (createdAt ? moment(createdAt).format('DD MMM YYYY h:mm A') : null),
    },
    {
      title: 'Due Date',
      dataIndex: 'dueDate',
      sorter: dateSorter('dueDate'),
      filterDropdown: dateFilterDropdown,
      onFilter: onDateFilter('dueDate'),
      render: dueDate => (dueDate ? moment(dueDate).format('DD MMM YYYY h:mm A') : null),
    },
    {
      title: 'Remark',
      dataIndex: 'remark',
      sorter: sorter('remark'),
      onFilter: onFilter('remark'),
      filterDropdown,
    },
  ];

  const editDeleteColumn = record => (
    <>
      <Tooltip placement="bottom" title="Edit">
        <Link to={`/ticket/edit/${record.id}`}>
          <EditOutlined className={classes.tableIcon} />
        </Link>
      </Tooltip>
      &nbsp;&nbsp;&nbsp;&nbsp;
      <Tooltip placement="bottom" title="Delete">
        <Popconfirm
          title="Are you sure you want to delete?"
          onConfirm={() => onDelete(record.id)}
          okText="Yes"
          cancelText="No"
        >
          <DeleteOutlined className={classes.tableIcon} />
        </Popconfirm>
      </Tooltip>
    </>
  );

  const requestedExtensionColumn = record => {
    if (!record.completedByUserAt) {
      if (record.lastRequestRevision !== null) {
        if (record.lastRequestRevision && !record.lastRequestRevision.respondedAt) {
          return (
            <Tooltip placement="bottom" title="View Requested Extension">
              <EyeOutlined className={classes.tableIcon} onClick={() => showRequestedExtensionModal(record)} />
            </Tooltip>
          );
        }
        if (
          record.lastRequestRevision !== null &&
          record.lastRequestRevision &&
          record.lastRequestRevision.respondedAt
        ) {
          if (
            record.lastRequestRevision &&
            record.lastRequestRevision.isRejected &&
            record.lastRequestRevision.isRejected === true
          ) {
            return 'Rejected';
          }
          return 'Approved';
        }
      }
      return null;
    }
    return null;
  };

  const completeByUserStatusColumn = record => {
    if (record.completedByUserAt && !record.completedByCreatorAt) {
      return (
        <>
          Completed at {moment(record.completedByUserAt).format('DD MMM YYYY h:mm A')} /&nbsp;
          <Tooltip placement="bottom" title="Revision">
            <FileTextOutlined className={classes.tableIcon} onClick={() => showModal(record, 'createRevision')} />
          </Tooltip>
        </>
      );
    }
    return null;
  };

  const completeByCreatorColumn = record => (
    <>
      {!record.completedByCreatorAt ? (
        <Tooltip placement="bottom" title="Complete">
          <Popconfirm
            title="Are you sure want to close this ticket?"
            onConfirm={() => onCompleteByCreator(record.id)}
            okText="Yes"
            cancelText="No"
          >
            <FileDoneOutlined className={classes.tableIcon} />
          </Popconfirm>
        </Tooltip>
      ) : (
        'Closed'
      )}
    </>
  );

  raisedTableColumns.push(
    {
      title: 'Actions',
      width: 70,
      render: editDeleteColumn,
    },
    {
      title: 'Extension Request',
      width: 95,
      render: requestedExtensionColumn,
    },
    {
      title: 'Revision',
      width: 130,
      render: completeByUserStatusColumn,
    },
    {
      title: 'Close',
      width: 60,
      render: completeByCreatorColumn,
    }
  );

  return (
    <Structure>
      <>
        <Heading text="Tickets Assigned To You" />
        <Table
          columns={assignedTableColumns}
          bordered
          rowKey={record => record.id}
          dataSource={get(ticketList, ['toWorkTickets'], [])}
          size="small"
          pagination={{
            defaultPageSize: 20,
            position: ['bottomLeft'],
            showTotal: total => `Total ${total} Items`,
          }}
          scroll={{ x: 1050 }}
        />
        <br />
        <Heading text="Tickets Raised By You" />
        <Table
          columns={raisedTableColumns}
          bordered
          rowKey={record => record.id}
          dataSource={get(ticketList, ['createdTickets'], [])}
          size="small"
          pagination={{
            defaultPageSize: 20,
            position: ['bottomLeft'],
            showTotal: total => `Total ${total} Items`,
          }}
          scroll={{ x: 1050 }}
        />
        <StylesModal
          isModalVisible={isModalVisible}
          requestType={requestType}
          ticketId={ticketId}
          hideModal={hideModal}
          ticketData={ticketData}
        />
        <Modal
          open={isRequestedExtensionModalVisible}
          onCancel={hideRequestedExtensionModal}
          centered
          maskClosable={false}
          title="Extension Requested"
          width="600px"
          cancelText="Close"
          okButtonProps={{ className: classes.hide }}
        >
          <Row>
            <Col xs={24} lg={24}>
              <p>
                Old Due Date:
                <span className={classes.detailColor}>
                  {get(recordData, ['lastRequestRevision', 'oldDueDate'], null)
                    ? moment(get(recordData, ['lastRequestRevision', 'oldDueDate'])).format('DD MMM YYYY h:mm A')
                    : '--'}
                </span>
              </p>
            </Col>
            <Col xs={24} lg={24}>
              <p>
                Requested Due Date:
                <span className={classes.detailColor}>
                  {get(recordData, ['lastRequestRevision', 'requestedDueDate'], null)
                    ? moment(get(recordData, ['lastRequestRevision', 'requestedDueDate'])).format('DD MMM YYYY h:mm A')
                    : '--'}
                </span>
              </p>
            </Col>
            <Col xs={24} lg={24}>
              <p>
                Remark:
                <span className={classes.detailColor}>
                  {get(recordData, ['lastRequestRevision', 'requestedRemark'], '--')}
                </span>
              </p>
            </Col>
          </Row>
          <br />
          <br />
          <Row>
            <Button type="primary" onClick={() => showModal(recordData, 'extendExtension')}>
              Approve
            </Button>
            &nbsp;&nbsp;&nbsp;&nbsp;
            <Button type="primary" danger onClick={() => showModal(recordData, 'rejectExtension')}>
              Reject
            </Button>
          </Row>
        </Modal>
        <TicketTimeline
          isTimelineModalVisible={isTimelineModalVisible}
          ticketData={ticketData}
          hideTimelineModal={hideTimelineModal}
        />
      </>
    </Structure>
  );
};

TicketList.propTypes = {
  classes: PropTypes.object.isRequired,
};

const StylesTicketList = withStyles(styles)(TicketList);

export default StylesTicketList;

import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { Table, Popconfirm, Tooltip, Row, Select } from 'antd';
import { CloudDownloadOutlined } from '@ant-design/icons';
import { useSelector, useDispatch } from 'react-redux';
import withStyles from 'react-jss';
import Modal from 'antd/lib/modal/Modal';
import moment from 'moment';
import { errorNotification, successNotification } from '../Snackbar';
import styles from '../CommonStyles';
import Heading from '../Components/Heading';
import Structure from '../Structure/Structure';
import { sorter, onFilter, filterDropdown, get, dateSorter } from '../utils';
import Api from '../Api';
import {
  clearEnquiryFilesList,
  clearEnquiryList,
  getEnquiryFilesList,
  getEnquiryList,
  clearQuotation,
  // setQuotation,
} from '../Actions';
import constants from '../constants';
import ProformaInvoice from './ProformaInvoice';

const { Option } = Select;

const EnquiryList = props => {
  const { classes } = props;
  const dispatch = useDispatch();

  const auth = useSelector(state => state.auth);
  const permissions = get(auth, ['permissions'], []);
  const enquiryList = useSelector(state => state.enquiryList);

  const [modalType, setModalType] = useState(null);
  const [enquiryId, setEnquiryId] = useState(null);
  const [confirmLoading, setConfirmLoading] = useState(false);
  const [statusValue, setStatusValue] = useState(null);
  const filesList = useSelector(state => state.filesList);

  useEffect(() => {
    dispatch(getEnquiryList());
    return () => {
      dispatch(clearEnquiryList());
      dispatch(clearEnquiryFilesList());
      dispatch(clearQuotation());
    };
  }, []);

  useEffect(() => {
    if (enquiryId && modalType === 'ViewFiles') {
      dispatch(getEnquiryFilesList(enquiryId));
    }
  }, [enquiryId, modalType]);

  const hideFileModal = () => {
    setModalType(null);
    dispatch(clearEnquiryFilesList());
  };

  const showModal = (record, type) => {
    setEnquiryId(record.id);
    setModalType(type);
  };

  const hideModal = () => {
    setEnquiryId(null);
    setModalType(null);
    dispatch(getEnquiryList());
    dispatch(clearEnquiryFilesList());
    dispatch(clearQuotation());
  };

  // const showEditPIModal = (record, type) => {
  //   hideFileModal();
  //   setEnquiryId(record.inquiryId);
  //   setModalType(type);
  //   dispatch(setQuotation(record.data));
  // };

  // const onDelete = async id => {
  //   try {
  //     await Api.delete(`/inquiries/${id}`);
  //     successNotification('Enquiry deleted');
  //     dispatch(getEnquiryList());
  //   } catch (err) {
  //     errorNotification(err);
  //   }
  // };

  const tooltipShow = arg => (
    <Tooltip placement="topLeft" title={arg}>
      {arg}
    </Tooltip>
  );

  const submitStatusChange = async () => {
    try {
      setConfirmLoading(true);
      await Api.put(`/inquiries/${enquiryId}/status`, { status: statusValue });
      setConfirmLoading(false);
      successNotification('Enquiry status updated');
      hideModal();
    } catch (err) {
      setConfirmLoading(false);
      errorNotification(err);
      hideModal();
    }
  };

  const columns = [
    {
      title: 'Created On',
      dataIndex: 'createdAt',
      sorter: dateSorter('createdAt'),
      render: createdAt => (createdAt ? moment(createdAt).format('DD MMM YYYY') : null),
      width: 110,
      fixed: 'left',
    },
    {
      title: 'Name',
      dataIndex: 'name',
      sorter: sorter('name'),
      filterDropdown,
      onFilter: onFilter('name'),
      ellipsis: { showTitle: false },
      render: tooltipShow,
      width: 220,
      fixed: 'left',
    },
    {
      title: 'GST Number',
      dataIndex: 'gstNo',
      sorter: sorter('gstNo'),
      filterDropdown,
      onFilter: onFilter('gstNo'),
      ellipsis: { showTitle: false },
      render: tooltipShow,
      width: 170,
    },
    {
      title: 'State',
      dataIndex: 'state',
      sorter: sorter('state'),
      filterDropdown,
      onFilter: onFilter('state'),
      ellipsis: { showTitle: false },
      render: tooltipShow,
      width: 120,
    },
    {
      title: 'Billing Address',
      dataIndex: 'billingAddress',
      sorter: sorter('billingAddress'),
      filterDropdown,
      onFilter: onFilter('billingAddress'),
      ellipsis: { showTitle: false },
      render: tooltipShow,
      width: 150,
    },
    {
      title: 'Shipping Address',
      dataIndex: 'shippingAddress',
      sorter: sorter('shippingAddress'),
      filterDropdown,
      onFilter: onFilter('shippingAddress'),
      ellipsis: { showTitle: false },
      render: tooltipShow,
      width: 165,
    },
    {
      title: 'Contact Person',
      dataIndex: 'contactPerson',
      sorter: sorter('contactPerson'),
      filterDropdown,
      onFilter: onFilter('contactPerson'),
      render: tooltipShow,
      width: 120,
    },
    {
      title: 'Primary Contact',
      dataIndex: 'primaryContact',
      sorter: sorter('primaryContact'),
      filterDropdown,
      onFilter: onFilter('primaryContact'),
      render: tooltipShow,
      width: 120,
    },
    {
      title: 'Contacts',
      dataIndex: 'contacts',
      sorter: sorter('contacts'),
      filterDropdown,
      onFilter: onFilter('contacts'),
      ellipsis: { showTitle: false },
      render: tooltipShow,
      width: 120,
    },
    {
      title: 'Primary Email',
      dataIndex: 'primaryEmail',
      sorter: sorter('primaryEmail'),
      filterDropdown,
      onFilter: onFilter('primaryEmail'),
      ellipsis: { showTitle: false },
      render: tooltipShow,
      width: 150,
    },
    {
      title: 'Emails',
      dataIndex: 'emails',
      sorter: sorter('emails'),
      filterDropdown,
      onFilter: onFilter('emails'),
      ellipsis: { showTitle: false },
      render: tooltipShow,
      width: 120,
    },
    {
      title: 'Payment Term',
      dataIndex: 'paymentTerms',
      sorter: sorter('paymentTerms'),
      filterDropdown,
      onFilter: onFilter('paymentTerms'),
      width: 120,
      align: 'right',
    },
    {
      title: 'Status',
      dataIndex: 'status',
      sorter: sorter('status'),
      onFilter: onFilter('status'),
      filterDropdown,
      width: 120,
    },
    {
      title: 'Remarks',
      dataIndex: 'remarks',
      sorter: sorter('remarks'),
      filterDropdown,
      onFilter: onFilter('remarks'),
      ellipsis: { showTitle: false },
      render: tooltipShow,
      width: 150,
    },
  ];

  // const renderActionIcons = record => (
  //   <div>
  //     {permissions.includes('edit_enquiry') ? (
  //       <>
  //         <Tooltip placement="bottom" title="Edit">
  //           <Link to={`/enquiry/edit/${record.id}`}>
  //             <EditOutlined className={classes.tableIcon} />
  //           </Link>
  //         </Tooltip>
  //         &nbsp;&nbsp;&nbsp;&nbsp;
  //       </>
  //     ) : null}
  //     {permissions.includes('delete_enquiry') ? (
  //       <>
  //         <Tooltip placement="bottom" title="Delete">
  //           <Popconfirm
  //             title="Are you sure you want to delete?"
  //             onConfirm={() => onDelete(record.id)}
  //             okText="Yes"
  //             cancelText="No"
  //           >
  //             <DeleteOutlined className={classes.tableIcon} />
  //           </Popconfirm>
  //         </Tooltip>
  //       </>
  //     ) : null}
  //   </div>
  // );

  // const statusColumn = record =>
  //   permissions.includes('edit_enquiry') ? (
  //     <Tooltip placement="bottom" title="Status">
  //       <FileTextOutlined className={classes.tableIcon} onClick={() => showModal(record, 'ChangeStatus')} />
  //     </Tooltip>
  //   ) : null;

  // if (permissions.includes('edit_enquiry') || permissions.includes('delete_enquiry')) {
  //   columns.push({
  //     title: 'Actions',
  //     width: 70,
  //     render: renderActionIcons,
  //   });
  //   columns.push({
  //     title: 'Status',
  //     width: 60,
  //     render: statusColumn,
  //     fixed: 'right',
  //   });
  // }

  // const createQuotation = record =>
  //   permissions.includes('create_proforma_invoice') ? (
  //     <Tooltip placement="bottom" title="Create PI">
  //       <FileTextOutlined className={classes.tableIcon} onClick={() => showModal(record, 'CreatePI')} />
  //     </Tooltip>
  //   ) : null;

  const viewQuotations = record => {
    if (permissions.includes('create_proforma_invoice')) {
      let color;
      if (record.files) {
        if (record.files > 0) {
          color = 'Green';
        } else {
          color = 'Red';
        }
      }
      return (
        <Tooltip placement="bottom" title="View PI">
          <CloudDownloadOutlined
            style={{ color }}
            className={classes.tableIcon}
            onClick={() => showModal(record, 'ViewFiles')}
          />
        </Tooltip>
      );
    }

    return null;
  };

  if (permissions.includes('create_proforma_invoice')) {
    // columns.push({
    //   title: 'Create PI',
    //   width: 70,
    //   render: createQuotation,
    //   fixed: 'right',
    // });
    columns.push({
      title: 'View / Edit PI',
      width: 70,
      render: viewQuotations,
      fixed: 'right',
    });
  }

  const filesTableColumns = [];

  const fileTableColumn = record => (
    <a href={record.url} rel="noreferrer noopener" target="_blank">
      <Tooltip placement="bottom" title={`Click to open/download "${record.name}" file.`}>
        {record.name}
      </Tooltip>
    </a>
  );

  // const editPIColumn = record => (
  //   <Tooltip placement="bottom" title="Edit PI">
  //     <EditOutlined className={classes.tableIcon} onClick={() => showEditPIModal(record, 'EditPI')} />
  //   </Tooltip>
  // );

  filesTableColumns.push({
    title: 'PI No',
    dataIndex: 'id',
    width: '10%',
  });
  filesTableColumns.push({
    title: 'View File',
    render: fileTableColumn,
  });
  // filesTableColumns.push({
  //   title: 'Edit PI',
  //   render: editPIColumn,
  //   width: '15%',
  // });

  return (
    <Structure>
      <>
        <Heading text="Enquiry List" />
        <Table
          columns={columns}
          bordered
          rowKey={record => record.id}
          pagination={{ defaultPageSize: 20, position: ['bottomLeft'], showTotal: total => `Total ${total} Items` }}
          dataSource={enquiryList}
          size="small"
          scroll={{ x: 1400, y: window.innerHeight - 300 }}
        />
        <Modal
          open={modalType === 'ChangeStatus'}
          onCancel={hideModal}
          confirmLoading={confirmLoading}
          title="Change Status."
          centered
          maskClosable={false}
          closable={false}
          width="300px"
          cancelText="Back"
          okText={
            <Popconfirm
              title="Are you sure?"
              onConfirm={() => submitStatusChange()}
              okText="Confirm"
              cancelText="Cancel"
            >
              Submit
            </Popconfirm>
          }
          okButtonProps={{ className: classes.button }}
        >
          <Row>
            <Select style={{ width: 280 }} placeholder="Select status" onChange={value => setStatusValue(value)}>
              {Object.values(constants.inquiry.status).map(opt => (
                <Option key={opt} value={opt}>
                  {opt}
                </Option>
              ))}
            </Select>
          </Row>
        </Modal>
        <Modal
          onOk={hideFileModal}
          open={modalType === 'ViewFiles'}
          centered
          maskClosable={false}
          title="Proforma Invoices List."
          width="600px"
          okText="Close"
          cancelButtonProps={{ className: classes.hide }}
          closable={false}
          okButtonProps={{ className: classes.button }}
        >
          <Table
            columns={filesTableColumns}
            bordered
            rowKey={record => record.id}
            dataSource={filesList}
            size="small"
            pagination={{ position: ['bottomLeft'] }}
          />
        </Modal>
        <ProformaInvoice hideModal={hideModal} enquiryId={enquiryId} modalType={modalType} />
      </>
    </Structure>
  );
};

EnquiryList.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(EnquiryList);

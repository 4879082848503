export const setQuotation = data => async dispatch => {
  try {
    dispatch({
      type: 'FETCH_QUOTATION',
      payload: data,
    });
  } catch (err) {
    throw err;
  }
};

export const clearQuotation = () => {
  return {
    type: 'CLEAR_QUOTATION',
  };
};

import React, { useCallback, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Button, Col, Input, Modal, Row, Popconfirm, Tooltip, List, Tabs } from 'antd';
import { useForm, Controller } from 'react-hook-form';
import { DeleteOutlined, FormOutlined, FileTextOutlined, PlusOutlined, FileDoneOutlined } from '@ant-design/icons';

import moment from 'moment';
import Structure from '../Structure/Structure';
import Heading from '../Components/Heading';
import { get } from '../utils';
import Api from '../Api';
import { errorNotification, infoNotification, successNotification } from '../Snackbar';
import GetRHFField from '../Components/GetRHFField';
import {
  clearWeeklyScheduler,
  clearWeeklySchedulerCounts,
  clearWeeklySchedulers,
  getWeeklyScheduler,
  getWeeklySchedulerCounts,
  getWeeklySchedulers,
} from '../Actions';
import TaskModal from './Tasks/TaskModal';

const AntInput = GetRHFField(Input);

const { TabPane } = Tabs;

const WeeklySchedule = () => {
  const dispatch = useDispatch();

  const { handleSubmit, control, reset, getValues } = useForm({
    shouldUnregister: false,
  });

  const auth = useSelector(state => state.auth);
  const permissions = get(auth, ['permissions'], []);

  const weeklySchedulers = useSelector(state => state.weeklySchedulers);
  const counts = useSelector(state => state.weeklySchedulerCounts);

  const weeklyScheduler = useSelector(state => state.weeklyScheduler);

  const [activeKey, setActiveKey] = useState('toSchedule');

  const [modalView, setModalView] = useState(false);
  const [confirmLoading, setConfirmLoading] = useState(false);
  const [recordId, setRecordId] = useState(null);

  const [modalType, setModaltype] = useState(null);
  const [todoData, setTodoData] = useState(null);

  useEffect(() => {
    dispatch(getWeeklySchedulers());
    dispatch(getWeeklySchedulerCounts());

    return () => {
      dispatch(clearWeeklySchedulers());
      dispatch(clearWeeklySchedulerCounts());
    };
  }, []);

  useEffect(() => {
    reset(weeklyScheduler);
  }, [weeklyScheduler]);

  const fetchSchedulers = key => {
    const conditions = {};

    if (key === 'toSchedule') {
      conditions.isScheduled = false;
    } else if (key === 'scheduled') {
      conditions.isScheduled = true;
    } else {
      conditions.isCompleted = true;
    }

    setActiveKey(key);
    dispatch(clearWeeklySchedulers());
    dispatch(getWeeklySchedulers(conditions));
    dispatch(getWeeklySchedulerCounts());
  };

  const showTaskModal = (type, data) => {
    setModaltype(type);
    setTodoData(data);
  };

  const showModal = () => {
    setRecordId(null);
    setModalView(true);
  };

  const showEditModal = useCallback(id => {
    dispatch(getWeeklyScheduler(id));
    setRecordId(id);
    setModalView(true);
  }, []);

  const hideModal = () => {
    reset();
    setRecordId(null);
    setModalView(false);
    setConfirmLoading(false);
    dispatch(clearWeeklyScheduler());

    const conditions = {};

    if (activeKey === 'toSchedule') {
      conditions.isScheduled = false;
    } else if (activeKey === 'scheduled') {
      conditions.isScheduled = true;
    } else {
      conditions.isCompleted = true;
    }

    dispatch(getWeeklySchedulers(conditions));
  };

  const onDelete = async id => {
    try {
      await Api.delete(`/tasks/weekly-scheduler/${id}`);
      successNotification('Todo deleted');
      dispatch(getWeeklySchedulers({ isScheduled: false }));
    } catch (err) {
      errorNotification(err);
    }
  };

  const onMarkComplete = async id => {
    try {
      await Api.put(`/tasks/weekly-scheduler/${id}/complete`);
      successNotification('Todo marked completed');
      dispatch(getWeeklySchedulers({ isCompleted: true }));
    } catch (err) {
      errorNotification(err);
    }
  };

  const onSubmit = useCallback(async () => {
    try {
      const formValues = getValues();
      setConfirmLoading(true);
      await Api.post('/tasks/weekly-scheduler', formValues);
      hideModal();
      infoNotification('Todo added successfully');
      setActiveKey('toSchedule');
      dispatch(getWeeklySchedulers({ isScheduled: false }));
    } catch (err) {
      setConfirmLoading(false);
      errorNotification(err);
    }
  }, []);

  const onEdit = useCallback(async () => {
    try {
      const formValues = getValues();
      setConfirmLoading(true);
      await Api.put(`/tasks/weekly-scheduler/${recordId}`, formValues);
      hideModal();
      infoNotification('Todo updated successfully');
      setActiveKey('toSchedule');
      dispatch(getWeeklySchedulers({ isScheduled: false }));
    } catch (err) {
      setConfirmLoading(false);
      errorNotification(err);
    }
  }, [recordId]);

  return (
    <Structure>
      <>
        <Heading text="Todo">
          {permissions.includes('todo') ? (
            <Button type="primary" onClick={showModal}>
              <PlusOutlined /> Todo
            </Button>
          ) : null}
        </Heading>
        <Tabs activeKey={activeKey} onChange={fetchSchedulers}>
          <TabPane tab={`To be scheduled (${counts.notScheduled})`} key="toSchedule">
            <List
              itemLayout="horizontal"
              size="small"
              pagination={false}
              footer={false}
              dataSource={weeklySchedulers}
              renderItem={item => (
                <List.Item
                  key={item.title}
                  actions={[
                    !item.isScheduled ? (
                      <Tooltip placement="bottom" title="Schedule">
                        <FileTextOutlined onClick={() => showTaskModal('task', item)} />
                      </Tooltip>
                    ) : null,
                    !item.isScheduled ? (
                      <Tooltip placement="bottom" title="Edit">
                        <FormOutlined onClick={() => showEditModal(item.id)} />
                      </Tooltip>
                    ) : null,
                    !item.isScheduled ? (
                      <Tooltip placement="bottom" title="Delete">
                        <Popconfirm
                          title="Are you sure you want to delete?"
                          onConfirm={() => onDelete(item.id)}
                          okText="Yes"
                          cancelText="No"
                        >
                          <DeleteOutlined />
                        </Popconfirm>
                      </Tooltip>
                    ) : null,
                  ]}
                  // extra={<span>{item.createdBy}</span>}
                >
                  <List.Item.Meta
                    title={item.name}
                    description={
                      <span
                        style={{
                          color: `${
                            // eslint-disable-next-line no-nested-ternary
                            item.createdBy === 'Vaibhav'
                              ? 'rgb(3, 155, 229)'
                              : item.createdBy === 'Neha'
                              ? 'rgb(121, 85, 72)'
                              : 'rgb(121, 134, 203)'
                          }`,
                        }}
                      >
                        Created by: - {item.createdBy}
                      </span>
                    }
                  />
                  {item.remark}
                </List.Item>
              )}
            />
          </TabPane>
          <TabPane tab={`Scheduled (${counts.scheduled})`} key="scheduled">
            <List
              itemLayout="horizontal"
              size="small"
              pagination={false}
              dataSource={weeklySchedulers}
              footer={false}
              renderItem={item => (
                <List.Item key={item.title}>
                  <List.Item.Meta
                    title={item.name}
                    description={
                      <span>
                        <span
                          style={{
                            color: `${
                              // eslint-disable-next-line no-nested-ternary
                              item.createdBy === 'Vaibhav'
                                ? 'rgb(3, 155, 229)'
                                : item.createdBy === 'Neha'
                                ? 'rgb(121, 85, 72)'
                                : 'rgb(121, 134, 203)'
                            }`,
                          }}
                        >
                          Created by: {item.createdBy} | Doer:{' '}
                          {item.assignees.length > 1 ? item.assignees.join(', ') : item.assignees}{' '}
                        </span>
                        - Start At: {moment(item.taskStartAt).format('YYYY-MM-DD HH:mm A')} /{' '}
                        <span
                          style={{
                            color: `${
                              // eslint-disable-next-line no-nested-ternary
                              moment(item.taskEndAt).isBefore(moment().toISOString())
                                ? 'rgb(234, 67, 53)'
                                : 'rgb(52, 168, 83)'
                            }`,
                          }}
                        >
                          Finish At: -{moment(item.taskEndAt).format('YYYY-MM-DD HH:mm A')}
                        </span>
                      </span>
                    }
                  />
                  {item.remark}
                </List.Item>
              )}
            />
          </TabPane>
          <TabPane tab={`Completed (${counts.completed})`} key="completed">
            <List
              itemLayout="horizontal"
              size="small"
              pagination={false}
              dataSource={weeklySchedulers}
              footer={false}
              renderItem={item => (
                <List.Item
                  key={item.title}
                  actions={[
                    <Tooltip placement="bottom" title="Complete">
                      <Popconfirm
                        title="Are you sure you want to mark as complete?"
                        onConfirm={() => onMarkComplete(item.id)}
                        okText="Yes"
                        cancelText="No"
                      >
                        <FileDoneOutlined />
                      </Popconfirm>
                    </Tooltip>,
                  ]}
                >
                  <List.Item.Meta
                    title={item.name}
                    description={
                      <span>
                        <span
                          style={{
                            color: `${
                              // eslint-disable-next-line no-nested-ternary
                              item.createdBy === 'Vaibhav'
                                ? 'rgb(3, 155, 229)'
                                : item.createdBy === 'Neha'
                                ? 'rgb(121, 85, 72)'
                                : 'rgb(121, 134, 203)'
                            }`,
                          }}
                        >
                          Created by: - {item.createdBy}{' '}
                        </span>
                        / Finished At: {moment(item.taskCompletedAt).format('YYYY-MM-DD HH:mm A')}
                      </span>
                    }
                  />
                  {item.remark}
                </List.Item>
              )}
            />
          </TabPane>
        </Tabs>
        {modalType === 'task' ? (
          <TaskModal
            setModaltype={setModaltype}
            setTodoData={setTodoData}
            todoData={todoData}
            onSubmit={() => fetchSchedulers(activeKey)}
          />
        ) : null}
        <Modal
          title="Todo"
          confirmLoading={confirmLoading}
          open={modalView}
          onCancel={hideModal}
          style={{ top: 10 }}
          width="60%"
          maskClosable={false}
          cancelText="Back"
          okText={
            <Popconfirm
              title="Sure want to submit form?"
              onConfirm={() => (recordId ? onEdit() : onSubmit())}
              okText="Confirm"
              cancelText="Cancel"
            >
              Submit
            </Popconfirm>
          }
        >
          <form onSubmit={handleSubmit(onSubmit)}>
            <Row>
              <Col span={24}>
                <Controller
                  name="name"
                  control={control}
                  render={({ field }) => <AntInput label="Name" autoSize={{ minRows: 1, maxRows: 6 }} {...field} />}
                />
              </Col>
            </Row>
            <Row>
              <Col span={24}>
                <Controller
                  name="remark"
                  control={control}
                  render={({ field }) => <AntInput label="Remark" autoSize={{ minRows: 1, maxRows: 6 }} {...field} />}
                />
              </Col>
            </Row>
          </form>
        </Modal>
      </>
    </Structure>
  );
};

export default WeeklySchedule;

import Api from '../Api';

export const getMaterialInventoryList = query => async dispatch => {
  try {
    const { data } = await Api.get('/material-inventory', query);
    dispatch({
      type: 'FETCH_MATERIAL_INVENTORY_LIST',
      payload: data,
    });
  } catch (err) {
    throw err;
  }
};

export const clearMaterialInventoryList = () => {
  return {
    type: 'CLEAR_MATERIAL_INVENTORY_LIST',
  };
};

export const getInventoryProfitCenter = query => async dispatch => {
  try {
    const { data } = await Api.get('/material-inventory/reports/profit-center', query);
    dispatch({
      type: 'FETCH_INVENTORY_PROFIT_CENTER',
      payload: data,
    });
  } catch (err) {
    throw err;
  }
};

export const clearInventoryProfitCenter = () => {
  return {
    type: 'CLEAR_INVENTORY_PROFIT_CENTER',
  };
};

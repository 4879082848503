import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { useSelector, useDispatch } from 'react-redux';
import withStyles from 'react-jss';
import { Table, Popconfirm, Tooltip, Typography, Button } from 'antd';
import { DeleteOutlined, EditOutlined } from '@ant-design/icons';
import startCase from 'lodash/startCase';
import moment from 'moment';
import { Link } from 'react-router-dom';
import fileDownload from 'js-file-download';
import Structure from '../Structure/Structure';
import {
  filterDropdown,
  get,
  onFilter,
  sorter,
  onFilterInsideObject,
  sorterInsideObject,
  dateSorter,
  dateFilterDropdown,
  onDateFilter,
} from '../utils';
import Api from '../Api';
import { errorNotification, successNotification } from '../Snackbar';
import styles from '../CommonStyles';
import Heading from '../Components/Heading';
import { getSaleReturnList, clearSaleReturnList } from '../Actions';

const { Text } = Typography;

const SaleReturnList = props => {
  const { classes } = props;
  const dispatch = useDispatch();

  const auth = useSelector(state => state.auth);
  const permissions = get(auth, ['permissions'], []);
  const saleReturnList = useSelector(state => state.saleReturnList);

  // const saleReturnFilesList = useSelector(state => state.saleReturnFilesList);
  // const [saleReturnId, setSaleReturnId] = useState(null);
  // const [modalType, setModalType] = useState(null);
  // const [fileList, setFileList] = useState([]);
  // const [uploading, setUploading] = useState(false);
  const [rowCounts, setRowCounts] = useState(0);

  useEffect(() => {
    dispatch(getSaleReturnList());
    return () => {
      dispatch(clearSaleReturnList());
    };
  }, []);

  useEffect(() => {
    if (saleReturnList) {
      setRowCounts(saleReturnList.length);
    }
  }, [saleReturnList]);

  // useEffect(() => {
  //   if (saleReturnId && modalType === 'ViewFiles') {
  //     dispatch(getSaleReturnFilesList(saleReturnId));
  //   }
  // }, [saleReturnId, modalType]);

  // const showModal = (id, type) => {
  //   setSaleReturnId(id);
  //   setModalType(type);
  // };

  // const hideModal = () => {
  //   setSaleReturnId(null);
  //   setModalType(null);
  //   setFileList([]);
  //   dispatch(clearSaleReturnFilesList());
  // };

  const onDelete = async id => {
    try {
      await Api.delete(`/order-sale-returns/${id}`);
      successNotification('Sale Return deleted');
      dispatch(getSaleReturnList());
    } catch (err) {
      errorNotification(err);
    }
  };

  const exportList = async () => {
    try {
      const { data } = await Api.getFile(`/order-sale-returns/export`);
      fileDownload(data, `sale-return-list.csv`);
    } catch (error) {
      errorNotification(error);
    }
  };

  // const onUpload = async () => {
  //   try {
  //     setUploading(true);
  //     const formData = new FormData();
  //     fileList.forEach((file, index) => {
  //       formData.append(`files[${index}]`, file);
  //     });
  //     const { data } = await Api.post(`/order-sale-returns/${saleReturnId}/files`, formData, {
  //       headers: { 'content-type': 'multipart/form-data' },
  //     });
  //     infoNotification(data);
  //     setUploading(false);
  //     hideModal();
  //     setFileList([]);
  //   } catch (err) {
  //     errorNotification(err);
  //   }
  // };

  // const onFileDelete = async id => {
  //   try {
  //     await Api.delete(`/order-sale-returns/${saleReturnId}/files/${id}`);
  //     successNotification('File delete');
  //     dispatch(getSaleReturnFilesList(saleReturnId));
  //   } catch (err) {
  //     errorNotification(err);
  //   }
  // };

  // const onRemove = file => {
  //   const index = fileList.indexOf(file);
  //   const newFileList = fileList.slice();
  //   newFileList.splice(index, 1);
  //   setFileList(newFileList);
  // };

  // const beforeUpload = (file, files) => {
  //   setFileList([...fileList, ...files]);
  //   return false;
  // };

  const tooltipShow = arg => (
    <Tooltip placement="topLeft" title={arg}>
      {arg}
    </Tooltip>
  );

  const tooltipShowInsideObject = (keys, arg) => {
    const value = get(arg, keys, null);
    return (
      <Tooltip placement="topLeft" title={value}>
        {value}
      </Tooltip>
    );
  };

  const columns = [
    {
      title: 'Sale Return No.',
      dataIndex: 'id',
      sorter: sorter('id'),
      filterDropdown,
      onFilter: onFilter('id'),
      width: 95,
      fixed: 'left',
    },
    {
      title: 'Order No.',
      dataIndex: 'orderId',
      sorter: sorter('orderId'),
      filterDropdown,
      onFilter: onFilter('orderId'),
      width: 90,
      fixed: 'left',
    },
    {
      title: 'Finished Good Name',
      dataIndex: 'order',
      sorter: sorterInsideObject('order', 'formulation', 'name'),
      filterDropdown,
      onFilter: onFilterInsideObject('order', 'formulation', 'name'),
      render: tooltipShowInsideObject.bind(null, ['formulation', 'name']),
      width: 200,
      fixed: 'left',
    },
    {
      title: 'Customer',
      dataIndex: 'order',
      filterDropdown,
      sorter: sorterInsideObject('order', 'customer'),
      onFilter: onFilterInsideObject('order', 'customer'),
      render: tooltipShowInsideObject.bind(null, ['customer']),
      width: 130,
    },
    {
      title: 'Consignor',
      dataIndex: 'consignor',
      sorter: sorter('consignor'),
      filterDropdown,
      onFilter: onFilter('consignor'),
      width: 130,
    },
    {
      title: 'Voucher No.',
      dataIndex: 'voucherNo',
      sorter: sorter('voucherNo'),
      filterDropdown,
      onFilter: onFilter('voucherNo'),
      width: 90,
    },
    {
      title: 'Voucher Date',
      dataIndex: 'voucherDate',
      sorter: dateSorter('voucherDate'),
      filterDropdown: dateFilterDropdown,
      onFilter: onDateFilter('voucherDate'),
      render: voucherDate => (voucherDate ? moment(voucherDate).format('DD MMM YYYY') : null),
      width: 120,
    },
    {
      title: 'Sale Type',
      dataIndex: 'type',
      sorter: sorter('type'),
      filterDropdown,
      onFilter: onFilter('type'),
      render: type => startCase(type),
      width: 100,
    },
    {
      title: 'HSN Code',
      dataIndex: 'order',
      sorter: sorterInsideObject('order', 'formulation', 'hsnCode'),
      filterDropdown,
      onFilter: onFilterInsideObject('order', 'formulation', 'hsnCode'),
      render: tooltipShowInsideObject.bind(null, ['formulation', 'hsnCode']),
      width: 100,
    },
    {
      title: 'Quantity',
      dataIndex: 'quantity',
      sorter: sorter('quantity'),
      filterDropdown,
      onFilter: onFilter('quantity'),
      width: 130,
      align: 'right',
    },
    {
      title: 'Rate',
      dataIndex: 'rate',
      sorter: sorter('rate'),
      filterDropdown,
      onFilter: onFilter('rate'),
      render: rate => Number(rate).toLocaleString('en-IN'),
      width: 130,
      align: 'right',
    },
    {
      title: 'Sale Value',
      dataIndex: 'value',
      sorter: sorter('value'),
      filterDropdown,
      onFilter: onFilter('value'),
      render: value => Number(value).toLocaleString('en-IN'),
      width: 130,
      align: 'right',
    },
    {
      title: 'GST (in %)',
      dataIndex: 'gst',
      sorter: sorter('gst'),
      filterDropdown,
      onFilter: onFilter('gst'),
      width: 100,
      align: 'right',
    },
    {
      title: 'GST Value',
      dataIndex: 'gstValue',
      sorter: sorter('gstValue'),
      filterDropdown,
      onFilter: onFilter('gstValue'),
      render: gstValue => Number(gstValue).toLocaleString('en-IN'),
      width: 130,
      align: 'right',
    },
    {
      title: 'Forwarding Charges',
      dataIndex: 'forwardingCharges',
      sorter: sorter('forwardingCharges'),
      filterDropdown,
      onFilter: onFilter('forwardingCharges'),
      render: forwardingCharges => Number(forwardingCharges).toLocaleString('en-IN'),
      width: 130,
      align: 'right',
    },
    {
      title: 'Forwarding Charges Tax Value (Tax 18%)',
      dataIndex: 'forwardingChargesTax',
      sorter: sorter('forwardingChargesTax'),
      filterDropdown,
      onFilter: onFilter('forwardingChargesTax'),
      render: forwardingChargesTax => Number(forwardingChargesTax).toLocaleString('en-IN'),
      width: 130,
      align: 'right',
    },
    {
      title: 'OtherCharges / Discount',
      dataIndex: 'otherChargesDiscount',
      sorter: sorter('otherChargesDiscount'),
      filterDropdown,
      onFilter: onFilter('otherChargesDiscount'),
      render: otherChargesDiscount => Number(otherChargesDiscount).toLocaleString('en-IN'),
      width: 130,
      align: 'right',
    },
    {
      title: 'Round Of',
      dataIndex: 'roundOf',
      sorter: sorter('roundOf'),
      filterDropdown,
      onFilter: onFilter('roundOf'),
      render: roundOf => Number(roundOf).toLocaleString('en-IN'),
      width: 130,
      align: 'right',
    },
    {
      title: 'Total',
      dataIndex: 'total',
      sorter: sorter('total'),
      filterDropdown,
      onFilter: onFilter('total'),
      render: total => Number(total).toLocaleString('en-IN'),
      width: 140,
      align: 'right',
    },
    {
      title: 'Done By',
      dataIndex: 'createdByUser',
      sorter: sorter('createdByUser'),
      filterDropdown,
      onFilter: onFilter('createdByUser'),
      render: tooltipShow,
      width: 120,
    },
    {
      title: 'Remarks',
      dataIndex: 'remarks',
      sorter: sorter('remarks'),
      filterDropdown,
      onFilter: onFilter('remarks'),
      ellipsis: { showTitle: false },
      render: tooltipShow,
      width: 150,
    },
  ];

  const renderActionIcons = record => (
    <div>
      {permissions.includes('edit_sale_return') ? (
        <>
          <Tooltip placement="bottom" title="Edit">
            <Link to={`/salereturn/edit/${record.id}`}>
              <EditOutlined className={classes.tableIcon} />
            </Link>
          </Tooltip>
          &nbsp;&nbsp;&nbsp;&nbsp;
        </>
      ) : null}
      {permissions.includes('delete_sale_return') ? (
        <>
          <Tooltip placement="bottom" title="Delete">
            <Popconfirm
              title="Are you sure you want to delete?"
              onConfirm={() => onDelete(record.id)}
              okText="Yes"
              cancelText="No"
            >
              <DeleteOutlined className={classes.tableIcon} />
            </Popconfirm>
          </Tooltip>
        </>
      ) : null}
    </div>
  );

  if (permissions.includes('edit_sale_return') || permissions.includes('delete_sale_return')) {
    columns.push({
      title: 'Actions',
      width: 70,
      render: renderActionIcons,
    });
  }

  // const renderUploadColumn = record => (
  //   <Tooltip placement="bottom" title="Upload Files">
  //     <CloudUploadOutlined className={classes.tableIcon} onClick={() => showModal(record.id, 'UploadFiles')} />
  //   </Tooltip>
  // );

  // columns.push({
  //   title: 'Upload Files',
  //   width: 70,
  //   render: renderUploadColumn,
  // });

  // const renderViewColumn = record => {
  //   let color;
  //   if (record.files) {
  //     if (record.files > 0) {
  //       color = 'Green';
  //     } else {
  //       color = 'Red';
  //     }
  //   }

  //   return (
  //     <Tooltip placement="bottom" title="View Files">
  //       <CloudDownloadOutlined
  //         style={{ color }}
  //         className={classes.tableIcon}
  //         onClick={() => showModal(record.id, 'ViewFiles')}
  //       />
  //     </Tooltip>
  //   );
  // };

  // columns.push({
  //   title: 'View Files',
  //   width: 70,
  //   render: renderViewColumn,
  // });

  // const filesTableColumns = [];

  // const renderFileTableActionColumn = record => {
  //   return (
  //     <Tooltip placement="bottom" title="Delete File">
  //       <Popconfirm
  //         title="Are you sure you want to delete?"
  //         onConfirm={() => onFileDelete(record.id)}
  //         okText="Yes"
  //         cancelText="No"
  //       >
  //         <DeleteOutlined className={classes.tableIcon} />
  //       </Popconfirm>
  //     </Tooltip>
  //   );
  // };
  // const renderFileTableNameColumn = record => (
  //   <a href={record.url} rel="noreferrer noopener" target="_blank">
  //     <Tooltip placement="bottom" title={`Click to open/download "${record.fileName}" file.`}>
  //       {record.fileName}
  //     </Tooltip>
  //   </a>
  // );
  // filesTableColumns.push({
  //   title: 'File',
  //   render: renderFileTableNameColumn,
  // });
  // filesTableColumns.push({
  //   title: 'Delete',
  //   render: renderFileTableActionColumn,
  //   width: 70,
  // });

  return (
    <Structure>
      <>
        <Heading text={`Sale Return List (${rowCounts})`}>
          <Button type="default" onClick={exportList}>
            Export
          </Button>
        </Heading>
        <Table
          columns={columns}
          bordered
          rowKey={record => record.id}
          pagination={{ defaultPageSize: 20, position: ['bottomLeft'] }}
          dataSource={saleReturnList}
          size="small"
          scroll={{ x: 1050, y: window.innerHeight - 300 }}
          onChange={(_page, _filters, _sorter, { currentDataSource }) => {
            setRowCounts((currentDataSource || []).length);
          }}
          summary={pageData => {
            let grandTotal = 0;
            pageData.forEach(({ total }) => {
              grandTotal += +total;
            });
            return (
              <>
                <Table.Summary.Row>
                  <Table.Summary.Cell colSpan={18}>Grand Total</Table.Summary.Cell>
                  <Table.Summary.Cell className={classes.textAlignRight}>
                    <Text>{Number(grandTotal.toFixed(2)).toLocaleString('en-IN')}</Text>
                  </Table.Summary.Cell>
                  <Table.Summary.Cell colSpan={5} />
                </Table.Summary.Row>
              </>
            );
          }}
        />
        {/* <Modal
          onOk={hideModal}
          open={modalType === 'UploadFiles'}
          centered
          maskClosable={false}
          title="Upload Sale Return Files."
          width="500px"
          okText="Close"
          cancelButtonProps={{ className: classes.hide }}
          okButtonProps={{ className: classes.button }}
          closable={false}
        >
          <Upload multiple onRemove={onRemove} beforeUpload={beforeUpload} fileList={fileList}>
            <Button icon={<CloudUploadOutlined />}>Select Files</Button>
          </Upload>
          <Button
            type="primary"
            onClick={onUpload}
            disabled={fileList.length === 0}
            loading={uploading}
            style={{ marginTop: 16 }}
          >
            {uploading ? 'Uploading' : 'Upload'}
          </Button>
        </Modal>
        <Modal
          onOk={hideModal}
          open={modalType === 'ViewFiles'}
          centered
          maskClosable={false}
          title="View Sale Return Files."
          width="800px"
          okText="Close"
          cancelButtonProps={{ className: classes.hide }}
          closable={false}
          okButtonProps={{ className: classes.button }}
        >
          <Table
            columns={filesTableColumns}
            bordered
            rowKey={record => record.id}
            dataSource={saleReturnFilesList}
            size="small"
            pagination={{ position: ['bottomLeft'] }}
          />
        </Modal> */}
      </>
    </Structure>
  );
};

SaleReturnList.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(SaleReturnList);

export const orderList = (state = [], action) => {
  if (action.type === 'FETCH_ORDER_LIST') {
    return action.payload;
  }
  if (action.type === 'CLEAR_ORDER_LIST') {
    return [];
  }
  return state;
};

export const ordersWithLessDetails = (state = [], action) => {
  if (action.type === 'FETCH_ORDER_LIST_WITH_LESS_DETAILS') {
    return action.payload;
  }
  if (action.type === 'CLEAR_ORDER_LIST_WITH_LESS_DETAILS') {
    return [];
  }
  return state;
};

export const completeOrdersIds = (state = [], action) => {
  if (action.type === 'FETCH_COMPLETE_ORDERS_IDS') {
    return action.payload;
  }
  if (action.type === 'CLEAR_COMPLETE_ORDERS_IDS') {
    return [];
  }
  return state;
};

export const order = (state = {}, action) => {
  if (action.type === 'FETCH_ORDER') {
    return action.payload;
  }
  if (action.type === 'CLEAR_ORDER') {
    return {};
  }
  return state;
};

export const orderFilesList = (state = [], action) => {
  if (action.type === 'FETCH_ORDER_FILES_LIST') {
    return action.payload;
  }
  if (action.type === 'CLEAR_ORDER_FILES_LIST') {
    return [];
  }
  return state;
};

export const orderShortageItemsList = (state = [], action) => {
  if (action.type === 'FETCH_ORDER_SHORTAGE_ITEMS_LIST') {
    return action.payload;
  }
  if (action.type === 'CLEAR_ORDER_SHORTAGE_ITEMS_LIST') {
    return [];
  }
  return state;
};

export const productionOrders = (state = [], action) => {
  if (action.type === 'FETCH_PRODUCTION_ORDERS_LIST') {
    return action.payload;
  }
  if (action.type === 'CLEAR_PRODUCTION_ORDERS_LIST') {
    return [];
  }
  return state;
};

export const jobCard = (state = {}, action) => {
  if (action.type === 'FETCH_JOB_CARD') {
    return action.payload;
  }
  if (action.type === 'CLEAR_JOB_CARD') {
    return {};
  }
  return state;
};

export const orderDispenseDetailsList = (state = [], action) => {
  if (action.type === 'FETCH_ORDER_DISPENSE_DETAILS_LIST') {
    return action.payload;
  }
  if (action.type === 'CLEAR_ORDER_DISPENSE_DETAILS_LIST') {
    return [];
  }
  return state;
};

export const orderBatchMaterialsDetail = (state = [], action) => {
  if (action.type === 'FETCH_ORDER_BATCH_MATERIALS_DETAIL') {
    return action.payload;
  }
  if (action.type === 'CLEAR_ORDER_BATCH_MATERIALS_DETAIL') {
    return [];
  }
  return state;
};

export const orderFillingDetail = (state = {}, action) => {
  if (action.type === 'FETCH_ORDER_FILLING_DETAIL') {
    return action.payload;
  }
  if (action.type === 'CLEAR_ORDER_FILLING_DETAIL') {
    return {};
  }
  return state;
};

export const isInPlanningOrders = (state = [], action) => {
  if (action.type === 'FETCH_IS_IN_PLANNING_ORDERS') {
    return action.payload;
  }
  if (action.type === 'CLEAR_IS_IN_PLANNING_ORDERS') {
    return [];
  }
  return state;
};

export const isInProductionPlanningOrders = (state = [], action) => {
  if (action.type === 'FETCH_IS_IN_PRODUCTION_PLANNING_ORDERS') {
    return action.payload;
  }
  if (action.type === 'CLEAR_IS_IN_PRODUCTION_PLANNING_ORDERS') {
    return [];
  }
  return state;
};

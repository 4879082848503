import Api from '../Api';

export const getCreditNoteList = () => async dispatch => {
  try {
    const { data } = await Api.get('/credit-note');
    dispatch({
      type: 'FETCH_CREDIT_NOTE_LIST',
      payload: data,
    });
  } catch (err) {
    throw err;
  }
};

export const clearCreditNoteList = () => {
  return {
    type: 'CLEAR_CREDIT_NOTE_LIST',
  };
};

export const getCreditNoteFilesList = id => async dispatch => {
  try {
    const { data } = await Api.get(`/credit-note/${id}/files`);
    dispatch({
      type: 'FETCH_CREDIT_NOTE_FILES_LIST',
      payload: data,
    });
  } catch (err) {
    throw err;
  }
};

export const clearCreditNoteFilesList = () => {
  return {
    type: 'CLEAR_CREDIT_NOTE_FILES_LIST',
  };
};

export const getCreditNote = id => async dispatch => {
  try {
    const { data } = await Api.get(`/credit-note/${id}`);
    dispatch({
      type: 'FETCH_CREDIT_NOTE',
      payload: data,
    });
  } catch (err) {
    throw err;
  }
};

export const clearCreditNote = () => {
  return {
    type: 'CLEAR_CREDIT_NOTE',
  };
};

import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { useSelector, useDispatch } from 'react-redux';
import withStyles from 'react-jss';
import { Table, Tooltip } from 'antd';
import { EditOutlined } from '@ant-design/icons';
import { Link } from 'react-router-dom';
import Structure from '../Structure/Structure';
import { filterDropdown, get, onFilter, sorter } from '../utils';
import styles from '../CommonStyles';
import Heading from '../Components/Heading';
import { getCustomerOpeningBalances, clearCustomerOpeningBalances } from '../Actions';

const OpeningBalances = props => {
  const { classes } = props;
  const dispatch = useDispatch();

  const auth = useSelector(state => state.auth);
  const permissions = get(auth, ['permissions'], []);
  const customerOpeningBalancesList = useSelector(state => state.customerOpeningBalancesList);

  useEffect(() => {
    dispatch(getCustomerOpeningBalances());
    return () => {
      dispatch(clearCustomerOpeningBalances());
    };
  }, []);

  const renderActionIcons = record => (
    <div>
      {permissions.includes('edit_customer_opening_balance') ? (
        <>
          <Tooltip placement="bottom" title="Edit">
            <Link to={`/customer/openingbalances/edit/${record.id}`}>
              <EditOutlined className={classes.tableIcon} />
            </Link>
          </Tooltip>
        </>
      ) : null}
    </div>
  );

  const columns = [
    {
      title: 'Customer',
      dataIndex: 'customer',
      sorter: sorter('customer'),
      filterDropdown,
      onFilter: onFilter('customer'),
    },
    {
      title: 'Balance',
      dataIndex: 'balance',
      sorter: sorter('balance'),
      filterDropdown,
      onFilter: onFilter('balance'),
    },
    {
      title: 'Actions',
      width: '7%',
      render: renderActionIcons,
    },
  ];

  return (
    <Structure>
      <>
        <Heading text="Opening Balances List" />
        <Table
          columns={columns}
          bordered
          rowKey={record => record.id}
          pagination={{ defaultPageSize: 20, position: ['bottomLeft'], showTotal: total => `Total ${total} Items` }}
          dataSource={customerOpeningBalancesList}
          size="small"
          scroll={{ x: 1050, y: window.innerHeight - 300 }}
        />
      </>
    </Structure>
  );
};

OpeningBalances.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(OpeningBalances);

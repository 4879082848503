export default {
  row: {
    display: 'flex',
    alignItems: 'center',
    paddingBottom: 10,
  },
  heading: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    color: 'rgba(0, 0, 0, 0.85)',
    fontSize: 18,
  },
};

import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { Button, Card, Descriptions, Row, Select } from 'antd';
import { Calendar as RBC, momentLocalizer } from 'react-big-calendar';
import moment from 'moment';
import { useDispatch, useSelector } from 'react-redux';
import withStyles from 'react-jss';
import _ from 'lodash';
import fileDownload from 'js-file-download';

import Modal from 'antd/lib/modal/Modal';
import Structure from '../Structure/Structure';
import Heading from '../Components/Heading';
import {
  getCalendarEvents,
  clearCalendarEvents,
  getCustomerCalendarEvents,
  clearCustomerCalendarEvents,
  getSupplierCalendarEvents,
  clearSupplierCalendarEvents,
} from '../Actions';
import styles from '../CommonStyles';
import { get } from '../utils';
import { materialTypes } from '../constants';
import Api from '../Api';
import { errorNotification, infoNotification } from '../Snackbar';

const CurrencyFormatter = new Intl.NumberFormat('en-IN', {
  currency: 'INR',
  style: 'currency',
});

const Calendar = props => {
  const { classes } = props;

  const dispatch = useDispatch();

  const [type, setType] = useState('purchase_orders');
  const [materialType, setMaterialType] = useState('all');
  const [event, setEvent] = useState(null);
  const [start, setStart] = useState(moment(new Date()).startOf('week').toISOString());
  const [end, setEnd] = useState(moment(new Date()).endOf('week').toISOString());

  const calendarEvents = useSelector(state => state.calendarEvents);
  const customerCalendarEvents = useSelector(state => state.customerCalendarEvents);
  const supplierCalendarEvents = useSelector(state => state.supplierCalendarEvents);

  useEffect(() => {
    return () => {
      dispatch(clearCalendarEvents());
      dispatch(clearCustomerCalendarEvents());
      dispatch(clearSupplierCalendarEvents());
    };
  }, []);

  useEffect(() => {
    dispatch(clearCalendarEvents());
    dispatch(clearCustomerCalendarEvents());
    dispatch(clearSupplierCalendarEvents());

    if (['purchase_orders', 'orders'].includes(type)) {
      dispatch(getCalendarEvents({ type, start, end, materialType }));
    } else if (type === 'customer_dues' && !customerCalendarEvents.length) {
      dispatch(getCustomerCalendarEvents());
    } else if (type === 'supplier_dues' && !supplierCalendarEvents.length) {
      dispatch(getSupplierCalendarEvents());
    }
  }, [type, start, end, materialType]);

  const exportData = async () => {
    try {
      infoNotification('File downloading...');
      const { data } = await Api.getFile(`/calendar/export`, { type, start, end, materialType });
      fileDownload(data, `calendar-data.csv`);
    } catch (error) {
      errorNotification(error);
    }
  };

  let events = [];

  if (['purchase_orders', 'orders'].includes(type)) {
    events = calendarEvents;
  } else if (type === 'customer_dues') {
    events = customerCalendarEvents.dueEvents;
  } else if (type === 'supplier_dues') {
    events = supplierCalendarEvents.dueEvents;
  }

  // TODO Bad code dont do this just a workaround
  useEffect(() => {
    const nodes = document.querySelectorAll('.rbc-time-content');
    if (nodes.length) {
      nodes.forEach(el => {
        el.style.display = 'none';
      });
    }

    const nodes2 = document.querySelectorAll('.rbc-time-header-gutter');
    if (nodes2.length) {
      nodes2.forEach(el => {
        el.style.display = 'none';
      });
    }

    const nodes3 = document.querySelectorAll('.rbc-time-header');
    if (nodes3.length) {
      nodes3.forEach(el => {
        el.style.height = '650px';
      });
    }

    const nodes4 = document.querySelectorAll('.rbc-time-header-content');
    if (nodes4.length) {
      nodes4.forEach(el => {
        el.style['border-left'] = 'none';
      });
    }

    const nodes5 = document.querySelectorAll('.rbc-allday-cell');
    if (nodes5.length) {
      nodes5.forEach(el => {
        el.style['min-height'] = '637px';
      });
    }
  }, [calendarEvents, start, end]);

  return (
    <Structure>
      <>
        <Heading text="Calendar View" />
        <Row>
          <Select value={type} onChange={setType} style={{ width: 250 }}>
            <Select.Option value="purchase_orders">Expected Purchase Orders</Select.Option>
            <Select.Option value="orders">Expected Orders</Select.Option>
            <Select.Option value="customer_dues">Customer Dues</Select.Option>
            <Select.Option value="supplier_dues">Supplier Dues</Select.Option>
          </Select>
          &nbsp;
          {type === 'purchase_orders' ? (
            <Select value={materialType} onChange={setMaterialType} style={{ width: 250 }}>
              <Select.Option value="all">All</Select.Option>
              {materialTypes.map(_materialType => (
                <Select.Option key={_materialType} value={_materialType}>
                  {_.startCase(_materialType)}
                </Select.Option>
              ))}
            </Select>
          ) : null}
          &nbsp;
          <Button type="default" onClick={exportData}>
            Export
          </Button>
        </Row>
        <br />
        {type === 'customer_dues' ? (
          <>
            <h3>Total Due: {CurrencyFormatter.format(+customerCalendarEvents.totalDue)}</h3>
            <h3>Total Overdue: {CurrencyFormatter.format(+customerCalendarEvents.totalOverDue)}</h3>
          </>
        ) : null}
        {type === 'supplier_dues' ? (
          <>
            <h3>Total Due: {CurrencyFormatter.format(+supplierCalendarEvents.totalDue)}</h3>
            <h3>Total Overdue: {CurrencyFormatter.format(+supplierCalendarEvents.totalOverDue)}</h3>
          </>
        ) : null}
        <RBC
          localizer={momentLocalizer(moment)}
          style={{ minHeight: Math.max(550, window.innerHeight - 150) }}
          selectable
          events={events}
          views={['month', 'week', 'day']}
          defaultView="month"
          onSelectEvent={setEvent}
          eventPropGetter={() => {
            return {
              style: {
                backgroundColor: 'rgb(97, 97, 97)',
                color: 'rgb(255, 255, 255)',
                fontSize: '16px',
                border: '0px',
              },
            };
          }}
          selected={event}
          onRangeChange={x => {
            if (x.start && x.end) {
              setStart(moment(x.start).startOf('day').toISOString());
              setEnd(moment(x.end).endOf('day').toISOString());
            } else if (x.length) {
              setStart(moment(x[0]).startOf('day').toISOString());
              setEnd(
                moment(x[x.length - 1])
                  .endOf('day')
                  .toISOString()
              );
            }
          }}
        />
        <Modal
          open={event && ['purchase_order', 'order'].includes(event.resource)}
          onOk={() => setEvent(null)}
          closable={false}
          style={{ top: 10 }}
          // width="85%"
          okText="Close"
          cancelButtonProps={{ className: classes.hide }}
          okButtonProps={{ className: classes.button }}
        >
          <Card title={_.startCase(get(event, ['resource']))}>
            <Descriptions column={1} size="small">
              {get(event, ['resource']) === 'purchase_order' ? (
                <>
                  <Descriptions.Item label="PO No.">
                    <span className={classes.detailColor}>{get(event, ['purchaseOrderId'])}</span>
                  </Descriptions.Item>
                  <Descriptions.Item label="Supplier">
                    <span className={classes.detailColor}>{get(event, ['supplier'])}</span>
                  </Descriptions.Item>
                  <Descriptions.Item label="Material">
                    <span className={classes.detailColor}>{get(event, ['title'])}</span>
                  </Descriptions.Item>
                </>
              ) : null}
              {get(event, ['resource']) === 'order' ? (
                <>
                  <Descriptions.Item label="Order No.">
                    <span className={classes.detailColor}>{get(event, ['orderId'])}</span>
                  </Descriptions.Item>
                  <Descriptions.Item label="Customer">
                    <span className={classes.detailColor}>{get(event, ['customer'])}</span>
                  </Descriptions.Item>
                  <Descriptions.Item label="Finished Good Name">
                    <span className={classes.detailColor}>{get(event, ['title'])}</span>
                  </Descriptions.Item>
                  <Descriptions.Item label="Section">
                    <span className={classes.detailColor}>{get(event, ['section'])}</span>
                  </Descriptions.Item>
                  <Descriptions.Item label="Status">
                    <span className={classes.detailColor}>{get(event, ['status'])}</span>
                  </Descriptions.Item>
                </>
              ) : null}
              <Descriptions.Item label="Unit">
                <span className={classes.detailColor}>{get(event, ['unit'])}</span>
              </Descriptions.Item>
              <Descriptions.Item label="Quantity">
                <span className={classes.detailColor}>{get(event, ['quantity'])}</span>
              </Descriptions.Item>
              <Descriptions.Item label="Rate">
                <span className={classes.detailColor}>{get(event, ['rate'])}</span>
              </Descriptions.Item>
              <Descriptions.Item label="Expected on">
                <span className={classes.detailColor}>{moment(get(event, ['start'])).format('DD MMM YYYY')}</span>
              </Descriptions.Item>
            </Descriptions>
          </Card>
        </Modal>
        <Modal
          open={event && event.customerId}
          onOk={() => setEvent(null)}
          closable={false}
          style={{ top: 10 }}
          // width="85%"
          okText="Close"
          cancelButtonProps={{ className: classes.hide }}
          okButtonProps={{ className: classes.button }}
        >
          <Card title="Customer Due">
            <Descriptions column={1} size="small">
              <Descriptions.Item label="Consignor">
                <span className={classes.detailColor}>{get(event, ['consignor'])}</span>
              </Descriptions.Item>
              <Descriptions.Item label="Customer">
                <span className={classes.detailColor}>{get(event, ['title'])}</span>
              </Descriptions.Item>
              <Descriptions.Item label="VoucherNo">
                <span className={classes.detailColor}>{get(event, ['voucherNo'])}</span>
              </Descriptions.Item>
              <Descriptions.Item label="VoucherDate">
                <span className={classes.detailColor}>
                  {get(event, ['voucherDate']) ? moment(get(event, ['voucherDate'])).format('DD MMM YYYY') : null}
                </span>
              </Descriptions.Item>
              <Descriptions.Item label="Expected">
                <span className={classes.detailColor}>{get(event, ['expected'])}</span>
              </Descriptions.Item>
              <Descriptions.Item label="Due Amount">
                <span className={classes.detailColor}>{Number(get(event, ['amount'], 0)).toLocaleString('en-IN')}</span>
              </Descriptions.Item>
              <Descriptions.Item label="Voucher Amount">
                <span className={classes.detailColor}>
                  {Number(get(event, ['voucherAmount'], 0)).toLocaleString('en-IN')}
                </span>
              </Descriptions.Item>
              <Descriptions.Item label="Handler">
                <span className={classes.detailColor}>{get(event, ['handler'])}</span>
              </Descriptions.Item>
            </Descriptions>
          </Card>
        </Modal>
        <Modal
          open={event && event.supplierId}
          onOk={() => setEvent(null)}
          closable={false}
          style={{ top: 10 }}
          // width="85%"
          okText="Close"
          cancelButtonProps={{ className: classes.hide }}
          okButtonProps={{ className: classes.button }}
        >
          <Card title="Supplier Due">
            <Descriptions column={1} size="small">
              <Descriptions.Item label="Consignor">
                <span className={classes.detailColor}>{get(event, ['consignor'])}</span>
              </Descriptions.Item>
              <Descriptions.Item label="Supplier">
                <span className={classes.detailColor}>{get(event, ['title'])}</span>
              </Descriptions.Item>
              <Descriptions.Item label="VoucherNo">
                <span className={classes.detailColor}>{get(event, ['voucherNo'])}</span>
              </Descriptions.Item>
              <Descriptions.Item label="VoucherDate">
                <span className={classes.detailColor}>
                  {get(event, ['voucharDate']) ? moment(get(event, ['voucherDate'])).format('DD MMM YYYY') : null}
                </span>
              </Descriptions.Item>
              <Descriptions.Item label="Expected">
                <span className={classes.detailColor}>{get(event, ['expected'])}</span>
              </Descriptions.Item>
              <Descriptions.Item label="Due Amount">
                <span className={classes.detailColor}>{Number(get(event, ['amount'], 0)).toLocaleString('en-IN')}</span>
              </Descriptions.Item>
              <Descriptions.Item label="Vouchar Amount">
                <span className={classes.detailColor}>
                  {Number(get(event, ['voucharAmount'], 0)).toLocaleString('en-IN')}
                </span>
              </Descriptions.Item>
            </Descriptions>
          </Card>
        </Modal>
      </>
    </Structure>
  );
};

Calendar.propTypes = {
  classes: PropTypes.object,
};

export default withStyles(styles)(Calendar);

import React, { useEffect, Fragment } from 'react';
import { DatePicker, Row, Table } from 'antd';
import { useDispatch, useSelector } from 'react-redux';
import { Field, getFormValues, reduxForm } from 'redux-form';
import moment from 'moment';
import GetField from '../Components/GetField';
import { clearLeadMIS, getLeadMIS } from '../Actions';
import { onFilter, sorter, filterDropdown } from '../utils';
import Structure from '../Structure/Structure';
import Heading from '../Components/Heading';

const AntDatePicker = GetField(DatePicker);

const LeadMIS = () => {
  const dispatch = useDispatch();

  const leadMIS = useSelector(state => state.leadMIS.data);
  const weeks = useSelector(state => state.leadMIS.weeks);

  const FormValues = useSelector(state => getFormValues('LeadMIS')(state));

  useEffect(() => {
    return () => {
      dispatch(clearLeadMIS());
    };
  }, []);

  useEffect(() => {
    if (FormValues && FormValues.date) {
      dispatch(
        getLeadMIS({
          date: moment(FormValues.date).startOf('month').toISOString(),
        })
      );
    }
  }, [FormValues && FormValues.date]);

  const _columns = [
    {
      title: 'Action',
      dataIndex: 'name',
      sorter: sorter('name'),
      onFilter: onFilter('name'),
      filterDropdown,
      fixed: 'left',
      width: '10%',
    },
    {
      title: `Week ${weeks[0].week || ''} (${weeks[0].start || ''} - ${weeks[0].end || ''})`,
      dataIndex: 'week1',
      render: (_text, record) => record.week1,
    },
    {
      title: `Week ${weeks[1].week || ''} (${weeks[1].start || ''} - ${weeks[1].end || ''})`,
      dataIndex: 'week2',
      render: (_text, record) => record.week2,
    },
    {
      title: `Week ${weeks[2].week || ''} (${weeks[2].start || ''} - ${weeks[2].end || ''})`,
      dataIndex: 'week3',
      render: (_text, record) => record.week3,
    },
    {
      title: `Week ${weeks[3].week || ''} (${weeks[3].start || ''} - ${weeks[3].end || ''})`,
      dataIndex: 'week4',
      render: (_text, record) => record.week4,
    },
    {
      title: `Week ${weeks[4].week || ''} (${weeks[4].start || ''} - ${weeks[4].end || ''})`,
      dataIndex: 'week5',
      render: (_text, record) => record.week5,
    },
  ];

  return (
    <Structure>
      <>
        <Heading text="Lead MIS" />
        <Row>
          <Field allowClear required name="date" placeholder="Month" picker="month" component={AntDatePicker} />
        </Row>
        <Table
          columns={_columns}
          bordered
          rowKey={record => record.name}
          dataSource={leadMIS}
          size="small"
          pagination={false}
          scroll={{ x: 1024, y: window.innerHeight - 300 }}
        />
      </>
    </Structure>
  );
};

const FormedLeadMIS = reduxForm({
  form: 'LeadMIS',
  enableReinitialize: true,
})(LeadMIS);

export default FormedLeadMIS;

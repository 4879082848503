import Api from '../Api';

export const getCustomerList = params => async dispatch => {
  try {
    const { data } = await Api.get('/customers', params);
    dispatch({
      type: 'FETCH_CUSTOMER_LIST',
      payload: data,
    });
  } catch (err) {
    throw err;
  }
};

export const clearCustomerList = () => {
  return {
    type: 'CLEAR_CUSTOMER_LIST',
  };
};

export const getCustomer = id => async dispatch => {
  try {
    const { data } = await Api.get(`/customers/${id}`);
    dispatch({
      type: 'FETCH_CUSTOMER',
      payload: data,
    });
  } catch (err) {
    throw err;
  }
};

export const clearCustomer = () => {
  return {
    type: 'CLEAR_CUSTOMER',
  };
};

export const getCustomerIdList = query => async dispatch => {
  try {
    const { data } = await Api.get('/customers/ids', query);
    dispatch({
      type: 'FETCH_CUSTOMER_ID_LIST',
      payload: data,
    });
  } catch (err) {
    throw err;
  }
};

export const clearCustomerIdList = () => {
  return {
    type: 'CLEAR_CUSTOMER_ID_LIST',
  };
};

export const getCustomerLedger = id => async dispatch => {
  try {
    const { data } = await Api.get(`/customers/${id}/ledger`);
    dispatch({
      type: 'FETCH_CUSTOMER_LEDGER',
      payload: data,
    });
  } catch (err) {
    throw err;
  }
};

export const clearCustomerLedger = () => {
  return {
    type: 'CLEAR_CUSTOMER_LEDGER',
  };
};

export const getCustomerInventoryView = id => async dispatch => {
  try {
    const { data } = await Api.get(`/material-inventory/customers/${id}/pp`);
    dispatch({
      type: 'FETCH_CUSTOMER_INVENTORY_VIEW',
      payload: data,
    });
  } catch (err) {
    throw err;
  }
};

export const clearCustomerInventoryView = () => {
  return {
    type: 'CLEAR_CUSTOMER_INVENTORY_VIEW',
  };
};

export const getCustomerOrders = id => async dispatch => {
  try {
    const { data } = await Api.get(`/customers/${id}/orders`);
    dispatch({
      type: 'FETCH_CUSTOMER_ORDERS',
      payload: data,
    });
  } catch (err) {
    throw err;
  }
};

export const clearCustomerOrders = () => {
  return {
    type: 'CLEAR_CUSTOMER_ORDERS',
  };
};

export const getCustomerComplaints = () => async dispatch => {
  try {
    const { data } = await Api.get(`/customer-complaints/`);
    dispatch({
      type: 'FETCH_CUSTOMER_COMPLAINTS',
      payload: data,
    });
  } catch (err) {
    throw err;
  }
};

export const clearCustomerComplaints = () => {
  return {
    type: 'CLEAR_CUSTOMER_COMPLAINTS',
  };
};

export const getCustomerOpeningBalances = () => async dispatch => {
  try {
    const { data } = await Api.get(`/customers/openingbalances`);
    dispatch({
      type: 'FETCH_CUSTOMER_OPENING_BALANCES_LIST',
      payload: data,
    });
  } catch (err) {
    throw err;
  }
};

export const clearCustomerOpeningBalances = () => {
  return {
    type: 'CLEAR_CUSTOMER_OPENING_BALANCES_LIST',
  };
};

export const getCustomerOpeningBalance = id => async dispatch => {
  try {
    const { data } = await Api.get(`/customers/openingbalances/${id}`);
    dispatch({
      type: 'FETCH_CUSTOMER_OPENING_BALANCE',
      payload: data,
    });
  } catch (err) {
    throw err;
  }
};

export const clearCustomerOpeningBalance = () => {
  return {
    type: 'CLEAR_CUSTOMER_OPENING_BALANCE',
  };
};

export const getCustomerNotes = id => async dispatch => {
  try {
    const { data } = await Api.get(`/customers/${id}/notes`);
    dispatch({
      type: 'FETCH_CUSTOMER_NOTES',
      payload: data,
    });
  } catch (err) {
    throw err;
  }
};

export const clearCustomerNotes = () => {
  return {
    type: 'CLEAR_CUSTOMER_NOTES',
  };
};

export const getCustomerWisePayables = query => async dispatch => {
  try {
    const { data } = await Api.get(`/customers/dues`, query);
    dispatch({
      type: 'FETCH_CUSTOMER_WISE_PAYABLES',
      payload: data,
    });
  } catch (err) {
    throw err;
  }
};

export const clearCustomerWisePayables = () => {
  return {
    type: 'CLEAR_CUSTOMER_WISE_PAYABLES',
  };
};

export const seriesList = (state = [], action) => {
  if (action.type === 'FETCH_BATCH_SERIES_LIST') {
    return action.payload;
  }
  if (action.type === 'CLEAR_BATCH_SERIES_LIST') {
    return [];
  }
  return state;
};

export const series = (state = {}, action) => {
  if (action.type === 'FETCH_BATCH_SERIES') {
    return action.payload;
  }
  if (action.type === 'CLEAR_BATCH_SERIES') {
    return {};
  }
  return state;
};

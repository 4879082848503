import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { Button, Col, Input, Modal, Row, Select } from 'antd';
import { CloseOutlined } from '@ant-design/icons';
import { Controller, useForm } from 'react-hook-form';
import { useDispatch, useSelector } from 'react-redux';
import Api from '../../Api';
import { errorNotification, successNotification } from '../../Snackbar';
import GetField from '../../Components/GetRHFField';
import { clearEmployeeList, getEmployeeList } from '../../Actions';

const AntInput = GetField(Input);

const AntSelect = GetField(Select);

const FillingModal = props => {
  const { recordId, hideModal, order } = props;
  const dispatch = useDispatch();

  const { handleSubmit, control } = useForm();

  const users = useSelector(state => state.employeeList);
  const [ConfirmLoading, SetConfirmLoading] = useState(false);

  useEffect(() => {
    dispatch(getEmployeeList());

    return () => {
      dispatch(clearEmployeeList());
    };
  }, []);

  const onSubmit = async formValues => {
    try {
      SetConfirmLoading(true);

      await Api.put(`/orders/${recordId}/endfilling`, formValues);

      successNotification('Filling details submitted');
      hideModal();
    } catch (error) {
      errorNotification(error);
      SetConfirmLoading(false);
    }
  };

  return (
    <Modal
      open
      maskClosable={false}
      okText="Close"
      onOk={hideModal}
      cancelButtonProps={{ style: { display: 'none' } }}
      width={800}
      style={{ top: 10 }}
      title={`Filling Details | ${order.id}`}
      closeIcon={<CloseOutlined onClick={hideModal} />}
    >
      <form onSubmit={handleSubmit(onSubmit)}>
        <Row gutter={[16, 0]}>
          <Col span={12}>
            <Controller
              name="total"
              control={control}
              render={({ field }) => (
                <AntInput
                  {...field}
                  placeholder={
                    order?.section === 'External' || order?.section === 'Liquid'
                      ? 'Enter total volume received'
                      : 'Enter total gross weight received'
                  }
                  label={
                    order?.section === 'External' || order?.section === 'Liquid'
                      ? 'Total Volume Received'
                      : 'Total Gross Weight Received'
                  }
                />
              )}
            />
          </Col>
          <Col span={12}>
            <Controller
              name="perUnit"
              control={control}
              render={({ field }) => (
                <AntInput
                  {...field}
                  placeholder={
                    order?.section === 'External' || order?.section === 'Liquid'
                      ? 'Enter volume per unit packaging'
                      : 'Enter weight per unit packaging'
                  }
                  label={
                    order?.section === 'External' || order?.section === 'Liquid'
                      ? 'Volume Per Unit Packaging'
                      : 'Weight Per Unit Packaging'
                  }
                />
              )}
            />
          </Col>
          <Col span={12}>
            <Controller
              name="usersInFilling"
              control={control}
              render={({ field }) => (
                <AntSelect
                  allowClear
                  required
                  mode="multiple"
                  label="Users in filling"
                  placeholder="Select user"
                  {...field}
                >
                  {users.map(el => (
                    <Select.Option key={el.id} value={el.id}>
                      {el.name}
                    </Select.Option>
                  ))}
                </AntSelect>
              )}
            />
          </Col>
          <Col span={12}>
            <Controller
              name="fillCheckedBy"
              control={control}
              render={({ field }) => (
                <AntSelect
                  allowClear
                  required
                  placeholder="Select user"
                  label={order?.section === 'Veterinary Tablet' ? 'Hardness Checked By' : 'Filling Checked By'}
                  {...field}
                >
                  {users.map(el => (
                    <Select.Option key={el.id} value={el.id}>
                      {el.name}
                    </Select.Option>
                  ))}
                </AntSelect>
              )}
            />
          </Col>
          {order?.section !== 'Veterinary Tablet' ? (
            <Col span={12}>
              <Controller
                name="sealingCheckedBy"
                control={control}
                render={({ field }) => (
                  <AntSelect allowClear required label="Sealing Checked By" placeholder="Select user" {...field}>
                    {users.map(el => (
                      <Select.Option key={el.id} value={el.id}>
                        {el.name}
                      </Select.Option>
                    ))}
                  </AntSelect>
                )}
              />
            </Col>
          ) : null}
          <Col span={12}>
            <Controller
              name="remarks"
              control={control}
              render={({ field }) => (
                <AntInput
                  allowClear
                  required
                  label="Remark"
                  placeholder="Remark"
                  {...field}
                  autoSize={{ minRows: 1, maxRows: 6 }}
                />
              )}
            />
          </Col>
        </Row>

        <Button htmlType="submit" type="primary" loading={ConfirmLoading}>
          Submit
        </Button>
      </form>
    </Modal>
  );
};

FillingModal.propTypes = {
  recordId: PropTypes.number.isRequired,
  order: PropTypes.object.isRequired,
  hideModal: PropTypes.func.isRequired,
};

export default FillingModal;

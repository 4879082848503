import Api from '../Api';

export const getMaterialSaleList = () => async dispatch => {
  try {
    const { data } = await Api.get('/material-sales');
    dispatch({
      type: 'FETCH_MATERIAL_SALE_LIST',
      payload: data,
    });
  } catch (err) {
    throw err;
  }
};

export const clearMaterialSaleList = () => {
  return {
    type: 'CLEAR_MATERIAL_SALE_LIST',
  };
};

export const getMaterialSaleFilesList = id => async dispatch => {
  try {
    const { data } = await Api.get(`/material-sales/${id}/files`);
    dispatch({
      type: 'FETCH_MATERIAL_SALE_FILES_LIST',
      payload: data,
    });
  } catch (err) {
    throw err;
  }
};

export const clearMaterialSaleFilesList = () => {
  return {
    type: 'CLEAR_MATERIAL_SALE_FILES_LIST',
  };
};

export const getMaterialSale = id => async dispatch => {
  try {
    const { data } = await Api.get(`/material-sales/${id}`);
    dispatch({
      type: 'FETCH_MATERIAL_SALE',
      payload: data,
    });
  } catch (err) {
    throw err;
  }
};

export const clearMaterialSale = () => {
  return {
    type: 'CLEAR_MATERIAL_SALE',
  };
};

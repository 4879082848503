import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import { Table, Typography } from 'antd';
import withStyles from 'react-jss';
import { getBankList, clearBankList } from '../Actions';
import Heading from '../Components/Heading';
import { sorter, onFilter, filterDropdown } from '../utils';
import Structure from '../Structure/Structure';
import styles from '../CommonStyles';

const { Text } = Typography;

const BankList = props => {
  const { classes } = props;
  const dispatch = useDispatch();

  const bankList = useSelector(state => state.bankList);

  const [rowCounts, setRowCounts] = useState(0);

  useEffect(() => {
    dispatch(getBankList());
    return () => {
      dispatch(clearBankList());
    };
  }, []);

  useEffect(() => {
    if (bankList) {
      setRowCounts(bankList.length);
    }
  }, [bankList]);

  const columns = [
    {
      title: 'Consignor',
      dataIndex: 'consignor',
      filterDropdown,
      sorter: sorter('consignor'),
      onFilter: onFilter('consignor'),
    },
    {
      title: 'Bank',
      dataIndex: 'bank',
      filterDropdown,
      sorter: sorter('bank'),
      onFilter: onFilter('bank'),
    },
    {
      title: 'Current Balance',
      dataIndex: 'balance',
      filterDropdown,
      sorter: sorter('balance'),
      onFilter: onFilter('balance'),
      align: 'right',
      render: balance => Number(balance).toLocaleString('en-IN'),
    },
  ];

  return (
    <Structure>
      <>
        <Heading text={`Bank List (${rowCounts})`} />
        <Table
          columns={columns}
          bordered
          rowKey={record => record.id}
          pagination={{ defaultPageSize: 20, position: ['bottomLeft'] }}
          dataSource={bankList}
          size="small"
          scroll={{ x: 1050, y: window.innerHeight - 300 }}
          onChange={(_page, _filters, _sorter, { currentDataSource }) => {
            setRowCounts((currentDataSource || []).length);
          }}
          summary={pageData => {
            const totalbalance = pageData.reduce((acc, { balance }) => {
              return (acc += +balance);
            }, 0);
            return (
              <>
                <Table.Summary.Row>
                  <Table.Summary.Cell colSpan={2}>Total Current Balance</Table.Summary.Cell>
                  <Table.Summary.Cell className={classes.textAlignRight}>
                    <Text>{Number(totalbalance.toFixed(2)).toLocaleString('en-IN')}</Text>
                  </Table.Summary.Cell>
                </Table.Summary.Row>
              </>
            );
          }}
        />
      </>
    </Structure>
  );
};

BankList.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(BankList);

import Api from '../../Api';

export const getDosageFormList = () => async dispatch => {
  try {
    const { data } = await Api.get('/dropdowns/formulation-dosageforms');
    dispatch({
      type: 'FETCH_DOSAGEFORM_LIST',
      payload: data,
    });
  } catch (err) {
    throw err;
  }
};

export const clearDosageFormList = () => {
  return {
    type: 'CLEAR_DOSAGEFORM_LIST',
  };
};

export const getDosageForm = id => async dispatch => {
  try {
    const { data } = await Api.get(`/dropdowns/formulation-dosageforms/${id}`);
    dispatch({
      type: 'FETCH_DOSAGEFORM',
      payload: data,
    });
  } catch (err) {
    throw err;
  }
};

export const clearDosageForm = () => {
  return {
    type: 'CLEAR_DOSAGEFORM',
  };
};

export const attendanceList = (state = [], action) => {
  if (action.type === 'FETCH_ATTENDANCE_LIST') {
    return action.payload;
  }
  if (action.type === 'CLEAR_ATTENDANCE_LIST') {
    return [];
  }
  return state;
};

export const attendance = (state = {}, action) => {
  if (action.type === 'FETCH_ATTENDANCE') {
    return action.payload;
  }
  if (action.type === 'CLEAR_ATTENDANCE') {
    return {};
  }
  return state;
};

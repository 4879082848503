import Api from '../Api';

export const getEmployeeRoleList = () => async dispatch => {
  try {
    const { data } = await Api.get('/dropdowns/employee-role');
    dispatch({
      type: 'FETCH_EMPLOYEE_ROLE_LIST',
      payload: data,
    });
  } catch (err) {
    throw err;
  }
};

export const clearEmployeeRoleList = () => {
  return {
    type: 'CLEAR_EMPLOYEE_ROLE_LIST',
  };
};

export const getEmployeeRole = id => async dispatch => {
  try {
    const { data } = await Api.get(`/dropdowns/employee-role/${id}`);
    dispatch({
      type: 'FETCH_EMPLOYEE_ROLE',
      payload: data,
    });
  } catch (err) {
    throw err;
  }
};

export const clearEmployeeRole = () => {
  return {
    type: 'CLEAR_EMPLOYEE_ROLE',
  };
};

export const getEmployeeRoleIdList = () => async dispatch => {
  try {
    const { data } = await Api.get('/dropdowns/employee-role/ids');
    dispatch({
      type: 'FETCH_EMPLOYEE_ROLE_ID_LIST',
      payload: data,
    });
  } catch (err) {
    throw err;
  }
};

export const clearEmployeeRoleIdList = () => {
  return {
    type: 'CLEAR_EMPLOYEE_ROLE_ID_LIST',
  };
};

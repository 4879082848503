import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { reduxForm, Field, getFormValues } from 'redux-form';
import withStyles from 'react-jss';
import { Input, Button, Row, Modal, Select, Popconfirm, Col } from 'antd';
import { useHistory } from 'react-router';
import { connect, useDispatch, useSelector } from 'react-redux';
import Heading from '../Components/Heading';
import Api from '../Api';
import Structure from '../Structure/Structure';
import styles from '../CommonStyles';
import { successNotification, errorNotification } from '../Snackbar';
import LeftGrid from '../Components/LeftGrid';
import RightGrid from '../Components/RightGrid';
import GetField from '../Components/GetField';
import { states } from '../constants';
import {
  getCustomer,
  clearCustomer,
  getUserList,
  clearUserList,
  clearPaymentCategoryList,
  getPaymentCategoryList,
  getSubcategoryList,
  clearSubcategoryList,
} from '../Actions';
import { get } from '../utils';

const AntInput = GetField(Input);
const AntSelect = GetField(Select);

const EditCustomer = props => {
  const { handleSubmit, classes, match } = props;
  const history = useHistory();
  const dispatch = useDispatch();

  const userList = useSelector(state => state.userList);
  const paymentCategoryList = useSelector(state => state.paymentCategoryList);
  const subcategoryList = useSelector(state => state.subcategoryList);

  const [formValueModal, setFormValuesModal] = useState(false);
  const [confirmLoading, setConfirmLoading] = useState(false);
  const [preview, setPreview] = useState({});
  const FormValues = useSelector(state => getFormValues('EditCustomer')(state));

  useEffect(() => {
    dispatch(getSubcategoryList());
    dispatch(getUserList());
    dispatch(getPaymentCategoryList());
    dispatch(getCustomer(match.params.id));
    return () => {
      dispatch(clearSubcategoryList());
      dispatch(clearUserList());
      dispatch(clearPaymentCategoryList());
      dispatch(clearCustomer());
    };
  }, []);

  const showFormValuesModal = () => {
    const _preview = {
      name: get(FormValues, ['name'], '--'),
      subcategories: '--',
      gstNo: get(FormValues, ['gstNo'], '--'),
      state: get(FormValues, ['state'], '--'),
      billingAddress: get(FormValues, ['billingAddress'], '--'),
      corrAddress: get(FormValues, ['corrAddress'], '--'),
      contactPerson: get(FormValues, ['contactPerson'], '--'),
      primaryContact: get(FormValues, ['primaryContact'], '--'),
      contacts: get(FormValues, ['contacts'], '--'),
      primaryEmail: get(FormValues, ['primaryEmail'], '--'),
      emails: get(FormValues, ['emails'], '--'),
      paymentTerms: get(FormValues, ['paymentTerms'], '--'),
      handler: '--',
      paymentCategory: '--',
      remarks: get(FormValues, ['remarks'], '--'),
      status: get(FormValues, ['status'], '--'),
    };

    const subcategoryIds = get(FormValues, ['subcategoryIds'], '--');
    if (subcategoryIds?.length) {
      const subcategories = [];
      subcategoryIds.map(el => {
        const subcategoryObj = subcategoryList.find(category => category.id === +el);
        subcategories.push(subcategoryObj?.subcategory);
      });

      _preview.subcategories = subcategories;
    }

    const handlerId = get(FormValues, ['handlerId'], null);
    if (handlerId) {
      const userObj = userList.find(user => user.id === +handlerId);
      if (userObj) {
        _preview.handler = userObj.name;
      }
    }

    const paymentCategoryId = get(FormValues, ['paymentCategoryId'], null);
    if (paymentCategoryId) {
      const paymentCategoryObj = paymentCategoryList.find(category => category.id === +paymentCategoryId);
      if (paymentCategoryObj) {
        _preview.paymentCategory = paymentCategoryObj.category;
      }
    }

    setPreview(_preview);
    setFormValuesModal(true);
  };

  const hideFormValuesModal = () => {
    setFormValuesModal(false);
    setPreview({});
  };

  const onSubmit = async () => {
    try {
      setConfirmLoading(true);
      await Api.put(`/customers/${match.params.id}`, FormValues);
      successNotification('Customer updated');
      setConfirmLoading(false);
      hideFormValuesModal();
      history.push('/customer/list');
    } catch (err) {
      setConfirmLoading(false);
      errorNotification(err);
    }
  };

  return (
    <Structure>
      <>
        <Heading text="Edit Customer" />
        <form onSubmit={handleSubmit(onSubmit)}>
          <Row>
            <LeftGrid>
              <Field label="Name" required allowClear name="name" placeholder="Name" component={AntInput} />
            </LeftGrid>
            <RightGrid>
              <Field
                label="Subcategories"
                allowClear
                mode="multiple"
                required
                name="subcategoryIds"
                component={AntSelect}
              >
                <Select.Option key={null} value="" disabled>
                  Select subcategory
                </Select.Option>
                {subcategoryList.map(subcategory => (
                  <Select.Option key={subcategory.id} value={subcategory.id}>
                    {subcategory.subcategory}
                  </Select.Option>
                ))}
              </Field>
            </RightGrid>
          </Row>
          <Row>
            <LeftGrid>
              <Field
                label="GST Number"
                required
                allowClear
                name="gstNo"
                placeholder="GST Number"
                component={AntInput}
              />
            </LeftGrid>
            <RightGrid>
              <Field label="State" allowClear required name="state" component={AntSelect}>
                <Select.Option key={null} value="" disabled>
                  Select state
                </Select.Option>
                {states.map(state => (
                  <Select.Option key={state} value={state}>
                    {state}
                  </Select.Option>
                ))}
              </Field>
            </RightGrid>
          </Row>
          <Row>
            <LeftGrid>
              <Field
                label="Billing Address"
                required
                allowClear
                name="billingAddress"
                placeholder="Billing Address"
                component={AntInput}
              />
            </LeftGrid>
            <RightGrid>
              <Field
                label="Correspondence Address"
                allowClear
                name="corrAddress"
                placeholder="Correspondence Address"
                component={AntInput}
              />
            </RightGrid>
          </Row>
          <Row>
            <LeftGrid>
              <Field
                label="Contact Person"
                allowClear
                name="contactPerson"
                placeholder="Contact Person Name"
                component={AntInput}
              />
            </LeftGrid>
            <RightGrid>
              <Field
                label="Primary Contact"
                required
                allowClear
                name="primaryContact"
                placeholder="Primary Contact Number"
                component={AntInput}
              />
            </RightGrid>
          </Row>
          <Row>
            <LeftGrid>
              <Field
                label="Contacts"
                allowClear
                name="contacts"
                placeholder="Contact Numbers (seperated by comma)"
                component={AntInput}
              />
            </LeftGrid>
            <RightGrid>
              <Field
                label="Primary Email"
                required
                allowClear
                name="primaryEmail"
                placeholder="Primary Email"
                component={AntInput}
              />
            </RightGrid>
          </Row>
          <Row>
            <LeftGrid>
              <Field
                label="Emails"
                allowClear
                name="emails"
                placeholder="Emails (seperated by comma)"
                component={AntInput}
              />
            </LeftGrid>
            <RightGrid>
              <Field
                label="Payment Term"
                required
                type="number"
                min={0}
                allowClear
                name="paymentTerms"
                placeholder="Payment Term (in days)"
                component={AntInput}
              />
            </RightGrid>
          </Row>
          <Row>
            <LeftGrid>
              <Field label="Handler" allowClear required name="handlerId" component={AntSelect}>
                <Select.Option key={null} value="" disabled>
                  Select handler
                </Select.Option>
                {userList.map(user => (
                  <Select.Option key={user.id} value={user.id}>
                    {user.name}
                  </Select.Option>
                ))}
              </Field>
            </LeftGrid>
            <RightGrid>
              <Field label="Payment Category" allowClear required name="paymentCategoryId" component={AntSelect}>
                <Select.Option key={null} value="" disabled>
                  Select payment category
                </Select.Option>
                {paymentCategoryList.map(category => (
                  <Select.Option key={category.id} value={category.id}>
                    {category.category}
                  </Select.Option>
                ))}
              </Field>
            </RightGrid>
          </Row>
          <Row>
            <LeftGrid>
              <Field label="Status" allowClear required name="status" component={AntSelect}>
                <Select.Option key={null} value="" disabled>
                  Select status
                </Select.Option>
                <Select.Option key="active" value="Active">
                  Active
                </Select.Option>
                <Select.Option key="inactive" value="Inactive">
                  Inactive
                </Select.Option>
              </Field>
            </LeftGrid>
            <RightGrid>
              <Field label="Remarks" allowClear name="remarks" placeholder="Remarks" component={AntInput} />
            </RightGrid>
          </Row>
          <Row className={classes.buttonContainer}>
            <Button className={classes.button} type="primary" onClick={showFormValuesModal}>
              Preview
            </Button>
          </Row>
        </form>
        <Modal
          onCancel={hideFormValuesModal}
          open={formValueModal}
          confirmLoading={confirmLoading}
          centered
          width="800px"
          maskClosable={false}
          closable={false}
          cancelText="Back"
          okButtonProps={{ className: classes.button }}
          okText={
            <Popconfirm
              title="Sure want to submit form ?"
              onConfirm={() => onSubmit()}
              okText="Confirm"
              cancelText="Cancel"
            >
              Submit
            </Popconfirm>
          }
        >
          <Row>
            <Col xs={24} lg={12}>
              <p>
                Name:
                <span className={classes.detailColor}>{preview.name}</span>
              </p>
            </Col>
            <Col xs={24} lg={12}>
              <p>
                Subcategory:
                <span className={classes.detailColor}>{preview.subcategories?.join(', ')}</span>
              </p>
            </Col>
          </Row>
          <Row>
            <Col xs={24} lg={12}>
              <p>
                GST Number:
                <span className={classes.detailColor}>{preview.gstNo}</span>
              </p>
            </Col>
            <Col xs={24} lg={12}>
              <p>
                State:
                <span className={classes.detailColor}>{preview.state}</span>
              </p>
            </Col>
          </Row>
          <Row>
            <Col xs={24} lg={12}>
              <p>
                Billing Address:
                <span className={classes.detailColor}>{preview.billingAddress}</span>
              </p>
            </Col>
            <Col xs={24} lg={12}>
              <p>
                Correspondence Address:
                <span className={classes.detailColor}>{preview.corrAddress}</span>
              </p>
            </Col>
          </Row>
          <Row>
            <Col xs={24} lg={12}>
              <p>
                Contact Person:
                <span className={classes.detailColor}>{preview.contactPerson}</span>
              </p>
            </Col>
            <Col xs={24} lg={12}>
              <p>
                Primary Contact:
                <span className={classes.detailColor}>{preview.primaryContact}</span>
              </p>
            </Col>
          </Row>
          <Row>
            <Col xs={24} lg={12}>
              <p>
                Contacts:
                <span className={classes.detailColor}>{preview.contacts}</span>
              </p>
            </Col>
            <Col xs={24} lg={12}>
              <p>
                Primary Email:
                <span className={classes.detailColor}>{preview.primaryEmail}</span>
              </p>
            </Col>
          </Row>
          <Row>
            <Col xs={24} lg={12}>
              <p>
                Emails:
                <span className={classes.detailColor}>{preview.emails}</span>
              </p>
            </Col>
            <Col xs={24} lg={12}>
              <p>
                Payment Terms (in days):
                <span className={classes.detailColor}>{preview.paymentTerms}</span>
              </p>
            </Col>
          </Row>
          <Row>
            <Col xs={24} lg={12}>
              <p>
                Handler:
                <span className={classes.detailColor}>{preview.handler}</span>
              </p>
            </Col>
            <Col xs={24} lg={12}>
              <p>
                Payment Category:
                <span className={classes.detailColor}>{preview.paymentCategory}</span>
              </p>
            </Col>
          </Row>
          <Row>
            <Col xs={24} lg={12}>
              <p>
                Status:
                <span className={classes.detailColor}>{preview.status}</span>
              </p>
            </Col>
            <Col xs={24} lg={12}>
              <p>
                Remarks:
                <span className={classes.detailColor}>{preview.remarks}</span>
              </p>
            </Col>
          </Row>
        </Modal>
      </>
    </Structure>
  );
};

EditCustomer.propTypes = {
  handleSubmit: PropTypes.func.isRequired,
  classes: PropTypes.object.isRequired,
  match: PropTypes.object.isRequired,
};

const StyledEditCustomer = withStyles(styles)(EditCustomer);

const FormedEditCustomer = reduxForm({
  form: 'EditCustomer',
  enableReinitialize: true,
})(StyledEditCustomer);

export default connect(({ customer }) => ({ initialValues: customer }), {})(FormedEditCustomer);

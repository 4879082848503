import { combineReducers } from 'redux';
import { reducer as FormReducer } from 'redux-form';

import * as Authentication from './Authentication';
import * as User from './User';
import * as Role from './Role';
import * as Material from './Material';
import * as Unit from './Unit';
import * as HSNCode from './HSNCode';
import * as Transporter from './Transporter';
import * as Category from './formulations_dropdown/Category';
import * as Subcategory from './formulations_dropdown/Subcategory';
import * as DosageForm from './formulations_dropdown/DosageForm';
import * as Section from './formulations_dropdown/Section';
import * as Generic from './formulations_dropdown/Generic';
import * as TimeMotion from './formulations_dropdown/TimeMotion';
import * as Formulation from './Formulation';
import * as Supplier from './Supplier';
import * as Customer from './Customer';
import * as Order from './Order';
import * as MaterialInventory from './MaterialInventory';
import * as AdjustedStock from './AdjustedStock';
import * as PurchaseOrder from './PurchaseOrder';
import * as ItemsToOrder from './ItemsToOrder';
import * as Purchase from './Purchase';
import * as Batch from './Batch';
import * as Sale from './Sale';
import * as MaterialSale from './MaterialSale';
import * as SaleReturn from './SaleReturn';
import * as Courier from './Courier';
import * as Narration from './Narration';
import * as Financial from './Financial';
import * as Reports from './Reports';
import * as Calendar from './Calendar';
import * as Files from './Files';
import * as MIS from './MIS';
import * as Enquiry from './Enquiry';
import * as Ticket from './Ticket';
import * as Quotation from './Quotation';
import * as CreditNote from './CreditNote';
import * as DebitNote from './DebitNote';
import * as CredDebitNote from './CredDebitNote';
import * as EmployeeRole from './EmployeeRole';
import * as Employee from './Employee';
import * as Attendance from './Attendance';
import * as Leave from './Leave';
import * as Holiday from './Holiday';
import * as whatsapp from './whatsapp';
import * as patient from './patient';
import * as FormulationInventory from './FormulationInventory';
import * as Charges from './charges';
import * as Lead from './Lead';
import * as proformaInvoice from './proforma_invoice';
import * as tasks from './tasks';
import * as PaymentCategory from './PaymentCategory';
import * as Location from './Location';
import * as LedgerContact from './LedgerContact';
import * as Asset from './Asset';
import * as BatchSeries from './BatchSeries';
import * as jobworks from './jobworks';

export default combineReducers({
  form: FormReducer,
  ...Authentication,
  ...User,
  ...Role,
  ...Material,
  ...Unit,
  ...HSNCode,
  ...Transporter,
  ...Category,
  ...Subcategory,
  ...DosageForm,
  ...Section,
  ...Generic,
  ...TimeMotion,
  ...Formulation,
  ...Supplier,
  ...Customer,
  ...Order,
  ...MaterialInventory,
  ...AdjustedStock,
  ...PurchaseOrder,
  ...ItemsToOrder,
  ...Purchase,
  ...Batch,
  ...Sale,
  ...MaterialSale,
  ...SaleReturn,
  ...Courier,
  ...Narration,
  ...Financial,
  ...Reports,
  ...Calendar,
  ...Files,
  ...MIS,
  ...Enquiry,
  ...Ticket,
  ...Quotation,
  ...CreditNote,
  ...DebitNote,
  ...CredDebitNote,
  ...EmployeeRole,
  ...Employee,
  ...Attendance,
  ...Leave,
  ...Holiday,
  ...whatsapp,
  ...patient,
  ...FormulationInventory,
  ...Charges,
  ...Lead,
  ...proformaInvoice,
  ...tasks,
  ...PaymentCategory,
  ...Location,
  ...LedgerContact,
  ...Asset,
  ...BatchSeries,
  ...jobworks,
});

import Api from '../Api';

export const getHolidays = () => async dispatch => {
  try {
    const { data } = await Api.get('/holidays');
    dispatch({
      type: 'FETCH_HOLIDAYS',
      payload: data,
    });
  } catch (err) {
    throw err;
  }
};

export const clearHolidays = () => {
  return {
    type: 'CLEAR_HOLIDAYS',
  };
};

import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { reduxForm, Field, getFormValues } from 'redux-form';
import withStyles from 'react-jss';
import { Input, Button, Row, Modal, Col, Popconfirm, Select } from 'antd';
import { useHistory } from 'react-router';
import { connect, useDispatch, useSelector } from 'react-redux';
import Heading from '../Components/Heading';
import Api from '../Api';
import Structure from '../Structure/Structure';
import styles from '../CommonStyles';
import { successNotification, errorNotification } from '../Snackbar';
import LeftGrid from '../Components/LeftGrid';
import RightGrid from '../Components/RightGrid';
import GetField from '../Components/GetField';
import { get } from '../utils';
import { clearAsset, getAsset } from '../Actions';
import constants from '../constants';

const AntInput = GetField(Input);
const AntSelect = GetField(Select);

const Edit = props => {
  const { handleSubmit, classes, match } = props;
  const history = useHistory();
  const dispatch = useDispatch();

  const [formValueModal, setFormValuesModal] = useState(false);
  const [confirmLoading, setConfirmLoading] = useState(false);
  const [preview, setPreview] = useState({});
  const FormValues = useSelector(state => getFormValues('EditAsset')(state));

  useEffect(() => {
    dispatch(getAsset(match.params.id));
    return () => {
      dispatch(clearAsset());
    };
  }, []);

  const showFormValuesModal = () => {
    const _preview = {
      name: get(FormValues, ['name'], '--'),
      description: get(FormValues, ['description'], '--'),
      consignor: get(FormValues, ['consignor'], '--'),
      serialNo: get(FormValues, ['serialNo'], '--'),
      value: get(FormValues, ['value'], '--'),
      remarks: get(FormValues, ['remarks'], '--'),
    };

    setPreview(_preview);
    setFormValuesModal(true);
  };

  const hideFormValuesModal = () => {
    setFormValuesModal(false);
    setPreview({});
  };

  const onSubmit = async () => {
    try {
      setConfirmLoading(true);
      await Api.put(`/assets/${match.params.id}`, FormValues);
      successNotification('Asset edited');
      setConfirmLoading(false);
      hideFormValuesModal();
      history.push('/assets/list');
    } catch (err) {
      setConfirmLoading(false);
      errorNotification(err);
    }
  };

  return (
    <Structure>
      <>
        <Heading text="Edit Asset" />
        <form onSubmit={handleSubmit(onSubmit)}>
          <Row>
            <LeftGrid>
              <Field label="Consignor" allowClear required name="consignor" component={AntSelect}>
                <Select.Option key={null} value="" disabled>
                  Select consignor
                </Select.Option>
                {Object.values(constants.consignors.list).map(consignor => (
                  <Select.Option key={consignor} value={consignor}>
                    {consignor}
                  </Select.Option>
                ))}
              </Field>
            </LeftGrid>
            <RightGrid>
              <Field label="Name" required allowClear name="name" placeholder="Name" component={AntInput} />
            </RightGrid>
          </Row>
          <Row>
            <LeftGrid>
              <Field
                label="Description"
                required
                allowClear
                name="description"
                placeholder="Description"
                component={AntInput}
              />
            </LeftGrid>
            <RightGrid>
              <Field label="Serial #" required allowClear name="serialNo" placeholder="Serial #" component={AntInput} />
            </RightGrid>
          </Row>
          <Row>
            <LeftGrid>
              <Field
                label="Value"
                allowClear
                type="number"
                min={0}
                name="value"
                placeholder="Value"
                component={AntInput}
              />
            </LeftGrid>
            <RightGrid>
              <Field label="Remarks" allowClear name="remarks" placeholder="Remarks" component={AntInput} />
            </RightGrid>
          </Row>
          <Row className={classes.buttonContainer}>
            <Button className={classes.button} type="primary" onClick={showFormValuesModal}>
              Preview
            </Button>
          </Row>
        </form>
        <Modal
          onCancel={hideFormValuesModal}
          open={formValueModal}
          confirmLoading={confirmLoading}
          centered
          width="800px"
          maskClosable={false}
          closable={false}
          cancelText="Back"
          okButtonProps={{ className: classes.button }}
          okText={
            <Popconfirm
              title="Sure want to submit form ?"
              onConfirm={() => onSubmit()}
              okText="Confirm"
              cancelText="Cancel"
            >
              Submit
            </Popconfirm>
          }
        >
          <Row>
            <Col xs={24} lg={12}>
              <p>
                Consignor:
                <span className={classes.detailColor}>{preview.consignor}</span>
              </p>
            </Col>
            <Col xs={24} lg={12}>
              <p>
                Name:
                <span className={classes.detailColor}>{preview.name}</span>
              </p>
            </Col>
            <Col xs={24} lg={12}>
              <p>
                Description:
                <span className={classes.detailColor}>{preview.description}</span>
              </p>
            </Col>
          </Row>
          <Row>
            <Col xs={24} lg={12}>
              <p>
                Serial #:
                <span className={classes.detailColor}>{preview.serialNo}</span>
              </p>
            </Col>
            <Col xs={24} lg={12}>
              <p>
                Value:
                <span className={classes.detailColor}>{preview.value}</span>
              </p>
            </Col>
          </Row>
          <Row>
            <Col xs={24} lg={12}>
              <p>
                Remarks:
                <span className={classes.detailColor}>{preview.remarks}</span>
              </p>
            </Col>
          </Row>
        </Modal>
      </>
    </Structure>
  );
};

Edit.propTypes = {
  handleSubmit: PropTypes.func.isRequired,
  classes: PropTypes.object.isRequired,
  match: PropTypes.object.isRequired,
};

const StyledEdit = withStyles(styles)(Edit);

const FormedEdit = reduxForm({
  form: 'EditAsset',
  enableReinitialize: true,
})(StyledEdit);

export default connect(({ asset }) => ({ initialValues: asset }), {})(FormedEdit);

import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { Button, Col, Input, Modal, Row, Select } from 'antd';
import { CloseOutlined } from '@ant-design/icons';
import { Controller, useForm } from 'react-hook-form';
import { useDispatch, useSelector } from 'react-redux';
import Api from '../../Api';
import { errorNotification, successNotification } from '../../Snackbar';
import GetField from '../../Components/GetRHFField';
import { clearEmployeeList, getEmployeeList } from '../../Actions';

const AntInput = GetField(Input);

const AntSelect = GetField(Select);

const ManufacturingModal = props => {
  const { recordId, hideModal, order } = props;
  const dispatch = useDispatch();

  const { handleSubmit, control } = useForm();

  const users = useSelector(state => state.employeeList);
  const [ConfirmLoading, SetConfirmLoading] = useState(false);

  useEffect(() => {
    dispatch(getEmployeeList());

    return () => {
      dispatch(clearEmployeeList());
    };
  }, []);

  const onSubmit = async formValues => {
    try {
      SetConfirmLoading(true);

      await Api.put(`/orders/${recordId}/endmanufacturing`, formValues);

      successNotification('Manufacturing details submitted');
      hideModal();
    } catch (error) {
      errorNotification(error);
      SetConfirmLoading(false);
    }
  };

  return (
    <Modal
      open
      maskClosable={false}
      okText="Close"
      onOk={hideModal}
      cancelButtonProps={{ style: { display: 'none' } }}
      width={800}
      style={{ top: 10 }}
      title={`Manufacturing Details | ${order.id}`}
      closeIcon={<CloseOutlined onClick={hideModal} />}
    >
      <form onSubmit={handleSubmit(onSubmit)}>
        <Row gutter={[16, 0]}>
          <Col span={12}>
            <Controller
              name="weightVolume"
              control={control}
              render={({ field }) => (
                <AntInput
                  {...field}
                  placeholder={
                    order?.section === 'External' || order?.section === 'Liquid' ? 'Enter volume' : 'Enter Weight'
                  }
                  label={order?.section === 'External' || order?.section === 'Liquid' ? 'Volume' : 'Gross Weight'}
                />
              )}
            />
          </Col>
          {order?.section !== 'Capsule' ? (
            <Col span={12}>
              <Controller
                name="color"
                control={control}
                render={({ field }) => <AntInput {...field} placeholder="Enter color" label="Color" />}
              />
            </Col>
          ) : null}
          {order?.section === 'Sachet' || order?.section === 'Capsule' || order?.section === 'Veterinary Sachet' ? (
            <Col span={12}>
              <Controller
                name="flow"
                control={control}
                render={({ field }) => <AntInput {...field} placeholder="Enter flow" label="Flow" />}
              />
            </Col>
          ) : null}
          {order?.section !== 'Veterinary Tablet' ||
          order?.section !== 'Veterinary Powder' ||
          order?.section !== 'Capsule' ? (
            <Col span={12}>
              <Controller
                name="flavourFragrance"
                control={control}
                render={({ field }) => (
                  <AntInput
                    {...field}
                    placeholder={order?.section === 'External' ? 'Enter fragrance' : 'Enter flavour'}
                    label={order?.section === 'External' ? 'Fragrance' : 'Flavour'}
                  />
                )}
              />
            </Col>
          ) : null}
          {order?.section === 'External' || order?.section === 'Liquid' ? (
            <Col span={12}>
              <Controller
                name="pH"
                control={control}
                render={({ field }) => <AntInput {...field} placeholder="Enter pH" label="pH" />}
              />
            </Col>
          ) : null}
          {order?.section === 'External' || order?.section === 'Liquid' ? (
            <Col span={12}>
              <Controller
                name="viscosity"
                control={control}
                render={({ field }) => <AntInput {...field} placeholder="Enter viscosity" label="Viscosity" />}
              />
            </Col>
          ) : null}
          {order?.section === 'External' || order?.section === 'Liquid' ? (
            <Col span={12}>
              <Controller
                name="gaze"
                control={control}
                render={({ field }) => <AntInput {...field} placeholder="Enter gaze" label="Gaze" />}
              />
            </Col>
          ) : null}
          <Col span={12}>
            <Controller
              name="usersInManufacturing"
              control={control}
              render={({ field }) => (
                <AntSelect
                  allowClear
                  required
                  mode="multiple"
                  label="Users in Manufacturing"
                  placeholder="Select user"
                  {...field}
                >
                  {users.map(el => (
                    <Select.Option key={el.id} value={el.id}>
                      {el.name}
                    </Select.Option>
                  ))}
                </AntSelect>
              )}
            />
          </Col>
          <Col span={12}>
            <Controller
              name="remarks"
              control={control}
              render={({ field }) => (
                <AntInput
                  allowClear
                  required
                  label="Remark"
                  placeholder="Remark"
                  {...field}
                  autoSize={{ minRows: 1, maxRows: 6 }}
                />
              )}
            />
          </Col>
        </Row>

        <Button htmlType="submit" type="primary" loading={ConfirmLoading}>
          Submit
        </Button>
      </form>
    </Modal>
  );
};

ManufacturingModal.propTypes = {
  recordId: PropTypes.number.isRequired,
  order: PropTypes.object.isRequired,
  hideModal: PropTypes.func.isRequired,
};

export default ManufacturingModal;

/* eslint-disable no-unused-vars */
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import PropTypes from 'prop-types';
import {
  Descriptions,
  Tabs,
  Button,
  Timeline,
  Tooltip,
  Comment,
  Row,
  Col,
  Popconfirm,
  Collapse,
  Table,
  List,
} from 'antd';
import { useDispatch, useSelector } from 'react-redux';
import moment from 'moment';
import {
  CloudUploadOutlined,
  CommentOutlined,
  DeleteOutlined,
  EditOutlined,
  FileTextOutlined,
  PlusOutlined,
} from '@ant-design/icons';

import { Link } from 'react-router-dom';
import withStyles from 'react-jss';
import { getLead, clearLead, clearProformaInvoices, getProformaInvoices, clearAssetList } from '../../Actions';
import { errorNotification, successNotification } from '../../Snackbar';
import Api from '../../Api';
import Structure from '../../Structure/Structure';
// import Interaction from './Interaction';
// import Followup from './Followup';
// import SetOutcome from './SetOutcome';
import { get, onFilter, sorter, filterDropdown, dateSorter, onDateFilter, dateFilterDropdown } from '../../utils';
import styles from '../../CommonStyles';
import LeadQueryModal from './LeadQueryModal';
import QueryCommentModal from './Query-Comment-Modal';
import QueryFileModal from './Query-File-Modal';

const { TabPane } = Tabs;
const { Panel } = Collapse;

const Detail = props => {
  const { classes, match } = props;

  const dispatch = useDispatch();

  const auth = useSelector(state => state.auth);
  const role = get(auth, ['user', 'role'], 'team');

  const [outcomeModal, setOutcomeModal] = useState(false);
  const [followUpId, setFollowUpId] = useState(null);
  const [followUpType, setFollowUpType] = useState(null);

  const [ModalType, SetModalType] = useState(null);
  const [QueryId, SetQueryId] = useState(null);

  const lead = useSelector(state => state.lead);
  const proformaInvoices = useSelector(state => state.proformaInvoices);

  const Query = useMemo(() => lead?.queries?.find(el => el.id === QueryId), [QueryId, lead]);

  useEffect(() => {
    dispatch(getLead(match.params.id));

    return () => {
      dispatch(clearLead());
    };
  }, []);

  const showModal = useCallback((type, id) => {
    SetModalType(type);
    SetQueryId(id);
  }, []);

  const hideModal = useCallback(() => {
    SetModalType(null);
    SetQueryId(null);
    dispatch(getLead(match.params.id));
  }, []);

  const showOutcomeModal = useCallback((id, type) => {
    setOutcomeModal(true);
    setFollowUpId(id);
    setFollowUpType(type);
  }, []);

  const hideOutcomeModal = useCallback(() => {
    setOutcomeModal(false);
    setFollowUpId(null);
    setFollowUpType(null);
    dispatch(getLead(match.params.id));
  }, []);

  const deleteQuery = useCallback(async id => {
    try {
      await Api.delete(`/leads/queries/${id}`);
      successNotification('Query deleted');
      dispatch(getLead(match.params.id));
    } catch (err) {
      errorNotification(err);
    }
  });

  const deleteQueryRemark = useCallback(async id => {
    try {
      await Api.delete(`/leads/queries/remarks/${id}`);
      successNotification('Remark deleted');
      dispatch(getLead(match.params.id));
    } catch (err) {
      errorNotification(err);
    }
  });

  const deleteInteraction = useCallback(async id => {
    try {
      await Api.delete(`/leads/interactions/${id}`);
      successNotification('Interaction/Follow-up deleted');
      dispatch(getLead(match.params.id));
    } catch (err) {
      errorNotification(err);
    }
  });

  const fetchTabData = useCallback(key => {
    if (key === 'Proforma_Invoice') {
      dispatch(clearProformaInvoices());
      dispatch(getProformaInvoices({ leadId: match.params.id }));
    }
  }, []);

  const columns = useMemo(() => {
    const _columns = [
      {
        title: 'PI #',
        dataIndex: 'piNo',
        sorter: sorter('piNo'),
        onFilter: onFilter('piNo'),
        filterDropdown,
        width: '8%',
      },
      {
        title: 'Date',
        dataIndex: 'createdAt',
        sorter: dateSorter('createdAt'),
        onFilter: onDateFilter('createdAt'),
        filterDropdown: dateFilterDropdown,
        render: date => (date ? moment(date).format('DD MMM YYYY') : null),
        width: '8%',
      },
      {
        title: 'Query',
        dataIndex: 'query',
        sorter: sorter('query'),
        onFilter: onFilter('query'),
        filterDropdown,
        width: '12%',
      },
      {
        title: 'Products',
        dataIndex: 'products',
        sorter: sorter('products'),
        onFilter: onFilter('products'),
        filterDropdown,
        render: text => (text.length ? text.join(', ') : '-'),
      },
      {
        title: 'Grand Total',
        dataIndex: 'grandTotal',
        render: text => Number(text).toLocaleString('en-IN'),
        width: '8%',
      },
      {
        title: 'Remark',
        dataIndex: 'remark',
      },
      {
        title: 'Detail',
        key: 'Detail',
        width: '6%',
        render: (_text, record) => (
          <Tooltip placement="bottom" title="Detail">
            <Link to={`/proformainvoices/detail/${record.id}`}>
              <FileTextOutlined />
            </Link>
          </Tooltip>
        ),
      },
    ];

    return _columns;
  }, []);

  const rowKey = useCallback(record => record.id, []);
  const scroll = useMemo(() => ({ x: 1024, y: window.innerHeight - 300 }), []);

  return (
    <Structure>
      <>
        <Row>
          <Col
            sm={{ span: 22, offset: 1 }}
            md={{ span: 22, offset: 1 }}
            lg={{ span: 20, offset: 2 }}
            xl={{ span: 16, offset: 4 }}
          >
            <Descriptions column={{ xs: 1, sm: 1, md: 2, lg: 3, xl: 3 }} size="small" borderless="true">
              <Descriptions.Item label="Company">{lead.company}</Descriptions.Item>
              <Descriptions.Item label="Name">{lead.name}</Descriptions.Item>
              <Descriptions.Item label="Description">{lead.remark}</Descriptions.Item>
              <Descriptions.Item label="Source">{lead.source}</Descriptions.Item>
              <Descriptions.Item label="Email">{lead.email}</Descriptions.Item>
              <Descriptions.Item label="Contact">{lead.contact}</Descriptions.Item>
              <Descriptions.Item label="GSTIN">{lead.gstNo}</Descriptions.Item>
              <Descriptions.Item label="Designation">{lead.designation}</Descriptions.Item>
              <Descriptions.Item label="Address">{lead.address}</Descriptions.Item>
              <Descriptions.Item label="City">{lead.city}</Descriptions.Item>
              <Descriptions.Item label="State">{lead.state}</Descriptions.Item>
              <Descriptions.Item label="Country">{lead.country}</Descriptions.Item>
              <Descriptions.Item label="PostalCode">{lead.postalCode}</Descriptions.Item>
              <Descriptions.Item label="Website">{lead.website}</Descriptions.Item>
              {/* <Descriptions.Item label="Status">{lead.status}</Descriptions.Item> */}
              <Descriptions.Item label="Date">{moment(lead.createdAt).format('DD MMM YY hh:mm A')}</Descriptions.Item>
            </Descriptions>
          </Col>
        </Row>
        <br />
        <br />
        <Row>
          <Col
            sm={{ span: 22, offset: 1 }}
            md={{ span: 22, offset: 1 }}
            lg={{ span: 20, offset: 2 }}
            xl={{ span: 20, offset: 2 }}
          >
            <Tabs defaultActiveKey="Queries" centered onChange={fetchTabData}>
              <TabPane tab="Queries" key="Queries">
                <Button type="primary" onClick={() => showModal('Add_Query_Modal')}>
                  Add Query
                </Button>
                <br />
                <Timeline>
                  {(lead.queries || []).map((query, index) => (
                    <>
                      <Timeline.Item key={query.id} dot={<CommentOutlined className={classes.commentIcon} />}>
                        <Comment
                          actions={
                            role === 'Owner'
                              ? [
                                  <Tooltip placement="bottom" title="Edit">
                                    <EditOutlined onClick={() => showModal('Add_Query_Modal', query.id)} />
                                  </Tooltip>,
                                  <Tooltip placement="bottom" title="Delete">
                                    <Popconfirm
                                      title="Are you sure you want to delete?"
                                      onConfirm={() => deleteQuery(query.id)}
                                      okText="Yes"
                                      cancelText="No"
                                    >
                                      <DeleteOutlined />
                                    </Popconfirm>
                                  </Tooltip>,
                                ]
                              : null
                          }
                          author={query.createdBy}
                          content={
                            <Collapse expandIconPosition="end" ghost destroyInactivePanel>
                              <Panel header={query.query} key={query.id}>
                                <Button onClick={() => showModal('Add_Query_Remark', query.id)}>
                                  <PlusOutlined /> Remark
                                </Button>
                                &nbsp;&nbsp;&nbsp;&nbsp;
                                <Button
                                  type="primary"
                                  icon={<CloudUploadOutlined />}
                                  onClick={() => showModal('QueryFileModal', query.id)}
                                >
                                  Files
                                </Button>
                                <br />
                                <br />
                                <List
                                  size="small"
                                  header={false}
                                  footer={false}
                                  borderless="true"
                                  dataSource={query?.query_remarks || []}
                                  renderItem={item => (
                                    <List.Item
                                      actions={[
                                        <Tooltip placement="bottom" title="Delete">
                                          <Popconfirm
                                            title="Are you sure you want to delete?"
                                            onConfirm={() => deleteQueryRemark(item.id)}
                                            okText="Yes"
                                            cancelText="No"
                                          >
                                            <DeleteOutlined />
                                          </Popconfirm>
                                        </Tooltip>,
                                      ]}
                                    >
                                      <List.Item.Meta
                                        title={
                                          <span>
                                            {item.createdBy} | {moment(item.createdAt).fromNow()}
                                          </span>
                                        }
                                        description={<span>Assigned To: {item.assignedTo}</span>}
                                      />
                                      {item.remark}
                                    </List.Item>
                                  )}
                                />
                              </Panel>
                            </Collapse>
                          }
                          datetime={
                            <Tooltip title={moment(query.createdAt).format('YYYY-MM-DD HH:mm:ss')}>
                              <span>{moment(query.createdAt).fromNow()}</span>
                            </Tooltip>
                          }
                        />
                      </Timeline.Item>
                    </>
                  ))}
                </Timeline>
              </TabPane>
              <TabPane tab="Proforma Invoice" key="Proforma_Invoice">
                <Table
                  bordered
                  size="small"
                  columns={columns}
                  dataSource={proformaInvoices}
                  rowKey={rowKey}
                  pagination={false}
                  scroll={scroll}
                />
              </TabPane>
            </Tabs>
          </Col>
        </Row>
        {ModalType === 'Add_Query_Modal' ? (
          <LeadQueryModal query={Query} leadId={match.params.id} modalType={ModalType} hideModal={hideModal} />
        ) : null}
        {ModalType === 'Add_Query_Remark' ? <QueryCommentModal query={Query} hideModal={hideModal} /> : null}
        {ModalType === 'QueryFileModal' && <QueryFileModal query={Query} hideModal={hideModal} />}
      </>
    </Structure>
  );
};

Detail.propTypes = {
  classes: PropTypes.object.isRequired,
  match: PropTypes.object.isRequired,
};

export default withStyles(styles)(Detail);

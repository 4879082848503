import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { reduxForm, Field, getFormValues } from 'redux-form';
import withStyles from 'react-jss';
import { Input, Button, Row, Modal, Col, Popconfirm, Select } from 'antd';
import { useHistory } from 'react-router';
import { useDispatch, useSelector } from 'react-redux';
import Heading from '../Components/Heading';
import Api from '../Api';
import Structure from '../Structure/Structure';
import styles from '../CommonStyles';
import { successNotification, errorNotification } from '../Snackbar';
import LeftGrid from '../Components/LeftGrid';
import RightGrid from '../Components/RightGrid';
import GetField from '../Components/GetField';
import { get } from '../utils';
import { getAssetIdList, clearAssetIdList } from '../Actions';
import constants from '../constants';

const AntInput = GetField(Input);
const AntSelect = GetField(Select);

const AdjustmentForm = props => {
  const { handleSubmit, classes } = props;
  const history = useHistory();
  const dispatch = useDispatch();

  const [formValueModal, setFormValuesModal] = useState(false);
  const [confirmLoading, setConfirmLoading] = useState(false);
  const [preview, setPreview] = useState({});
  const FormValues = useSelector(state => getFormValues('AssetAdjustmentForm')(state));

  const assetIdList = useSelector(state => state.assetIdList);

  useEffect(() => {
    dispatch(getAssetIdList());
    return () => {
      dispatch(clearAssetIdList());
    };
  }, []);

  const showFormValuesModal = () => {
    const _preview = {
      type: get(FormValues, ['type'], '--'),
      name: '--',
      value: get(FormValues, ['value'], '--'),
    };

    const assetId = get(FormValues, ['assetId'], null);
    if (assetId) {
      const assetObj = assetIdList.find(asset => asset.id === +assetId);
      if (assetObj) {
        _preview.name = assetObj.name;
      }
    }

    setPreview(_preview);
    setFormValuesModal(true);
  };

  const hideFormValuesModal = () => {
    setFormValuesModal(false);
    setPreview({});
  };

  const onSubmit = async () => {
    try {
      setConfirmLoading(true);
      await Api.post('/assets/adjustment', FormValues);
      successNotification('Asset added');
      setConfirmLoading(false);
      hideFormValuesModal();
      history.push('/assets/adjustment/list');
    } catch (err) {
      setConfirmLoading(false);
      errorNotification(err);
    }
  };

  return (
    <Structure>
      <>
        <Heading text="Asset Adjustment Form" />
        <form onSubmit={handleSubmit(onSubmit)}>
          <Row>
            <LeftGrid>
              <Field label="Adjustment Type" allowClear required name="type" component={AntSelect}>
                <Select.Option key={null} value="" disabled>
                  Select type
                </Select.Option>
                {Object.values(constants.assets.adjustments).map(adj => (
                  <Select.Option key={adj} value={adj}>
                    {adj}
                  </Select.Option>
                ))}
              </Field>
            </LeftGrid>
            <RightGrid>
              <Field label="Asset" allowClear required name="assetId" component={AntSelect}>
                <Select.Option key={null} value="" disabled>
                  Select asset
                </Select.Option>
                {assetIdList.map(asset => (
                  <Select.Option key={asset.id} value={asset.id}>
                    {asset.name}
                  </Select.Option>
                ))}
              </Field>
            </RightGrid>
          </Row>
          <Row>
            <LeftGrid>
              <Field
                label="Value"
                allowClear
                type="number"
                min={0}
                name="value"
                placeholder="Value"
                component={AntInput}
              />
            </LeftGrid>
          </Row>
          <Row className={classes.buttonContainer}>
            <Button className={classes.button} type="primary" onClick={showFormValuesModal}>
              Preview
            </Button>
          </Row>
        </form>
        <Modal
          onCancel={hideFormValuesModal}
          open={formValueModal}
          confirmLoading={confirmLoading}
          centered
          width="800px"
          maskClosable={false}
          closable={false}
          cancelText="Back"
          okButtonProps={{ className: classes.button }}
          okText={
            <Popconfirm
              title="Sure want to submit form ?"
              onConfirm={() => onSubmit()}
              okText="Confirm"
              cancelText="Cancel"
            >
              Submit
            </Popconfirm>
          }
        >
          <Row>
            <Col xs={24} lg={12}>
              <p>
                Type:
                <span className={classes.detailColor}>{preview.type}</span>
              </p>
            </Col>
            <Col xs={24} lg={12}>
              <p>
                Asset:
                <span className={classes.detailColor}>{preview.name}</span>
              </p>
            </Col>
          </Row>
          <Row>
            <Col xs={24} lg={12}>
              <p>
                Value:
                <span className={classes.detailColor}>{preview.value}</span>
              </p>
            </Col>
          </Row>
        </Modal>
      </>
    </Structure>
  );
};

AdjustmentForm.propTypes = {
  handleSubmit: PropTypes.func.isRequired,
  classes: PropTypes.object.isRequired,
};

const StyledAdjustmentForm = withStyles(styles)(AdjustmentForm);

const FormedAdjustmentForm = reduxForm({
  form: 'AssetAdjustmentForm',
})(StyledAdjustmentForm);

export default FormedAdjustmentForm;

import Api from '../Api';

export const getBatchList = query => async dispatch => {
  try {
    const { data } = await Api.get('/orders/batchdetails', query);
    dispatch({
      type: 'FETCH_BATCH_LIST',
      payload: data,
    });
  } catch (err) {
    throw err;
  }
};

export const clearBatchList = () => {
  return {
    type: 'CLEAR_BATCH_LIST',
  };
};

export const getBatchDetail = id => async dispatch => {
  try {
    const { data } = await Api.get(`/orders/${id}/batchdetails/materials`);
    dispatch({
      type: 'FETCH_BATCH_DETAIL',
      payload: data,
    });
  } catch (err) {
    throw err;
  }
};

export const clearBatchDetail = () => {
  return {
    type: 'CLEAR_BATCH_DETAIL',
  };
};

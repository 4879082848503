export const assetList = (state = [], action) => {
  if (action.type === 'FETCH_ASSET_LIST') {
    return action.payload;
  }
  if (action.type === 'CLEAR_ASSET_LIST') {
    return [];
  }
  return state;
};

export const asset = (state = {}, action) => {
  if (action.type === 'FETCH_ASSET') {
    return action.payload;
  }
  if (action.type === 'CLEAR_ASSET') {
    return {};
  }
  return state;
};

export const assetIdList = (state = [], action) => {
  if (action.type === 'FETCH_ASSET_ID_LIST') {
    return action.payload;
  }
  if (action.type === 'CLEAR_ASSET_ID_LIST') {
    return [];
  }
  return state;
};

export const assetAdjustmentList = (state = [], action) => {
  if (action.type === 'FETCH_ASSET_ADJUSTMENT_LIST') {
    return action.payload;
  }
  if (action.type === 'CLEAR_ASSET_ADJUSTMENT_LIST') {
    return [];
  }
  return state;
};

export const assetValuesList = (state = [], action) => {
  if (action.type === 'FETCH_ASSET_VALUES') {
    return action.payload;
  }
  if (action.type === 'CLEAR_ASSET_VALUES') {
    return [];
  }
  return state;
};

import api from '../Api';

export const getTicketList = () => async dispatch => {
  try {
    const { data } = await api.get('/tickets');
    dispatch({
      type: 'FETCH_TICKET_LIST',
      payload: data,
    });
  } catch (err) {
    throw err;
  }
};

export const clearTicketList = () => {
  return {
    type: 'CLEAR_TICKET_LIST',
  };
};

export const getTicket = id => async dispatch => {
  try {
    const { data } = await api.get(`/tickets/${id}`);
    dispatch({
      type: 'FETCH_TICKET',
      payload: data,
    });
  } catch (err) {
    throw err;
  }
};

export const clearTicket = () => {
  return {
    type: 'CLEAR_TICKET',
  };
};

import React, { useCallback, useEffect, useMemo, useState } from 'react';
import PropTypes from 'prop-types';
import { Table, Tooltip } from 'antd';
import { useDispatch, useSelector } from 'react-redux';
import moment from 'moment';
import withStyles from 'react-jss';
import { CheckSquareOutlined, CloseSquareOutlined } from '@ant-design/icons';
import Structure from '../Structure/Structure';
import Heading from '../Components/Heading';
import { onFilter, sorter, filterDropdown, dateSorter, onDateFilter, dateFilterDropdown } from '../utils';
import styles from '../CommonStyles';
import { clearTaskExtensionRequests, getTaskExtensionRequests } from '../Actions';
import AcceptExtension from './Tasks/AcceptExtension';
import RejectExtension from './Tasks/RejectExtension';

const TaskExtensions = props => {
  const { classes } = props;

  const dispatch = useDispatch();

  const [modalType, setModaltype] = useState(null);
  const [recordData, setRecordData] = useState({});

  const { taskExtensions } = useSelector(state => state.taskExtensionRequests);

  useEffect(() => {
    dispatch(getTaskExtensionRequests());

    return () => {
      clearTaskExtensionRequests();
    };
  }, []);

  const showModal = (data, type) => {
    setRecordData(data);
    setModaltype(type);
  };

  const hideModal = () => {
    setRecordData(null);
    setModaltype(null);
    dispatch(getTaskExtensionRequests());
  };

  const approveTaskColumn = useCallback(
    (_text, record) =>
      !record.approvedAt && !record.rejectedAt ? (
        <Tooltip placement="bottom" title="Approve">
          <CheckSquareOutlined className={classes.tableIcon} onClick={() => showModal(record, 'approveTask')} />
        </Tooltip>
      ) : null,
    []
  );

  const rejectTaskColumn = useCallback(
    (_text, record) =>
      !record.approvedAt && !record.rejectedAt ? (
        <Tooltip placement="bottom" title="Reject">
          <CloseSquareOutlined className={classes.tableIcon} onClick={() => showModal(record, 'rejectTask')} />
        </Tooltip>
      ) : null,
    []
  );

  // const approveSubTaskColumn = useCallback(
  //   (_text, record) =>
  //     !record.approvedAt && !record.rejectedAt ? (
  //       <Tooltip placement="bottom" title="Approve">
  //         <CheckSquareOutlined className={classes.tableIcon} onClick={() => showModal(record, 'approveSubTask')} />
  //       </Tooltip>
  //     ) : null,
  //   []
  // );

  // const rejectSubTaskColumn = useCallback(
  //   (_text, record) =>
  //     !record.approvedAt && !record.rejectedAt ? (
  //       <Tooltip placement="bottom" title="Reject">
  //         <CloseSquareOutlined className={classes.tableIcon} onClick={() => showModal(record, 'rejectSubTask')} />
  //       </Tooltip>
  //     ) : null,
  //   []
  // );

  const taskTableColumns = useMemo(
    () => [
      {
        title: 'Task',
        dataIndex: 'taskName',
        sorter: sorter('taskName'),
        onFilter: onFilter('taskName'),
        filterDropdown,
      },
      {
        title: 'Description',
        dataIndex: 'taskDescription',
        sorter: sorter('taskDescription'),
        onFilter: onFilter('taskDescription'),
        filterDropdown,
      },
      {
        title: 'Requested By',
        dataIndex: 'requestedby',
        sorter: sorter('requestedby'),
        onFilter: onFilter('requestedby'),
        filterDropdown,
      },
      {
        title: 'Current Task End Date',
        dataIndex: 'taskEndAt',
        sorter: dateSorter('taskEndAt'),
        filterDropdown: dateFilterDropdown,
        onFilter: onDateFilter('taskEndAt'),
        render: taskEndAt => (taskEndAt ? moment(taskEndAt).format('DD MMM YYYY h:mm A') : null),
      },
      {
        title: 'Requested Task End Date',
        dataIndex: 'requestedEndAt',
        sorter: dateSorter('requestedEndAt'),
        filterDropdown: dateFilterDropdown,
        onFilter: onDateFilter('requestedEndAt'),
        render: requestedEndAt => (requestedEndAt ? moment(requestedEndAt).format('DD MMM YYYY h:mm A') : null),
      },
      {
        title: 'Requested Remark',
        dataIndex: 'requestedRemark',
        sorter: sorter('requestedRemark'),
        onFilter: onFilter('requestedRemark'),
        filterDropdown,
      },
      {
        title: 'Approve',
        width: '7%',
        render: approveTaskColumn,
      },
      {
        title: 'Reject',
        width: '7%',
        render: rejectTaskColumn,
      },
    ],
    []
  );

  // const subTaskTableColumns = useMemo(
  //   () => [
  //     {
  //       title: 'Sub Task',
  //       dataIndex: 'subTaskName',
  //       sorter: sorter('subTaskName'),
  //       onFilter: onFilter('subTaskName'),
  //       filterDropdown,
  //     },
  //     {
  //       title: 'Description',
  //       dataIndex: 'subTaskDescription',
  //       sorter: sorter('subTaskDescription'),
  //       onFilter: onFilter('subTaskDescription'),
  //       filterDropdown,
  //     },
  //     {
  //       title: 'Requested By',
  //       dataIndex: 'requestedBy',
  //       sorter: sorter('requestedBy'),
  //       onFilter: onFilter('requestedBy'),
  //       filterDropdown,
  //     },
  //     {
  //       title: 'Current Sub-Task End Date',
  //       dataIndex: 'subTaskEndAt',
  //       sorter: dateSorter('subTaskEndAt'),
  //       filterDropdown: dateFilterDropdown,
  //       onFilter: onDateFilter('subTaskEndAt'),
  //       render: subTaskEndAt => (subTaskEndAt ? moment(subTaskEndAt).format('DD MMM YYYY h:mm A') : null),
  //     },
  //     {
  //       title: 'Requested Task End Date',
  //       dataIndex: 'requestedEndAt',
  //       sorter: dateSorter('requestedEndAt'),
  //       filterDropdown: dateFilterDropdown,
  //       onFilter: onDateFilter('requestedEndAt'),
  //       render: requestedEndAt => (requestedEndAt ? moment(requestedEndAt).format('DD MMM YYYY h:mm A') : null),
  //     },
  //     {
  //       title: 'Requested Remark',
  //       dataIndex: 'requestedRemark',
  //       sorter: sorter('requestedRemark'),
  //       onFilter: onFilter('requestedRemark'),
  //       filterDropdown,
  //     },
  //     {
  //       title: 'Approve',
  //       width: '7%',
  //       render: approveSubTaskColumn,
  //     },
  //     {
  //       title: 'Reject',
  //       width: '7%',
  //       render: rejectSubTaskColumn,
  //     },
  //   ],
  //   []
  // );

  return (
    <Structure>
      <>
        <Heading text="Task Extensions" />
        <Table
          columns={taskTableColumns}
          bordered
          rowKey={record => record.id}
          dataSource={taskExtensions}
          size="small"
          pagination={{
            defaultPageSize: 20,
            position: ['bottomLeft'],
            showTotal: total => `Total ${total} Items`,
          }}
          scroll={{ x: 1050 }}
        />
        {/* <br />
        <br />
        <Heading text="Sub Task Extensions" />
        <Table
          columns={subTaskTableColumns}
          bordered
          rowKey={record => record.id}
          dataSource={subTaskExtensions}
          size="small"
          pagination={{
            defaultPageSize: 20,
            position: ['bottomLeft'],
            showTotal: total => `Total ${total} Items`,
          }}
          scroll={{ x: 1050 }}
        /> */}
        {modalType === 'approveTask' || modalType === 'approveSubTask' ? (
          <AcceptExtension recordData={recordData} modalType={modalType} hideModal={hideModal} />
        ) : null}
        {modalType === 'rejectTask' || modalType === 'rejectSubTask' ? (
          <RejectExtension recordData={recordData} modalType={modalType} hideModal={hideModal} />
        ) : null}
      </>
    </Structure>
  );
};

TaskExtensions.propTypes = {
  classes: PropTypes.object.isRequired,
};

const StylesTicketList = withStyles(styles)(TaskExtensions);

export default StylesTicketList;

import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import withStyles from 'react-jss';
import { Button, Input, Popconfirm, Table, Modal } from 'antd';
import { useDispatch, useSelector } from 'react-redux';
import { Controller, useForm } from 'react-hook-form';
import Tooltip from 'antd/es/tooltip';
import { DeleteOutlined, EditOutlined } from '@ant-design/icons';
import Structure from '../Structure/Structure';
import styles from '../CommonStyles';
import { successNotification, errorNotification } from '../Snackbar';
import Api from '../Api';
import Heading from '../Components/Heading';
import GetRHFField from '../Components/GetRHFField';
import { get } from '../utils';
import { getBatchSeriesList, clearBatchSeriesList, getBatchSeries, clearBatchSeries } from '../Actions';

const AntInput = GetRHFField(Input);

const BatchSeries = props => {
  const { classes } = props;
  const dispatch = useDispatch();

  const auth = useSelector(state => state.auth);
  const permissions = get(auth, ['permissions'], []);
  const seriesList = useSelector(state => state.seriesList);
  const series = useSelector(state => state.series);

  const [ViewModal, setViewModal] = useState(false);
  const [confirmLoading, setConfirmLoading] = useState(false);
  const [RecordId, setRecordId] = useState(null);

  const { control, getValues, reset } = useForm();

  useEffect(() => {
    dispatch(getBatchSeriesList());
    return () => {
      dispatch(clearBatchSeriesList());
    };
  }, []);

  const onDelete = async id => {
    try {
      await Api.delete(`/dropdowns/batch-series/${id}`);
      successNotification('Series deleted');
      dispatch(getBatchSeriesList());
    } catch (err) {
      errorNotification(err);
    }
  };

  const showModal = () => {
    setRecordId(null);
    setViewModal(true);
  };

  const showEditModal = id => {
    setRecordId(id);
    dispatch(getBatchSeries(id));
    setViewModal(true);
  };

  const hideModal = () => {
    setRecordId(null);
    dispatch(clearBatchSeries());
    setViewModal(false);
    setConfirmLoading(false);
  };

  useEffect(() => {
    if (series) {
      reset(series);
    }
  }, [series]);

  const onNew = async () => {
    try {
      const formValues = getValues();
      setConfirmLoading(true);
      await Api.post('/dropdowns/batch-series', formValues);
      setConfirmLoading(false);
      setViewModal(false);
      successNotification('Series added');
      dispatch(clearBatchSeries());
      dispatch(getBatchSeriesList());
    } catch (err) {
      setConfirmLoading(false);
      errorNotification(err);
    }
  };

  const onEdit = async () => {
    try {
      const formValues = getValues();

      setConfirmLoading(true);
      await Api.put(`/dropdowns/batch-series/${RecordId}`, formValues);
      setViewModal(false);
      successNotification('Series updated');
      dispatch(clearBatchSeries());
      dispatch(getBatchSeriesList());
    } catch (err) {
      errorNotification(err);
    }
    setConfirmLoading(false);
  };

  const columns = [
    {
      title: 'Series',
      dataIndex: 'series',
    },
  ];

  const renderActionIcons = record => (
    <div>
      {permissions.includes('batch_series') ? (
        <>
          <Tooltip placement="bottom" title="Edit">
            <EditOutlined className={classes.tableIcon} onClick={() => showEditModal(record.id)} />
          </Tooltip>
          &nbsp;&nbsp;&nbsp;&nbsp;
          <Tooltip placement="bottom" title="Delete">
            <Popconfirm title="Are you sure?" onConfirm={() => onDelete(record.id)} okText="Yes" cancelText="No">
              <DeleteOutlined className={classes.tableIcon} />
            </Popconfirm>
          </Tooltip>
        </>
      ) : null}
    </div>
  );

  if (permissions.includes('batch_series')) {
    columns.push({
      title: 'Actions',
      width: 70,
      render: renderActionIcons,
    });
  }

  return (
    <Structure>
      <>
        <Heading text="Batch Series">
          {permissions.includes('batch_series') ? (
            <Button className={classes.button} onClick={showModal}>
              New Batch Series
            </Button>
          ) : null}
        </Heading>
        <Table
          columns={columns}
          bordered
          rowKey={record => record.id}
          pagination={{ defaultPageSize: 20, position: ['bottomLeft'], showTotal: total => `Total ${total} Items` }}
          dataSource={seriesList}
          size="small"
          scroll={{ x: 1024, y: window.innerHeight - 300 }}
        />
        <Modal
          title={RecordId ? 'Edit Batch Series.' : 'New Batch Series.'}
          confirmLoading={confirmLoading}
          open={ViewModal}
          onCancel={hideModal}
          centered
          maskClosable={false}
          closable={false}
          width="400px"
          cancelText="Back"
          okText={
            RecordId ? (
              <Popconfirm title="Sure want to submit?" onConfirm={() => onEdit()} okText="Confirm" cancelText="Cancel">
                Submit
              </Popconfirm>
            ) : (
              <Popconfirm title="Sure want to submit?" onConfirm={() => onNew()} okText="Confirm" cancelText="Cancel">
                Submit
              </Popconfirm>
            )
          }
          okButtonProps={{ className: classes.button }}
        >
          <Controller
            name="series"
            control={control}
            render={({ field }) => <AntInput required allowClear placeholder="Batch Series" {...field} />}
          />
        </Modal>
      </>
    </Structure>
  );
};

BatchSeries.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(BatchSeries);

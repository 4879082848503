import React, { useEffect, useRef, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import withStyles from 'react-jss';
import PropTypes from 'prop-types';
import { Table, Popconfirm, Tooltip, Modal } from 'antd';
import { Link } from 'react-router-dom';
import { EditOutlined, DeleteOutlined, FileTextOutlined } from '@ant-design/icons';
import { useReactToPrint } from 'react-to-print';
import Structure from '../Structure/Structure';
import { sorter, onFilter, filterDropdown, get } from '../utils';
import Api from '../Api';
import {
  getLedgerContactList,
  clearLedgerContactList,
  getLedgerContactLedger,
  clearLedgerContactLedger,
} from '../Actions';
import { errorNotification, successNotification } from '../Snackbar';
import styles from '../CommonStyles';
import Heading from '../Components/Heading';
import Ledger from '../Ledger/ContactLedger';

const List = props => {
  const { classes } = props;
  const dispatch = useDispatch();

  const auth = useSelector(state => state.auth);
  const permissions = get(auth, ['permissions'], []);
  const ledgerContactList = useSelector(state => state.ledgerContactList);
  const ledgerContactLedger = useSelector(state => state.ledgerContactLedger);

  const [isModalVisible, setIsModalVisible] = useState(false);
  const [contactId, setContactId] = useState(null);
  const [contact, setContact] = useState(null);

  const printRef = useRef();
  const handlePrint = useReactToPrint({
    content: () => printRef.current,
    pageStyle: `
    @page {
      size: 210mm 297mm;
      margin: 5mm;
    }

    @media all {
      .pagebreak {
        margin-top: 1rem;
        display: block;
        page-break-before: auto;
    }

    @media print {
      .pagebreak {
        margin-top: 1rem;
        display: block;
        page-break-before: auto;
      }
    }
  `,
  });

  useEffect(() => {
    dispatch(getLedgerContactList());
    return () => {
      dispatch(clearLedgerContactList());
    };
  }, []);

  useEffect(() => {
    if (contactId && isModalVisible) {
      dispatch(getLedgerContactLedger(contactId));
    }
  }, [contactId, isModalVisible]);

  const showModal = record => {
    setIsModalVisible(true);
    setContactId(record.id);
    setContact(record);
  };

  const handleOk = () => {
    setIsModalVisible(false);
    setContactId(null);
    setContact(null);
    dispatch(clearLedgerContactLedger());
  };

  const onDelete = async id => {
    try {
      await Api.delete(`/contacts/${id}`);
      successNotification('Ledger contact deleted');
      dispatch(getLedgerContactList());
    } catch (err) {
      errorNotification(err);
    }
  };

  const tooltipShow = arg => (
    <Tooltip placement="topLeft" title={arg}>
      {arg}
    </Tooltip>
  );

  const columns = [
    {
      title: 'Name',
      dataIndex: 'name',
      sorter: sorter('name'),
      filterDropdown,
      onFilter: onFilter('name'),
      ellipsis: { showTitle: false },
      render: tooltipShow,
      width: 200,
      fixed: 'left',
    },
    {
      title: 'GST Number',
      dataIndex: 'gstNo',
      sorter: sorter('gstNo'),
      filterDropdown,
      onFilter: onFilter('gstNo'),
      ellipsis: { showTitle: false },
      render: tooltipShow,
      width: 170,
    },
    {
      title: 'State',
      dataIndex: 'state',
      sorter: sorter('state'),
      filterDropdown,
      onFilter: onFilter('state'),
      width: 120,
    },
    {
      title: 'Billing Address',
      dataIndex: 'billingAddress',
      sorter: sorter('billingAddress'),
      filterDropdown,
      onFilter: onFilter('billingAddress'),
      ellipsis: { showTitle: false },
      render: tooltipShow,
      width: 170,
    },
    {
      title: 'Corr Address',
      dataIndex: 'corrAddress',
      sorter: sorter('corrAddress'),
      filterDropdown,
      onFilter: onFilter('corrAddress'),
      ellipsis: { showTitle: false },
      render: tooltipShow,
      width: 170,
    },
    {
      title: 'Contact Person',
      dataIndex: 'contactPerson',
      sorter: sorter('contactPerson'),
      filterDropdown,
      onFilter: onFilter('contactPerson'),
      width: 120,
    },
    {
      title: 'Primary Contact',
      dataIndex: 'primaryContact',
      sorter: sorter('primaryContact'),
      filterDropdown,
      onFilter: onFilter('primaryContact'),
      width: 120,
    },
    {
      title: 'Contacts',
      dataIndex: 'contacts',
      sorter: sorter('contacts'),
      filterDropdown,
      onFilter: onFilter('contacts'),
      ellipsis: { showTitle: false },
      render: tooltipShow,
      width: 120,
    },
    {
      title: 'Primary Email',
      dataIndex: 'primaryEmail',
      sorter: sorter('primaryEmail'),
      filterDropdown,
      onFilter: onFilter('primaryEmail'),
      ellipsis: { showTitle: false },
      render: tooltipShow,
      width: 150,
    },
    {
      title: 'Emails',
      dataIndex: 'emails',
      sorter: sorter('emails'),
      filterDropdown,
      onFilter: onFilter('emails'),
      ellipsis: { showTitle: false },
      render: tooltipShow,
      width: 120,
    },
    {
      title: 'Opening Balance',
      dataIndex: 'openingBalance',
      sorter: sorter('openingBalance'),
      filterDropdown,
      onFilter: onFilter('openingBalance'),
      width: 120,
      align: 'right',
    },
    {
      title: 'Remarks',
      dataIndex: 'remarks',
      sorter: sorter('remarks'),
      filterDropdown,
      onFilter: onFilter('remarks'),
      ellipsis: { showTitle: false },
      render: tooltipShow,
      width: 150,
    },
  ];

  const renderActionIcons = record => (
    <div>
      {permissions.includes('edit_ledger_contact') ? (
        <>
          <Tooltip placement="bottom" title="Edit">
            <Link to={`/ledgercontact/edit/${record.id}`}>
              <EditOutlined className={classes.tableIcon} />
            </Link>
          </Tooltip>
          &nbsp;&nbsp;&nbsp;&nbsp;
        </>
      ) : null}
      {permissions.includes('delete_ledger_contact') ? (
        <>
          <Tooltip placement="bottom" title="Delete">
            <Popconfirm
              title="Are you sure you want to delete?"
              onConfirm={() => onDelete(record.id)}
              okText="Yes"
              cancelText="No"
            >
              <DeleteOutlined className={classes.tableIcon} />
            </Popconfirm>
          </Tooltip>
        </>
      ) : null}
    </div>
  );

  if (permissions.includes('edit_ledger_contact') || permissions.includes('delete_ledger_contact')) {
    columns.push({
      title: 'Actions',
      width: 70,
      render: renderActionIcons,
    });
  }

  const renderLedgerIcons = record => (
    <>
      {permissions.includes('edit_ledger_contact') ? (
        <Tooltip placement="top" title="View Ledger">
          <FileTextOutlined className={classes.tableIcon} onClick={() => showModal(record)} />
        </Tooltip>
      ) : null}
    </>
  );

  if (permissions.includes('edit_ledger_contact')) {
    columns.push({
      title: 'Ledger',
      width: 70,
      fixed: 'right',
      render: renderLedgerIcons,
    });
  }

  return (
    <Structure>
      <>
        <Heading text="Ledger Contacts" />
        <Table
          columns={columns}
          bordered
          rowKey={record => record.id}
          pagination={{
            defaultPageSize: 20,
            position: ['bottomLeft'],
            showTotal: total => `Total ${total} Records`,
          }}
          dataSource={ledgerContactList}
          size="small"
          scroll={{ x: 1050, y: window.innerHeight - 300 }}
        />
        <Modal
          open={isModalVisible}
          onOk={handleOk}
          onCancel={handlePrint}
          maskClosable={false}
          closable={false}
          width="1000px"
          okText="Close"
          cancelText="Print"
          cancelButtonProps={{ className: classes.button }}
          okButtonProps={{ className: classes.button }}
        >
          <Ledger statement="Customer" statementData={contact} ledgerData={ledgerContactLedger} Reference={printRef} />
        </Modal>
      </>
    </Structure>
  );
};

List.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(List);

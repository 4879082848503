import React, { useEffect, useMemo } from 'react';
import PropTypes from 'prop-types';
import { Descriptions, Row, Col, Table, Button } from 'antd';
import { EditOutlined } from '@ant-design/icons';
import { Link } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import moment from 'moment';

import { getProformaInvoice, clearProformaInvoice } from '../../Actions';
import Structure from '../../Structure/Structure';

const Detail = props => {
  const { match } = props;
  const dispatch = useDispatch();

  const auth = useSelector(state => state.auth);
  const permissions = useMemo(() => auth?.permissions || [], [auth]);
  const detail = useSelector(state => state.proformaInvoice);

  useEffect(() => {
    dispatch(getProformaInvoice(match.params.id));
    return () => {
      dispatch(clearProformaInvoice());
    };
  }, []);

  const productColumns = useMemo(
    () => [
      {
        title: 'Product',
        dataIndex: 'product',
      },
      {
        title: 'Pack Size',
        dataIndex: 'packSize',
        width: '10%',
      },
      {
        title: 'Unit',
        dataIndex: 'unit',
        width: '5%',
      },
      {
        title: 'HSN',
        dataIndex: 'hsn',
        width: '8%',
      },
      {
        title: 'Quantity',
        dataIndex: 'quantity',
        width: '8%',
      },
      {
        title: 'Rate',
        dataIndex: 'rate',
        render: text => Number(text).toLocaleString('en-IN'),
        width: '8%',
      },
      {
        title: 'Value',
        dataIndex: 'value',
        render: text => Number(text).toLocaleString('en-IN'),
        width: '8%',
      },
      {
        title: 'Tax (%)',
        dataIndex: 'tax',
        width: '8%',
      },
      {
        title: 'Tax Value',
        dataIndex: 'taxValue',
        render: text => Number(text).toLocaleString('en-IN'),
        width: '8%',
      },
      {
        title: 'Total',
        dataIndex: 'total',
        render: text => Number(text).toLocaleString('en-IN'),
        width: '8%',
      },
    ],
    []
  );

  const chargeColoums = useMemo(() => [
    {
      title: 'Charge',
      dataIndex: 'charge',
    },
    {
      title: 'HSN',
      dataIndex: 'hsn',
      width: '8%',
    },
    {
      title: 'Charge',
      dataIndex: 'value',
      render: text => Number(text).toLocaleString('en-IN'),
      width: '8%',
    },
    {
      title: 'Tax (%)',
      dataIndex: 'tax',
      width: '8%',
    },
    {
      title: 'Tax Value',
      dataIndex: 'taxValue',
      render: text => Number(text).toLocaleString('en-IN'),
      width: '8%',
    },
    {
      title: 'Total',
      dataIndex: 'total',
      render: text => Number(text).toLocaleString('en-IN'),
      width: '8%',
    },
  ]);

  const rowKey = useMemo(() => record => record.id, []);

  const scroll = useMemo(() => ({ x: 1024, y: window.innerHeight - 300 }), []);

  return (
    <Structure>
      <>
        <Row>
          <Col
            sm={{ span: 22, offset: 1 }}
            md={{ span: 22, offset: 1 }}
            lg={{ span: 20, offset: 2 }}
            xl={{ span: 16, offset: 4 }}
          >
            <Descriptions column={{ xs: 1, sm: 1, md: 1, lg: 2, xl: 2, xxl: 2 }} size="small" bordered>
              <Descriptions.Item label="PI #">{detail.piNo}</Descriptions.Item>
              <Descriptions.Item label="Date">{moment(detail.createdAt).format('DD MMM YYYY')}</Descriptions.Item>
              <Descriptions.Item label="To">{detail.lead}</Descriptions.Item>
              <Descriptions.Item label="Valid for (in days)">{detail.validForDays}</Descriptions.Item>
              <Descriptions.Item label="Payment Term">{detail.paymentTerm}</Descriptions.Item>
              <Descriptions.Item label="Delivery">{detail.delivery}</Descriptions.Item>
              <Descriptions.Item label="Transport">{detail.transport}</Descriptions.Item>
              <Descriptions.Item label="Grand Total">
                {Number(detail.grandTotal).toLocaleString('en-IN')}
              </Descriptions.Item>
              <Descriptions.Item label="Remark">{detail.remark || 'N.A.'}</Descriptions.Item>
            </Descriptions>
          </Col>
        </Row>
        {detail?.products?.length ? (
          <>
            <br />
            <Row>
              <Col
                sm={{ span: 22, offset: 1 }}
                md={{ span: 22, offset: 1 }}
                lg={{ span: 22, offset: 1 }}
                xl={{ span: 22, offset: 1 }}
              >
                <Table
                  bordered
                  size="small"
                  columns={productColumns}
                  dataSource={detail.products || []}
                  rowKey={rowKey}
                  pagination={false}
                  scroll={scroll}
                  title={() => 'Products'}
                />
              </Col>
            </Row>
            <br />
          </>
        ) : null}
        {detail?.charges?.length ? (
          <>
            <Row>
              <Col
                sm={{ span: 22, offset: 1 }}
                md={{ span: 22, offset: 1 }}
                lg={{ span: 22, offset: 1 }}
                xl={{ span: 22, offset: 1 }}
              >
                <Table
                  bordered
                  size="small"
                  columns={chargeColoums}
                  dataSource={detail.charges || []}
                  rowKey={rowKey}
                  pagination={false}
                  scroll={scroll}
                  title={() => 'Charges'}
                />
              </Col>
            </Row>
            <br />
          </>
        ) : null}
        <br />
        <Row>
          <Col
            sm={{ span: 22, offset: 1 }}
            md={{ span: 22, offset: 1 }}
            lg={{ span: 20, offset: 2 }}
            xl={{ span: 16, offset: 4 }}
          >
            {permissions.includes('editProformaInvoice') ? (
              <Button>
                <Link to={`/proformainvoices/edit/${match.params.id}`}>
                  <EditOutlined /> Edit
                </Link>
              </Button>
            ) : null}
            {/* &nbsp;&nbsp;&nbsp;&nbsp;
            <Button>
              <CloudDownloadOutlined onClick={() => onFileDownload(match.params.id)} /> Download
            </Button> */}
          </Col>
        </Row>
      </>
    </Structure>
  );
};

Detail.propTypes = {
  match: PropTypes.object.isRequired,
};

export default Detail;

export const adjustedStockList = (state = [], action) => {
  if (action.type === 'FETCH_ADJUSTED_STOCK_LIST') {
    return action.payload;
  }
  if (action.type === 'CLEAR_ADJUSTED_STOCK_LIST') {
    return [];
  }
  return state;
};

export const stockTransferList = (state = [], action) => {
  if (action.type === 'FETCH_STOCK_TRANSFER_LIST') {
    return action.payload;
  }
  if (action.type === 'CLEAR_STOCK_TRANSFER_LIST') {
    return [];
  }
  return state;
};

import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import withStyles from 'react-jss';
import { reduxForm, Field, getFormValues } from 'redux-form';
import { useHistory } from 'react-router';
import { useSelector, useDispatch, connect } from 'react-redux';
import { Button, Input, Row, Select, Modal, Popconfirm, Col, Upload } from 'antd';
import startCase from 'lodash/startCase';
import { CloudUploadOutlined } from '@ant-design/icons';
import Structure from '../Structure/Structure';
import styles from '../CommonStyles';
import { errorNotification, successNotification } from '../Snackbar';
import Api from '../Api';
import LeftGrid from '../Components/LeftGrid';
import RightGrid from '../Components/RightGrid';
import GetField from '../Components/GetField';
import Heading from '../Components/Heading';
import {
  getOrderList,
  clearOrderList,
  getMaterialIdList,
  clearMaterialIdList,
  getSupplierIdList,
  clearSupplierIdList,
  clearPurchaseOrder,
  getFormulationIdList,
  clearFormulationIdList,
} from '../Actions';
import { get } from '../utils';
import constants from '../constants';

const AntSelect = GetField(Select);
const AntInput = GetField(Input);

const NewPurchaseOrder = props => {
  const { handleSubmit, classes, change } = props;
  const history = useHistory();
  const dispatch = useDispatch();

  const materialIdList = useSelector(state => state.materialIdList);
  const orderList = useSelector(state => state.orderList);
  const formulationIdList = useSelector(state => state.formulationIdList);
  const supplierIdList = useSelector(state => state.supplierIdList);

  const materialId = useSelector(state => state.purchaseOrder.materialId);

  const [formValueModal, setFormValuesModal] = useState(false);
  const [filesModal, setFilesModal] = useState(false);
  const [confirmLoading, setConfirmLoading] = useState(false);
  const [fileList, setFileList] = useState([]);
  const [preview, setPreview] = useState({});
  const FormValues = useSelector(state => getFormValues('NewPurchaseOrder')(state));

  useEffect(() => {
    dispatch(getMaterialIdList());
    dispatch(getSupplierIdList({ status: 'Active' }));
    return () => {
      dispatch(clearMaterialIdList());
      dispatch(clearSupplierIdList());
      dispatch(clearFormulationIdList());
      dispatch(clearOrderList());
      dispatch(clearPurchaseOrder());
    };
  }, []);

  useEffect(() => {
    if (FormValues && FormValues.materialId) {
      dispatch(getFormulationIdList({ materialId: FormValues.materialId }));

      const materialObj = materialIdList.find(material => material.id === +FormValues.materialId);
      if (materialObj) {
        change('lastPurchaseRate', get(materialObj, ['lastPurchase', 'rate'], null));
        change('lastPurchaseSuppier', get(materialObj, ['lastPurchase', 'supplier'], null));
      }
    }
  }, [FormValues && FormValues.materialId, materialIdList]);

  useEffect(() => {
    dispatch(
      getOrderList({
        formulationId: FormValues.formulationId,
        materialId: FormValues.materialId,
      })
    );
  }, [FormValues && FormValues.materialId, FormValues && FormValues.formulationId]);

  const showFormValuesModal = () => {
    const _preview = {
      orderNo: '--',
      formulation: '--',
      material: '--',
      type: '--',
      unit: '--',
      supplier: '--',
      quantity: get(FormValues, ['quantity'], '--'),
      rate: get(FormValues, ['rate'], '--'),
      leadTime: get(FormValues, ['leadTime'], '--'),
      remarks: get(FormValues, ['remarks'], '--'),
      consignor: get(FormValues, ['consignor'], '--'),
    };

    const orderId = get(FormValues, ['orderId'], null);
    if (orderId) {
      const orderObj = orderList.find(order => order.id === +orderId);
      if (orderObj) {
        _preview.orderNo = orderObj.id;
        _preview.formulation = orderObj.formulation.name;
      }
    }

    const materialNo = get(FormValues, ['materialId'], null);
    if (materialNo) {
      const materialObj = materialIdList.find(material => material.id === +materialNo);
      if (materialObj) {
        _preview.material = materialObj.name;
        _preview.type = startCase(materialObj.type);
        _preview.unit = materialObj.unit;
      }
    }

    const supplierId = get(FormValues, ['supplierId'], null);
    if (supplierId) {
      const supplierObj = supplierIdList.find(supplier => supplier.id === +supplierId);
      if (supplierObj) {
        _preview.supplier = supplierObj.name;
      }
    }

    setPreview(_preview);
    setFormValuesModal(true);
  };

  const hideFormValuesModal = () => {
    setFormValuesModal(false);
    setPreview({});
  };

  const showFileModal = () => {
    setFilesModal(true);
  };

  const hideFileModal = () => {
    setFilesModal(false);
  };

  const onRemove = file => {
    const index = fileList.indexOf(file);
    const newFileList = fileList.slice();
    newFileList.splice(index, 1);
    setFileList(newFileList);
  };

  const beforeUpload = (file, files) => {
    setFileList([...fileList, ...files]);
    return false;
  };

  const onSubmit = async () => {
    try {
      setConfirmLoading(true);
      const formData = new FormData();
      Object.keys(FormValues).map(value => {
        formData.append(`${value}`, FormValues[value]);
      });
      fileList.forEach((file, index) => {
        formData.append(`files[${index}]`, file);
      });
      await Api.post('/purchase-orders', formData, {
        headers: { 'content-type': 'multipart/form-data' },
      });
      successNotification('Purchase Order created');
      setConfirmLoading(false);
      hideFormValuesModal();
      setFileList([]);
      history.push('/purchaseorder/list');
    } catch (err) {
      setConfirmLoading(false);
      errorNotification(err);
    }
  };

  return (
    <Structure>
      <>
        <Heading text="New Purchase Order" />
        <form onSubmit={handleSubmit(onSubmit)}>
          <Row>
            <LeftGrid>
              <Field
                label="Material"
                allowClear
                disabled={!!materialId}
                required
                name="materialId"
                component={AntSelect}
              >
                <Select.Option key={null} value="" disabled>
                  Select material
                </Select.Option>
                {materialIdList.map(material => (
                  <Select.Option key={material.id} value={material.id}>
                    {material.name}
                  </Select.Option>
                ))}
              </Field>
            </LeftGrid>
            <RightGrid>
              <Field
                label="Finished Good Name"
                disabled={!!materialId}
                allowClear
                required
                name="formulationId"
                component={AntSelect}
              >
                <Select.Option key={null} value="" disabled>
                  Select finished good name
                </Select.Option>
                {formulationIdList.map(formulation => (
                  <Select.Option key={formulation.id} value={formulation.id}>
                    {formulation.name}
                  </Select.Option>
                ))}
              </Field>
            </RightGrid>
          </Row>
          <Row>
            <LeftGrid>
              <Field
                label="Order Number"
                allowClear
                disabled={!!materialId}
                required
                name="orderId"
                component={AntSelect}
              >
                <Select.Option key={null} value="" disabled>
                  Select order number
                </Select.Option>
                {orderList.map(order => (
                  <Select.Option key={order.id} value={order.id}>
                    {order.id}
                  </Select.Option>
                ))}
              </Field>
            </LeftGrid>
            <RightGrid>
              <Field label="Consignor" required name="consignor" component={AntSelect}>
                <Select.Option key={null} value="" disabled>
                  Select consignor
                </Select.Option>
                {Object.values(constants.consignors.list).map(consignor => (
                  <Select.Option key={consignor} value={consignor}>
                    {consignor}
                  </Select.Option>
                ))}
              </Field>
            </RightGrid>
          </Row>
          <Row>
            <LeftGrid>
              <Field
                label="Last Supplier"
                disabled
                allowClear
                name="lastPurchaseSuppier"
                placeholder="Last Supplier"
                component={AntInput}
              />
            </LeftGrid>
            <RightGrid>
              <Field label="Supplier" allowClear required name="supplierId" component={AntSelect}>
                <Select.Option key={null} value="" disabled>
                  Select supplier
                </Select.Option>
                {supplierIdList.map(supplier => (
                  <Select.Option key={supplier.id} value={supplier.id}>
                    {supplier.name}
                  </Select.Option>
                ))}
              </Field>
            </RightGrid>
          </Row>
          <Row>
            <LeftGrid>
              <Field label="Type" allowClear disabled required name="materialId" component={AntSelect}>
                <Select.Option key={null} value="" disabled>
                  Select material
                </Select.Option>
                {materialIdList.map(material => (
                  <Select.Option key={material.id} value={material.id}>
                    {startCase(material.type)}
                  </Select.Option>
                ))}
              </Field>
            </LeftGrid>
            <RightGrid>
              <Field label="Unit" allowClear disabled required name="materialId" component={AntSelect}>
                <Select.Option key={null} value="" disabled>
                  Select material
                </Select.Option>
                {materialIdList.map(material => (
                  <Select.Option key={material.id} value={material.id}>
                    {material.unit}
                  </Select.Option>
                ))}
              </Field>
            </RightGrid>
          </Row>
          <Row>
            <LeftGrid>
              <Field
                label="Quantity"
                required
                allowClear
                name="quantity"
                placeholder="Quantity"
                type="number"
                min={0}
                component={AntInput}
              />
            </LeftGrid>
            <RightGrid>
              <Field
                label="Last Purchase Rate"
                disabled
                allowClear
                name="lastPurchaseRate"
                placeholder="Last Purchase Rate"
                type="number"
                min={0}
                component={AntInput}
              />
            </RightGrid>
          </Row>
          <Row>
            <LeftGrid>
              <Field
                label="Rate"
                required
                allowClear
                type="number"
                min={0}
                name="rate"
                placeholder="Rate"
                component={AntInput}
              />
            </LeftGrid>
            <RightGrid>
              <Field
                label="Lead Time (in days)"
                allowClear
                type="number"
                min={0}
                name="leadTime"
                placeholder="Lead Time (in days)"
                component={AntInput}
              />
            </RightGrid>
          </Row>
          <Row>
            <LeftGrid>
              <Field label="Remarks" allowClear name="remarks" placeholder="Remarks" component={AntInput} />
            </LeftGrid>
            <RightGrid>
              <Button className={classes.uploadButton} onClick={() => showFileModal()}>
                Upload Files
              </Button>
            </RightGrid>
          </Row>
          <Row className={classes.buttonContainer}>
            <Button className={classes.button} type="primary" onClick={showFormValuesModal}>
              Preview
            </Button>
          </Row>
          <Modal
            onOk={hideFileModal}
            open={filesModal}
            centered
            maskClosable={false}
            title="Upload Files."
            width="500px"
            okText="Close"
            cancelButtonProps={{ className: classes.hide }}
            okButtonProps={{ className: classes.button }}
            closable={false}
          >
            <Upload multiple onRemove={onRemove} beforeUpload={beforeUpload} fileList={fileList}>
              <Button icon={<CloudUploadOutlined />}>Select Files</Button>
            </Upload>
          </Modal>
        </form>
        <Modal
          onCancel={hideFormValuesModal}
          open={formValueModal}
          confirmLoading={confirmLoading}
          centered
          width="800px"
          maskClosable={false}
          closable={false}
          cancelText="Back"
          okButtonProps={{ className: classes.button }}
          okText={
            <Popconfirm
              title="Sure want to submit form?"
              onConfirm={() => onSubmit()}
              okText="Confirm"
              cancelText="Cancel"
            >
              Submit
            </Popconfirm>
          }
        >
          <Row>
            <Col xs={24} lg={12}>
              <p>
                Order Number:
                <span className={classes.detailColor}>{preview.orderNo}</span>
              </p>
            </Col>
            <Col xs={24} lg={12}>
              <p>
                Finished Good Name:
                <span className={classes.detailColor}>{preview.formulation}</span>
              </p>
            </Col>
          </Row>
          <Row>
            <Col xs={24} lg={12}>
              <p>
                Material:
                <span className={classes.detailColor}>{preview.material}</span>
              </p>
            </Col>
            <Col xs={24} lg={12}>
              <p>
                Type:
                <span className={classes.detailColor}>{preview.type}</span>
              </p>
            </Col>
          </Row>
          <Row>
            <Col xs={24} lg={12}>
              <p>
                Unit:
                <span className={classes.detailColor}>{preview.unit}</span>
              </p>
            </Col>
            <Col xs={24} lg={12}>
              <p>
                Supplier:
                <span className={classes.detailColor}>{preview.supplier}</span>
              </p>
            </Col>
          </Row>
          <Row>
            <Col xs={24} lg={12}>
              <p>
                Quantity:
                <span className={classes.detailColor}>{preview.quantity}</span>
              </p>
            </Col>
            <Col xs={24} lg={12}>
              <p>
                Rate:
                <span className={classes.detailColor}>{preview.rate}</span>
              </p>
            </Col>
          </Row>
          <Row>
            <Col xs={24} lg={12}>
              <p>
                Lead Time (in days):
                <span className={classes.detailColor}>{preview.leadTime}</span>
              </p>
            </Col>
            <Col xs={24} lg={12}>
              <p>
                Consignor:
                <span className={classes.detailColor}>{preview.consignor}</span>
              </p>
            </Col>
          </Row>
          <Row>
            <Col xs={24} lg={12}>
              <p>
                Remarks:
                <span className={classes.detailColor}>{preview.remarks}</span>
              </p>
            </Col>
          </Row>
        </Modal>
      </>
    </Structure>
  );
};

NewPurchaseOrder.propTypes = {
  handleSubmit: PropTypes.func.isRequired,
  classes: PropTypes.object.isRequired,
  change: PropTypes.func.isRequired,
};

const StyledNewPurchaseOrder = withStyles(styles)(NewPurchaseOrder);

const FormedNewPurchaseOrder = reduxForm({
  form: 'NewPurchaseOrder',
  enableReinitialize: true,
})(StyledNewPurchaseOrder);

export default connect(({ purchaseOrder }) => ({ initialValues: purchaseOrder }), {})(FormedNewPurchaseOrder);

import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Button, Col, Form, Row, Select } from 'antd';
import fileDownload from 'js-file-download';
import moment from 'moment';
import Structure from '../Structure/Structure';
import Heading from '../Components/Heading';
import { getItemsToOrderList, clearItemsToOrderList } from '../Actions';
import Api from '../Api';
import { errorNotification } from '../Snackbar';
import ItemsToOrderTable from './ItemsToOrderTable';
import constants from '../constants';

const ItemsToOrder = () => {
  const dispatch = useDispatch();

  const itemsToOrderList = useSelector(state => state.itemsToOrderList);

  const [rowCounts, setRowCounts] = useState(0);

  const [Consignor, SetConsignor] = useState(null);

  useEffect(() => {
    if (Consignor) {
      dispatch(getItemsToOrderList({ consignor: Consignor }));
    }

    return () => {
      dispatch(clearItemsToOrderList());
    };
  }, [Consignor]);

  useEffect(() => {
    if (itemsToOrderList) {
      setRowCounts(itemsToOrderList.length);
    }
  }, [itemsToOrderList]);

  const exportMaterialList = async () => {
    try {
      const { data } = await Api.getFile(`/purchase-planning/export`, { consignor: Consignor });
      fileDownload(data, `${Consignor}-Items-to-order-${moment().format('DD-MMM-YY hh:mm')}.csv`);
    } catch (error) {
      errorNotification(error);
    }
  };

  return (
    <Structure>
      <>
        <Heading text={`Item To Order (${rowCounts})`} />
        <Row gutter={[8, 8]}>
          <Col>
            <Form.Item>
              <Select onChange={SetConsignor} placeholder="Consignor" value={Consignor} style={{ width: 280 }}>
                {Object.values(constants.consignors.list).map(consignor => (
                  <Select.Option key={consignor} value={consignor}>
                    {consignor}
                  </Select.Option>
                ))}
              </Select>
            </Form.Item>
          </Col>
          <Col>
            <Button type="default" onClick={exportMaterialList}>
              Export
            </Button>
          </Col>
        </Row>
        <ItemsToOrderTable setRowCounts={setRowCounts} />
      </>
    </Structure>
  );
};

export default ItemsToOrder;

import React, { useCallback, useState } from 'react';
import PropTypes from 'prop-types';
import { Col, DatePicker, Input, Modal, Row, Popconfirm } from 'antd';
import { useForm, Controller } from 'react-hook-form';
import moment from 'moment';
import startCase from 'lodash/startCase';

import Api from '../../Api';
import { errorNotification, successNotification } from '../../Snackbar';
import GetRHFField from '../../Components/GetRHFField';

const AntInput = GetRHFField(Input);
const AntDatePicker = GetRHFField(DatePicker);

const Extension = props => {
  const { setModaltype, refetch, setRefetch, event, setEvent } = props;

  const { control, reset, getValues } = useForm({
    shouldUnregister: false,
  });

  const [confirmLoading, setConfirmLoading] = useState(false);

  const disabledDate = useCallback(current => {
    return current && current < moment().subtract(1, 'day').endOf('day');
  }, []);

  const onComplete = useCallback(async () => {
    try {
      const formValues = getValues();

      setConfirmLoading(true);
      if (event && event.type === 'task') {
        await Api.post(`/tasks/extensions/request/${event.id}`, formValues);
      } else if (event && event.type === 'subTask') {
        await Api.post(`/tasks/request/subtasks/${event.id}`, formValues);
      }
      reset();
      setConfirmLoading(false);
      successNotification('Request Extension Successfully');
      setModaltype(null);
      setEvent(null);
      setRefetch(!refetch);
    } catch (err) {
      setConfirmLoading(false);
      errorNotification(err);
    }
  }, [refetch, event]);

  return (
    <Modal
      title={`Request Extension for ${startCase(event.type)}`}
      confirmLoading={confirmLoading}
      open
      onCancel={() => setModaltype(null)}
      centered
      maskClosable={false}
      cancelText="Back"
      okText={
        <Popconfirm
          title="Sure want to submit form?"
          onConfirm={() => onComplete()}
          okText="Confirm"
          cancelText="Cancel"
        >
          Submit
        </Popconfirm>
      }
    >
      <form>
        <Row>
          <Col span={24}>
            <Controller
              name="newEndAt"
              control={control}
              render={({ field }) => (
                <AntDatePicker
                  allowClear
                  showTime={{ format: 'hh:mm A' }}
                  format="YYYY-MM-DD hh:mm A"
                  disabledDate={disabledDate}
                  label="End Date"
                  placeholder="End Date"
                  {...field}
                />
              )}
            />
          </Col>
        </Row>
        <Row>
          <Col span={24}>
            <Controller
              name="requestedRemark"
              control={control}
              render={({ field }) => (
                <AntInput
                  allowClear
                  required
                  label="Remark"
                  placeholder="Remark"
                  {...field}
                  autoSize={{ minRows: 1, maxRows: 6 }}
                />
              )}
            />
          </Col>
        </Row>
      </form>
    </Modal>
  );
};

Extension.propTypes = {
  setModaltype: PropTypes.func.isRequired,
  refetch: PropTypes.bool,
  setRefetch: PropTypes.func.isRequired,
  event: PropTypes.object.isRequired,
  setEvent: PropTypes.func.isRequired,
};

export default Extension;

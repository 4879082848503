import React, { useState } from 'react';
import PropTypes from 'prop-types';
import withStyles from 'react-jss';
import Modal from 'antd/lib/modal/Modal';
import { Col, Input, Popconfirm, Row } from 'antd';
import { Field, getFormValues, reduxForm, reset } from 'redux-form';
import { useDispatch, useSelector } from 'react-redux';

import styles from '../CommonStyles';
import GetField from '../Components/GetField';
import Api from '../Api';
import { errorNotification, successNotification } from '../Snackbar';

const AntInput = GetField(Input.TextArea);

const Modals = props => {
  const { modalType, classes, saleId, hideModal } = props;

  const FormValues = useSelector(state => getFormValues('SaleList')(state));
  const [confirmLoading, setConfirmLoading] = useState(false);
  const dispatch = useDispatch();

  const onSendInvoice = async () => {
    try {
      setConfirmLoading(true);
      await Api.post(`/order-sales/invoice/${saleId}`, FormValues);
      successNotification('Sale Invoice Sent');
      setConfirmLoading(false);
      dispatch(reset('SaleList'));
      hideModal();
    } catch (err) {
      setConfirmLoading(false);
      errorNotification(err);
    }
  };

  return (
    <Modal
      open={modalType === 'SendInvoice'}
      onCancel={hideModal}
      confirmLoading={confirmLoading}
      centered
      maskClosable={false}
      title="Send Invoice."
      width="1000px"
      closable={false}
      cancelText="Cancel"
      okText={
        <Popconfirm
          title="Sure want to submit form?"
          onConfirm={() => onSendInvoice()}
          okText="Confirm"
          cancelText="Cancel"
        >
          Submit
        </Popconfirm>
      }
      okButtonProps={{ className: classes.button }}
    >
      <Row>
        <Col xs={{ span: 24 }} lg={{ span: 10 }}>
          <Field
            required
            allowClear
            name="deliveryNote"
            label="Delivery Note"
            placeholder="Delivery Note"
            component={AntInput}
          />
        </Col>
        <Col xs={{ span: 24 }} lg={{ span: 10, offset: 4 }}>
          <Field
            required
            allowClear
            name="termsOfDelivery"
            label="Terms of Delivery"
            placeholder="Terms of Delivery"
            component={AntInput}
          />
        </Col>
      </Row>
      <Row>
        <Col xs={{ span: 24 }} lg={{ span: 10 }}>
          <Field
            required
            allowClear
            name="vehicleNo"
            label="Vehicle Number"
            placeholder="Vehicle Number"
            component={AntInput}
          />
        </Col>
        <Col xs={{ span: 24 }} lg={{ span: 10, offset: 4 }}>
          <Field required allowClear name="remarks" label="Remarks" placeholder="Remarks" component={AntInput} />
        </Col>
      </Row>
    </Modal>
  );
};

Modals.propTypes = {
  modalType: PropTypes.string,
  hideModal: PropTypes.func,
  saleId: PropTypes.number,
  classes: PropTypes.object.isRequired,
};

const StylesModals = withStyles(styles)(Modals);

const FormedModals = reduxForm({
  form: 'SaleList',
  enableReinitialize: true,
})(StylesModals);

export default FormedModals;

import Api from '../Api';

export const getMaterialList = query => async dispatch => {
  try {
    const { data } = await Api.get('/materials', query);
    dispatch({
      type: 'FETCH_MATERIAL_LIST',
      payload: data,
    });
  } catch (err) {
    throw err;
  }
};

export const clearMaterialList = () => {
  return {
    type: 'CLEAR_MATERIAL_LIST',
  };
};

export const getMaterial = id => async dispatch => {
  try {
    const { data } = await Api.get(`/materials/${id}`);
    dispatch({
      type: 'FETCH_MATERIAL',
      payload: data,
    });
  } catch (err) {
    throw err;
  }
};

export const clearMaterial = () => {
  return {
    type: 'CLEAR_MATERIAL',
  };
};

export const getMaterialIdList = params => async dispatch => {
  try {
    const { data } = await Api.get('/materials/ids', params);
    dispatch({
      type: 'FETCH_MATERIAL_ID_LIST',
      payload: data,
    });
  } catch (err) {
    throw err;
  }
};

export const clearMaterialIdList = () => {
  return {
    type: 'CLEAR_MATERIAL_ID_LIST',
  };
};

export const getMaterialTransactions = query => async dispatch => {
  try {
    const { data } = await Api.get('/materials/transactions', query);
    dispatch({
      type: 'FETCH_MATERIAL_TRANSACTIONS',
      payload: data,
    });
  } catch (err) {
    throw err;
  }
};

export const clearMaterialTransactions = () => {
  return {
    type: 'CLEAR_MATERIAL_TRANSACTIONS',
  };
};

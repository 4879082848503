import Api from '../Api';

export const getProformaInvoices = query => async dispatch => {
  const { data } = await Api.get('/proforma-invoices', query);
  dispatch({
    type: 'FETCH_PROFORMA_INVOICES',
    payload: data,
  });
};

export const clearProformaInvoices = () => {
  return {
    type: 'CLEAR_PROFORMA_INVOICES',
  };
};

export const getProformaInvoice = id => async dispatch => {
  const { data } = await Api.get(`/proforma-invoices/${id}`);
  dispatch({
    type: 'FETCH_PROFORMA_INVOICE',
    payload: data,
  });
};

export const clearProformaInvoice = () => {
  return {
    type: 'CLEAR_PROFORMA_INVOICE',
  };
};

export const getPIFilesList = id => async dispatch => {
  try {
    const { data } = await Api.get(`/proforma-invoices/${id}/files`);
    dispatch({
      type: 'FETCH_PI_FILES_LIST',
      payload: data,
    });
  } catch (err) {
    throw err;
  }
};

export const clearPIFilesList = () => {
  return {
    type: 'CLEAR_PI_FILES_LIST',
  };
};

import Api from '../Api';

export const getCourierList = () => async dispatch => {
  try {
    const { data } = await Api.get('/courier-entries');
    dispatch({
      type: 'FETCH_COURIER_LIST',
      payload: data,
    });
  } catch (err) {
    throw err;
  }
};

export const clearCourierList = () => {
  return {
    type: 'CLEAR_COURIER_LIST',
  };
};

export const getCourierFilesList = id => async dispatch => {
  try {
    const { data } = await Api.get(`/courier-entries/${id}/files`);
    dispatch({
      type: 'FETCH_COURIER_FILES_LIST',
      payload: data,
    });
  } catch (err) {
    throw err;
  }
};

export const clearCourierFilesList = () => {
  return {
    type: 'CLEAR_COURIER_FILES_LIST',
  };
};

export const getCourier = id => async dispatch => {
  try {
    const { data } = await Api.get(`/courier-entries/${id}`);
    dispatch({
      type: 'FETCH_COURIER',
      payload: data,
    });
  } catch (err) {
    throw err;
  }
};

export const clearCourier = () => {
  return {
    type: 'CLEAR_COURIER',
  };
};

import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { Button, Col, Input, InputNumber, Modal, Row, Select } from 'antd';
import { CloseOutlined } from '@ant-design/icons';
import { Controller, useForm } from 'react-hook-form';
import { useDispatch, useSelector } from 'react-redux';
import Api from '../../Api';
import { errorNotification, successNotification } from '../../Snackbar';
import GetField from '../../Components/GetRHFField';
import { clearEmployeeList, getEmployeeList, getJobCard } from '../../Actions';

const AntInput = GetField(Input);
const AntSelect = GetField(Select);
const AntInputNumber = GetField(InputNumber);

const EditJobCardModal = props => {
  const { recordId, hideModal, order } = props;

  console.log(order);

  const dispatch = useDispatch();

  const { handleSubmit, control, watch, setValue, reset } = useForm({});

  const users = useSelector(state => state.employeeList);
  const jobCard = useSelector(state => state.jobCard);

  const [ConfirmLoading, SetConfirmLoading] = useState(false);

  useEffect(() => {
    dispatch(getJobCard(order.id));
    dispatch(getEmployeeList());

    return () => {
      dispatch(clearEmployeeList());
    };
  }, []);

  useEffect(() => {
    if (jobCard) {
      reset({
        manufacturing: jobCard?.manufacturingdetails,
        filling: jobCard?.fillingdetails,
        packing: { ...jobCard?.packingdetails, quantity: order.quantity },
      });
    }
  }, [jobCard]);

  useEffect(() => {
    const actualYield = watch('actualYield');
    if (actualYield) {
      const percentageYield = ((+actualYield / +order.quantity) * 100).toFixed(2);
      setValue('percentageYield', percentageYield);
    }
  }, [watch('actualYield')]);

  const onSubmit = async formValues => {
    try {
      SetConfirmLoading(true);

      await Api.put(`/orders/jobcard/${recordId}`, formValues);
      successNotification('Job card details submitted');
      hideModal();
    } catch (error) {
      errorNotification(error);
      SetConfirmLoading(false);
    }
  };

  return (
    <Modal
      open
      maskClosable={false}
      closable={false}
      onOk={hideModal}
      okText="Close"
      cancelButtonProps={{ style: { display: 'none' } }}
      width={800}
      style={{ top: 10 }}
      title={`Job card details | ${order.id}`}
      closeIcon={<CloseOutlined onClick={hideModal} />}
    >
      <form onSubmit={handleSubmit(onSubmit)}>
        <Row>
          <Col xs={{ span: 24 }} style={{ textAlign: 'center' }}>
            <h3>Manufacturing Details</h3>
          </Col>
        </Row>
        <Row gutter={[16, 0]}>
          <Col span={12}>
            <Controller
              name="manufacturing.weightVolume"
              control={control}
              render={({ field }) => (
                <AntInput
                  {...field}
                  placeholder={
                    order?.section === 'External' || order?.section === 'Liquid' ? 'Enter volume' : 'Enter Weight'
                  }
                  label={order?.section === 'External' || order?.section === 'Liquid' ? 'Volume' : 'Gross Weight'}
                />
              )}
            />
          </Col>
          {order?.section !== 'Capsule' ? (
            <Col span={12}>
              <Controller
                name="manufacturing.color"
                control={control}
                render={({ field }) => <AntInput {...field} placeholder="Enter color" label="Color" />}
              />
            </Col>
          ) : null}
          {order?.section === 'Sachet' || order?.section === 'Capsule' || order?.section === 'Veterinary Sachet' ? (
            <Col span={12}>
              <Controller
                name="manufacturing.flow"
                control={control}
                render={({ field }) => <AntInput {...field} placeholder="Enter flow" label="Flow" />}
              />
            </Col>
          ) : null}
          {order?.section !== 'Veterinary Tablet' ||
          order?.section !== 'Veterinary Powder' ||
          order?.section !== 'Capsule' ? (
            <Col span={12}>
              <Controller
                name="manufacturing.flavourFragrance"
                control={control}
                render={({ field }) => (
                  <AntInput
                    {...field}
                    placeholder={order?.section === 'External' ? 'Enter fragrance' : 'Enter flavour'}
                    label={order?.section === 'External' ? 'Fragrance' : 'Flavour'}
                  />
                )}
              />
            </Col>
          ) : null}
          {order?.section === 'External' || order?.section === 'Liquid' ? (
            <Col span={12}>
              <Controller
                name="manufacturing.pH"
                control={control}
                render={({ field }) => <AntInput {...field} placeholder="Enter pH" label="pH" />}
              />
            </Col>
          ) : null}
          {order?.section === 'External' || order?.section === 'Liquid' ? (
            <Col span={12}>
              <Controller
                name="manufacturing.viscosity"
                control={control}
                render={({ field }) => <AntInput {...field} placeholder="Enter viscosity" label="Viscosity" />}
              />
            </Col>
          ) : null}
          {order?.section === 'External' || order?.section === 'Liquid' ? (
            <Col span={12}>
              <Controller
                name="manufacturing.gaze"
                control={control}
                render={({ field }) => <AntInput {...field} placeholder="Enter gaze" label="Gaze" />}
              />
            </Col>
          ) : null}
          <Col span={12}>
            <Controller
              name="manufacturing.remarks"
              control={control}
              render={({ field }) => (
                <AntInput
                  allowClear
                  required
                  label="Remark"
                  placeholder="Remark"
                  {...field}
                  autoSize={{ minRows: 1, maxRows: 6 }}
                />
              )}
            />
          </Col>
          {/* <Col span={12}>
            <Controller
              name="usersInManufacturing"
              control={control}
              render={({ field }) => (
                <AntSelect
                  allowClear
                  required
                  mode="multiple"
                  label="Users in Manufacturing"
                  placeholder="Select user"
                  {...field}
                >
                  {users.map(el => (
                    <Select.Option key={el.id} value={el.id}>
                      {el.name}
                    </Select.Option>
                  ))}
                </AntSelect>
              )}
            />
          </Col> */}
        </Row>
        <br />
        <Row>
          <Col xs={{ span: 24 }} style={{ textAlign: 'center' }}>
            <h3>Filling Details</h3>
          </Col>
        </Row>
        <Row gutter={[16, 0]}>
          <Col span={12}>
            <Controller
              name="filling.total"
              control={control}
              render={({ field }) => (
                <AntInput
                  {...field}
                  placeholder={
                    order?.section === 'External' || order?.section === 'Liquid'
                      ? 'Enter total volume received'
                      : 'Enter total gross weight received'
                  }
                  label={
                    order?.section === 'External' || order?.section === 'Liquid'
                      ? 'Total Volume Received'
                      : 'Total Gross Weight Received'
                  }
                />
              )}
            />
          </Col>
          <Col span={12}>
            <Controller
              name="filling.perUnit"
              control={control}
              render={({ field }) => (
                <AntInput
                  {...field}
                  placeholder={
                    order?.section === 'External' || order?.section === 'Liquid'
                      ? 'Enter volume per unit packaging'
                      : 'Enter weight per unit packaging'
                  }
                  label={
                    order?.section === 'External' || order?.section === 'Liquid'
                      ? 'Volume Per Unit Packaging'
                      : 'Weight Per Unit Packaging'
                  }
                />
              )}
            />
          </Col>
          {/* <Col span={12}>
            <Controller
              name="usersInFilling"
              control={control}
              render={({ field }) => (
                <AntSelect
                  allowClear
                  required
                  mode="multiple"
                  label="Users in filling"
                  placeholder="Select user"
                  {...field}
                >
                  {users.map(el => (
                    <Select.Option key={el.id} value={el.id}>
                      {el.name}
                    </Select.Option>
                  ))}
                </AntSelect>
              )}
            />
          </Col> */}
          <Col span={12}>
            <Controller
              name="filling.fillCheckedBy"
              control={control}
              render={({ field }) => (
                <AntSelect
                  allowClear
                  required
                  placeholder="Select user"
                  label={order?.section === 'Veterinary Tablet' ? 'Hardness Checked By' : 'Filling Checked By'}
                  {...field}
                >
                  {users.map(el => (
                    <Select.Option key={el.id} value={el.id}>
                      {el.name}
                    </Select.Option>
                  ))}
                </AntSelect>
              )}
            />
          </Col>
          {order?.section !== 'Veterinary Tablet' ? (
            <Col span={12}>
              <Controller
                name="filling.sealingCheckedBy"
                control={control}
                render={({ field }) => (
                  <AntSelect allowClear required label="Sealing Checked By" placeholder="Select user" {...field}>
                    {users.map(el => (
                      <Select.Option key={el.id} value={el.id}>
                        {el.name}
                      </Select.Option>
                    ))}
                  </AntSelect>
                )}
              />
            </Col>
          ) : null}
          <Col span={12}>
            <Controller
              name="filling.remarks"
              control={control}
              render={({ field }) => (
                <AntInput
                  allowClear
                  required
                  label="Remark"
                  placeholder="Remark"
                  {...field}
                  autoSize={{ minRows: 1, maxRows: 6 }}
                />
              )}
            />
          </Col>
        </Row>
        <br />
        <Row>
          <Col xs={{ span: 24 }} style={{ textAlign: 'center' }}>
            <h3>Packing Details</h3>
          </Col>
        </Row>
        <Row gutter={[16, 0]}>
          <Col span={12}>
            <Controller
              name="packing.finishedDetails"
              control={control}
              render={({ field }) => <AntInput {...field} placeholder="Finished Details" label="Finished Details" />}
            />
          </Col>
          <Col span={12}>
            <Controller
              name="packing.quantity"
              control={control}
              render={({ field }) => (
                <AntInputNumber
                  {...field}
                  min={0}
                  step={1}
                  disabled
                  placeholder="Quantity As Per Order"
                  label="Quantity As Per Order"
                />
              )}
            />
          </Col>
          <Col span={12}>
            <Controller
              name="packing.actualYield"
              control={control}
              render={({ field }) => (
                <AntInputNumber {...field} min={0} step={1} placeholder="Actual Yield" label="Actual Yield" />
              )}
            />
          </Col>
          <Col span={12}>
            <Controller
              name="packing.percentageYield"
              control={control}
              render={({ field }) => (
                <AntInputNumber
                  {...field}
                  min={0}
                  disabled
                  placeholder="Percentage Yield (in %)"
                  label="Percentage Yield (in %)"
                />
              )}
            />
          </Col>
          <Col span={12}>
            <Controller
              name="packing.remarks"
              control={control}
              render={({ field }) => (
                <AntInput
                  allowClear
                  required
                  label="Remark"
                  placeholder="Remark"
                  {...field}
                  autoSize={{ minRows: 1, maxRows: 6 }}
                />
              )}
            />
          </Col>
          {/* <Col span={12}>
            <Controller
              name="usersInPacking"
              control={control}
              render={({ field }) => (
                <AntSelect
                  allowClear
                  required
                  mode="multiple"
                  label="Users in packing"
                  placeholder="Select user"
                  {...field}
                >
                  {users.map(el => (
                    <Select.Option key={el.id} value={el.id}>
                      {el.name}
                    </Select.Option>
                  ))}
                </AntSelect>
              )}
            />
          </Col>
          <Col span={12}>
            <Controller
              name="shipperPackedBy"
              control={control}
              render={({ field }) => (
                <AntSelect
                  allowClear
                  required
                  mode="multiple"
                  placeholder="Select user"
                  label="Shipper Packed By"
                  {...field}
                >
                  {users.map(el => (
                    <Select.Option key={el.id} value={el.id}>
                      {el.name}
                    </Select.Option>
                  ))}
                </AntSelect>
              )}
            />
          </Col> */}
        </Row>

        <br />
        <Button htmlType="submit" type="primary" loading={ConfirmLoading}>
          Submit
        </Button>
      </form>
    </Modal>
  );
};

EditJobCardModal.propTypes = {
  recordId: PropTypes.number.isRequired,
  order: PropTypes.object.isRequired,
  hideModal: PropTypes.func.isRequired,
};

export default EditJobCardModal;

import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Button, Col, InputNumber, Modal, Row } from 'antd';
import { CloseOutlined } from '@ant-design/icons';
import { Controller, useForm } from 'react-hook-form';
import Api from '../../Api';
import { errorNotification, successNotification } from '../../Snackbar';
import GetField from '../../Components/GetRHFField';

const AntInputNumber = GetField(InputNumber);

const RemainingStock = props => {
  const { recordId, hideModal, order } = props;

  const { handleSubmit, control } = useForm();

  const [ConfirmLoading, SetConfirmLoading] = useState(false);

  const onSubmit = async formValues => {
    try {
      SetConfirmLoading(true);

      await Api.put(`/orders/${recordId}/semifinishedstock`, formValues);

      successNotification('Submitted successfully');
      hideModal();
    } catch (error) {
      errorNotification(error);
      SetConfirmLoading(false);
    }
  };

  return (
    <Modal
      open
      maskClosable={false}
      okText="Close"
      onOk={hideModal}
      cancelButtonProps={{ style: { display: 'none' } }}
      width={800}
      style={{ top: 10 }}
      title={`Remaining Semi Finished Stock | ${order.id}`}
      closeIcon={<CloseOutlined onClick={hideModal} />}
    >
      <form onSubmit={handleSubmit(onSubmit)}>
        <Row gutter={[16, 0]}>
          <Col span={12}>Remaining Semi Finished Stock: {order.remainingStock}</Col>
          <Col span={12}>
            <Controller
              name="quantity"
              control={control}
              render={({ field }) => (
                <AntInputNumber {...field} placeholder="Quantity to be used" label="Quantity to be used" />
              )}
            />
          </Col>
        </Row>

        <Button htmlType="submit" type="primary" loading={ConfirmLoading}>
          Submit
        </Button>
      </form>
    </Modal>
  );
};

RemainingStock.propTypes = {
  recordId: PropTypes.number.isRequired,
  order: PropTypes.object.isRequired,
  hideModal: PropTypes.func.isRequired,
};

export default RemainingStock;

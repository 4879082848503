import React, { useCallback, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { Input, Modal, Popconfirm } from 'antd';
import withStyles from 'react-jss';
import { useDispatch, useSelector } from 'react-redux';
import { useForm, Controller } from 'react-hook-form';

import styles from '../../CommonStyles';
import { successNotification, errorNotification } from '../../Snackbar';
import Api from '../../Api';
import GetField from '../../Components/GetRHFField';
import { getLeadQuery, clearLeadQuery } from '../../Actions';

const AntInput = GetField(Input);

const LeadQueryModal = props => {
  const { classes, query, leadId, partyId, modalType, hideModal } = props;

  const dispatch = useDispatch();

  const { control, getValues, reset } = useForm();

  const [confirmLoading, setConfirmLoading] = useState(false);

  const Query = useSelector(state => state.query);

  useEffect(() => {
    if (query?.id) {
      dispatch(getLeadQuery(query?.id));
    }

    return () => {
      dispatch(clearLeadQuery());
    };
  }, [query]);

  useEffect(() => reset(Query), [Query]);

  const onSubmit = useCallback(async () => {
    try {
      const formValues = getValues();

      setConfirmLoading(true);
      if (query?.id) {
        await Api.put(`/leads/queries/${query.id}`, { ...formValues, partyId, leadId });
        successNotification('Query Updated');
      } else {
        await Api.post('/leads/queries', { ...formValues, partyId, leadId });
        successNotification('Query Added');
      }

      setConfirmLoading(false);
      reset();
      hideModal();
    } catch (err) {
      setConfirmLoading(false);
      errorNotification(err);
    }
  }, [query]);

  return (
    <Modal
      onCancel={() => {
        reset();
        hideModal();
      }}
      open={modalType === 'Add_Query_Modal'}
      confirmLoading={confirmLoading}
      maskClosable={false}
      title="Add Query"
      style={{ top: 10 }}
      okButtonProps={{ className: classes.button }}
      okText={
        <Popconfirm title="Sure want to submit?" onConfirm={() => onSubmit()} okText="Confirm" cancelText="Cancel">
          Submit
        </Popconfirm>
      }
    >
      <Controller
        name="query"
        control={control}
        render={({ field }) => <AntInput allowClear label="Query" placeholder="Query" {...field} />}
      />
    </Modal>
  );
};

LeadQueryModal.propTypes = {
  handleSubmit: PropTypes.func,
  classes: PropTypes.object.isRequired,
  query: PropTypes.object,
  leadId: PropTypes.any,
  partyId: PropTypes.any,
  modalType: PropTypes.string,
  hideModal: PropTypes.func,
};

export default withStyles(styles)(LeadQueryModal);

import React, { useEffect, useState } from 'react';
import { useSelector, useDispatch, connect } from 'react-redux';
import withStyles from 'react-jss';
import PropTypes from 'prop-types';
import { Table, Popconfirm, Tooltip, Button, Input, Modal, Select } from 'antd';
import { EditOutlined } from '@ant-design/icons';
import { reduxForm, Field, getFormValues, reset } from 'redux-form';
import startCase from 'lodash/startCase';
import Structure from '../Structure/Structure';
import { sorter, onFilter, filterDropdown, get } from '../utils';
import Api from '../Api';
import { getNarrationList, clearNarrationList, getNarration, clearNarration } from '../Actions';
import { errorNotification, successNotification } from '../Snackbar';
import styles from '../CommonStyles';
import Heading from '../Components/Heading';
import GetField from '../Components/GetField';
import constants from '../constants';

const AntInput = GetField(Input);
const AntSelect = GetField(Select);

const Narration = props => {
  const { classes } = props;
  const dispatch = useDispatch();

  const auth = useSelector(state => state.auth);
  const permissions = get(auth, ['permissions'], []);
  const narrationList = useSelector(state => state.narrationList);

  const [dropdownModal, setDropdownModal] = useState(false);
  const [confirmLoading, setConfirmLoading] = useState(false);
  const [editDropdownId, setEditDropdownId] = useState(null);

  const FormValues = useSelector(state => getFormValues('NarrationForm')(state));

  useEffect(() => {
    dispatch(getNarrationList());
    return () => {
      dispatch(clearNarrationList());
    };
  }, []);

  const showDropdownModal = () => {
    setEditDropdownId(null);
    setDropdownModal(true);
  };

  const showEditDropdownModal = id => {
    setEditDropdownId(id);
    dispatch(getNarration(id));
    setDropdownModal(true);
  };

  const hideDropdownModal = () => {
    dispatch(reset('NarrationForm'));
    setDropdownModal(false);
    setConfirmLoading(false);
    setEditDropdownId(null);
    dispatch(clearNarration());
  };

  const onNew = async () => {
    try {
      setConfirmLoading(true);
      await Api.post('/dropdowns/bank-narrations', FormValues);
      dispatch(reset('NarrationForm'));
      setConfirmLoading(false);
      setDropdownModal(false);
      successNotification('Narration added');
      dispatch(clearNarration());
      dispatch(getNarrationList());
    } catch (err) {
      setConfirmLoading(false);
      errorNotification(err);
    }
  };

  const onEdit = async () => {
    try {
      setConfirmLoading(true);
      await Api.put(`/dropdowns/bank-narrations/${editDropdownId}`, FormValues);
      dispatch(reset('NarrationForm'));
      setConfirmLoading(false);
      setDropdownModal(false);
      successNotification('Narration updated');
      dispatch(clearNarration());
      dispatch(getNarrationList());
    } catch (err) {
      setConfirmLoading(false);
      errorNotification(err);
    }
  };

  const columns = [
    {
      title: 'Parent Head',
      dataIndex: 'parentHead',
      sorter: sorter('parentHead'),
      filterDropdown,
      onFilter: onFilter('parentHead'),
    },
    {
      title: 'Summary Head',
      dataIndex: 'head',
      sorter: sorter('head'),
      filterDropdown,
      onFilter: onFilter('head'),
    },
    {
      title: 'Narration',
      dataIndex: 'narration',
      sorter: sorter('narration'),
      filterDropdown,
      onFilter: onFilter('narration'),
    },
    {
      title: 'Status',
      dataIndex: 'isActive',
      sorter: sorter('isActive'),
      filterDropdown,
      onFilter: onFilter('isActive'),
      render: isActive => startCase(isActive),
    },
  ];

  const renderActionIcons = record => (
    <div>
      {permissions.includes('edit_narration') ? (
        <>
          <Tooltip placement="bottom" title="Edit">
            <EditOutlined className={classes.tableIcon} onClick={() => showEditDropdownModal(record.id)} />
          </Tooltip>
          &nbsp;&nbsp;&nbsp;&nbsp;
        </>
      ) : null}
    </div>
  );

  if (permissions.includes('edit_narration')) {
    columns.push({
      title: 'Actions',
      width: 70,
      render: renderActionIcons,
    });
  }

  return (
    <Structure>
      <>
        <Heading text="Narration List">
          {permissions.includes('new_narration') ? (
            <Button className={classes.button} onClick={showDropdownModal}>
              New Narration
            </Button>
          ) : null}
        </Heading>
        <Table
          columns={columns}
          bordered
          rowKey={record => record.id}
          pagination={{ defaultPageSize: 20, position: ['bottomLeft'], showTotal: total => `Total ${total} Items` }}
          dataSource={narrationList}
          size="small"
          scroll={{ x: 1050, y: window.innerHeight - 300 }}
        />
        <Modal
          confirmLoading={confirmLoading}
          open={dropdownModal}
          onCancel={hideDropdownModal}
          centered
          maskClosable={false}
          title={editDropdownId ? 'Edit Narration.' : 'New Narration.'}
          closable={false}
          width="600px"
          cancelText="Back"
          okText={
            editDropdownId ? (
              <Popconfirm
                title="Sure want to submit form?"
                onConfirm={() => onEdit()}
                okText="Confirm"
                cancelText="Cancel"
              >
                Submit
              </Popconfirm>
            ) : (
              <Popconfirm
                title="Sure want to submit form?"
                onConfirm={() => onNew()}
                okText="Confirm"
                cancelText="Cancel"
              >
                Submit
              </Popconfirm>
            )
          }
          okButtonProps={{ className: classes.button }}
        >
          <Field label="Parent Heads" allowClear required name="parentHead" component={AntSelect}>
            <Select.Option key={null} value="" disabled>
              Select parent head
            </Select.Option>
            {Object.values(constants.bank.parentHead).map(head => (
              <Select.Option key={head} value={head}>
                {head}
              </Select.Option>
            ))}
          </Field>
          <Field label="Summary Heads" allowClear required name="head" component={AntSelect}>
            <Select.Option key={null} value="" disabled>
              Select summary head
            </Select.Option>
            {Object.values(constants.bank.summaryHead).map(head => (
              <Select.Option key={head} value={head}>
                {head}
              </Select.Option>
            ))}
          </Field>
          <Field label="Narration" required allowClear name="narration" placeholder="Narration" component={AntInput} />
          <Field label="Status" allowClear required name="isActive" component={AntSelect}>
            <Select.Option key={null} value="" disabled>
              Select status
            </Select.Option>
            <Select.Option key="true" value>
              True
            </Select.Option>
            <Select.Option key="false" value={false}>
              False
            </Select.Option>
          </Field>
        </Modal>
      </>
    </Structure>
  );
};

Narration.propTypes = {
  classes: PropTypes.object.isRequired,
};

const StyledNarration = withStyles(styles)(Narration);

const FormedNarration = reduxForm({
  form: 'NarrationForm',
  enableReinitialize: true,
})(StyledNarration);

export default connect(({ narration }) => ({ initialValues: narration }), {})(FormedNarration);

import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import withStyles from 'react-jss';
import { useHistory } from 'react-router';
import { Button, Row, Input, Select, Modal, Col, Popconfirm } from 'antd';
import { reduxForm, Field, getFormValues } from 'redux-form';
import startCase from 'lodash/startCase';
import { connect, useDispatch, useSelector } from 'react-redux';
import Structure from '../Structure/Structure';
import styles from '../CommonStyles';
import { successNotification, errorNotification } from '../Snackbar';
import Api from '../Api';
import LeftGrid from '../Components/LeftGrid';
import RightGrid from '../Components/RightGrid';
import GetField from '../Components/GetField';
import { materialTypes } from '../constants';
import {
  getUnitIdList,
  clearUnitIdList,
  getMaterial,
  clearMaterial,
  getHSNCodeIdList,
  clearHSNCodeIdList,
  getCustomerIdList,
  clearCustomerIdList,
  getLocationList,
  clearLocationList,
} from '../Actions';
import Heading from '../Components/Heading';
import { get } from '../utils';

const AntInput = GetField(Input);
const AntSelect = GetField(Select);

const EditMaterial = props => {
  const { handleSubmit, classes, match } = props;
  const history = useHistory();
  const dispatch = useDispatch();

  const [formValueModal, setFormValuesModal] = useState(false);
  const [confirmLoading, setConfirmLoading] = useState(false);
  const [preview, setPreview] = useState({});
  const FormValues = useSelector(state => getFormValues('EditMaterial')(state));

  const unitIdList = useSelector(state => state.unitIdList);
  const hsnCodeIdList = useSelector(state => state.hsnCodeIdList);
  const customerIdList = useSelector(state => state.customerIdList);
  const locationList = useSelector(state => state.locationList);

  useEffect(() => {
    dispatch(getUnitIdList());
    dispatch(getHSNCodeIdList());
    dispatch(getLocationList());
    dispatch(getCustomerIdList({ status: 'Active' }));
    dispatch(getMaterial(match.params.id));
    return () => {
      dispatch(clearUnitIdList());
      dispatch(clearHSNCodeIdList());
      dispatch(clearLocationList());
      dispatch(clearMaterial());
      dispatch(clearCustomerIdList());
    };
  }, []);

  const showFormValuesModal = () => {
    const _preview = {
      type: get(FormValues, ['type']) ? startCase(get(FormValues, ['type'])) : '--',
      name: get(FormValues, ['name'], '--'),
      manualRate: get(FormValues, ['manualRate'], '--'),
      maximumStock: get(FormValues, ['maximumStock'], '--'),
      remarks: get(FormValues, ['remarks'], '--'),
      unit: '--',
      hsn: '--',
      customer: '--',
      location: '--',
    };

    const unitId = get(FormValues, ['unitId'], null);
    if (unitId) {
      const unitObj = unitIdList.find(unit => unit.id === +unitId);
      if (unitObj) {
        _preview.unit = unitObj.unit;
      }
    }

    const hsnId = get(FormValues, ['hsnId'], null);
    if (hsnId) {
      const hsnObj = hsnCodeIdList.find(hsn => hsn.id === +hsnId);
      if (hsnObj) {
        _preview.hsn = hsnObj.hsn;
      }
    }

    const customerId = get(FormValues, ['customerId'], null);
    if (customerId) {
      const customerObj = customerIdList.find(customer => customer.id === +customerId);
      if (customerObj) {
        _preview.customer = customerObj.name;
      }
    }

    const locationId = get(FormValues, ['locationId'], null);
    if (locationId) {
      const locationObj = locationList.find(unit => unit.id === +locationId);
      if (locationObj) {
        _preview.location = locationObj.location;
      }
    }

    setPreview(_preview);
    setFormValuesModal(true);
  };

  const hideFormValuesModal = () => {
    setFormValuesModal(false);
    setPreview({});
  };

  const onSubmit = async () => {
    try {
      setConfirmLoading(true);
      await Api.put(`/materials/${match.params.id}`, FormValues);
      successNotification('Material updated');
      setConfirmLoading(false);
      hideFormValuesModal();
      history.push('/material/list');
    } catch (err) {
      setConfirmLoading(false);
      errorNotification(err);
    }
  };

  return (
    <Structure>
      <>
        <Heading text="Edit Material" />
        <form onSubmit={handleSubmit(onSubmit)}>
          <Row>
            <LeftGrid>
              <Field label="Type" allowClear required name="type" component={AntSelect}>
                <Select.Option key={null} value="" disabled>
                  Select type
                </Select.Option>
                {materialTypes.map(type => (
                  <Select.Option key={type} value={type}>
                    {startCase(type)}
                  </Select.Option>
                ))}
              </Field>
            </LeftGrid>
            <RightGrid>
              <Field label="Name" required allowClear name="name" placeholder="Name" component={AntInput} />
            </RightGrid>
          </Row>
          <Row>
            <LeftGrid>
              <Field label="Unit" allowClear required name="unitId" component={AntSelect}>
                <Select.Option key={null} value="" disabled>
                  Select unit
                </Select.Option>
                {unitIdList.map(unit => (
                  <Select.Option key={unit.id} value={unit.id}>
                    {unit.unit}
                  </Select.Option>
                ))}
              </Field>
            </LeftGrid>
            <RightGrid>
              <Field
                label="Manual Rate"
                allowClear
                type="number"
                min={0}
                name="manualRate"
                placeholder="Manual Rate"
                component={AntInput}
              />
            </RightGrid>
          </Row>
          <Row>
            <LeftGrid>
              <Field
                label="Maximum Stock"
                allowClear
                required
                type="number"
                min={0}
                name="maximumStock"
                placeholder="Maximum Stock"
                component={AntInput}
              />
            </LeftGrid>
            <RightGrid>
              <Field label="HSN Code" allowClear required name="hsnId" component={AntSelect}>
                <Select.Option key={null} value="" disabled>
                  Select HSN Code
                </Select.Option>
                {hsnCodeIdList.map(hsn => (
                  <Select.Option key={hsn.id} value={hsn.id}>
                    {hsn.hsn}
                  </Select.Option>
                ))}
              </Field>
            </RightGrid>
          </Row>
          <Row>
            <LeftGrid>
              <Field label="Customer" allowClear required name="customerId" component={AntSelect}>
                <Select.Option key={null} value="" disabled>
                  Select customer
                </Select.Option>
                {customerIdList.map(customer => (
                  <Select.Option key={customer.id} value={customer.id}>
                    {customer.name}
                  </Select.Option>
                ))}
              </Field>
            </LeftGrid>
            <RightGrid>
              <Field label="Location" allowClear required name="locationId" component={AntSelect}>
                <Select.Option key={null} value="" disabled>
                  Select location
                </Select.Option>
                {locationList.map(location => (
                  <Select.Option key={location.id} value={location.id}>
                    {location.location}
                  </Select.Option>
                ))}
              </Field>
            </RightGrid>
          </Row>
          <Row>
            <LeftGrid>
              <Field label="Remarks" allowClear name="remarks" placeholder="Remarks" component={AntInput} />
            </LeftGrid>
          </Row>
          <Row className={classes.buttonContainer}>
            <Button className={classes.button} type="primary" onClick={showFormValuesModal}>
              Preview
            </Button>
          </Row>
        </form>
        <Modal
          onCancel={hideFormValuesModal}
          open={formValueModal}
          confirmLoading={confirmLoading}
          centered
          width="800px"
          maskClosable={false}
          closable={false}
          cancelText="Back"
          okButtonProps={{ className: classes.button }}
          okText={
            <Popconfirm
              title="Sure want to submit form?"
              onConfirm={() => onSubmit()}
              okText="Confirm"
              cancelText="Cancel"
            >
              Submit
            </Popconfirm>
          }
        >
          <Row>
            <Col xs={24} lg={12}>
              <p>
                Type:
                <span className={classes.detailColor}>{preview.type}</span>
              </p>
            </Col>
            <Col xs={24} lg={12}>
              <p>
                Name:
                <span className={classes.detailColor}>{preview.name}</span>
              </p>
            </Col>
          </Row>
          <Row>
            <Col xs={24} lg={12}>
              <p>
                Unit:
                <span className={classes.detailColor}>{preview.unit}</span>
              </p>
            </Col>
            <Col xs={24} lg={12}>
              <p>
                Manual Rate:
                <span className={classes.detailColor}>{preview.manualRate}</span>
              </p>
            </Col>
          </Row>
          <Row>
            <Col xs={24} lg={12}>
              <p>
                Maximum Stock:
                <span className={classes.detailColor}>{preview.maximumStock}</span>
              </p>
            </Col>
            <Col xs={24} lg={12}>
              <p>
                HSN Code:
                <span className={classes.detailColor}>{preview.hsn}</span>
              </p>
            </Col>
          </Row>
          <Row>
            <Col xs={24} lg={12}>
              <p>
                Customer:
                <span className={classes.detailColor}>{preview.customer}</span>
              </p>
            </Col>
            <Col xs={24} lg={12}>
              <p>
                Location:
                <span className={classes.detailColor}>{preview.location}</span>
              </p>
            </Col>
          </Row>
          <Row>
            <Col xs={24} lg={12}>
              <p>
                Remarks:
                <span className={classes.detailColor}>{preview.remarks}</span>
              </p>
            </Col>
          </Row>
        </Modal>
      </>
    </Structure>
  );
};

EditMaterial.propTypes = {
  handleSubmit: PropTypes.func.isRequired,
  classes: PropTypes.object.isRequired,
  match: PropTypes.object.isRequired,
};

const StyledEditMaterial = withStyles(styles)(EditMaterial);

const FormedEditMaterial = reduxForm({
  form: 'EditMaterial',
  enableReinitialize: true,
})(StyledEditMaterial);

export default connect(({ material }) => ({ initialValues: material }), {})(FormedEditMaterial);

import React, { useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Table } from 'antd';
import moment from 'moment';
import startCase from 'lodash/startCase';
import Structure from '../Structure/Structure';
import { getSentWhatsAppMessages, clearWhatsAppMessages } from '../Actions';
import { onDateFilter, dateFilterDropdown, dateSorter, sorter, onFilter, filterDropdown } from '../utils';

const Sent = () => {
  const dispatch = useDispatch();

  const sentWhatsAppMessages = useSelector(state => state.sentWhatsAppMessages);

  useEffect(() => {
    dispatch(getSentWhatsAppMessages());
    return () => {
      dispatch(clearWhatsAppMessages());
    };
  }, []);

  const reasonColumn = record => {
    if (record.payload?.template?.notification?.name === 'purchase_order_add') {
      return `Sent on PO create for PO # ${record.payload?.template?.notification?.components[0]?.parameters[0]?.text}`;
    }
    if (record.payload?.template?.notification?.name === 'purchase_order_edit') {
      return `Sent on PO update for PO # ${record.payload?.template?.notification?.components[0]?.parameters[0]?.text}`;
    }
    if (record.payload?.template?.notification?.name === 'order_sale') {
      return `Invoice created for Order # ${record.payload?.template?.notification?.components[0]?.parameters[1]?.text}`;
    }
    if (
      record.payload?.template?.notification?.name === 'order_sale_pdf' ||
      record.payload?.template?.notification?.name === 'order_sale_invoice'
    ) {
      return `Invoice file link sent for Order # ${record.payload?.template?.notification?.components[0]?.parameters[0]?.text}`;
    }
    if (record.payload?.template?.notification?.name === 'shipping_details') {
      return `Sent on Courier entry: ${record.payload?.template?.notification?.components[0]?.parameters[0]?.text} on ${
        record.payload?.template?.notification?.components[0]?.parameters[1]?.text
          ? moment(record.payload?.template?.notification?.components[0]?.parameters[1]?.text).format('DD MMM YYYY')
          : null
      }`;
    }
    if (record.payload?.template?.notification?.name === 'track_detail') {
      return `Sent on tracking details: # ${
        record.payload?.template?.notification?.components[0]?.parameters[0]?.text
      } on ${
        record.payload?.template?.notification?.components[0]?.parameters[1]?.text
          ? moment(record.payload?.template?.notification?.components[0]?.parameters[1]?.text).format('DD MMM YYYY')
          : null
      }`;
    }
    if (record.payload?.template?.notification?.name === 'order_approval') {
      return `Sent on order approval for Order # ${record.payload?.template?.notification?.components[0]?.parameters[1]?.text}`;
    }
    if (record.payload?.template?.notification?.name === 'order_update') {
      return `Sent on order update for Order # ${record.payload?.template?.notification?.components[0]?.parameters[1]?.text}`;
    }

    return startCase(record.payload?.template?.notification?.name);
  };

  const statusColumn = record => {
    if (record.statuscode === 200 || record.statuscode === 201) {
      return 'Success';
    }
    if (record.statuscode === 500) {
      return startCase(record.result);
    }
    return record.statuscode;
  };

  const columns = [
    {
      title: 'Recipient',
      dataIndex: 'party',
      key: 'party',
      sorter: sorter('party'),
      filterDropdown,
      onFilter: onFilter('party'),
      width: '15%',
    },
    {
      title: 'Reason',
      dataIndex: 'reason',
      render: (_text, record) => reasonColumn(record),
    },
    {
      title: 'Status',
      dataIndex: 'status',
      render: (_text, record) => statusColumn(record),
      width: '10%',
    },
    {
      title: 'Created',
      dataIndex: 'created',
      sorter: dateSorter('created'),
      filterDropdown: dateFilterDropdown,
      onFilter: onDateFilter('created'),
      render: created => (created ? moment(created).format('LLLL') : null),
      width: '8%',
    },
    {
      title: 'Sent',
      dataIndex: 'sent',
      sorter: dateSorter('sent'),
      filterDropdown: dateFilterDropdown,
      onFilter: onDateFilter('sent'),
      render: sent => (sent ? moment(sent).format('LLLL') : null),
      width: '8%',
    },
    {
      title: 'Received',
      dataIndex: 'received',
      sorter: dateSorter('received'),
      filterDropdown: dateFilterDropdown,
      onFilter: onDateFilter('received'),
      render: received => (received ? moment(received).format('LLLL') : null),
      width: '8%',
    },
    {
      title: 'Read',
      dataIndex: 'read',
      sorter: dateSorter('read'),
      filterDropdown: dateFilterDropdown,
      onFilter: onDateFilter('read'),
      render: read => (read ? moment(read).format('LLLL') : null),
      width: '8%',
    },
  ];

  return (
    <Structure>
      <>
        <Table
          columns={columns}
          bordered
          rowKey={record => record.uuid}
          pagination={{ defaultPageSize: 20, position: ['bottomLeft'], showTotal: total => `Total ${total} Records` }}
          dataSource={sentWhatsAppMessages}
          size="small"
          scroll={{ x: 1050, y: window.innerHeight - 300 }}
        />
      </>
    </Structure>
  );
};

export default Sent;

import React, { useEffect, useCallback, useState, Fragment } from 'react';
import PropTypes from 'prop-types';
import withStyles from 'react-jss';
import { useHistory } from 'react-router';
import { useForm, Controller, useFieldArray, useWatch } from 'react-hook-form';
import { Button, Input, Select, Row } from 'antd';
import { useDispatch, useSelector } from 'react-redux';

import LeftGrid from '../../Components/LeftGrid';
import RightGrid from '../../Components/RightGrid';
import GetField from '../../Components/GetRHFField';
import styles from '../../CommonStyles';
import {
  getChargeIds,
  getLeadIds,
  clearChargeIds,
  clearLeadIds,
  getCustomerIdList,
  clearCustomerIdList,
  getHSNCodeList,
  clearHSNCodeList,
  getUnitIdList,
  clearUnitIdList,
  getProformaInvoice,
  clearProformaInvoice,
  clearLeadQueries,
  getLeadQueries,
} from '../../Actions';
import { successNotification, errorNotification } from '../../Snackbar';
import Api from '../../Api';
import ProductTable from './ProductTable';
import ChargeTable from './ChargeTable';
import Structure from '../../Structure/Structure';
import Heading from '../../Components/Heading';
import { consignorList } from '../../constants';

const AntSelect = GetField(Select);
const AntInput = GetField(Input);

const { Option } = Select;

const Edit = props => {
  const { classes, match } = props;
  const history = useHistory();

  const dispatch = useDispatch();

  const { handleSubmit, control, reset, setValue, getValues, watch } = useForm({
    shouldUnregister: true,
    defaultValues: {
      products: [
        {
          product: null,
          quantity: null,
          rate: null,
          value: null,
          tax: null,
          taxValue: null,
          total: null,
        },
      ],
      charges: [
        {
          chargeId: null,
          value: null,
          tax: null,
          taxValue: null,
          total: null,
        },
      ],
    },
  });

  const {
    fields: productFields,
    append: productAppend,
    remove: productRemove,
  } = useFieldArray({
    control,
    name: 'products',
  });

  const {
    fields: chargeFields,
    append: chargeAppend,
    remove: chargeRemove,
  } = useFieldArray({
    control,
    name: 'charges',
  });

  const type = useWatch({ control, name: 'type' });
  const leadId = useWatch({ control, name: 'leadId' });
  const partyId = useWatch({ control, name: 'partyId' });

  const leadIds = useSelector(state => state.leadIds);
  const partyIds = useSelector(state => state.customerIdList);
  const queryIds = useSelector(state => state.queryIds);
  const proformaInvoice = useSelector(state => state.proformaInvoice);

  const [confirmLoading, setConfirmLoading] = useState(false);

  useEffect(() => {
    dispatch(getCustomerIdList({ status: 'Active' }));
    dispatch(getUnitIdList());
    dispatch(getLeadIds());
    dispatch(getChargeIds());
    dispatch(getHSNCodeList());
    dispatch(getProformaInvoice(match.params.id));
    return () => {
      dispatch(clearCustomerIdList());
      dispatch(clearUnitIdList());
      dispatch(clearLeadIds());
      dispatch(clearChargeIds());
      dispatch(clearHSNCodeList());
      dispatch(clearProformaInvoice());
    };
  }, []);

  useEffect(() => {
    dispatch(clearLeadQueries());

    if (leadId || partyId) {
      dispatch(getLeadQueries({ leadId, partyId }));
    }
  }, [leadId, partyId]);

  useEffect(() => {
    reset(proformaInvoice);
    setValue('partyId', proformaInvoice?.partyId);
    setValue('queryId', proformaInvoice?.queryId);
  }, [proformaInvoice]);

  const onSubmit = useCallback(async formValues => {
    try {
      setConfirmLoading(true);
      await Api.put(`/proforma-invoices/${match.params.id}`, formValues);
      reset();
      successNotification('Proforma Invoice edited');
      history.push('/proformainvoices/list');
    } catch (err) {
      setConfirmLoading(false);
      errorNotification(err);
    }
  }, []);

  return (
    <Structure>
      <>
        <Heading text="Edit PI" />
        <form onSubmit={handleSubmit(onSubmit)}>
          <Row>
            <LeftGrid>
              <Controller
                name="consignor"
                control={control}
                render={({ field }) => (
                  <AntSelect allowClear label="Consignor" placeholder="Select consignor" {...field}>
                    {consignorList.map(consignor => (
                      <Option key={consignor} value={consignor}>
                        {consignor}
                      </Option>
                    ))}
                  </AntSelect>
                )}
              />
            </LeftGrid>
            <RightGrid>
              <Controller
                name="type"
                control={control}
                render={({ field }) => (
                  <AntSelect
                    allowClear
                    label="Whom"
                    placeholder="Select whom"
                    {...field}
                    onChange={value => {
                      field.onChange(value);
                      setValue('leadId', null);
                      setValue('partyId', null);
                      setValue('queryId', null);
                    }}
                  >
                    <Option key="lead" value="lead">
                      Lead
                    </Option>
                    <Option key="party" value="party">
                      Party
                    </Option>
                  </AntSelect>
                )}
              />
            </RightGrid>
          </Row>
          <Row>
            <LeftGrid>
              {type === 'party' ? (
                <Controller
                  name="partyId"
                  control={control}
                  render={({ field }) => (
                    <AntSelect
                      allowClear
                      label="Party"
                      placeholder="Select party"
                      {...field}
                      onChange={value => {
                        field.onChange(value);
                        setValue('leadId', null);
                        setValue('queryId', null);
                      }}
                    >
                      {partyIds.map(party => (
                        <Option key={party.id} value={party.id}>
                          {party.name}
                        </Option>
                      ))}
                    </AntSelect>
                  )}
                />
              ) : (
                <Controller
                  name="leadId"
                  control={control}
                  render={({ field }) => (
                    <AntSelect
                      allowClear
                      label="Lead"
                      placeholder="Select lead"
                      {...field}
                      onChange={value => {
                        field.onChange(value);
                        setValue('partyd', null);
                        setValue('queryId', null);
                      }}
                    >
                      {leadIds.map(lead => (
                        <Option key={lead.id} value={lead.id}>
                          {lead.company}
                        </Option>
                      ))}
                    </AntSelect>
                  )}
                />
              )}
            </LeftGrid>
            <RightGrid>
              <Controller
                name="queryId"
                control={control}
                render={({ field }) => (
                  <AntSelect allowClear label="Query" placeholder="Select query" {...field}>
                    {queryIds.map(query => (
                      <Option key={query.id} value={query.id}>
                        {query.query}
                      </Option>
                    ))}
                  </AntSelect>
                )}
              />
            </RightGrid>
          </Row>

          <Row className={classes.padding5px}>
            <div style={{ overflow: 'auto' }}>
              <div style={{ minWidth: '1600px' }}>
                <table className={classes.inputTable}>
                  <thead className={classes.inputThead}>
                    <tr key="header">
                      <th className={classes.inputTh} style={{ width: '5%' }}>
                        Remove
                      </th>
                      <th className={classes.inputTh}>Product</th>
                      <th className={classes.inputTh} style={{ width: '10%' }}>
                        Pack Size
                      </th>
                      <th className={classes.inputTh} style={{ width: '6%' }}>
                        Unit
                      </th>
                      <th className={classes.inputTh} style={{ width: '7%' }}>
                        HSN
                      </th>
                      <th className={classes.inputTh} style={{ width: '7%' }}>
                        Quantity
                      </th>
                      <th className={classes.inputTh} style={{ width: '7%' }}>
                        Rate
                      </th>
                      <th className={classes.inputTh} style={{ width: '7%' }}>
                        Value
                      </th>
                      <th className={classes.inputTh} style={{ width: '7%' }}>
                        Tax (in %)
                      </th>
                      <th className={classes.inputTh} style={{ width: '7%' }}>
                        Tax Value
                      </th>
                      <th className={classes.inputTh} style={{ width: '7%' }}>
                        Total
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    {productFields.map((item, index) => (
                      <ProductTable
                        key={item.id}
                        productItem={item}
                        productIndex={index}
                        productRemove={productRemove}
                        getValues={getValues}
                        setValue={setValue}
                        watch={watch}
                        control={control}
                      />
                    ))}
                  </tbody>
                </table>
              </div>
            </div>
          </Row>
          <br />
          <Row className={classes.padding5px}>
            <Button
              type="primary"
              htmlType="button"
              onClick={() =>
                productAppend({
                  product: null,
                  quantity: null,
                  rate: null,
                  value: null,
                  tax: null,
                  taxValue: null,
                  total: null,
                })
              }
            >
              Add Product
            </Button>
          </Row>
          <br />
          <Row className={classes.padding5px}>
            <div style={{ overflow: 'auto' }}>
              <div style={{ minWidth: '800px' }}>
                <table className={classes.inputTable}>
                  <thead className={classes.inputThead}>
                    <tr key="header">
                      <th className={classes.inputTh}>Charge Name</th>
                      <th className={classes.inputTh} style={{ width: '10%' }}>
                        Charge
                      </th>
                      <th className={classes.inputTh} style={{ width: '8%' }}>
                        Tax (in %)
                      </th>
                      <th className={classes.inputTh} style={{ width: '8%' }}>
                        Tax Value
                      </th>
                      <th className={classes.inputTh} style={{ width: '10%' }}>
                        Total
                      </th>
                      <th className={classes.inputTh} style={{ width: '8%' }}>
                        Remove
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    {chargeFields.map((item, index) => (
                      <ChargeTable
                        key={item.id}
                        chargeItem={item}
                        chargeIndex={index}
                        chargeRemove={chargeRemove}
                        getValues={getValues}
                        setValue={setValue}
                        watch={watch}
                        control={control}
                      />
                    ))}
                  </tbody>
                </table>
              </div>
            </div>
          </Row>
          <br />
          <Row className={classes.padding5px}>
            <Button
              type="primary"
              htmlType="button"
              onClick={() =>
                chargeAppend({
                  chargeId: null,
                  value: null,
                  tax: null,
                  taxValue: null,
                  total: null,
                })
              }
            >
              Add Charge
            </Button>
          </Row>
          <Row>
            <LeftGrid>
              <Controller
                name="productsTotal"
                control={control}
                render={({ field }) => (
                  <AntInput
                    type="number"
                    disabled
                    min={0}
                    label="Product Total"
                    placeholder="Product Total"
                    {...field}
                  />
                )}
              />
            </LeftGrid>
            <RightGrid>
              <Controller
                name="chargesTotal"
                control={control}
                render={({ field }) => (
                  <AntInput type="number" disabled min={0} label="Charge Total" placeholder="Charge Total" {...field} />
                )}
              />
            </RightGrid>
          </Row>
          <Row>
            <LeftGrid>
              <Controller
                name="grandTotal"
                control={control}
                render={({ field }) => (
                  <AntInput type="number" disabled min={0} label="Grand Total" placeholder="Grand Total" {...field} />
                )}
              />
            </LeftGrid>
            <RightGrid>
              <Controller
                name="remark"
                control={control}
                render={({ field }) => <AntInput allowClear label="Remark" placeholder="Remark" {...field} />}
              />
            </RightGrid>
          </Row>
          <Row>
            <LeftGrid>
              <Controller
                name="validForDays"
                control={control}
                render={({ field }) => (
                  <AntInput
                    allowClear
                    type="number"
                    min={0}
                    label="Valid for (in days)"
                    placeholder="Valid for (in days)"
                    {...field}
                  />
                )}
              />
            </LeftGrid>
            <RightGrid>
              <Controller
                name="paymentTerm"
                control={control}
                render={({ field }) => (
                  <AntInput allowClear label="Payment Term" placeholder="Payment Term" {...field} />
                )}
              />
            </RightGrid>
          </Row>
          <Row>
            <LeftGrid>
              <Controller
                name="delivery"
                control={control}
                render={({ field }) => <AntInput allowClear label="Delivery" placeholder="Delivery" {...field} />}
              />
            </LeftGrid>
            <RightGrid>
              <Controller
                name="transport"
                control={control}
                render={({ field }) => <AntInput allowClear label="Transport" placeholder="Transport" {...field} />}
              />
            </RightGrid>
          </Row>
          <Row className={classes.buttonContainer}>
            <Button type="primary" loading={confirmLoading} htmlType="submit">
              Submit
            </Button>
          </Row>
        </form>
      </>
    </Structure>
  );
};

Edit.propTypes = {
  classes: PropTypes.object.isRequired,
  match: PropTypes.object.isRequired,
};

export default withStyles(styles)(Edit);

import React, { useEffect, useState } from 'react';
import { useSelector, useDispatch, connect } from 'react-redux';
import withStyles from 'react-jss';
import PropTypes from 'prop-types';
import { Table, Popconfirm, Tooltip, Button, Input, Modal } from 'antd';
import { EditOutlined, DeleteOutlined } from '@ant-design/icons';
import { reduxForm, Field, getFormValues, reset } from 'redux-form';
import Structure from '../Structure/Structure';
import { sorter, onFilter, filterDropdown, get } from '../utils';
import Api from '../Api';
import { getEmployeeRoleList, clearEmployeeRoleList, getEmployeeRole, clearEmployeeRole } from '../Actions';
import { errorNotification, successNotification } from '../Snackbar';
import styles from '../CommonStyles';
import Heading from '../Components/Heading';
import GetField from '../Components/GetField';

const AntInput = GetField(Input);

const EmployeeRole = props => {
  const { classes } = props;
  const dispatch = useDispatch();

  const auth = useSelector(state => state.auth);
  const permissions = get(auth, ['permissions'], []);
  const employeeRoleList = useSelector(state => state.employeeRoleList);

  const [dropdownModal, setDropdownModal] = useState(false);
  const [confirmLoading, setConfirmLoading] = useState(false);
  const [editDropdownId, setEditDropdownId] = useState(null);

  const FormValues = useSelector(state => getFormValues('EmployeeRoleForm')(state));

  useEffect(() => {
    dispatch(getEmployeeRoleList());
    return () => {
      dispatch(clearEmployeeRoleList());
    };
  }, []);

  const onDelete = async id => {
    try {
      await Api.delete(`/dropdowns/employee-role/${id}`);
      successNotification('Employee role deleted');
      dispatch(getEmployeeRoleList());
    } catch (err) {
      errorNotification(err);
    }
  };

  const showDropdownModal = () => {
    setEditDropdownId(null);
    setDropdownModal(true);
  };

  const showEditDropdownModal = id => {
    setEditDropdownId(id);
    dispatch(getEmployeeRole(id));
    setDropdownModal(true);
  };

  const hideDropdownModal = () => {
    dispatch(reset('EmployeeRoleForm'));
    setConfirmLoading(false);
    setEditDropdownId(null);
    dispatch(clearEmployeeRole());
    setDropdownModal(false);
  };

  const onNew = async () => {
    try {
      setConfirmLoading(true);
      await Api.post('/dropdowns/employee-role', FormValues);
      dispatch(reset('EmployeeRoleForm'));
      setConfirmLoading(false);
      setDropdownModal(false);
      successNotification('Employee role added');
      dispatch(clearEmployeeRole());
      dispatch(getEmployeeRoleList());
    } catch (err) {
      setConfirmLoading(false);
      errorNotification(err);
    }
  };

  const onEdit = async () => {
    try {
      setConfirmLoading(true);
      await Api.put(`/dropdowns/employee-role/${editDropdownId}`, FormValues);
      dispatch(reset('EmployeeRoleForm'));
      setConfirmLoading(false);
      setDropdownModal(false);
      successNotification('Employee role updated');
      dispatch(clearEmployeeRole());
      dispatch(getEmployeeRoleList());
    } catch (err) {
      setConfirmLoading(false);
      errorNotification(err);
    }
  };

  const columns = [
    {
      title: 'Role',
      dataIndex: 'role',
      sorter: sorter('role'),
      filterDropdown,
      onFilter: onFilter('role'),
    },
  ];

  const renderActionIcons = record => (
    <div>
      {permissions.includes('edit_employee_role') ? (
        <>
          <Tooltip placement="bottom" title="Edit">
            <EditOutlined className={classes.tableIcon} onClick={() => showEditDropdownModal(record.id)} />
          </Tooltip>
          &nbsp;&nbsp;&nbsp;&nbsp;
        </>
      ) : null}
      {permissions.includes('delete_employee_role') ? (
        <>
          <Tooltip placement="bottom" title="Delete">
            <Popconfirm
              title="Are you sure you want to delete?"
              onConfirm={() => onDelete(record.id)}
              okText="Yes"
              cancelText="No"
            >
              <DeleteOutlined className={classes.tableIcon} />
            </Popconfirm>
          </Tooltip>
        </>
      ) : null}
    </div>
  );

  if (permissions.includes('edit_employee_role') || permissions.includes('delete_employee_role')) {
    columns.push({
      title: 'Actions',
      width: 70,
      render: renderActionIcons,
    });
  }

  return (
    <Structure>
      <>
        <Heading text="Employee Role">
          {permissions.includes('new_employee_role') ? (
            <Button className={classes.button} onClick={showDropdownModal}>
              New Employee Role
            </Button>
          ) : null}
        </Heading>
        <Table
          columns={columns}
          bordered
          rowKey={record => record.id}
          pagination={{ defaultPageSize: 20, position: ['bottomLeft'], showTotal: total => `Total ${total} Items` }}
          dataSource={employeeRoleList}
          size="small"
          scroll={{ x: 1050, y: window.innerHeight - 300 }}
        />
        <Modal
          confirmLoading={confirmLoading}
          open={dropdownModal}
          onCancel={hideDropdownModal}
          centered
          maskClosable={false}
          title={editDropdownId ? 'Edit Employee Role.' : 'New Employee Role.'}
          closable={false}
          width="400px"
          cancelText="Back"
          okText={
            editDropdownId ? (
              <Popconfirm
                title="Sure want to submit form?"
                onConfirm={() => onEdit()}
                okText="Confirm"
                cancelText="Cancel"
              >
                Submit
              </Popconfirm>
            ) : (
              <Popconfirm
                title="Sure want to submit form?"
                onConfirm={() => onNew()}
                okText="Confirm"
                cancelText="Cancel"
              >
                Submit
              </Popconfirm>
            )
          }
          okButtonProps={{ className: classes.button }}
        >
          <Field
            label="Employee Role"
            required
            allowClear
            name="role"
            placeholder="Employee Role"
            component={AntInput}
          />
        </Modal>
      </>
    </Structure>
  );
};

EmployeeRole.propTypes = {
  classes: PropTypes.object.isRequired,
};

const StyledEmployeeRole = withStyles(styles)(EmployeeRole);

const FormedEmployeeRole = reduxForm({
  form: 'EmployeeRoleForm',
  enableReinitialize: true,
})(StyledEmployeeRole);

export default connect(({ employeeRole }) => ({ initialValues: employeeRole }), {})(FormedEmployeeRole);

export const hsnCodeList = (state = [], action) => {
  if (action.type === 'FETCH_HSN_CODE_LIST') {
    return action.payload;
  }
  if (action.type === 'CLEAR_HSN_CODE_LIST') {
    return [];
  }
  return state;
};

export const hsnCode = (state = {}, action) => {
  if (action.type === 'FETCH_HSN_CODE') {
    return action.payload;
  }
  if (action.type === 'CLEAR_HSN_CODE') {
    return {};
  }
  return state;
};

export const hsnCodeIdList = (state = [], action) => {
  if (action.type === 'FETCH_HSN_CODE_ID_LIST') {
    return action.payload;
  }
  if (action.type === 'CLEAR_HSN_CODE_ID_LIST') {
    return [];
  }
  return state;
};

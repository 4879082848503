import Api from '../Api';

export const getOrderList = query => async dispatch => {
  try {
    const { data } = await Api.get('/orders', query);
    dispatch({
      type: 'FETCH_ORDER_LIST',
      payload: data,
    });
  } catch (err) {
    throw err;
  }
};

export const clearOrderList = () => {
  return {
    type: 'CLEAR_ORDER_LIST',
  };
};

export const getOrdersWithLessDetails = query => async dispatch => {
  try {
    const { data } = await Api.get('/orders/lessdetails', query);
    dispatch({
      type: 'FETCH_ORDER_LIST_WITH_LESS_DETAILS',
      payload: data,
    });
  } catch (err) {
    throw err;
  }
};

export const clearOrdersWithLessDetails = () => {
  return {
    type: 'CLEAR_ORDER_LIST_WITH_LESS_DETAILS',
  };
};

export const getCompletedOrdersIds = () => async dispatch => {
  try {
    const { data } = await Api.get('/orders/completedids');
    dispatch({
      type: 'FETCH_COMPLETE_ORDERS_IDS',
      payload: data,
    });
  } catch (err) {
    throw err;
  }
};

export const clearCompletedOrdersIds = () => {
  return {
    type: 'CLEAR_COMPLETE_ORDERS_IDS',
  };
};

export const getOrder = id => async dispatch => {
  try {
    const { data } = await Api.get(`/orders/${id}`);
    dispatch({
      type: 'FETCH_ORDER',
      payload: data,
    });
  } catch (err) {
    throw err;
  }
};

export const clearOrder = () => {
  return {
    type: 'CLEAR_ORDER',
  };
};

export const getOrderFilesList = (id, fileType) => async dispatch => {
  try {
    const { data } = await Api.get(`/orders/${id}/files?type=${fileType}`);
    dispatch({
      type: 'FETCH_ORDER_FILES_LIST',
      payload: data,
    });
  } catch (err) {
    throw err;
  }
};

export const clearOrderFilesList = () => {
  return {
    type: 'CLEAR_ORDER_FILES_LIST',
  };
};

export const getOrderShortageItemsList = id => async dispatch => {
  try {
    const { data } = await Api.get(`/purchase-planning/order/${id}`);
    dispatch({
      type: 'FETCH_ORDER_SHORTAGE_ITEMS_LIST',
      payload: data,
    });
  } catch (err) {
    throw err;
  }
};

export const clearOrderShortageItemsList = () => {
  return {
    type: 'CLEAR_ORDER_SHORTAGE_ITEMS_LIST',
  };
};

export const getProductionOrderList = () => async dispatch => {
  try {
    const { data } = await Api.get('/orders/productionorders');

    dispatch({
      type: 'FETCH_PRODUCTION_ORDERS_LIST',
      payload: data,
    });
  } catch (error) {
    throw error;
  }
};

export const clearProductionOrderList = () => {
  return {
    type: 'CLEAR_PRODUCTION_ORDERS_LIST',
  };
};

export const getJobCard = id => async dispatch => {
  try {
    const { data } = await Api.get(`/orders/jobcard/${id}`);
    dispatch({
      type: 'FETCH_JOB_CARD',
      payload: data,
    });
  } catch (err) {
    throw err;
  }
};

export const clearJobCard = () => {
  return {
    type: 'CLEAR_JOB_CARD',
  };
};

export const getOrderDispenseDetailsList = id => async dispatch => {
  try {
    const { data } = await Api.get(`/orders/${id}/dispenses`);
    dispatch({
      type: 'FETCH_ORDER_DISPENSE_DETAILS_LIST',
      payload: data,
    });
  } catch (err) {
    throw err;
  }
};

export const clearOrderDispenseDetailsList = () => {
  return {
    type: 'CLEAR_ORDER_DISPENSE_DETAILS_LIST',
  };
};

export const getOrderBatchMaterialDetail = id => async dispatch => {
  try {
    const { data } = await Api.get(`/orders/${id}/ppmaterials`);
    dispatch({
      type: 'FETCH_ORDER_BATCH_MATERIALS_DETAIL',
      payload: data,
    });
  } catch (err) {
    throw err;
  }
};

export const clearOrderBatchMaterialDetail = () => {
  return {
    type: 'CLEAR_ORDER_BATCH_MATERIALS_DETAIL',
  };
};

export const getOrderFillingDetail = id => async dispatch => {
  try {
    const { data } = await Api.get(`/orders/${id}/fillingchecks`);
    dispatch({
      type: 'FETCH_ORDER_FILLING_DETAIL',
      payload: data,
    });
  } catch (err) {
    throw err;
  }
};

export const clearOrderFillingDetail = () => {
  return {
    type: 'CLEAR_ORDER_FILLING_DETAIL',
  };
};

export const getIsInPlanningOrders = query => async dispatch => {
  try {
    const { data } = await Api.get('/orders/isinplanning', query);
    dispatch({
      type: 'FETCH_IS_IN_PLANNING_ORDERS',
      payload: data,
    });
  } catch (err) {
    throw err;
  }
};

export const clearIsInPlanningOrders = () => {
  return {
    type: 'CLEAR_IS_IN_PLANNING_ORDERS',
  };
};

export const getOrdersInProductionPlanning = query => async dispatch => {
  try {
    const { data } = await Api.get('/orders/isinproductionplanning', query);
    dispatch({
      type: 'FETCH_IS_IN_PRODUCTION_PLANNING_ORDERS',
      payload: data,
    });
  } catch (err) {
    throw err;
  }
};

export const clearOrdersInProductionPlanning = () => {
  return {
    type: 'CLEAR_IS_IN_PRODUCTION_PLANNING_ORDERS',
  };
};

import React, { useEffect, useState } from 'react';
import { useSelector, useDispatch, connect } from 'react-redux';
import withStyles from 'react-jss';
import PropTypes from 'prop-types';
import { Table, Popconfirm, Tooltip, Button, Input, Modal } from 'antd';
import { EditOutlined, DeleteOutlined } from '@ant-design/icons';
import { reduxForm, Field, getFormValues, reset } from 'redux-form';
import Structure from '../Structure/Structure';
import { sorter, onFilter, filterDropdown, get } from '../utils';
import Api from '../Api';
import { getHSNCodeList, clearHSNCodeList, getHSNCode, clearHSNCode } from '../Actions';
import { errorNotification, successNotification } from '../Snackbar';
import styles from '../CommonStyles';
import Heading from '../Components/Heading';
import GetField from '../Components/GetField';

const AntInput = GetField(Input);

const HSNCode = props => {
  const { classes } = props;
  const dispatch = useDispatch();

  const auth = useSelector(state => state.auth);
  const permissions = get(auth, ['permissions'], []);
  const hsnCodeList = useSelector(state => state.hsnCodeList);

  const [dropdownModal, setDropdownModal] = useState(false);
  const [confirmLoading, setConfirmLoading] = useState(false);
  const [editDropdownId, setEditDropdownId] = useState(null);

  const FormValues = useSelector(state => getFormValues('HSNCodeForm')(state));

  useEffect(() => {
    dispatch(getHSNCodeList());
    return () => {
      dispatch(clearHSNCodeList());
    };
  }, []);

  const onDelete = async id => {
    try {
      await Api.delete(`/dropdowns/hsn-codes/${id}`);
      successNotification('HSN Code deleted');
      dispatch(getHSNCodeList());
    } catch (err) {
      errorNotification(err);
    }
  };

  const showDropdownModal = () => {
    setEditDropdownId(null);
    setDropdownModal(true);
  };

  const showEditDropdownModal = id => {
    setEditDropdownId(id);
    dispatch(getHSNCode(id));
    setDropdownModal(true);
  };

  const hideDropdownModal = () => {
    dispatch(reset('HSNCodeForm'));
    setConfirmLoading(false);
    setEditDropdownId(null);
    dispatch(clearHSNCode());
    setDropdownModal(false);
  };

  const onNew = async () => {
    try {
      setConfirmLoading(true);
      await Api.post('/dropdowns/hsn-codes', FormValues);
      dispatch(reset('HSNCodeForm'));
      setConfirmLoading(false);
      setDropdownModal(false);
      successNotification('HSN Code added');
      dispatch(clearHSNCode());
      dispatch(getHSNCodeList());
    } catch (err) {
      setConfirmLoading(false);
      errorNotification(err);
    }
  };

  const onEdit = async () => {
    try {
      setConfirmLoading(true);
      await Api.put(`/dropdowns/hsn-codes/${editDropdownId}`, FormValues);
      dispatch(reset('HSNCodeForm'));
      setConfirmLoading(false);
      setDropdownModal(false);
      successNotification('HSN Code updated');
      dispatch(clearHSNCode());
      dispatch(getHSNCodeList());
    } catch (err) {
      setConfirmLoading(false);
      errorNotification(err);
    }
  };

  const columns = [
    {
      title: 'HSN Code',
      dataIndex: 'hsn',
      sorter: sorter('hsn'),
      filterDropdown,
      onFilter: onFilter('hsn'),
    },
    {
      title: 'GST (in %)',
      dataIndex: 'gst',
      sorter: sorter('gst'),
      filterDropdown,
      onFilter: onFilter('gst'),
    },
  ];

  const renderActionIcons = record => (
    <div>
      {permissions.includes('edit_hsn_code') ? (
        <>
          <Tooltip placement="bottom" title="Edit">
            <EditOutlined className={classes.tableIcon} onClick={() => showEditDropdownModal(record.id)} />
          </Tooltip>
          &nbsp;&nbsp;&nbsp;&nbsp;
        </>
      ) : null}
      {permissions.includes('delete_hsn_code') ? (
        <>
          <Tooltip placement="bottom" title="Delete">
            <Popconfirm
              title="Are you sure you want to delete?"
              onConfirm={() => onDelete(record.id)}
              okText="Yes"
              cancelText="No"
            >
              <DeleteOutlined className={classes.tableIcon} />
            </Popconfirm>
          </Tooltip>
        </>
      ) : null}
    </div>
  );

  if (permissions.includes('edit_hsn_code') || permissions.includes('delete_hsn_code')) {
    columns.push({
      title: 'Actions',
      width: 70,
      render: renderActionIcons,
    });
  }

  return (
    <Structure>
      <>
        <Heading text="HSN Code & GST Slab List">
          {permissions.includes('new_hsn_code') ? (
            <Button className={classes.button} onClick={showDropdownModal}>
              New HSN Code
            </Button>
          ) : null}
        </Heading>
        <Table
          columns={columns}
          bordered
          rowKey={record => record.id}
          pagination={{ defaultPageSize: 20, position: ['bottomLeft'], showTotal: total => `Total ${total} Items` }}
          dataSource={hsnCodeList}
          size="small"
          scroll={{ x: 1050, y: window.innerHeight - 300 }}
        />
        <Modal
          confirmLoading={confirmLoading}
          open={dropdownModal}
          onCancel={hideDropdownModal}
          centered
          maskClosable={false}
          title={editDropdownId ? 'Edit HSN Code.' : 'New HSN Code.'}
          closable={false}
          width="400px"
          cancelText="Back"
          okText={
            editDropdownId ? (
              <Popconfirm
                title="Sure want to submit form?"
                onConfirm={() => onEdit()}
                okText="Confirm"
                cancelText="Cancel"
              >
                Submit
              </Popconfirm>
            ) : (
              <Popconfirm
                title="Sure want to submit form?"
                onConfirm={() => onNew()}
                okText="Confirm"
                cancelText="Cancel"
              >
                Submit
              </Popconfirm>
            )
          }
          okButtonProps={{ className: classes.button }}
        >
          <Field label="HSN Code" required allowClear name="hsn" placeholder="HSN Code" component={AntInput} />
          <Field
            label="GST (in %)"
            type="number"
            name="gst"
            placeholder="GST (in %)"
            min={0}
            required
            allowClear
            component={AntInput}
          />
        </Modal>
      </>
    </Structure>
  );
};

HSNCode.propTypes = {
  classes: PropTypes.object.isRequired,
};

const StyledHSNCode = withStyles(styles)(HSNCode);

const FormedHSNCode = reduxForm({
  form: 'HSNCodeForm',
  enableReinitialize: true,
})(StyledHSNCode);

export default connect(({ hsnCode }) => ({ initialValues: hsnCode }), {})(FormedHSNCode);

export const paymentCategoryList = (state = [], action) => {
  if (action.type === 'FETCH_PAYMENT_CATEGORY_LIST') {
    return action.payload;
  }
  if (action.type === 'CLEAR_PAYMENT_CATEGORY_LIST') {
    return [];
  }
  return state;
};

export const paymentCategory = (state = {}, action) => {
  if (action.type === 'FETCH_PAYMENT_CATEGORY') {
    return action.payload;
  }
  if (action.type === 'CLEAR_PAYMENT_CATEGORY') {
    return {};
  }
  return state;
};

export const paymentCategoryReport = (state = [], action) => {
  if (action.type === 'FETCH_PAYMENT_CATEGORY_REPPORT') {
    return action.payload;
  }
  if (action.type === 'CLEAR_PAYMENT_CATEGORY_REPORT') {
    return [];
  }
  return state;
};

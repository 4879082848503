import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { Table, Popconfirm, Tooltip, Modal, Row, Col, Select } from 'antd';
import PropTypes from 'prop-types';
import { useSelector, useDispatch } from 'react-redux';
import { CloudDownloadOutlined, DeleteOutlined, EditOutlined, FileTextOutlined } from '@ant-design/icons';
import { Link } from 'react-router-dom';
import moment from 'moment';

import withStyles from 'react-jss';
import { errorNotification, successNotification } from '../../Snackbar';
import { sorter, onFilter, filterDropdown, dateSorter, onDateFilter, dateFilterDropdown } from '../../utils';
import Api from '../../Api';
import { getProformaInvoices, clearProformaInvoices, getPIFilesList, clearPIFilesList } from '../../Actions';
import Structure from '../../Structure/Structure';
import Heading from '../../Components/Heading';
import styles from '../../CommonStyles';

const List = props => {
  const { classes } = props;

  const dispatch = useDispatch();

  const auth = useSelector(state => state.auth);
  const permissions = useMemo(() => auth?.permissions || [], [auth]);
  const proformaInvoices = useSelector(state => state.proformaInvoices);
  const piFilesList = useSelector(state => state.piFilesList);

  const [piId, setPIId] = useState(null);
  const [modalType, setModalType] = useState(null);

  const [financialYear, setFinancialYear] = useState('2024');

  useEffect(() => {
    // dispatch(getProformaInvoices());
    return () => {
      dispatch(clearProformaInvoices());
      dispatch(clearPIFilesList());
    };
  }, []);

  useEffect(() => {
    dispatch(getProformaInvoices({ financialYear }));
  }, [financialYear]);

  useEffect(() => {
    if (piId && modalType === 'ViewFiles') {
      dispatch(getPIFilesList(piId));
    }
  }, [piId, modalType]);

  const showModal = (id, type) => {
    setPIId(id);
    setModalType(type);
  };

  const hideModal = () => {
    setPIId(null);
    setModalType(null);
    dispatch(clearPIFilesList());
  };

  const onDelete = useCallback(async id => {
    try {
      await Api.delete(`/proforma-invoices/${id}`);
      successNotification('Proforma invoice deleted');
      dispatch(getProformaInvoices({ financialYear }));
    } catch (err) {
      errorNotification(err);
    }
  }, []);

  const onConvert = useCallback(async id => {
    try {
      await Api.put(`/proforma-invoices/${id}/convert`);
      successNotification('Proforma invoice converted');
      dispatch(getProformaInvoices({ financialYear }));
    } catch (err) {
      errorNotification(err);
    }
  }, []);

  // const onFileDownload = useCallback(async (id, to) => {
  //   try {
  //     const { data } = await Api.getFile(`/proforma-invoices/${id}/pdf`);

  //     fileDownload(data, `PI-${id}-${to}.pdf`);

  //     successNotification('File downloaded');
  //   } catch (err) {
  //     errorNotificationOnBlob(err);
  //   }
  // }, []);

  const detailColumn = useMemo(
    () => (_text, record) =>
      (
        <Tooltip placement="bottom" title="Detail">
          <Link to={`/proformainvoices/detail/${record.id}`}>
            <FileTextOutlined />
          </Link>
        </Tooltip>
      ),
    []
  );

  const downloadColumn = useMemo(
    () => (_text, record) => {
      let color;
      if (record.files) {
        if (record.files > 0) {
          color = 'Green';
        } else {
          color = 'Red';
        }
      }
      return (
        <Tooltip placement="bottom" title="Download">
          <CloudDownloadOutlined
            style={{ color }}
            className={classes.tableIcon}
            onClick={() => showModal(record.id, 'ViewFiles')}
          />
        </Tooltip>
      );
    },
    []
  );

  const editColumn = useMemo(
    () => (_text, record) =>
      permissions.includes('editProformaInvoice') && !record.authorizedAt ? (
        <Tooltip placement="bottom" title="Edit">
          <Link to={`/proformainvoices/edit/${record.id}`}>
            <EditOutlined />
          </Link>
        </Tooltip>
      ) : null,
    []
  );

  const deleteColumn = useMemo(
    () => (_text, record) =>
      permissions.includes('deleteProformaInvoice') ? (
        <Tooltip placement="bottom" title="Cancel">
          <Popconfirm
            title="Are you sure you want to cancel?"
            onConfirm={() => onDelete(record.id)}
            okText="Yes"
            cancelText="No"
          >
            <DeleteOutlined />
          </Popconfirm>
        </Tooltip>
      ) : null,
    []
  );

  // const closeColumn = useMemo(
  //   () => (_text, record) =>
  //     permissions.includes('deleteProformaInvoice') ? (
  //       <Tooltip placement="bottom" title="Close">
  //         <Popconfirm
  //           title="Are you sure you want to delete?"
  //           onConfirm={() => onDelete(record.id)}
  //           okText="Yes"
  //           cancelText="No"
  //         >
  //           <FileTextOutlined />
  //         </Popconfirm>
  //       </Tooltip>
  //     ) : null,
  //   []
  // );

  const convertColumn = useMemo(
    () => (_text, record) =>
      permissions.includes('deleteProformaInvoice') ? (
        <Tooltip placement="bottom" title="Convert">
          <Popconfirm
            title="Are you sure you want to convert?"
            onConfirm={() => onConvert(record.id)}
            okText="Yes"
            cancelText="No"
          >
            <FileTextOutlined />
          </Popconfirm>
        </Tooltip>
      ) : null,
    []
  );

  const tooltipShow = useMemo(
    () => text =>
      (
        <Tooltip placement="topLeft" title={text}>
          {text}
        </Tooltip>
      ),
    []
  );

  const columns = useMemo(() => {
    const _columns = [
      {
        title: 'Consignor',
        dataIndex: 'consignor',
        sorter: sorter('consignor'),
        filterDropdown,
        onFilter: onFilter('consignor'),
        width: 120,
      },
      {
        title: 'PI #',
        dataIndex: 'piNo',
        sorter: sorter('piNo'),
        onFilter: onFilter('piNo'),
        filterDropdown,
        width: 70,
      },
      {
        title: 'Revision',
        dataIndex: 'revisionNo',
        width: 70,
      },
      {
        title: 'Date',
        dataIndex: 'createdAt',
        sorter: dateSorter('createdAt'),
        onFilter: onDateFilter('createdAt'),
        filterDropdown: dateFilterDropdown,
        render: text => (text ? moment(text).format('DD MMM YYYY') : null),
        width: 80,
      },
      {
        title: 'To',
        dataIndex: 'to',
        sorter: sorter('to'),
        onFilter: onFilter('to'),
        filterDropdown,
        width: 130,
      },
      {
        title: 'Grand Total',
        dataIndex: 'grandTotal',
        sorter: sorter('grandTotal'),
        onFilter: onFilter('grandTotal'),
        filterDropdown,
        render: text => Number(text).toLocaleString('en-IN'),
        width: 100,
      },
      {
        title: 'Products',
        dataIndex: 'products',
        sorter: sorter('products'),
        onFilter: onFilter('products'),
        filterDropdown,
        render: text =>
          text.length ? (
            <Tooltip placement="topLeft" title={text}>
              {text.join(', ')}
            </Tooltip>
          ) : (
            '-'
          ),
        ellipsis: { showTitle: false },
      },
      {
        title: 'Remark',
        dataIndex: 'remark',
        sorter: sorter('remark'),
        onFilter: onFilter('remark'),
        filterDropdown,
        ellipsis: { showTitle: false },
        render: tooltipShow,
      },
    ];

    _columns.push(
      {
        title: 'Detail',
        key: 'Detail',
        render: detailColumn,
        width: 70,
      },
      {
        title: 'Download',
        render: downloadColumn,
        width: 85,
      }
    );

    if (permissions.includes('editProformaInvoice')) {
      _columns.push({
        title: 'Edit',
        key: 'edit',
        render: editColumn,
        width: 45,
      });
    }

    if (permissions.includes('deleteProformaInvoice')) {
      _columns.push({
        title: 'Cancel',
        key: 'delete',
        render: deleteColumn,
        width: 65,
      });
    }

    if (permissions.includes('deleteProformaInvoice')) {
      _columns.push({
        title: 'Convert',
        key: 'convert',
        render: convertColumn,
        width: 70,
      });
    }

    return _columns;
  }, []);

  const rowKey = useMemo(() => record => record.id, []);

  const pagination = useMemo(
    () => ({
      defaultPageSize: 20,
      position: ['bottomLeft'],
      showTotal: total => `Total ${total} Records`,
    }),
    []
  );

  const scroll = useMemo(() => ({ x: 1024, y: window.innerHeight - 300 }), []);

  return (
    <Structure>
      <>
        <Heading text="PI List" />
        <Row gutter={[32, 8]}>
          <Col>
            <Select placeholder="Select year" onChange={value => setFinancialYear(value)} defaultValue={financialYear}>
              <Select.Option value="2019">19-20</Select.Option>
              <Select.Option value="2020">20-21</Select.Option>
              <Select.Option value="2021">21-22</Select.Option>
              <Select.Option value="2022">22-23</Select.Option>
              <Select.Option value="2023">23-24</Select.Option>
              <Select.Option value="2024">24-25</Select.Option>
              <Select.Option value="2025">25-26</Select.Option>
              <Select.Option value="2026">26-27</Select.Option>
              <Select.Option value="2027">27-28</Select.Option>
              <Select.Option value="2028">28-29</Select.Option>
              <Select.Option value="2029">29-30</Select.Option>
              <Select.Option value="2030">30-31</Select.Option>
            </Select>
          </Col>
        </Row>
        <Table
          bordered
          size="small"
          columns={columns}
          dataSource={proformaInvoices}
          rowKey={rowKey}
          pagination={pagination}
          scroll={scroll}
        />
        <Modal
          onOk={hideModal}
          open={modalType === 'ViewFiles'}
          centered
          maskClosable={false}
          title="View Purchase Order Files."
          width="800px"
          okText="Close"
          cancelButtonProps={{ className: classes.hide }}
          closable={false}
          okButtonProps={{ className: classes.button }}
        >
          <Table
            columns={[
              {
                title: 'File',
                dataIndex: 'fileName',
                key: 'file',
                render: (_text, record) => (
                  <a href={record.url} rel="noreferrer noopener" target="_blank">
                    <Tooltip placement="bottom" title={`Click to open/download "${record.fileName}" file.`}>
                      {record.fileName}
                    </Tooltip>
                  </a>
                ),
              },
            ]}
            bordered
            rowKey={record => record.id}
            dataSource={piFilesList}
            size="small"
            pagination={{ position: ['bottomLeft'] }}
          />
        </Modal>
      </>
    </Structure>
  );
};

List.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(List);

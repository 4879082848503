import React, { useEffect } from 'react';
import { Button, Card, Col, DatePicker, Row, Table } from 'antd';
import { useDispatch, useSelector } from 'react-redux';
import { Field, getFormValues, reduxForm } from 'redux-form';
import moment from 'moment';
import fileDownload from 'js-file-download';
import { FileExcelOutlined } from '@ant-design/icons';
import Structure from '../Structure/Structure';
import { getInventoryProfitCenter, clearInventoryProfitCenter } from '../Actions';
import { onFilter, sorter, filterDropdown } from '../utils';
import GetField from '../Components/GetField';
import Api from '../Api';
import { errorNotificationOnBlob, successNotification } from '../Snackbar';

const AntDatePicker = GetField(DatePicker);

const ProfitCenter = () => {
  const dispatch = useDispatch();

  const inventory = useSelector(state => state.profitCenter.inventory);
  const openingInventory = useSelector(state => state.profitCenter.openingInventory);
  const closingInventory = useSelector(state => state.profitCenter.closingInventory);

  const FormValues = useSelector(state => getFormValues('ProfitCenter')(state));

  useEffect(() => {
    if (FormValues && FormValues.from && FormValues.to) {
      dispatch(
        getInventoryProfitCenter({
          from: moment(FormValues.from).toISOString(),
          to: moment(FormValues.to).toISOString(),
        })
      );
    }

    return () => {
      dispatch(clearInventoryProfitCenter());
    };
  }, [FormValues && FormValues.from, FormValues && FormValues.to]);

  const onFileDownload = async () => {
    try {
      const { data } = await Api.getFile(`/material-inventory/reports/profit-center/export`, {
        from: moment(FormValues.from).toISOString(),
        to: moment(FormValues.to).toISOString(),
      });
      fileDownload(data, `profit-center.xlsx`);
      successNotification('File downloaded');
    } catch (err) {
      errorNotificationOnBlob(err);
    }
  };

  function disableDateRanges(range = { startDate: false, endDate: false }) {
    const { startDate, endDate } = range;
    return function disabledDate(current) {
      let startCheck = true;
      let endCheck = true;
      if (startDate) {
        startCheck = current && current < moment(startDate, 'YYYY-MM-DD');
      }
      if (endDate) {
        endCheck = current && current > moment(endDate, 'YYYY-MM-DD');
      }
      return (startDate && startCheck) || (endDate && endCheck);
    };
  }

  const columns = [
    {
      title: 'Name',
      dataIndex: 'name',
      sorter: sorter('name'),
      onFilter: onFilter('name'),
      filterDropdown,
    },
    {
      title: 'Opening Stock',
      dataIndex: 'openingStock',
      sorter: sorter('openingStock'),
      onFilter: onFilter('openingStock'),
      filterDropdown,
    },
    {
      title: 'Opening Amount',
      dataIndex: 'openingAmount',
      sorter: sorter('openingAmount'),
      onFilter: onFilter('openingAmount'),
      filterDropdown,
    },
    {
      title: 'Closing Stock',
      dataIndex: 'closingStock',
      sorter: sorter('closingStock'),
      onFilter: onFilter('closingStock'),
      filterDropdown,
    },
    {
      title: 'Closing Amount',
      dataIndex: 'closingAmount',
      sorter: sorter('closingAmount'),
      onFilter: onFilter('closingAmount'),
      filterDropdown,
    },
    {
      title: 'Impact',
      dataIndex: 'difference',
      sorter: sorter('difference'),
      onFilter: onFilter('difference'),
      filterDropdown,
    },
  ];

  return (
    <Structure>
      <>
        <Row gutter={[24, 0]}>
          <Col>
            <Field
              allowClear
              required
              name="from"
              placeholder="From"
              disabledDate={disableDateRanges({ endDate: new Date(), startDate: new Date('2023-05-08') })}
              component={AntDatePicker}
            />
          </Col>
          <Col>
            <Field
              allowClear
              required
              name="to"
              placeholder="To"
              disabledDate={disableDateRanges({ endDate: new Date(), startDate: new Date('2023-05-08') })}
              component={AntDatePicker}
            />
          </Col>
          <Col>
            <Button icon={<FileExcelOutlined />} onClick={() => onFileDownload()}>
              Export
            </Button>
          </Col>
        </Row>
        <Row gutter={[24, 8]}>
          <Col span={24}>
            <Row gutter={[8, 8]}>
              <Col xs={24} lg={4}>
                <Card size="small" title="Opening Inventory">
                  <span>{openingInventory?.total}</span>
                </Card>
              </Col>
              <Col xs={24} lg={4}>
                <Card size="small" title="Closing Inventory">
                  <span>{closingInventory?.total}</span>
                </Card>
              </Col>
              <Col xs={24} lg={4}>
                <Card size="small" title="Difference">
                  <span>{Number(closingInventory?.total - openingInventory?.total || 0).toFixed(2)}</span>
                </Card>
              </Col>
            </Row>
          </Col>
          <Col span={24}>
            <Table
              title={() => 'Inventory Profit Center'}
              columns={columns}
              bordered
              rowKey={record => record.name}
              dataSource={inventory}
              size="small"
              pagination={{
                defaultPageSize: 20,
                position: ['bottomLeft'],
                showTotal: total => `Total ${total} Items`,
              }}
              scroll={{ x: 1024, y: window.innerHeight - 300 }}
            />
          </Col>
        </Row>
      </>
    </Structure>
  );
};

const FormedProfitCenter = reduxForm({
  form: 'ProfitCenter',
  enableReinitialize: true,
})(ProfitCenter);

export default FormedProfitCenter;

import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import withStyles from 'react-jss';
import { useSelector, useDispatch } from 'react-redux';
import { reduxForm, Field, getFormValues } from 'redux-form';
import { Input, Button, Modal } from 'antd';
import { useHistory } from 'react-router';
import { Link } from 'react-router-dom';
import styles from './styles';
import { login } from '../Actions';
import { successNotification, errorNotification } from '../Snackbar';
import Api from '../Api';
import GetField from '../Components/GetField';

const AntInput = GetField(Input);
const AntPassword = GetField(Input.Password);

const Login = props => {
  const { classes, handleSubmit } = props;

  const [openForgotEmail, setVisibleForgotEmail] = useState(false);
  const [openForgotPassword, setVisibleForgotPassword] = useState(false);
  const [confirmLoading, setConfirmLoading] = useState(false);

  const [openVerifyModal, setVisibleVerifyModal] = useState(false);

  const dispatch = useDispatch();
  const history = useHistory();
  const auth = useSelector(state => state.auth);
  const FormValues = useSelector(state => getFormValues('Login')(state));

  useEffect(() => {
    if (auth.type && auth.user) {
      history.push('/');
    }
  }, [auth]);

  const onSubmit = async formValues => {
    try {
      await dispatch(login(formValues)).then(() => {
        history.push('/');
      });
    } catch (err) {
      errorNotification(err);
    }
  };

  const hideForgotModal = () => {
    setVisibleForgotEmail(false);
    setVisibleForgotPassword(false);
    setConfirmLoading(false);
  };

  const showForgotModal = () => {
    setVisibleForgotEmail(true);
  };

  const onForgot = async () => {
    try {
      if (!FormValues || !FormValues.email) {
        return errorNotification({ message: 'Enter Email.' });
      }
      const { email } = FormValues;
      setConfirmLoading(true);
      await Api.post('/auth/forgetpasswordrequest', { email });
      successNotification('Code sent to your e-mail');
      setVisibleForgotEmail(false);
      setConfirmLoading(false);
      setVisibleForgotPassword(true);
    } catch (err) {
      setConfirmLoading(false);
      errorNotification(err);
    }
  };

  const onSubmitNewPasword = async () => {
    try {
      if (!FormValues || !FormValues.password) {
        return errorNotification({ message: 'Enter new password' });
      }
      const { email, otp, password, repeatPassword } = FormValues;
      if (!repeatPassword) {
        return errorNotification({ message: 'Enter repeat password' });
      }
      if (password !== repeatPassword) {
        return errorNotification({ message: 'Password not matching' });
      }
      await Api.post('/auth/setpassword', { email, otp, password });
      successNotification('Password changed');
      hideForgotModal();
      props.reset();
    } catch (err) {
      setConfirmLoading(false);
      errorNotification(err);
    }
  };

  const getTitle = () => {
    if (openForgotEmail) {
      return 'Forgot password ? 1/2';
    }
    if (openForgotPassword) {
      return 'Forgot password ? 2/2';
    }
  };

  const getCallback = () => {
    if (openForgotEmail) {
      return onForgot;
    }
    if (openForgotPassword) {
      return onSubmitNewPasword;
    }
    return () => {};
  };

  const showVerifyModal = () => {
    setVisibleVerifyModal(true);
  };

  const hideVefiryModal = () => {
    setVisibleVerifyModal(false);
  };

  const handleVefiryModalOk = async () => {
    try {
      const { email, otp, password, repeatPassword } = FormValues;
      if (!FormValues || !FormValues.email) {
        return errorNotification({ message: 'Enter Email' });
      }
      if (!FormValues || !FormValues.otp) {
        return errorNotification({ message: 'Enter Code' });
      }
      if (!FormValues || !FormValues.password) {
        return errorNotification({ message: 'Enter new password' });
      }
      if (!repeatPassword) {
        return errorNotification({ message: 'Enter repeat password' });
      }
      if (password !== repeatPassword) {
        return errorNotification({ message: 'Password does not match' });
      }
      await Api.post('/auth/setpassword', { email, otp, password });
      successNotification('Password set, log in using email and password');
      hideVefiryModal();
      props.reset();
    } catch (err) {
      setVisibleVerifyModal(false);
      errorNotification(err);
    }
  };

  return (
    <div className={classes.container}>
      <div className={classes.loginBox}>
        <span className={classes.heading}>Sign in to Plus Formulations</span>
        <span className={classes.subHeading}>
          Happy to see you <span>😇</span>
        </span>
        <form onSubmit={handleSubmit(onSubmit)}>
          <Field placeholder="Email" required allowClear size="large" name="email" component={AntInput} />
          <Field
            placeholder="Password"
            required
            type="password"
            name="password"
            allowClear
            visibilityToggle
            size="large"
            component={AntPassword}
          />
          <Button className={classes.button} type="primary" size="large" htmlType="submit">
            Log in
          </Button>
          <Link className={classes.linksText} to="/login" onKeyPress={showForgotModal} onClick={showForgotModal}>
            Forgot password ?
          </Link>
          <Link className={classes.linksText} to="/login" onKeyPress={showVerifyModal} onClick={showVerifyModal}>
            Verify Account
          </Link>
        </form>
        <Modal
          onOk={getCallback()}
          open={openForgotEmail || openForgotPassword}
          confirmLoading={confirmLoading}
          onCancel={hideForgotModal}
          centered
          maskClosable={false}
          title={getTitle()}
          width="400px"
          cancelText="Back"
          okText="Continue"
        >
          <>
            {openForgotEmail ? (
              <>
                <p className={classes.modalText}>
                  You will receive 6 digits characters code by e-mail to verify your identity.
                </p>
                <Field placeholder="Email to recover" allowClear name="email" component={AntInput} />
              </>
            ) : null}
            {openForgotPassword ? (
              <>
                <p className={classes.modalText}>
                  You have received a six digits characters code by e-mail <span>📫</span>. Enter it in the Code,
                  Password and Repeat Password fields below to continue.
                </p>
                <Field placeholder="Code" allowClear name="otp" component={AntInput} />
                <Field
                  placeholder="New Password"
                  required
                  type="password"
                  name="password"
                  allowClear
                  visibilityToggle
                  component={AntPassword}
                />
                <Field
                  placeholder="Repeat Password"
                  required
                  type="password"
                  name="repeatPassword"
                  allowClear
                  visibilityToggle
                  component={AntPassword}
                />
              </>
            ) : null}
          </>
        </Modal>
        <Modal
          onOk={handleVefiryModalOk}
          open={openVerifyModal}
          onCancel={hideVefiryModal}
          centered
          maskClosable={false}
          title="Verify and set password."
          width="400px"
          cancelText="Back"
          okText="Continue"
        >
          <p className={classes.modalText}>
            Enter the e-mail and code (You have received a six digits characters code by e-mail <span>📫</span> ), plus
            new password in Password and Repeat Password fields below to continue.
          </p>
          <Field placeholder="Email" allowClear name="email" component={AntInput} />
          <Field placeholder="Code" allowClear name="otp" component={AntInput} />
          <Field
            placeholder="Password"
            required
            type="password"
            name="password"
            allowClear
            visibilityToggle
            component={AntPassword}
          />
          <Field
            placeholder="Repeat Password"
            required
            type="password"
            name="repeatPassword"
            allowClear
            visibilityToggle
            component={AntPassword}
          />
        </Modal>
      </div>
    </div>
  );
};

Login.propTypes = {
  classes: PropTypes.object.isRequired,
  handleSubmit: PropTypes.func.isRequired,
  reset: PropTypes.func.isRequired,
};

const StyledLogin = withStyles(styles)(Login);

const FormedLogin = reduxForm({
  form: 'Login',
})(StyledLogin);

export default FormedLogin;

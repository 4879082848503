import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import withStyles from 'react-jss';
import PropTypes from 'prop-types';
import { Table, Popconfirm, Tooltip, Modal, Row, Col, Button, DatePicker } from 'antd';
import { Link } from 'react-router-dom';
import { EditOutlined, FileTextOutlined, EyeOutlined, MailOutlined, FileExcelOutlined } from '@ant-design/icons';
import { useReactToPrint } from 'react-to-print';
import fileDownload from 'js-file-download';
import Structure from '../Structure/Structure';
import { sorter, onFilter, filterDropdown, get } from '../utils';
import Api from '../Api';
import { getSupplierList, clearSupplierList, getSupplierLedger, clearSupplierLedger } from '../Actions';
import { errorNotification, errorNotificationOnBlob, infoNotification, successNotification } from '../Snackbar';
import styles from '../CommonStyles';
import SupplierLedger from '../Ledger/SupplierLedger';

const SupplierList = props => {
  const { classes } = props;
  const dispatch = useDispatch();

  const auth = useSelector(state => state.auth);
  const permissions = get(auth, ['permissions'], []);
  const supplierList = useSelector(state => state.supplierList);
  const supplierLedger = useSelector(state => state.supplierLedger);

  const [isModalVisible, setIsModalVisible] = useState(false);
  const [supplierId, setSupplierId] = useState(null);
  const [supplier, setSupplier] = useState(null);

  const [from, setFrom] = useState(null);
  const [to, setTo] = useState(null);
  const [rowKeys, setRowKeys] = useState([]);

  const onSelectChange = useCallback(selectedRowKeys => setRowKeys(selectedRowKeys), [setRowKeys]);

  const printRef = useRef();
  const handlePrint = useReactToPrint({
    content: () => printRef.current,
    pageStyle: `
    @page {
      size: 210mm 297mm;
      margin: 5mm;
    }
  
    @media all {
      .pagebreak {
        margin-top: 1rem;
        display: block;
        page-break-before: auto;
    }
  
    @media print {
      .pagebreak {
        margin-top: 1rem;
        display: block;
        page-break-before: auto;
      }
    }
  `,
  });

  useEffect(() => {
    dispatch(getSupplierList());
    return () => {
      dispatch(clearSupplierList());
      dispatch(clearSupplierLedger());
    };
  }, []);

  useEffect(() => {
    if (supplierId && isModalVisible) {
      dispatch(getSupplierLedger(supplierId));
    }
  }, [supplierId, isModalVisible]);

  const showModal = record => {
    setIsModalVisible(true);
    setSupplier(record);
    setSupplierId(record.id);
  };

  const handleOk = () => {
    setIsModalVisible(false);
    setSupplier(null);
    setSupplierId(null);
    dispatch(clearSupplierLedger());
  };

  // eslint-disable-next-line no-unused-vars
  const onDelete = async id => {
    try {
      await Api.delete(`/suppliers/${id}`);
      successNotification('Supplier deleted');
      dispatch(getSupplierList());
    } catch (err) {
      errorNotification(err);
    }
  };

  const onEmail = async id => {
    try {
      await Api.get(`/suppliers/${id}/email`);
      successNotification('Email sent');
    } catch (err) {
      errorNotification(err);
    }
  };

  const onFileDownload = useCallback(async () => {
    try {
      infoNotification('Downloading...');
      const { data } = await Api.getFile('/suppliers/ledgers/export', { from, to, supplierIds: rowKeys.join(',') });
      fileDownload(data, `supplier-ledger.xlsx`);
      successNotification('File downloaded');
    } catch (err) {
      errorNotificationOnBlob(err);
    }
  }, [from, to, rowKeys]);

  const tooltipShow = arg => (
    <Tooltip placement="topLeft" title={arg}>
      {arg}
    </Tooltip>
  );

  const columns = [
    {
      title: 'Name',
      dataIndex: 'name',
      sorter: sorter('name'),
      filterDropdown,
      onFilter: onFilter('name'),
      ellipsis: { showTitle: false },
      render: tooltipShow,
      width: 200,
      fixed: 'left',
    },
    {
      title: 'GST Number',
      dataIndex: 'gstNo',
      sorter: sorter('gstNo'),
      filterDropdown,
      onFilter: onFilter('gstNo'),
      ellipsis: { showTitle: false },
      render: tooltipShow,
      width: 170,
    },
    {
      title: 'State',
      dataIndex: 'state',
      sorter: sorter('state'),
      filterDropdown,
      onFilter: onFilter('state'),
      ellipsis: { showTitle: false },
      render: tooltipShow,
      width: 120,
    },
    {
      title: 'Billing Address',
      dataIndex: 'address',
      sorter: sorter('address'),
      filterDropdown,
      onFilter: onFilter('address'),
      ellipsis: { showTitle: false },
      render: tooltipShow,
      width: 150,
    },
    {
      title: 'Corr Address',
      dataIndex: 'corrAddress',
      sorter: sorter('corrAddress'),
      filterDropdown,
      onFilter: onFilter('corrAddress'),
      ellipsis: { showTitle: false },
      render: tooltipShow,
      width: 150,
    },
    {
      title: 'Contact Person',
      dataIndex: 'contactPerson',
      sorter: sorter('contactPerson'),
      filterDropdown,
      onFilter: onFilter('contactPerson'),
      render: tooltipShow,
      width: 120,
    },
    {
      title: 'Primary Contact',
      dataIndex: 'primaryContact',
      sorter: sorter('primaryContact'),
      filterDropdown,
      onFilter: onFilter('primaryContact'),
      render: tooltipShow,
      width: 120,
    },
    {
      title: 'Contacts',
      dataIndex: 'contacts',
      sorter: sorter('contacts'),
      filterDropdown,
      onFilter: onFilter('contacts'),
      ellipsis: { showTitle: false },
      render: tooltipShow,
      width: 120,
    },
    {
      title: 'Primary Email',
      dataIndex: 'primaryEmail',
      sorter: sorter('primaryEmail'),
      filterDropdown,
      onFilter: onFilter('primaryEmail'),
      ellipsis: { showTitle: false },
      render: tooltipShow,
      width: 150,
    },
    {
      title: 'Emails',
      dataIndex: 'emails',
      sorter: sorter('emails'),
      filterDropdown,
      onFilter: onFilter('emails'),
      ellipsis: { showTitle: false },
      render: tooltipShow,
      width: 120,
    },
    {
      title: 'Payment Term',
      dataIndex: 'paymentTerms',
      sorter: sorter('paymentTerms'),
      filterDropdown,
      onFilter: onFilter('paymentTerms'),
      width: 120,
      align: 'right',
    },
    {
      title: 'Remarks',
      dataIndex: 'remarks',
      sorter: sorter('remarks'),
      filterDropdown,
      onFilter: onFilter('remarks'),
      ellipsis: { showTitle: false },
      render: tooltipShow,
      width: 150,
    },
  ];

  const renderActionIcons = record => (
    <div>
      {permissions.includes('edit_supplier') ? (
        <Tooltip placement="bottom" title="Edit">
          <Link to={`/supplier/edit/${record.id}`}>
            <EditOutlined className={classes.tableIcon} />
          </Link>
        </Tooltip>
      ) : null}
    </div>
  );

  const renderPODetailsIcons = record => (
    <>
      <Tooltip placement="bottom" title="View Details">
        <Link to={`/supplier/details/${record.id}`} target="_blank" rel="noreferrer noopener" without="true">
          <EyeOutlined className={classes.tableIcon} />
        </Link>
      </Tooltip>
      &nbsp;&nbsp;&nbsp;&nbsp;
      <Tooltip placement="bottom" title="Email Link">
        <Popconfirm title="Are you sure?" onConfirm={() => onEmail(record.id)} okText="Yes" cancelText="No">
          <MailOutlined className={classes.tableIcon} />
        </Popconfirm>
      </Tooltip>
    </>
  );

  if (permissions.includes('edit_supplier')) {
    columns.push({
      title: 'Actions',
      width: 70,
      render: renderActionIcons,
    });
    columns.push({
      title: 'Details',
      width: 80,
      render: renderPODetailsIcons,
    });
  }

  const renderLedgerIcons = record => (
    <>
      {permissions.includes('supplier_ledger_view') ? (
        <Tooltip placement="top" title="View Ledger">
          <FileTextOutlined className={classes.tableIcon} onClick={() => showModal(record)} />
        </Tooltip>
      ) : null}
    </>
  );

  if (permissions.includes('supplier_ledger_view')) {
    columns.push({
      title: 'Ledger',
      width: 70,
      fixed: 'right',
      render: renderLedgerIcons,
    });
  }

  const rowKey = useMemo(() => record => record.id, []);

  const pagination = useMemo(
    () => ({
      defaultPageSize: 20,
      position: ['bottomLeft'],
      showTotal: total => `Total ${total} Records`,
    }),
    []
  );

  const scroll = useMemo(() => ({ x: 1050, y: window.innerHeight - 300 }), []);

  return (
    <Structure>
      <>
        <Row gutter={[16, 16]}>
          <Col xs={24} sm={24} md={24} lg={6} xl={4} xxl={4}>
            <DatePicker placeholder="From Date" onChange={value => setFrom(value ? value.toISOString() : null)} />
          </Col>
          <Col xs={24} sm={24} md={24} lg={6} xl={4} xxl={4}>
            <DatePicker placeholder="To Date" onChange={value => setTo(value ? value.toISOString() : null)} />
          </Col>
          <Col xs={24} sm={24} md={24} lg={6} xl={4} xxl={4}>
            <Button icon={<FileExcelOutlined />} onClick={onFileDownload}>
              Export Ledger
            </Button>
          </Col>
        </Row>
        <Table
          bordered
          size="small"
          rowSelection={{
            rowKeys,
            onChange: onSelectChange,
          }}
          columns={columns}
          dataSource={supplierList}
          rowKey={rowKey}
          pagination={pagination}
          scroll={scroll}
        />
        <Modal
          open={isModalVisible}
          onOk={handleOk}
          onCancel={handlePrint}
          maskClosable={false}
          closable={false}
          width="1000px"
          okText="Close"
          cancelText="Print"
          cancelButtonProps={{ className: classes.button }}
          okButtonProps={{ className: classes.button }}
        >
          <div ref={printRef}>
            <SupplierLedger statement="Supplier" statementData={supplier} ledgerData={supplierLedger} />
          </div>
        </Modal>
      </>
    </Structure>
  );
};

SupplierList.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(SupplierList);

import Api from '../Api';

export const getPurchaseOrderList = query => async dispatch => {
  try {
    const { data } = await Api.get('/purchase-orders', query);
    dispatch({
      type: 'FETCH_PURCHASE_ORDER_LIST',
      payload: data,
    });
  } catch (err) {
    throw err;
  }
};

export const clearPurchaseOrderList = () => {
  return {
    type: 'CLEAR_PURCHASE_ORDER_LIST',
  };
};

export const getPurchaseOrder = id => async dispatch => {
  try {
    const { data } = await Api.get(`/purchase-orders/${id}`);
    dispatch({
      type: 'FETCH_PURCHASE_ORDER',
      payload: data,
    });
  } catch (err) {
    throw err;
  }
};

export const clearPurchaseOrder = () => {
  return {
    type: 'CLEAR_PURCHASE_ORDER',
  };
};

export const setPurchaseOrder = data => async dispatch => {
  try {
    dispatch({
      type: 'FETCH_PURCHASE_ORDER',
      payload: data,
    });
  } catch (err) {
    throw err;
  }
};

export const getPurchaseOrderIdList = params => async dispatch => {
  try {
    const { data } = await Api.get('/purchase-orders/idsforpurchase', params);
    dispatch({
      type: 'FETCH_PURCHASE_ORDER_ID_LIST',
      payload: data,
    });
  } catch (err) {
    throw err;
  }
};

export const clearPurchaseOrderIdList = () => {
  return {
    type: 'CLEAR_PURCHASE_ORDER_ID_LIST',
  };
};

export const getPurchaseOrderFilesList = id => async dispatch => {
  try {
    const { data } = await Api.get(`/purchase-orders/${id}/files`);
    dispatch({
      type: 'FETCH_PURCHASE_ORDER_FILES_LIST',
      payload: data,
    });
  } catch (err) {
    throw err;
  }
};

export const clearPurchaseOrderFilesList = () => {
  return {
    type: 'CLEAR_PURCHASE_ORDER_FILES_LIST',
  };
};

export const getSupplierWisePOList = id => async dispatch => {
  try {
    const { data } = await Api.get(`/purchase-orders/suppliers/${id}`);
    dispatch({
      type: 'FETCH_SUPPLIER_WISE_PO_LIST',
      payload: data,
    });
  } catch (err) {
    throw err;
  }
};

export const clearSupplierWisePOList = () => {
  return {
    type: 'CLEAR_PSUPPLIER_WISE_PO_LIST',
  };
};

/* eslint-disable no-nested-ternary */
import React, { useEffect, Fragment } from 'react';
import PropTypes from 'prop-types';
import { Card, Col, Row, Table } from 'antd';
import { useDispatch, useSelector } from 'react-redux';
import moment from 'moment';
import withStyles from 'react-jss';
import { onFilter, sorter, filterDropdown, dateSorter, onDateFilter, dateFilterDropdown } from '../utils';
import Structure from '../Structure/Structure';
import Heading from '../Components/Heading';
import { getLeadFMS, clearLeadFMS } from '../Actions';
import styles from '../CommonStyles';

const LeadFMS = props => {
  const { classes } = props;

  const dispatch = useDispatch();

  const leadFMS = useSelector(state => state.leadFMS);

  useEffect(() => {
    dispatch(getLeadFMS());

    return () => {
      dispatch(clearLeadFMS());
    };
  }, []);

  const _columns = [
    {
      title: 'Whom',
      dataIndex: 'whom',
      sorter: sorter('whom'),
      onFilter: onFilter('whom'),
      filterDropdown,
    },
    {
      title: 'To',
      dataIndex: 'toName',
      sorter: sorter('toName'),
      onFilter: onFilter('toName'),
      filterDropdown,
    },
    {
      title: 'Query',
      dataIndex: 'query',
      sorter: sorter('query'),
      onFilter: onFilter('query'),
      filterDropdown,
    },
    {
      title: 'Managed By',
      dataIndex: 'createdBy',
      sorter: sorter('createdBy'),
      onFilter: onFilter('createdBy'),
      filterDropdown,
    },
    {
      title: 'Created',
      dataIndex: 'createdAt',
      sorter: dateSorter('createdAt'),
      onFilter: onDateFilter('createdAt'),
      filterDropdown: dateFilterDropdown,
      render: date => (date ? moment(date).format('DD MMM YY hh:mm A') : null),
    },
    {
      title: 'Proforma Invoice (24 hour)',
      children: [
        {
          title: 'Planned',
          dataIndex: 'plannedPi',
          sorter: dateSorter('plannedPi'),
          onFilter: onDateFilter('plannedPi'),
          filterDropdown: dateFilterDropdown,
          render: date => (date ? moment(date).format('DD MMM YY hh:mm A') : null),
        },
        {
          title: 'Actual',
          dataIndex: 'piCreatedAt',
          sorter: dateSorter('piCreatedAt'),
          onFilter: onDateFilter('piCreatedAt'),
          filterDropdown: dateFilterDropdown,
          render: date => (date ? moment(date).format('DD MMM YY hh:mm A') : null),
        },
        {
          title: 'Status',
          dataIndex: 'status',
          sorter: true,
          filters: [
            { text: 'Delay', value: 'Delay' },
            { text: 'Ontime', value: 'Ontime' },
          ],
          render: (_text, record) =>
            record.piCreatedAt ? (
              moment(record.piCreatedAt).diff(record.plannedPi) > 0 ? (
                <span style={{ color: 'red' }}>Delay</span>
              ) : (
                'Ontime'
              )
            ) : null,
        },
      ],
    },
    {
      title: 'Convert/Cancel (24 hour)',
      children: [
        {
          title: 'Planned',
          dataIndex: 'plannedConvertedOrCancelled',
          sorter: dateSorter('plannedConvertedOrCancelled'),
          onFilter: onDateFilter('plannedConvertedOrCancelled'),
          filterDropdown: dateFilterDropdown,
          render: date => (date ? moment(date).format('DD MMM YY hh:mm A') : null),
        },
        {
          title: 'Actual',
          render: (_text, record) =>
            record.piConvertedAt
              ? `Converted at ${moment(record.piConvertedAt).format('DD MMM YY hh:mm A')}`
              : record.piConvertedAt
              ? `Closed at ${moment(record.piCancelledAt).format('DD MMM YY hh:mm A')}`
              : null,
        },
        {
          title: 'Status',
          dataIndex: 'status',
          sorter: true,
          filters: [
            { text: 'Delay', value: 'Delay' },
            { text: 'Ontime', value: 'Ontime' },
          ],
          render: (_text, record) =>
            record.closedAt ? (
              moment(record.closedAt).diff(record.plannedClosed) > 0 ? (
                <span style={{ color: 'red' }}>Delay</span>
              ) : (
                'Ontime'
              )
            ) : null,
        },
      ],
    },
  ];

  return (
    <Structure>
      <>
        <Heading text="Query FMS" />
        <Row gutter={[8, 8]}>
          <Col span={4}>
            <Card size="small" title="Total Leads">
              <span className={classes.dashboardNumberFont}>{leadFMS.totalLeads}</span>
            </Card>
          </Col>
          <Col span={4}>
            <Card size="small" title="Leads Without Query">
              <span className={classes.dashboardNumberFont}>{leadFMS.leadsWithoutQuery}</span>
            </Card>
          </Col>
          <Col span={4}>
            <Card size="small" title="Leads With Query">
              <span className={classes.dashboardNumberFont}>{leadFMS.leadsWithQuery}</span>
            </Card>
          </Col>
          <Col span={4}>
            <Card size="small" title="Total Lead Queries">
              <span className={classes.dashboardNumberFont}>{leadFMS.totalLeadQuery}</span>
            </Card>
          </Col>
          <Col span={4}>
            <Card size="small" title="Lead Queries without PI">
              <span className={classes.dashboardNumberFont}>{leadFMS.leadsQueryWithoutPI}</span>
            </Card>
          </Col>
        </Row>
        <Row gutter={[8, 8]}>
          <Col span={4}>
            <Card size="small" title="Total Customer">
              <span className={classes.dashboardNumberFont}>{leadFMS.totalCustomer}</span>
            </Card>
          </Col>
          <Col span={4}>
            <Card size="small" title="Customer Without Query">
              <span className={classes.dashboardNumberFont}>{leadFMS.customerWithoutQuery}</span>
            </Card>
          </Col>
          <Col span={4}>
            <Card size="small" title="Customer With Query">
              <span className={classes.dashboardNumberFont}>{leadFMS.customerWithQuery}</span>
            </Card>
          </Col>
          <Col span={4}>
            <Card size="small" title="Total Customer Queries">
              <span className={classes.dashboardNumberFont}>{leadFMS.totalCustomerQuery}</span>
            </Card>
          </Col>
          <Col span={4}>
            <Card size="small" title="Customer Queries without PI">
              <span className={classes.dashboardNumberFont}>{leadFMS.customerQueryWithoutPI}</span>
            </Card>
          </Col>
        </Row>
        <br />
        <Table
          columns={_columns}
          bordered
          rowKey={record => record.id}
          dataSource={leadFMS.leads}
          size="small"
          pagination={false}
          scroll={{ x: 1200, y: window.innerHeight - 300 }}
        />
      </>
    </Structure>
  );
};

LeadFMS.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(LeadFMS);

import api from '../Api';

export const getSentWhatsAppMessages = () => async dispatch => {
  try {
    const { data } = await api.get('/whatsapp/sent');
    dispatch({
      type: 'FETCH_SENT_WHATSAPP_MESSAGES',
      payload: data,
    });
  } catch (err) {
    throw err;
  }
};

export const clearWhatsAppMessages = () => {
  return {
    type: 'CLEAR_WHATSAPP_MESSAGES',
  };
};
export const getReceivedWhatsAppMessages = () => async dispatch => {
  try {
    const { data } = await api.get('/whatsapp/received');
    dispatch({
      type: 'FETCH_RECEIVED_WHATSAPP_MESSAGES',
      payload: data,
    });
  } catch (err) {
    throw err;
  }
};

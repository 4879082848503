export const batchList = (state = [], action) => {
  if (action.type === 'FETCH_BATCH_LIST') {
    return action.payload;
  }
  if (action.type === 'CLEAR_BATCH_LIST') {
    return [];
  }
  return state;
};

export const batchDetail = (state = [], action) => {
  if (action.type === 'FETCH_BATCH_DETAIL') {
    return action.payload;
  }
  if (action.type === 'CLEAR_BATCH_DETAIL') {
    return [];
  }
  return state;
};

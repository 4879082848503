import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import { Button, Input, Row } from 'antd';
import { useHistory } from 'react-router';
import { Field, getFormValues, reduxForm } from 'redux-form';
import withStyles from 'react-jss';
import { useSelector } from 'react-redux';
import GetField from '../Components/GetField';
import styles from '../CommonStyles';
import CenterGrid from '../Components/CenterGrid';
import { errorNotification, successNotification } from '../Snackbar';
import Api from '../Api';
import Heading from '../Components/Heading';

const AntInput = GetField(Input);

const ComplaintForm = props => {
  const { handleSubmit, classes, match } = props;
  const history = useHistory();

  const FormValues = useSelector(state => getFormValues('ComplaintForm')(state));

  const onSubmit = async () => {
    try {
      await Api.post(`/customer-complaints/`, { ...FormValues, customerId: match.params.id });
      successNotification('Complaint raised');
      history.push(`/customer/details/${match.params.id}`);
    } catch (err) {
      errorNotification(err);
    }
  };

  return (
    <>
      <Heading text="Raise Complaint" />
      <form onSubmit={handleSubmit(onSubmit)}>
        <Row>
          <CenterGrid>
            <Field
              required
              allowClear
              name="reason"
              label="Reason for complaint"
              placeholder="Reason for complaint"
              component={AntInput}
            />
          </CenterGrid>
        </Row>
        <Row>
          <CenterGrid>
            <Field
              required
              allowClear
              name="details"
              label="Provide details"
              placeholder="Provide details"
              component={AntInput}
            />
          </CenterGrid>
        </Row>
        <Row className={classes.buttonContainer}>
          <Button htmlType="submit" type="primary" className={classes.button}>
            Submit
          </Button>
        </Row>
      </form>
    </>
  );
};

ComplaintForm.propTypes = {
  handleSubmit: PropTypes.func.isRequired,
  classes: PropTypes.object.isRequired,
  match: PropTypes.object.isRequired,
};

const StyledComplaintForm = withStyles(styles)(ComplaintForm);

const FormedComplaintForm = reduxForm({
  form: 'ComplaintForm',
})(StyledComplaintForm);

export default FormedComplaintForm;

import React, { useState, useEffect, useMemo } from 'react';
import { reduxForm, Field, getFormValues } from 'redux-form';
import { Input, Row, Select, Table } from 'antd';
import { useDispatch, useSelector } from 'react-redux';
import startCase from 'lodash/startCase';
import moment from 'moment';

import Heading from '../Components/Heading';
import Structure from '../Structure/Structure';
import LeftGrid from '../Components/LeftGrid';
import RightGrid from '../Components/RightGrid';
import GetField from '../Components/GetField';
import { getFormulationIdList, clearFormulationIdList, getGPPercentage, clearGPPercentage } from '../Actions';
import { get } from '../utils';

const AntInput = GetField(Input);
const AntSelect = GetField(Select);

const GPPercentage = () => {
  const dispatch = useDispatch();

  const auth = useSelector(state => state.auth);
  const role = get(auth, ['user', 'role'], null);

  const formulationIdList = useSelector(state => state.formulationIdList);
  const gpPercentage = useSelector(state => state.gpPercentage);

  const [salesData, setSalesData] = useState([]);
  const [ordersData, setOrdersData] = useState([]);

  const FormValues = useSelector(state => getFormValues('GPPercentage')(state));

  useEffect(() => {
    dispatch(getFormulationIdList());
    return () => {
      dispatch(clearFormulationIdList());
      dispatch(clearGPPercentage());
    };
  }, []);

  useEffect(() => {
    if (FormValues && FormValues.formulationId) {
      dispatch(clearGPPercentage());
      dispatch(getGPPercentage(FormValues.formulationId));
    }
  }, [FormValues && FormValues.formulationId, formulationIdList]);

  useEffect(() => {
    if (gpPercentage && gpPercentage.sales.length && FormValues && FormValues.formulationId) {
      const _tempSaleData = [];
      const _tempOrdersData = [];

      const { bufferPercentage = 0 } = FormValues;

      const { lastRate, maxRate, theoriticalYield, sales, orders } = gpPercentage;

      const _totalCostLast = +((+lastRate * +bufferPercentage) / 100 + +lastRate).toFixed(2);
      const _totalCostMax = +((+maxRate * +bufferPercentage) / 100 + +maxRate).toFixed(2);

      const _costPerUnitLast = +(_totalCostLast / +theoriticalYield).toFixed(6);
      const _costPerUnitMax = +(_totalCostMax / +theoriticalYield).toFixed(6);

      sales.map(item => {
        const { rate } = item;

        const _gpLast = +(+rate - _costPerUnitLast).toFixed(2);
        const _gpMax = +(+rate - _costPerUnitMax).toFixed(2);

        const _gpLastPercentage = +((_gpLast / +rate) * 100).toFixed(2);
        const _gpMaxPercentage = +((_gpMax / +rate) * 100).toFixed(2);

        _tempSaleData.push({
          id: item.id,
          voucherNo: item.voucherNo,
          voucherDate: item.voucherDate,
          customerName: item.customerName,
          orderId: item.orderId,
          authorizedAt: item.authorizedAt,
          nature: item.nature,
          orderRate: item.orderRate,
          lastRate,
          maxRate,
          theoriticalYield,
          totalCostLast: _totalCostLast,
          totalCostMax: _totalCostMax,
          costPerUnitLast: _costPerUnitLast,
          costPerUnitMax: _costPerUnitMax,
          saleRate: +rate,
          gpLast: _gpLast,
          gpMax: _gpMax,
          gpLastPercentage: _gpLastPercentage,
          gpMaxPercentage: _gpMaxPercentage,
        });
      });

      if (gpPercentage.orders.length) {
        orders.map(item => {
          const rate = FormValues.saleRate || 0;

          const _gpLast = +(+rate - _costPerUnitLast).toFixed(2);
          const _gpMax = +(+rate - _costPerUnitMax).toFixed(2);

          const _gpLastPercentage = +((_gpLast / +rate) * 100).toFixed(2);
          const _gpMaxPercentage = +((_gpMax / +rate) * 100).toFixed(2);

          _tempOrdersData.push({
            id: item.id,
            createdAt: item.createdAt,
            nature: item.nature,
            customerName: item.customerName,
            orderRate: item.rate,
            lastRate,
            maxRate,
            theoriticalYield,
            totalCostLast: _totalCostLast,
            totalCostMax: _totalCostMax,
            costPerUnitLast: _costPerUnitLast,
            costPerUnitMax: _costPerUnitMax,
            saleRate: +rate,
            gpLast: _gpLast,
            gpMax: _gpMax,
            gpLastPercentage: _gpLastPercentage,
            gpMaxPercentage: _gpMaxPercentage,
          });
        });
      }

      setOrdersData(_tempOrdersData);
      setSalesData(_tempSaleData);
    }
  }, [
    gpPercentage,
    FormValues && FormValues.formulationId,
    FormValues && FormValues.bufferPercentage,
    FormValues && FormValues.saleRate,
  ]);

  const columns = useMemo(() => {
    const _columns = [
      {
        title: 'Sale #',
        dataIndex: 'voucherNo',
      },
      {
        title: 'Sale Date',
        dataIndex: 'voucherDate',
        render: voucherDate => (voucherDate ? moment(voucherDate).format('DD MMM YYYY') : null),
      },
      {
        title: 'Order #',
        dataIndex: 'orderId',
      },
      {
        title: 'Order Date',
        dataIndex: 'authorizedAt',
        render: authorizedAt => (authorizedAt ? moment(authorizedAt).format('DD MMM YYYY') : null),
      },
      {
        title: 'Customer',
        dataIndex: 'customerName',
      },
      {
        title: 'Type',
        dataIndex: 'nature',
        render: nature => startCase(nature),
      },
      {
        title: 'Order Rate',
        dataIndex: 'orderRate',
      },
      {
        title: 'Sale Rate',
        dataIndex: 'saleRate',
      },
    ];

    if (role === 'Owner') {
      _columns.push(
        {
          title: 'Purchase Rate',
          children: [
            {
              title: 'Last',
              dataIndex: 'lastRate',
            },
            {
              title: 'Max',
              dataIndex: 'maxRate',
            },
          ],
        },
        {
          title: 'Theoritical Yield',
          dataIndex: 'theoriticalYield',
        },
        {
          title: 'Total Cost',
          children: [
            {
              title: 'Last',
              dataIndex: 'totalCostLast',
            },
            {
              title: 'Max',
              dataIndex: 'totalCostMax',
            },
          ],
        },
        {
          title: 'Cost/Unit',
          children: [
            {
              title: 'Last',
              dataIndex: 'costPerUnitLast',
            },
            {
              title: 'Max',
              dataIndex: 'costPerUnitMax',
            },
          ],
        },
        {
          title: 'GP',
          children: [
            {
              title: 'Last',
              dataIndex: 'gpLast',
            },
            {
              title: 'Max',
              dataIndex: 'gpMax',
            },
          ],
        }
      );
    }

    _columns.push({
      title: 'GP Percentage',
      children: [
        {
          title: 'Last',
          dataIndex: 'gpLastPercentage',
        },
        {
          title: 'Max',
          dataIndex: 'gpMaxPercentage',
        },
      ],
    });

    return _columns;
  }, []);

  const orderColumns = useMemo(() => {
    const _columns = [
      {
        title: 'Order #',
        dataIndex: 'id',
      },
      {
        title: 'Order Date',
        dataIndex: 'createdAt',
        render: createdAt => (createdAt ? moment(createdAt).format('DD MMM YYYY') : null),
      },
      {
        title: 'Customer',
        dataIndex: 'customerName',
      },
      {
        title: 'Type',
        dataIndex: 'nature',
        render: nature => startCase(nature),
      },
      {
        title: 'Order Rate',
        dataIndex: 'orderRate',
      },
    ];

    if (role === 'Owner') {
      _columns.push(
        {
          title: 'Purchase Rate',
          children: [
            {
              title: 'Last',
              dataIndex: 'lastRate',
            },
            {
              title: 'Max',
              dataIndex: 'maxRate',
            },
          ],
        },
        {
          title: 'Theoritical Yield',
          dataIndex: 'theoriticalYield',
        },
        {
          title: 'Total Cost',
          children: [
            {
              title: 'Last',
              dataIndex: 'totalCostLast',
            },
            {
              title: 'Max',
              dataIndex: 'totalCostMax',
            },
          ],
        },
        {
          title: 'Cost/Unit',
          children: [
            {
              title: 'Last',
              dataIndex: 'costPerUnitLast',
            },
            {
              title: 'Max',
              dataIndex: 'costPerUnitMax',
            },
          ],
        },
        {
          title: 'GP',
          children: [
            {
              title: 'Last',
              dataIndex: 'gpLast',
            },
            {
              title: 'Max',
              dataIndex: 'gpMax',
            },
          ],
        }
      );
    }

    _columns.push({
      title: 'GP Percentage',
      children: [
        {
          title: 'Last',
          dataIndex: 'gpLastPercentage',
        },
        {
          title: 'Max',
          dataIndex: 'gpMaxPercentage',
        },
      ],
    });

    return _columns;
  }, []);

  return (
    <Structure>
      <>
        <Heading text="GP Percentage" />
        <Row>
          <LeftGrid>
            <Field label="Finished Good Name" allowClear required name="formulationId" component={AntSelect}>
              <Select.Option key={null} value="" disabled>
                Select finished good name
              </Select.Option>
              {formulationIdList.map(formulation => (
                <Select.Option key={formulation.id} value={formulation.id}>
                  {formulation.name}
                </Select.Option>
              ))}
            </Field>
          </LeftGrid>
          <RightGrid>
            <Field
              label="Buffer Percentage"
              type="number"
              required
              min={0}
              allowClear
              name="bufferPercentage"
              placeholder="Buffer Percentage"
              component={AntInput}
            />
          </RightGrid>
        </Row>
        <Row>
          <LeftGrid>
            <Field
              label="Sale Rate"
              type="number"
              required
              min={0}
              allowClear
              name="saleRate"
              placeholder="Sale Rate"
              component={AntInput}
            />
          </LeftGrid>
        </Row>
        <br />
        <Table
          columns={columns}
          bordered
          rowKey={record => record.id}
          pagination={false}
          dataSource={salesData}
          size="small"
          scroll={{ x: 425 }}
        />
        <br />
        <Heading text="Unauthorized Orders" />
        <Table
          columns={orderColumns}
          bordered
          rowKey={record => record.id}
          pagination={false}
          dataSource={ordersData}
          size="small"
          scroll={{ x: 425 }}
        />
      </>
    </Structure>
  );
};

const FormedGPPercentage = reduxForm({
  form: 'GPPercentage',
})(GPPercentage);

export default FormedGPPercentage;

import React, { useEffect, useState, Fragment } from 'react';
import PropTypes from 'prop-types';
import withStyles from 'react-jss';
import { Button, Select, Row, InputNumber, Input } from 'antd';
import { useForm, Controller, useWatch } from 'react-hook-form';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router';

import startCase from 'lodash/startCase';

import moment from 'moment';
import Structure from '../Structure/Structure';
import { errorNotification, successNotification } from '../Snackbar';
import Api from '../Api';
import styles from '../CommonStyles';
import { getMaterialIdList, clearMaterialIdList } from '../Actions';
import Heading from '../Components/Heading';

import LeftGrid from '../Components/LeftGrid';
import RightGrid from '../Components/RightGrid';
import GetField from '../Components/GetRHFField';
import constants from '../constants';

const AntSelect = GetField(Select);
const AntInput = GetField(Input);
const AntInputNumber = GetField(InputNumber);

const InventoryAdjustment = props => {
  const { classes } = props;

  const history = useHistory();

  const dispatch = useDispatch();

  const { handleSubmit, control } = useForm();

  const materialIdList = useSelector(state => state.materialIdList);

  const [Batches, SetBatches] = useState([]);

  const [confirmLoading, setConfirmLoading] = useState(false);

  const consignor = useWatch({
    control,
    name: 'consignor',
  });

  const materialId = useWatch({
    control,
    name: 'materialId',
  });

  useEffect(() => {
    dispatch(getMaterialIdList());
    return () => {
      dispatch(clearMaterialIdList());
    };
  }, []);

  const updateBatches = async (_consignor, _materialId) => {
    try {
      if (_consignor && _materialId) {
        const { data } = await Api.get(`/material-inventory/ids`, {
          consignor: _consignor,
          materialId: _materialId,
        });
        SetBatches(data);
      } else {
        SetBatches([]);
      }
    } catch (error) {
      errorNotification(error);
    }
  };

  useEffect(() => {
    if (consignor && materialId) {
      updateBatches(consignor, materialId);
    }
  }, [consignor, materialId]);

  const onSubmit = async formValues => {
    try {
      setConfirmLoading(true);
      await Api.post('/material-inventory/adjustment', formValues);
      successNotification('Stock adjusted');
      setConfirmLoading(false);
      history.push('/material/inventory/adjustment/list');
    } catch (err) {
      setConfirmLoading(false);
      errorNotification(err);
    }
  };

  return (
    <Structure>
      <>
        <Heading text="Stock Adjustment" />
        <form onSubmit={handleSubmit(onSubmit)}>
          <Row>
            <LeftGrid>
              <Controller
                name="type"
                control={control}
                render={({ field }) => (
                  <AntSelect allowClear label="Type" placeholder="Select type" {...field}>
                    <Select.Option key="stockIn" value="stockIn">
                      Stock In
                    </Select.Option>
                    <Select.Option key="stockOut" value="stockOut">
                      Stock Out
                    </Select.Option>
                  </AntSelect>
                )}
              />
            </LeftGrid>
            <RightGrid>
              <Controller
                name="consignor"
                control={control}
                render={({ field }) => (
                  <AntSelect allowClear label="Consignor" placeholder="Select consignor" {...field}>
                    {Object.values(constants.consignors.list).map(el => (
                      <Select.Option key={el} value={el}>
                        {el}
                      </Select.Option>
                    ))}
                  </AntSelect>
                )}
              />
            </RightGrid>
          </Row>
          <Row>
            <LeftGrid>
              <Controller
                name="materialId"
                control={control}
                render={({ field }) => (
                  <AntSelect allowClear label="Material" placeholder="Select material" {...field}>
                    {materialIdList.map(material => (
                      <Select.Option key={material.id} value={material.id}>
                        {material.name}
                      </Select.Option>
                    ))}
                  </AntSelect>
                )}
              />
            </LeftGrid>
            <RightGrid>
              <Controller
                name="materialId"
                control={control}
                render={({ field }) => (
                  <AntSelect allowClear disabled label="Type" placeholder="Select material" {...field}>
                    {materialIdList.map(material => (
                      <Select.Option key={material.id} value={material.id}>
                        {startCase(material.type)}
                      </Select.Option>
                    ))}
                  </AntSelect>
                )}
              />
            </RightGrid>
          </Row>
          <Row>
            <LeftGrid>
              <Controller
                name="batch"
                control={control}
                render={({ field }) => (
                  <AntSelect {...field} allowClear label="Batch" placeholder="Batch">
                    {Batches.map(batch => (
                      <Select.Option key={batch.batch} value={batch.batch}>
                        {batch.batch}
                      </Select.Option>
                    ))}
                  </AntSelect>
                )}
              />
            </LeftGrid>
            <RightGrid>
              <Controller
                name="batch"
                control={control}
                render={({ field }) => (
                  <AntSelect {...field} disabled allowClear label="Stock" placeholder="Stock">
                    {Batches.map(batch => (
                      <Select.Option key={batch.batch} value={batch.batch}>
                        {batch.stock}
                      </Select.Option>
                    ))}
                  </AntSelect>
                )}
              />
            </RightGrid>
          </Row>
          <Row>
            <LeftGrid>
              <Controller
                name="batch"
                control={control}
                render={({ field }) => (
                  <AntSelect {...field} disabled allowClear label="Mfg." placeholder="Mfg.">
                    {Batches.map(batch => (
                      <Select.Option key={batch.batch} value={batch.batch}>
                        {moment(batch.mfgDate).format('MMM YY')}
                      </Select.Option>
                    ))}
                  </AntSelect>
                )}
              />
            </LeftGrid>
            <RightGrid>
              <Controller
                name="batch"
                control={control}
                render={({ field }) => (
                  <AntSelect {...field} disabled allowClear label="Exp." placeholder="Exp.">
                    {Batches.map(batch => (
                      <Select.Option key={batch.batch} value={batch.batch}>
                        {moment(batch.expDate).format('MMM YY')}
                      </Select.Option>
                    ))}
                  </AntSelect>
                )}
              />
            </RightGrid>
          </Row>
          <Row>
            <LeftGrid>
              <Controller
                name="quantity"
                control={control}
                render={({ field }) => (
                  <AntInputNumber
                    label="Adjust Quantity"
                    placeholder="To adjust quantity"
                    step={0.001}
                    min={0}
                    {...field}
                  />
                )}
              />
            </LeftGrid>
            <RightGrid>
              <Controller
                name="remark"
                control={control}
                render={({ field }) => <AntInput allowClear label="Remark" placeholder="Remark" {...field} />}
              />
            </RightGrid>
          </Row>
          <Row className={classes.buttonContainer}>
            <Button type="primary" loading={confirmLoading} htmlType="submit">
              Submit
            </Button>
          </Row>
        </form>
      </>
    </Structure>
  );
};

InventoryAdjustment.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(InventoryAdjustment);

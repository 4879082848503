import React, { useCallback, useMemo } from 'react';
import PropTypes from 'prop-types';
import withStyles from 'react-jss';
import { useSelector } from 'react-redux';
import { Layout, Menu, Drawer } from 'antd';
import { Link, useHistory } from 'react-router-dom';
import styles from './styles';
import { Modules, getDefaultSelectedKeys, getSubModules } from './helper';
import { get } from '../utils';

const { Sider } = Layout;

const Sidebar = props => {
  const { classes, collapsed, open, setVisible } = props;

  const auth = useSelector(state => state.auth);
  const history = useHistory();

  const permissions = get(auth, ['permissions'], []);
  const role = get(auth, ['user', 'role'], []);

  const toggleDrawerCollapse = () => {
    setVisible(!open);
  };

  const renderItem = useCallback(
    ({ type, module, title, link, subModules, icon }) => {
      if (type === 'item') {
        if (role !== 'Owner' && !permissions.includes(module)) {
          return null;
        }
        return { label: title, key: title, icon, onClick: () => history.push(link) };
      }
      if (type === 'list') {
        if (
          role !== 'Owner' &&
          !getSubModules(subModules).filter(subModule => permissions.includes(subModule)).length
        ) {
          return null;
        }
        return {
          label: title,
          key: title,
          icon,
          children: subModules.map(subModule => renderItem(subModule)),
        };
      }
    },
    [permissions]
  );

  const defaultSelectedKeys = useMemo(
    () => [getDefaultSelectedKeys(history.location.pathname)],
    [history.location.pathname]
  );

  const menuItems = useMemo(() => {
    if (auth.user) {
      return Modules.map(renderItem);
    }

    return [];
  }, [auth]);

  return (
    <div>
      <Drawer className={classes.drawer} placement="left" closable={false} onClose={toggleDrawerCollapse} open={open}>
        <div className={classes.logoCenterDiv}>
          <Link to="/">
            <img src="/images/name.svg" alt="logo" className={classes.logo} />
          </Link>
        </div>
        <br />
        <Menu
          theme="dark"
          mode="inline"
          className={classes.menu}
          items={menuItems}
          defaultSelectedKeys={defaultSelectedKeys}
        />
      </Drawer>
      <Sider className={classes.sidebar} collapsed={collapsed}>
        <div className={classes.logoCenterDiv}>
          <Link to="/">
            {collapsed ? (
              <img src="/images/logo.png" alt="logo" className={classes.logo} />
            ) : (
              <img src="/images/name.png" alt="logo" className={classes.logoName} />
            )}
          </Link>
        </div>
        <br />
        <Menu
          theme="dark"
          mode="inline"
          items={menuItems}
          className={classes.menu}
          defaultSelectedKeys={defaultSelectedKeys}
        />
      </Sider>
    </div>
  );
};

Sidebar.propTypes = {
  classes: PropTypes.object.isRequired,
  collapsed: PropTypes.bool,
  open: PropTypes.bool,
  setVisible: PropTypes.func.isRequired,
};

const StyledSidebar = withStyles(styles)(Sidebar);

export default StyledSidebar;

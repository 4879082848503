export const supplierList = (state = [], action) => {
  if (action.type === 'FETCH_SUPPLIER_LIST') {
    return action.payload;
  }
  if (action.type === 'CLEAR_SUPPLIER_LIST') {
    return [];
  }
  return state;
};

export const supplier = (state = {}, action) => {
  if (action.type === 'FETCH_SUPPLIER') {
    return action.payload;
  }
  if (action.type === 'CLEAR_SUPPLIER') {
    return {};
  }
  return state;
};

export const supplierIdList = (state = [], action) => {
  if (action.type === 'FETCH_SUPPLIER_ID_LIST') {
    return action.payload;
  }
  if (action.type === 'CLEAR_SUPPLIER_ID_LIST') {
    return [];
  }
  return state;
};

export const supplierLedger = (state = {}, action) => {
  if (action.type === 'FETCH_SUPPLIER_LEDGER') {
    return action.payload;
  }
  if (action.type === 'CLEAR_SUPPLIER_LEDGER') {
    return {};
  }
  return state;
};

export const supplierOpeningBalancesList = (state = [], action) => {
  if (action.type === 'FETCH_SUPPLIER_OPENING_BALANCES_LIST') {
    return action.payload;
  }
  if (action.type === 'CLEAR_SUPPLIER_OPENING_BALANCES_LIST') {
    return [];
  }
  return state;
};

export const supplierOpeningBalance = (state = {}, action) => {
  if (action.type === 'FETCH_SUPPLIER_OPENING_BALANCE') {
    return action.payload;
  }
  if (action.type === 'CLEAR_SUPPLIER_OPENING_BALANCE') {
    return {};
  }
  return state;
};

export const supplierWisePayables = (state = [], action) => {
  if (action.type === 'FETCH_SUPPLIER_WISE_PAYABLES') {
    return action.payload;
  }
  if (action.type === 'CLEAR_SUPPLIER_WISE_PAYABLES') {
    return [];
  }
  return state;
};

export const orderReport = (state = {}, action) => {
  if (action.type === 'FETCH_ORDER_REPORT') {
    return action.payload;
  }
  if (action.type === 'CLEAR_ORDER_REPORT') {
    return {};
  }
  return state;
};

export const orderThroughputBrands = (state = [], action) => {
  if (action.type === 'FETCH_ORDER_THROUGHPUT_BRANDS') {
    return action.payload;
  }
  if (action.type === 'CLEAR_ORDER_THROUGHPUT_BRANDS') {
    return [];
  }
  return state;
};

export const orderUsersReport = (state = {}, action) => {
  if (action.type === 'FETCH_ORDER_USERS_REPORT') {
    return action.payload;
  }
  if (action.type === 'CLEAR_ORDER_USERS_REPORT') {
    return {};
  }
  return state;
};

export const purchaseOrderReport = (state = {}, action) => {
  if (action.type === 'FETCH_PURCHASE_ORDER_REPORT') {
    return action.payload;
  }
  if (action.type === 'CLEAR_PURCHASE_ORDER_REPORT') {
    return {};
  }
  return state;
};

export const purchaseReport = (state = {}, action) => {
  if (action.type === 'FETCH_PURCHASE_REPORT') {
    return action.payload;
  }
  if (action.type === 'CLEAR_PURCHASE_REPORT') {
    return {};
  }
  return state;
};

export const saleReport = (state = {}, action) => {
  if (action.type === 'FETCH_SALE_REPORT') {
    return action.payload;
  }
  if (action.type === 'CLEAR_SALE_REPORT') {
    return {};
  }
  return state;
};

export const materialReport = (state = {}, action) => {
  if (action.type === 'FETCH_MATERIAL_REPORT') {
    return action.payload;
  }
  if (action.type === 'CLEAR_MATERIAL_REPORT') {
    return {};
  }
  return state;
};

export const customerInventoryReport = (state = [], action) => {
  if (action.type === 'FETCH_CUSTOMER_INVENTORY_TOTAL_REPORT') {
    return action.payload;
  }
  if (action.type === 'CLEAR_CUSTOMER_INVENTORY_TOTAL_REPORT') {
    return [];
  }
  return state;
};

export const materialInOutReport = (state = [], action) => {
  if (action.type === 'FETCH_MATERIAL_IN_OUT_REPORT') {
    return action.payload;
  }
  if (action.type === 'CLEAR_MATERIAL_IN_OUT_REPORT') {
    return [];
  }
  return state;
};

export const formulationReport = (state = {}, action) => {
  if (action.type === 'FETCH_FORMULATION_REPORT') {
    return action.payload;
  }
  if (action.type === 'CLEAR_FORMULATION_REPORT') {
    return {};
  }
  return state;
};

import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { useSelector, useDispatch } from 'react-redux';
import withStyles from 'react-jss';
import { Table, Popconfirm, Tooltip, Upload, Button, Typography, Modal } from 'antd';
import {
  CloudDownloadOutlined,
  CloudUploadOutlined,
  DeleteOutlined,
  EditOutlined,
  FileTextOutlined,
} from '@ant-design/icons';
import moment from 'moment';
import { Link } from 'react-router-dom';
import startCase from 'lodash/startCase';
import fileDownload from 'js-file-download';
import Structure from '../Structure/Structure';
import {
  filterDropdown,
  get,
  onFilter,
  sorter,
  onFilterInsideObject,
  sorterInsideObject,
  dateSorter,
  dateFilterDropdown,
  onDateFilter,
} from '../utils';
import Api from '../Api';
import { errorNotification, successNotification, infoNotification } from '../Snackbar';
import styles from '../CommonStyles';
import Heading from '../Components/Heading';
import {
  getMaterialSaleList,
  clearMaterialSaleList,
  getMaterialSaleFilesList,
  clearMaterialSaleFilesList,
} from '../Actions';
import { materialTypes } from '../constants';
import StylesModals from './Modals';

const { Text } = Typography;

const MaterialSaleList = props => {
  const { classes } = props;
  const dispatch = useDispatch();

  const auth = useSelector(state => state.auth);
  const permissions = get(auth, ['permissions'], []);
  const materialSaleList = useSelector(state => state.materialSaleList);
  const materialSaleFilesList = useSelector(state => state.materialSaleFilesList);

  const [saleId, setSaleId] = useState(null);
  const [modalType, setModalType] = useState(null);
  const [fileList, setFileList] = useState([]);
  const [uploading, setUploading] = useState(false);
  const [rowCounts, setRowCounts] = useState(0);

  useEffect(() => {
    dispatch(getMaterialSaleList());
    return () => {
      dispatch(clearMaterialSaleList());
      dispatch(clearMaterialSaleFilesList());
    };
  }, []);

  useEffect(() => {
    if (materialSaleList) {
      setRowCounts(materialSaleList.length);
    }
  }, [materialSaleList]);

  useEffect(() => {
    if (saleId && modalType === 'ViewFiles') {
      dispatch(getMaterialSaleFilesList(saleId));
    }
  }, [saleId, modalType]);

  const showModal = (id, type) => {
    setSaleId(id);
    setModalType(type);
  };

  const hideModal = () => {
    setSaleId(null);
    setModalType(null);
    setFileList([]);
    dispatch(clearMaterialSaleFilesList());
  };

  const onDelete = async id => {
    try {
      await Api.delete(`/material-sales/${id}`);
      successNotification('Material sale deleted');
      dispatch(getMaterialSaleList());
    } catch (err) {
      errorNotification(err);
    }
  };

  const onUpload = async () => {
    try {
      setUploading(true);
      const formData = new FormData();
      fileList.forEach((file, index) => {
        formData.append(`files[${index}]`, file);
      });
      const { data } = await Api.post(`/material-sales/${saleId}/files`, formData, {
        headers: { 'content-type': 'multipart/form-data' },
      });
      infoNotification(data);
      setUploading(false);
      hideModal();
      setFileList([]);
    } catch (err) {
      errorNotification(err);
    }
  };

  const onFileDelete = async id => {
    try {
      await Api.delete(`/material-sales/${saleId}/files/${id}`);
      successNotification('File delete');
      dispatch(clearMaterialSaleFilesList(saleId));
    } catch (err) {
      errorNotification(err);
    }
  };

  const exportList = async () => {
    try {
      const { data } = await Api.getFile(`/material-sales/export`);
      fileDownload(data, `material-sales-list.csv`);
    } catch (error) {
      errorNotification(error);
    }
  };

  const onRemove = file => {
    const index = fileList.indexOf(file);
    const newFileList = fileList.slice();
    newFileList.splice(index, 1);
    setFileList(newFileList);
  };

  const beforeUpload = (file, files) => {
    setFileList([...fileList, ...files]);
    return false;
  };

  const tooltipShow = arg => (
    <Tooltip placement="topLeft" title={arg}>
      {arg}
    </Tooltip>
  );

  const tooltipShowInsideObject = (keys, arg) => {
    const value = get(arg, keys, null);
    return (
      <Tooltip placement="topLeft" title={materialTypes.includes(`${value}`) ? startCase(value) : value}>
        {materialTypes.includes(`${value}`) ? startCase(value) : value}
      </Tooltip>
    );
  };

  const columns = [
    {
      title: 'Sale No.',
      dataIndex: 'id',
      sorter: sorter('id'),
      filterDropdown,
      onFilter: onFilter('id'),
      width: 80,
      fixed: 'left',
    },
    {
      title: 'Material',
      dataIndex: 'material',
      sorter: sorterInsideObject('material', 'name'),
      filterDropdown,
      onFilter: onFilterInsideObject('material', 'name'),
      render: tooltipShowInsideObject.bind(null, ['name']),
      width: 160,
      fixed: 'left',
    },
    {
      title: 'Type',
      dataIndex: 'material',
      sorter: sorterInsideObject('material', 'type'),
      filterDropdown,
      onFilter: onFilterInsideObject('material', 'type'),
      render: tooltipShowInsideObject.bind(null, ['type']),
      width: 100,
    },
    {
      title: 'Unit',
      dataIndex: 'material',
      sorter: sorterInsideObject('material', 'unit'),
      filterDropdown,
      onFilter: onFilterInsideObject('material', 'unit'),
      render: tooltipShowInsideObject.bind(null, ['unit']),
      width: 80,
    },
    {
      title: 'Customer',
      dataIndex: 'customer',
      filterDropdown,
      sorter: sorter('customer'),
      onFilter: onFilter('customer'),
      width: 130,
    },
    {
      title: 'Consignor',
      dataIndex: 'consignor',
      sorter: sorter('consignor'),
      filterDropdown,
      onFilter: onFilter('consignor'),
      width: 130,
    },
    {
      title: 'Voucher No.',
      dataIndex: 'voucherNo',
      sorter: sorter('voucherNo'),
      filterDropdown,
      onFilter: onFilter('voucherNo'),
      width: 90,
    },
    {
      title: 'Voucher Date',
      dataIndex: 'voucherDate',
      sorter: dateSorter('voucherDate'),
      filterDropdown: dateFilterDropdown,
      onFilter: onDateFilter('voucherDate'),
      render: voucherDate => (voucherDate ? moment(voucherDate).format('DD MMM YYYY') : null),
      width: 120,
    },
    {
      title: 'Sale Type',
      dataIndex: 'type',
      sorter: sorter('type'),
      filterDropdown,
      onFilter: onFilter('type'),
      render: type => startCase(type),
      width: 100,
    },
    {
      title: 'Quantity',
      dataIndex: 'quantity',
      sorter: sorter('quantity'),
      filterDropdown,
      onFilter: onFilter('quantity'),
      width: 130,
      align: 'right',
    },
    {
      title: 'Rate',
      dataIndex: 'rate',
      sorter: sorter('rate'),
      filterDropdown,
      onFilter: onFilter('rate'),
      render: rate => Number(rate).toLocaleString('en-IN'),
      width: 130,
      align: 'right',
    },
    {
      title: 'Sale Value',
      dataIndex: 'value',
      sorter: sorter('value'),
      filterDropdown,
      onFilter: onFilter('value'),
      render: value => Number(value).toLocaleString('en-IN'),
      width: 130,
      align: 'right',
    },
    {
      title: 'GST (in %)',
      dataIndex: 'gst',
      sorter: sorter('gst'),
      filterDropdown,
      onFilter: onFilter('gst'),
      width: 100,
      align: 'right',
    },
    {
      title: 'GST Value',
      dataIndex: 'gstValue',
      sorter: sorter('gstValue'),
      filterDropdown,
      onFilter: onFilter('gstValue'),
      render: gstValue => Number(gstValue).toLocaleString('en-IN'),
      width: 130,
      align: 'right',
    },
    {
      title: 'Forwarding Charges',
      dataIndex: 'forwardingCharges',
      sorter: sorter('forwardingCharges'),
      filterDropdown,
      onFilter: onFilter('forwardingCharges'),
      render: forwardingCharges => Number(forwardingCharges).toLocaleString('en-IN'),
      width: 130,
      align: 'right',
    },
    {
      title: 'Forwarding Charges Tax Value (Tax 18%)',
      dataIndex: 'forwardingChargesTax',
      sorter: sorter('forwardingChargesTax'),
      filterDropdown,
      onFilter: onFilter('forwardingChargesTax'),
      render: forwardingChargesTax => Number(forwardingChargesTax).toLocaleString('en-IN'),
      width: 130,
      align: 'right',
    },
    {
      title: 'OtherCharges / Discount',
      dataIndex: 'otherChargesDiscount',
      sorter: sorter('otherChargesDiscount'),
      filterDropdown,
      onFilter: onFilter('otherChargesDiscount'),
      render: otherChargesDiscount => Number(otherChargesDiscount).toLocaleString('en-IN'),
      width: 130,
      align: 'right',
    },
    {
      title: 'Round Of',
      dataIndex: 'roundOf',
      sorter: sorter('roundOf'),
      filterDropdown,
      onFilter: onFilter('roundOf'),
      render: roundOf => Number(roundOf).toLocaleString('en-IN'),
      width: 130,
      align: 'right',
    },
    {
      title: 'Total',
      dataIndex: 'total',
      sorter: sorter('total'),
      filterDropdown,
      onFilter: onFilter('total'),
      render: total => Number(total).toLocaleString('en-IN'),
      width: 140,
      align: 'right',
    },
    {
      title: 'Marks & No/Container No',
      dataIndex: 'containerNo',
      sorter: sorter('containerNo'),
      filterDropdown,
      onFilter: onFilter('containerNo'),
      render: tooltipShow,
      width: 120,
    },
    {
      title: 'No & Kind of Pkgs.',
      dataIndex: 'kindOfPackage',
      sorter: sorter('kindOfPackage'),
      filterDropdown,
      onFilter: onFilter('kindOfPackage'),
      render: tooltipShow,
      width: 120,
    },
    {
      title: 'Done By',
      dataIndex: 'createdByUser',
      sorter: sorter('createdByUser'),
      filterDropdown,
      onFilter: onFilter('createdByUser'),
      render: tooltipShow,
      width: 120,
    },
    {
      title: 'Remarks',
      dataIndex: 'remarks',
      sorter: sorter('remarks'),
      filterDropdown,
      onFilter: onFilter('remarks'),
      ellipsis: { showTitle: false },
      render: tooltipShow,
      width: 150,
    },
  ];

  const renderActionIcons = record => (
    <div>
      {permissions.includes('edit_material_sale') ? (
        <>
          <Tooltip placement="bottom" title="Edit">
            <Link to={`/material/sale/edit/${record.id}`}>
              <EditOutlined className={classes.tableIcon} />
            </Link>
          </Tooltip>
          &nbsp;&nbsp;&nbsp;&nbsp;
        </>
      ) : null}
      {permissions.includes('delete_material_sale') ? (
        <>
          <Tooltip placement="bottom" title="Delete">
            <Popconfirm
              title="Are you sure you want to delete?"
              onConfirm={() => onDelete(record.id)}
              okText="Yes"
              cancelText="No"
            >
              <DeleteOutlined className={classes.tableIcon} />
            </Popconfirm>
          </Tooltip>
        </>
      ) : null}
    </div>
  );

  if (permissions.includes('edit_material_sale') || permissions.includes('delete_material_sale')) {
    columns.push({
      title: 'Actions',
      width: 70,
      render: renderActionIcons,
    });
  }

  const renderPurchaseUploadColumn = record => {
    if (permissions.includes('material_sale_file_upload')) {
      return (
        <Tooltip placement="bottom" title="Upload Files">
          <CloudUploadOutlined className={classes.tableIcon} onClick={() => showModal(record.id, 'UploadFiles')} />
        </Tooltip>
      );
    }
    return null;
  };

  if (permissions.includes('material_sale_file_upload')) {
    columns.push({
      title: 'Upload Files',
      width: 70,
      render: renderPurchaseUploadColumn,
    });
  }

  const renderPurchaseViewColumn = record => {
    if (permissions.includes('material_sale_file_view')) {
      let color;
      if (record.files) {
        if (record.files > 0) {
          color = 'Green';
        } else {
          color = 'Red';
        }
      }
      return (
        <Tooltip placement="bottom" title="View Files">
          <CloudDownloadOutlined
            style={{ color }}
            className={classes.tableIcon}
            onClick={() => showModal(record.id, 'ViewFiles')}
          />
        </Tooltip>
      );
    }
    return null;
  };
  if (permissions.includes('material_sale_file_view')) {
    columns.push({
      title: 'View Files',
      width: 70,
      render: renderPurchaseViewColumn,
    });
  }

  const filesTableColumns = [];

  const renderFileTableActionColumn = record => {
    return (
      <Tooltip placement="bottom" title="Delete File">
        <Popconfirm
          title="Are you sure you want to delete?"
          onConfirm={() => onFileDelete(record.id)}
          okText="Yes"
          cancelText="No"
        >
          <DeleteOutlined className={classes.tableIcon} />
        </Popconfirm>
      </Tooltip>
    );
  };
  const renderFileTableNameColumn = record => (
    <a href={record.url} rel="noreferrer noopener" target="_blank">
      <Tooltip placement="bottom" title={`Click to open/download "${record.fileName}" file.`}>
        {record.fileName}
      </Tooltip>
    </a>
  );
  filesTableColumns.push({
    title: 'File',
    render: renderFileTableNameColumn,
  });
  filesTableColumns.push({
    title: 'Delete',
    render: renderFileTableActionColumn,
    width: 70,
  });

  const renderSendInvoiceColumn = record => (
    <Tooltip placement="top" title="Send Invoice">
      <FileTextOutlined className={classes.tableIcon} onClick={() => showModal(record.id, 'SendInvoice')} />
    </Tooltip>
  );

  columns.push({
    title: 'Send Invoice',
    width: 70,
    render: renderSendInvoiceColumn,
  });

  return (
    <Structure>
      <>
        <Heading text={`Material Sale List (${rowCounts})`}>
          <Button type="default" onClick={exportList}>
            Export
          </Button>
        </Heading>
        <Table
          columns={columns}
          bordered
          rowKey={record => record.id}
          pagination={{ defaultPageSize: 20, position: ['bottomLeft'] }}
          dataSource={materialSaleList}
          size="small"
          scroll={{ x: 1050, y: window.innerHeight - 300 }}
          onChange={(_page, _filters, _sorter, { currentDataSource }) => {
            setRowCounts((currentDataSource || []).length);
          }}
          summary={pageData => {
            let grandTotal = 0;
            pageData.forEach(({ total }) => {
              grandTotal += +total;
            });
            return (
              <>
                <Table.Summary.Row>
                  <Table.Summary.Cell colSpan={18}>Grand Total</Table.Summary.Cell>
                  <Table.Summary.Cell className={classes.textAlignRight}>
                    <Text>{Number(grandTotal.toFixed(2)).toLocaleString('en-IN')}</Text>
                  </Table.Summary.Cell>
                  <Table.Summary.Cell colSpan={7} />
                </Table.Summary.Row>
              </>
            );
          }}
        />
        <Modal
          onOk={hideModal}
          open={modalType === 'UploadFiles'}
          centered
          maskClosable={false}
          title="Upload Material Sale Files."
          width="500px"
          okText="Close"
          cancelButtonProps={{ className: classes.hide }}
          okButtonProps={{ className: classes.button }}
          closable={false}
        >
          <Upload multiple onRemove={onRemove} beforeUpload={beforeUpload} fileList={fileList}>
            <Button icon={<CloudUploadOutlined />}>Select Files</Button>
          </Upload>
          <Button
            type="primary"
            onClick={onUpload}
            disabled={fileList.length === 0}
            loading={uploading}
            style={{ marginTop: 16 }}
          >
            {uploading ? 'Uploading' : 'Upload'}
          </Button>
        </Modal>
        <Modal
          onOk={hideModal}
          open={modalType === 'ViewFiles'}
          centered
          maskClosable={false}
          title="View Material Sale Files."
          width="800px"
          okText="Close"
          cancelButtonProps={{ className: classes.hide }}
          closable={false}
          okButtonProps={{ className: classes.button }}
        >
          <Table
            columns={filesTableColumns}
            bordered
            rowKey={record => record.id}
            dataSource={materialSaleFilesList}
            size="small"
            pagination={{ position: ['bottomLeft'] }}
          />
        </Modal>
        <StylesModals hideModal={hideModal} saleId={saleId} modalType={modalType} />
      </>
    </Structure>
  );
};

MaterialSaleList.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(MaterialSaleList);

import React, { useEffect, useState } from 'react';
import { useSelector, useDispatch, connect } from 'react-redux';
import withStyles from 'react-jss';
import PropTypes from 'prop-types';
import { Popconfirm, Table, Tooltip, Modal, Input, Row, Col, DatePicker, Select } from 'antd';
import moment from 'moment';
import { EditOutlined, EyeOutlined, FileTextOutlined } from '@ant-design/icons';
import { Field, getFormValues, reduxForm, reset } from 'redux-form';
import Structure from '../Structure/Structure';
import { sorter, onFilter, filterDropdown, dateSorter, dateFilterDropdown, onDateFilter, get } from '../utils';
import {
  getBatchList,
  clearBatchList,
  getOrderBatchMaterialDetail,
  clearOrderBatchMaterialDetail,
  getBatchDetail,
  clearBatchDetail,
  getBatchSeriesList,
  clearBatchSeriesList,
} from '../Actions';
import styles from '../CommonStyles';
import Heading from '../Components/Heading';
import { errorNotification, successNotification } from '../Snackbar';
import Api from '../Api';
import GetField from '../Components/GetField';

const AntInput = GetField(Input);
const AntDatePicker = GetField(DatePicker);
const AntSelect = GetField(Select);

const BatchList = props => {
  const { classes, change } = props;
  const dispatch = useDispatch();

  const auth = useSelector(state => state.auth);
  const permissions = get(auth, ['permissions'], []);
  const batchList = useSelector(state => state.batchList);
  const orderBatchMaterialsDetail = useSelector(state => state.orderBatchMaterialsDetail);
  const batchDetail = useSelector(state => state.batchDetail);

  const seriesList = useSelector(state => state.seriesList);

  const [financialYear, setFinancialYear] = useState('2024');

  const [orderId, setOrderId] = useState(null);
  const [confirmLoading, setConfirmLoading] = useState(false);
  const [modalType, setModalType] = useState(null);
  const [Rows, setRows] = useState([{ index: 1 }]);
  const [batchSeries, setBatchSeries] = useState(null);
  const [batchNo, setBatchNo] = useState(null);
  const [rowCounts, setRowCounts] = useState(0);

  const FormValues = useSelector(state => getFormValues('BatchForm')(state));

  useEffect(() => {
    dispatch(getBatchSeriesList());
    return () => {
      dispatch(clearBatchSeriesList());
      dispatch(clearBatchList());
      dispatch(clearOrderBatchMaterialDetail());
      dispatch(clearBatchDetail());
    };
  }, []);

  useEffect(() => {
    dispatch(getBatchList({ financialYear }));
  }, [financialYear]);

  useEffect(() => {
    if (orderId && modalType === 'FillBatchMaterialDetails') {
      dispatch(getOrderBatchMaterialDetail(orderId));
    }
    if (orderId && modalType === 'ViewBatchMaterialDetails') {
      dispatch(getBatchDetail(orderId));
    }
  }, [orderId, modalType]);

  useEffect(() => {
    setRows(orderBatchMaterialsDetail.map((el, index) => ({ index: index + 1 })));
  }, [orderBatchMaterialsDetail]);

  useEffect(() => {
    if (FormValues && FormValues.batchSeries) {
      if (FormValues.batchSeries === batchSeries) {
        change('batchNo', batchNo);
      } else {
        Api.get(`/orders/series/${FormValues.batchSeries}`)
          .then(({ data }) => {
            change('batchNo', data);
          })
          .catch(console.error);
      }
    }
  }, [FormValues && FormValues.batchSeries]);

  useEffect(() => {
    if (batchList) {
      setRowCounts(batchList.length);
    }
  }, [batchList]);

  const showModal = (id, type) => {
    setOrderId(id);
    setModalType(type);
  };

  const hideModal = () => {
    setOrderId(null);
    setModalType(null);
    dispatch(reset('BatchForm'));
    dispatch(clearOrderBatchMaterialDetail());
  };

  const onBatchSubmit = async () => {
    try {
      setConfirmLoading(true);
      await Api.post(`/orders/${orderId}/batchprinted`, FormValues);
      successNotification('Batch detail submit');
      dispatch(reset('BatchForm'));
      setConfirmLoading(false);
      hideModal();
      dispatch(getBatchList({ financialYear }));
    } catch (err) {
      setConfirmLoading(false);
      errorNotification(err);
    }
  };

  const columns = [
    {
      title: 'Batch Number',
      dataIndex: 'batchCode',
      sorter: sorter('batchCode'),
      filterDropdown,
      onFilter: onFilter('batchCode'),
    },
    {
      title: 'Order No.',
      dataIndex: 'orderId',
      sorter: sorter('orderId'),
      filterDropdown,
      onFilter: onFilter('orderId'),
    },
    {
      title: 'Formulation',
      dataIndex: 'formulation',
      sorter: sorter('formulation'),
      filterDropdown,
      onFilter: onFilter('formulation'),
    },
    {
      title: 'MRP',
      dataIndex: 'mrp',
      sorter: sorter('mrp'),
      filterDropdown,
      onFilter: onFilter('mrp'),
      render: mrp => Number(mrp).toLocaleString('en-IN'),
      align: 'right',
    },
    {
      title: 'Manufactured Date',
      dataIndex: 'mfgDate',
      sorter: dateSorter('mfgDate'),
      filterDropdown: dateFilterDropdown,
      onFilter: onDateFilter('mfgDate'),
      render: mfgDate => (mfgDate ? moment(mfgDate).format('MMM YYYY') : null),
    },
    {
      title: 'Expiry Date',
      dataIndex: 'expDate',
      sorter: dateSorter('expDate'),
      filterDropdown: dateFilterDropdown,
      onFilter: onDateFilter('expDate'),
      render: expDate => (expDate ? moment(expDate).format('MMM YYYY') : null),
    },
    {
      title: 'Created By',
      dataIndex: 'user',
      sorter: sorter('user'),
      filterDropdown,
      onFilter: onFilter('user'),
    },
  ];

  const renderText = ({ key, type = 'text', disabled = false } = {}) => {
    const TableField = (text, record) => (
      <Field
        name={`materials[${record.index - 1}][${key}]`}
        disabled={disabled}
        min="0"
        type={type}
        component={AntInput}
      />
    );
    return TableField;
  };

  const materialColumns = [
    {
      title: 'Material',
      dataIndex: 'name',
      width: 300,
      render: renderText({ key: 'name', disabled: true }),
    },
    {
      title: 'Unit',
      dataIndex: 'unit',
      width: 70,
      render: renderText({ key: 'unit', disabled: true }),
    },
    {
      title: 'Qty As Per Order',
      dataIndex: 'orderQuantity',
      width: 80,
      render: renderText({ key: 'orderQuantity', disabled: true }),
    },
    {
      title: 'Actual Qty',
      dataIndex: 'quantity',
      width: 80,
      render: renderText({ key: 'quantity', disabled: false }),
    },
    {
      title: 'Printed By',
      dataIndex: 'remarks',
      width: 100,
      render: renderText({ key: 'remarks', disabled: false }),
    },
  ];

  const ViewbatchDetail = [
    {
      title: 'Material',
      dataIndex: 'name',
      width: 300,
    },
    {
      title: 'Unit',
      dataIndex: 'unit',
      width: 70,
    },
    {
      title: 'Qty As Per Order',
      dataIndex: 'orderQuantity',
      width: 70,
    },
    {
      title: 'Actual Qty',
      dataIndex: 'quantity',
      width: 70,
    },
    {
      title: 'Printed By',
      dataIndex: 'remarks',
      width: 40,
    },
    {
      title: 'Done By',
      dataIndex: 'createdByUser',
      width: 40,
    },
  ];

  const renderDetailsColumn = record => {
    if (permissions.includes('batch_details')) {
      if (record.haveMaterialDetais) {
        return (
          <Tooltip placement="top" title="View Batch Quantity Detail">
            <EyeOutlined
              className={classes.tableIcon}
              onClick={() => showModal(record.orderId, 'ViewBatchMaterialDetails')}
            />
          </Tooltip>
        );
      }
      return (
        <Tooltip placement="top" title="Fill Batch Quantity Detail">
          <FileTextOutlined
            className={classes.tableIcon}
            onClick={() => showModal(record.orderId, 'FillBatchMaterialDetails')}
          />
        </Tooltip>
      );
    }
    return 'Done';
  };

  const renderActionIcons = record => (
    <>
      <Tooltip placement="bottom" title="Edit">
        <EditOutlined
          className={classes.tableIcon}
          onClick={() => {
            setModalType('EditBatchDetail');
            setOrderId(record.orderId);
            setBatchNo(record.batchNo);
            setBatchSeries(record.batchSeries);
            change('mfgDate', moment(record.mfgDate).format('YYYY-MM'));
            change(
              'validity',
              record.mfgDate && record.expDate ? moment(record.expDate).diff(record.mfgDate, 'month') + 1 : null
            );
            change('batchNo', record.batchNo);
            change('batchSeries', record.batchSeries);
          }}
        />
      </Tooltip>
    </>
  );

  if (permissions.includes('batch_details')) {
    columns.push(
      {
        title: 'Batch Detail',
        width: 70,
        render: renderDetailsColumn,
      },
      {
        title: 'Actions',
        width: 70,
        render: renderActionIcons,
      }
    );
  }

  const onBatchEdit = async () => {
    try {
      setConfirmLoading(true);
      await Api.put(`/orders/${orderId}/batchdetails`, FormValues);
      successNotification('Batch details updated');
      setConfirmLoading(false);
      dispatch(reset('BatchDetailForm'));
      hideModal();
      dispatch(getBatchList({ financialYear }));
    } catch (err) {
      setConfirmLoading(false);
      errorNotification(err);
    }
  };

  return (
    <Structure>
      <>
        <Heading text={`Batch List (${rowCounts})`} />
        <Row gutter={[32, 8]}>
          <Col>
            <Select placeholder="Select year" onChange={value => setFinancialYear(value)} defaultValue={financialYear}>
              <Select.Option value="2019">19-20</Select.Option>
              <Select.Option value="2020">20-21</Select.Option>
              <Select.Option value="2021">21-22</Select.Option>
              <Select.Option value="2022">22-23</Select.Option>
              <Select.Option value="2023">23-24</Select.Option>
              <Select.Option value="2024">24-25</Select.Option>
              <Select.Option value="2025">25-26</Select.Option>
              <Select.Option value="2026">26-27</Select.Option>
              <Select.Option value="2027">27-28</Select.Option>
              <Select.Option value="2028">28-29</Select.Option>
              <Select.Option value="2029">29-30</Select.Option>
              <Select.Option value="2030">30-31</Select.Option>
            </Select>
          </Col>
        </Row>
        <Table
          columns={columns}
          bordered
          rowKey={record => record.id}
          pagination={{ defaultPageSize: 20, position: ['bottomLeft'] }}
          dataSource={batchList}
          size="small"
          scroll={{ x: 1050, y: window.innerHeight - 300 }}
          onChange={(_page, _filters, _sorter, { currentDataSource }) => {
            setRowCounts((currentDataSource || []).length);
          }}
        />
        <Modal
          open={modalType === 'FillBatchMaterialDetails'}
          onCancel={hideModal}
          confirmLoading={confirmLoading}
          title="Fill Batch Details."
          width={900}
          maskClosable={false}
          closable={false}
          cancelText="Back"
          okButtonProps={{ className: classes.button }}
          okText={
            <Popconfirm
              title="Sure want to submit form?"
              onConfirm={() => onBatchSubmit()}
              okText="Confirm"
              cancelText="Cancel"
            >
              Submit
            </Popconfirm>
          }
        >
          <Table
            columns={materialColumns}
            bordered
            rowKey={record => record.index}
            pagination={false}
            dataSource={Rows}
            size="small"
            scroll={{ x: 425 }}
          />
        </Modal>
        <Modal
          open={modalType === 'ViewBatchMaterialDetails'}
          onOk={hideModal}
          title="View Batch Details."
          width={800}
          maskClosable={false}
          closable={false}
          okText="Close"
          cancelButtonProps={{ className: classes.hide }}
          okButtonProps={{ className: classes.button }}
        >
          <Table
            columns={ViewbatchDetail}
            bordered
            rowKey={record => record.id}
            pagination={false}
            dataSource={batchDetail}
            size="small"
            scroll={{ x: 425 }}
          />
        </Modal>
        <Modal
          open={modalType === 'EditBatchDetail'}
          onCancel={hideModal}
          title="Edit Batch Details."
          width={800}
          maskClosable={false}
          closable={false}
          cancelText="Cancel"
          okText={
            <Popconfirm
              title="Sure want to submit form?"
              onConfirm={() => onBatchEdit()}
              okText="Confirm"
              cancelText="Cancel"
            >
              Submit
            </Popconfirm>
          }
          okButtonProps={{ className: classes.button }}
        >
          <Row>
            <Col xs={{ span: 24 }} lg={{ span: 10 }}>
              <Field label="Batch Series" allowClear required name="batchSeries" component={AntSelect}>
                <Select.Option key={null} value="" disabled>
                  Select series
                </Select.Option>
                {seriesList.map(series => (
                  <Select.Option key={series.series} value={series.series}>
                    {series.series}
                  </Select.Option>
                ))}
              </Field>
            </Col>
            <Col xs={{ span: 24 }} lg={{ span: 10, offset: 4 }}>
              <Field
                label="Batch Number"
                type="number"
                disabled
                allowClear
                name="batchNo"
                placeholder="Batch Number"
                component={AntInput}
              />
            </Col>
          </Row>
          <Row>
            <Col xs={{ span: 24 }} lg={{ span: 10 }}>
              <Field
                required
                allowClear
                label="Manufactured Date"
                name="mfgDate"
                placeholder="Manufactured Date"
                component={AntDatePicker}
                picker="month"
              />
            </Col>
            <Col xs={{ span: 24 }} lg={{ span: 10, offset: 4 }}>
              <Field
                required
                allowClear
                label="Valid For (Months)"
                name="validity"
                placeholder="Valid For (in months)"
                component={AntInput}
                type="number"
                min={1}
              />
            </Col>
          </Row>
        </Modal>
      </>
    </Structure>
  );
};

BatchList.propTypes = {
  classes: PropTypes.object.isRequired,
  change: PropTypes.func.isRequired,
};

const StylesBatchList = withStyles(styles)(BatchList);

const FormedBatchList = reduxForm({
  form: 'BatchForm',
  enableReinitialize: true,
})(StylesBatchList);

export default connect(
  ({ orderBatchMaterialsDetail }) => ({
    initialValues: { materials: orderBatchMaterialsDetail },
  }),
  {}
)(FormedBatchList);

export const materialInventoryList = (state = [], action) => {
  if (action.type === 'FETCH_MATERIAL_INVENTORY_LIST') {
    return action.payload;
  }
  if (action.type === 'CLEAR_MATERIAL_INVENTORY_LIST') {
    return [];
  }
  return state;
};

export const profitCenter = (
  state = {
    inventory: [],
    openingInventory: null,
    closingInventory: null,
  },
  action
) => {
  if (action.type === 'FETCH_INVENTORY_PROFIT_CENTER') {
    return action.payload;
  }
  if (action.type === 'CLEAR_INVENTORY_PROFIT_CENTER') {
    return {
      inventory: [],
      openingInventory: null,
      closingInventory: null,
    };
  }
  return state;
};

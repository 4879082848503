import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import withStyles from 'react-jss';
import { Table, Popconfirm, Tooltip, Modal, Select } from 'antd';
import { useSelector, useDispatch } from 'react-redux';
import { Link } from 'react-router-dom';
import { EditOutlined, DeleteOutlined } from '@ant-design/icons';
import { reduxForm, Field, getFormValues } from 'redux-form';
import Structure from '../Structure/Structure';
import styles from '../CommonStyles';
import { getRoleList, clearRoleList } from '../Actions';
import { errorNotification, successNotification } from '../Snackbar';
import { sorter, onFilter, filterDropdown, get } from '../utils';
import Api from '../Api';
import Heading from '../Components/Heading';
import GetField from '../Components/GetField';

const AntSelect = GetField(Select);

const RoleList = props => {
  const { classes } = props;
  const dispatch = useDispatch();

  const [roleDeleteModal, setRoleDeleteModal] = useState(false);
  const [roleListArray, setRoleListArray] = useState([]);
  const [deleteRoleId, setDeleteRoleId] = useState(null);

  const auth = useSelector(state => state.auth);
  const authpermissions = get(auth, ['permissions'], []);
  const roleList = useSelector(state => state.roleList);
  const [rowCounts, setRowCounts] = useState(0);

  const FormValues = useSelector(state => getFormValues('DeleteRole')(state));

  useEffect(() => {
    dispatch(getRoleList());
    return () => {
      dispatch(clearRoleList());
    };
  }, []);

  useEffect(() => {
    if (roleList) {
      setRowCounts(roleList.length);
    }
  }, [roleList]);

  const showDeleteModal = roleId => {
    setDeleteRoleId(roleId);
    setRoleListArray(roleList.filter(role => role.id !== roleId));
    setRoleDeleteModal(true);
  };

  const hideDeleteModal = () => {
    setDeleteRoleId(null);
    setRoleListArray([]);
    setRoleDeleteModal(false);
  };

  const onDelete = async () => {
    try {
      const { newRoleId } = FormValues;
      await Api.delete(`/roles/${deleteRoleId}`, { newRoleId });
      successNotification('Role deleted and replaced');
      hideDeleteModal();
      dispatch(getRoleList());
    } catch (err) {
      errorNotification(err);
    }
  };

  const columns = [
    {
      title: 'Role',
      dataIndex: 'role',
      sorter: sorter('role'),
      filterDropdown,
      onFilter: onFilter('role'),
      width: 200,
    },
    {
      title: 'Permissions',
      dataIndex: 'permissions',
      sorter: sorter('permissions'),
      filterDropdown,
      onFilter: onFilter('permissions'),
      render: permissions => (permissions.length ? permissions.join(', ') : 'No Permissions'),
    },
  ];

  const renderActionIcons = record => (
    <div>
      {authpermissions.includes('edit_role') ? (
        <>
          <Tooltip placement="bottom" title="Edit">
            <Link to={`/role/edit/${record.id}`}>
              <EditOutlined className={classes.tableIcon} />
            </Link>
          </Tooltip>
          &nbsp;&nbsp;&nbsp;&nbsp;
        </>
      ) : null}
      {authpermissions.includes('delete_role') ? (
        <>
          <Tooltip placement="bottom" title="Delete">
            <Popconfirm
              title="Are you sure you want to delete?"
              onConfirm={() => showDeleteModal(record.id)}
              okText="Yes"
              cancelText="No"
            >
              <DeleteOutlined className={classes.tableIcon} />
            </Popconfirm>
          </Tooltip>
        </>
      ) : null}
      <Modal
        onOk={onDelete}
        open={roleDeleteModal}
        onCancel={hideDeleteModal}
        centered
        maskClosable={false}
        title="Select alternate role"
      >
        <Field required name="newRoleId" component={AntSelect}>
          <Select.Option key={null} value="" disabled>
            Select alternate role
          </Select.Option>
          {roleListArray.map(role => (
            <Select.Option key={role.id} value={role.id}>
              {role.role}
            </Select.Option>
          ))}
        </Field>
      </Modal>
    </div>
  );

  if (authpermissions.includes('edit_role') || authpermissions.includes('delete_role')) {
    columns.push({
      title: 'Actions',
      width: 70,
      render: renderActionIcons,
    });
  }

  return (
    <Structure>
      <>
        <Heading text={`Role List (${rowCounts})`} />
        <Table
          columns={columns}
          bordered
          rowKey={record => record.id}
          pagination={{ defaultPageSize: 20, position: ['bottomLeft'] }}
          dataSource={roleList}
          size="small"
          scroll={{ x: 1050, y: window.innerHeight - 300 }}
          onChange={(_page, _filters, _sorter, { currentDataSource }) => {
            setRowCounts((currentDataSource || []).length);
          }}
        />
      </>
    </Structure>
  );
};

RoleList.propTypes = {
  classes: PropTypes.object.isRequired,
};

const StyledRoleList = withStyles(styles)(RoleList);

const FormedNewRole = reduxForm({
  form: 'DeleteRole',
})(StyledRoleList);

export default FormedNewRole;

export const saleList = (state = [], action) => {
  if (action.type === 'FETCH_SALE_LIST') {
    return action.payload;
  }
  if (action.type === 'CLEAR_SALE_LIST') {
    return [];
  }
  return state;
};

export const saleFilesList = (state = [], action) => {
  if (action.type === 'FETCH_SALE_FILES_LIST') {
    return action.payload;
  }
  if (action.type === 'CLEAR_SALE_FILES_LIST') {
    return [];
  }
  return state;
};

export const sale = (state = {}, action) => {
  if (action.type === 'FETCH_SALE_ORDER') {
    return action.payload;
  }
  if (action.type === 'CLEAR_SALE_ORDER') {
    return {};
  }
  return state;
};

export const orderSale = (state = {}, action) => {
  if (action.type === 'FETCH_ORDER_SALE') {
    return action.payload;
  }
  if (action.type === 'CLEAR_ORDER_SALE') {
    return {};
  }
  return state;
};

export const saleOrderReport = (state = [], action) => {
  if (action.type === 'FETCH_ORDER_SALE_REPORT') {
    return action.payload;
  }
  if (action.type === 'CLEAR_ORDER_SALE_REPORT') {
    return [];
  }
  return state;
};

export const saleOrderCustomerReport = (state = [], action) => {
  if (action.type === 'FETCH_ORDER_SALE_CUSTOMER_REPORT') {
    return action.payload;
  }
  if (action.type === 'CLEAR_ORDER_SALE_CUSTOMER_REPORT') {
    return [];
  }
  return state;
};

export const customerASPReport = (state = [], action) => {
  if (action.type === 'FETCH_ORDER_SALE_ASP_REPORT') {
    return action.payload;
  }
  if (action.type === 'CLEAR_ORDER_SALE_ASP_REPORT') {
    return [];
  }
  return state;
};

export const gstReversal = (state = [], action) => {
  if (action.type === 'FETCH_GST_REVERSAL_REPORT') {
    return action.payload;
  }
  if (action.type === 'CLEAR_GST_REVERSAL_REPORT') {
    return [];
  }
  return state;
};

/* eslint-disable no-nested-ternary */
import React, { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import withStyles from 'react-jss';
import PropTypes from 'prop-types';
import { Table, Popconfirm, Tooltip } from 'antd';
import { Link } from 'react-router-dom';
import { EditOutlined, DeleteOutlined, UndoOutlined } from '@ant-design/icons';
import Structure from '../Structure/Structure';
import { sorter, onFilter, filterDropdown, get } from '../utils';
import Api from '../Api';
import { getUserList, clearUserList } from '../Actions';
import { errorNotification, successNotification } from '../Snackbar';
import styles from '../CommonStyles';
import Heading from '../Components/Heading';

const UserList = props => {
  const { classes } = props;
  const dispatch = useDispatch();

  const auth = useSelector(state => state.auth);
  const permissions = get(auth, ['permissions'], []);
  const userList = useSelector(state => state.userList);

  const [rowCounts, setRowCounts] = useState(0);

  useEffect(() => {
    dispatch(getUserList());
    return () => {
      dispatch(clearUserList());
    };
  }, []);

  useEffect(() => {
    if (userList) {
      setRowCounts(userList.length);
    }
  }, [userList]);

  const onDelete = async id => {
    try {
      await Api.delete(`/users/${id}`);
      successNotification('User deleted');
      dispatch(getUserList());
    } catch (err) {
      errorNotification(err);
    }
  };

  const onUnDelete = async id => {
    try {
      await Api.put(`/users/restore/${id}`);
      successNotification('User recovered');
      dispatch(getUserList());
    } catch (err) {
      errorNotification(err);
    }
  };

  const columns = [
    {
      title: 'Name',
      dataIndex: 'name',
      key: 'name',
      sorter: sorter('name'),
      filterDropdown,
      onFilter: onFilter('name'),
    },
    {
      title: 'Email',
      dataIndex: 'email',
      key: 'email',
      sorter: sorter('email'),
      filterDropdown,
      onFilter: onFilter('email'),
    },
    {
      title: 'Phone #',
      dataIndex: 'phone',
      key: 'phone',
      sorter: sorter('phone'),
      filterDropdown,
      onFilter: onFilter('phone'),
    },
    {
      title: 'Role',
      dataIndex: 'role',
      key: 'role',
      sorter: sorter('role'),
      filterDropdown,
      onFilter: onFilter('role'),
    },
  ];

  const renderActionIcons = record =>
    record.isDeleted === false ? (
      <div>
        {permissions.includes('edit_user') ? (
          <>
            <Tooltip placement="bottom" title="Edit">
              <Link to={`/user/edit/${record.id}`}>
                <EditOutlined className={classes.tableIcon} />
              </Link>
            </Tooltip>
            &nbsp;&nbsp;&nbsp;&nbsp;
          </>
        ) : null}
        {permissions.includes('delete_user') ? (
          <>
            <Tooltip placement="bottom" title="Delete">
              <Popconfirm
                title="Are you sure you want to delete?"
                onConfirm={() => onDelete(record.id)}
                okText="Yes"
                cancelText="No"
              >
                <DeleteOutlined className={classes.tableIcon} />
              </Popconfirm>
            </Tooltip>
          </>
        ) : null}
      </div>
    ) : permissions.includes('recover_user') ? (
      <Tooltip placement="bottom" title="Recover">
        <Popconfirm title="Are you sure?" onConfirm={() => onUnDelete(record.id)} okText="Yes" cancelText="No">
          <UndoOutlined className={classes.tableIcon} />
        </Popconfirm>
      </Tooltip>
    ) : null;

  if (
    permissions.includes('edit_user') ||
    permissions.includes('delete_user') ||
    permissions.includes('recover_user')
  ) {
    columns.push({
      title: 'Actions',
      width: 70,
      render: renderActionIcons,
    });
  }

  return (
    <Structure>
      <>
        <Heading text={`User List (${rowCounts})`} />
        <Table
          columns={columns}
          bordered
          rowKey={record => record.id}
          pagination={{ defaultPageSize: 20, position: ['bottomLeft'] }}
          dataSource={userList}
          size="small"
          scroll={{ x: 1050, y: window.innerHeight - 300 }}
          onChange={(_page, _filters, _sorter, { currentDataSource }) => {
            setRowCounts((currentDataSource || []).length);
          }}
        />
      </>
    </Structure>
  );
};

UserList.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(UserList);

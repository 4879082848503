import Api from '../Api';

export const getFormulationList = () => async dispatch => {
  try {
    const { data } = await Api.get('/formulations');
    dispatch({
      type: 'FETCH_FORMULATION_LIST',
      payload: data,
    });
  } catch (err) {
    throw err;
  }
};

export const clearFormulationList = () => {
  return {
    type: 'CLEAR_FORMULATION_LIST',
  };
};

export const getFormulation = id => async dispatch => {
  try {
    const { data } = await Api.get(`/formulations/${id}`);
    dispatch({
      type: 'FETCH_FORMULATION',
      payload: data,
    });
  } catch (err) {
    throw err;
  }
};

export const clearFormulation = () => {
  return {
    type: 'CLEAR_FORMULATION',
  };
};

export const getFormulationIdList = params => async dispatch => {
  try {
    const { data } = await Api.get('/formulations/ids', params);
    dispatch({
      type: 'FETCH_FORMULATION_ID_LIST',
      payload: data,
    });
  } catch (err) {
    throw err;
  }
};

export const clearFormulationIdList = () => {
  return {
    type: 'CLEAR_FORMULATION_ID_LIST',
  };
};

export const getFormulationIdForImport = params => async dispatch => {
  try {
    const { data } = await Api.get('/formulations/ids/import', params);
    dispatch({
      type: 'FETCH_FORMULATION_IDS_FOR_IMPORT',
      payload: data,
    });
  } catch (err) {
    throw err;
  }
};

export const clearFormulationIdForImport = () => {
  return {
    type: 'CLEAR_FORMULATION_IDS_FOR_IMPORT',
  };
};

export const getFormulationView = id => async dispatch => {
  try {
    const { data } = await Api.get(`/formulations/view/${id}`);
    dispatch({
      type: 'FETCH_FORMULATION_VIEW',
      payload: data,
    });
  } catch (err) {
    throw err;
  }
};

export const clearFormulationView = () => {
  return {
    type: 'CLEAR_FORMULATION_VIEW',
  };
};

export const getFormulationCosting = id => async dispatch => {
  try {
    const { data } = await Api.get(`/formulations/costing/${id}`);
    dispatch({
      type: 'FETCH_FORMULATION_COSTING',
      payload: data,
    });
  } catch (err) {
    throw err;
  }
};

export const clearFormulationCosting = () => {
  return {
    type: 'CLEAR_FORMULATION_COSTING',
  };
};

export const getFormulationForCosting = id => async dispatch => {
  try {
    const { data } = await Api.get(`/formulations/costing/new/${id}`);
    dispatch({
      type: 'FETCH_FORMULATION_FOR_COSTING',
      payload: data,
    });
  } catch (err) {
    throw err;
  }
};

export const clearFormulationForCosting = () => {
  return {
    type: 'CLEAR_FORMULATION_FOR_COSTING',
  };
};

export const getGPPercentage = id => async dispatch => {
  try {
    const { data } = await Api.get(`/formulations/gpPercentage/${id}`);
    dispatch({
      type: 'FETCH_GP_PERCENTAGE',
      payload: data,
    });
  } catch (err) {
    throw err;
  }
};

export const clearGPPercentage = () => {
  return {
    type: 'CLEAR_GP_PERCENTAGE',
  };
};

export const getGPReport = () => async dispatch => {
  try {
    const { data } = await Api.get('/formulations/reports/gp');
    dispatch({
      type: 'FETCH_GP_REPORT',
      payload: data,
    });
  } catch (err) {
    throw err;
  }
};

export const clearGPReport = () => {
  return {
    type: 'CLEAR_GP_REPORT',
  };
};

export const getLastSellingGP = query => async dispatch => {
  try {
    const { data } = await Api.get('/formulations/reports/gp/lastselling', query);
    dispatch({
      type: 'FETCH_LAST_SELLING_GP',
      payload: data,
    });
  } catch (err) {
    throw err;
  }
};

export const clearLastSellingGP = () => {
  return {
    type: 'CLEAR_LAST_SELLING_GP',
  };
};

export const getFormulationFilesList = id => async dispatch => {
  try {
    const { data } = await Api.get(`/formulations/${id}/files`);
    dispatch({
      type: 'FETCH_FORMULATIONS_FILES_LIST',
      payload: data,
    });
  } catch (err) {
    throw err;
  }
};

export const clearFormulationFilesList = () => {
  return {
    type: 'CLEAR_FORMULATIONS_FILES_LIST',
  };
};

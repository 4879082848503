import React, { useEffect, useState, useRef, useCallback } from 'react';
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';
import withStyles from 'react-jss';
import { Table, Tooltip, Popconfirm, Modal, Descriptions, Row, Col, Select, Input, Button, Upload, Badge } from 'antd';
import {
  CopyOutlined,
  DeleteOutlined,
  EditOutlined,
  FileProtectOutlined,
  FileTextOutlined,
  RetweetOutlined,
  CloudDownloadOutlined,
  CloudUploadOutlined,
} from '@ant-design/icons';
import { Link, useHistory } from 'react-router-dom';
import startCase from 'lodash/startCase';
import { useReactToPrint } from 'react-to-print';
import { Field, getFormValues, reduxForm, reset } from 'redux-form';
import fileDownload from 'js-file-download';
import moment from 'moment';
import {
  getFormulationList,
  clearFormulationList,
  clearFormulationView,
  getFormulationView,
  getFormulationCosting,
  clearFormulationCosting,
  getFormulation,
  getFormulationFilesList,
  clearFormulationFilesList,
} from '../Actions';
import styles from '../CommonStyles';
import Structure from '../Structure/Structure';
import { successNotification, errorNotification, errorNotificationOnBlob, infoNotification } from '../Snackbar';
import Api from '../Api';
import Heading from '../Components/Heading';
import { sorter, onFilter, filterDropdown, get } from '../utils';
import GetField from '../Components/GetField';

const AntInput = GetField(Input);
const AntSelect = GetField(Select);

const FormulationList = props => {
  const { classes, change } = props;
  const dispatch = useDispatch();
  const history = useHistory();

  const auth = useSelector(state => state.auth);
  const permissions = get(auth, ['permissions'], []);
  const formulationList = useSelector(state => state.formulationList);
  const formulationFilesList = useSelector(state => state.formulationFilesList);
  const formulationView = useSelector(state => state.formulationView);
  const formulationCosting = useSelector(state => state.formulationCosting);
  const FormValues = useSelector(state => getFormValues('FormulationCostingForm')(state));

  const [formulationId, setFormulationId] = useState(null);
  const [viewModal, setViewModal] = useState(null);
  const [rowCounts, setRowCounts] = useState(0);

  const [uploading, setUploading] = useState(false);
  const [fileList, setFileList] = useState([]);

  const printRef = useRef();
  const handlePrint = useReactToPrint({
    content: () => printRef.current,
    pageStyle: '',
  });

  useEffect(() => {
    dispatch(getFormulationList());
    return () => {
      dispatch(clearFormulationFilesList());
      dispatch(clearFormulationList());
      dispatch(clearFormulationView());
      dispatch(clearFormulationCosting());
      dispatch(reset('FormulationCostingForm'));
    };
  }, []);

  useEffect(() => {
    if (formulationList) {
      setRowCounts(formulationList.length);
    }
  }, [formulationList]);

  useEffect(() => {
    if (viewModal === 'ViewFormulationModal') {
      dispatch(getFormulationView(formulationId));
    }
    if (viewModal === 'CostingModal') {
      dispatch(getFormulationCosting(formulationId));
    }

    if (formulationId && viewModal === 'ViewFiles') {
      dispatch(getFormulationFilesList(formulationId));
    }
  }, [viewModal, formulationId]);

  useEffect(() => {
    if (FormValues && FormValues.rateType === 'MaxPurchaseRate' && formulationCosting) {
      change('totalCost', get(formulationCosting, ['costingByMaxRate', 'totalCost'], null));
      change('theoriticalYield', get(formulationCosting, ['costingByMaxRate', 'theoriticalYield'], null));
    }
    if (FormValues && FormValues.rateType === 'LastPurchaseRate' && formulationCosting) {
      change('totalCost', get(formulationCosting, ['costingByLastRate', 'totalCost'], null));
      change('theoriticalYield', get(formulationCosting, ['costingByLastRate', 'theoriticalYield'], null));
    }
  }, [FormValues && FormValues.rateType, formulationCosting]);

  useEffect(() => {
    if (
      formulationCosting &&
      FormValues &&
      FormValues.rateType &&
      FormValues.totalCost &&
      FormValues.theoriticalYield &&
      FormValues.buffer
    ) {
      const costPlus5PercentValue = (
        (+FormValues.totalCost * +FormValues.buffer) / 100 +
        +FormValues.totalCost
      ).toFixed(2);
      change('costPlus5Percent', costPlus5PercentValue);

      const costPerUnitValue = costPlus5PercentValue / +FormValues.theoriticalYield;
      change('costPerUnit', costPerUnitValue);
    }
  }, [
    formulationCosting,
    FormValues && FormValues.rateType,
    FormValues && FormValues.totalCost,
    FormValues && FormValues.theoriticalYield,
    FormValues && FormValues.buffer,
  ]);

  useEffect(() => {
    if (
      formulationCosting &&
      FormValues &&
      FormValues.rateType &&
      FormValues.totalCost &&
      FormValues.theoriticalYield &&
      FormValues.buffer &&
      FormValues.costPlus5Percent &&
      FormValues.costPerUnit &&
      FormValues.sellingPrice
    ) {
      const gpValue = (+FormValues.sellingPrice - +FormValues.costPerUnit).toFixed(2);
      const gpPercentValue = ((gpValue / +FormValues.sellingPrice) * 100).toFixed(2);
      change('gp', gpValue);
      change('gpPercent', gpPercentValue);
    }
  }, [
    formulationCosting,
    FormValues && FormValues.rateType,
    FormValues && FormValues.totalCost,
    FormValues && FormValues.theoriticalYield,
    FormValues && FormValues.buffer,
    FormValues && FormValues.costPlus5Percent,
    FormValues && FormValues.costPerUnit,
    FormValues && FormValues.sellingPrice,
  ]);

  const copyFormulation = id => {
    dispatch(getFormulation(id));
    history.push('/formulations/new');
  };

  const showModal = (id, type) => {
    setFormulationId(id);
    setViewModal(type);
  };

  const hideModal = () => {
    setFormulationId(null);
    setViewModal(null);
    dispatch(clearFormulationFilesList());
    dispatch(clearFormulationView());
    dispatch(clearFormulationCosting());
    dispatch(reset('FormulationCostingForm'));
  };

  const onDelete = async id => {
    try {
      await Api.delete(`/formulations/${id}`);
      successNotification('Formulation deleted');
      dispatch(getFormulationList());
    } catch (err) {
      errorNotification(err);
    }
  };

  const updateStatus = async id => {
    try {
      await Api.put(`/formulations/${id}/status`);
      successNotification('Formulation status updated');
      dispatch(getFormulationList());
    } catch (err) {
      errorNotification(err);
    }
  };

  const onFileDelete = async id => {
    try {
      await Api.delete(`/formulations/${formulationId}/files/${id}`);
      successNotification('File delete');
      dispatch(getFormulationFilesList(formulationId));
    } catch (err) {
      errorNotification(err);
    }
  };

  const onUpload = async () => {
    try {
      setUploading(true);
      const formData = new FormData();
      fileList.forEach((file, index) => {
        formData.append(`files[${index}]`, file);
      });
      const { data } = await Api.post(`/formulations/${formulationId}/files`, formData, {
        headers: { 'content-type': 'multipart/form-data' },
      });
      infoNotification(data);
      hideModal();
      setFileList([]);
    } catch (err) {
      setUploading(false);
      errorNotification(err);
    }
  };

  const onRemove = file => {
    const index = fileList.indexOf(file);
    const newFileList = fileList.slice();
    newFileList.splice(index, 1);
    setFileList(newFileList);
  };

  const beforeUpload = (file, files) => {
    setFileList([...fileList, ...files]);
    return false;
  };

  const renderUploadColumn = record => (
    <Tooltip placement="bottom" title="Upload Files">
      <CloudUploadOutlined className={classes.tableIcon} onClick={() => showModal(record.id, 'UploadFiles')} />
    </Tooltip>
  );

  const renderFileViewColumn = record => {
    let color;
    if (record.files) {
      if (record.files > 0) {
        color = 'Green';
      } else {
        color = 'Red';
      }
    }
    return (
      <Tooltip placement="bottom" title="View Files">
        <CloudDownloadOutlined
          style={{ color }}
          className={classes.tableIcon}
          onClick={() => showModal(record.id, 'ViewFiles')}
        />
      </Tooltip>
    );
  };

  const tooltipShow = (text, record) => {
    let color = 'Black';
    if (record) {
      if (!record.isActive) {
        color = 'Red';
      }
    }

    return (
      <Tooltip placement="topLeft" title={text}>
        <span style={{ color }}>{text}</span>
      </Tooltip>
    );
  };

  const columns = [
    {
      title: 'Finished Good Name',
      dataIndex: 'name',
      sorter: sorter('name'),
      filterDropdown,
      onFilter: onFilter('name'),
      width: 250,
      fixed: 'left',
      render: (text, record) => tooltipShow(text, record),
    },
    {
      title: 'Consignor',
      dataIndex: 'consignor',
      sorter: sorter('consignor'),
      filterDropdown,
      onFilter: onFilter('consignor'),
      width: 120,
    },
    {
      title: 'Generic',
      dataIndex: 'generic',
      sorter: sorter('generic'),
      filterDropdown,
      onFilter: onFilter('generic'),
      width: 120,
    },
    {
      title: 'Category',
      dataIndex: 'category',
      sorter: sorter('category'),
      filterDropdown,
      onFilter: onFilter('category'),
      width: 120,
    },
    {
      title: 'Subcategory',
      dataIndex: 'subcategory',
      sorter: sorter('subcategory'),
      filterDropdown,
      onFilter: onFilter('subcategory'),
      width: 140,
    },
    {
      title: 'Dosage Form',
      dataIndex: 'dosageForm',
      sorter: sorter('dosageForm'),
      filterDropdown,
      onFilter: onFilter('dosageForm'),
      width: 110,
    },
    {
      title: 'Section',
      dataIndex: 'section',
      sorter: sorter('section'),
      filterDropdown,
      onFilter: onFilter('section'),
      width: 110,
    },
    {
      title: 'Unit',
      dataIndex: 'unit',
      sorter: sorter('unit'),
      filterDropdown,
      onFilter: onFilter('unit'),
      width: 80,
    },
    {
      title: 'Batch Size',
      dataIndex: 'batchSize',
      sorter: sorter('batchSize'),
      filterDropdown,
      onFilter: onFilter('batchSize'),
      width: 120,
      align: 'right',
    },
    {
      title: 'Average/Fill Weight or Fill Volume',
      dataIndex: 'packSize',
      sorter: sorter('packSize'),
      filterDropdown,
      onFilter: onFilter('packSize'),
      width: 120,
      align: 'right',
    },
    {
      title: 'Theorotical Yeild',
      dataIndex: 'theoroticalYeild',
      sorter: sorter('theoroticalYeild'),
      filterDropdown,
      onFilter: onFilter('theoroticalYeild'),
      width: 130,
      align: 'right',
    },
    {
      title: 'HSN Code',
      dataIndex: 'hsnCode',
      sorter: sorter('hsnCode'),
      filterDropdown,
      onFilter: onFilter('hsnCode'),
      width: 90,
    },
    {
      title: 'Time Motion Packing Details',
      dataIndex: 'tmCategory',
      sorter: sorter('tmCategory'),
      onFilter: onFilter('tmCategory'),
      filterDropdown,
      width: 100,
    },
    {
      title: 'Time Motion Study / Shift',
      dataIndex: 'timeMotion',
      sorter: sorter('timeMotion'),
      filterDropdown,
      onFilter: onFilter('timeMotion'),
      width: 100,
    },
    {
      title: 'Remarks',
      dataIndex: 'remarks',
      sorter: sorter('remarks'),
      filterDropdown,
      onFilter: onFilter('remarks'),
      width: 140,
    },
    {
      title: 'Status',
      dataIndex: 'isActive',
      sorter: sorter('isActive'),
      width: 140,
      render: (_text, record) => (record.isActive ? 'Active' : 'Not Active'),
    },
    {
      title: 'Done By',
      dataIndex: 'createdByUser',
      sorter: sorter('createdByUser'),
      filterDropdown,
      onFilter: onFilter('createdByUser'),
      width: 120,
    },
    {
      title: 'Upload Files',
      width: 70,
      render: renderUploadColumn,
    },
    {
      title: 'View Files',
      width: 70,
      render: renderFileViewColumn,
    },
  ];

  const renderEditIcons = record =>
    permissions.includes('edit_formulation') ? (
      <Tooltip placement="bottom" title="Edit">
        <Link to={`/formulations/edit/${record.id}`}>
          <EditOutlined className={classes.tableIcon} />
        </Link>
      </Tooltip>
    ) : null;

  const renderDeleteIcons = record =>
    permissions.includes('delete_formulation') ? (
      <Tooltip placement="bottom" title="Delete">
        <Popconfirm
          title="Are you sure you want to delete?"
          onConfirm={() => onDelete(record.id)}
          okText="Yes"
          cancelText="No"
        >
          <DeleteOutlined className={classes.tableIcon} />
        </Popconfirm>
      </Tooltip>
    ) : null;

  const renderUpdateStatusIcons = record =>
    permissions.includes('delete_formulation') ? (
      <Tooltip placement="bottom" title={`Update Active Status to ${!record.isActive ? 'Active' : 'Not Active'}`}>
        <Popconfirm title="Are you sure ?" onConfirm={() => updateStatus(record.id)} okText="Yes" cancelText="No">
          <RetweetOutlined className={classes.tableIcon} />
        </Popconfirm>
      </Tooltip>
    ) : null;

  if (permissions.includes('edit_formulation')) {
    columns.push({
      title: 'Edit',
      width: 60,
      render: renderEditIcons,
      fixed: 'right',
    });
  }

  if (permissions.includes('delete_formulation')) {
    columns.push({
      title: 'Delete',
      width: 70,
      render: renderDeleteIcons,
      fixed: 'right',
    });
  }

  if (permissions.includes('formulation_list')) {
    columns.push({
      title: 'Active Status',
      width: 70,
      render: renderUpdateStatusIcons,
      fixed: 'right',
    });
  }

  const renderCopyIcons = record =>
    permissions.includes('edit_formulation') ? (
      <Tooltip placement="bottom" title="Copy">
        <CopyOutlined className={classes.tableIcon} onClick={() => copyFormulation(record.id)} />
      </Tooltip>
    ) : null;

  if (permissions.includes('edit_formulation')) {
    columns.push({
      title: 'Copy',
      width: 60,
      render: renderCopyIcons,
      fixed: 'right',
    });
  }

  const renderViewColumn = record =>
    permissions.includes('view_formulation') ? (
      <>
        <Tooltip placement="bottom" title="View">
          <FileTextOutlined
            className={classes.tableIcon}
            onClick={() => showModal(record.id, 'ViewFormulationModal')}
          />
        </Tooltip>
      </>
    ) : null;

  if (permissions.includes('view_formulation')) {
    columns.push({
      title: 'View',
      width: 60,
      render: renderViewColumn,
      fixed: 'right',
    });
  }

  const renderCostingColumn = record =>
    permissions.includes('view_formulation') ? (
      <>
        <Tooltip placement="bottom" title="View Costing">
          <FileProtectOutlined className={classes.tableIcon} onClick={() => showModal(record.id, 'CostingModal')} />
        </Tooltip>
      </>
    ) : null;

  if (permissions.includes('view_formulation')) {
    columns.push({
      title: 'Costing',
      width: 70,
      render: renderCostingColumn,
      fixed: 'right',
    });
  }

  const materialTableColumn = [
    {
      title: 'Material',
      dataIndex: 'name',
    },
    {
      title: 'Type',
      dataIndex: 'type',
      render: type => startCase(type),
    },
    {
      title: 'Unit',
      dataIndex: 'unit',
    },
    {
      title: 'Quantity',
      dataIndex: 'quantity',
      align: 'right',
    },
  ];

  const exportFormulations = useCallback(async () => {
    try {
      const { data: file } = await Api.getFile(`/formulations/export`);

      fileDownload(file, `formulations.csv`);
    } catch (error) {
      errorNotificationOnBlob(error);
    }
  }, []);

  const renderFileTableNameColumn = record => (
    <a href={record.url} rel="noreferrer noopener" target="_blank">
      <Tooltip placement="bottom" title={`Click to open/download "${record.fileName}" file.`}>
        {record.fileName}
      </Tooltip>
    </a>
  );

  const renderFileTableActionColumn = record => (
    <Tooltip placement="bottom" title="Delete File">
      <Popconfirm
        title="Are you sure you want to delete?"
        onConfirm={() => onFileDelete(record.id)}
        okText="Yes"
        cancelText="No"
      >
        <DeleteOutlined className={classes.tableIcon} />
      </Popconfirm>
    </Tooltip>
  );

  const filesTableColumns = [
    {
      title: 'File',
      render: renderFileTableNameColumn,
    },
    {
      title: 'Delete',
      render: renderFileTableActionColumn,
      width: 70,
    },
  ];

  return (
    <Structure>
      <>
        <Heading text={`Formulation List (${rowCounts})`} />
        <Row gutter={[16, 8]}>
          <Col>
            <Button onClick={exportFormulations}>Export</Button>
          </Col>
          <Col>
            <Badge color="Red" text="In-Active" />
          </Col>
          <Col>
            <Badge color="Black" text="Active" />
          </Col>
        </Row>
        <br />
        <Table
          columns={columns}
          bordered
          rowKey={record => record.id}
          pagination={{ defaultPageSize: 20, position: ['bottomLeft'] }}
          dataSource={formulationList}
          size="small"
          scroll={{ x: 2200, y: window.innerHeight - 300 }}
          onChange={(_page, _filters, _sorter, { currentDataSource }) => {
            setRowCounts((currentDataSource || []).length);
          }}
        />
        <Modal
          open={viewModal === 'ViewFormulationModal'}
          onOk={hideModal}
          onCancel={handlePrint}
          maskClosable={false}
          closable={false}
          centered
          width="1000px"
          okText="Close"
          cancelText="Print"
          cancelButtonProps={{ className: classes.button }}
          okButtonProps={{ className: classes.button }}
        >
          <div style={{ padding: '10px' }} ref={printRef}>
            <Descriptions size="small" title="Formulation Details" bordered column={1}>
              <Descriptions.Item label="Finished Good Name">{get(formulationView, ['name'], '--')}</Descriptions.Item>
              <Descriptions.Item label="Consignor">{get(formulationView, ['consignor'], '--')}</Descriptions.Item>
              <Descriptions.Item label="Generic Name">{get(formulationView, ['generic'], '--')}</Descriptions.Item>
              <Descriptions.Item label="HSN Code">{get(formulationView, ['hsn'], '--')}</Descriptions.Item>
              <Descriptions.Item label="Category">{get(formulationView, ['category'], '--')}</Descriptions.Item>
              <Descriptions.Item label="Subcategory">{get(formulationView, ['subcategory'], '--')}</Descriptions.Item>
              <Descriptions.Item label="Dosage Form">{get(formulationView, ['dosageForm'], '--')}</Descriptions.Item>
              <Descriptions.Item label="Section">{get(formulationView, ['section'], '--')}</Descriptions.Item>
              <Descriptions.Item label="Batch Size">{get(formulationView, ['batchSize'], '--')}</Descriptions.Item>
              <Descriptions.Item label="Unit">{get(formulationView, ['unit'], '--')}</Descriptions.Item>
              <Descriptions.Item label="Average/Fill Weight or Fill Volume">
                {get(formulationView, ['packSize'], '--')} |{' '}
                {get(formulationView, ['unit'], '--') === 'Ltr'
                  ? `${Math.round(get(formulationView, ['packSize'], '--') * 1000)} ml`
                  : `${Math.round(get(formulationView, ['packSize'], '--') * 1000000)} mg`}
              </Descriptions.Item>
              <Descriptions.Item label="Theorotical Yeild">
                {get(formulationView, ['theoroticalYeild'], '--')}
              </Descriptions.Item>
              <Descriptions.Item label="Time Motion packing Details">
                {get(formulationView, ['tmCategory'], '--')}
              </Descriptions.Item>
              <Descriptions.Item label="Time Motion Study / Shift">
                {get(formulationView, ['timeMotion'], '--')}
              </Descriptions.Item>
              <Descriptions.Item label="Remarks">{get(formulationView, ['remarks'], '--')}</Descriptions.Item>
              <Descriptions.Item label="Remark">
                {get(formulationView, ['remark'], [])
                  ? get(formulationView, ['remark'], [])
                      .map(el => el.remark)
                      .join(', ')
                  : '-'}
              </Descriptions.Item>
              <Descriptions.Item label="Converion Charge">
                {get(formulationView, ['converionCharge'], '--')}
              </Descriptions.Item>
              <Descriptions.Item label="Packing Charge">
                {get(formulationView, ['packingCharge'], '--')}
              </Descriptions.Item>
              <Descriptions.Item label="Formulation View Timestamp">
                {moment().format('DD MMM YY hh:mm:ss A')}
              </Descriptions.Item>
            </Descriptions>
            <br />
            <Table
              columns={materialTableColumn}
              bordered
              rowKey={record => record.id}
              pagination={false}
              dataSource={get(formulationView, ['recipe'], [])}
              size="small"
              scroll={{ x: 425 }}
            />
            <br />
            <Table
              columns={[
                {
                  title: 'Unit',
                  dataIndex: 'unit',
                },
                {
                  title: 'Quantity',
                  dataIndex: 'quantity',
                },
              ]}
              bordered
              title={() => 'Raw Materials Weight Table'}
              rowKey={record => record.unit}
              pagination={false}
              dataSource={get(formulationView, ['weights'], [])}
              size="small"
              scroll={{ x: 425 }}
            />
          </div>
        </Modal>
        <Modal
          open={viewModal === 'CostingModal'}
          onOk={hideModal}
          maskClosable={false}
          closable={false}
          width="800px"
          okText="Close"
          cancelButtonProps={{ className: classes.hide }}
          okButtonProps={{ className: classes.button }}
        >
          <Row>
            <Col xs={{ span: 24 }} lg={{ span: 10 }}>
              <Field label="Rate type" allowClear required name="rateType" component={AntSelect}>
                <Select.Option key={null} value="" disabled>
                  Select rate type
                </Select.Option>
                <Select.Option key="MaxPurchaseRate" value="MaxPurchaseRate">
                  Max Purchase Rate
                </Select.Option>
                <Select.Option key="LastPurchaseRate" value="LastPurchaseRate">
                  Last Purchase Rate
                </Select.Option>
              </Field>
            </Col>
            <Col xs={{ span: 24 }} lg={{ span: 10, offset: 4 }}>
              <Field
                type="number"
                disabled
                allowClear
                name="totalCost"
                label="Total Cost"
                placeholder="Total Cost"
                component={AntInput}
              />
            </Col>
          </Row>
          <Row>
            <Col xs={{ span: 24 }} lg={{ span: 10 }}>
              <Field
                type="number"
                min={0}
                allowClear
                name="buffer"
                label="Buffer"
                placeholder="Buffer"
                component={AntInput}
              />
            </Col>
            <Col xs={{ span: 24 }} lg={{ span: 10, offset: 4 }}>
              <Field
                type="number"
                disabled
                allowClear
                name="costPlus5Percent"
                label="Total Cost + Buffer(%)"
                placeholder="Total Cost + Buffer(%)"
                component={AntInput}
              />
            </Col>
          </Row>
          <Row>
            <Col xs={{ span: 24 }} lg={{ span: 10 }}>
              <Field
                type="number"
                disabled
                allowClear
                name="theoriticalYield"
                label="Theoritical Yield"
                placeholder="Theoritical Yield"
                component={AntInput}
              />
            </Col>
            <Col xs={{ span: 24 }} lg={{ span: 10, offset: 4 }}>
              <Field
                type="number"
                disabled
                allowClear
                name="costPerUnit"
                label="Cost Per Unit"
                placeholder="Cost Per Unit"
                component={AntInput}
              />
            </Col>
          </Row>
          <Row>
            <Col xs={{ span: 24 }} lg={{ span: 10 }}>
              <Field
                type="number"
                allowClear
                name="sellingPrice"
                label="Selling Price"
                placeholder="Selling Price"
                component={AntInput}
              />
            </Col>
            <Col xs={{ span: 24 }} lg={{ span: 10, offset: 4 }}>
              <Field type="number" disabled allowClear name="gp" label="GP" placeholder="GP" component={AntInput} />
            </Col>
          </Row>
          <Row>
            <Col xs={{ span: 24 }} lg={{ span: 10 }}>
              <Field
                type="number"
                disabled
                allowClear
                name="gpPercent"
                label="GP %"
                placeholder="GP %"
                component={AntInput}
              />
            </Col>
          </Row>
        </Modal>
        <Modal
          onOk={hideModal}
          open={viewModal === 'UploadFiles'}
          centered
          maskClosable={false}
          title="Upload Files."
          width="500px"
          okText="Close"
          cancelButtonProps={{ className: classes.hide }}
          okButtonProps={{ className: classes.button }}
          closable={false}
        >
          <Upload multiple onRemove={onRemove} beforeUpload={beforeUpload} fileList={fileList}>
            <Button icon={<CloudUploadOutlined />}>Select Files</Button>
          </Upload>
          <Button
            type="primary"
            onClick={onUpload}
            disabled={fileList.length === 0}
            loading={uploading}
            style={{ marginTop: 16 }}
          >
            {uploading ? 'Uploading' : 'Upload'}
          </Button>
        </Modal>
        <Modal
          onOk={hideModal}
          open={viewModal === 'ViewFiles'}
          centered
          maskClosable={false}
          title="View Files."
          width="800px"
          okText="Close"
          cancelButtonProps={{ className: classes.hide }}
          closable={false}
          okButtonProps={{ className: classes.button }}
        >
          <Table
            columns={filesTableColumns}
            bordered
            rowKey={record => record.id}
            dataSource={formulationFilesList}
            size="small"
            pagination={{ position: ['bottomLeft'] }}
          />
        </Modal>
      </>
    </Structure>
  );
};

FormulationList.propTypes = {
  classes: PropTypes.object.isRequired,
  change: PropTypes.func.isRequired,
};

const StyledFormulationList = withStyles(styles)(FormulationList);

const FormedFormulationList = reduxForm({
  form: 'FormulationCostingForm',
  enableReinitialize: true,
})(StyledFormulationList);

export default FormedFormulationList;

import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Button, DatePicker, Row, Upload } from 'antd';
import { CloudUploadOutlined } from '@ant-design/icons';
import { useSelector } from 'react-redux';
import withStyles from 'react-jss';
import { Field, getFormValues, reduxForm } from 'redux-form';
import { useHistory } from 'react-router';
import Structure from '../Structure/Structure';
import Api from '../Api';
import { errorNotification, successNotification } from '../Snackbar';
import styles from '../CommonStyles';
import CenterGrid from '../Components/CenterGrid';
import GetField from '../Components/GetField';

const AntDatePicker = GetField(DatePicker);

const TransactionFileUpload = props => {
  const { classes } = props;

  const history = useHistory();

  const FormValues = useSelector(state => getFormValues('TransactionFileUpload')(state));

  const [fileList, setFileList] = useState([]);
  const [uploading, setUploading] = useState(false);

  const onUpload = async () => {
    try {
      setUploading(true);
      const formData = new FormData();
      Object.keys(FormValues).map(value => {
        formData.append(`${value}`, FormValues[value]);
      });
      fileList.forEach((file, index) => {
        formData.append(`files[${index}]`, file);
      });
      const { data } = await Api.post(`/financial-files`, formData, {
        headers: { 'content-type': 'multipart/form-data' },
      });
      successNotification(data);
      setUploading(false);
      setFileList([]);
      history.push('/transaction/filelist');
    } catch (err) {
      errorNotification(err);
    }
  };

  const onRemove = file => {
    const index = fileList.indexOf(file);
    const newFileList = fileList.slice();
    newFileList.splice(index, 1);
    setFileList(newFileList);
  };

  const beforeUpload = (file, files) => {
    setFileList([...fileList, ...files]);
    return false;
  };

  return (
    <Structure>
      <>
        <Row>
          <CenterGrid>
            <Field allowClear required name="fileDate" label="Date" placeholder="Date" component={AntDatePicker} />
          </CenterGrid>
        </Row>
        <Row>
          <CenterGrid>
            <Upload multiple onRemove={onRemove} beforeUpload={beforeUpload} fileList={fileList}>
              <Button icon={<CloudUploadOutlined />}>Select Files</Button>
            </Upload>
          </CenterGrid>
        </Row>
        <Row className={classes.buttonContainer}>
          <Button className={classes.button} loading={uploading} type="primary" onClick={onUpload}>
            Submit
          </Button>
        </Row>
      </>
    </Structure>
  );
};

TransactionFileUpload.propTypes = {
  classes: PropTypes.object.isRequired,
};

const StyledTransactionFileUpload = withStyles(styles)(TransactionFileUpload);

const FormedTransactionFileUpload = reduxForm({
  form: 'TransactionFileUpload',
})(StyledTransactionFileUpload);

export default FormedTransactionFileUpload;

export const employeeList = (state = [], action) => {
  if (action.type === 'FETCH_EMPLOYEE_LIST') {
    return action.payload;
  }
  if (action.type === 'CLEAR_EMPLOYEE_LIST') {
    return [];
  }
  return state;
};

export const employee = (state = {}, action) => {
  if (action.type === 'FETCH_EMPLOYEE') {
    return action.payload;
  }
  if (action.type === 'CLEAR_EMPLOYEE') {
    return {};
  }
  return state;
};

export const employeeFilesList = (state = [], action) => {
  if (action.type === 'FETCH_EMPLOYEE_FILES_LIST') {
    return action.payload;
  }
  if (action.type === 'CLEAR_EMPLOYEE_FILES_LIST') {
    return [];
  }
  return state;
};

export const employeeIdList = (state = [], action) => {
  if (action.type === 'FETCH_EMPLOYEE_ID_LIST') {
    return action.payload;
  }
  if (action.type === 'CLEAR_EMPLOYEE_ID_LIST') {
    return [];
  }
  return state;
};

export const employeeLedger = (state = {}, action) => {
  if (action.type === 'FETCH_EMPLOYEE_LEDGER') {
    return action.payload;
  }
  if (action.type === 'CLEAR_EMPLOYEE_LEDGER') {
    return {};
  }
  return state;
};

export const employeeAdvance = (state = {}, action) => {
  if (action.type === 'FETCH_EMPLOYEE_ADVANCE') {
    return action.payload;
  }
  if (action.type === 'CLEAR_EMPLOYEE_ADVANCE') {
    return {};
  }
  return state;
};

export const employeeSalaryRecord = (state = [], action) => {
  if (action.type === 'FETCH_EMPLOYEE_SALARY_RECORDS') {
    return action.payload;
  }
  if (action.type === 'CLEAR_EMPLOYEE_SALARY_RECORDS') {
    return [];
  }
  return state;
};

export const employeeMonthWiseSalaryRecord = (
  state = {
    salary: {},
    attendances: [],
  },
  action
) => {
  if (action.type === 'FETCH_EMPLOYEE_MONTH_WISE_SALARY_RECORDS') {
    return action.payload;
  }
  if (action.type === 'CLEAR_EMPLOYEE_MONTH_WISE_SALARY_RECORDS') {
    return {
      salary: {},
      attendances: [],
    };
  }
  return state;
};

import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import withStyles from 'react-jss';
import { Field, getFormValues, reduxForm } from 'redux-form';
import { useHistory } from 'react-router';
import { useDispatch, useSelector } from 'react-redux';
import { Button, Col, DatePicker, Input, Modal, Popconfirm, Row, Select } from 'antd';
import moment from 'moment';
import startCase from 'lodash/startCase';
import styles from '../CommonStyles';
import GetField from '../Components/GetField';
import { getCustomerIdList, clearCustomerIdList, getSupplierIdList, clearSupplierIdList } from '../Actions';
import { errorNotification, successNotification } from '../Snackbar';
import Api from '../Api';
import Structure from '../Structure/Structure';
import Heading from '../Components/Heading';
import LeftGrid from '../Components/LeftGrid';
import RightGrid from '../Components/RightGrid';
import { get } from '../utils';
import constants from '../constants';

const AntInput = GetField(Input);
const AntSelect = GetField(Select);
const AntDatePicker = GetField(DatePicker);

const NewCreditDebitNote = props => {
  const { handleSubmit, classes, change } = props;
  const history = useHistory();
  const dispatch = useDispatch();

  const supplierIdList = useSelector(state => state.supplierIdList);
  const customerIdList = useSelector(state => state.customerIdList);

  const [formValueModal, setFormValuesModal] = useState(false);
  const [confirmLoading, setConfirmLoading] = useState(false);
  const [preview, setPreview] = useState({});
  const FormValues = useSelector(state => getFormValues('NewCreditDebitNote')(state));

  useEffect(() => {
    dispatch(getCustomerIdList({ status: 'Active' }));
    dispatch(getSupplierIdList({ status: 'Active' }));

    return () => {
      dispatch(clearCustomerIdList());
      dispatch(clearSupplierIdList());
    };
  }, []);

  useEffect(() => {
    if (FormValues && FormValues.customerId) {
      change('supplierId', null);
    }
  }, [FormValues && FormValues.customerId]);

  useEffect(() => {
    if (FormValues && FormValues.supplierId) {
      change('customerId', null);
    }
  }, [FormValues && FormValues.supplierId]);

  const showFormValuesModal = () => {
    const voucherDate = get(FormValues, ['voucherDate']);
    const type = get(FormValues, ['type']);

    const _preview = {
      type: type ? startCase(type) : '--',
      customer: '--',
      supplier: '--',
      consignor: get(FormValues, ['consignor'], '--'),
      voucherNo: get(FormValues, ['voucherNo'], '--'),
      voucherDate: voucherDate ? moment(voucherDate).format('DD MMM YYYY') : '--',
      amount: get(FormValues, ['amount'], '--'),
      remarks: get(FormValues, ['remarks'], '--'),
    };

    const customerId = get(FormValues, ['customerId'], null);
    if (customerId) {
      const customerObj = customerIdList.find(customer => customer.id === +customerId);
      if (customerObj) {
        _preview.customer = customerObj.name;
      }
    }

    const supplierId = get(FormValues, ['supplierId'], null);
    if (supplierId) {
      const supplierObj = supplierIdList.find(supplier => supplier.id === +supplierId);
      if (supplierObj) {
        _preview.supplier = supplierObj.name;
      }
    }

    setPreview(_preview);
    setFormValuesModal(true);
  };

  const hideFormValuesModal = () => {
    setFormValuesModal(false);
    setPreview({});
  };

  const onSubmit = async () => {
    try {
      setConfirmLoading(true);
      await Api.post('/cdnotes', FormValues);
      successNotification('Credit/Debit Note added');
      setConfirmLoading(false);
      hideFormValuesModal();
      history.push('/creditdebitnote/list');
    } catch (err) {
      setConfirmLoading(false);
      errorNotification(err);
    }
  };

  return (
    <Structure>
      <>
        <Heading text="New Credit/Debit Note" />
        <form onSubmit={handleSubmit(onSubmit)}>
          <Row>
            <LeftGrid>
              <Field label="Type" allowClear required name="type" component={AntSelect}>
                <Select.Option key={null} value="" disabled>
                  Select type
                </Select.Option>
                <Select.Option key="credit_note" value="credit_note">
                  Credit Note
                </Select.Option>
                <Select.Option key="debit_note" value="debit_note">
                  Debit Note
                </Select.Option>
              </Field>
            </LeftGrid>
            <RightGrid>
              <Field label="Customer" allowClear required name="customerId" component={AntSelect}>
                <Select.Option key={null} value="" disabled>
                  Select customer
                </Select.Option>
                {customerIdList.map(customer => (
                  <Select.Option key={customer.id} value={customer.id}>
                    {customer.name}
                  </Select.Option>
                ))}
              </Field>
            </RightGrid>
          </Row>
          <Row>
            <LeftGrid>
              <Field label="Supplier" allowClear required name="supplierId" component={AntSelect}>
                <Select.Option key={null} value="" disabled>
                  Select supplier
                </Select.Option>
                {supplierIdList.map(supplier => (
                  <Select.Option key={supplier.id} value={supplier.id}>
                    {supplier.name}
                  </Select.Option>
                ))}
              </Field>
            </LeftGrid>
            <RightGrid>
              <Field label="Consignor" allowClear required name="consignor" component={AntSelect}>
                <Select.Option key={null} value="" disabled>
                  Select consignor
                </Select.Option>
                {Object.values(constants.consignors.list).map(consignor => (
                  <Select.Option key={consignor} value={consignor}>
                    {consignor}
                  </Select.Option>
                ))}
              </Field>
            </RightGrid>
          </Row>
          <Row>
            <LeftGrid>
              <Field
                label="Voucher Number"
                allowClear
                required
                name="voucherNo"
                placeholder="Voucher Number"
                component={AntInput}
              />
            </LeftGrid>
            <RightGrid>
              <Field
                allowClear
                required
                label="Voucher Date"
                name="voucherDate"
                placeholder="Voucher Date"
                component={AntDatePicker}
              />
            </RightGrid>
          </Row>
          <Row>
            <LeftGrid>
              <Field
                label="Amount"
                allowClear
                type="number"
                min={0}
                step="any"
                name="amount"
                placeholder="Amount"
                component={AntInput}
              />
            </LeftGrid>
            <RightGrid>
              <Field label="Remarks" allowClear name="remarks" placeholder="Remarks" component={AntInput} />
            </RightGrid>
          </Row>
          <Row className={classes.buttonContainer}>
            <Button className={classes.button} type="primary" onClick={showFormValuesModal}>
              Preview
            </Button>
          </Row>
        </form>
        <Modal
          onCancel={hideFormValuesModal}
          open={formValueModal}
          confirmLoading={confirmLoading}
          centered
          width="800px"
          maskClosable={false}
          closable={false}
          cancelText="Back"
          okButtonProps={{ className: classes.button }}
          okText={
            <Popconfirm
              title="Sure want to submit form?"
              onConfirm={() => onSubmit()}
              okText="Confirm"
              cancelText="Cancel"
            >
              Submit
            </Popconfirm>
          }
        >
          <Row>
            <Col xs={24} lg={12}>
              <p>
                Type:
                <span className={classes.detailColor}>{preview.type}</span>
              </p>
            </Col>
            <Col xs={24} lg={12}>
              <p>
                Customer:
                <span className={classes.detailColor}>{preview.customer}</span>
              </p>
            </Col>
          </Row>
          <Row>
            <Col xs={24} lg={12}>
              <p>
                Supplier:
                <span className={classes.detailColor}>{preview.supplier}</span>
              </p>
            </Col>
            <Col xs={24} lg={12}>
              <p>
                Consignor:
                <span className={classes.detailColor}>{preview.consignor}</span>
              </p>
            </Col>
          </Row>
          <Row>
            <Col xs={24} lg={12}>
              <p>
                Voucher Number:
                <span className={classes.detailColor}>{preview.voucherNo}</span>
              </p>
            </Col>
            <Col xs={24} lg={12}>
              <p>
                Voucher Date:
                <span className={classes.detailColor}>{preview.voucherDate}</span>
              </p>
            </Col>
          </Row>
          <Row>
            <Col xs={24} lg={12}>
              <p>
                Amount:
                <span className={classes.detailColor}>{preview.amount}</span>
              </p>
            </Col>
            <Col xs={24} lg={12}>
              <p>
                Remarks:
                <span className={classes.detailColor}>{preview.remarks}</span>
              </p>
            </Col>
          </Row>
        </Modal>
      </>
    </Structure>
  );
};

NewCreditDebitNote.propTypes = {
  handleSubmit: PropTypes.func.isRequired,
  classes: PropTypes.object.isRequired,
  change: PropTypes.func.isRequired,
};

const StyledNewCreditDebitNote = withStyles(styles)(NewCreditDebitNote);

const FormedNewCreditDebitNote = reduxForm({
  form: 'NewCreditDebitNote',
})(StyledNewCreditDebitNote);

export default FormedNewCreditDebitNote;

import api from '../Api';

export const login =
  (body = {}) =>
  async dispatch => {
    if (
      (window.location.pathname.startsWith('/customer/details/') ||
        window.location.pathname.startsWith('/supplier/details/')) &&
      !localStorage.getItem('token')
    ) {
      return;
    }
    try {
      const { data } = await api.post('/auth/login', body);

      localStorage.setItem('token', data.token);

      dispatch({
        type: 'FETCH_TOKEN',
        payload: {
          type: true,
          user: data.user,
          permissions: data.permissions,
        },
      });
    } catch (err) {
      throw err;
    }
  };

export const logout = () => {
  localStorage.clear('token');
  return {
    type: 'REMOVE_TOKEN',
  };
};

import React, { useEffect, Fragment } from 'react';
import { DatePicker, Row, Table } from 'antd';
import { useDispatch, useSelector } from 'react-redux';
import { Field, getFormValues, reduxForm } from 'redux-form';
import moment from 'moment';
import GetField from '../Components/GetField';
import { onFilter, sorter, filterDropdown } from '../utils';
import Structure from '../Structure/Structure';
import Heading from '../Components/Heading';
import { getOrdersMIS, clearOrdersMIS } from '../Actions';

const AntDatePicker = GetField(DatePicker);

const OrderMIS = () => {
  const dispatch = useDispatch();

  const ordersMIS = useSelector(state => state.ordersMIS.data);
  const weeks = useSelector(state => state.ordersMIS.weeks);

  const FormValues = useSelector(state => getFormValues('OrderMIS')(state));

  useEffect(() => {
    return () => {
      dispatch(clearOrdersMIS());
    };
  }, []);

  useEffect(() => {
    if (FormValues && FormValues.date) {
      dispatch(
        getOrdersMIS({
          date: moment(FormValues.date).startOf('month').format('YYYY-MM-DD'),
        })
      );
    }
  }, [FormValues && FormValues.date]);

  const _columns = [
    {
      title: 'Name',
      dataIndex: 'name',
      sorter: sorter('name'),
      onFilter: onFilter('name'),
      filterDropdown,
      fixed: 'left',
      width: '10%',
    },
    {
      title: `Week ${weeks[0].week || ''} (${weeks[0].start || ''} - ${weeks[0].end || ''})`,
      dataIndex: 'week1',
      children: [
        { title: 'Total', dataIndex: 'total', render: (_text, record) => record.week1.total },
        { title: 'Delayed', dataIndex: 'delayed', render: (_text, record) => record.week1.delayed },
      ],
    },
    {
      title: `Week ${weeks[1].week || ''} (${weeks[1].start || ''} - ${weeks[1].end || ''})`,
      dataIndex: 'week2',
      children: [
        { title: 'Total', dataIndex: 'total', render: (_text, record) => record.week2.total },
        { title: 'Delayed', dataIndex: 'delayed', render: (_text, record) => record.week2.delayed },
      ],
    },
    {
      title: `Week ${weeks[2].week || ''} (${weeks[2].start || ''} - ${weeks[2].end || ''})`,
      dataIndex: 'week3',
      children: [
        { title: 'Total', dataIndex: 'total', render: (_text, record) => record.week3.total },
        { title: 'Delayed', dataIndex: 'delayed', render: (_text, record) => record.week3.delayed },
      ],
    },
    {
      title: `Week ${weeks[3].week || ''} (${weeks[3].start || ''} - ${weeks[3].end || ''})`,
      dataIndex: 'week4',
      children: [
        { title: 'Total', dataIndex: 'total', render: (_text, record) => record.week4.total },
        { title: 'Delayed', dataIndex: 'delayed', render: (_text, record) => record.week4.delayed },
      ],
    },
    {
      title: `Week ${weeks[4].week || ''} (${weeks[4].start || ''} - ${weeks[4].end || ''})`,
      dataIndex: 'week5',
      children: [
        { title: 'Total', dataIndex: 'total', render: (_text, record) => record.week5.total },
        { title: 'Delayed', dataIndex: 'delayed', render: (_text, record) => record.week5.delayed },
      ],
    },
  ];

  return (
    <Structure>
      <>
        <Heading text="Order MIS" />
        <Row>
          <Field allowClear required name="date" placeholder="Month" picker="month" component={AntDatePicker} />
        </Row>
        <Table
          columns={_columns}
          bordered
          rowKey={() => 'key'}
          dataSource={ordersMIS}
          size="small"
          pagination={false}
          scroll={{ x: 1024, y: window.innerHeight - 300 }}
        />
      </>
    </Structure>
  );
};

const FormedOrderMIS = reduxForm({
  form: 'OrderMIS',
  enableReinitialize: true,
})(OrderMIS);

export default FormedOrderMIS;

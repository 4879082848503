import React, { useEffect, useState, useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Button, Col, DatePicker, Row, Table } from 'antd';
import fileDownload from 'js-file-download';
import { getGSTReversalReport, clearGSTReversalReport } from '../Actions';
import Structure from '../Structure/Structure';
import Heading from '../Components/Heading';
import { errorNotificationOnBlob, infoNotification, successNotification } from '../Snackbar';
import Api from '../Api';

const GSTReversal = () => {
  const dispatch = useDispatch();

  const [from, setFrom] = useState(null);
  const [to, setTo] = useState(null);

  const gstReversal = useSelector(state => state.gstReversal);

  useEffect(() => {
    return () => {
      dispatch(clearGSTReversalReport());
    };
  }, []);

  useEffect(() => {
    if (from && to) {
      dispatch(getGSTReversalReport({ from, to }));
    }
  }, [from, to]);

  const onFileDownload = useCallback(async () => {
    try {
      infoNotification('Downloading...');
      const { data } = await Api.getFile('/order-sales/jhol/report/details', { from, to });
      fileDownload(data, `gst-reversal${new Date().toISOString()}.xlsx`);
      successNotification('File downloaded');
    } catch (err) {
      errorNotificationOnBlob(err);
    }
  }, [from, to]);

  const columns = [
    {
      title: 'GST',
      dataIndex: 'gst',
    },
    {
      title: 'Total',
      dataIndex: 'total',
    },
  ];

  return (
    <Structure>
      <>
        <Heading text="GST Reversal" />
        <Row gutter={[16, 8]}>
          <Col>
            <DatePicker placeholder="From" onChange={value => setFrom(value ? value.toISOString() : null)} />
          </Col>
          <Col>
            <DatePicker placeholder="To" onChange={value => setTo(value ? value.toISOString() : null)} />
          </Col>
          <Col>
            <Button type="default" onClick={onFileDownload}>
              Export Detailed Report
            </Button>
          </Col>
        </Row>
        <Table
          columns={columns}
          bordered
          rowKey={record => record.gst}
          pagination={false}
          dataSource={gstReversal}
          size="small"
          scroll={{ x: 1280, y: 600 }}
        />
      </>
    </Structure>
  );
};

export default GSTReversal;

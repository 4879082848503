import React, { useEffect, useRef, useState } from 'react';
import PropTypes from 'prop-types';
import { Col, DatePicker, Form, Row, Table, Descriptions, Button, Calendar } from 'antd';
import moment from 'moment';
import { useDispatch, useSelector } from 'react-redux';
import { useReactToPrint } from 'react-to-print';
import { FilePdfOutlined } from '@ant-design/icons';
import { clearMonthWiseSalaryRecords, getMonthWiseSalaryRecords } from '../Actions';
import { dailyWorkingMinutes } from '../constants';

const MonthWiseDetail = props => {
  const { employeeId } = props;
  const dispatch = useDispatch();

  const salaryRecord = useSelector(state => state.employeeMonthWiseSalaryRecord);

  const [Month, SetMonth] = useState(moment().subtract(1, 'month').startOf('month'));

  const printRef = useRef();
  const handlePrint = useReactToPrint({
    content: () => printRef.current,
    pageStyle: `
    @page {
      size: 210mm 297mm;
      margin: 5mm;
    }

    @media all {
      .pagebreak {
        margin-top: 1rem;
        display: block;
        page-break-before: auto;
    }

    @media print {
      .pagebreak {
        margin-top: 1rem;
        display: block;
        page-break-before: auto;
      }
    }
  `,
  });

  useEffect(() => {
    if (employeeId && Month) {
      dispatch(
        getMonthWiseSalaryRecords({
          id: employeeId,
          month: Month.toISOString(),
        })
      );
    }
    return () => {
      dispatch(clearMonthWiseSalaryRecords());
    };
  }, [employeeId, Month]);

  const columns = [
    {
      title: 'In',
      dataIndex: 'in',
      render: date => (date ? moment(date).format('DD/MM/YY hh:mm:ss A') : null),
    },
    {
      title: 'Out',
      dataIndex: 'out',
      render: date => (date ? moment(date).format('DD/MM/YY hh:mm:ss A') : null),
    },
  ];

  const dateCellRender = value => {
    console.log(moment(value).format('DD/MM/YYYY'));

    const flag = (salaryRecord.attendances || []).find(el => {
      console.log(el);
      return moment(value).format('DD/MM/YYYY') === moment(el.in).format('DD/MM/YYYY');
    });

    // eslint-disable-next-line no-nested-ternary
    return flag ? 'Present' : moment(value).day() === 0 ? '' : <span style={{ color: 'red' }}>Absent</span>;
  };

  return (
    <>
      <Row>
        <Col>
          <Form.Item>
            <DatePicker onChange={SetMonth} picker="month" placeholder="Month" value={Month} />
          </Form.Item>
        </Col>
      </Row>

      <div ref={printRef}>
        <Descriptions column={2} size="small" bordered>
          <Descriptions.Item label="Name">{salaryRecord?.salary?.name}</Descriptions.Item>
          <Descriptions.Item label="Month">
            {salaryRecord?.salary?.month ? moment(salaryRecord?.salary?.month).format('MMM YYYY') : null}
          </Descriptions.Item>
          <Descriptions.Item label="Working (in minute)">{salaryRecord?.salary?.actualWorkingMinute}</Descriptions.Item>
          <Descriptions.Item label="Working (in INR)">
            {Number(
              Number(salaryRecord?.salary?.salaryPerMinute) * Number(salaryRecord?.salary?.actualWorkingMinute)
            ).toFixed(2)}
          </Descriptions.Item>
          <Descriptions.Item label="Overtime Week Days (in minute)">
            {salaryRecord?.salary?.overtimeMinuteNormal}
          </Descriptions.Item>
          <Descriptions.Item label="Overtime Week Days (in INR)">
            {Number(
              Number(salaryRecord?.salary?.salaryPerMinute) * Number(salaryRecord?.salary?.overtimeMinuteNormal)
            ).toFixed(2)}
          </Descriptions.Item>
          <Descriptions.Item label="Overtime Sunday (in minute)">
            {salaryRecord?.salary?.overtimeMinuteSunday}
          </Descriptions.Item>
          <Descriptions.Item label="Overtime Sunday (in INR)">
            {Number(
              Number(salaryRecord?.salary?.salaryPerMinute) * Number(salaryRecord?.salary?.overtimeMinuteSunday)
            ).toFixed(2)}
          </Descriptions.Item>
          <Descriptions.Item label="Overtime Holiday (in minute)">
            {salaryRecord?.salary?.overtimeMinuteHoliday}
          </Descriptions.Item>
          <Descriptions.Item label="Overtime Holiday (in INR)">
            {Number(
              Number(salaryRecord?.salary?.salaryPerMinute) * Number(salaryRecord?.salary?.overtimeMinuteHoliday)
            ).toFixed(2)}
          </Descriptions.Item>
          <Descriptions.Item label="Bonus/Late (in minute)">{salaryRecord?.salary?.lateMinute}</Descriptions.Item>
          <Descriptions.Item label="Bonus/Late (in INR)">
            {Number(Number(salaryRecord?.salary?.salaryPerMinute) * Number(salaryRecord?.salary?.lateMinute)).toFixed(
              2
            )}
          </Descriptions.Item>
          <Descriptions.Item label="Paid Sundays (in days)">
            {Number(salaryRecord?.salary?.sundaysToBePaid).toFixed(0)}
          </Descriptions.Item>
          <Descriptions.Item label="Paid Sundays (in INR)">
            {Number(
              Number(salaryRecord?.salary?.salaryPerMinute) *
                Number(salaryRecord?.salary?.sundaysToBePaid) *
                Number(dailyWorkingMinutes)
            ).toFixed(2)}
          </Descriptions.Item>
          <Descriptions.Item label="Paid Holidays (in days)">
            {Number(salaryRecord?.salary?.paidHolidays).toFixed(0)}
          </Descriptions.Item>
          <Descriptions.Item label="Paid Holidays (in INR)">
            {Number(
              Number(salaryRecord?.salary?.salaryPerMinute) *
                Number(salaryRecord?.salary?.paidHolidays) *
                Number(dailyWorkingMinutes)
            ).toFixed(2)}
          </Descriptions.Item>
          <Descriptions.Item label="Extra Day Paid (in days)">
            {Number(salaryRecord?.salary?.extraDayPaid).toFixed(0)}
          </Descriptions.Item>
          <Descriptions.Item label="Extra Day Paid (in INR)">
            {Number(
              Number(salaryRecord?.salary?.salaryPerMinute) *
                Number(salaryRecord?.salary?.extraDayPaid) *
                Number(dailyWorkingMinutes)
            ).toFixed(2)}
          </Descriptions.Item>
          <Descriptions.Item label="Holidays Worked (in days)">
            {Number(salaryRecord?.salary?.holidaysWorked).toFixed(0)}
          </Descriptions.Item>
          <Descriptions.Item label="Sundays worked (in days)">
            {Number(salaryRecord?.salary?.sundaysWorked).toFixed(0)}
          </Descriptions.Item>
          <Descriptions.Item label="Present (in days)">
            {Number(salaryRecord?.salary?.totalPresent).toFixed(0)}
          </Descriptions.Item>
          <Descriptions.Item label="Absent (in days)">
            {Number(salaryRecord?.salary?.totalAbsent).toFixed(0)}
          </Descriptions.Item>
          <Descriptions.Item label="Unapproved Leaves">{salaryRecord?.salary?.unapprovedLeaves}</Descriptions.Item>
          <Descriptions.Item label="Salary (in INR)">{salaryRecord?.salary?.salary}</Descriptions.Item>
          <Descriptions.Item label="Salary / Day (in INR)">
            {Number(Number(salaryRecord?.salary?.salaryPerMinute) * Number(dailyWorkingMinutes)).toFixed(2)}
          </Descriptions.Item>
          <Descriptions.Item label="Salary To Be Paid (in INR)">
            {salaryRecord?.salary?.salaryToBeGiven}
          </Descriptions.Item>
        </Descriptions>
        <br />
        <Table
          columns={columns}
          bordered
          rowKey={record => record.id}
          pagination={false}
          dataSource={salaryRecord.attendances || []}
          size="small"
          title={() => 'Attendance Details'}
        />
      </div>
      <br />
      <Button icon={<FilePdfOutlined />} onClick={handlePrint}>
        Print Details
      </Button>
      <br />
      <Calendar value={Month} dateCellRender={dateCellRender} />
    </>
  );
};

MonthWiseDetail.propTypes = {
  employeeId: PropTypes.number,
};

export default MonthWiseDetail;

export const formulationList = (state = [], action) => {
  if (action.type === 'FETCH_FORMULATION_LIST') {
    return action.payload;
  }
  if (action.type === 'CLEAR_FORMULATION_LIST') {
    return [];
  }
  return state;
};

export const formulation = (state = {}, action) => {
  if (action.type === 'FETCH_FORMULATION') {
    return action.payload;
  }
  if (action.type === 'CLEAR_FORMULATION') {
    return {};
  }
  return state;
};

export const formulationIdList = (state = [], action) => {
  if (action.type === 'FETCH_FORMULATION_ID_LIST') {
    return action.payload;
  }
  if (action.type === 'CLEAR_FORMULATION_ID_LIST') {
    return [];
  }
  return state;
};

export const formulationView = (state = {}, action) => {
  if (action.type === 'FETCH_FORMULATION_VIEW') {
    return action.payload;
  }
  if (action.type === 'CLEAR_FORMULATION_VIEW') {
    return {};
  }
  return state;
};

export const formulationCosting = (state = {}, action) => {
  if (action.type === 'FETCH_FORMULATION_COSTING') {
    return action.payload;
  }
  if (action.type === 'CLEAR_FORMULATION_COSTING') {
    return {};
  }
  return state;
};

export const formulationForCosting = (state = {}, action) => {
  if (action.type === 'FETCH_FORMULATION_FOR_COSTING') {
    return action.payload;
  }
  if (action.type === 'CLEAR_FORMULATION_FOR_COSTING') {
    return {};
  }
  return state;
};

export const gpPercentage = (
  state = {
    lastRate: 0,
    maxRate: 0,
    theoriticalYield: 0,
    sales: [],
    orders: [],
  },
  action
) => {
  if (action.type === 'FETCH_GP_PERCENTAGE') {
    return action.payload;
  }
  if (action.type === 'CLEAR_GP_PERCENTAGE') {
    return {
      lastRate: 0,
      maxRate: 0,
      theoriticalYield: 0,
      sales: [],
      orders: [],
    };
  }
  return state;
};

export const gpReport = (state = [], action) => {
  if (action.type === 'FETCH_GP_REPORT') {
    return action.payload;
  }
  if (action.type === 'CLEAR_GP_REPORT') {
    return [];
  }
  return state;
};

export const lastSellingGP = (state = [], action) => {
  if (action.type === 'FETCH_LAST_SELLING_GP') {
    return action.payload;
  }
  if (action.type === 'CLEAR_LAST_SELLING_GP') {
    return [];
  }
  return state;
};

export const formulationIdsForImport = (state = [], action) => {
  if (action.type === 'FETCH_FORMULATION_IDS_FOR_IMPORT') {
    return action.payload;
  }
  if (action.type === 'CLEAR_FORMULATION_IDS_FOR_IMPORT') {
    return [];
  }
  return state;
};

export const formulationFilesList = (state = [], action) => {
  if (action.type === 'FETCH_FORMULATIONS_FILES_LIST') {
    return action.payload;
  }
  if (action.type === 'CLEAR_FORMULATIONS_FILES_LIST') {
    return [];
  }
  return state;
};

export const unitList = (state = [], action) => {
  if (action.type === 'FETCH_UNIT_LIST') {
    return action.payload;
  }
  if (action.type === 'CLEAR_UNIT_LIST') {
    return [];
  }
  return state;
};

export const unit = (state = {}, action) => {
  if (action.type === 'FETCH_UNIT') {
    return action.payload;
  }
  if (action.type === 'CLEAR_UNIT') {
    return {};
  }
  return state;
};

export const unitIdList = (state = [], action) => {
  if (action.type === 'FETCH_UNIT_ID_LIST') {
    return action.payload;
  }
  if (action.type === 'CLEAR_UNIT_ID_LIST') {
    return [];
  }
  return state;
};

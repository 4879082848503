import Api from '../Api';

export const getLocationList = () => async dispatch => {
  try {
    const { data } = await Api.get('/dropdowns/location');
    dispatch({
      type: 'FETCH_LOCATION_LIST',
      payload: data,
    });
  } catch (err) {
    throw err;
  }
};

export const clearLocationList = () => {
  return {
    type: 'CLEAR_LOCATION_LIST',
  };
};

export const getLocation = id => async dispatch => {
  try {
    const { data } = await Api.get(`/dropdowns/location/${id}`);
    dispatch({
      type: 'FETCH_LOCATION',
      payload: data,
    });
  } catch (err) {
    throw err;
  }
};

export const clearLocation = () => {
  return {
    type: 'CLEAR_LOCATION',
  };
};

import Api from '../Api';

// KRA action types
export const getKRAList = () => async dispatch => {
  try {
    const { data } = await Api.get('/tasks/kras');
    dispatch({
      type: 'FETCH_KRAS',
      payload: data,
    });
  } catch (err) {
    throw err;
  }
};

export const clearKRAList = () => {
  return {
    type: 'CLEAR_KRAS',
  };
};

export const getKRA = id => async dispatch => {
  try {
    const { data } = await Api.get(`/tasks/kras/${id}`);
    dispatch({
      type: 'FETCH_KRA',
      payload: data,
    });
  } catch (err) {
    throw err;
  }
};

export const clearKRA = () => {
  return {
    type: 'CLEAR_KRA',
  };
};

// Task action types
export const getTaskList = query => async dispatch => {
  try {
    const { data } = await Api.get('/tasks', query);

    dispatch({
      type: 'FETCH_TASKS',
      payload: data,
    });
  } catch (err) {
    throw err;
  }
};

export const clearTaskList = () => {
  return {
    type: 'CLEAR_TASKS',
  };
};

export const getTask = id => async dispatch => {
  try {
    const { data } = await Api.get(`/tasks/${id}`);
    dispatch({
      type: 'FETCH_TASK',
      payload: data,
    });
  } catch (err) {
    throw err;
  }
};

export const clearTask = () => {
  return {
    type: 'CLEAR_TASK',
  };
};

export const getSubTask = (taskId, subTaskId) => async dispatch => {
  try {
    const { data } = await Api.get(`/tasks/${taskId}/subtasks/${subTaskId}`);
    dispatch({
      type: 'FETCH_SUBTASK',
      payload: data,
    });
  } catch (err) {
    throw err;
  }
};

export const clearSubTask = () => {
  return {
    type: 'CLEAR_SUBTASK',
  };
};

// Notes action types
export const getWeeklySchedulers = query => async dispatch => {
  try {
    const { data } = await Api.get('/tasks/weekly-scheduler', query);
    dispatch({
      type: 'FETCH_WEEKLY_SCHEDULERS',
      payload: data,
    });
  } catch (err) {
    throw err;
  }
};

export const clearWeeklySchedulers = () => {
  return {
    type: 'CLEAR_WEEKLY_SCHEDULERS',
  };
};

export const getWeeklySchedulerCounts = () => async dispatch => {
  try {
    const { data } = await Api.get('/tasks/weekly-scheduler/counts');

    dispatch({
      type: 'FETCH_WEEKLY_SCHEDULER_COUNTS',
      payload: data,
    });
  } catch (err) {
    throw err;
  }
};

export const clearWeeklySchedulerCounts = () => {
  return {
    type: 'CLEAR_WEEKLY_SCHEDULER_COUNTS',
  };
};

export const getWeeklyScheduler = id => async dispatch => {
  try {
    const { data } = await Api.get(`/tasks/weekly-scheduler/${id}`);
    dispatch({
      type: 'FETCH_WEEKLY_SCHEDULER',
      payload: data,
    });
  } catch (err) {
    throw err;
  }
};

export const clearWeeklyScheduler = () => {
  return {
    type: 'CLEAR_WEEKLY_SCHEDULER',
  };
};

export const getTaskExtensionRequests = () => async dispatch => {
  try {
    const { data } = await Api.get('/tasks/extensions');

    dispatch({
      type: 'FETCH_TASK_EXTENSIONS',
      payload: data,
    });
  } catch (err) {
    throw err;
  }
};

export const clearTaskExtensionRequests = () => {
  return {
    type: 'CLEAR_TASK_EXTENSIONS',
  };
};

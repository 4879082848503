import React, { useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Table } from 'antd';
import moment from 'moment';
import Structure from '../Structure/Structure';
import { dateSorter, dateFilterDropdown, onDateFilter } from '../utils';
import { getWeeklyCollections, clearWeeklyCollections } from '../Actions';
import Heading from '../Components/Heading';

const WeeklyCollectionReport = () => {
  const dispatch = useDispatch();

  const weeklyCollections = useSelector(state => state.weeklyCollections);

  useEffect(() => {
    dispatch(getWeeklyCollections());
    return () => {
      dispatch(clearWeeklyCollections());
    };
  }, []);

  const columns = [
    {
      title: 'From',
      dataIndex: 'from',
      sorter: dateSorter('from'),
      onFilter: onDateFilter('from'),
      filterDropdown: dateFilterDropdown,
      render: date => (date ? moment(date).format('DD MMM YY') : null),
    },
    {
      title: 'To',
      dataIndex: 'to',
      sorter: dateSorter('to'),
      onFilter: onDateFilter('to'),
      filterDropdown: dateFilterDropdown,
      render: date => (date ? moment(date).format('DD MMM YY') : null),
    },
    {
      title: 'To Be Collected',
      dataIndex: 'toBeCollected',
    },
    {
      title: 'Collected',
      dataIndex: 'collected',
    },
  ];

  return (
    <Structure>
      <>
        <Heading text="Weekly Collection Report" />
        <Table
          columns={columns}
          bordered
          rowKey={record => record.id}
          pagination={{ defaultPageSize: 20, position: ['bottomLeft'] }}
          dataSource={weeklyCollections}
          size="small"
          scroll={{ x: 1024, y: window.innerHeight - 300 }}
        />
      </>
    </Structure>
  );
};

export default WeeklyCollectionReport;

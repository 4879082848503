export const materialSaleList = (state = [], action) => {
  if (action.type === 'FETCH_MATERIAL_SALE_LIST') {
    return action.payload;
  }
  if (action.type === 'CLEAR_MATERIAL_SALE_LIST') {
    return [];
  }
  return state;
};

export const materialSaleFilesList = (state = [], action) => {
  if (action.type === 'FETCH_MATERIAL_SALE_FILES_LIST') {
    return action.payload;
  }
  if (action.type === 'CLEAR_MATERIAL_SALE_FILES_LIST') {
    return [];
  }
  return state;
};

export const materialSale = (state = {}, action) => {
  if (action.type === 'FETCH_MATERIAL_SALE') {
    return action.payload;
  }
  if (action.type === 'CLEAR_MATERIAL_SALE') {
    return {};
  }
  return state;
};

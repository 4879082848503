export const genericList = (state = [], action) => {
  if (action.type === 'FETCH_GENERIC_LIST') {
    return action.payload;
  }
  if (action.type === 'CLEAR_GENERIC_LIST') {
    return [];
  }
  return state;
};

export const generic = (state = {}, action) => {
  if (action.type === 'FETCH_GENERIC') {
    return action.payload;
  }
  if (action.type === 'CLEAR_GENERIC') {
    return {};
  }
  return state;
};

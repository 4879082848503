export const supplierMIS = (state = [], action) => {
  if (action.type === 'FETCH_SUPPLIER_MIS') {
    return action.payload;
  }
  if (action.type === 'CLEAR_SUPPLIER_MIS') {
    return [];
  }
  return state;
};

export const supplierDelayedPOs = (state = [], action) => {
  if (action.type === 'FETCH_SUPPLIER_DELAYED_POS') {
    return action.payload;
  }
  if (action.type === 'CLEAR_SUPPLIER_DELAYED_POS') {
    return [];
  }
  return state;
};

export const orderMIS = (state = [], action) => {
  if (action.type === 'FETCH_ORDER_MIS') {
    return action.payload;
  }
  if (action.type === 'CLEAR_ORDER_MIS') {
    return [];
  }
  return state;
};

export const teamMIS = (state = [], action) => {
  if (action.type === 'FETCH_TEAM_MIS') {
    return action.payload;
  }
  if (action.type === 'CLEAR_TEAM_MIS') {
    return [];
  }
  return state;
};

export const taskMIS = (
  state = {
    data: [],
    weeks: [
      {
        week: null,
        start: null,
        end: null,
      },
      {
        week: null,
        start: null,
        end: null,
      },
      {
        week: null,
        start: null,
        end: null,
      },
      {
        week: null,
        start: null,
        end: null,
      },
      {
        week: null,
        start: null,
        end: null,
      },
    ],
  },
  action
) => {
  if (action.type === 'FETCH_TASK_MIS') {
    return action.payload;
  }
  if (action.type === 'CLEAR_TASK_MIS') {
    return {
      data: [],
      weeks: [
        {
          week: null,
          start: null,
          end: null,
        },
        {
          week: null,
          start: null,
          end: null,
        },
        {
          week: null,
          start: null,
          end: null,
        },
        {
          week: null,
          start: null,
          end: null,
        },
        {
          week: null,
          start: null,
          end: null,
        },
      ],
    };
  }
  return state;
};

export const ordersFMS = (state = [], action) => {
  if (action.type === 'FETCH_ORDERS_FMS') {
    return action.payload;
  }
  if (action.type === 'CLEAR_ORDERS_FMS') {
    return [];
  }
  return state;
};

export const ordersMIS = (
  state = {
    data: [],
    weeks: [
      {
        week: null,
        start: null,
        end: null,
      },
      {
        week: null,
        start: null,
        end: null,
      },
      {
        week: null,
        start: null,
        end: null,
      },
      {
        week: null,
        start: null,
        end: null,
      },
      {
        week: null,
        start: null,
        end: null,
      },
    ],
  },
  action
) => {
  if (action.type === 'FETCH_ORDERS_MIS') {
    return action.payload;
  }
  if (action.type === 'CLEAR_ORDERS_MIS') {
    return {
      data: [],
      weeks: [
        {
          week: null,
          start: null,
          end: null,
        },
        {
          week: null,
          start: null,
          end: null,
        },
        {
          week: null,
          start: null,
          end: null,
        },
        {
          week: null,
          start: null,
          end: null,
        },
        {
          week: null,
          start: null,
          end: null,
        },
      ],
    };
  }
  return state;
};

export const ordersASP = (
  state = {
    formulations: [],
    customers: [],
  },
  action
) => {
  if (action.type === 'FETCH_ORDERS_ASP_REPORT') {
    return action.payload;
  }
  if (action.type === 'CLEAR_ORDERS_ASP_REPORT') {
    return {
      formulations: [],
      customers: [],
    };
  }
  return state;
};

export const saleASP = (state = [], action) => {
  if (action.type === 'FETCH_SALE_ASP_REPORT') {
    return action.payload;
  }
  if (action.type === 'CLEAR_SALE_ASP_REPORT') {
    return [];
  }
  return state;
};

export const productASP = (state = [], action) => {
  if (action.type === 'FETCH_PRODUCT_ASP_REPORT') {
    return action.payload;
  }
  if (action.type === 'CLEAR_PRODUCT_ASP_REPORT') {
    return [];
  }
  return state;
};

export const leadFMS = (
  state = {
    totalLeads: null,
    leadsWithoutQuery: null,
    leadsWithQuery: null,
    totalLeadQuery: null,
    leadsQueryWithoutPI: null,
    totalCustomer: null,
    customerWithoutQuery: null,
    customerWithQuery: null,
    totalCustomerQuery: null,
    customerQueryWithoutPI: null,
    leads: [],
  },
  action
) => {
  if (action.type === 'FETCH_LEADS_FMS') {
    return action.payload;
  }
  if (action.type === 'CLEAR_LEADS_FMS') {
    return {
      totalLeads: null,
      leadsWithoutQuery: null,
      leadsWithQuery: null,
      totalLeadQuery: null,
      leadsQueryWithoutPI: null,
      totalCustomer: null,
      customerWithoutQuery: null,
      customerWithQuery: null,
      totalCustomerQuery: null,
      customerQueryWithoutPI: null,
      leads: [],
    };
  }
  return state;
};

export const leadMIS = (
  state = {
    data: [],
    weeks: [
      {
        week: null,
        start: null,
        end: null,
      },
      {
        week: null,
        start: null,
        end: null,
      },
      {
        week: null,
        start: null,
        end: null,
      },
      {
        week: null,
        start: null,
        end: null,
      },
      {
        week: null,
        start: null,
        end: null,
      },
    ],
  },
  action
) => {
  if (action.type === 'FETCH_LEAD_MIS') {
    return action.payload;
  }
  if (action.type === 'CLEAR_LEAD_MIS') {
    return {
      data: [],
      weeks: [
        {
          week: null,
          start: null,
          end: null,
        },
        {
          week: null,
          start: null,
          end: null,
        },
        {
          week: null,
          start: null,
          end: null,
        },
        {
          week: null,
          start: null,
          end: null,
        },
        {
          week: null,
          start: null,
          end: null,
        },
      ],
    };
  }
  return state;
};

import { secondary } from '../theme';

export default {
  marginTop: {
    marginTop: 5,
    borderRadius: '10px',
  },
  footer: {
    display: 'block',
    borderTop: '1px solid #eee',
    paddingTop: '6px',
  },
  floatLeft: {
    float: 'left',
  },
  floatRight: {
    float: 'right',
  },
  tableIcon: {
    fontSize: '17px !important',
    color: 'rgba(0, 0, 0, 0.65)',
  },
  hide: {
    display: 'none',
  },
  button: {
    background: '#096dd9 !important',
    color: secondary,
    border: 'none',
  },
  heading: {
    marginTop: 5,
    marginBottom: 5,
    fontWeight: 'bold',
  },
  detailColor: {
    color: '#096dd9',
    paddingLeft: 5,
  },
};

import Api from '../Api';

export const getHSNCodeList = () => async dispatch => {
  try {
    const { data } = await Api.get('/dropdowns/hsn-codes');
    dispatch({
      type: 'FETCH_HSN_CODE_LIST',
      payload: data,
    });
  } catch (err) {
    throw err;
  }
};

export const clearHSNCodeList = () => {
  return {
    type: 'CLEAR_HSN_CODE_LIST',
  };
};

export const getHSNCode = id => async dispatch => {
  try {
    const { data } = await Api.get(`/dropdowns/hsn-codes/${id}`);
    dispatch({
      type: 'FETCH_HSN_CODE',
      payload: data,
    });
  } catch (err) {
    throw err;
  }
};

export const clearHSNCode = () => {
  return {
    type: 'CLEAR_HSN_CODE',
  };
};

export const getHSNCodeIdList = () => async dispatch => {
  try {
    const { data } = await Api.get('/dropdowns/hsn-codes/ids');
    dispatch({
      type: 'FETCH_HSN_CODE_ID_LIST',
      payload: data,
    });
  } catch (err) {
    throw err;
  }
};

export const clearHSNCodeIdList = () => {
  return {
    type: 'CLEAR_HSN_CODE_ID_LIST',
  };
};

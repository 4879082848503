import moment from 'moment';
import { Input, Space, Button, DatePicker } from 'antd';
import PropTypes from 'prop-types';
import React from 'react';
import { SearchOutlined } from '@ant-design/icons';
import _ from 'lodash';

export const get = (object, keys, defaultValue) => {
  const returnValue = defaultValue === undefined ? null : defaultValue;
  if (object) {
    if (keys && keys.length) {
      return keys.reduce(
        (acc, key) => (acc && acc[key] !== undefined && acc[key] !== null ? acc[key] : returnValue),
        object
      );
    }
  }
  return returnValue;
};

export const sorter = key => (a, b) => {
  if (key) {
    if (get(a, [key], null) === null || get(b, [key], null) === null) {
      return 1;
    }
    if (!Number.isNaN(Number(get(a, [key], null))) && !Number.isNaN(Number(get(b, [key], null)))) {
      return +get(a, [key], null) > +get(b, [key], null) ? 1 : -1;
    }
    return String(get(a, [key], null)).toLowerCase() > String(get(b, [key], null)).toLowerCase() ? 1 : -1;
  }
  return a > b ? 1 : -1;
};

export const sorterInsideObject =
  (...keys) =>
  (a, b) => {
    if (keys.length) {
      return get(a, keys, null) > get(b, keys, null) ? 1 : -1;
    }
    return a > b ? 1 : -1;
  };

export const dateSorter = key => (a, b) => {
  const valueA = get(a, [key], null);
  const valueB = get(b, [key], null);
  if (valueA && valueB) {
    return moment(valueA).toDate() > moment(valueB).toDate() ? 1 : -1;
  }
  return -1;
};

export const dateSorterInsideObject =
  (...keys) =>
  (a, b) => {
    const valueA = get(a, keys, null);
    const valueB = get(b, keys, null);
    if (valueA && valueB) {
      return moment(valueA).toDate() > moment(valueB).toDate() ? 1 : -1;
    }
    return -1;
  };

export const onFilter =
  (key, useStartCase = false) =>
  (value, record) => {
    if (value) {
      if (useStartCase) {
        return record[key] !== undefined && record[key] !== null
          ? _.startCase(record[key].toString()).toLowerCase().includes(value.toLowerCase())
          : record[key] === value;
      }
      return record[key] !== undefined && record[key] !== null
        ? record[key].toString().toLowerCase().includes(value.toLowerCase())
        : record[key] === value;
    }
    return !!(record[key] || '').toString();
  };

export const onFilterInsideObject =
  (...keys) =>
  (value, record) => {
    const _value = get(record, keys, null);
    return _value !== null ? _value.toString().toLowerCase().includes(value.toLowerCase()) : false;
  };

export const onDateFilter = key => (value, record) =>
  record[key] ? [record[key], moment(record[key]).format('D-MMM-YY')].includes(value.format('D-MMM-YY')) : false;

export const filterDropdown = ({ setSelectedKeys, selectedKeys, confirm, clearFilters }) => (
  <div style={{ width: 280, padding: 8 }}>
    <Input
      placeholder="Search"
      value={selectedKeys ? selectedKeys[0] : ''}
      onChange={e => {
        if (e.target.value) {
          setSelectedKeys([e.target.value]);
        } else {
          setSelectedKeys([]);
        }
      }}
      allowClear
      onPressEnter={confirm}
      style={{ marginBottom: 8 }}
    />
    <div style={{ display: 'flex', justifyContent: 'space-between' }}>
      <Button
        type="primary"
        onClick={() => {
          setSelectedKeys([null]);
          confirm();
        }}
        size="small"
        style={{ width: 80 }}
      >
        Not Empty
      </Button>
      <Button type="primary" onClick={confirm} icon={<SearchOutlined />} size="small" style={{ width: 80 }}>
        Search
      </Button>
      <Button onClick={() => clearFilters({ confirm: true })} size="small" style={{ width: 80 }}>
        Reset
      </Button>
    </div>
  </div>
);

filterDropdown.propTypes = {
  setSelectedKeys: PropTypes.func.isRequired,
  selectedKeys: PropTypes.array,
  confirm: PropTypes.any,
  clearFilters: PropTypes.any,
};

export const dateFilterDropdown = ({ setSelectedKeys, selectedKeys, confirm, clearFilters }) => (
  <div style={{ width: 220, padding: 8 }}>
    <DatePicker
      placeholder="Select Date"
      value={selectedKeys ? selectedKeys[0] : ''}
      onChange={date => (date ? setSelectedKeys([date]) : setSelectedKeys([]))}
      allowClear
      onPressEnter={confirm}
      style={{ marginBottom: 8 }}
    />
    <Space>
      <Button type="primary" onClick={confirm} icon={<SearchOutlined />} size="small" style={{ width: 100 }}>
        Search
      </Button>
      <Button onClick={() => clearFilters({ confirm: true })} size="small" style={{ width: 100 }}>
        Reset
      </Button>
    </Space>
  </div>
);

dateFilterDropdown.propTypes = {
  setSelectedKeys: PropTypes.func.isRequired,
  selectedKeys: PropTypes.array,
  confirm: PropTypes.any,
  clearFilters: PropTypes.any,
};

export const filterItemToOrderDropdown = ({ setSelectedKeys, confirm, clearFilters }) => {
  return (
    <>
      <div style={{ width: 280, padding: 8 }}>
        <div style={{ display: 'flex', justifyContent: 'space-between' }}>
          <Button
            type="primary"
            onClick={() => {
              setSelectedKeys(['less']);
              confirm();
            }}
            size="small"
            style={{ width: 80 }}
          >
            ≤ Zero
          </Button>
          <Button
            type="primary"
            onClick={() => {
              setSelectedKeys(['greater']);
              confirm();
            }}
            size="small"
            style={{ width: 80 }}
          >
            {'> Zero'}
          </Button>
          <Button onClick={() => clearFilters({ confirm: true })} size="small" style={{ width: 80 }}>
            Reset
          </Button>
        </div>
      </div>
    </>
  );
};

filterItemToOrderDropdown.propTypes = {
  setSelectedKeys: PropTypes.func.isRequired,
  confirm: PropTypes.any,
  clearFilters: PropTypes.any,
};

export const onItemToOrderFilter = key => (value, record) => {
  if (value) {
    if (value === 'less') {
      return Number(record[key]) <= 0;
    }
    if (value === 'greater') {
      return Number(record[key]) > 0;
    }
  }
  return !!(record[key] || '').toString();
};

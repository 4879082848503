import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import withStyles from 'react-jss';
import { Field, getFormValues, reduxForm } from 'redux-form';
import { connect, useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router';
import { Button, Col, DatePicker, Input, Modal, Popconfirm, Row, Select } from 'antd';
import startCase from 'lodash/startCase';
import moment from 'moment';
import styles from '../CommonStyles';
import GetField from '../Components/GetField';
import LeftGrid from '../Components/LeftGrid';
import RightGrid from '../Components/RightGrid';
import { errorNotification, successNotification } from '../Snackbar';
import Api from '../Api';
import Structure from '../Structure/Structure';
import Heading from '../Components/Heading';
import { getSale, clearSale } from '../Actions';
import { get } from '../utils';
import constants from '../constants';

const AntInput = GetField(Input);
const AntSelect = GetField(Select);
const AntDatePicker = GetField(DatePicker);

const EditSale = props => {
  const { handleSubmit, classes, change, match } = props;
  const history = useHistory();
  const dispatch = useDispatch();

  const [formValueModal, setFormValuesModal] = useState(false);
  const [confirmLoading, setConfirmLoading] = useState(false);
  const [preview, setPreview] = useState({});
  const FormValues = useSelector(state => getFormValues('EditSale')(state));

  useEffect(() => {
    dispatch(getSale(match.params.id));
    return () => {
      dispatch(clearSale());
    };
  }, []);

  useEffect(() => {
    if (FormValues && FormValues.quantity && FormValues.rate) {
      const value = (+FormValues.quantity * +FormValues.rate).toFixed(2);
      change('value', value);
    } else {
      change('value', '');
    }
  }, [FormValues && FormValues.quantity, FormValues && FormValues.rate]);

  useEffect(() => {
    if (FormValues && FormValues.value && (FormValues.gst || FormValues.gst === 0)) {
      const gstValue = ((+FormValues.value * +FormValues.gst) / 100).toFixed(2);
      change('gstValue', gstValue);
    } else {
      change('gstValue', '');
    }
  }, [FormValues && FormValues.value, FormValues && (FormValues.gst || FormValues.gst === 0)]);

  useEffect(() => {
    if (FormValues && FormValues.forwardingCharges) {
      const forwardingChargesTax = ((+FormValues.forwardingCharges * 18) / 100).toFixed(2);
      change('forwardingChargesTax', forwardingChargesTax);
    } else {
      change('forwardingChargesTax', '');
    }
  }, [FormValues && FormValues.forwardingCharges]);

  useEffect(() => {
    if (FormValues && FormValues.value && FormValues && FormValues.gstValue) {
      const total = (
        +FormValues.value +
        +FormValues.gstValue +
        (+FormValues.forwardingCharges || 0) +
        (+FormValues.forwardingChargesTax || 0) +
        (+FormValues.otherChargesDiscount || 0) +
        (+FormValues.roundOf || 0)
      ).toFixed(2);
      change('total', total);
    }
  }, [
    FormValues && FormValues.value,
    FormValues && FormValues.gstValue,
    FormValues && FormValues.forwardingCharges,
    FormValues && FormValues.forwardingChargesTax,
    FormValues && FormValues.otherChargesDiscount,
    FormValues && FormValues.roundOf,
  ]);

  const showFormValuesModal = () => {
    const voucherDate = get(FormValues, ['voucherDate']);
    const type = get(FormValues, ['type']);

    const _preview = {
      orderNo: get(FormValues, ['orderId'], '--'),
      customer: get(FormValues, ['customerName'], '--'),
      consignor: get(FormValues, ['consignor'], '--'),
      voucherNo: get(FormValues, ['voucherNo'], '--'),
      voucherDate: voucherDate ? moment(voucherDate).format('DD MMM YYYY') : '--',
      type: type ? startCase(type) : '--',
      formulation: get(FormValues, ['formulationName'], '--'),
      hsn: get(FormValues, ['hsn'], '--'),
      quantity: get(FormValues, ['quantity'], '--'),
      orderRate: get(FormValues, ['orderRate'], '--'),
      rate: get(FormValues, ['rate'], '--'),
      value: get(FormValues, ['value'], '--'),
      gst: get(FormValues, ['gst'], '--'),
      gstValue: get(FormValues, ['gstValue'], '--'),
      forwardingCharges: get(FormValues, ['forwardingCharges'], '--'),
      forwardingChargesTax: get(FormValues, ['forwardingChargesTax'], '--'),
      otherChargesDiscount: get(FormValues, ['otherChargesDiscount'], '--'),
      roundOf: get(FormValues, ['roundOf'], '--'),
      total: get(FormValues, ['total'], '--'),
      containerNo: get(FormValues, ['containerNo'], '--'),
      kindOfPackage: get(FormValues, ['kindOfPackage'], '--'),
      remarks: get(FormValues, ['remarks'], '--'),
    };

    setPreview(_preview);
    setFormValuesModal(true);
  };

  const hideFormValuesModal = () => {
    setFormValuesModal(false);
    setPreview({});
  };

  const onSubmit = async () => {
    try {
      setConfirmLoading(true);
      await Api.put(`/order-sales/${match.params.id}`, FormValues);
      successNotification('Sale updated');
      setConfirmLoading(false);
      hideFormValuesModal();
      history.push('/sale/list');
    } catch (err) {
      setConfirmLoading(false);
      errorNotification(err);
    }
  };

  return (
    <Structure>
      <>
        <Heading text="Edit Sale" />
        <form onSubmit={handleSubmit(onSubmit)}>
          <Row>
            <LeftGrid>
              <Field
                label="Order Number"
                disabled
                allowClear
                name="orderId"
                placeholder="Order Number"
                component={AntInput}
              />
            </LeftGrid>
            <RightGrid>
              <Field
                label="Customer"
                disabled
                allowClear
                name="customerName"
                placeholder="Customer"
                component={AntInput}
              />
            </RightGrid>
          </Row>
          <Row>
            <LeftGrid>
              <Field label="Consignor" allowClear required name="consignor" component={AntSelect}>
                <Select.Option key={null} value="" disabled>
                  Select consignor
                </Select.Option>
                {Object.values(constants.consignors.list).map(consignor => (
                  <Select.Option key={consignor} value={consignor}>
                    {consignor}
                  </Select.Option>
                ))}
              </Field>
            </LeftGrid>
            <RightGrid>
              <Field
                label="Voucher Number"
                allowClear
                required
                name="voucherNo"
                placeholder="Voucher Number"
                component={AntInput}
              />
            </RightGrid>
          </Row>
          <Row>
            <LeftGrid>
              <Field
                allowClear
                required
                label="Voucher Date"
                name="voucherDate"
                placeholder="Voucher Date"
                component={AntDatePicker}
              />
            </LeftGrid>
            <RightGrid>
              <Field label="Sale Type" allowClear required name="type" component={AntSelect}>
                <Select.Option key={null} value="" disabled>
                  Select sale type
                </Select.Option>
                <Select.Option key="invoice" value="invoice">
                  Invoice
                </Select.Option>
                <Select.Option key="challan" value="challan">
                  Challan
                </Select.Option>
              </Field>
            </RightGrid>
          </Row>
          <Row>
            <LeftGrid>
              <Field
                label="Finished Good Name"
                disabled
                allowClear
                name="formulationName"
                placeholder="Finished Good Name"
                component={AntInput}
              />
            </LeftGrid>
            <RightGrid>
              <Field label="HSN Code" disabled allowClear name="hsn" placeholder="HSN Code" component={AntInput} />
            </RightGrid>
          </Row>
          <Row>
            <LeftGrid>
              <Field
                label="Quantity"
                disabled
                allowClear
                type="number"
                min={0}
                name="quantity"
                placeholder="Quantity"
                component={AntInput}
              />
            </LeftGrid>
            <RightGrid>
              <Field
                label="Order Rate"
                disabled
                allowClear
                name="orderRate"
                placeholder="Order Rate"
                component={AntInput}
              />
            </RightGrid>
          </Row>
          <Row>
            <LeftGrid>
              <Field
                label="Rate"
                allowClear
                type="number"
                min={0}
                name="rate"
                placeholder="Rate"
                component={AntInput}
              />
            </LeftGrid>
            <RightGrid>
              <Field
                label="Sale Value"
                disabled
                allowClear
                type="number"
                min={0}
                name="value"
                placeholder="Sale Value"
                component={AntInput}
              />
            </RightGrid>
          </Row>
          <Row>
            <LeftGrid>
              <Field
                label="GST (in %)"
                allowClear
                type="number"
                min={0}
                name="gst"
                placeholder="GST (in %)"
                component={AntInput}
              />
            </LeftGrid>
            <RightGrid>
              <Field
                label="GST Value"
                disabled
                allowClear
                type="number"
                min={0}
                name="gstValue"
                placeholder="GST Value"
                component={AntInput}
              />
            </RightGrid>
          </Row>
          <Row>
            <LeftGrid>
              <Field
                label="Forwarding Charges"
                allowClear
                type="number"
                min={0}
                name="forwardingCharges"
                placeholder="Forwarding Charges"
                component={AntInput}
              />
            </LeftGrid>
            <RightGrid>
              <Field
                label="Forwarding Charges Tax Value (Tax 18%)"
                allowClear
                disabled
                type="number"
                min={0}
                name="forwardingChargesTax"
                placeholder="Forwarding Charges Tax Value (Tax 18%)"
                component={AntInput}
              />
            </RightGrid>
          </Row>
          <Row>
            <LeftGrid>
              <Field
                label="Other Charges/Discount (+/-)"
                allowClear
                type="number"
                name="otherChargesDiscount"
                placeholder="Other Charges/Discount (+/-)"
                component={AntInput}
              />
            </LeftGrid>
            <RightGrid>
              <Field
                label="Round of (+/-)"
                allowClear
                type="number"
                name="roundOf"
                placeholder="Round of (+/-)"
                component={AntInput}
              />
            </RightGrid>
          </Row>
          <Row>
            <LeftGrid>
              <Field
                label="Total"
                disabled
                allowClear
                type="number"
                min={0}
                name="total"
                placeholder="Total"
                component={AntInput}
              />
            </LeftGrid>
            <RightGrid>
              <Field
                allowClear
                required
                name="containerNo"
                label="Marks & No/Container No"
                placeholder="Marks & No/Container No"
                component={AntInput}
              />
            </RightGrid>
          </Row>
          <Row>
            <LeftGrid>
              <Field
                allowClear
                required
                name="kindOfPackage"
                label="No & Kind of Pkgs."
                placeholder="No & Kind of Pkgs."
                component={AntInput}
              />
            </LeftGrid>
            <RightGrid>
              <Field label="Remarks" allowClear name="remarks" placeholder="Remarks" component={AntInput} />
            </RightGrid>
          </Row>
          <Row className={classes.buttonContainer}>
            <Button className={classes.button} type="primary" onClick={showFormValuesModal}>
              Preview
            </Button>
          </Row>
        </form>
        <Modal
          onCancel={hideFormValuesModal}
          open={formValueModal}
          confirmLoading={confirmLoading}
          centered
          width="800px"
          maskClosable={false}
          closable={false}
          cancelText="Back"
          okButtonProps={{ className: classes.button }}
          okText={
            <Popconfirm
              title="Sure want to submit form?"
              onConfirm={() => onSubmit()}
              okText="Confirm"
              cancelText="Cancel"
            >
              Submit
            </Popconfirm>
          }
        >
          <Row>
            <Col xs={24} lg={12}>
              <p>
                Order Number:
                <span className={classes.detailColor}>{preview.orderNo}</span>
              </p>
            </Col>
            <Col xs={24} lg={12}>
              <p>
                Customer:
                <span className={classes.detailColor}>{preview.customer}</span>
              </p>
            </Col>
          </Row>
          <Row>
            <Col xs={24} lg={12}>
              <p>
                Consignor:
                <span className={classes.detailColor}>{preview.consignor}</span>
              </p>
            </Col>
            <Col xs={24} lg={12}>
              <p>
                Voucher Number:
                <span className={classes.detailColor}>{preview.voucherNo}</span>
              </p>
            </Col>
          </Row>
          <Row>
            <Col xs={24} lg={12}>
              <p>
                Voucher Date:
                <span className={classes.detailColor}>{preview.voucherDate}</span>
              </p>
            </Col>
            <Col xs={24} lg={12}>
              <p>
                Sale Type:
                <span className={classes.detailColor}>{preview.type}</span>
              </p>
            </Col>
          </Row>
          <Row>
            <Col xs={24} lg={12}>
              <p>
                Finished Good Name:
                <span className={classes.detailColor}>{preview.formulation}</span>
              </p>
            </Col>
            <Col xs={24} lg={12}>
              <p>
                HSN Code:
                <span className={classes.detailColor}>{preview.hsn}</span>
              </p>
            </Col>
          </Row>
          <Row>
            <Col xs={24} lg={12}>
              <p>
                Quantity:
                <span className={classes.detailColor}>{preview.quantity}</span>
              </p>
            </Col>
            <Col xs={24} lg={12}>
              <p>
                Order Rate:
                <span className={classes.detailColor}>{preview.orderRate}</span>
              </p>
            </Col>
          </Row>
          <Row>
            <Col xs={24} lg={12}>
              <p>
                Rate:
                <span className={classes.detailColor}>{preview.rate}</span>
              </p>
            </Col>
            <Col xs={24} lg={12}>
              <p>
                Sale Value:
                <span className={classes.detailColor}>{preview.value}</span>
              </p>
            </Col>
          </Row>
          <Row>
            <Col xs={24} lg={12}>
              <p>
                GST (in %):
                <span className={classes.detailColor}>{preview.gst}</span>
              </p>
            </Col>
            <Col xs={24} lg={12}>
              <p>
                GST Value:
                <span className={classes.detailColor}>{preview.gstValue}</span>
              </p>
            </Col>
          </Row>
          <Row>
            <Col xs={24} lg={12}>
              <p>
                Forwarding Charges:
                <span className={classes.detailColor}>{preview.forwardingCharges}</span>
              </p>
            </Col>
            <Col xs={24} lg={12}>
              <p>
                Forwarding Charges Tax Value (Tax 18%):
                <span className={classes.detailColor}>{preview.forwardingChargesTax}</span>
              </p>
            </Col>
          </Row>
          <Row>
            <Col xs={24} lg={12}>
              <p>
                Other Charges/Discount (+/-):
                <span className={classes.detailColor}>{preview.otherChargesDiscount}</span>
              </p>
            </Col>
            <Col xs={24} lg={12}>
              <p>
                Round of (+/-):
                <span className={classes.detailColor}>{preview.roundOf}</span>
              </p>
            </Col>
          </Row>
          <Row>
            <Col xs={24} lg={12}>
              <p>
                Total:
                <span className={classes.detailColor}>{preview.total}</span>
              </p>
            </Col>
            <Col xs={24} lg={12}>
              <p>
                Marks & No/Container No:
                <span className={classes.detailColor}>{preview.containerNo}</span>
              </p>
            </Col>
          </Row>
          <Row>
            <Col xs={24} lg={12}>
              <p>
                No & Kind of Pkgs.:
                <span className={classes.detailColor}>{preview.kindOfPackage}</span>
              </p>
            </Col>
            <Col xs={24} lg={12}>
              <p>
                Remarks:
                <span className={classes.detailColor}>{preview.remarks}</span>
              </p>
            </Col>
          </Row>
        </Modal>
      </>
    </Structure>
  );
};

EditSale.propTypes = {
  handleSubmit: PropTypes.func.isRequired,
  classes: PropTypes.object.isRequired,
  match: PropTypes.object.isRequired,
  change: PropTypes.func.isRequired,
};

const StyledEditSale = withStyles(styles)(EditSale);

const FormedEditSale = reduxForm({
  form: 'EditSale',
  enableReinitialize: true,
})(StyledEditSale);

export default connect(({ sale }) => ({ initialValues: sale }), {})(FormedEditSale);

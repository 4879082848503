import Api from '../Api';

export const getAssetList = params => async dispatch => {
  try {
    const { data } = await Api.get('/assets', params);
    dispatch({
      type: 'FETCH_ASSET_LIST',
      payload: data,
    });
  } catch (err) {
    throw err;
  }
};

export const clearAssetList = () => {
  return {
    type: 'CLEAR_ASSET_LIST',
  };
};

export const getAsset = id => async dispatch => {
  try {
    const { data } = await Api.get(`/assets/${id}`);
    dispatch({
      type: 'FETCH_ASSET',
      payload: data,
    });
  } catch (err) {
    throw err;
  }
};

export const clearAsset = () => {
  return {
    type: 'CLEAR_ASSET',
  };
};

export const getAssetIdList = params => async dispatch => {
  try {
    const { data } = await Api.get('/assets/ids', params);
    dispatch({
      type: 'FETCH_ASSET_ID_LIST',
      payload: data,
    });
  } catch (err) {
    throw err;
  }
};

export const clearAssetIdList = () => {
  return {
    type: 'CLEAR_ASSET_ID_LIST',
  };
};

export const getAssetAdjustmentList = params => async dispatch => {
  try {
    const { data } = await Api.get('/assets/adjustments', params);
    dispatch({
      type: 'FETCH_ASSET_ADJUSTMENT_LIST',
      payload: data,
    });
  } catch (err) {
    throw err;
  }
};

export const clearAssetAdjustmentList = () => {
  return {
    type: 'CLEAR_ASSET_ADJUSTMENT_LIST',
  };
};

export const getAssetValuesOnDate = query => async dispatch => {
  try {
    const { data } = await Api.get('/assets/values', query);
    dispatch({
      type: 'FETCH_ASSET_VALUES',
      payload: data,
    });
  } catch (err) {
    throw err;
  }
};

export const clearAssetValuesOnDate = () => {
  return {
    type: 'CLEAR_ASSET_VALUES',
  };
};

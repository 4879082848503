import Api from '../Api';

export const getSaleList = query => async dispatch => {
  try {
    const { data } = await Api.get('/order-sales', query);
    dispatch({
      type: 'FETCH_SALE_LIST',
      payload: data,
    });
  } catch (err) {
    throw err;
  }
};

export const clearSaleList = () => {
  return {
    type: 'CLEAR_SALE_LIST',
  };
};

export const getSaleFilesList = id => async dispatch => {
  try {
    const { data } = await Api.get(`/order-sales/${id}/files`);
    dispatch({
      type: 'FETCH_SALE_FILES_LIST',
      payload: data,
    });
  } catch (err) {
    throw err;
  }
};

export const clearSaleFilesList = () => {
  return {
    type: 'CLEAR_SALE_FILES_LIST',
  };
};

export const setSale = data => async dispatch => {
  try {
    dispatch({
      type: 'FETCH_SALE_ORDER',
      payload: data,
    });
  } catch (err) {
    throw err;
  }
};

export const getSale = id => async dispatch => {
  try {
    const { data } = await Api.get(`/order-sales/${id}`);
    dispatch({
      type: 'FETCH_SALE_ORDER',
      payload: data,
    });
  } catch (err) {
    throw err;
  }
};

export const clearSale = () => {
  return {
    type: 'CLEAR_SALE_ORDER',
  };
};

export const getOrderSale = id => async dispatch => {
  try {
    const { data } = await Api.get(`/order-sales/order/${id}`);
    dispatch({
      type: 'FETCH_ORDER_SALE',
      payload: data,
    });
  } catch (err) {
    throw err;
  }
};

export const clearOrderSale = () => {
  return {
    type: 'CLEAR_ORDER_SALE',
  };
};

export const getOrderSaleReport = query => async dispatch => {
  try {
    const { data } = await Api.get(`/order-sales/report`, query);
    dispatch({
      type: 'FETCH_ORDER_SALE_REPORT',
      payload: data,
    });
  } catch (err) {
    throw err;
  }
};

export const clearOrderSaleReport = () => {
  return {
    type: 'CLEAR_ORDER_SALE_REPORT',
  };
};

export const getCustomerASPReport = query => async dispatch => {
  try {
    const { data } = await Api.get(`/order-sales/report/customers`, query);
    dispatch({
      type: 'FETCH_ORDER_SALE_ASP_REPORT',
      payload: data,
    });
  } catch (err) {
    throw err;
  }
};

export const clearCustomerASPReport = () => {
  return {
    type: 'CLEAR_ORDER_SALE_ASP_REPORT',
  };
};

export const getGSTReversalReport = query => async dispatch => {
  try {
    const { data } = await Api.get(`/order-sales/jhol/report`, query);
    dispatch({
      type: 'FETCH_GST_REVERSAL_REPORT',
      payload: data,
    });
  } catch (err) {
    throw err;
  }
};

export const clearGSTReversalReport = () => {
  return {
    type: 'CLEAR_GST_REVERSAL_REPORT',
  };
};

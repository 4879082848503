import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Table, Tooltip } from 'antd';
import { FileTextOutlined } from '@ant-design/icons';
import { reset } from 'redux-form';
import moment from 'moment';
import Structure from '../Structure/Structure';
import { onFilter, sorter, filterDropdown, dateSorter, onDateFilter, dateFilterDropdown } from '../utils';
import { getCustomerComplaints, clearCustomerComplaints } from '../Actions';
import ComplaintResolve from './ComplaintResolve';

const ComplaintList = () => {
  const dispatch = useDispatch();

  const complaints = useSelector(state => state.complaints);
  const [complaintId, setComplaintId] = useState(null);
  const [viewModal, setViewModal] = useState(null);

  useEffect(() => {
    dispatch(getCustomerComplaints());
    return () => {
      dispatch(clearCustomerComplaints());
    };
  }, []);

  const showModal = id => {
    setComplaintId(id);
    setViewModal(true);
  };

  const hideModal = () => {
    setComplaintId(null);
    setViewModal(false);
    dispatch(getCustomerComplaints());
    dispatch(reset('ComplaintResolve'));
  };

  const columns = [
    {
      title: 'Customer',
      dataIndex: 'customer',
      filterDropdown,
      sorter: sorter('customer'),
      onFilter: onFilter('customer'),
    },
    {
      title: 'Date',
      dataIndex: 'createdAt',
      sorter: dateSorter('createdAt'),
      filterDropdown: dateFilterDropdown,
      onFilter: onDateFilter('createdAt'),
      render: date => (date ? moment(date).format('DD MMM YYYY') : null),
      width: 120,
    },
    {
      title: 'Reason',
      dataIndex: 'reason',
      filterDropdown,
      sorter: sorter('reason'),
      onFilter: onFilter('reason'),
    },
    {
      title: 'Details',
      dataIndex: 'details',
      filterDropdown,
      sorter: sorter('details'),
      onFilter: onFilter('details'),
    },
    {
      title: 'Status',
      dataIndex: 'status',
      filterDropdown,
      sorter: sorter('status'),
      onFilter: onFilter('status'),
      width: '10%',
      render: (_text, record) =>
        record.status === 'open' ? (
          <>
            <Tooltip placement="top" title="Resolve">
              <FileTextOutlined onClick={() => showModal(record.id)} />
            </Tooltip>
          </>
        ) : (
          'Resolved'
        ),
    },
    {
      title: 'Resolved By',
      dataIndex: 'resolvedBy',
      sorter: sorter('resolvedBy'),
      onFilter: onFilter('resolvedBy'),
      filterDropdown,
      width: 150,
    },
    {
      title: 'Resolved Date',
      dataIndex: 'resolvedAt',
      sorter: dateSorter('resolvedAt'),
      filterDropdown: dateFilterDropdown,
      onFilter: onDateFilter('resolvedAt'),
      render: date => (date ? moment(date).format('DD MMM YYYY') : null),
      width: 120,
    },
  ];

  return (
    <Structure>
      <>
        <Table
          columns={columns}
          bordered
          rowKey={record => record.id}
          pagination={{ defaultPageSize: 20, position: ['bottomLeft'], showTotal: total => `Total ${total} Items` }}
          dataSource={complaints}
          size="small"
          scroll={{ x: 1050, y: window.innerHeight - 300 }}
        />
        <ComplaintResolve hideModal={hideModal} viewModal={viewModal} complaintId={complaintId} />
      </>
    </Structure>
  );
};

export default ComplaintList;

import Api from '../Api';

export const getTransporterList = () => async dispatch => {
  try {
    const { data } = await Api.get('/transports');
    dispatch({
      type: 'FETCH_TRANSPORTER_LIST',
      payload: data,
    });
  } catch (err) {
    throw err;
  }
};

export const clearTransporterList = () => {
  return {
    type: 'CLEAR_TRANSPORTER_LIST',
  };
};

export const getTransporter = id => async dispatch => {
  try {
    const { data } = await Api.get(`/transports/${id}`);
    dispatch({
      type: 'FETCH_TRANSPORTER',
      payload: data,
    });
  } catch (err) {
    throw err;
  }
};

export const clearTransporter = () => {
  return {
    type: 'CLEAR_TRANSPORTER',
  };
};

export const getTransporterIdList = () => async dispatch => {
  try {
    const { data } = await Api.get('/transports/ids');
    dispatch({
      type: 'FETCH_TRANSPORTER_ID_LIST',
      payload: data,
    });
  } catch (err) {
    throw err;
  }
};

export const clearTransporterIdList = () => {
  return {
    type: 'CLEAR_TRANSPORTER_ID_LIST',
  };
};

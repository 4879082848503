import React from 'react';
import { Row, Col } from 'antd';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router';
import withStyles from 'react-jss';

import styles from './styles';

const Heading = props => {
  const { children, style, className, classes, text } = props;
  return children ? (
    <Row className={classes.row}>
      <Col xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 4 }} lg={{ span: 4 }} xl={{ span: 3 }}>
        {children}
      </Col>
      <Col
        xs={{ span: 24 }}
        sm={{ span: 24 }}
        md={{ span: 16 }}
        lg={{ span: 16 }}
        xl={{ span: 18 }}
        className={classes.heading}
      >
        <span style={style} className={className}>
          {text}
        </span>
      </Col>
    </Row>
  ) : (
    <Row className={classes.row}>
      <Col xs={24} className={classes.heading}>
        <span style={style} className={className}>
          {text}
        </span>
      </Col>
    </Row>
  );
};

Heading.propTypes = {
  children: PropTypes.oneOfType([PropTypes.element, PropTypes.string, PropTypes.number, PropTypes.array]),
  style: PropTypes.object,
  className: PropTypes.string,
  text: PropTypes.string.isRequired,
  classes: PropTypes.object.isRequired,
};

export default withRouter(withStyles(styles)(Heading));

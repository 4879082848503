import api from '../Api';

export const getUserList = () => async dispatch => {
  try {
    const { data } = await api.get('/users');

    dispatch({
      type: 'FETCH_USER_LIST',
      payload: data,
    });
  } catch (err) {
    throw err;
  }
};

export const clearUserList = () => {
  return {
    type: 'CLEAR_USER_LIST',
  };
};

export const getFilteredUserList = query => async dispatch => {
  try {
    const { data } = await api.get('/users', query);

    dispatch({
      type: 'FETCH_FILTERED_USER_LIST',
      payload: data,
    });
  } catch (err) {
    throw err;
  }
};

export const clearFilteredUserList = () => {
  return {
    type: 'CLEAR_FILTERED_USER_LIST',
  };
};

export const getUser = id => async dispatch => {
  try {
    const { data } = await api.get(`/users/${id}`);
    dispatch({
      type: 'FETCH_USER',
      payload: data,
    });
  } catch (err) {
    throw err;
  }
};

export const clearUser = () => {
  return {
    type: 'CLEAR_USER',
  };
};

import Api from '../Api';

export const getJobWorks = () => async dispatch => {
  const { data } = await Api.get('/jobworks');
  dispatch({
    type: 'FETCH_JOB_WORKS',
    payload: data,
  });
};

export const clearJobWorks = () => {
  return {
    type: 'CLEAR_JOB_WORKS',
  };
};

export const getJobWork = id => async dispatch => {
  const { data } = await Api.get(`/jobworks/${id}`);
  dispatch({
    type: 'FETCH_JOB_WORK',
    payload: data,
  });
};

export const clearJobWork = () => {
  return {
    type: 'CLEAR_JOB_WORK',
  };
};

export const getJobWorkFiles = id => async dispatch => {
  try {
    const { data } = await Api.get(`/jobworks/${id}/files`);
    dispatch({
      type: 'FETCH_JOB_WORK_FILES',
      payload: data,
    });
  } catch (err) {
    throw err;
  }
};

export const clearJobWorkFiles = () => {
  return {
    type: 'CLEAR_JOB_WORK_FILES',
  };
};

import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import withStyles from 'react-jss';
import { reduxForm, Field, getFormValues } from 'redux-form';
import { Input, Select, Row, Modal, Button, Popconfirm, Col } from 'antd';
import { useHistory } from 'react-router';
import { connect, useDispatch, useSelector } from 'react-redux';
import styles from '../CommonStyles';
import LeftGrid from '../Components/LeftGrid';
import RightGrid from '../Components/RightGrid';
import GetField from '../Components/GetField';
import { errorNotification, successNotification } from '../Snackbar';
import Api from '../Api';
import Structure from '../Structure/Structure';
import Heading from '../Components/Heading';
import {
  getCustomerIdList,
  clearCustomerIdList,
  getCustomerOpeningBalance,
  clearCustomerOpeningBalance,
} from '../Actions';
import { get } from '../utils';

const AntInput = GetField(Input);
const AntSelect = GetField(Select);

const EditOpeningBalance = props => {
  const { handleSubmit, classes, match } = props;
  const history = useHistory();
  const dispatch = useDispatch();

  const [formValueModal, setFormValuesModal] = useState(false);
  const [confirmLoading, setConfirmLoading] = useState(false);
  const [preview, setPreview] = useState({});

  const customerIdList = useSelector(state => state.customerIdList);
  const FormValues = useSelector(state => getFormValues('EditOpeningBalance')(state));

  useEffect(() => {
    dispatch(getCustomerIdList({ status: 'Active' }));
    dispatch(getCustomerOpeningBalance(match.params.id));
    return () => {
      dispatch(clearCustomerIdList());
      dispatch(clearCustomerOpeningBalance());
    };
  }, []);

  const showFormValuesModal = () => {
    const _preview = {
      customer: '--',
      balance: get(FormValues, ['balance'], '--'),
    };

    const customerId = get(FormValues, ['customerId'], null);
    if (customerId) {
      const customerObj = customerIdList.find(customer => customer.id === +customerId);
      if (customerObj) {
        _preview.customer = customerObj.name;
      }
    }

    setPreview(_preview);
    setFormValuesModal(true);
  };

  const hideFormValuesModal = () => {
    setFormValuesModal(false);
    setPreview({});
  };

  const onSubmit = async () => {
    try {
      setConfirmLoading(true);
      await Api.put(`/customers/openingbalances/${match.params.id}`, FormValues);
      successNotification('Opening Balance updated');
      setConfirmLoading(false);
      hideFormValuesModal();
      history.push('/customer/openingbalances/list');
    } catch (err) {
      setConfirmLoading(false);
      errorNotification(err);
    }
  };

  return (
    <Structure>
      <>
        <Heading text="Edit Opening Balance" />
        <form onSubmit={handleSubmit(onSubmit)}>
          <Row>
            <LeftGrid>
              <Field label="Customer" required name="customerId" disabled component={AntSelect}>
                <Select.Option key={null} value="" disabled>
                  Select customer
                </Select.Option>
                {customerIdList.map(customer => (
                  <Select.Option key={customer.id} value={customer.id}>
                    {customer.name}
                  </Select.Option>
                ))}
              </Field>
            </LeftGrid>
            <RightGrid>
              <Field
                label="Balance"
                allowClear
                type="number"
                min={0}
                name="balance"
                placeholder="Balance"
                component={AntInput}
              />
            </RightGrid>
          </Row>
          <Row className={classes.buttonContainer}>
            <Button className={classes.button} type="primary" onClick={showFormValuesModal}>
              Preview
            </Button>
          </Row>
        </form>
        <Modal
          onCancel={hideFormValuesModal}
          open={formValueModal}
          confirmLoading={confirmLoading}
          centered
          width="800px"
          maskClosable={false}
          closable={false}
          cancelText="Back"
          okButtonProps={{ className: classes.button }}
          okText={
            <Popconfirm
              title="Sure want to submit form?"
              onConfirm={() => onSubmit()}
              okText="Confirm"
              cancelText="Cancel"
            >
              Submit
            </Popconfirm>
          }
        >
          <Row>
            <Col xs={24} lg={12}>
              <p>
                Customer:
                <span className={classes.detailColor}>{preview.customer}</span>
              </p>
            </Col>
            <Col xs={24} lg={12}>
              <p>
                Balance:
                <span className={classes.detailColor}>{preview.balance}</span>
              </p>
            </Col>
          </Row>
        </Modal>
      </>
    </Structure>
  );
};

EditOpeningBalance.propTypes = {
  handleSubmit: PropTypes.func.isRequired,
  classes: PropTypes.object.isRequired,
  match: PropTypes.object.isRequired,
};

const StyledEditOpeningBalance = withStyles(styles)(EditOpeningBalance);

const FormedEditOpeningBalance = reduxForm({
  form: 'EditOpeningBalance',
  enableReinitialize: true,
})(StyledEditOpeningBalance);

export default connect(
  ({ customerOpeningBalance }) => ({ initialValues: customerOpeningBalance }),
  {}
)(FormedEditOpeningBalance);

import React, { useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Table } from 'antd';
import Structure from '../Structure/Structure';
import { sorter, onFilter, filterDropdown } from '../utils';
import { getPatientList, clearPatientList } from '../Actions';
import Heading from '../Components/Heading';

const PatientList = () => {
  const dispatch = useDispatch();

  const patientList = useSelector(state => state.patientList);

  useEffect(() => {
    dispatch(getPatientList());
    return () => {
      dispatch(clearPatientList());
    };
  }, []);

  const columns = [
    {
      title: 'Patient #',
      dataIndex: 'id',
      key: 'id',
      filterDropdown,
      sorter: sorter('id'),
      onFilter: onFilter('id'),
    },
    {
      title: 'Queue #',
      dataIndex: 'patientNo',
      key: 'patientNo',
      filterDropdown,
      sorter: sorter('patientNo'),
      onFilter: onFilter('patientNo'),
    },
    {
      title: 'Name',
      dataIndex: 'name',
      key: 'name',
      filterDropdown,
      sorter: sorter('name'),
      onFilter: onFilter('name'),
    },
    {
      title: 'Gender',
      dataIndex: 'gender',
      key: 'gender',
      filterDropdown,
      sorter: sorter('gender'),
      onFilter: onFilter('gender'),
    },
    {
      title: 'Phone #',
      dataIndex: 'phone',
      key: 'phone',
      filterDropdown,
      sorter: sorter('phone'),
      onFilter: onFilter('phone'),
    },
    {
      title: 'Category',
      dataIndex: 'category',
      key: 'category',
      filterDropdown,
      sorter: sorter('category'),
      onFilter: onFilter('category'),
    },
    {
      title: 'Age',
      dataIndex: 'age',
      key: 'age',
      filterDropdown,
      sorter: sorter('age'),
      onFilter: onFilter('age'),
    },
    {
      title: 'Location',
      dataIndex: 'location',
      key: 'location',
      filterDropdown,
      sorter: sorter('location'),
      onFilter: onFilter('location'),
    },
  ];

  return (
    <Structure>
      <>
        <Heading text="Patient List" />
        <Table
          columns={columns}
          bordered
          rowKey={record => record.id}
          pagination={{ defaultPageSize: 50, position: ['bottomLeft'] }}
          dataSource={patientList}
          size="small"
          scroll={{ x: 1050, y: window.innerHeight - 300 }}
        />
      </>
    </Structure>
  );
};

export default PatientList;

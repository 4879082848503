import React, { useEffect, useState } from 'react';
import { useSelector, useDispatch, connect } from 'react-redux';
import withStyles from 'react-jss';
import PropTypes from 'prop-types';
import { Table, Popconfirm, Tooltip, Button, Input, Modal } from 'antd';
import { EditOutlined, DeleteOutlined } from '@ant-design/icons';
import { reduxForm, Field, getFormValues, reset } from 'redux-form';
import Structure from '../Structure/Structure';
import { sorter, onFilter, filterDropdown, get } from '../utils';
import Api from '../Api';
import { getPaymentCategoryList, clearPaymentCategoryList, getPaymentCategory, clearPaymentCategory } from '../Actions';
import { errorNotification, successNotification } from '../Snackbar';
import styles from '../CommonStyles';
import Heading from '../Components/Heading';
import GetField from '../Components/GetField';

const AntInput = GetField(Input);

const PaymentCategory = props => {
  const { classes } = props;
  const dispatch = useDispatch();

  const auth = useSelector(state => state.auth);
  const permissions = get(auth, ['permissions'], []);
  const paymentCategoryList = useSelector(state => state.paymentCategoryList);

  const [dropdownModal, setDropdownModal] = useState(false);
  const [confirmLoading, setConfirmLoading] = useState(false);
  const [editDropdownId, setEditDropdownId] = useState(null);

  const FormValues = useSelector(state => getFormValues('PaymentCategoryForm')(state));

  useEffect(() => {
    dispatch(getPaymentCategoryList());
    return () => {
      dispatch(clearPaymentCategoryList());
    };
  }, []);

  const onDelete = async id => {
    try {
      await Api.delete(`/dropdowns/payment-category/${id}`);
      successNotification('Payment category deleted');
      dispatch(getPaymentCategoryList());
    } catch (err) {
      errorNotification(err);
    }
  };

  const showDropdownModal = () => {
    setEditDropdownId(null);
    setDropdownModal(true);
  };

  const showEditDropdownModal = id => {
    setEditDropdownId(id);
    dispatch(getPaymentCategory(id));
    setDropdownModal(true);
  };

  const hideDropdownModal = () => {
    dispatch(reset('PaymentCategoryForm'));
    setConfirmLoading(false);
    setEditDropdownId(null);
    dispatch(clearPaymentCategory());
    setDropdownModal(false);
  };

  const onNew = async () => {
    try {
      setConfirmLoading(true);
      await Api.post('/dropdowns/payment-category', FormValues);
      dispatch(reset('PaymentCategoryForm'));
      setConfirmLoading(false);
      setDropdownModal(false);
      successNotification('Category added');
      dispatch(clearPaymentCategory());
      dispatch(getPaymentCategoryList());
    } catch (err) {
      setConfirmLoading(false);
      errorNotification(err);
    }
  };

  const onEdit = async () => {
    try {
      setConfirmLoading(true);
      await Api.put(`/dropdowns/payment-category/${editDropdownId}`, FormValues);
      dispatch(reset('PaymentCategoryForm'));
      setConfirmLoading(false);
      setDropdownModal(false);
      successNotification('Category updated');
      dispatch(clearPaymentCategory());
      dispatch(getPaymentCategoryList());
    } catch (err) {
      setConfirmLoading(false);
      errorNotification(err);
    }
  };

  const columns = [
    {
      title: 'Category',
      dataIndex: 'category',
      sorter: sorter('category'),
      filterDropdown,
      onFilter: onFilter('category'),
    },
  ];

  const renderActionIcons = record => (
    <div>
      {permissions.includes('payment_category') ? (
        <>
          <Tooltip placement="bottom" title="Edit">
            <EditOutlined className={classes.tableIcon} onClick={() => showEditDropdownModal(record.id)} />
          </Tooltip>
          &nbsp;&nbsp;&nbsp;&nbsp;
        </>
      ) : null}
      {permissions.includes('payment_category') ? (
        <>
          <Tooltip placement="bottom" title="Delete">
            <Popconfirm
              title="Are you sure you want to delete?"
              onConfirm={() => onDelete(record.id)}
              okText="Yes"
              cancelText="No"
            >
              <DeleteOutlined className={classes.tableIcon} />
            </Popconfirm>
          </Tooltip>
        </>
      ) : null}
    </div>
  );

  if (permissions.includes('payment_category') || permissions.includes('payment_category')) {
    columns.push({
      title: 'Actions',
      width: 70,
      render: renderActionIcons,
    });
  }

  return (
    <Structure>
      <>
        <Heading text="Category List">
          {permissions.includes('payment_category') ? (
            <Button className={classes.button} onClick={showDropdownModal}>
              New Payment Category
            </Button>
          ) : null}
        </Heading>
        <Table
          columns={columns}
          bordered
          rowKey={record => record.id}
          pagination={{ defaultPageSize: 20, position: ['bottomLeft'], showTotal: total => `Total ${total} Items` }}
          dataSource={paymentCategoryList}
          size="small"
          scroll={{ x: 1050, y: window.innerHeight - 300 }}
        />
        <Modal
          confirmLoading={confirmLoading}
          open={dropdownModal}
          onCancel={hideDropdownModal}
          centered
          maskClosable={false}
          title={editDropdownId ? 'Edit Payment Category.' : 'New Payment Category.'}
          closable={false}
          width="400px"
          cancelText="Back"
          okText={
            editDropdownId ? (
              <Popconfirm
                title="Sure want to submit form?"
                onConfirm={() => onEdit()}
                okText="Confirm"
                cancelText="Cancel"
              >
                Submit
              </Popconfirm>
            ) : (
              <Popconfirm
                title="Sure want to submit form?"
                onConfirm={() => onNew()}
                okText="Confirm"
                cancelText="Cancel"
              >
                Submit
              </Popconfirm>
            )
          }
          okButtonProps={{ className: classes.button }}
        >
          <Field required allowClear name="category" placeholder="Category" component={AntInput} />
        </Modal>
      </>
    </Structure>
  );
};

PaymentCategory.propTypes = {
  classes: PropTypes.object.isRequired,
};

const StyledPaymentCategory = withStyles(styles)(PaymentCategory);

const FormedPaymentCategory = reduxForm({
  form: 'PaymentCategoryForm',
  enableReinitialize: true,
})(StyledPaymentCategory);

export default connect(({ paymentCategory }) => ({ initialValues: paymentCategory }), {})(FormedPaymentCategory);

import Api from '../Api';

export const getPurchaseList = query => async dispatch => {
  try {
    const { data } = await Api.get('/purchases', query);
    dispatch({
      type: 'FETCH_PURCHASE_LIST',
      payload: data,
    });
  } catch (err) {
    throw err;
  }
};

export const clearPurchaseList = () => {
  return {
    type: 'CLEAR_PURCHASE_LIST',
  };
};

export const getPurchaseFilesList = id => async dispatch => {
  try {
    const { data } = await Api.get(`/purchases/${id}/files`);
    dispatch({
      type: 'FETCH_PURCHASE_FILES_LIST',
      payload: data,
    });
  } catch (err) {
    throw err;
  }
};

export const clearPurchaseFilesList = () => {
  return {
    type: 'CLEAR_PURCHASE_FILES_LIST',
  };
};

export const getPurchase = id => async dispatch => {
  try {
    const { data } = await Api.get(`/purchases/${id}`);
    dispatch({
      type: 'FETCH_PURCHASE',
      payload: data,
    });
  } catch (err) {
    throw err;
  }
};

export const clearPurchase = () => {
  return {
    type: 'CLEAR_PURCHASE',
  };
};

import Api from '../../Api';

export const getSectionList = () => async dispatch => {
  try {
    const { data } = await Api.get('/dropdowns/formulation-sections');
    dispatch({
      type: 'FETCH_SECTION_LIST',
      payload: data,
    });
  } catch (err) {
    throw err;
  }
};

export const clearSectionList = () => {
  return {
    type: 'CLEAR_SECTION_LIST',
  };
};

export const getSection = id => async dispatch => {
  try {
    const { data } = await Api.get(`/dropdowns/formulation-sections/${id}`);
    dispatch({
      type: 'FETCH_SECTION',
      payload: data,
    });
  } catch (err) {
    throw err;
  }
};

export const clearSection = () => {
  return {
    type: 'CLEAR_SECTION',
  };
};

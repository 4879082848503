import React, { useState, useCallback, useEffect } from 'react';
import PropTypes from 'prop-types';
import { Controller } from 'react-hook-form';
import { DeleteOutlined } from '@ant-design/icons';
import { Input, Select } from 'antd';
import withStyles from 'react-jss';
import { useSelector } from 'react-redux';

import styles from '../../CommonStyles';
import GetField from '../../Components/GetRHFField';

const AntInput = GetField(Input);
const AntSelect = GetField(Select);
const { Option } = Select;

const ProductTable = props => {
  const { classes, getValues, setValue, control, productItem, productIndex, productRemove } = props;

  const hsnIds = useSelector(state => state.hsnCodeList);
  const unitIds = useSelector(state => state.unitIdList);

  const [hsnIndexesObject, setHSNIndexesObject] = useState({});

  useEffect(() => {
    const _hsnIndexes = {};

    hsnIds.map(hsn => {
      _hsnIndexes[+hsn.id] = hsn;
    });

    setHSNIndexesObject(_hsnIndexes);
  }, [hsnIds]);

  const calcProdTotal = useCallback(() => {
    const product = getValues(`products[${productIndex}].product`);

    if (product) {
      const quantity = getValues(`products[${productIndex}].quantity`) || 0;

      const rate = getValues(`products[${productIndex}].rate`) || 0;

      const tax = getValues(`products[${productIndex}].tax`) || 0;

      const value = +(+quantity * +rate).toFixed(2) || 0;

      const taxValue = +((+value * +tax) / 100).toFixed(2) || 0;

      const total = +(+value + +taxValue).toFixed(2) || 0;

      setValue(`products[${productIndex}].value`, value);

      setValue(`products[${productIndex}].taxValue`, taxValue);

      setValue(`products[${productIndex}].total`, total);
    }
  }, []);

  const calcGrandTotal = useCallback(() => {
    const productsArray = getValues('products');
    const chargesArray = getValues('charges');

    let productTotal = 0;
    let chargeTotal = 0;

    if (productsArray.length) {
      productTotal = (productsArray || []).reduce((acc, { total }) => acc + +total, 0);

      setValue('productsTotal', +productTotal.toFixed(2));
    }

    if (chargesArray.length) {
      chargeTotal = (chargesArray || []).reduce((acc, { total }) => acc + +total, 0);

      setValue('chargesTotal', +chargeTotal.toFixed(2));
    }

    const grandTotal = +(+productTotal + +chargeTotal) || 0;

    setValue('grandTotal', Math.round(grandTotal));
  }, []);

  return (
    <tr>
      <td
        className={classes.inputTd}
        style={{
          textAlign: 'center',
        }}
      >
        <DeleteOutlined
          onClick={() => {
            const productsArr = getValues('products');

            if (productsArr && productsArr.length > 1 && productIndex !== 0) {
              productRemove(productIndex);
            }

            calcGrandTotal();
          }}
        />
      </td>
      <td className={classes.inputTd}>
        <Controller
          name={`products[${productIndex}].product`}
          defaultValue={productItem.product}
          control={control}
          render={({ field }) => <AntInput {...field} />}
        />
      </td>
      <td className={classes.inputTd}>
        <Controller
          name={`products[${productIndex}].packSize`}
          defaultValue={productItem.packSize}
          control={control}
          render={({ field }) => <AntInput {...field} />}
        />
      </td>
      <td className={classes.inputTd}>
        <Controller
          name={`products[${productIndex}].unitId`}
          defaultValue={productItem.unitId}
          control={control}
          render={({ field }) => (
            <AntSelect placeholder="Select unit" {...field}>
              {unitIds.map(unit => (
                <Option key={unit.id} value={unit.id}>
                  {unit.unit}
                </Option>
              ))}
            </AntSelect>
          )}
        />
      </td>
      <td className={classes.inputTd}>
        <Controller
          name={`products[${productIndex}].hsnId`}
          defaultValue={productItem.hsnId}
          control={control}
          render={({ field }) => (
            <AntSelect
              placeholder="Select hsn"
              {...field}
              onChange={value => {
                field.onChange(value);

                if (value) {
                  const hsn = hsnIndexesObject[value];

                  setValue(`products[${productIndex}].tax`, hsn.gst);

                  calcProdTotal();
                  calcGrandTotal();
                }
              }}
            >
              {hsnIds.map(hsn => (
                <Option key={hsn.id} value={hsn.id}>
                  {hsn.hsn}
                </Option>
              ))}
            </AntSelect>
          )}
        />
      </td>
      <td className={classes.inputTd}>
        <Controller
          name={`products[${productIndex}].quantity`}
          defaultValue={productItem.quantity}
          control={control}
          render={({ field }) => (
            <AntInput
              type="number"
              min={0}
              {...field}
              onChange={value => {
                field.onChange(value);
                calcProdTotal();
                calcGrandTotal();
              }}
            />
          )}
        />
      </td>
      <td className={classes.inputTd}>
        <Controller
          name={`products[${productIndex}].rate`}
          defaultValue={productItem.rate}
          control={control}
          render={({ field }) => (
            <AntInput
              type="number"
              min={0}
              {...field}
              onChange={value => {
                field.onChange(value);
                calcProdTotal();
                calcGrandTotal();
              }}
            />
          )}
        />
      </td>
      <td className={classes.inputTd}>
        <Controller
          name={`products[${productIndex}].value`}
          defaultValue={productItem.value}
          control={control}
          render={({ field }) => <AntInput type="number" min={0} disabled {...field} />}
        />
      </td>
      <td className={classes.inputTd}>
        <Controller
          name={`products[${productIndex}].tax`}
          defaultValue={productItem.tax}
          control={control}
          render={({ field }) => <AntInput {...field} disabled />}
        />
      </td>
      <td className={classes.inputTd}>
        <Controller
          name={`products[${productIndex}].taxValue`}
          defaultValue={productItem.taxValue}
          control={control}
          render={({ field }) => <AntInput type="number" min={0} disabled {...field} />}
        />
      </td>
      <td className={classes.inputTd}>
        <Controller
          name={`products[${productIndex}].total`}
          defaultValue={productItem.total}
          control={control}
          render={({ field }) => <AntInput type="number" min={0} disabled {...field} />}
        />
      </td>
    </tr>
  );
};

ProductTable.propTypes = {
  classes: PropTypes.object.isRequired,
  getValues: PropTypes.func.isRequired,
  setValue: PropTypes.func.isRequired,
  control: PropTypes.any.isRequired,
  watch: PropTypes.any.isRequired,
  productItem: PropTypes.object.isRequired,
  productIndex: PropTypes.number.isRequired,
  productRemove: PropTypes.func.isRequired,
  setProductList: PropTypes.any,
};

export default withStyles(styles)(ProductTable);

import React, { useEffect, useMemo } from 'react';
import PropTypes from 'prop-types';
import { Descriptions, Row, Col, Table } from 'antd';
import { useDispatch, useSelector } from 'react-redux';
import moment from 'moment';

import { getJobWork, clearJobWork } from '../Actions';
import Structure from '../Structure/Structure';

const ViewJobWork = props => {
  const { match } = props;
  const dispatch = useDispatch();

  const detail = useSelector(state => state.jobwork);

  useEffect(() => {
    dispatch(getJobWork(match.params.id));
    return () => {
      dispatch(clearJobWork());
    };
  }, []);

  const productColumns = useMemo(
    () => [
      {
        title: 'Order #',
        dataIndex: 'orderId',
        width: '10%',
      },
      {
        title: 'Formulation',
        dataIndex: 'formulation',
      },
      {
        title: 'Batch',
        dataIndex: 'batch',
        width: '5%',
      },
      {
        title: 'Unit',
        dataIndex: 'unit',
        width: '5%',
      },
      {
        title: 'HSN',
        dataIndex: 'hsn',
        width: '8%',
      },
      {
        title: 'Quantity',
        dataIndex: 'quantity',
        width: '8%',
      },
      {
        title: 'Rate',
        dataIndex: 'rate',
        render: text => Number(text).toLocaleString('en-IN'),
        width: '8%',
      },
      {
        title: 'Value',
        dataIndex: 'value',
        render: text => Number(text).toLocaleString('en-IN'),
        width: '8%',
      },
      {
        title: 'Tax (%)',
        dataIndex: 'tax',
        width: '8%',
      },
      {
        title: 'Tax Value',
        dataIndex: 'taxValue',
        render: text => Number(text).toLocaleString('en-IN'),
        width: '8%',
      },
      {
        title: 'Total',
        dataIndex: 'total',
        render: text => Number(text).toLocaleString('en-IN'),
        width: '8%',
      },
    ],
    []
  );

  const chargeColoums = useMemo(() => [
    {
      title: 'Charge',
      dataIndex: 'charge',
    },
    {
      title: 'HSN',
      dataIndex: 'hsn',
      width: '8%',
    },
    {
      title: 'Charge',
      dataIndex: 'value',
      render: text => Number(text).toLocaleString('en-IN'),
      width: '8%',
    },
    {
      title: 'Tax (%)',
      dataIndex: 'tax',
      width: '8%',
    },
    {
      title: 'Tax Value',
      dataIndex: 'taxValue',
      render: text => Number(text).toLocaleString('en-IN'),
      width: '8%',
    },
    {
      title: 'Total',
      dataIndex: 'total',
      render: text => Number(text).toLocaleString('en-IN'),
      width: '8%',
    },
  ]);

  const rowKey = useMemo(() => record => record.id, []);

  const scroll = useMemo(() => ({ x: 1024, y: window.innerHeight - 300 }), []);

  return (
    <Structure>
      <>
        <Row>
          <Col
            sm={{ span: 22, offset: 1 }}
            md={{ span: 22, offset: 1 }}
            lg={{ span: 20, offset: 2 }}
            xl={{ span: 16, offset: 4 }}
          >
            <Descriptions column={{ xs: 1, sm: 1, md: 1, lg: 2, xl: 2, xxl: 2 }} size="small" bordered>
              <Descriptions.Item label="JW #">{detail.jwNo}</Descriptions.Item>
              <Descriptions.Item label="Date">{moment(detail.jwDate).format('DD MMM YYYY')}</Descriptions.Item>
              <Descriptions.Item label="Party">{detail.party}</Descriptions.Item>
              <Descriptions.Item label="Grand Total">{Number(detail.total).toLocaleString('en-IN')}</Descriptions.Item>
              <Descriptions.Item label="Remark">{detail.remark}</Descriptions.Item>
            </Descriptions>
          </Col>
        </Row>
        {detail?.products?.length ? (
          <>
            <br />
            <Row>
              <Col
                sm={{ span: 22, offset: 1 }}
                md={{ span: 22, offset: 1 }}
                lg={{ span: 22, offset: 1 }}
                xl={{ span: 22, offset: 1 }}
              >
                <Table
                  bordered
                  size="small"
                  columns={productColumns}
                  dataSource={detail.products || []}
                  rowKey={rowKey}
                  pagination={false}
                  scroll={scroll}
                  title={() => 'Products'}
                />
              </Col>
            </Row>
            <br />
          </>
        ) : null}
        {detail?.charges?.length ? (
          <>
            <Row>
              <Col
                sm={{ span: 22, offset: 1 }}
                md={{ span: 22, offset: 1 }}
                lg={{ span: 22, offset: 1 }}
                xl={{ span: 22, offset: 1 }}
              >
                <Table
                  bordered
                  size="small"
                  columns={chargeColoums}
                  dataSource={detail.charges || []}
                  rowKey={rowKey}
                  pagination={false}
                  scroll={scroll}
                  title={() => 'Charges'}
                />
              </Col>
            </Row>
            <br />
          </>
        ) : null}
      </>
    </Structure>
  );
};

ViewJobWork.propTypes = {
  match: PropTypes.object.isRequired,
};

export default ViewJobWork;

import React from 'react';
import { Input, Select } from 'antd';
import withStyles from 'react-jss';
import PropTypes from 'prop-types';
import { Controller } from 'react-hook-form';
import { DeleteOutlined } from '@ant-design/icons';
import { useSelector } from 'react-redux';
import startCase from 'lodash/startCase';
import styles from '../CommonStyles';

import GetRHFField from '../Components/GetRHFField';

const AntInput = GetRHFField(Input);
const AntSelect = GetRHFField(Select);
const { Option } = Select;

const MaterialTable = props => {
  const { classes, control, getValues, watch, item, index, remove } = props;

  const materialIdList = useSelector(state => state.materialIdList);

  return (
    <tr>
      <td className={classes.inputTd}>
        <Controller
          name={`recipe[${index}].materialId`}
          defaultValue={item.materialId}
          control={control}
          render={({ field }) => (
            <AntSelect placeholder="Select material" {...field}>
              {materialIdList.map(material => (
                <Option key={material.id} value={material.id}>
                  {material.name}
                </Option>
              ))}
            </AntSelect>
          )}
        />
      </td>
      <td className={classes.inputTd}>
        <Controller
          name={`recipe[${index}].type`}
          defaultValue={item.type}
          control={control}
          render={({ field }) => (
            <AntSelect
              placeholder="Select type"
              disabled
              {...{
                ...field,
                value: watch(`recipe[${index}].materialId`),
              }}
            >
              {materialIdList.map(material => (
                <Option key={material.id} value={material.id}>
                  {startCase(material.type)}
                </Option>
              ))}
            </AntSelect>
          )}
        />
      </td>
      <td className={classes.inputTd}>
        <Controller
          name={`recipe[${index}].unit`}
          defaultValue={item.unit}
          control={control}
          render={({ field }) => (
            <AntSelect
              placeholder="Select unit"
              disabled
              {...{
                ...field,
                value: watch(`recipe[${index}].materialId`),
              }}
            >
              {materialIdList.map(material => (
                <Option key={material.id} value={material.id}>
                  {material.unit}
                </Option>
              ))}
            </AntSelect>
          )}
        />
      </td>
      <td className={classes.inputTd}>
        <Controller
          name={`recipe[${index}].quantity`}
          defaultValue={item.quantity}
          control={control}
          render={({ field }) => <AntInput type="number" min={0} {...field} />}
        />
      </td>
      <td
        className={classes.inputTd}
        style={{
          textAlign: 'center',
        }}
      >
        <DeleteOutlined
          className={classes.tableIcon}
          onClick={() => {
            const materialsArr = getValues('recipe');

            if (materialsArr && materialsArr.length > 1 && index > 0) {
              remove(index);
            }
          }}
        />
      </td>
    </tr>
  );
};

MaterialTable.propTypes = {
  classes: PropTypes.object.isRequired,
  getValues: PropTypes.func.isRequired,
  control: PropTypes.any.isRequired,
  watch: PropTypes.func.isRequired,
  item: PropTypes.object.isRequired,
  index: PropTypes.number.isRequired,
  remove: PropTypes.func.isRequired,
};

export default withStyles(styles)(MaterialTable);

import Api from '../Api';

export const getBatchSeriesList = () => async dispatch => {
  try {
    const { data } = await Api.get('/dropdowns/batch-series');
    dispatch({
      type: 'FETCH_BATCH_SERIES_LIST',
      payload: data,
    });
  } catch (err) {
    throw err;
  }
};

export const clearBatchSeriesList = () => {
  return {
    type: 'CLEAR_BATCH_SERIES_LIST',
  };
};

export const getBatchSeries = id => async dispatch => {
  try {
    const { data } = await Api.get(`/dropdowns/batch-series/${id}`);
    dispatch({
      type: 'FETCH_BATCH_SERIES',
      payload: data,
    });
  } catch (err) {
    throw err;
  }
};

export const clearBatchSeries = () => {
  return {
    type: 'CLEAR_BATCH_SERIES',
  };
};

import React, { useState } from 'react';
import PropTypes from 'prop-types';
import withStyles from 'react-jss';
import { Input, Popconfirm } from 'antd';
import Modal from 'antd/lib/modal/Modal';
import SunEditor from 'suneditor-react';
import '../Marketing/suneditor.css';
import { Field, getFormValues, reduxForm } from 'redux-form';
import { useSelector } from 'react-redux';
import { errorNotification, successNotification } from '../Snackbar';
import Api from '../Api';
import styles from '../CommonStyles';
import GetField from '../Components/GetField';

const AntInput = GetField(Input);

const ComplaintResolve = props => {
  const { viewModal, complaintId, hideModal, classes, handleSubmit } = props;

  const FormValues = useSelector(state => getFormValues('ComplaintResolve')(state));

  const [confirmLoading, setConfirmLoading] = useState(false);
  const [editorContent, setEditorContent] = useState(null);

  const handleChange = content => {
    setEditorContent(content);
  };

  const onSubmit = async () => {
    try {
      setConfirmLoading(true);
      await Api.post(`/customer-complaints/resolve/${complaintId}`, {
        subject: FormValues.subject,
        html: editorContent,
      });
      successNotification('Response sent');
      setConfirmLoading(false);
      setEditorContent(null);
      hideModal();
    } catch (err) {
      setConfirmLoading(false);
      errorNotification(err);
    }
  };

  return (
    <Modal
      open={viewModal}
      onCancel={hideModal}
      confirmLoading={confirmLoading}
      centered
      maskClosable={false}
      title="Send Response."
      width="800px"
      closable={false}
      cancelText="Cancel"
      okText={
        <Popconfirm title="Sure want to submit form?" onConfirm={() => onSubmit()} okText="Confirm" cancelText="Cancel">
          Submit
        </Popconfirm>
      }
      okButtonProps={{ className: classes.button }}
    >
      <form onSubmit={handleSubmit(onSubmit)}>
        <Field
          required
          allowClear
          name="subject"
          placeholder="Subject"
          component={AntInput}
          style={{ width: '100%' }}
        />
        <SunEditor name="html" width="100%" height="100" placeholder="Type response here...." onChange={handleChange} />
      </form>
    </Modal>
  );
};

ComplaintResolve.propTypes = {
  viewModal: PropTypes.bool,
  hideModal: PropTypes.func,
  complaintId: PropTypes.number,
  classes: PropTypes.object.isRequired,
  handleSubmit: PropTypes.func.isRequired,
};

const StyledComplaintResolve = withStyles(styles)(ComplaintResolve);

const FormedComplaintResolve = reduxForm({
  form: 'ComplaintResolve',
})(StyledComplaintResolve);

export default FormedComplaintResolve;

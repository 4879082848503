import React, { useEffect, useCallback, useState } from 'react';
import PropTypes from 'prop-types';
import withStyles from 'react-jss';
import { useHistory } from 'react-router';
import { useForm, Controller, useFieldArray } from 'react-hook-form';
import { Button, Select, Row, Input } from 'antd';
import { useDispatch, useSelector } from 'react-redux';

import LeftGrid from '../Components/LeftGrid';
import RightGrid from '../Components/RightGrid';
import GetField from '../Components/GetRHFField';
import styles from '../CommonStyles';

import { successNotification, errorNotification } from '../Snackbar';
import Api from '../Api';
import Structure from '../Structure/Structure';
import Heading from '../Components/Heading';
import constants from '../constants';

import ProductTable from './ProductTable';
import ChargeTable from './ChargeTable';
import {
  clearSupplierIdList,
  getSupplierIdList,
  getCompletedOrdersIds,
  clearCompletedOrdersIds,
  getChargeIds,
  clearChargeIds,
} from '../Actions';

const AntSelect = GetField(Select);
const AntInputTextArea = GetField(Input.TextArea);

const AddJobWork = props => {
  const { classes } = props;
  const history = useHistory();

  const dispatch = useDispatch();

  const { handleSubmit, control } = useForm({
    shouldUnregister: true,
    defaultValues: {
      products: [
        {
          orderId: null,
          quantity: null,
          rate: null,
        },
      ],
      charges: [
        {
          chargeId: null,
          value: null,
        },
      ],
    },
  });

  const { fields, append, remove } = useFieldArray({
    control,
    name: 'products',
  });

  const {
    fields: chargeFields,
    append: chargeAppend,
    remove: chargeRemove,
  } = useFieldArray({
    control,
    name: 'charges',
  });

  const [confirmLoading, setConfirmLoading] = useState(false);

  const partyIds = useSelector(state => state.supplierIdList);

  useEffect(() => {
    dispatch(getSupplierIdList({ status: 'Active' }));
    dispatch(getChargeIds());
    dispatch(getCompletedOrdersIds());

    return () => {
      dispatch(clearSupplierIdList());
      dispatch(clearChargeIds());
      dispatch(clearCompletedOrdersIds());
    };
  }, []);

  const onSubmit = useCallback(async formValues => {
    try {
      setConfirmLoading(true);
      await Api.post('/jobworks', formValues);
      successNotification('Job work added');
      history.push('/jobworks/list');
    } catch (err) {
      setConfirmLoading(false);
      errorNotification(err);
    }
  }, []);

  return (
    <Structure>
      <Heading text="New Job Work" />
      <form onSubmit={handleSubmit(onSubmit)}>
        <Row>
          <LeftGrid>
            <Controller
              name="consignor"
              control={control}
              render={({ field }) => (
                <AntSelect allowClear label="Consignor" placeholder="Select consignor" {...field}>
                  {Object.values(constants.consignors.list).map(consignor => (
                    <Select.Option key={consignor} value={consignor}>
                      {consignor}
                    </Select.Option>
                  ))}
                </AntSelect>
              )}
            />
          </LeftGrid>
          <RightGrid>
            <Controller
              name="partyId"
              control={control}
              render={({ field }) => (
                <AntSelect allowClear label="Party" placeholder="Select party" {...field}>
                  {partyIds.map(party => (
                    <Select.Option key={party.id} value={party.id}>
                      {party.name}
                    </Select.Option>
                  ))}
                </AntSelect>
              )}
            />
          </RightGrid>
        </Row>
        <Row>
          <LeftGrid>
            <Controller
              name="remark"
              control={control}
              render={({ field }) => (
                <AntInputTextArea
                  allowClear
                  label="Remark"
                  placeholder="Remark"
                  {...field}
                  autoSize={{ minRows: 1, maxRows: 6 }}
                />
              )}
            />
          </LeftGrid>
        </Row>

        <Row className={classes.padding5px}>
          <div style={{ overflow: 'auto' }}>
            <div style={{ minWidth: '1600px' }}>
              <table className={classes.inputTable}>
                <thead className={classes.inputThead}>
                  <tr key="header">
                    <th className={classes.inputTh} style={{ width: 80 }}>
                      Remove
                    </th>
                    <th className={classes.inputTh} style={{ width: '10%' }}>
                      Order
                    </th>
                    <th className={classes.inputTh}>Product</th>
                    <th className={classes.inputTh} style={{ width: '8%' }}>
                      Batch
                    </th>
                    <th className={classes.inputTh} style={{ width: '6%' }}>
                      Unit
                    </th>
                    <th className={classes.inputTh} style={{ width: '7%' }}>
                      Quantity
                    </th>
                    <th className={classes.inputTh} style={{ width: '7%' }}>
                      Rate
                    </th>
                    <th className={classes.inputTh} style={{ width: '6%' }}>
                      Discount value
                    </th>
                    <th className={classes.inputTh} style={{ width: '7%' }}>
                      HSN
                    </th>
                    <th className={classes.inputTh} style={{ width: '7%' }}>
                      Tax (in %)
                    </th>
                  </tr>
                </thead>
                <tbody>
                  {fields.map((item, index) => (
                    <ProductTable key={item.id} item={item} index={index} remove={remove} control={control} />
                  ))}
                </tbody>
              </table>
            </div>
          </div>
        </Row>
        <br />
        <Row className={classes.padding5px}>
          <Button
            type="primary"
            htmlType="button"
            onClick={() =>
              append({
                orderId: null,
                quantity: null,
                rate: null,
              })
            }
          >
            Add Product
          </Button>
        </Row>
        <br />
        <Row className={classes.padding5px}>
          <div style={{ overflow: 'auto' }}>
            <div style={{ minWidth: '800px' }}>
              <table className={classes.inputTable}>
                <thead className={classes.inputThead}>
                  <tr key="header">
                    <th className={classes.inputTh} style={{ width: 80 }}>
                      Remove
                    </th>
                    <th className={classes.inputTh}>Charge Name</th>
                    <th className={classes.inputTh} style={{ width: '10%' }}>
                      Value
                    </th>
                    <th className={classes.inputTh} style={{ width: '10%' }}>
                      HSN
                    </th>
                    <th className={classes.inputTh} style={{ width: '10%' }}>
                      Tax (in %)
                    </th>
                  </tr>
                </thead>
                <tbody>
                  {chargeFields.map((item, index) => (
                    <ChargeTable
                      key={item.id}
                      item={item}
                      index={index}
                      chargeRemove={chargeRemove}
                      control={control}
                    />
                  ))}
                </tbody>
              </table>
            </div>
          </div>
        </Row>
        <br />
        <Row className={classes.padding5px}>
          <Button
            type="primary"
            htmlType="button"
            onClick={() =>
              chargeAppend({
                chargeId: null,
                value: null,
              })
            }
          >
            Add Charge
          </Button>
        </Row>
        <Row className={classes.buttonContainer}>
          <Button type="primary" loading={confirmLoading} htmlType="submit">
            Submit
          </Button>
        </Row>
      </form>
    </Structure>
  );
};

AddJobWork.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(AddJobWork);

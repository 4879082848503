import React from 'react';
import { Route, Switch } from 'react-router-dom';

import NewUser from './User/NewUser';
import EditUser from './User/EditUser';
import UserList from './User/UserList';
import NewRole from './Role/NewRole';
import EditRole from './Role/EditRole';
import RoleList from './Role/RoleList';
import NewMaterial from './Material/NewMaterial';
import EditMaterial from './Material/EditMaterial';
import MaterialList from './Material/MaterialList';
import UOMList from './MasterDropdowns/UOM';
import Location from './MasterDropdowns/Location';
import HSNCode from './MasterDropdowns/HSNCode';
import Charges from './MasterDropdowns/Charges';
import BatchSeries from './MasterDropdowns/BatchSeries';
import EmployeeRole from './MasterDropdowns/EmployeeRole';
import NewTransporter from './Transporter/NewTransporter';
import EditTransporter from './Transporter/EditTransporter';
import TransporterList from './Transporter/TransporterList';
import PaymentCategory from './MasterDropdowns/PaymentCategory';
import Category from './MasterDropdowns/formulations_dropdown/Category';
import SubCategory from './MasterDropdowns/formulations_dropdown/SubCategory';
import DosageForm from './MasterDropdowns/formulations_dropdown/DosageForm';
import Section from './MasterDropdowns/formulations_dropdown/Section';
import Generic from './MasterDropdowns/formulations_dropdown/Generic';
import TimeMotion from './MasterDropdowns/formulations_dropdown/TimeMotion';
import NewFormulation from './Formulation/NewFormulation';
import EditFromulation from './Formulation/EditFromulation';
import FormulationList from './Formulation/FormulationList';
import FormulationCosting from './Formulation/FormulationCosting';
import GPReport from './Formulation/GPReport';
import LastSellingProductGP from './Formulation/Last-Selling-Product-GP';
import NewSupplier from './Supplier/NewSupplier';
import EditSupplier from './Supplier/EditSupplier';
import SupplierList from './Supplier/SupplierList';
import SupplierOpeningBalances from './Supplier/OpeningBalances';
import EditSupplierOpeningBalance from './Supplier/EditOpeningBalance';
import NewCustomer from './Customer/NewCustomer';
import EditCustomer from './Customer/EditCustomer';
import CustomerList from './Customer/CustomerList';
import ViewCustomer from './Customer/ViewCustomer';
import CustomerOpeningBalances from './Customer/OpeningBalances';
import EditCustomerOpeningBalance from './Customer/EditOpeningBalance';
import ComplaintList from './Customer/ComplaintList';
import NewOrder from './Order/NewOrder';
import EditOrder from './Order/EditOrder';
import OrderList from './Order/OrderList';
import AuthorizedOrdersList from './Order/AuthorizedOrdersList';
import MaterialInventory from './Inventory/MaterialInventory';
import MaterialTransaction from './Inventory/MaterialTransaction';
import InventoryAdjustment from './Inventory/InventoryAdjustment';
import AdjustedStockList from './Inventory/AdjustedStockList';
import TransferForm from './Inventory/TransferForm';
import StockTransferList from './Inventory/StockTransferList';
import NewPurchaseOrder from './Purchase Order/NewPurchaseOrder';
import EditPurchaseOrder from './Purchase Order/EditPurchaseOrder';
import PurchaseOrderList from './Purchase Order/PurchaseOrderList';
import ItemsToOrder from './Inventory/ItemsToOrder';
import NewPurchase from './Purchase/NewPurchase';
import EditPurchase from './Purchase/EditPurchase';
import PurchaseList from './Purchase/PurchaseList';
import PlanningList from './Order/PlanningList';
import ProductionPlanningList from './Order/ProductionPlanningList';
import Production from './Order/Production';
// import ProductionOrders from './Order/ProductionOrders';
import BatchList from './Batch/BatchList';
import NewSale from './Sale/NewSale';
import EditSale from './Sale/EditSale';
import SaleList from './Sale/SaleList';
import NewMaterialSale from './Material Sale/NewMaterialSale';
import EditMaterialSale from './Material Sale/EditMaterialSale';
import MaterialSaleList from './Material Sale/MaterialSaleList';
import NewSaleReturn from './Sale Return/NewSaleReturn';
import EditSaleReturn from './Sale Return/EditSaleReturn';
import SaleReturnList from './Sale Return/SaleReturnList';
import NewCourier from './Courier/NewCourier';
import EditCourier from './Courier/EditCourier';
import CourierList from './Courier/CourierList';
import Narration from './MasterDropdowns/Narration';
import NewTransaction from './Financial/NewTransaction';
import EditTransaction from './Financial/EditTransaction';
import TransactionList from './Financial/TransactionList';
import TransactionFileUpload from './Financial/TransactionFileUpload';
import TransactionFileList from './Financial/TransactionFileList';
import BankList from './Financial/BankList';
import FinancialSummary from './Financial Reports/FinancialSummary';
import CashFlow from './Financial Reports/CashFlow';
import BalanceSheet from './Financial Reports/BalanceSheet';
import MIS from './Financial Reports/MIS';
import MISForm from './Financial Reports/MISForm';
import Dashboard from './Reports/Dashboard';
import TeamReport from './Reports/TeamReport';
import ProfitCenter from './Reports/ProfitCenter';
import ASPReport from './Reports/ASPReports';
import Calendar from './Calendar/Calendar';
import Emailing from './Marketing/Emailing';
import Messaging from './Marketing/Messaging';
import GeneralFiles from './Marketing/GeneralFiles';
import SupplierMIS from './MIS/SupplierMIS';
import TeamMIS from './MIS/TeamMIS';
import TaskMIS from './MIS/TaskMIS';
import OrdersFMS from './MIS/OrdersFMS';
import OrdersMIS from './MIS/OrderMIS';
import LeadFMS from './MIS/LeadFMS';
import LeadMIS from './MIS/LeadMIS';
import NewEnquiry from './Enquiry/NewEnquiry';
import EditEnquiry from './Enquiry/EditEnquiry';
import EnquiryList from './Enquiry/EnquiryList';
import NewTicket from './Tickets/NewTicket';
import TicketList from './Tickets/TicketList';
import EditTicket from './Tickets/EditTicket';
import NewCreditNote from './CreditNote/NewCreditNote';
import EditCreditNote from './CreditNote/EditCreditNote';
import CreditNoteList from './CreditNote/CreditNoteList';
import NewDebitNote from './DebitNote/NewDebitNote';
import EditDebitNote from './DebitNote/EditDebitNote';
import DebitNoteList from './DebitNote/DebitNoteList';
import NewCreditDebitNote from './CreditDebitNote/NewCreditDebitNote';
import EditCreditDebitNote from './CreditDebitNote/EditCreditDebitNote';
import CreditDebitNoteList from './CreditDebitNote/CreditDebitNoteList';
import NewEmployee from './Employee/NewEmployee';
import EditEmployee from './Employee/EditEmployee';
import EmployeeList from './Employee/EmployeeList';
import OldAttendance from './Attendance/OldAttendance';
import NewAttendance from './Attendance/NewAttendance';
import EditAttendance from './Attendance/EditAttendance';
import AttendanceList from './Attendance/AttendanceList';
import NewLeave from './Leave/NewLeave';
import EditLeave from './Leave/EditLeave';
import LeaveList from './Leave/LeaveList';
import NewHoliday from './Holiday/NewHoliday';
import HolidayList from './Holiday/HolidayList';
import WhatsAppSent from './WhatsApp/Sent';
import WhatsAppReceived from './WhatsApp/Received';
import PatientForm from './Patients/Form';
import PatientList from './Patients/List';
import GPPercentage from './Formulation/GPPercentage';
import FormulationInventory from './FormulationInventory/Inventory';
import FormulationInventoryAdjustmentForm from './FormulationInventory/AdjustmentForm';
import FormulationInventoryAdjustmentList from './FormulationInventory/AdjustmentList';
import AddLead from './CRM/Lead/AddLead';
import EditLead from './CRM/Lead/EditLead';
import LeadList from './CRM/Lead/LeadList';
import LeadDetail from './CRM/Lead/Detail';
import LeadFollowUpList from './CRM/Lead/FollowUpList';
import AddProformaInvoice from './CRM/proforma_invoice/Add';
import EditProformaInvoice from './CRM/proforma_invoice/Edit';
import ProformaInvoiceDetail from './CRM/proforma_invoice/Detail';
import ProformaInvoiceList from './CRM/proforma_invoice/List';
import TaskCalendar from './Tasks/Calendar';
import KRAList from './Tasks/KRA/List';
import TaskExtensions from './Tasks/TaskExtensions';
import WeeklySchedule from './Tasks/WeeklySchedule';
import NewLedgerContact from './Ledger Contacts/New';
import EditLedgerContact from './Ledger Contacts/Edit';
import LedgerContactList from './Ledger Contacts/List';
import NewAsset from './Assets/New';
import EditAsset from './Assets/Edit';
import AssetList from './Assets/List';
import AssetAdjustmentForm from './Assets/AdjustmentForm';
import AssetAdjustmentList from './Assets/AdjustmentList';
import GSTReversal from './Financial Reports/GSTReversal';
import WeeklyCollectionReport from './Financial Reports/WeeklyCollectionReport';

import AddJobWork from './JobWork/Add-Job-Work';
import EditJobWork from './JobWork/Edit-Job-Work';
import JobWorks from './JobWork/Job-Works';
import ViewJobWork from './JobWork/View-Job-Work';

const Routes = () => (
  <Switch>
    <Route path="/" exact component={TaskCalendar} />
    <Route path="/user/new" exact component={NewUser} />
    <Route path="/user/edit/:id" exact component={EditUser} />
    <Route path="/user/list" exact component={UserList} />
    <Route path="/role/new" exact component={NewRole} />
    <Route path="/role/edit/:id" exact component={EditRole} />
    <Route path="/role/list" exact component={RoleList} />
    <Route path="/paymentcategory" exact component={PaymentCategory} />
    <Route path="/location" exact component={Location} />
    <Route path="/uom" exact component={UOMList} />
    <Route path="/hsncode" exact component={HSNCode} />
    <Route path="/charges" exact component={Charges} />
    <Route path="/batch-series" exact component={BatchSeries} />
    <Route path="/employeerole" exact component={EmployeeRole} />
    <Route path="/transporter/new" exact component={NewTransporter} />
    <Route path="/transporter/edit/:id" exact component={EditTransporter} />
    <Route path="/transporter/list" exact component={TransporterList} />
    <Route path="/formulations/category" exact component={Category} />
    <Route path="/formulations/subcategory" exact component={SubCategory} />
    <Route path="/formulations/dosageform" exact component={DosageForm} />
    <Route path="/formulations/section" exact component={Section} />
    <Route path="/formulations/generic" exact component={Generic} />
    <Route path="/formulations/time-motions" exact component={TimeMotion} />
    <Route path="/formulations/new" exact component={NewFormulation} />
    <Route path="/formulations/edit/:id" exact component={EditFromulation} />
    <Route path="/formulations/list" exact component={FormulationList} />
    <Route path="/formulations/costing" exact component={FormulationCosting} />
    <Route path="/formulations/gpreport" exact component={GPReport} />
    <Route path="/formulations/gpreport/lastselling" exact component={LastSellingProductGP} />
    <Route path="/supplier/new" exact component={NewSupplier} />
    <Route path="/supplier/edit/:id" exact component={EditSupplier} />
    <Route path="/supplier/list" exact component={SupplierList} />
    <Route path="/supplier/openingbalances/list" exact component={SupplierOpeningBalances} />
    <Route path="/supplier/openingbalances/edit/:id" exact component={EditSupplierOpeningBalance} />
    <Route path="/customer/new" exact component={NewCustomer} />
    <Route path="/customer/edit/:id" exact component={EditCustomer} />
    <Route path="/customer/view/:id" exact component={ViewCustomer} />
    <Route path="/customer/list" exact component={CustomerList} />
    <Route path="/customer/openingbalances/list" exact component={CustomerOpeningBalances} />
    <Route path="/customer/openingbalances/edit/:id" exact component={EditCustomerOpeningBalance} />
    <Route path="/customer/complaints" exact component={ComplaintList} />
    <Route path="/order/new" exact component={NewOrder} />
    <Route path="/order/edit/:id" exact component={EditOrder} />
    <Route path="/order/list" exact component={OrderList} />
    <Route path="/order/planning" exact component={PlanningList} />
    <Route path="/order/list/authorized" exact component={AuthorizedOrdersList} />
    <Route path="/material/new" exact component={NewMaterial} />
    <Route path="/material/edit/:id" exact component={EditMaterial} />
    <Route path="/material/list" exact component={MaterialList} />
    <Route path="/material/inventory" exact component={MaterialInventory} />
    <Route path="/material/transaction" exact component={MaterialTransaction} />
    <Route path="/material/inventory/adjustment" exact component={InventoryAdjustment} />
    <Route path="/material/inventory/adjustment/list" exact component={AdjustedStockList} />
    <Route path="/material/inventory/transfers" exact component={TransferForm} />
    <Route path="/material/inventory/transfers/list" exact component={StockTransferList} />
    <Route path="/material/itemtoorder" exact component={ItemsToOrder} />
    <Route path="/material/sale/new" exact component={NewMaterialSale} />
    <Route path="/material/sale/edit/:id" exact component={EditMaterialSale} />
    <Route path="/material/sale/list" exact component={MaterialSaleList} />
    <Route path="/purchaseorder/new" exact component={NewPurchaseOrder} />
    <Route path="/purchaseorder/edit/:id" exact component={EditPurchaseOrder} />
    <Route path="/purchaseorder/list" exact component={PurchaseOrderList} />
    <Route path="/purchase/new" exact component={NewPurchase} />
    <Route path="/purchase/edit/:id" exact component={EditPurchase} />
    <Route path="/purchase/list" exact component={PurchaseList} />
    <Route path="/production/planninglist" exact component={ProductionPlanningList} />
    <Route path="/production/orders/copy" exact component={Production} />
    <Route path="/production/orders" exact component={Production} />
    <Route path="/production/batch/list" exact component={BatchList} />
    <Route path="/production/gpPercentage" exact component={GPPercentage} />
    <Route path="/sale/new" exact component={NewSale} />
    <Route path="/sale/edit/:id" exact component={EditSale} />
    <Route path="/sale/list" exact component={SaleList} />
    <Route path="/salereturn/new" exact component={NewSaleReturn} />
    <Route path="/salereturn/edit/:id" exact component={EditSaleReturn} />
    <Route path="/salereturn/list" exact component={SaleReturnList} />
    <Route path="/courier/new" exact component={NewCourier} />
    <Route path="/courier/edit/:id" exact component={EditCourier} />
    <Route path="/courier/list" exact component={CourierList} />
    <Route path="/narration" exact component={Narration} />
    <Route path="/bank/list" exact component={BankList} />
    <Route path="/transaction/new" exact component={NewTransaction} />
    <Route path="/transaction/edit/:id" exact component={EditTransaction} />
    <Route path="/transaction/list" exact component={TransactionList} />
    <Route path="/transaction/fileupload" exact component={TransactionFileUpload} />
    <Route path="/transaction/filelist" exact component={TransactionFileList} />
    <Route path="/finance/report/financialsummary" exact component={FinancialSummary} />
    <Route path="/finance/report/cashflow" exact component={CashFlow} />
    <Route path="/finance/report/balancesheet" exact component={BalanceSheet} />
    <Route path="/finance/report/mis" exact component={MIS} />
    <Route path="/finance/report/mis/form" exact component={MISForm} />
    <Route path="/report/team" exact component={TeamReport} />
    <Route path="/report/profitcenter" exact component={ProfitCenter} />
    <Route path="/report/aspreport" exact component={ASPReport} />
    <Route path="/dashboard" exact component={Dashboard} />
    <Route path="/Calendar" exact component={Calendar} />
    <Route path="/marketing/emailing" exact component={Emailing} />
    <Route path="/marketing/messaging" exact component={Messaging} />
    <Route path="/marketing/files" exact component={GeneralFiles} />
    <Route path="/mis/supplier" exact component={SupplierMIS} />
    <Route path="/mis/team" exact component={TeamMIS} />
    <Route path="/mis/task" exact component={TaskMIS} />
    <Route path="/mis/order" exact component={OrdersMIS} />
    <Route path="/mis/order/fms" exact component={OrdersFMS} />
    <Route path="/mis/lead" exact component={LeadMIS} />
    <Route path="/mis/lead/fms" exact component={LeadFMS} />
    <Route path="/enquiry/new" exact component={NewEnquiry} />
    <Route path="/enquiry/edit/:id" exact component={EditEnquiry} />
    <Route path="/enquiry/list" exact component={EnquiryList} />
    <Route path="/ticket/new" exact component={NewTicket} />
    <Route path="/ticket/edit/:id" exact component={EditTicket} />
    <Route path="/ticket/list" exact component={TicketList} />
    <Route path="/creditnote/new" exact component={NewCreditNote} />
    <Route path="/creditnote/edit/:id" exact component={EditCreditNote} />
    <Route path="/creditnote/list" exact component={CreditNoteList} />
    <Route path="/debitnote/new" exact component={NewDebitNote} />
    <Route path="/debitnote/edit/:id" exact component={EditDebitNote} />
    <Route path="/debitnote/list" exact component={DebitNoteList} />
    <Route path="/creditdebitnote/new" exact component={NewCreditDebitNote} />
    <Route path="/creditdebitnote/edit/:id" exact component={EditCreditDebitNote} />
    <Route path="/creditdebitnote/list" exact component={CreditDebitNoteList} />
    <Route path="/employee/new" exact component={NewEmployee} />
    <Route path="/employee/edit/:id" exact component={EditEmployee} />
    <Route path="/employee/list" exact component={EmployeeList} />
    <Route path="/attendance/old" exact component={OldAttendance} />
    <Route path="/attendance/new" exact component={NewAttendance} />
    <Route path="/attendance/edit/:id" exact component={EditAttendance} />
    <Route path="/attendance/list" exact component={AttendanceList} />
    <Route path="/leave/new" exact component={NewLeave} />
    <Route path="/leave/edit/:id" exact component={EditLeave} />
    <Route path="/leave/list" exact component={LeaveList} />
    <Route path="/holiday/new" exact component={NewHoliday} />
    <Route path="/holiday/list" exact component={HolidayList} />
    <Route path="/whatsapp/sent" exact component={WhatsAppSent} />
    <Route path="/whatsapp/received" exact component={WhatsAppReceived} />
    <Route path="/patients/form" exact component={PatientForm} />
    <Route path="/patients/list" exact component={PatientList} />
    <Route path="/formulations/inventory" exact component={FormulationInventory} />
    <Route path="/formulations/inventory/adjustment" exact component={FormulationInventoryAdjustmentForm} />
    <Route path="/formulations/inventory/adjustment/list" exact component={FormulationInventoryAdjustmentList} />
    <Route path="/leads/add" exact component={AddLead} />
    <Route path="/leads/edit/:id" exact component={EditLead} />
    <Route path="/leads/list" exact component={LeadList} />
    <Route path="/leads/detail/:id" exact component={LeadDetail} />
    <Route path="/leads/followup/list" exact component={LeadFollowUpList} />
    <Route path="/proformainvoices/add" exact component={AddProformaInvoice} />
    <Route path="/proformainvoices/edit/:id" exact component={EditProformaInvoice} />
    <Route path="/proformainvoices/detail/:id" exact component={ProformaInvoiceDetail} />
    <Route path="/proformainvoices/list" exact component={ProformaInvoiceList} />
    {/* <Route path="/tasks/calendar" exact component={TaskCalendar} /> */}
    <Route path="/tasks/kra/list" exact component={KRAList} />
    <Route path="/tasks/schedule/list" exact component={WeeklySchedule} />
    <Route path="/extensions" exact component={TaskExtensions} />
    <Route path="/ledgercontact/new" exact component={NewLedgerContact} />
    <Route path="/ledgercontact/edit/:id" exact component={EditLedgerContact} />
    <Route path="/ledgercontact/list" exact component={LedgerContactList} />
    <Route path="/assets/new" exact component={NewAsset} />
    <Route path="/assets/edit/:id" exact component={EditAsset} />
    <Route path="/assets/list" exact component={AssetList} />
    <Route path="/assets/adjustment" exact component={AssetAdjustmentForm} />
    <Route path="/assets/adjustment/list" exact component={AssetAdjustmentList} />
    <Route path="/gstreversal" exact component={GSTReversal} />
    <Route path="/weekly-collection" exact component={WeeklyCollectionReport} />

    <Route path="/jobworks/new" exact component={AddJobWork} />
    <Route path="/jobworks/list" exact component={JobWorks} />
    <Route path="/jobworks/edit/:id" exact component={EditJobWork} />
    <Route path="/jobworks/view/:id" exact component={ViewJobWork} />
  </Switch>
);

export default Routes;

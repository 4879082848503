import React, { useEffect, Fragment } from 'react';
import { DatePicker, Row, Table } from 'antd';
import { useDispatch, useSelector } from 'react-redux';
import { Field, getFormValues, reduxForm } from 'redux-form';
import moment from 'moment';
import GetField from '../Components/GetField';
import { clearTaskMIS, getTaskMIS } from '../Actions';
import { onFilter, sorter, filterDropdown } from '../utils';
import Structure from '../Structure/Structure';
import Heading from '../Components/Heading';

const AntDatePicker = GetField(DatePicker);

const TaskMIS = () => {
  const dispatch = useDispatch();

  const taskMIS = useSelector(state => state.taskMIS.data);
  const weeks = useSelector(state => state.taskMIS.weeks);

  const FormValues = useSelector(state => getFormValues('TaskMIS')(state));

  useEffect(() => {
    return () => {
      dispatch(clearTaskMIS());
    };
  }, []);

  useEffect(() => {
    if (FormValues && FormValues.month) {
      dispatch(getTaskMIS({ month: moment(FormValues.month).toISOString() }));
    }
  }, [FormValues && FormValues.month]);

  // const kraScore = (record, week) => {
  //   let score = 0;
  //   if (!_.isEmpty(record[week])) {
  //     score =
  //       Math.round(
  //         +(
  //           (+record[week].totalKRATasksNotCompleted + +record[week].totalKRATasksCompletedDelayed) /
  //           +record[week].totalKRATasks
  //         ) * 100
  //       ) || 0;
  //   } else {
  //     score = '';
  //   }

  //   if (score === 0 || score === '') {
  //     return <span style={{ color: 'green' }}>{score}</span>;
  //   }
  //   return <span style={{ color: 'red' }}>{(score *= -1)}</span>;
  // };

  // const taskScore = (record, week) => {
  //   let score = 0;

  //   if (!_.isEmpty(record[week])) {
  //     score =
  //       Math.round(
  //         +(
  //           (+record[week].totalTasksNotCompleted + +record[week].totalTasksCompletedDelayed) /
  //           +record[week].totalTasks
  //         ) * 100
  //       ) || 0;
  //   } else {
  //     score = '';
  //   }

  //   if (score === 0 || score === '') {
  //     return <span style={{ color: 'green' }}>{score}</span>;
  //   }
  //   return <span style={{ color: 'red' }}>{(score *= -1)}</span>;
  // };

  const _columns = [
    {
      title: 'Name',
      dataIndex: 'name',
      sorter: sorter('name'),
      onFilter: onFilter('name'),
      filterDropdown,
      fixed: 'left',
      width: '6%',
    },
    {
      title: `Week ${weeks[0].week || ''} (${weeks[0].start || ''} - ${weeks[0].end || ''})`,
      dataIndex: 'week1',
      children: [
        {
          title: 'KRA',
          children: [
            {
              title: 'Total',
              render: (_text, record) => record.week1.totalKRATasks,
            },
            {
              title: 'Not Done',
              render: (_text, record) =>
                record.week1.totalKRATasksNotCompleted > 0 ? (
                  <span style={{ color: 'red' }}>{record.week1.totalKRATasksNotCompleted}</span>
                ) : (
                  record.week1.totalKRATasksNotCompleted
                ),
            },
            {
              title: 'Delayed',
              render: (_text, record) =>
                record.week1.totalKRATasksCompletedDelayed > 0 ? (
                  <span style={{ color: 'red' }}>{record.week1.totalKRATasksCompletedDelayed}</span>
                ) : (
                  record.week1.totalKRATasksCompletedDelayed
                ),
            },
          ],
        },
        {
          title: 'Task',
          children: [
            {
              title: 'Total',
              render: (_text, record) => record.week1.totalTasks,
            },
            {
              title: 'Not Done',
              render: (_text, record) =>
                record.week1.totalTasksNotCompleted > 0 ? (
                  <span style={{ color: 'red' }}>{record.week1.totalTasksNotCompleted}</span>
                ) : (
                  record.week1.totalTasksNotCompleted
                ),
            },
            {
              title: 'Delayed',
              render: (_text, record) =>
                record.week1.totalTasksCompletedDelayed > 0 ? (
                  <span style={{ color: 'red' }}>{record.week1.totalTasksCompletedDelayed}</span>
                ) : (
                  record.week1.totalTasksCompletedDelayed
                ),
            },
          ],
        },
      ],
    },
    {
      title: `Week ${weeks[1].week || ''} (${weeks[1].start || ''} - ${weeks[1].end || ''})`,
      dataIndex: 'week2',
      children: [
        {
          title: 'KRA',
          children: [
            {
              title: 'Total',
              render: (_text, record) => record.week2.totalKRATasks,
            },
            {
              title: 'Not Done',
              render: (_text, record) =>
                record.week2.totalKRATasksNotCompleted > 0 ? (
                  <span style={{ color: 'red' }}>{record.week2.totalKRATasksNotCompleted}</span>
                ) : (
                  record.week2.totalKRATasksNotCompleted
                ),
            },
            {
              title: 'Delayed',
              render: (_text, record) =>
                record.week2.totalKRATasksCompletedDelayed > 0 ? (
                  <span style={{ color: 'red' }}>{record.week2.totalKRATasksCompletedDelayed}</span>
                ) : (
                  record.week2.totalKRATasksCompletedDelayed
                ),
            },
          ],
        },
        {
          title: 'Task',
          children: [
            {
              title: 'Total',
              render: (_text, record) => record.week2.totalTasks,
            },
            {
              title: 'Not Done',
              render: (_text, record) =>
                record.week2.totalTasksNotCompleted > 0 ? (
                  <span style={{ color: 'red' }}>{record.week2.totalTasksNotCompleted}</span>
                ) : (
                  record.week2.totalTasksNotCompleted
                ),
            },
            {
              title: 'Delayed',
              render: (_text, record) =>
                record.week2.totalTasksCompletedDelayed > 0 ? (
                  <span style={{ color: 'red' }}>{record.week2.totalTasksCompletedDelayed}</span>
                ) : (
                  record.week2.totalTasksCompletedDelayed
                ),
            },
          ],
        },
      ],
    },
    {
      title: `Week ${weeks[2].week || ''} (${weeks[2].start || ''} - ${weeks[2].end || ''})`,
      dataIndex: 'week3',
      children: [
        {
          title: 'KRA',
          children: [
            {
              title: 'Total',
              render: (_text, record) => record.week3.totalKRATasks,
            },
            {
              title: 'Not Done',
              render: (_text, record) =>
                record.week3.totalKRATasksNotCompleted > 0 ? (
                  <span style={{ color: 'red' }}>{record.week3.totalKRATasksNotCompleted}</span>
                ) : (
                  record.week3.totalKRATasksNotCompleted
                ),
            },
            {
              title: 'Delayed',
              render: (_text, record) =>
                record.week3.totalKRATasksCompletedDelayed > 0 ? (
                  <span style={{ color: 'red' }}>{record.week3.totalKRATasksCompletedDelayed}</span>
                ) : (
                  record.week3.totalKRATasksCompletedDelayed
                ),
            },
          ],
        },
        {
          title: 'Task',
          children: [
            {
              title: 'Total',
              render: (_text, record) => record.week3.totalTasks,
            },
            {
              title: 'Not Done',
              render: (_text, record) =>
                record.week3.totalTasksNotCompleted > 0 ? (
                  <span style={{ color: 'red' }}>{record.week3.totalTasksNotCompleted}</span>
                ) : (
                  record.week3.totalTasksNotCompleted
                ),
            },
            {
              title: 'Delayed',
              render: (_text, record) =>
                record.week3.totalTasksCompletedDelayed > 0 ? (
                  <span style={{ color: 'red' }}>{record.week3.totalTasksCompletedDelayed}</span>
                ) : (
                  record.week3.totalTasksCompletedDelayed
                ),
            },
          ],
        },
      ],
    },
    {
      title: `Week ${weeks[3].week || ''} (${weeks[3].start || ''} - ${weeks[3].end || ''})`,
      dataIndex: 'week4',
      children: [
        {
          title: 'KRA',
          children: [
            {
              title: 'Total',
              render: (_text, record) => record.week4.totalKRATasks,
            },
            {
              title: 'Not Done',
              render: (_text, record) =>
                record.week4.totalKRATasksNotCompleted > 0 ? (
                  <span style={{ color: 'red' }}>{record.week4.totalKRATasksNotCompleted}</span>
                ) : (
                  record.week4.totalKRATasksNotCompleted
                ),
            },
            {
              title: 'Delayed',
              render: (_text, record) =>
                record.week4.totalKRATasksCompletedDelayed > 0 ? (
                  <span style={{ color: 'red' }}>{record.week4.totalKRATasksCompletedDelayed}</span>
                ) : (
                  record.week4.totalKRATasksCompletedDelayed
                ),
            },
          ],
        },
        {
          title: 'Task',
          children: [
            {
              title: 'Total',
              render: (_text, record) => record.week4.totalTasks,
            },
            {
              title: 'Not Done',
              render: (_text, record) =>
                record.week4.totalTasksNotCompleted > 0 ? (
                  <span style={{ color: 'red' }}>{record.week4.totalTasksNotCompleted}</span>
                ) : (
                  record.week4.totalTasksNotCompleted
                ),
            },
            {
              title: 'Delayed',
              render: (_text, record) =>
                record.week4.totalTasksCompletedDelayed > 0 ? (
                  <span style={{ color: 'red' }}>{record.week4.totalTasksCompletedDelayed}</span>
                ) : (
                  record.week4.totalTasksCompletedDelayed
                ),
            },
          ],
        },
      ],
    },
    {
      title: `Week ${weeks[4].week || ''} (${weeks[4].start || ''} - ${weeks[4].end || ''})`,
      dataIndex: 'week5',
      children: [
        {
          title: 'KRA',
          children: [
            {
              title: 'Total',
              render: (_text, record) => record.week5.totalKRATasks,
            },
            {
              title: 'Not Done',
              render: (_text, record) =>
                record.week5.totalKRATasksNotCompleted > 0 ? (
                  <span style={{ color: 'red' }}>{record.week5.totalKRATasksNotCompleted}</span>
                ) : (
                  record.week5.totalKRATasksNotCompleted
                ),
            },
            {
              title: 'Delayed',
              render: (_text, record) =>
                record.week5.totalKRATasksCompletedDelayed > 0 ? (
                  <span style={{ color: 'red' }}>{record.week5.totalKRATasksCompletedDelayed}</span>
                ) : (
                  record.week5.totalKRATasksCompletedDelayed
                ),
            },
          ],
        },
        {
          title: 'Task',
          children: [
            {
              title: 'Total',
              render: (_text, record) => record.week5.totalTasks,
            },
            {
              title: 'Not Done',
              render: (_text, record) =>
                record.week5.totalTasksNotCompleted > 0 ? (
                  <span style={{ color: 'red' }}>{record.week5.totalTasksNotCompleted}</span>
                ) : (
                  record.week5.totalTasksNotCompleted
                ),
            },
            {
              title: 'Delayed',
              render: (_text, record) =>
                record.week5.totalTasksCompletedDelayed > 0 ? (
                  <span style={{ color: 'red' }}>{record.week5.totalTasksCompletedDelayed}</span>
                ) : (
                  record.week5.totalTasksCompletedDelayed
                ),
            },
          ],
        },
      ],
    },
  ];

  return (
    <Structure>
      <>
        <Heading text="Task MIS" />
        <Row>
          <Field allowClear required name="month" placeholder="Month" picker="month" component={AntDatePicker} />
        </Row>
        <Table
          columns={_columns}
          bordered
          rowKey={record => record.name}
          dataSource={taskMIS}
          size="small"
          pagination={false}
          scroll={{ x: 2400, y: window.innerHeight - 300 }}
        />
      </>
    </Structure>
  );
};

const FormedTaskMIS = reduxForm({
  form: 'TaskMIS',
  enableReinitialize: true,
})(TaskMIS);

export default FormedTaskMIS;

import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';
import startCase from 'lodash/startCase';
import withStyles from 'react-jss';
import { Table, Tooltip, Popconfirm } from 'antd';
import { DeleteOutlined, EditOutlined } from '@ant-design/icons';
import { Link } from 'react-router-dom';
import { getTransporterList, clearTransporterList } from '../Actions';
import { successNotification, errorNotification } from '../Snackbar';
import Api from '../Api';
import Heading from '../Components/Heading';
import { sorter, onFilter, filterDropdown, get } from '../utils';
import styles from '../CommonStyles';
import Structure from '../Structure/Structure';

const TransporterList = props => {
  const { classes } = props;
  const dispatch = useDispatch();

  const auth = useSelector(state => state.auth);
  const permissions = get(auth, ['permissions'], []);
  const transporterList = useSelector(state => state.transporterList);

  const [rowCounts, setRowCounts] = useState(0);

  useEffect(() => {
    dispatch(getTransporterList());
    return () => {
      dispatch(clearTransporterList());
    };
  }, []);

  useEffect(() => {
    if (transporterList) {
      setRowCounts(transporterList.length);
    }
  }, [transporterList]);

  const onDelete = async id => {
    try {
      await Api.delete(`/transports/${id}`);
      successNotification('Transporter deleted');
      dispatch(getTransporterList());
    } catch (err) {
      errorNotification(err);
    }
  };

  const tooltipShow = arg => (
    <Tooltip placement="topLeft" title={arg}>
      {arg}
    </Tooltip>
  );

  const columns = [
    {
      title: 'Transport Name',
      dataIndex: 'name',
      filterDropdown,
      sorter: sorter('name'),
      onFilter: onFilter('name'),
      render: tooltipShow,
      width: 180,
      fixed: 'left',
    },
    {
      title: 'GST Number',
      dataIndex: 'gstNo',
      filterDropdown,
      sorter: sorter('gstNo'),
      onFilter: onFilter('gstNo'),
      render: tooltipShow,
      width: 160,
    },
    {
      title: 'Type',
      dataIndex: 'type',
      filterDropdown,
      sorter: sorter('type'),
      onFilter: onFilter('type'),
      render: type => startCase(type),
      width: 130,
    },
    {
      title: 'Speciality Area',
      dataIndex: 'specialityArea',
      filterDropdown,
      sorter: sorter('specialityArea'),
      onFilter: onFilter('specialityArea'),
      render: specialityArea => startCase(specialityArea),
      width: 130,
    },
    {
      title: 'Address',
      dataIndex: 'address',
      filterDropdown,
      sorter: sorter('address'),
      onFilter: onFilter('address'),
      ellipsis: { showTitle: false },
      render: tooltipShow,
      width: 170,
    },
    {
      title: 'Contact Person',
      dataIndex: 'contactPerson',
      filterDropdown,
      sorter: sorter('contactPerson'),
      onFilter: onFilter('contactPerson'),
      ellipsis: { showTitle: false },
      render: tooltipShow,
      width: 150,
    },
    {
      title: 'Primary Contact',
      dataIndex: 'primaryContact',
      filterDropdown,
      sorter: sorter('primaryContact'),
      onFilter: onFilter('primaryContact'),
      render: tooltipShow,
      width: 120,
    },
    {
      title: 'Contacts',
      dataIndex: 'contacts',
      filterDropdown,
      sorter: sorter('contacts'),
      onFilter: onFilter('contacts'),
      ellipsis: { showTitle: false },
      render: tooltipShow,
      width: 120,
    },
    {
      title: 'Primary Email',
      dataIndex: 'primaryEmail',
      filterDropdown,
      sorter: sorter('primaryEmail'),
      onFilter: onFilter('primaryEmail'),
      ellipsis: { showTitle: false },
      render: tooltipShow,
      width: 140,
    },
    {
      title: 'Emails',
      dataIndex: 'emails',
      filterDropdown,
      sorter: sorter('emails'),
      onFilter: onFilter('emails'),
      ellipsis: { showTitle: false },
      render: tooltipShow,
      width: 120,
    },
    {
      title: 'Remarks',
      dataIndex: 'remarks',
      filterDropdown,
      sorter: sorter('remarks'),
      onFilter: onFilter('remarks'),
      ellipsis: { showTitle: false },
      render: tooltipShow,
      width: 150,
    },
  ];

  const renderActionIcons = record => (
    <div>
      {permissions.includes('edit_transporter') ? (
        <>
          <Tooltip placement="bottom" title="Edit">
            <Link to={`/transporter/edit/${record.id}`}>
              <EditOutlined className={classes.tableIcon} />
            </Link>
          </Tooltip>
          &nbsp;&nbsp;&nbsp;&nbsp;
        </>
      ) : null}
      {permissions.includes('delete_transporter') ? (
        <>
          <Tooltip placement="bottom" title="Delete">
            <Popconfirm
              title="Are you sure you want to delete?"
              onConfirm={() => onDelete(record.id)}
              okText="Yes"
              cancelText="No"
            >
              <DeleteOutlined className={classes.tableIcon} />
            </Popconfirm>
          </Tooltip>
        </>
      ) : null}
    </div>
  );

  if (permissions.includes('edit_transporter') || permissions.includes('delete_transporter')) {
    columns.push({
      title: 'Actions',
      width: 70,
      // fixed: 'right',
      render: renderActionIcons,
    });
  }

  return (
    <Structure>
      <>
        <Heading text={`Transporter List (${rowCounts})`} />
        <Table
          columns={columns}
          bordered
          rowKey={record => record.id}
          pagination={{ defaultPageSize: 20, position: ['bottomLeft'] }}
          dataSource={transporterList}
          size="small"
          scroll={{ x: 1050, y: window.innerHeight - 300 }}
          onChange={(_page, _filters, _sorter, { currentDataSource }) => {
            setRowCounts((currentDataSource || []).length);
          }}
        />
      </>
    </Structure>
  );
};

TransporterList.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(TransporterList);

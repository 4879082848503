import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { Button, Col, Input, Row } from 'antd';
import withStyles from 'react-jss';
import moment from 'moment';
import { useDispatch, useSelector } from 'react-redux';
import { Field, getFormValues, reduxForm } from 'redux-form';
import styles from '../CommonStyles';
import { clearEmployeeAdvance, getEmployeeAdvance } from '../Actions';
import { get } from '../utils';
import GetField from '../Components/GetField';

const AntInput = GetField(Input);

const Advance = props => {
  const { classes, employeeId, Reference, change } = props;
  const dispatch = useDispatch();

  const employeeAdvance = useSelector(state => state.employeeAdvance);

  const FormValues = useSelector(state => getFormValues('EmployeeAdvanceForm')(state));

  useEffect(() => {
    if (employeeId) {
      dispatch(getEmployeeAdvance(employeeId));
    }
    return () => {
      dispatch(clearEmployeeAdvance());
    };
  }, [employeeId]);

  useEffect(() => {
    if (employeeAdvance) {
      change('advanceRemaining', get(employeeAdvance, ['advanceRemaining'], 0));
      change('salaryPerMinute', Number(get(employeeAdvance, ['salaryPerMinute'], 0)).toFixed(2));
      change('advanceForMonth', 0);
      change('lessDeduction', 0);
      change('salary', get(employeeAdvance, ['salary'], 0));
      change('actualWorkingMinute', Number(get(employeeAdvance, ['actualWorkingMinute'], 0) / 60).toFixed(2));
      change('overtimeMinute', Number(get(employeeAdvance, ['overtimeMinute'], 0) / 60).toFixed(2));
      change('lateMinute', Number(get(employeeAdvance, ['lateMinute'], 0) / 60).toFixed(2));
      change('totalAbsent', get(employeeAdvance, ['totalAbsent'], 0));
      change('salaryToBeGiven', get(employeeAdvance, ['salaryToBeGiven'], 0));
    }
  }, [employeeAdvance]);

  const calculateFields = () => {
    const balanceAdvance =
      Number((FormValues && FormValues.advanceRemaining) || 0) +
      Number((FormValues && FormValues.advanceForMonth) || 0) -
      Number((FormValues && FormValues.lessDeduction) || 0);

    const advanceEligibility = ((Number((FormValues && FormValues.salary) || 0) - +balanceAdvance) / 2).toFixed(2) || 0;

    const salaryPerHr = Number(get(employeeAdvance, ['salaryPerMinute'], 0) * 60).toFixed(2);

    const _workingSalary = (Number((FormValues && FormValues.adjustActualWorking) || 0) * +salaryPerHr).toFixed(2) || 0;

    const _overtimeSalary = (Number((FormValues && FormValues.adjustOvertime) || 0) * +salaryPerHr).toFixed(2) || 0;

    const _lateSalary = (Number((FormValues && FormValues.adjustLate) || 0) * +salaryPerHr).toFixed(2) || 0;

    const _leaveSalary = (Number((FormValues && FormValues.adjustLeave) || 0) * +salaryPerHr * 8.5).toFixed(2) || 0;

    const salaryToBeGiven = (FormValues && FormValues.salaryToBeGiven) || 0;

    const _netSalaryToBePaid = Math.round(
      +salaryToBeGiven + +_workingSalary + +_overtimeSalary + +_lateSalary - +_leaveSalary
    );

    const salaryPayable = Math.round(+_netSalaryToBePaid - Number((FormValues && FormValues.lessDeduction) || 0));

    change('balanceAdvance', balanceAdvance);
    change('advanceEligibility', advanceEligibility);
    change('netSalaryToBePaid', _netSalaryToBePaid);
    change('salaryPayable', salaryPayable);
  };

  return (
    <div ref={Reference}>
      <div className={classes.textAlignCenter}>
        {get(employeeAdvance, ['employee', 'name'], '--')} Advance Calculator
      </div>
      <div className={classes.textAlignCenter}>
        {get(employeeAdvance, ['employee', 'joiningDate'])
          ? moment(get(employeeAdvance, ['employee', 'joiningDate'])).format('DD MMM YYYY')
          : null}{' '}
        to {moment().format('DD MMM YYYY')}
      </div>
      <Row>
        <Col xs={{ span: 24 }} lg={{ span: 10 }}>
          <Field
            type="number"
            min={0}
            defaultValue={0}
            name="advanceForMonth"
            label="Advance For Month"
            placeholder="Advance For Month"
            component={AntInput}
          />
        </Col>
        <Col xs={{ span: 24 }} lg={{ span: 10, offset: 4 }}>
          <Field
            type="number"
            min={0}
            defaultValue={0}
            name="lessDeduction"
            label="Less Deduction"
            placeholder="Less Deduction"
            component={AntInput}
          />
        </Col>
      </Row>
      <Row>
        <Col xs={{ span: 24 }} lg={{ span: 10 }}>
          <Field
            type="number"
            disabled
            name="advanceRemaining"
            label="Employee Balance"
            placeholder="Employee Balance"
            component={AntInput}
          />
        </Col>
        <Col xs={{ span: 24 }} lg={{ span: 10, offset: 4 }}>
          <Field
            type="number"
            disabled
            name="balanceAdvance"
            label="Balance Advance"
            placeholder="Balance Advance"
            component={AntInput}
          />
        </Col>
      </Row>
      <Row>
        <Col xs={{ span: 24 }} lg={{ span: 10 }}>
          <Field
            type="number"
            disabled
            name="salary"
            label="Basic Salary"
            placeholder="Basic Salary"
            component={AntInput}
          />
        </Col>
        <Col xs={{ span: 24 }} lg={{ span: 10, offset: 4 }}>
          <Field
            type="number"
            disabled
            name="salaryPerMinute"
            label="Salary/Hr"
            placeholder="Salary/Hr"
            component={AntInput}
          />
        </Col>
      </Row>
      <Row>
        <Col xs={{ span: 24 }} lg={{ span: 10 }}>
          <Field
            type="number"
            disabled
            name="actualWorkingMinute"
            label="Working (in hr)"
            placeholder="Working (in hr)"
            component={AntInput}
          />
        </Col>
        <Col xs={{ span: 24 }} lg={{ span: 10, offset: 4 }}>
          <Field
            type="number"
            name="adjustActualWorking"
            label="Adjust Working (in hr)"
            placeholder="Adjust Working (in hr)"
            component={AntInput}
          />
        </Col>
      </Row>
      <Row>
        <Col xs={{ span: 24 }} lg={{ span: 10 }}>
          <Field
            type="number"
            disabled
            name="overtimeMinute"
            label="Overtime (in hr)"
            placeholder="Overtime (in hr)"
            component={AntInput}
          />
        </Col>
        <Col xs={{ span: 24 }} lg={{ span: 10, offset: 4 }}>
          <Field
            type="number"
            name="adjustOvertime"
            label="Adjust Overtime (in hr)"
            placeholder="Adjust Overtime (in hr)"
            component={AntInput}
          />
        </Col>
      </Row>
      <Row>
        <Col xs={{ span: 24 }} lg={{ span: 10 }}>
          <Field
            type="number"
            disabled
            name="lateMinute"
            label="Late (in hr)"
            placeholder="Late (in hr)"
            component={AntInput}
          />
        </Col>
        <Col xs={{ span: 24 }} lg={{ span: 10, offset: 4 }}>
          <Field
            type="number"
            name="adjustLate"
            label="Adjust Late (in hr)"
            placeholder="Adjust Late (in hr)"
            component={AntInput}
          />
        </Col>
      </Row>
      <Row>
        <Col xs={{ span: 24 }} lg={{ span: 10 }}>
          <Field
            type="number"
            disabled
            name="totalAbsent"
            label="Leave (in days)"
            placeholder="Leave (in days)"
            component={AntInput}
          />
        </Col>
        <Col xs={{ span: 24 }} lg={{ span: 10, offset: 4 }}>
          <Field
            type="number"
            name="adjustLeave"
            label="Adjust Leave (in days)"
            placeholder="Adjust Leave (in days)"
            component={AntInput}
          />
        </Col>
      </Row>
      <Row>
        <Col xs={{ span: 24 }} lg={{ span: 10 }}>
          <Field
            type="number"
            disabled
            name="salaryToBeGiven"
            label="Salary till now (current month)"
            placeholder="Salary till now (current month)"
            component={AntInput}
          />
        </Col>
        <Col xs={{ span: 24 }} lg={{ span: 10, offset: 4 }}>
          <Field
            type="number"
            disabled
            name="advanceEligibility"
            label="Advance Eligibility"
            placeholder="Advance Eligibility"
            component={AntInput}
          />
        </Col>
      </Row>
      <Row>
        <Col xs={{ span: 24 }} lg={{ span: 10 }}>
          <Field
            type="number"
            disabled
            name="netSalaryToBePaid"
            label="Net Salary"
            placeholder="Net Salary"
            component={AntInput}
          />
        </Col>
        <Col xs={{ span: 24 }} lg={{ span: 10, offset: 4 }}>
          <Field
            type="number"
            disabled
            name="salaryPayable"
            label="Salary Payable (Net - Less deduction)"
            placeholder="Salary Payable (Net - Less deduction)"
            component={AntInput}
          />
        </Col>
      </Row>
      <Row>
        <Button type="primary" onClick={() => calculateFields()}>
          Calculate
        </Button>
      </Row>
    </div>
  );
};

Advance.propTypes = {
  employeeId: PropTypes.number,
  classes: PropTypes.object.isRequired,
  Reference: PropTypes.any,
  change: PropTypes.func.isRequired,
};

const StyledAdvance = withStyles(styles)(Advance);

const FormedAdvance = reduxForm({
  form: 'EmployeeAdvanceForm',
  enableReinitialize: true,
})(StyledAdvance);

export default FormedAdvance;

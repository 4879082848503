import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import withStyles from 'react-jss';
import { Field, getFormValues, reduxForm } from 'redux-form';
import { useHistory } from 'react-router';
import { connect, useDispatch, useSelector } from 'react-redux';
import { Button, DatePicker, Input, Row, Select } from 'antd';
import startCase from 'lodash/startCase';
import moment from 'moment';
import styles from '../CommonStyles';
import GetField from '../Components/GetField';
import { getMaterialSale, clearMaterialSale } from '../Actions';
import constants, { materialTypes } from '../constants';
import LeftGrid from '../Components/LeftGrid';
import RightGrid from '../Components/RightGrid';
import { errorNotification, successNotification } from '../Snackbar';
import Api from '../Api';
import Structure from '../Structure/Structure';
import Heading from '../Components/Heading';

const AntSelect = GetField(Select);
const AntInput = GetField(Input);
const AntDatePicker = GetField(DatePicker);

const EditMaterialSale = props => {
  const { handleSubmit, classes, change, match } = props;
  const history = useHistory();
  const dispatch = useDispatch();

  const [confirmLoading, setConfirmLoading] = useState(false);
  const FormValues = useSelector(state => getFormValues('EditMaterialSale')(state));

  const [Batches, SetBatches] = useState([]);

  useEffect(() => {
    dispatch(getMaterialSale(match.params.id));
    return () => {
      dispatch(clearMaterialSale());
    };
  }, []);

  const updateBatches = async (_consignor, _materialId) => {
    try {
      if (_consignor && _materialId) {
        const { data } = await Api.get(`/material-inventory/ids`, {
          consignor: _consignor,
          materialId: _materialId,
        });
        SetBatches(data);
      } else {
        SetBatches([]);
      }
    } catch (error) {
      errorNotification(error);
    }
  };

  useEffect(() => {
    if (FormValues.consignor && FormValues.materialId) {
      updateBatches(FormValues.consignor, FormValues.materialId);
    }
  }, [FormValues && FormValues.consignor, FormValues && FormValues.materialId]);

  useEffect(() => {
    if (FormValues && FormValues.quantity && FormValues.rate) {
      const value = (+FormValues.quantity * +FormValues.rate).toFixed(2);
      change('value', value);
    } else {
      change('value', '');
    }
  }, [FormValues && FormValues.quantity, FormValues && FormValues.rate]);

  useEffect(() => {
    if (FormValues && FormValues.value && FormValues.gst) {
      const gstValue = ((+FormValues.value * +FormValues.gst) / 100).toFixed(2);
      change('gstValue', gstValue);
    } else {
      change('gstValue', '');
    }
  }, [FormValues && FormValues.value, FormValues && (FormValues.gst || FormValues.gst === 0)]);

  useEffect(() => {
    if (FormValues && FormValues.forwardingCharges) {
      const forwardingChargesTax = ((+FormValues.forwardingCharges * 18) / 100).toFixed(2);
      change('forwardingChargesTax', forwardingChargesTax);
    } else {
      change('forwardingChargesTax', '');
    }
  }, [FormValues && FormValues.forwardingCharges]);

  useEffect(() => {
    if (FormValues && FormValues.value && FormValues && FormValues.gstValue) {
      const total = (
        +FormValues.value +
        +FormValues.gstValue +
        (+FormValues.forwardingCharges || 0) +
        (+FormValues.forwardingChargesTax || 0) +
        (+FormValues.otherChargesDiscount || 0) +
        (+FormValues.roundOf || 0)
      ).toFixed(2);
      change('total', total);
    }
  }, [
    FormValues && FormValues.value,
    FormValues && FormValues.gstValue,
    FormValues && FormValues.forwardingCharges,
    FormValues && FormValues.forwardingChargesTax,
    FormValues && FormValues.otherChargesDiscount,
    FormValues && FormValues.roundOf,
  ]);

  const onSubmit = async () => {
    try {
      setConfirmLoading(true);
      await Api.put(`/material-sales/${match.params.id}`, FormValues);
      successNotification('Material Sale updated');
      setConfirmLoading(false);
      history.push('/material/sale/list');
    } catch (err) {
      setConfirmLoading(false);
      errorNotification(err);
    }
  };

  return (
    <Structure>
      <>
        <Heading text="Edit Material Sale" />
        <form onSubmit={handleSubmit(onSubmit)}>
          <Row>
            <LeftGrid>
              <Field label="Consignor" disabled allowClear required name="consignor" component={AntSelect}>
                <Select.Option key={null} value="" disabled>
                  Select a consignor
                </Select.Option>
                {Object.values(constants.consignors.list).map(consignor => (
                  <Select.Option key={consignor} value={consignor}>
                    {consignor}
                  </Select.Option>
                ))}
              </Field>
            </LeftGrid>
            <RightGrid>
              <Field
                label="Customer"
                disabled
                allowClear
                name="customerName"
                placeholder="Customer"
                component={AntInput}
              />
            </RightGrid>
          </Row>
          <Row>
            <LeftGrid>
              <Field
                label="Material"
                disabled
                allowClear
                name="materialName"
                placeholder="Material"
                component={AntInput}
              />
            </LeftGrid>
            <RightGrid>
              <Field label="Item Type" disabled name="materialType" component={AntSelect}>
                {materialTypes.map(type => (
                  <Select.Option key={type} value={type}>
                    {startCase(type)}
                  </Select.Option>
                ))}
              </Field>
            </RightGrid>
          </Row>
          <Row>
            <LeftGrid>
              <Field label="Unit" disabled allowClear name="unit" placeholder="Unit" component={AntInput} />
            </LeftGrid>
            <RightGrid>
              <Field label="Batch" disabled allowClear required name="batch" component={AntSelect}>
                <Select.Option key={null} value="" disabled>
                  Select batch
                </Select.Option>
                {Batches.map(batch => (
                  <Select.Option key={batch.batch} value={batch.batch}>
                    {batch.batch}
                  </Select.Option>
                ))}
              </Field>
            </RightGrid>
          </Row>
          <Row>
            <LeftGrid>
              <Field label="Stock" allowClear disabled required name="batch" component={AntSelect}>
                <Select.Option key={null} value="" disabled>
                  Select batch
                </Select.Option>
                {Batches.map(batch => (
                  <Select.Option key={batch.batch} value={batch.batch}>
                    {batch.stock}
                  </Select.Option>
                ))}
              </Field>
            </LeftGrid>
            <RightGrid>
              <Field label="Mfg." allowClear disabled required name="batch" component={AntSelect}>
                <Select.Option key={null} value="" disabled>
                  Select batch
                </Select.Option>
                {Batches.map(batch => (
                  <Select.Option key={batch.batch} value={batch.batch}>
                    {moment(batch.mfgDate).format('MMM YY')}
                  </Select.Option>
                ))}
              </Field>
            </RightGrid>
          </Row>
          <Row>
            <LeftGrid>
              <Field label="Exp." allowClear disabled required name="batch" component={AntSelect}>
                <Select.Option key={null} value="" disabled>
                  Select batch
                </Select.Option>
                {Batches.map(batch => (
                  <Select.Option key={batch.batch} value={batch.batch}>
                    {moment(batch.expDate).format('MMM YY')}
                  </Select.Option>
                ))}
              </Field>
            </LeftGrid>
            <RightGrid>
              <Field
                label="Voucher Number"
                allowClear
                required
                name="voucherNo"
                placeholder="Voucher Number"
                component={AntInput}
              />
            </RightGrid>
          </Row>
          <Row>
            <LeftGrid>
              <Field
                allowClear
                required
                label="Voucher Date"
                name="voucherDate"
                placeholder="Voucher Date"
                component={AntDatePicker}
              />
            </LeftGrid>
            <RightGrid>
              <Field label="Sale Type" allowClear required name="type" component={AntSelect}>
                <Select.Option key={null} value="" disabled>
                  Select sale type
                </Select.Option>
                <Select.Option key="invoice" value="invoice">
                  Invoice
                </Select.Option>
                <Select.Option key="challan" value="challan">
                  Challan
                </Select.Option>
              </Field>
            </RightGrid>
          </Row>
          <Row>
            <LeftGrid>
              <Field
                label="Quantity"
                allowClear
                type="number"
                min={0}
                disabled
                name="quantity"
                placeholder="Quantity"
                component={AntInput}
              />
            </LeftGrid>
            <RightGrid>
              <Field
                label="Rate"
                allowClear
                type="number"
                min={0}
                name="rate"
                placeholder="Rate"
                component={AntInput}
              />
            </RightGrid>
          </Row>
          <Row>
            <LeftGrid>
              <Field
                label="Sale Value"
                disabled
                allowClear
                type="number"
                min={0}
                name="value"
                placeholder="Sale Value"
                component={AntInput}
              />
            </LeftGrid>
            <RightGrid>
              <Field
                label="GST (in %)"
                allowClear
                disabled
                type="number"
                min={0}
                name="gst"
                placeholder="GST (in %)"
                component={AntInput}
              />
            </RightGrid>
          </Row>
          <Row>
            <LeftGrid>
              <Field
                label="GST Value"
                disabled
                allowClear
                type="number"
                min={0}
                name="gstValue"
                placeholder="GST Value"
                component={AntInput}
              />
            </LeftGrid>
            <RightGrid>
              <Field
                label="Forwarding Charges"
                allowClear
                type="number"
                min={0}
                name="forwardingCharges"
                placeholder="Forwarding Charges"
                component={AntInput}
              />
            </RightGrid>
          </Row>
          <Row>
            <LeftGrid>
              <Field
                label="Forwarding Charges Tax Value (Tax 18%)"
                allowClear
                disabled
                type="number"
                min={0}
                name="forwardingChargesTax"
                placeholder="Forwarding Charges Tax Value (Tax 18%)"
                component={AntInput}
              />
            </LeftGrid>
            <RightGrid>
              <Field
                label="Other Charges/Discount (+/-)"
                allowClear
                type="number"
                name="otherChargesDiscount"
                placeholder="Other Charges/Discount (+/-)"
                component={AntInput}
              />
            </RightGrid>
          </Row>
          <Row>
            <LeftGrid>
              <Field
                label="Round of (+/-)"
                allowClear
                type="number"
                name="roundOf"
                placeholder="Round of (+/-)"
                component={AntInput}
              />
            </LeftGrid>
            <RightGrid>
              <Field
                label="Total"
                disabled
                allowClear
                type="number"
                min={0}
                name="total"
                placeholder="Total"
                component={AntInput}
              />
            </RightGrid>
          </Row>
          <Row>
            <LeftGrid>
              <Field
                allowClear
                required
                name="containerNo"
                label="Marks & No/Container No"
                placeholder="Marks & No/Container No"
                component={AntInput}
              />
            </LeftGrid>
            <RightGrid>
              <Field
                allowClear
                required
                name="kindOfPackage"
                label="No & Kind of Pkgs."
                placeholder="No & Kind of Pkgs."
                component={AntInput}
              />
            </RightGrid>
          </Row>
          <Row>
            <LeftGrid>
              <Field label="Remarks" allowClear name="remarks" placeholder="Remarks" component={AntInput} />
            </LeftGrid>
          </Row>
          <Row className={classes.buttonContainer}>
            <Button className={classes.button} loading={confirmLoading} type="primary" htmlType="submit">
              Submit
            </Button>
          </Row>
        </form>
      </>
    </Structure>
  );
};

EditMaterialSale.propTypes = {
  handleSubmit: PropTypes.func.isRequired,
  classes: PropTypes.object.isRequired,
  match: PropTypes.object.isRequired,
  change: PropTypes.func.isRequired,
};

const StyledEditMaterialSale = withStyles(styles)(EditMaterialSale);

const FormedEditMaterialSale = reduxForm({
  form: 'EditMaterialSale',
  enableReinitialize: true,
})(StyledEditMaterialSale);

export default connect(({ materialSale }) => ({ initialValues: materialSale }), {})(FormedEditMaterialSale);

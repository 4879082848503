export const dosageFormList = (state = [], action) => {
  if (action.type === 'FETCH_DOSAGEFORM_LIST') {
    return action.payload;
  }
  if (action.type === 'CLEAR_DOSAGEFORM_LIST') {
    return [];
  }
  return state;
};

export const dosageForm = (state = {}, action) => {
  if (action.type === 'FETCH_DOSAGEFORM') {
    return action.payload;
  }
  if (action.type === 'CLEAR_DOSAGEFORM') {
    return {};
  }
  return state;
};

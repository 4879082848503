import React from 'react';
import PropTypes from 'prop-types';
import withStyles from 'react-jss';
import Modal from 'antd/lib/modal/Modal';
import { Descriptions, Table } from 'antd';
import moment from 'moment';
import styles from '../CommonStyles';
import { get } from '../utils';

const TicketTimeline = props => {
  const { classes, isTimelineModalVisible, ticketData, hideTimelineModal } = props;

  const extensionColumn = [
    {
      title: 'Requested',
      dataIndex: 'requestedAt',
      render: requestedAt => (requestedAt ? moment(requestedAt).format('DD MMM YYYY h:mm A') : null),
    },
    {
      title: 'Old Due Date',
      dataIndex: 'oldDueDate',
      render: oldDueDate => (oldDueDate ? moment(oldDueDate).format('DD MMM YYYY h:mm A') : null),
    },
    {
      title: 'Requested Due Date',
      dataIndex: 'requestedDueDate',
      render: requestedDueDate => (requestedDueDate ? moment(requestedDueDate).format('DD MMM YYYY h:mm A') : null),
    },
    {
      title: 'New Due Date',
      dataIndex: 'newDueDate',
      render: newDueDate => (newDueDate ? moment(newDueDate).format('DD MMM YYYY h:mm A') : null),
    },
    {
      title: 'Doer Remark',
      dataIndex: 'requestedRemark',
    },
    {
      title: 'Status',
      dataIndex: 'isRejected',
      render: text => (text === true ? 'Rejected' : 'Approved'),
    },
    {
      title: 'Creator Remark',
      dataIndex: 'respondedRemark',
    },
    {
      title: 'Respended At',
      dataIndex: 'respondedAt',
      render: respondedAt => (respondedAt ? moment(respondedAt).format('DD MMM YYYY h:mm A') : null),
    },
  ];

  const revisionColumn = [
    {
      title: 'Doer Completed',
      dataIndex: 'completedByUserAt',
      render: completedByUserAt => (completedByUserAt ? moment(completedByUserAt).format('DD MMM YYYY h:mm A') : null),
    },
    {
      title: 'Old Due Date',
      dataIndex: 'dueDate',
      render: dueDate => (dueDate ? moment(dueDate).format('DD MMM YYYY h:mm A') : null),
    },
    {
      title: 'New Due Date',
      dataIndex: 'newDueDate',
      render: dueDate => (dueDate ? moment(dueDate).format('DD MMM YYYY h:mm A') : null),
    },
    {
      title: 'Ticket Old Remark',
      dataIndex: 'OldRemark',
    },
    {
      title: 'Revision Remark',
      dataIndex: 'newRemark',
    },
    {
      title: 'Respended At',
      dataIndex: 'revisedAt',
      render: revisedAt => (revisedAt ? moment(revisedAt).format('DD MMM YYYY h:mm A') : null),
    },
  ];

  return (
    <Modal
      open={isTimelineModalVisible}
      onOk={hideTimelineModal}
      centered
      maskClosable={false}
      closable={false}
      title="Ticket Details"
      width="1000px"
      okText="Close"
      okButtonProps={{ className: classes.button }}
      cancelButtonProps={{ className: classes.hide }}
    >
      <Descriptions column={1} size="small">
        <Descriptions.Item label="Ticket">
          <span className={classes.detailColor}>{get(ticketData, ['title'], '--')}</span>
        </Descriptions.Item>
        <Descriptions.Item label="Description">
          <span className={classes.detailColor}>{get(ticketData, ['description'], '--')}</span>
        </Descriptions.Item>
        <Descriptions.Item label="Assign Date">
          <span className={classes.detailColor}>
            {get(ticketData, ['createdAt'])
              ? moment(get(ticketData, ['createdAt'])).format('DD MMM YYYY h:mm A')
              : '--'}
          </span>
        </Descriptions.Item>
        <Descriptions.Item label="Due Date">
          <span className={classes.detailColor}>
            {get(ticketData, ['dueDate']) ? moment(get(ticketData, ['dueDate'])).format('DD MMM YYYY h:mm A') : '--'}
          </span>
        </Descriptions.Item>
        <Descriptions.Item label="Creator">
          <span className={classes.detailColor}>{get(ticketData, ['creator'], '--')}</span>
        </Descriptions.Item>
        <Descriptions.Item label="Doer">
          <span className={classes.detailColor}>{get(ticketData, ['doer'], '--')}</span>
        </Descriptions.Item>
        <Descriptions.Item label="Creator Remark">
          <span className={classes.detailColor}>{get(ticketData, ['remark'], '--')}</span>
        </Descriptions.Item>
        <Descriptions.Item label="Doer Remark">
          <span className={classes.detailColor}>{get(ticketData, ['userRemark'], '--')}</span>
        </Descriptions.Item>
        <Descriptions.Item label="Doer Completed At">
          <span className={classes.detailColor}>
            {get(ticketData, ['completedByUserAt'])
              ? moment(get(ticketData, ['completedByUserAt'])).format('DD MMM YYYY h:mm A')
              : '--'}
          </span>
        </Descriptions.Item>
        <Descriptions.Item label="Creator Closed At">
          <span className={classes.detailColor}>
            {get(ticketData, ['completedByCreatorAt'])
              ? moment(get(ticketData, ['completedByCreatorAt'])).format('DD MMM YYYY h:mm A')
              : '--'}
          </span>
        </Descriptions.Item>
      </Descriptions>
      <br />
      <h3>Extension Requests:</h3>
      <Table
        columns={extensionColumn}
        bordered
        rowKey={record => record.requestedAt}
        pagination={false}
        dataSource={get(ticketData, ['oldRequestRevisions'], [])}
        size="small"
        scroll={{ x: 425 }}
      />
      <br />
      <h3>Revisions:</h3>
      <Table
        columns={revisionColumn}
        bordered
        rowKey={record => record.revisedAt}
        pagination={false}
        dataSource={get(ticketData, ['oldRevisions'], [])}
        size="small"
        scroll={{ x: 425 }}
      />
    </Modal>
  );
};

TicketTimeline.propTypes = {
  isTimelineModalVisible: PropTypes.bool,
  ticketData: PropTypes.any,
  hideTimelineModal: PropTypes.func,
  classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(TicketTimeline);

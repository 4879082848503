import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import withStyles from 'react-jss';
import { Input, Button, Row, Select, DatePicker, Checkbox } from 'antd';
import { useHistory } from 'react-router';
import { useDispatch, useSelector } from 'react-redux';
import moment from 'moment';
import { useForm, Controller } from 'react-hook-form';

import Heading from '../Components/Heading';
import Api from '../Api';
import Structure from '../Structure/Structure';
import styles from '../CommonStyles';
import { successNotification, errorNotification } from '../Snackbar';

import GetField from '../Components/GetRHFField';
import LeftGrid from '../Components/LeftGrid';
import RightGrid from '../Components/RightGrid';

import {
  getFormulationIdList,
  clearFormulationIdList,
  getCustomerIdList,
  clearCustomerIdList,
  getTransporterIdList,
  clearTransporterIdList,
} from '../Actions';

const AntInput = GetField(Input);
const AntInputTextArea = GetField(Input.TextArea);
const AntSelect = GetField(Select);
const AntDatePicker = GetField(DatePicker);
const AntCheckBox = GetField(Checkbox);

const NewOrder = props => {
  const { classes } = props;
  const history = useHistory();
  const dispatch = useDispatch();

  const { handleSubmit, control, setValue, watch } = useForm();

  const formulationIdList = useSelector(state => state.formulationIdList);
  const customerIdList = useSelector(state => state.customerIdList);
  const transporterIdList = useSelector(state => state.transporterIdList);

  const [confirmLoading, setConfirmLoading] = useState(false);

  useEffect(() => {
    dispatch(getFormulationIdList());
    dispatch(getTransporterIdList());
    dispatch(getCustomerIdList({ status: 'Active' }));
    return () => {
      dispatch(clearFormulationIdList());
      dispatch(clearTransporterIdList());
      dispatch(clearCustomerIdList());
    };
  }, []);

  const onSubmit = async formValues => {
    try {
      setConfirmLoading(true);
      await Api.post('/orders', formValues);
      successNotification('Order created');
      setConfirmLoading(false);
      history.push('/order/list');
    } catch (err) {
      setConfirmLoading(false);
      errorNotification(err);
    }
  };

  return (
    <Structure>
      <>
        <Heading text="New Order" />
        <form onSubmit={handleSubmit(onSubmit)}>
          <Row>
            <LeftGrid>
              <Controller
                name="customerId"
                control={control}
                render={({ field }) => (
                  <AntSelect allowClear label="Customer" placeholder="Select customer" {...field}>
                    {customerIdList.map(customer => (
                      <Select.Option key={customer.id} value={customer.id}>
                        {customer.name}
                      </Select.Option>
                    ))}
                  </AntSelect>
                )}
              />
            </LeftGrid>
            <RightGrid>
              <Controller
                name="formulationId"
                control={control}
                render={({ field }) => (
                  <AntSelect allowClear label="Finished Good Name" placeholder="Select finished good name" {...field}>
                    {formulationIdList.map(formulation => (
                      <Select.Option key={formulation.id} value={formulation.id}>
                        {formulation.name}
                      </Select.Option>
                    ))}
                  </AntSelect>
                )}
              />
            </RightGrid>
          </Row>
          <Row>
            <LeftGrid>
              <Controller
                name="formulationId"
                control={control}
                render={({ field }) => (
                  <AntSelect allowClear label="Consignor" disabled placeholder="Select finished good name" {...field}>
                    {formulationIdList.map(formulation => (
                      <Select.Option key={formulation.id} value={formulation.id}>
                        {formulation.consignor}
                      </Select.Option>
                    ))}
                  </AntSelect>
                )}
              />
            </LeftGrid>
            <RightGrid>
              <Controller
                name="formulationId"
                control={control}
                render={({ field }) => (
                  <AntSelect allowClear label="Section" disabled placeholder="Select finished good name" {...field}>
                    {formulationIdList.map(formulation => (
                      <Select.Option key={formulation.id} value={formulation.id}>
                        {formulation.section}
                      </Select.Option>
                    ))}
                  </AntSelect>
                )}
              />
            </RightGrid>
          </Row>
          <Row>
            <LeftGrid>
              <Controller
                name="formulationId"
                control={control}
                render={({ field }) => (
                  <AntSelect
                    allowClear
                    label="Last Sale Date"
                    disabled
                    placeholder="Select finished good name"
                    {...field}
                  >
                    {formulationIdList.map(formulation => (
                      <Select.Option key={formulation.id} value={formulation.id}>
                        {formulation.lastSaleDate ? moment(formulation.lastSaleDate).format('DD-MM-YYYY') : ''}
                      </Select.Option>
                    ))}
                  </AntSelect>
                )}
              />
            </LeftGrid>
            <RightGrid>
              <Controller
                name="formulationId"
                control={control}
                render={({ field }) => (
                  <AntSelect
                    allowClear
                    label="Last Sale Rate"
                    disabled
                    placeholder="Select finished good name"
                    {...field}
                  >
                    {formulationIdList.map(formulation => (
                      <Select.Option key={formulation.id} value={formulation.id}>
                        {formulation.lastSaleRate || ''}
                      </Select.Option>
                    ))}
                  </AntSelect>
                )}
              />
            </RightGrid>
          </Row>
          <Row>
            <LeftGrid>
              <Controller
                name="quantity"
                control={control}
                render={({ field }) => (
                  <AntInput type="number" min={0} label="Quantity" placeholder="Quantity" {...field} />
                )}
              />
            </LeftGrid>
            <RightGrid>
              <Controller
                name="rate"
                control={control}
                render={({ field }) => <AntInput type="number" min={0} label="Rate" placeholder="Rate" {...field} />}
              />
            </RightGrid>
          </Row>

          <Row>
            <LeftGrid>
              <Controller
                name="mrp"
                control={control}
                render={({ field }) => <AntInput type="number" min={0} label="MRP" placeholder="MRP" {...field} />}
              />
            </LeftGrid>
            <RightGrid>
              <Controller
                name="nature"
                control={control}
                render={({ field }) => (
                  <AntSelect allowClear label="Nature" placeholder="Select nature" {...field}>
                    <Select.Option key="OTP" value="OTP">
                      OTP
                    </Select.Option>
                    <Select.Option key="NBD CRR" value="NBD CRR">
                      NBD CRR
                    </Select.Option>
                    <Select.Option key="NBD Incoming" value="NBD Incoming">
                      NBD Incoming
                    </Select.Option>
                    <Select.Option key="NBD Outgoing" value="NBD Outgoing">
                      NBD Outgoing
                    </Select.Option>
                  </AntSelect>
                )}
              />
            </RightGrid>
          </Row>
          <Row>
            <LeftGrid>
              <Controller
                name="paymentTerms"
                control={control}
                render={({ field }) => (
                  <AntSelect
                    allowClear
                    label="Payment Term"
                    placeholder="Select payment term"
                    {...field}
                    onChange={value => {
                      field.onChange(value);

                      if (value !== 'Advance') {
                        setValue('dateOfAdvanceDeposit', null);
                        setValue('depositedAmount', null);
                      }
                      if (value !== 'Payment With Other Order') {
                        setValue('orderIds', null);
                      }
                    }}
                  >
                    <Select.Option key="Credit" value="Credit">
                      Credit
                    </Select.Option>
                    <Select.Option key="Advance" value="Advance">
                      Advance
                    </Select.Option>
                    <Select.Option key="Partial Payment Against Invoice" value="Partial Payment Against Invoice">
                      Partial Payment Against Invoice
                    </Select.Option>
                    <Select.Option key="Full Payment Against Invoice" value="Full Payment Against Invoice">
                      Full Payment Against Invoice
                    </Select.Option>
                    <Select.Option key="Payment With Other Order" value="Payment With Other Order">
                      Payment With Other Order
                    </Select.Option>
                  </AntSelect>
                )}
              />
            </LeftGrid>
          </Row>
          {watch('paymentTerms') === 'Advance' ? (
            <>
              <Row>
                <LeftGrid>
                  <Controller
                    name="dateOfAdvanceDeposit"
                    control={control}
                    render={({ field }) => (
                      <AntDatePicker label="Advance Deposit Date" placeholder="Advance Deposit Date" {...field} />
                    )}
                  />
                </LeftGrid>
                <RightGrid>
                  <Controller
                    name="depositedAmount"
                    control={control}
                    render={({ field }) => (
                      <AntInput
                        type="number"
                        min={0}
                        label="Deposited Amount"
                        placeholder="Deposited Amount"
                        {...field}
                      />
                    )}
                  />
                </RightGrid>
              </Row>
            </>
          ) : null}
          {watch('paymentTerms') === 'Payment With Other Order' ? (
            <>
              <Row>
                <LeftGrid>
                  <Controller
                    name="orderIds"
                    control={control}
                    render={({ field }) => (
                      <AntInput type="number" min={0} label="Orders" placeholder="Orders" {...field} />
                    )}
                  />
                </LeftGrid>
              </Row>
            </>
          ) : null}
          <Row>
            <LeftGrid>
              <Controller
                name="transportId"
                control={control}
                render={({ field }) => (
                  <AntSelect allowClear label="Transport" placeholder="Select transport" {...field}>
                    {transporterIdList.map(transport => (
                      <Select.Option key={transport.id} value={transport.id}>
                        {transport.name}
                      </Select.Option>
                    ))}
                  </AntSelect>
                )}
              />
            </LeftGrid>
            <RightGrid>
              <Controller
                name="isChangeRequired"
                control={control}
                render={({ field }) => <AntCheckBox label="Design Changes Required" {...field} />}
              />
            </RightGrid>
          </Row>
          <Row>
            <LeftGrid>
              <Controller
                name="estDispatchDate"
                control={control}
                render={({ field }) => (
                  <AntDatePicker label="Estimated Dispatch Date" placeholder="Estimated Dispatch Date" {...field} />
                )}
              />
            </LeftGrid>
            <RightGrid>
              <Controller
                name="remarks"
                control={control}
                render={({ field }) => (
                  <AntInputTextArea
                    allowClear
                    label="Customer Remarks"
                    placeholder="Customer Remarks"
                    {...field}
                    autoSize={{ minRows: 1, maxRows: 6 }}
                  />
                )}
              />
            </RightGrid>
          </Row>
          <Row>
            <LeftGrid>
              <Controller
                name="internalRemarks"
                control={control}
                render={({ field }) => (
                  <AntInputTextArea
                    allowClear
                    label="Internal Remarks"
                    placeholder="Internal Remarks"
                    {...field}
                    autoSize={{ minRows: 1, maxRows: 6 }}
                  />
                )}
              />
            </LeftGrid>
            <RightGrid>
              <Controller
                name="isGRUpload"
                control={control}
                render={({ field }) => <AntCheckBox label="GR Upload" {...field} />}
              />
            </RightGrid>
          </Row>
          <Row className={classes.buttonContainer}>
            <Button className={classes.button} type="primary" loading={confirmLoading} htmlType="submit">
              Preview
            </Button>
          </Row>
        </form>
      </>
    </Structure>
  );
};

NewOrder.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(NewOrder);

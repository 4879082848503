import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Layout, Dropdown, Avatar, Input, Modal } from 'antd';
import withStyles from 'react-jss';
import { useSelector, useDispatch } from 'react-redux';
import { useHistory } from 'react-router';
import { MenuUnfoldOutlined, MenuFoldOutlined, UserOutlined } from '@ant-design/icons';
import { Field, reduxForm, getFormValues } from 'redux-form';
import styles from './styles';
import { logout } from '../Actions';
import GetField from '../Components/GetField';
import { successNotification, errorNotification } from '../Snackbar';
import Api from '../Api';

const AntPassword = GetField(Input.Password);

const { Header } = Layout;

const Navbar = props => {
  const { classes, setCollapsed, collapsed, setVisible, open } = props;
  const [changePasswordModal, setChangePasswordModal] = useState(false);
  const [confirmLoading, setConfirmLoading] = useState(false);

  const FormValues = useSelector(state => getFormValues('Navbar')(state));

  const auth = useSelector(state => state.auth);
  const dispatch = useDispatch();
  const history = useHistory();

  const toggleCollapse = () => {
    setCollapsed(!collapsed);
  };

  const toggleDrawerCollapse = () => {
    setVisible(!open);
  };

  const showChangePasswordModal = () => {
    setChangePasswordModal(true);
  };

  const hideChangePasswordModal = () => {
    setChangePasswordModal(false);
    setConfirmLoading(false);
  };

  const onLogout = () => {
    dispatch(logout());
    history.push('/login');
  };

  const freezeUsers = async () => {
    try {
      await Api.post('/users/freeze');
      successNotification('Users freezed');
      history.push('/');
    } catch (err) {
      errorNotification(err);
    }
  };

  const UnFreezeUsers = async () => {
    try {
      await Api.post('/users/unfreeze');
      successNotification('Users unfreezed');
      history.push('/');
    } catch (err) {
      errorNotification(err);
    }
  };

  const onSubmit = async () => {
    try {
      setConfirmLoading(true);
      const { password, newPassword } = FormValues;
      await Api.post('/auth/changepassword/', { password, newPassword });
      successNotification('Password changed');
      hideChangePasswordModal();
    } catch (err) {
      errorNotification(err);
    }
  };

  const items = [
    {
      key: 'userInfo',
      label: `${auth.user.firstName} | ${auth.user.role}`,
    },
    ...(auth.user.role === 'Owner'
      ? [
          {
            key: 'freeze',
            onClick: freezeUsers,
            label: 'Freeze ERP',
          },
          {
            key: 'unFreeze',
            onClick: UnFreezeUsers,
            label: 'UnFreeze ERP',
          },
        ]
      : []),
    {
      key: 'changepassword',
      onClick: showChangePasswordModal,
      label: 'Change Password',
    },
    {
      key: 'logout',
      onClick: onLogout,
      label: 'Logout',
    },
  ];

  return (
    <div>
      <Header className={classes.header}>
        <div
          onClick={toggleCollapse}
          role="button"
          tabIndex={0}
          onKeyPress={toggleCollapse}
          className={classes.collapseIcon}
        >
          {collapsed ? <MenuUnfoldOutlined /> : <MenuFoldOutlined />}
        </div>
        <div
          onClick={toggleDrawerCollapse}
          role="button"
          tabIndex={0}
          onKeyPress={toggleDrawerCollapse}
          className={classes.collapseDrawerIcon}
        >
          {open ? <MenuUnfoldOutlined /> : <MenuFoldOutlined />}
        </div>

        <Dropdown menu={{ items }} trigger={['click']}>
          <span className={classes.action}>
            <Avatar className={classes.avatar} alt="avatar">
              <UserOutlined />
            </Avatar>
          </span>
        </Dropdown>
      </Header>
      <Modal
        onOk={onSubmit}
        open={changePasswordModal}
        onCancel={hideChangePasswordModal}
        centered
        title="Change Password."
        width="400px"
        closable={false}
        okText="Confirm"
        cancelText="Back"
        confirmLoading={confirmLoading}
        maskClosable={false}
      >
        <Field
          placeholder="Current Password"
          required
          type="password"
          name="password"
          allowClear
          visibilityToggle
          component={AntPassword}
        />
        <Field
          placeholder="New Password"
          required
          type="password"
          name="newPassword"
          allowClear
          visibilityToggle
          component={AntPassword}
        />
      </Modal>
    </div>
  );
};

Navbar.propTypes = {
  classes: PropTypes.object.isRequired,
  setCollapsed: PropTypes.func.isRequired,
  collapsed: PropTypes.bool.isRequired,
  setVisible: PropTypes.func.isRequired,
  open: PropTypes.bool.isRequired,
};

const StyledNavbar = withStyles(styles)(Navbar);

const FormedNavbar = reduxForm({
  form: 'Navbar',
})(StyledNavbar);

export default FormedNavbar;

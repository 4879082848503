import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { reduxForm, Field, getFormValues } from 'redux-form';
import withStyles from 'react-jss';
import { Button, Row, Modal, Select, Col, Popconfirm, DatePicker, Input } from 'antd';
import { useHistory } from 'react-router';
import { useDispatch, useSelector } from 'react-redux';
import moment from 'moment';
import Heading from '../Components/Heading';
import Api from '../Api';
import Structure from '../Structure/Structure';
import styles from '../CommonStyles';
import { successNotification, errorNotification } from '../Snackbar';
import LeftGrid from '../Components/LeftGrid';
import RightGrid from '../Components/RightGrid';
import GetField from '../Components/GetField';
import { get } from '../utils';
import { getEmployeeIdList, clearEmployeeIdList } from '../Actions';

const AntInput = GetField(Input);
const AntSelect = GetField(Select);
const AntDatePicker = GetField(DatePicker);

const NewLeave = props => {
  const { handleSubmit, classes } = props;
  const history = useHistory();
  const dispatch = useDispatch();

  const employeeIdList = useSelector(state => state.employeeIdList);

  const [formValueModal, setFormValuesModal] = useState(false);
  const [confirmLoading, setConfirmLoading] = useState(false);
  const [preview, setPreview] = useState({});
  const FormValues = useSelector(state => getFormValues('NewLeave')(state));

  useEffect(() => {
    dispatch(getEmployeeIdList({ status: 'Active' }));
    return () => {
      dispatch(clearEmployeeIdList());
    };
  }, []);

  const showFormValuesModal = () => {
    const from = get(FormValues, ['from']);
    const to = get(FormValues, ['to']);

    const _preview = {
      employee: '--',
      reason: get(FormValues, ['reason'], '--'),
      from: from ? moment(from).format('DD MMM YYYY hh:mm A') : '--',
      to: to ? moment(to).format('DD MMM YYYY hh:mm A') : '--',
    };

    const employeeId = get(FormValues, ['employeeId'], null);
    if (employeeId) {
      const employeeObj = employeeIdList.find(employee => employee.id === +employeeId);
      if (employeeObj) {
        _preview.employee = employeeObj.name;
      }
    }

    setPreview(_preview);
    setFormValuesModal(true);
  };

  const hideFormValuesModal = () => {
    setFormValuesModal(false);
    setPreview({});
  };

  const onSubmit = async () => {
    try {
      setConfirmLoading(true);
      const { from, to } = FormValues;
      await Api.post('/leave-request', {
        ...FormValues,
        from: new Date(from).toISOString(),
        to: new Date(to).toISOString(),
      });
      successNotification('Leave requested');
      setConfirmLoading(false);
      hideFormValuesModal();
      history.push('/leave/list');
    } catch (err) {
      setConfirmLoading(false);
      errorNotification(err);
    }
  };

  return (
    <Structure>
      <>
        <Heading text="Request Leave" />
        <form onSubmit={handleSubmit(onSubmit)}>
          <Row>
            <LeftGrid>
              <Field label="Employee" allowClear required name="employeeId" component={AntSelect}>
                <Select.Option key={null} value="" disabled>
                  Select employee
                </Select.Option>
                {employeeIdList.map(employee => (
                  <Select.Option key={employee.id} value={employee.id}>
                    {employee.name}
                  </Select.Option>
                ))}
              </Field>
            </LeftGrid>
            <RightGrid>
              <Field label="Reason" required allowClear name="reason" placeholder="Reason" component={AntInput} />
            </RightGrid>
          </Row>
          <Row>
            <LeftGrid>
              <Field
                allowClear
                required
                showTime={{ format: 'hh:mm A' }}
                use12Hours
                name="from"
                label="From"
                placeholder="From"
                component={AntDatePicker}
              />
            </LeftGrid>
            <RightGrid>
              <Field
                allowClear
                required
                showTime={{ format: 'hh:mm A' }}
                use12Hours
                name="to"
                label="To"
                placeholder="To"
                component={AntDatePicker}
              />
            </RightGrid>
          </Row>
          <Row className={classes.buttonContainer}>
            <Button className={classes.button} type="primary" onClick={showFormValuesModal}>
              Preview
            </Button>
          </Row>
        </form>
        <Modal
          onCancel={hideFormValuesModal}
          open={formValueModal}
          confirmLoading={confirmLoading}
          centered
          width="800px"
          maskClosable={false}
          closable={false}
          cancelText="Back"
          okButtonProps={{ className: classes.button }}
          okText={
            <Popconfirm
              title="Sure want to submit form ?"
              onConfirm={() => onSubmit()}
              okText="Confirm"
              cancelText="Cancel"
            >
              Submit
            </Popconfirm>
          }
        >
          <Row>
            <Col xs={24} lg={12}>
              <p>
                Employee:
                <span className={classes.detailColor}>{preview.employee}</span>
              </p>
            </Col>
            <Col xs={24} lg={12}>
              <p>
                Reason:
                <span className={classes.detailColor}>{preview.reason}</span>
              </p>
            </Col>
          </Row>
          <Row>
            <Col xs={24} lg={12}>
              <p>
                From:
                <span className={classes.detailColor}>{preview.from}</span>
              </p>
            </Col>
            <Col xs={24} lg={12}>
              <p>
                To:
                <span className={classes.detailColor}>{preview.to}</span>
              </p>
            </Col>
          </Row>
        </Modal>
      </>
    </Structure>
  );
};

NewLeave.propTypes = {
  handleSubmit: PropTypes.func.isRequired,
  classes: PropTypes.object.isRequired,
};

const StyledNewLeave = withStyles(styles)(NewLeave);

const FormedNewLeave = reduxForm({
  form: 'NewLeave',
})(StyledNewLeave);

export default FormedNewLeave;

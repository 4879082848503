export const calendarEvents = (state = [], action) => {
  if (action.type === 'FETCH_CALENDAR_EVENTS') {
    return action.payload;
  }

  if (action.type === 'CLEAR_CALENDAR_EVENTS') {
    return [];
  }

  return state;
};

export const customerCalendarEvents = (
  state = {
    totalDue: 0,
    totalOverDue: 0,
    dueEvents: [],
  },
  action
) => {
  if (action.type === 'FETCH_CUSTOMER_CALENDAR_EVENTS') {
    return action.payload;
  }

  if (action.type === 'CLEAR_CUSTOMER_CALENDAR_EVENTS') {
    return {
      totalDue: 0,
      totalOverDue: 0,
      dueEvents: [],
    };
  }

  return state;
};

export const supplierCalendarEvents = (
  state = {
    totalDue: 0,
    totalOverDue: 0,
    dueEvents: [],
  },
  action
) => {
  if (action.type === 'FETCH_SUPPLIER_CALENDAR_EVENTS') {
    return action.payload;
  }

  if (action.type === 'CLEAR_SUPPLIER_CALENDAR_EVENTS') {
    return {
      totalDue: 0,
      totalOverDue: 0,
      dueEvents: [],
    };
  }

  return state;
};

export const courierList = (state = [], action) => {
  if (action.type === 'FETCH_COURIER_LIST') {
    return action.payload;
  }
  if (action.type === 'CLEAR_COURIER_LIST') {
    return [];
  }
  return state;
};

export const courierFilesList = (state = [], action) => {
  if (action.type === 'FETCH_COURIER_FILES_LIST') {
    return action.payload;
  }
  if (action.type === 'CLEAR_COURIER_FILES_LIST') {
    return [];
  }
  return state;
};

export const courier = (state = {}, action) => {
  if (action.type === 'FETCH_COURIER') {
    return action.payload;
  }
  if (action.type === 'CLEAR_COURIER') {
    return {};
  }
  return state;
};

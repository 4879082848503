export const charges = (state = [], action = {}) => {
  if (action.type === 'FETCH_CHARGES') {
    return action.payload;
  }
  if (action.type === 'CLEAR_CHARGES') {
    return [];
  }
  return state;
};

export const charge = (state = {}, action = {}) => {
  if (action.type === 'FETCH_CHARGE') {
    return action.payload;
  }
  if (action.type === 'CLEAR_CHARGE') {
    return {};
  }
  return state;
};

export const chargeIds = (state = [], action = {}) => {
  if (action.type === 'FETCH_CHARGE_IDS') {
    return action.payload;
  }
  if (action.type === 'CLEAR_CHARGE_IDS') {
    return [];
  }
  return state;
};

export const enquiryList = (state = [], action) => {
  if (action.type === 'FETCH_ENQUIRY_LIST') {
    return action.payload;
  }
  if (action.type === 'CLEAR_ENQUIRY_LIST') {
    return [];
  }
  return state;
};

export const enquiry = (state = {}, action) => {
  if (action.type === 'FETCH_ENQUIRY') {
    return action.payload;
  }
  if (action.type === 'CLEAR_ENQUIRY') {
    return {};
  }
  return state;
};

export const filesList = (state = [], action) => {
  if (action.type === 'FETCH_ENQUIRY_FILES_LIST') {
    return action.payload;
  }
  if (action.type === 'CLEAR_ENQUIRY_FILES_LIST') {
    return [];
  }
  return state;
};

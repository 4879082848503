import React, { useEffect, useState, Fragment } from 'react';
import PropTypes from 'prop-types';
import withStyles from 'react-jss';
import { connect, useDispatch, useSelector } from 'react-redux';
import { Button, Col, Input, Popconfirm, Row, Table, Tooltip, Upload } from 'antd';
import { Link } from 'react-router-dom';
import {
  CloudDownloadOutlined,
  CloudUploadOutlined,
  DeleteOutlined,
  EditOutlined,
  FileDoneOutlined,
} from '@ant-design/icons';
import moment from 'moment';
import Modal from 'antd/lib/modal/Modal';
import { Field, getFormValues, reduxForm, reset } from 'redux-form';
import { errorNotification, infoNotification, successNotification } from '../Snackbar';
import Api from '../Api';
import {
  clearCourierList,
  getCourierList,
  getCourierFilesList,
  clearCourierFilesList,
  getCourier,
  clearCourier,
} from '../Actions';
import { get, onFilter, sorter, filterDropdown, dateSorter, dateFilterDropdown, onDateFilter } from '../utils';
import styles from '../CommonStyles';
import Structure from '../Structure/Structure';
import Heading from '../Components/Heading';
import GetField from '../Components/GetField';

const AntInput = GetField(Input);

const CourierList = props => {
  const { classes } = props;
  const dispatch = useDispatch();

  const auth = useSelector(state => state.auth);
  const permissions = get(auth, ['permissions'], []);
  const courierList = useSelector(state => state.courierList);
  const courierFilesList = useSelector(state => state.courierFilesList);

  const [courierId, setCourierId] = useState(null);
  const [modalType, setModalType] = useState(null);
  const [fileList, setFileList] = useState([]);
  const [uploading, setUploading] = useState(false);
  const [confirmLoading, setConfirmLoading] = useState(false);
  const [rowCounts, setRowCounts] = useState(0);

  const FormValues = useSelector(state => getFormValues('CouriesDetailForm')(state));

  useEffect(() => {
    dispatch(getCourierList());
    return () => {
      dispatch(clearCourierList());
      dispatch(clearCourierFilesList());
      dispatch(clearCourier());
    };
  }, []);

  useEffect(() => {
    if (courierList) {
      setRowCounts(courierList.length);
    }
  }, [courierList]);

  useEffect(() => {
    if (courierId && modalType === 'ViewFiles') {
      dispatch(getCourierFilesList(courierId));
    }
    if (courierId && modalType === 'CourierDetails') {
      dispatch(getCourier(courierId));
    }
  }, [courierId, modalType]);

  const onDelete = async id => {
    try {
      await Api.delete(`/courier-entries/${id}`);
      successNotification('Courier deleted');
      dispatch(getCourierList());
    } catch (err) {
      errorNotification(err);
    }
  };

  const showModal = (id, type) => {
    setCourierId(id);
    setModalType(type);
  };

  const hideModal = () => {
    setCourierId(null);
    setModalType(null);
    setFileList([]);
    dispatch(clearCourierFilesList());
    dispatch(clearCourier());
  };

  const onUpload = async () => {
    try {
      setUploading(true);
      const formData = new FormData();
      fileList.forEach((file, index) => {
        formData.append(`files[${index}]`, file);
      });
      const { data } = await Api.post(`/courier-entries/${courierId}/files`, formData, {
        headers: { 'content-type': 'multipart/form-data' },
      });
      infoNotification(data);
      setUploading(false);
      hideModal();
      setFileList([]);
    } catch (err) {
      errorNotification(err);
    }
  };

  const onFileDelete = async id => {
    try {
      await Api.delete(`/courier-entries/${courierId}/files/${id}`);
      successNotification('File delete');
      dispatch(getCourierFilesList(courierId));
    } catch (err) {
      errorNotification(err);
    }
  };

  const onRemove = file => {
    const index = fileList.indexOf(file);
    const newFileList = fileList.slice();
    newFileList.splice(index, 1);
    setFileList(newFileList);
  };

  const beforeUpload = (file, files) => {
    setFileList([...fileList, ...files]);
    return false;
  };

  const onCourierSubmit = async () => {
    try {
      setConfirmLoading(true);
      await Api.post(`/courier-entries/${courierId}`, FormValues);
      successNotification('Courier detaile updated');
      setConfirmLoading(false);
      dispatch(reset('CouriesDetailForm'));
      hideModal();
      dispatch(getCourierList());
    } catch (err) {
      setConfirmLoading(false);
      errorNotification(err);
    }
  };

  const onCourierReceiptConfirmation = async body => {
    try {
      setConfirmLoading(true);
      await Api.put(`/courier-entries/confirm/${courierId}`, body);
      successNotification('Courier Receipt Confirmed');
      setConfirmLoading(false);
      hideModal();
      dispatch(getCourierList());
    } catch (err) {
      setConfirmLoading(false);
      errorNotification(err);
    }
  };

  const tooltipShow = arg => (
    <Tooltip placement="topLeft" title={arg}>
      {arg}
    </Tooltip>
  );

  const columns = [
    {
      title: 'Courier Date',
      dataIndex: 'courierDate',
      sorter: dateSorter('courierDate'),
      filterDropdown: dateFilterDropdown,
      onFilter: onDateFilter('courierDate'),
      render: courierDate => (courierDate ? moment(courierDate).format('DD MMM YYYY') : null),
    },
    {
      title: 'Consignee',
      dataIndex: 'consignee',
      filterDropdown,
      sorter: sorter('consignee'),
      onFilter: onFilter('consignee'),
      render: tooltipShow,
    },
    {
      title: 'Gross Weight',
      dataIndex: 'grossWeight',
      filterDropdown,
      sorter: sorter('grossWeight'),
      onFilter: onFilter('grossWeight'),
      ellipsis: { showTitle: false },
      render: tooltipShow,
      align: 'right',
    },
    {
      title: 'Content',
      dataIndex: 'content',
      filterDropdown,
      sorter: sorter('content'),
      onFilter: onFilter('content'),
      ellipsis: { showTitle: false },
      render: tooltipShow,
    },
    {
      title: 'Remarks',
      dataIndex: 'remarks',
      filterDropdown,
      sorter: sorter('remarks'),
      onFilter: onFilter('remarks'),
      ellipsis: { showTitle: false },
      render: tooltipShow,
    },
  ];

  const renderActionIcons = record => (
    <div>
      {permissions.includes('edit_courier') ? (
        <>
          <Tooltip placement="bottom" title="Edit">
            <Link to={`/courier/edit/${record.id}`}>
              <EditOutlined className={classes.tableIcon} />
            </Link>
          </Tooltip>
          &nbsp;&nbsp;&nbsp;&nbsp;
        </>
      ) : null}
      {permissions.includes('delete_courier') ? (
        <>
          <Tooltip placement="bottom" title="Delete">
            <Popconfirm
              title="Are you sure you want to delete?"
              onConfirm={() => onDelete(record.id)}
              okText="Yes"
              cancelText="No"
            >
              <DeleteOutlined className={classes.tableIcon} />
            </Popconfirm>
          </Tooltip>
        </>
      ) : null}
    </div>
  );

  if (permissions.includes('edit_courier') || permissions.includes('delete_courier')) {
    columns.push({
      title: 'Actions',
      width: 70,
      render: renderActionIcons,
    });
  }

  const renderCourierDetailsColumn = record => {
    if (permissions.includes('update_courier_details')) {
      return (
        <Tooltip placement="bottom" title="Courier Details.">
          <FileDoneOutlined className={classes.tableIcon} onClick={() => showModal(record.id, 'CourierDetails')} />
        </Tooltip>
      );
    }
    return null;
  };

  if (permissions.includes('update_courier_details')) {
    columns.push({
      title: 'Courier Details.',
      width: 70,
      render: renderCourierDetailsColumn,
    });
  }

  const renderCourierConfirmColumn = record => {
    if (permissions.includes('confirm_courier_receipt')) {
      if (!record.isConfirmed) {
        return (
          <Tooltip placement="bottom" title="Confirm Courier Receipt">
            <FileDoneOutlined
              className={classes.tableIcon}
              onClick={() => showModal(record.id, 'ConfirmCourierReceipt')}
            />
          </Tooltip>
        );
      }
      return 'Done';
    }
    return null;
  };

  if (permissions.includes('confirm_courier_receipt')) {
    columns.push({
      title: 'Courier Receipt',
      width: 70,
      render: renderCourierConfirmColumn,
    });
  }

  const renderPurchaseUploadColumn = record => {
    if (permissions.includes('courier_file_upload')) {
      return (
        <Tooltip placement="bottom" title="Upload Files">
          <CloudUploadOutlined className={classes.tableIcon} onClick={() => showModal(record.id, 'UploadFiles')} />
        </Tooltip>
      );
    }
    return null;
  };

  if (permissions.includes('courier_file_upload')) {
    columns.push({
      title: 'Upload Files',
      width: 70,
      render: renderPurchaseUploadColumn,
    });
  }

  const renderPurchaseViewColumn = record => {
    if (permissions.includes('courier_file_view')) {
      let color;
      if (record.files) {
        if (record.files > 0) {
          color = 'Green';
        } else {
          color = 'Red';
        }
      }
      return (
        <Tooltip placement="bottom" title="View Files">
          <CloudDownloadOutlined
            style={{ color }}
            className={classes.tableIcon}
            onClick={() => showModal(record.id, 'ViewFiles')}
          />
        </Tooltip>
      );
    }
    return null;
  };

  if (permissions.includes('courier_file_view')) {
    columns.push({
      title: 'View Files',
      width: 70,
      render: renderPurchaseViewColumn,
    });
  }

  const filesTableColumns = [];

  const renderFileTableActionColumn = record => {
    return (
      <Tooltip placement="bottom" title="Delete File">
        <Popconfirm
          title="Are you sure you want to delete?"
          onConfirm={() => onFileDelete(record.id)}
          okText="Yes"
          cancelText="No"
        >
          <DeleteOutlined className={classes.tableIcon} />
        </Popconfirm>
      </Tooltip>
    );
  };

  const renderFileTableNameColumn = record => (
    <a href={record.url} rel="noreferrer noopener" target="_blank">
      <Tooltip placement="bottom" title={`Click to open/download "${record.fileName}" file.`}>
        {record.fileName}
      </Tooltip>
    </a>
  );

  filesTableColumns.push({
    title: 'File',
    render: renderFileTableNameColumn,
  });

  filesTableColumns.push({
    title: 'Delete',
    render: renderFileTableActionColumn,
    width: 70,
  });

  return (
    <Structure>
      <>
        <Heading text={`Courier List (${rowCounts})`} />
        <Table
          columns={columns}
          bordered
          rowKey={record => record.id}
          pagination={{ defaultPageSize: 20, position: ['bottomLeft'] }}
          dataSource={courierList}
          size="small"
          scroll={{ x: 1050, y: window.innerHeight - 300 }}
          onChange={(_page, _filters, _sorter, { currentDataSource }) => {
            setRowCounts((currentDataSource || []).length);
          }}
        />
        <Modal
          onOk={hideModal}
          open={modalType === 'UploadFiles'}
          centered
          maskClosable={false}
          title="Upload Files."
          width="500px"
          okText="Close"
          cancelButtonProps={{ className: classes.hide }}
          okButtonProps={{ className: classes.button }}
          closable={false}
        >
          <Upload multiple onRemove={onRemove} beforeUpload={beforeUpload} fileList={fileList}>
            <Button icon={<CloudUploadOutlined />}>Select Files</Button>
          </Upload>
          <Button
            type="primary"
            onClick={onUpload}
            disabled={fileList.length === 0}
            loading={uploading}
            style={{ marginTop: 16 }}
          >
            {uploading ? 'Uploading' : 'Upload'}
          </Button>
        </Modal>
        <Modal
          onOk={hideModal}
          open={modalType === 'ViewFiles'}
          centered
          maskClosable={false}
          title="View Files."
          width="800px"
          okText="Close"
          cancelButtonProps={{ className: classes.hide }}
          closable={false}
          okButtonProps={{ className: classes.button }}
        >
          <Table
            columns={filesTableColumns}
            bordered
            rowKey={record => record.id}
            dataSource={courierFilesList}
            size="small"
            pagination={{ position: ['bottomLeft'] }}
          />
        </Modal>
        <Modal
          open={modalType === 'CourierDetails'}
          onCancel={hideModal}
          confirmLoading={confirmLoading}
          title="Courier Details."
          centered
          width="800px"
          maskClosable={false}
          closable={false}
          cancelText="Back"
          okButtonProps={{ className: classes.button }}
          okText={
            <Popconfirm
              title="Sure want to submit form?"
              onConfirm={() => onCourierSubmit()}
              okText="Confirm"
              cancelText="Cancel"
            >
              Submit
            </Popconfirm>
          }
        >
          <Row>
            <Col xs={{ span: 24 }} lg={{ span: 10 }}>
              <Field
                allowClear
                name="docketNo"
                label="Docket Number"
                placeholder="Docket Number"
                component={AntInput}
              />
            </Col>
            <Col xs={{ span: 24 }} lg={{ span: 10, offset: 4 }}>
              <Field
                required
                allowClear
                name="name"
                label="Service Provider"
                placeholder="Service Provider"
                component={AntInput}
              />
            </Col>
          </Row>
          <Row>
            <Col xs={{ span: 24 }} lg={{ span: 10 }}>
              <Field
                required
                allowClear
                min={0}
                type="number"
                name="rate"
                label="Rate"
                placeholder="Rate"
                component={AntInput}
              />
            </Col>
            <Col xs={{ span: 24 }} lg={{ span: 10, offset: 4 }}>
              <Field
                required
                allowClear
                min={0}
                type="number"
                name="amount"
                label="Amount"
                placeholder="Amount"
                component={AntInput}
              />
            </Col>
          </Row>
        </Modal>
        <Modal
          open={modalType === 'ConfirmCourierReceipt'}
          confirmLoading={confirmLoading}
          onCancel={hideModal}
          centered
          maskClosable={false}
          closable={false}
          title="Courier Receipt Confirmation"
          width="400px"
          cancelText="Back"
          okText={
            <Popconfirm
              title="Are you sure?"
              onConfirm={() => onCourierReceiptConfirmation({ isConfirmed: true })}
              okText="Confirm"
              cancelText="Cancel"
            >
              Confirm the receipt
            </Popconfirm>
          }
          okButtonProps={{ className: classes.button }}
        >
          <p>Sure you want to confirm the receipt?</p>
        </Modal>
      </>
    </Structure>
  );
};

CourierList.propTypes = {
  classes: PropTypes.object.isRequired,
};

const StyledOrderList = withStyles(styles)(CourierList);

const FormedCourierList = reduxForm({
  form: 'CouriesDetailForm',
  enableReinitialize: true,
})(StyledOrderList);

export default connect(({ courier }) => ({ initialValues: courier }), {})(FormedCourierList);

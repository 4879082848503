export const creditNoteList = (state = [], action) => {
  if (action.type === 'FETCH_CREDIT_NOTE_LIST') {
    return action.payload;
  }
  if (action.type === 'CLEAR_CREDIT_NOTE_LIST') {
    return [];
  }
  return state;
};

export const creditNoteFilesList = (state = [], action) => {
  if (action.type === 'FETCH_CREDIT_NOTE_FILES_LIST') {
    return action.payload;
  }
  if (action.type === 'CLEAR_CREDIT_NOTE_FILES_LIST') {
    return [];
  }
  return state;
};

export const creditNote = (state = {}, action) => {
  if (action.type === 'FETCH_CREDIT_NOTE') {
    return action.payload;
  }
  if (action.type === 'CLEAR_CREDIT_NOTE') {
    return {};
  }
  return state;
};

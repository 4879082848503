import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';
import withStyles from 'react-jss';
import { Button, Card, Col, Collapse, DatePicker, Modal, Row, Select, Table, Tooltip } from 'antd';
import startCase from 'lodash/startCase';
import moment from 'moment';
import styles from '../CommonStyles';
import { getTransactionList, clearTransactionList } from '../Actions';
import Structure from '../Structure/Structure';
import Heading from '../Components/Heading';
import { get, onFilter, sorter, filterDropdown, onDateFilter, dateFilterDropdown, dateSorter } from '../utils';
import constants from '../constants';
import Api from '../Api';

const { Panel } = Collapse;

const BalanceSheet = props => {
  const { classes } = props;
  const dispatch = useDispatch();

  const transactionList = useSelector(state => state.transactionList);

  const [fromData, SetFromData] = useState({
    balanceSheet: {},
    supplierWisePayables: [],
    customerWisePayables: [],
    assetValuesList: [],
  });
  const [toData, SetToData] = useState({
    balanceSheet: {},
    supplierWisePayables: [],
    customerWisePayables: [],
    assetValuesList: [],
  });
  const [Consignor, SetConsignor] = useState(constants.consignors.list.plusFormulationsHeadOffice);

  const [viewModal, setViewModal] = useState(false);
  const [ViewToModal, setViewToModal] = useState(false);
  const [narrationId, setNarrationId] = useState(null);
  const [parentHead, setParentHead] = useState(null);
  const [contactId, setContactId] = useState(null);
  const [customerId, setCustomerId] = useState(null);
  const [supplierId, setSupplierId] = useState(null);

  const [valueModal, SetValueModal] = useState(null);

  const fetchData = async () => {
    const date = new Date().toISOString();

    const { data: balanceFromData } = await Api.post('/financial-reports/balancesheet', { date });
    const { data: supplierFromData } = await Api.get(`/suppliers/dues`, { date });
    const { data: customerFromData } = await Api.get(`/customers/dues`, { date });
    const { data: assetFromData } = await Api.get('/assets/values', { date });
    const { data: financialSummaryFromData } = await Api.post('/financial-reports/financialsummary', {
      from: '2021-01-01T00:00:00.000Z',
      to: date,
      consignor: Consignor,
    });

    SetFromData({
      balanceSheet: balanceFromData,
      supplierWisePayables: supplierFromData,
      customerWisePayables: customerFromData,
      assetValuesList: assetFromData,
      financialSummary: financialSummaryFromData,
    });
  };

  useEffect(() => {
    fetchData();
  }, []);

  const handleFromChange = async from => {
    if (from) {
      const { data: balanceFromData } = await Api.post('/financial-reports/balancesheet', { date: from });
      const { data: supplierFromData } = await Api.get(`/suppliers/dues`, { date: from });
      const { data: customerFromData } = await Api.get(`/customers/dues`, { date: from });
      const { data: assetFromData } = await Api.get('/assets/values', { date: from });
      const { data: financialSummaryFromData } = await Api.post('/financial-reports/financialsummary', {
        from: '2021-01-01T00:00:00.000Z',
        to: from,
        consignor: Consignor,
      });

      SetFromData({
        balanceSheet: balanceFromData,
        supplierWisePayables: supplierFromData,
        customerWisePayables: customerFromData,
        assetValuesList: assetFromData,
        financialSummary: financialSummaryFromData,
      });
    }
  };

  const handleToChange = async to => {
    if (to) {
      const { data: balanceToData } = await Api.post('/financial-reports/balancesheet', { date: to });
      const { data: supplierToData } = await Api.get(`/suppliers/dues`, { date: to });
      const { data: customerToData } = await Api.get(`/customers/dues`, { date: to });
      const { data: assetToData } = await Api.get('/assets/values', { date: to });
      const { data: financialSummaryToData } = await Api.post('/financial-reports/financialsummary', {
        from: '2021-01-01T00:00:00.000Z',
        to,
        consignor: Consignor,
      });

      SetToData({
        balanceSheet: balanceToData,
        supplierWisePayables: supplierToData,
        customerWisePayables: customerToData,
        assetValuesList: assetToData,
        financialSummary: financialSummaryToData,
      });
    }
  };

  const resetTransactionList = () => {
    fetchData();

    SetToData({
      balanceSheet: {},
      supplierWisePayables: [],
      customerWisePayables: [],
      assetValuesList: [],
    });
  };

  useEffect(() => {
    if (narrationId || parentHead || contactId || customerId || supplierId) {
      dispatch(getTransactionList({ narrationId, parentHead, contactId, customerId, supplierId }));
    }
  }, [narrationId, parentHead, contactId, customerId, supplierId]);

  const showModal = (id, head, contact, customer, supplier) => {
    setViewModal(true);
    setNarrationId(id);
    setParentHead(head);
    setContactId(contact);
    setCustomerId(customer);
    setSupplierId(supplier);
  };

  const hideModal = () => {
    setViewModal(false);
    setNarrationId(null);
    setParentHead(null);
    setContactId(null);
    setCustomerId(null);
    setSupplierId(null);
    dispatch(clearTransactionList());
  };

  const showValueModal = type => {
    SetValueModal(type);
  };

  const hideValueModal = () => {
    SetValueModal(null);
  };

  const showToValueModal = type => {
    setViewToModal(type);
  };

  const hideToValueModal = () => {
    setViewToModal(null);
  };

  const tooltipShow = arg => (
    <Tooltip placement="topLeft" title={Object.values(constants.bank.types).includes(`${arg}`) ? startCase(arg) : arg}>
      {Object.values(constants.bank.types).includes(`${arg}`) ? startCase(arg) : arg}
    </Tooltip>
  );

  const TransactionColumns = [
    {
      title: 'Type',
      dataIndex: 'type',
      sorter: sorter('type'),
      filterDropdown,
      onFilter: onFilter('type'),
      width: 80,
      render: type => startCase(type),
    },
    {
      title: 'Bank',
      dataIndex: 'bank',
      sorter: sorter('bank'),
      filterDropdown,
      onFilter: onFilter('bank'),
      render: tooltipShow,
      width: 170,
    },
    {
      title: 'Narration',
      dataIndex: 'narration',
      sorter: sorter('narration'),
      filterDropdown,
      onFilter: onFilter('narration'),
      render: tooltipShow,
      width: 170,
    },
    {
      title: 'Head',
      dataIndex: 'parentHead',
      sorter: sorter('parentHead'),
      filterDropdown,
      onFilter: onFilter('parentHead'),
      render: tooltipShow,
      width: 170,
    },
    {
      title: 'Summary Head',
      dataIndex: 'head',
      sorter: sorter('head'),
      filterDropdown,
      onFilter: onFilter('head'),
      render: tooltipShow,
      width: 170,
    },
    {
      title: 'Date',
      dataIndex: 'transDate',
      sorter: dateSorter('transDate'),
      filterDropdown: dateFilterDropdown,
      onFilter: onDateFilter('transDate'),
      render: transDate => (transDate ? moment(transDate).format('DD MMM YYYY') : null),
      width: 120,
    },
    {
      title: 'Amount',
      dataIndex: 'amount',
      sorter: sorter('amount'),
      filterDropdown,
      onFilter: onFilter('amount'),
      render: amount => Number(amount).toLocaleString('en-IN'),
      width: 150,
      align: 'right',
    },
    {
      title: 'Closing Balance',
      dataIndex: 'closingBalance',
      sorter: sorter('closingBalance'),
      filterDropdown,
      onFilter: onFilter('closingBalance'),
      render: closingBalance => Number(closingBalance).toLocaleString('en-IN'),
      width: 150,
      align: 'right',
    },
    {
      title: 'Customer',
      dataIndex: 'customer',
      sorter: sorter('customer'),
      filterDropdown,
      onFilter: onFilter('customer'),
      render: tooltipShow,
      ellipsis: { showTitle: false },
      width: 150,
    },
    {
      title: 'Supplier',
      dataIndex: 'supplier',
      sorter: sorter('supplier'),
      filterDropdown,
      onFilter: onFilter('supplier'),
      render: tooltipShow,
      ellipsis: { showTitle: false },
      width: 150,
    },
    {
      title: 'Employee',
      dataIndex: 'employee',
      sorter: sorter('employee'),
      filterDropdown,
      onFilter: onFilter('employee'),
      render: tooltipShow,
      ellipsis: { showTitle: false },
      width: 150,
    },
    {
      title: 'Contact',
      dataIndex: 'contact',
      sorter: sorter('contact'),
      filterDropdown,
      onFilter: onFilter('contact'),
      render: tooltipShow,
      ellipsis: { showTitle: false },
      width: 150,
    },
    {
      title: 'Asset',
      dataIndex: 'asset',
      sorter: sorter('asset'),
      filterDropdown,
      onFilter: onFilter('asset'),
      render: tooltipShow,
      ellipsis: { showTitle: false },
      width: 100,
    },
    {
      title: 'Reference',
      dataIndex: 'refNo',
      sorter: sorter('refNo'),
      filterDropdown,
      onFilter: onFilter('refNo'),
      render: tooltipShow,
      ellipsis: { showTitle: false },
      width: 200,
    },
    {
      title: 'Remarks',
      dataIndex: 'remarks',
      sorter: sorter('remarks'),
      filterDropdown,
      onFilter: onFilter('remarks'),
      render: tooltipShow,
      ellipsis: { showTitle: false },
      width: 200,
    },
    {
      title: 'Done By',
      dataIndex: 'createdByUser',
      sorter: sorter('createdByUser'),
      filterDropdown,
      onFilter: onFilter('createdByUser'),
      width: 120,
    },
  ];

  const valueColumns = [
    {
      title: 'Name',
      dataIndex: 'name',
      sorter: sorter('name'),
      filterDropdown,
      onFilter: onFilter('name'),
    },
    {
      title: 'Balance',
      dataIndex: 'due',
      sorter: sorter('due'),
      filterDropdown,
      onFilter: onFilter('due'),
      render: tooltipShow,
    },
  ];

  const assetColumns = [
    {
      title: 'Name',
      dataIndex: 'name',
      sorter: sorter('name'),
      filterDropdown,
      onFilter: onFilter('name'),
    },
    {
      title: 'Value',
      dataIndex: 'value',
      sorter: sorter('value'),
      filterDropdown,
      onFilter: onFilter('value'),
      render: tooltipShow,
    },
  ];

  return (
    <Structure>
      <>
        <Heading text="Balance Sheet" />
        <Row gutter={[8, 8]}>
          <Col>
            <DatePicker
              placeholder="From"
              onChange={(date, dateString) => handleFromChange(moment(dateString).startOf('day').toISOString())}
            />
          </Col>
          <Col>
            <DatePicker
              placeholder="To"
              onChange={(date, dateString) => handleToChange(moment(dateString).endOf('day').toISOString())}
            />
          </Col>
          <Col>
            <Select onChange={SetConsignor} placeholder="Consignor" value={Consignor} style={{ width: 280 }}>
              {Object.values(constants.consignors.list).map(consignor => (
                <Select.Option key={consignor} value={consignor}>
                  {consignor}
                </Select.Option>
              ))}
            </Select>
          </Col>
          <Col>
            <Button type="default" onClick={resetTransactionList}>
              Reset
            </Button>
          </Col>
        </Row>
        <Row gutter={[8, 8]}>
          <Col xs={24} lg={12}>
            <Card>
              <Collapse bordered={false} expandIconPosition="right">
                <Panel
                  header={`Capital Account Total: ${Number(
                    get(fromData.balanceSheet, ['capitalAccount'], 0)
                  ).toLocaleString('en-IN')}`}
                  key="capitalAccount"
                >
                  {get(fromData.balanceSheet, ['capitalAccounts'], []).map(contact => (
                    <Row key={contact.name}>
                      <span>{contact.name}:&nbsp;</span>
                      <span className={classes.detailColor}>{Number(contact.balance).toLocaleString('en-IN')}</span>
                    </Row>
                  ))}
                </Panel>
              </Collapse>
              <br />
              <Collapse bordered={false} expandIconPosition="right">
                <Panel
                  header={`Loans Total: ${Number(get(fromData.balanceSheet, ['loan'], 0)).toLocaleString('en-IN')}`}
                  key="loan"
                >
                  {get(fromData.balanceSheet, ['loans'], []).map(loan => (
                    <Row key={loan.id}>
                      <span
                        className={classes.headingColor}
                        onClick={() => {
                          showModal(loan.id);
                        }}
                        onKeyDown={() => {
                          showModal(loan.id);
                        }}
                        role="button"
                        tabIndex={0}
                      >
                        {loan.name}:&nbsp;
                      </span>
                      <span>{Number(loan.balance).toLocaleString('en-IN')}</span>
                    </Row>
                  ))}
                </Panel>
              </Collapse>
              <br />
              <Collapse bordered={false} expandIconPosition="right">
                <Panel
                  header={`Other Loans Total: ${Number(get(fromData.balanceSheet, ['otherLoan'], 0)).toLocaleString(
                    'en-IN'
                  )}`}
                  key="otherLoan"
                >
                  {get(fromData.balanceSheet, ['otherLoans'], []).map(loan => (
                    <Row key={loan.contactId}>
                      <span
                        className={classes.headingColor}
                        onClick={() => {
                          showModal(loan.id, loan.parentHead, loan.contactId, loan.customerId, loan.supplierId);
                        }}
                        onKeyDown={() => {
                          showModal(loan.id);
                        }}
                        role="button"
                        tabIndex={0}
                      >
                        {loan.name}:&nbsp;
                      </span>
                      <span>{Number(loan.balance).toLocaleString('en-IN')}</span>
                    </Row>
                  ))}
                </Panel>
              </Collapse>
              <br />
              <Collapse bordered={false} expandIconPosition="right">
                <Panel
                  header={`Current Liabilities Total: ${Number(
                    get(fromData.balanceSheet, ['currentLiabilitiesTotal'], 0)
                  ).toLocaleString('en-IN')}`}
                  key="currentLiabilitiesTotal"
                >
                  {get(fromData.balanceSheet, ['currentLiabilities'], []).map(narration => (
                    <Row key={narration.id}>
                      <span
                        className={classes.headingColor}
                        onClick={() => {
                          showModal(
                            narration.id,
                            narration.parentHead,
                            narration.contactId,
                            narration.customerId,
                            narration.supplierId
                          );
                        }}
                        onKeyDown={() => {
                          showModal(
                            narration.id,
                            narration.parentHead,
                            narration.contactId,
                            narration.customerId,
                            narration.supplierId
                          );
                        }}
                        role="button"
                        tabIndex={0}
                      >
                        {narration?.narration}:&nbsp;
                      </span>
                      <span>{Number(narration.value).toLocaleString('en-IN')}</span>
                    </Row>
                  ))}
                </Panel>
              </Collapse>
              <br />
              <Row>
                <span
                  className={classes.headingColor}
                  onClick={() => {
                    showValueModal('supplier');
                  }}
                  onKeyDown={() => {
                    showValueModal('supplier');
                  }}
                  role="button"
                  tabIndex={0}
                >
                  Supplier Payable Total:&nbsp;
                </span>
                <span>{Number(get(fromData.balanceSheet, ['totalSupplierPayable'], 0)).toLocaleString('en-IN')}</span>
              </Row>
            </Card>
            <br />
            <Card>
              <Row>
                <span
                  className={classes.headingColor}
                  onClick={() => {
                    showValueModal('asset');
                  }}
                  onKeyDown={() => {
                    showValueModal('asset');
                  }}
                  role="button"
                  tabIndex={0}
                >
                  Fixed Asset Total:&nbsp;
                </span>
                <span>{Number(get(fromData.balanceSheet, ['fixedAssets'], 0)).toLocaleString('en-IN')}</span>
              </Row>
              <br />
              <Collapse bordered={false} expandIconPosition="right">
                <Panel
                  header={`Current Assets Total: ${Number(
                    get(fromData.balanceSheet, ['currentAssetsTotal'], 0)
                  ).toLocaleString('en-IN')}`}
                  key="currentAssetsTotal"
                >
                  {get(fromData.balanceSheet, ['currentAssets'], []).map(narration => (
                    <Row key={narration.id}>
                      <span
                        className={classes.headingColor}
                        onClick={() => {
                          showModal(
                            narration.id,
                            narration.parentHead,
                            narration.contactId,
                            narration.customerId,
                            narration.supplierId
                          );
                        }}
                        onKeyDown={() => {
                          showModal(
                            narration.id,
                            narration.parentHead,
                            narration.contactId,
                            narration.customerId,
                            narration.supplierId
                          );
                        }}
                        role="button"
                        tabIndex={0}
                      >
                        {narration?.narration}:&nbsp;
                      </span>
                      <span>{Number(narration.value).toLocaleString('en-IN')}</span>
                    </Row>
                  ))}
                </Panel>
              </Collapse>
              <br />
              <Row>
                <span
                  className={classes.headingColor}
                  onClick={() => {
                    showValueModal('customer');
                  }}
                  onKeyDown={() => {
                    showValueModal('customer');
                  }}
                  role="button"
                  tabIndex={0}
                >
                  Customer Receivable Total:&nbsp;
                </span>
                <span>
                  {Number(get(fromData.balanceSheet, ['totalCustomerReceivable'], 0)).toLocaleString('en-IN')}
                </span>
              </Row>
              <br />
              <Collapse bordered={false} expandIconPosition="right">
                <Panel header="Bank/Cash Balances" key="Bank/Cash Balances">
                  {get(fromData.balanceSheet, ['banks'], []).map(bank => (
                    <Row key={bank.id}>
                      <span>{bank?.bank}:&nbsp;</span>
                      <span className={classes.detailColor}>{Number(bank.balance).toLocaleString('en-IN')}</span>
                    </Row>
                  ))}
                </Panel>
              </Collapse>
              <br />
              <Collapse bordered={false} expandIconPosition="right">
                <Panel
                  header={`Closing Inventory Total: ${Number(
                    get(fromData.balanceSheet, ['totalClosingInventory'], 0)
                  ).toLocaleString('en-IN')}`}
                  key="totalClosingInventory"
                >
                  <Row>
                    Closing Raw Material Inventory:
                    <span className={classes.detailColor}>
                      {Number(get(fromData.balanceSheet, ['closingRawMaterialInventory'], 0)).toLocaleString('en-IN')}
                    </span>
                  </Row>
                  <Row>
                    Closing Packing Material Inventory:
                    <span className={classes.detailColor}>
                      {Number(get(fromData.balanceSheet, ['closingPackingMaterialInventory'], 0)).toLocaleString(
                        'en-IN'
                      )}
                    </span>
                  </Row>
                  <Row>
                    Closing Printed Packing Material Inventory:
                    <span className={classes.detailColor}>
                      {Number(
                        get(fromData.balanceSheet, ['closingPrintedPackagingMaterialInventory'], 0)
                      ).toLocaleString('en-IN')}
                    </span>
                  </Row>
                  <Row>
                    Closing Consumable Material Inventory:
                    <span className={classes.detailColor}>
                      {Number(get(fromData.balanceSheet, ['closingConsumableMaterialInventory'], 0)).toLocaleString(
                        'en-IN'
                      )}
                    </span>
                  </Row>
                  <Row>
                    Closing Manufacturing Materials Inventory:
                    <span className={classes.detailColor}>
                      {Number(get(fromData.balanceSheet, ['closingManufacturingMaterialInventory'], 0)).toLocaleString(
                        'en-IN'
                      )}
                    </span>
                  </Row>
                  <Row>
                    Closing Ready To Dispatch Materials Inventory:
                    <span className={classes.detailColor}>
                      {Number(
                        get(fromData.balanceSheet, ['closingReadyToDispatchMaterialInventory'], 0)
                      ).toLocaleString('en-IN')}
                    </span>
                  </Row>
                </Panel>
              </Collapse>
            </Card>
            <br />
            <Card>
              <Row>
                Working Capital:&nbsp;
                <span className={classes.detailColor}>
                  {Number(get(fromData.balanceSheet, ['workingCapital'], 0)).toLocaleString('en-IN')}
                </span>
              </Row>
              <br />
              <Row>
                Working Capital Ratio:&nbsp;
                <span className={classes.detailColor}>
                  {Number(get(fromData.balanceSheet, ['workingCapitalRatio'], 0)).toLocaleString('en-IN')}
                </span>
              </Row>
              <br />
              <Row>
                Current Ratio:&nbsp;
                <span className={classes.detailColor}>
                  {Number(get(fromData.balanceSheet, ['currentRatio'], 0)).toLocaleString('en-IN')}
                </span>
              </Row>
              <br />
              <Row>
                Quick Ratio:&nbsp;
                <span className={classes.detailColor}>
                  {Number(get(fromData.balanceSheet, ['quickRatio'], 0)).toLocaleString('en-IN')}
                </span>
              </Row>
            </Card>
          </Col>
          <Col xs={24} lg={12}>
            <Card>
              <Collapse bordered={false} expandIconPosition="right">
                <Panel
                  header={`Capital Account Total: ${Number(
                    get(toData.balanceSheet, ['capitalAccount'], 0)
                  ).toLocaleString('en-IN')}`}
                  key="capitalAccount"
                >
                  {get(toData.balanceSheet, ['capitalAccounts'], []).map(contact => (
                    <Row key={contact.name}>
                      <span>{contact.name}:&nbsp;</span>
                      <span className={classes.detailColor}>{Number(contact.balance).toLocaleString('en-IN')}</span>
                    </Row>
                  ))}
                </Panel>
              </Collapse>
              <br />
              <Collapse bordered={false} expandIconPosition="right">
                <Panel
                  header={`Loans Total: ${Number(get(toData.balanceSheet, ['loan'], 0)).toLocaleString('en-IN')}`}
                  key="loan"
                >
                  {get(toData.balanceSheet, ['loans'], []).map(loan => (
                    <Row key={loan.id}>
                      <span
                        className={classes.headingColor}
                        onClick={() => {
                          showModal(loan.id);
                        }}
                        onKeyDown={() => {
                          showModal(loan.id);
                        }}
                        role="button"
                        tabIndex={0}
                      >
                        {loan.name}:&nbsp;
                      </span>
                      <span>{Number(loan.balance).toLocaleString('en-IN')}</span>
                    </Row>
                  ))}
                </Panel>
              </Collapse>
              <br />
              <Collapse bordered={false} expandIconPosition="right">
                <Panel
                  header={`Other Loans Total: ${Number(get(toData.balanceSheet, ['otherLoan'], 0)).toLocaleString(
                    'en-IN'
                  )}`}
                  key="otherLoan"
                >
                  {get(toData.balanceSheet, ['otherLoans'], []).map(loan => (
                    <Row key={loan.contactId}>
                      <span
                        className={classes.headingColor}
                        onClick={() => {
                          showModal(loan.id, loan.parentHead, loan.contactId, loan.customerId, loan.supplierId);
                        }}
                        onKeyDown={() => {
                          showModal(loan.id);
                        }}
                        role="button"
                        tabIndex={0}
                      >
                        {loan.name}:&nbsp;
                      </span>
                      <span>{Number(loan.balance).toLocaleString('en-IN')}</span>
                    </Row>
                  ))}
                </Panel>
              </Collapse>
              <br />
              <Collapse bordered={false} expandIconPosition="right">
                <Panel
                  header={`Current Liabilities Total: ${Number(
                    get(toData.balanceSheet, ['currentLiabilitiesTotal'], 0)
                  ).toLocaleString('en-IN')}`}
                  key="currentLiabilitiesTotal"
                >
                  {get(toData.balanceSheet, ['currentLiabilities'], []).map(narration => (
                    <Row key={narration.id}>
                      <span
                        className={classes.headingColor}
                        onClick={() => {
                          showModal(
                            narration.id,
                            narration.parentHead,
                            narration.contactId,
                            narration.customerId,
                            narration.supplierId
                          );
                        }}
                        onKeyDown={() => {
                          showModal(
                            narration.id,
                            narration.parentHead,
                            narration.contactId,
                            narration.customerId,
                            narration.supplierId
                          );
                        }}
                        role="button"
                        tabIndex={0}
                      >
                        {narration?.narration}:&nbsp;
                      </span>
                      <span>{Number(narration.value).toLocaleString('en-IN')}</span>
                    </Row>
                  ))}
                </Panel>
              </Collapse>
              <br />
              <Row>
                <span
                  className={classes.headingColor}
                  onClick={() => {
                    showToValueModal('supplier');
                  }}
                  onKeyDown={() => {
                    showToValueModal('supplier');
                  }}
                  role="button"
                  tabIndex={0}
                >
                  Supplier Payable Total:&nbsp;
                </span>
                <span>{Number(get(toData.balanceSheet, ['totalSupplierPayable'], 0)).toLocaleString('en-IN')}</span>
              </Row>
            </Card>
            <br />
            <Card>
              <Row>
                <span
                  className={classes.headingColor}
                  onClick={() => {
                    showToValueModal('asset');
                  }}
                  onKeyDown={() => {
                    showToValueModal('asset');
                  }}
                  role="button"
                  tabIndex={0}
                >
                  Fixed Asset Total:&nbsp;
                </span>
                <span>{Number(get(toData.balanceSheet, ['fixedAssets'], 0)).toLocaleString('en-IN')}</span>
              </Row>
              <br />
              <Collapse bordered={false} expandIconPosition="right">
                <Panel
                  header={`Current Assets Total: ${Number(
                    get(toData.balanceSheet, ['currentAssetsTotal'], 0)
                  ).toLocaleString('en-IN')}`}
                  key="currentAssetsTotal"
                >
                  {get(toData.balanceSheet, ['currentAssets'], []).map(narration => (
                    <Row key={narration.id}>
                      <span
                        className={classes.headingColor}
                        onClick={() => {
                          showModal(
                            narration.id,
                            narration.parentHead,
                            narration.contactId,
                            narration.customerId,
                            narration.supplierId
                          );
                        }}
                        onKeyDown={() => {
                          showModal(
                            narration.id,
                            narration.parentHead,
                            narration.contactId,
                            narration.customerId,
                            narration.supplierId
                          );
                        }}
                        role="button"
                        tabIndex={0}
                      >
                        {narration?.narration}:&nbsp;
                      </span>
                      <span>{Number(narration.value).toLocaleString('en-IN')}</span>
                    </Row>
                  ))}
                </Panel>
              </Collapse>
              <br />
              <Row>
                <span
                  className={classes.headingColor}
                  onClick={() => {
                    showToValueModal('customer');
                  }}
                  onKeyDown={() => {
                    showToValueModal('customer');
                  }}
                  role="button"
                  tabIndex={0}
                >
                  Customer Receivable Total:&nbsp;
                </span>
                <span>{Number(get(toData.balanceSheet, ['totalCustomerReceivable'], 0)).toLocaleString('en-IN')}</span>
              </Row>
              <br />
              <Collapse bordered={false} expandIconPosition="right">
                <Panel header="Bank/Cash Balances" key="Bank/Cash Balances">
                  {get(toData.balanceSheet, ['banks'], []).map(bank => (
                    <Row key={bank.id}>
                      <span>{bank?.bank}:&nbsp;</span>
                      <span className={classes.detailColor}>{Number(bank.balance).toLocaleString('en-IN')}</span>
                    </Row>
                  ))}
                </Panel>
              </Collapse>
              <br />
              <Collapse bordered={false} expandIconPosition="right">
                <Panel
                  header={`Closing Inventory Total: ${Number(
                    get(toData.balanceSheet, ['totalClosingInventory'], 0)
                  ).toLocaleString('en-IN')}`}
                  key="totalClosingInventory"
                >
                  <Row>
                    Closing Raw Material Inventory:
                    <span className={classes.detailColor}>
                      {Number(get(toData.balanceSheet, ['closingRawMaterialInventory'], 0)).toLocaleString('en-IN')}
                    </span>
                  </Row>
                  <Row>
                    Closing Packing Material Inventory:
                    <span className={classes.detailColor}>
                      {Number(get(toData.balanceSheet, ['closingPackingMaterialInventory'], 0)).toLocaleString('en-IN')}
                    </span>
                  </Row>
                  <Row>
                    Closing Printed Packing Material Inventory:
                    <span className={classes.detailColor}>
                      {Number(get(toData.balanceSheet, ['closingPrintedPackagingMaterialInventory'], 0)).toLocaleString(
                        'en-IN'
                      )}
                    </span>
                  </Row>
                  <Row>
                    Closing Consumable Material Inventory:
                    <span className={classes.detailColor}>
                      {Number(get(toData.balanceSheet, ['closingConsumableMaterialInventory'], 0)).toLocaleString(
                        'en-IN'
                      )}
                    </span>
                  </Row>
                  <Row>
                    Closing Manufacturing Materials Inventory:
                    <span className={classes.detailColor}>
                      {Number(get(toData.balanceSheet, ['closingManufacturingMaterialInventory'], 0)).toLocaleString(
                        'en-IN'
                      )}
                    </span>
                  </Row>
                  <Row>
                    Closing Ready To Dispatch Materials Inventory:
                    <span className={classes.detailColor}>
                      {Number(get(toData.balanceSheet, ['closingReadyToDispatchMaterialInventory'], 0)).toLocaleString(
                        'en-IN'
                      )}
                    </span>
                  </Row>
                </Panel>
              </Collapse>
            </Card>
            <br />
            <Card>
              <Row>
                Working Capital:&nbsp;
                <span className={classes.detailColor}>
                  {Number(get(toData.balanceSheet, ['workingCapital'], 0)).toLocaleString('en-IN')}
                </span>
              </Row>
              <br />
              <Row>
                Working Capital Ratio:&nbsp;
                <span className={classes.detailColor}>
                  {Number(get(toData.balanceSheet, ['workingCapitalRatio'], 0)).toLocaleString('en-IN')}
                </span>
              </Row>
              <br />
              <Row>
                Current Ratio:&nbsp;
                <span className={classes.detailColor}>
                  {Number(get(toData.balanceSheet, ['currentRatio'], 0)).toLocaleString('en-IN')}
                </span>
              </Row>
              <br />
              <Row>
                Quick Ratio:&nbsp;
                <span className={classes.detailColor}>
                  {Number(get(toData.balanceSheet, ['quickRatio'], 0)).toLocaleString('en-IN')}
                </span>
              </Row>
            </Card>
          </Col>
        </Row>
        <Row>
          <Col
            xs={{ span: 22, offset: 1 }}
            sm={{ span: 22, offset: 1 }}
            md={{ span: 22, offset: 1 }}
            lg={{ span: 14, offset: 5 }}
            xl={{ span: 12, offset: 6 }}
          >
            <Card>
              <br />
              <Row>
                Debtors Turnover Days ((Sale + Other Income)/Customer Receivable Total):&nbsp;
                <>
                  <div>
                    ToSale: {+get(toData.financialSummary, ['totalSales'], 0)}
                    <br />
                    ToOtherIncome: {+get(toData.financialSummary, ['totalOtherIncome'], 0)}
                    <br />
                    FromSale: {+get(fromData.financialSummary, ['totalSales'], 0)}
                    <br />
                    FromOtherIncome: {+get(fromData.financialSummary, ['totalOtherIncome'], 0)}
                    <br />
                    ToCustomerReceivable: {+get(toData.balanceSheet, ['totalCustomerReceivable'], 0)}
                    <br />
                    FromCustomerReceivable: {+get(fromData.balanceSheet, ['totalCustomerReceivable'], 0)}
                    <br />
                    Sale:{' '}
                    {+get(toData.financialSummary, ['totalSales'], 0) -
                      +get(fromData.financialSummary, ['totalSales'], 0)}
                    <br />
                    OtherIncome:{' '}
                    {+get(toData.financialSummary, ['totalOtherIncome'], 0) -
                      +get(fromData.financialSummary, ['totalOtherIncome'], 0)}
                    <br />
                    CustomerReceivable:{' '}
                    {(+get(toData.balanceSheet, ['totalCustomerReceivable'], 0) +
                      +get(fromData.balanceSheet, ['totalCustomerReceivable'], 0)) /
                      2}
                    <br />
                  </div>
                  <br />
                  <br />
                  <br />
                  <br />
                  <span className={classes.detailColor}>
                    {Number(
                      Math.round(
                        ((+get(toData.financialSummary, ['totalSales'], 0) +
                          +get(toData.financialSummary, ['totalOtherIncome'], 0) -
                          (+get(fromData.financialSummary, ['totalSales'], 0) +
                            +get(fromData.financialSummary, ['totalOtherIncome'], 0))) /
                          ((+get(toData.balanceSheet, ['totalCustomerReceivable'], 0) +
                            +get(fromData.balanceSheet, ['totalCustomerReceivable'], 0)) /
                            2)) *
                          100
                      )
                    ).toLocaleString('en-IN')}
                  </span>
                </>
              </Row>
              <br />
              <Row>
                Inventory Turnover Days (TVC/Closing Stock):&nbsp;
                <span className={classes.detailColor}>
                  {Number(
                    Math.round(
                      ((+get(toData.financialSummary, ['tvc'], 0) - +get(fromData.financialSummary, ['tvc'], 0)) /
                        ((+get(toData.financialSummary, ['totalClosingInventory'], 0) +
                          +get(fromData.financialSummary, ['totalClosingInventory'], 0)) /
                          2)) *
                        100
                    )
                  ).toLocaleString('en-IN')}
                </span>
              </Row>
              <br />
              <Row>
                Creditors Turnover Days (TVC/Supplier Payable Total):&nbsp;
                <span className={classes.detailColor}>
                  {Number(
                    Math.round(
                      ((+get(toData.financialSummary, ['tvc'], 0) - +get(fromData.financialSummary, ['tvc'], 0)) /
                        ((get(toData.balanceSheet, ['totalSupplierPayable'], 0) +
                          get(fromData.balanceSheet, ['totalSupplierPayable'], 0)) /
                          2)) *
                        100
                    )
                  ).toLocaleString('en-IN')}
                </span>
              </Row>
              <br />
              <Row>
                Cash Conversion Days (Debtors Turnover Days + Inventory Turnover Days - Creditors Turnover Days):&nbsp;
                <span className={classes.detailColor}>
                  {Number(
                    Math.round(
                      ((+get(toData.financialSummary, ['totalSales'], 0) +
                        +get(toData.financialSummary, ['totalOtherIncome'], 0) -
                        (+get(fromData.financialSummary, ['totalSales'], 0) +
                          +get(fromData.financialSummary, ['totalOtherIncome'], 0))) /
                        ((+get(toData.balanceSheet, ['totalCustomerReceivable'], 0) +
                          +get(fromData.balanceSheet, ['totalCustomerReceivable'], 0)) /
                          2)) *
                        100 +
                        ((+get(toData.financialSummary, ['tvc'], 0) - +get(fromData.financialSummary, ['tvc'], 0)) /
                          ((+get(toData.financialSummary, ['totalClosingInventory'], 0) +
                            +get(fromData.financialSummary, ['totalClosingInventory'], 0)) /
                            2)) *
                          100 -
                        ((+get(toData.financialSummary, ['tvc'], 0) - +get(fromData.financialSummary, ['tvc'], 0)) /
                          ((get(toData.balanceSheet, ['totalSupplierPayable'], 0) +
                            get(fromData.balanceSheet, ['totalSupplierPayable'], 0)) /
                            2)) *
                          100
                    )
                  ).toLocaleString('en-IN')}
                </span>
              </Row>
              <br />
              <Row>
                Cash Conversion Amount (Debtors + Inventory - Creditors ):&nbsp;
                <span className={classes.detailColor}>
                  {Number(
                    +(
                      +get(toData.balanceSheet, ['totalCustomerReceivable'], 0) +
                      +get(toData.financialSummary, ['totalClosingInventory'], 0) -
                      +get(toData.balanceSheet, ['totalSupplierPayable'], 0)
                    ).toFixed(2)
                  ).toLocaleString('en-IN')}
                </span>
              </Row>
            </Card>
          </Col>
        </Row>
        <Modal
          open={viewModal}
          onOk={hideModal}
          style={{ top: 20 }}
          maskClosable={false}
          closable={false}
          width="1300px"
          okText="Close"
          cancelButtonProps={{ className: classes.hide }}
          okButtonProps={{ className: classes.button }}
        >
          <Table
            columns={TransactionColumns}
            bordered
            rowKey={record => record.id}
            pagination={{ defaultPageSize: 50, position: ['bottomLeft'] }}
            dataSource={transactionList}
            size="small"
            scroll={{ x: 1280, y: 600 }}
          />
        </Modal>
        <Modal
          open={valueModal}
          onOk={hideValueModal}
          style={{ top: 20 }}
          maskClosable={false}
          closable={false}
          width="80%"
          okText="Close"
          cancelButtonProps={{ className: classes.hide }}
          okButtonProps={{ className: classes.button }}
        >
          <Table
            columns={valueModal === 'asset' ? assetColumns : valueColumns}
            bordered
            rowKey={record => record.id}
            pagination={false}
            dataSource={
              // eslint-disable-next-line no-nested-ternary
              valueModal === 'supplier'
                ? fromData.supplierWisePayables
                : valueModal === 'customer'
                ? fromData.customerWisePayables
                : fromData.assetValuesList
            }
            size="small"
            scroll={{ x: 1280, y: 600 }}
          />
        </Modal>
        <Modal
          open={ViewToModal}
          onOk={hideToValueModal}
          style={{ top: 20 }}
          maskClosable={false}
          closable={false}
          width="80%"
          okText="Close"
          cancelButtonProps={{ className: classes.hide }}
          okButtonProps={{ className: classes.button }}
        >
          <Table
            columns={ViewToModal === 'asset' ? assetColumns : valueColumns}
            bordered
            rowKey={record => record.id}
            pagination={false}
            dataSource={
              // eslint-disable-next-line no-nested-ternary
              ViewToModal === 'supplier'
                ? toData.supplierWisePayables
                : ViewToModal === 'customer'
                ? toData.customerWisePayables
                : toData.assetValuesList
            }
            size="small"
            scroll={{ x: 1280, y: 600 }}
          />
        </Modal>
      </>
    </Structure>
  );
};

BalanceSheet.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(BalanceSheet);

import Api from '../Api';

export const getEmployeeList = query => async dispatch => {
  try {
    const { data } = await Api.get('/employees', query);
    dispatch({
      type: 'FETCH_EMPLOYEE_LIST',
      payload: data,
    });
  } catch (err) {
    throw err;
  }
};

export const clearEmployeeList = () => {
  return {
    type: 'CLEAR_EMPLOYEE_LIST',
  };
};

export const getEmployee = id => async dispatch => {
  try {
    const { data } = await Api.get(`/employees/${id}`);
    dispatch({
      type: 'FETCH_EMPLOYEE',
      payload: data,
    });
  } catch (err) {
    throw err;
  }
};

export const clearEmployee = () => {
  return {
    type: 'CLEAR_EMPLOYEE',
  };
};

export const getEmployeeFilesList = id => async dispatch => {
  try {
    const { data } = await Api.get(`/employees/${id}/files`);
    dispatch({
      type: 'FETCH_EMPLOYEE_FILES_LIST',
      payload: data,
    });
  } catch (err) {
    throw err;
  }
};

export const clearEmployeeFilesList = () => {
  return {
    type: 'CLEAR_EMPLOYEE_FILES_LIST',
  };
};

export const getEmployeeIdList = params => async dispatch => {
  try {
    const { data } = await Api.get('/employees/ids', params);
    dispatch({
      type: 'FETCH_EMPLOYEE_ID_LIST',
      payload: data,
    });
  } catch (err) {
    throw err;
  }
};

export const clearEmployeeIdList = () => {
  return {
    type: 'CLEAR_EMPLOYEE_ID_LIST',
  };
};

export const getEmployeeLedger = id => async dispatch => {
  try {
    const { data } = await Api.get(`/employees/${id}/ledger`);
    dispatch({
      type: 'FETCH_EMPLOYEE_LEDGER',
      payload: data,
    });
  } catch (err) {
    throw err;
  }
};

export const clearEmployeeLedger = () => {
  return {
    type: 'CLEAR_EMPLOYEE_LEDGER',
  };
};

export const getEmployeeAdvance = id => async dispatch => {
  try {
    const { data } = await Api.get(`/employees/${id}/advance`);
    dispatch({
      type: 'FETCH_EMPLOYEE_ADVANCE',
      payload: data,
    });
  } catch (err) {
    throw err;
  }
};

export const clearEmployeeAdvance = () => {
  return {
    type: 'CLEAR_EMPLOYEE_ADVANCE',
  };
};

export const getEmployeeSalaryRecords = id => async dispatch => {
  try {
    const { data } = await Api.get(`/employees/${id}/details`);
    dispatch({
      type: 'FETCH_EMPLOYEE_SALARY_RECORDS',
      payload: data,
    });
  } catch (err) {
    throw err;
  }
};

export const clearEmployeeSalaryRecords = () => {
  return {
    type: 'CLEAR_EMPLOYEE_SALARY_RECORDS',
  };
};

export const getMonthWiseSalaryRecords = query => async dispatch => {
  try {
    const { data } = await Api.get(`/employees/monthwise`, query);
    dispatch({
      type: 'FETCH_EMPLOYEE_MONTH_WISE_SALARY_RECORDS',
      payload: data,
    });
  } catch (err) {
    throw err;
  }
};

export const clearMonthWiseSalaryRecords = () => {
  return {
    type: 'CLEAR_EMPLOYEE_MONTH_WISE_SALARY_RECORDS',
  };
};

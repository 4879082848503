export const transporterList = (state = [], action) => {
  if (action.type === 'FETCH_TRANSPORTER_LIST') {
    return action.payload;
  }
  if (action.type === 'CLEAR_TRANSPORTER_LIST') {
    return [];
  }
  return state;
};

export const transporter = (state = {}, action) => {
  if (action.type === 'FETCH_TRANSPORTER') {
    return action.payload;
  }
  if (action.type === 'CLEAR_TRANSPORTER') {
    return {};
  }
  return state;
};

export const transporterIdList = (state = [], action) => {
  if (action.type === 'FETCH_TRANSPORTER_ID_LIST') {
    return action.payload;
  }
  if (action.type === 'CLEAR_TRANSPORTER_ID_LIST') {
    return [];
  }
  return state;
};

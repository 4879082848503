/* eslint-disable react/prop-types */
import React from 'react';
import { Form, Select, DatePicker, Checkbox, InputNumber, TimePicker, Input } from 'antd';
import moment from 'moment';

const layout = {
  labelCol: { span: 24 },
};

const GetField = Component =>
  React.forwardRef(({ meta, children, hasFeedback, style, label, componentStyle, ...rest }, ref) => {
    const hasError = meta && meta.touched && meta.invalid;

    if (Component === DatePicker || Component === TimePicker || Component === DatePicker.MonthPicker) {
      rest.value = rest.value ? moment(new Date(rest.value)) : null;
    }

    if (Component === Input.TextArea) {
      rest.autoSize = { minRows: 1, maxRows: 6 };
    }

    if (Component === Select) {
      rest.showSearch = rest.showSearch || (rest ? rest.showSearch : null) || true;
      rest.optionFilterProp = rest.optionFilterProp || (rest ? rest.optionFilterProp : 'children') || 'children';
      delete rest.onBlur;
    }

    if (Component === Checkbox) {
      rest.checked = !!rest.value;
      delete rest.value;
    }

    if (Component === InputNumber) {
      rest.step = +rest.step || 1;

      const [, digits] = rest.step.toString().split('.');

      rest.pattern = `^\\d*${+rest.step < 1 ? `(.\\d{0,${digits.length}})?` : ''}$`;

      rest.precision = digits ? digits.length : 0;
    }

    return (
      <Form.Item
        {...layout}
        label={label}
        validateStatus={hasError ? 'error' : 'success'}
        hasFeedback={hasFeedback && hasError}
        help={hasError && meta.error}
        style={style}
      >
        <Component className="input" {...rest} ref={ref} step="any" style={componentStyle}>
          {children}
        </Component>
      </Form.Item>
    );
  });

export default GetField;

import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import withStyles from 'react-jss';
import Modal from 'antd/lib/modal/Modal';
import { Col, DatePicker, Input, Popconfirm, Row } from 'antd';
import { Field, getFormValues, reduxForm, reset } from 'redux-form';
import { useDispatch, useSelector } from 'react-redux';
import startCase from 'lodash/startCase';

import styles from '../CommonStyles';
import GetField from '../Components/GetField';
import Api from '../Api';
import { errorNotification, successNotification } from '../Snackbar';
import { get } from '../utils';

const AntDatePicker = GetField(DatePicker);
const AntInput = GetField(Input);

const Modals = props => {
  const { classes, isModalVisible, requestType, ticketId, hideModal, ticketData, change } = props;

  const FormValues = useSelector(state => getFormValues('TicketForm')(state));
  const [confirmLoading, setConfirmLoading] = useState(false);
  const dispatch = useDispatch();

  useEffect(() => {
    return () => {
      dispatch(reset('TicketForm'));
    };
  }, [hideModal]);

  useEffect(() => {
    if (requestType === 'extendExtension') {
      change('dueDate', get(ticketData, ['lastRequestRevision', 'requestedDueDate'], null));
    }
  }, [requestType === 'extendExtension', ticketData]);

  const onSubmit = async () => {
    try {
      setConfirmLoading(true);
      if (requestType === 'requestExtension') {
        await Api.put(`/tickets/${ticketId}/requestextension`, FormValues);
        successNotification('Extention requested');
        setConfirmLoading(false);
        dispatch(reset('TicketForm'));
        hideModal();
      }
      if (requestType === 'extendExtension') {
        await Api.put(`/tickets/${ticketId}/extend`, FormValues);
        successNotification('Extention extended');
        setConfirmLoading(false);
        dispatch(reset('TicketForm'));
        hideModal();
      }
      if (requestType === 'rejectExtension') {
        await Api.put(`/tickets/${ticketId}/reject`, FormValues);
        successNotification('Extention rejected');
        setConfirmLoading(false);
        dispatch(reset('TicketForm'));
        hideModal();
      }
      if (requestType === 'createRevision') {
        await Api.put(`/tickets/${ticketId}/revision`, FormValues);
        successNotification('Revision created');
        setConfirmLoading(false);
        dispatch(reset('TicketForm'));
        hideModal();
      }
      if (requestType === 'complete') {
        await Api.put(`/tickets/${ticketId}/completefromuser`, FormValues);
        successNotification('Ticket completed');
        setConfirmLoading(false);
        dispatch(reset('TicketForm'));
        hideModal();
      }
    } catch (err) {
      setConfirmLoading(false);
      errorNotification(err);
    }
  };

  return (
    <Modal
      open={isModalVisible}
      onCancel={hideModal}
      confirmLoading={confirmLoading}
      centered
      maskClosable={false}
      title={startCase(requestType)}
      width="600px"
      closable={false}
      cancelText="Cancel"
      okText={
        <Popconfirm title="Sure want to submit form?" onConfirm={() => onSubmit()} okText="Confirm" cancelText="Cancel">
          Submit
        </Popconfirm>
      }
      okButtonProps={{ className: classes.button }}
    >
      <Row>
        {requestType === 'rejectExtension' || requestType === 'complete' ? null : (
          <Col span={24}>
            <Field
              allowClear
              required
              showTime={{ format: 'HH:mm' }}
              use12Hours
              name="dueDate"
              label="Due Date"
              placeholder="Due Date"
              component={AntDatePicker}
            />
          </Col>
        )}
        <Col span={24}>
          <Field allowClear required name="remark" label="Remark" placeholder="Remark" component={AntInput} />
        </Col>
      </Row>
    </Modal>
  );
};

Modals.propTypes = {
  isModalVisible: PropTypes.bool,
  requestType: PropTypes.string,
  ticketId: PropTypes.number,
  hideModal: PropTypes.func,
  ticketData: PropTypes.object,
  classes: PropTypes.object.isRequired,
  change: PropTypes.func.isRequired,
};

const StylesModal = withStyles(styles)(Modals);

const FormedModal = reduxForm({
  form: 'TicketForm',
  enableReinitialize: true,
})(StylesModal);

export default FormedModal;

export const formulationInventory = (state = [], action) => {
  if (action.type === 'FETCH_FORMULATION_INVENTORY') {
    return action.payload;
  }
  if (action.type === 'CLEAR_FORMULATION_INVENTORY') {
    return [];
  }
  return state;
};

export const formulationStockAdjustments = (state = [], action) => {
  if (action.type === 'FETCH_FORMULATION_STOCK_ADJUSTMENTS') {
    return action.payload;
  }
  if (action.type === 'CLEAR_FORMULATION_STOCK_ADJUSTMENTS') {
    return [];
  }
  return state;
};

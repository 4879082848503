import React, { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Col, Row, Select, Table } from 'antd';
import startCase from 'lodash/startCase';
import moment from 'moment';
import Structure from '../Structure/Structure';
import { sorter, onFilter, filterDropdown, dateSorter, dateFilterDropdown, onDateFilter } from '../utils';
import { getAdjustedStockList, clearAdjustedStockList } from '../Actions';
import Heading from '../Components/Heading';

const AdjustedStockList = () => {
  const dispatch = useDispatch();

  const adjustedStockList = useSelector(state => state.adjustedStockList);

  const [financialYear, setFinancialYear] = useState('2024');

  useEffect(() => {
    dispatch(getAdjustedStockList({ financialYear }));
    return () => {
      dispatch(clearAdjustedStockList());
    };
  }, [financialYear]);

  const columns = [
    {
      title: 'Adjustment Type',
      dataIndex: 'type',
      sorter: sorter('type'),
      onFilter: onFilter('type', true),
      filterDropdown,
      render: type => startCase(type),
      width: 180,
    },
    {
      title: 'Consignor',
      dataIndex: 'consignor',
      filterDropdown,
      sorter: sorter('consignor'),
      onFilter: onFilter('consignor', true),
      width: 120,
    },
    {
      title: 'Material',
      dataIndex: 'material',
      filterDropdown,
      sorter: sorter('material'),
      onFilter: onFilter('material', true),
    },
    {
      title: 'Batch',
      dataIndex: 'batch',
      filterDropdown,
      sorter: sorter('batch'),
      onFilter: onFilter('batch', true),
      width: 120,
    },
    {
      title: 'Type',
      dataIndex: 'materialType',
      filterDropdown,
      sorter: sorter('materialType'),
      onFilter: onFilter('materialType', true),
      render: type => startCase(type),
      width: 120,
    },
    {
      title: 'Unit',
      dataIndex: 'unit',
      filterDropdown,
      sorter: sorter('unit'),
      onFilter: onFilter('unit', true),
      width: 80,
    },
    {
      title: 'Quantity',
      dataIndex: 'quantity',
      sorter: sorter('quantity'),
      filterDropdown,
      onFilter: onFilter('quantity'),
      width: 120,
    },
    {
      title: 'Remark',
      dataIndex: 'remark',
      sorter: sorter('remark'),
      filterDropdown,
      onFilter: onFilter('remark'),
    },
    {
      title: 'Date',
      dataIndex: 'createdAt',
      sorter: dateSorter('createdAt'),
      filterDropdown: dateFilterDropdown,
      onFilter: onDateFilter('createdAt'),
      render: createdAt => (createdAt ? moment(createdAt).format('DD MMM YYYY') : null),
      width: 120,
    },
    {
      title: 'Adjust By',
      dataIndex: 'byUser',
      sorter: sorter('byUser'),
      filterDropdown,
      onFilter: onFilter('byUser'),
      width: 140,
    },
  ];

  return (
    <Structure>
      <>
        <Heading text="Adjusted Stock List" />
        <Row gutter={[32, 8]}>
          <Col>
            <Select placeholder="Select year" onChange={value => setFinancialYear(value)} defaultValue={financialYear}>
              <Select.Option value="2019">19-20</Select.Option>
              <Select.Option value="2020">20-21</Select.Option>
              <Select.Option value="2021">21-22</Select.Option>
              <Select.Option value="2022">22-23</Select.Option>
              <Select.Option value="2023">23-24</Select.Option>
              <Select.Option value="2024">24-25</Select.Option>
              <Select.Option value="2025">25-26</Select.Option>
              <Select.Option value="2026">26-27</Select.Option>
              <Select.Option value="2027">27-28</Select.Option>
              <Select.Option value="2028">28-29</Select.Option>
              <Select.Option value="2029">29-30</Select.Option>
              <Select.Option value="2030">30-31</Select.Option>
            </Select>
          </Col>
        </Row>
        <Table
          columns={columns}
          bordered
          rowKey={record => record.id}
          pagination={{ defaultPageSize: 20, position: ['bottomLeft'] }}
          dataSource={adjustedStockList}
          size="small"
          scroll={{ x: 1600, y: window.innerHeight - 300 }}
        />
      </>
    </Structure>
  );
};

export default AdjustedStockList;

import Api from '../Api';

export const getAttendanceList = () => async dispatch => {
  try {
    const { data } = await Api.get('/attendances');
    dispatch({
      type: 'FETCH_ATTENDANCE_LIST',
      payload: data,
    });
  } catch (err) {
    throw err;
  }
};

export const clearAttendanceList = () => {
  return {
    type: 'CLEAR_ATTENDANCE_LIST',
  };
};

export const getAttendance = id => async dispatch => {
  try {
    const { data } = await Api.get(`/attendances/${id}`);
    dispatch({
      type: 'FETCH_ATTENDANCE',
      payload: data,
    });
  } catch (err) {
    throw err;
  }
};

export const clearAttendance = () => {
  return {
    type: 'CLEAR_ATTENDANCE',
  };
};

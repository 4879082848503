import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { Col, Input, Row, Select, Table } from 'antd';
import { Field, reduxForm } from 'redux-form';
import withStyles from 'react-jss';
import { useHistory } from 'react-router';
import { useDispatch, useSelector } from 'react-redux';
import Structure from '../Structure/Structure';

import Heading from '../Components/Heading';
import GetField from '../Components/GetField';
import constants from '../constants';
import { errorNotification, successNotification } from '../Snackbar';
import Api from '../Api';
import styles from '../CommonStyles';
import { clearUserList, getUserList } from '../Actions';

const AntInput = GetField(Input);
const AntSelect = GetField(Select);

const MISForm = props => {
  const { handleSubmit, classes } = props;
  const history = useHistory();
  const dispatch = useDispatch();

  const userList = useSelector(state => state.userList);

  useEffect(() => {
    dispatch(getUserList());
    return () => {
      dispatch(clearUserList());
    };
  }, []);

  const onSubmit = async () => {
    try {
      await Api.post('/bank-statements');
      successNotification('Transaction added');
      history.push('/transaction/list');
    } catch (err) {
      errorNotification(err);
    }
  };

  const renderText = ({ key, type = 'text', disabled = false } = {}) => {
    const TableField = (text, record) => (
      <Field
        name={`misFields[${record.index - 1}][${key}]`}
        disabled={disabled}
        min="0"
        type={type}
        component={AntInput}
      />
    );
    return TableField;
  };

  const { selectOptions } = classes;

  const renderUserSelect = ({ key, disabled = false } = {}) => {
    const TableField = (text, record) => (
      <Field
        name={`misFields[${record.index - 1}][${key}]`}
        showSearch
        allowClear
        optionFilterProp="children"
        disabled={disabled}
        component={AntSelect}
      >
        <Select.Option className={selectOptions} value="">
          {disabled ? null : 'Select'}
        </Select.Option>
        {userList.map(user => (
          <Select.Option className={selectOptions} key={user.id} value={user.id}>
            {user.name}
          </Select.Option>
        ))}
      </Field>
    );
    return TableField;
  };

  const columns = [
    {
      title: 'MIS Heads',
      dataIndex: 'misHead',
      width: 300,
      render: renderText({ key: 'misHead', disabled: true }),
    },
    {
      title: 'User',
      dataIndex: 'user',
      width: 70,
      render: renderUserSelect({ key: 'user', disabled: false }),
    },
    {
      title: 'Plan',
      dataIndex: 'plan',
      width: 70,
      render: renderText({ key: 'plan', disabled: false }),
    },
  ];

  return (
    <Structure>
      <>
        <Heading text="Finance MIS Form" />
        <form onSubmit={handleSubmit(onSubmit)}>
          <Row>
            <Col
              xs={{ span: 22, offset: 1 }}
              sm={{ span: 22, offset: 1 }}
              md={{ span: 22, offset: 1 }}
              lg={{ span: 22, offset: 1 }}
              xl={{ span: 22, offset: 1 }}
            >
              <Table
                columns={columns}
                bordered
                rowKey={record => record.index}
                pagination={false}
                dataSource={Object.values(constants.bank.financeMIS)}
                size="small"
                scroll={{ x: 425 }}
              />
            </Col>
          </Row>

          {/* {Object.values(constants.bank.financeMIS).map((type, index) => (
            <Row key={index}>
              <Col xs={24} lg={{ span: 5, offset: 1 }}>
                <Field allowClear defaultValue={type} name="type" component={AntSelect}>
                  {Object.values(constants.bank.financeMIS).map(mistype => (
                    <Select.Option key={mistype} value={mistype}>
                      {mistype}
                    </Select.Option>
                  ))}
                </Field>
              </Col>
              <Col xs={24} lg={{ span: 5, offset: 1 }}>
                <Field allowClear required name="userId" component={AntSelect}>
                  <Select.Option key={null} value="" disabled>
                    Select user
                  </Select.Option>
                  {userList.map(user => (
                    <Select.Option key={user.id} value={user.id}>
                      {user.name}
                    </Select.Option>
                  ))}
                </Field>
              </Col>
              <Col xs={24} lg={{ span: 5, offset: 1 }}>
                <Field allowClear required name="unitId" component={AntSelect}>
                  <Select.Option key={null} value="" disabled>
                    Select unit
                  </Select.Option>
                  {unitIdList.map(unit => (
                    <Select.Option key={unit.id} value={unit.id}>
                      {unit.unit}
                    </Select.Option>
                  ))}
                </Field>
              </Col>
              <Col xs={24} lg={{ span: 4, offset: 1 }}>
                <Field allowClear name="plan" placeholder="Plan" component={AntInput} />
              </Col>
            </Row>
          ))} */}
        </form>
      </>
    </Structure>
  );
};

MISForm.propTypes = {
  handleSubmit: PropTypes.func.isRequired,
  classes: PropTypes.object.isRequired,
};

const StyledMISForm = withStyles(styles)(MISForm);

const FormedMISForm = reduxForm({
  form: 'MISForm',
})(StyledMISForm);

export default FormedMISForm;

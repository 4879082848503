import Api from '../Api';

export const getEnquiryList = params => async dispatch => {
  try {
    const { data } = await Api.get('/inquiries', params);
    dispatch({
      type: 'FETCH_ENQUIRY_LIST',
      payload: data,
    });
  } catch (err) {
    throw err;
  }
};

export const clearEnquiryList = () => {
  return {
    type: 'CLEAR_ENQUIRY_LIST',
  };
};

export const getEnquiry = id => async dispatch => {
  try {
    const { data } = await Api.get(`/inquiries/${id}`);
    dispatch({
      type: 'FETCH_ENQUIRY',
      payload: data,
    });
  } catch (err) {
    throw err;
  }
};

export const clearEnquiry = () => {
  return {
    type: 'CLEAR_ENQUIRY',
  };
};

export const getEnquiryFilesList = id => async dispatch => {
  try {
    const { data } = await Api.get(`/inquiries/pi/${id}`);
    dispatch({
      type: 'FETCH_ENQUIRY_FILES_LIST',
      payload: data,
    });
  } catch (err) {
    throw err;
  }
};

export const clearEnquiryFilesList = () => {
  return {
    type: 'CLEAR_ENQUIRY_FILES_LIST',
  };
};
